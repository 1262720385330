import React, { FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';

export const DeviationFromDoaApprovalInformation: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.deviationFromDoaApprovalInformation';
        const errorMessage = inputError(name, formikProps);
        const value = inputValue(name, formikProps);
        const defaultHelperText = `${value.length}/2000`;
        return (
            <TextField
                name={name}
                label='Deviation from DoA / Approval Information'
                value={value}
                disabled={disabled}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage || defaultHelperText}
                margin='dense'
                rowsMax={3}
                multiline
                fullWidth
            />
        );
    };
