import { getActualization } from '../../api/model-service/actualization';
import { AppThunk } from '../app-thunk';
import { successResponse } from './createActualizationSlice';

export const refreshActualization = (): AppThunk => (dispatch, getState) => {
    const actualizationState = getState().REFACTORED.actualizationResult;
    const actualization = actualizationState.data;
    if (!actualization || actualizationState.loading) {
        return Promise.resolve();
    }

    return getActualization(actualization.id)
        .then(response => {
            dispatch(successResponse(response))
        });
};
