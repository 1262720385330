import React, { ReactNode } from 'react';
import { OtherTermsConditionsProps } from './OtherTermsConditionsProps';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Configuration, MyPlantProduct as MyPlantProductType } from '../../api/model-service/model-service';
import { Grid } from '@material-ui/core';
import { ContractGoverningLaw } from './ContractGoverningLaw';
import { ContractLanguage } from './ContractLanguage';
import { Form } from '../Form/Form';
import { SecondContractLanguage } from './SecondContractLanguage';
import { TerminationCategory } from './TerminationCategory';
import { TerminationAmount } from './TerminationAmount';
import { TerminationWordingStandard } from './TerminationWordingStandard';
import { SuspensionWordingStandard } from './SuspensionWordingStandard';
import { EmbeddedDerivatives } from './EmbeddedDerivatives';
import { LimitationOfLiabilityAmount } from './LimitationOfLiabilityAmount';
import { LimitationOfLiabilityWordingStandard } from './LimitationOfLiabilityWordingStandard';
import { ProductivityImplClause } from './ProductivityImplClause';
import { DeliveryTerms } from './DeliveryTerms';
import { PassageOfTitleWordingStandard } from './PassageOfTitleWordingStandard';
import { SiteInventoryIncluded } from './SiteInventoryIncluded';
import { WarrantyPeriodParts } from './WarrantyPeriodParts';
import { WarrantyPeriodService } from './WarrantyPeriodService';
import { MyPlantProduct } from './MyPlantProduct';
import { MyPlantWording } from './MyPlantWording';
import { WarrantyWordingStandard } from './WarrantyWordingStandard';
import { ResidualValueOrBuyBackGuaranteeIncluded } from './ResidualValueOrBuyBackGuaranteeIncluded';
import { AssignmentWordingStandard } from './AssignmentWordingStandard';
import { OtherContractWordingStandard } from './OtherContractWordingStandard';
import { DepositChargeForReupPartsBlocks } from './DepositChargeForReupPartsBlocks';
import { PartsListChecked } from './PartsListChecked';
import { DeviationFromDoaApprovalInformation } from './DeviationFromDoaApprovalInformation';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { getTermsAndConditionsSchema } from './schema';
import { ObjectSchema } from 'yup';
import { isModelAsSold } from '../../utils/isModelAsSold';

function defaultValues(props: OtherTermsConditionsFormProps): Configuration {
    return {
        ...props.configuration,
        termsConditions: {
            contractGoverningLaw: 'Germany',
            contractLanguage: 'German',
            secondContractLanguage: '',
            terminationCategory: '',
            terminationAmount: 15,
            terminationWordingStandard: true,
            suspensionWordingStandard: true,
            embeddedDerivatives: false,
            limitationOfLiabilityAmount: 100,
            limitationOfLiabilityWordingStandard: true,
            productivityImplClause: props.showProductivityImplClause ? true : null,
            deliveryTerms: 'DAP',
            passageOfTitleWordingStandard: true,
            siteInventoryIncluded: props.showSiteInventoryIncluded ? false : null,
            warrantyPeriodParts: 1,
            warrantyPeriodService: props.showWarrantyPeriodService ? 1 : null,
            warrantyWordingStandard: true,
            residualValueOrBuyBackGuaranteeIncluded: false,
            assignmentWordingStandard: true,
            otherContractWordingStandard: true,
            deviationFromDoaApprovalInformation: '',
            myPlantProduct: props.defaultMyPlantProduct,
            myPlantWording: true,
            depositChargeForReupPartsBlocks: props.showDepositChargeForReupPartsBlocks ? false : null,
            partsListChecked: props.showPartsListChecked ? false : null,
        },
    }
}

export class OtherTermsConditionsForm extends ConfigurationForm<OtherTermsConditionsFormProps> {

    public render(): ReactNode {
        const props = this.props;
        const initialValues = props.configuration.termsConditions
            ? props.configuration
            : defaultValues(props);
        const schema = getTermsAndConditionsSchema(props);
        const disabled = isModelAsSold(props.model);

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(config, actions) => this.handleSubmit(config, actions, schema)}
                enableReinitialize
            >
                {(formikProps: FormikProps<Configuration>) =>
                    <Form testName='termsConditions' noValidate>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4}>
                                <ContractGoverningLaw formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContractLanguage formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SecondContractLanguage formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TerminationCategory formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TerminationAmount formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TerminationWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <SuspensionWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <EmbeddedDerivatives formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <LimitationOfLiabilityAmount formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <LimitationOfLiabilityWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            {props.showProductivityImplClause
                                ? <Grid item xs={6} sm={3}>
                                    <ProductivityImplClause formikProps={formikProps} disabled={disabled}/>
                                </Grid>
                                : null
                            }
                            <Grid item xs={12} sm={3}>
                                <DeliveryTerms formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <PassageOfTitleWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            {props.showSiteInventoryIncluded
                                ? <Grid item xs={6} sm={3}>
                                    <SiteInventoryIncluded formikProps={formikProps} disabled={disabled}/>
                                </Grid>
                                : null
                            }
                            <Grid item xs={12} sm={3}>
                                <WarrantyPeriodParts formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            {props.showWarrantyPeriodService
                                ? <Grid item xs={12} sm={3}>
                                    <WarrantyPeriodService formikProps={formikProps} disabled={disabled}/>
                                </Grid>
                                : null
                            }
                            <Grid item xs={12} sm={3}>
                                <MyPlantProduct formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <MyPlantWording formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <WarrantyWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <ResidualValueOrBuyBackGuaranteeIncluded formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AssignmentWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <OtherContractWordingStandard formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                            {props.showDepositChargeForReupPartsBlocks
                                ? <Grid item xs={6} sm={3}>
                                    <DepositChargeForReupPartsBlocks formikProps={formikProps} disabled={disabled}/>
                                </Grid>
                                : null
                            }
                            {props.showPartsListChecked
                                ? <Grid item xs={6} sm={3}>
                                    <PartsListChecked formikProps={formikProps} disabled={false}/>
                                </Grid>
                                : null
                            }
                            <Grid item xs={12}>
                                <DeviationFromDoaApprovalInformation formikProps={formikProps} disabled={disabled}/>
                            </Grid>
                        </Grid>
                        <ConfigurationFormButtons
                            loading={formikProps.isSubmitting}
                            onBack={props.onBack}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onPrimaryComplete={() => this.submitForm(formikProps, 'PRIMARY_COMPLETE')}
                            viewConfigurationUrl={props.viewConfigurationUrl}
                        />
                    </Form>
                }
            </Formik>
        );
    }

    private handleSubmit(configuration: Configuration,
                         actions: FormikActions<Configuration>,
                         schema: ObjectSchema<Partial<Configuration>>): void {

        const submittedConfig = this.getSubmittedConfiguration(configuration, schema);
        this.props.onSubmit(submittedConfig)
            .then(() => {
                if (this.isPrimaryCompleteSubmit()) {
                    this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private getSubmittedConfiguration(formValues: Configuration,
                                      schema: ObjectSchema<Partial<Configuration>>): Configuration {

        const configFromSchema = schema.cast(formValues);
        return this.isPrimaryCompleteSubmit()
            ? {
                ...configFromSchema,
                state: 'COMPLETED',
            }
            : configFromSchema as Configuration;
    }

    private isPrimaryCompleteSubmit(): boolean {
        return this.submitReason === 'PRIMARY_COMPLETE';
    }
}

export interface OtherTermsConditionsFormProps extends OtherTermsConditionsProps {
    showProductivityImplClause: boolean,
    showSiteInventoryIncluded: boolean,
    showWarrantyPeriodService: boolean,
    showDepositChargeForReupPartsBlocks: boolean,
    showPartsListChecked: boolean,
    defaultMyPlantProduct: MyPlantProductType,
}
