import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { FieldProps } from './FieldProps';
import { IFormOption } from '../../data/utils/form';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { formOption } from '../../utils/formOption';
import { Activity, ActivitySchedulingType } from '../../api/model-service/model-service';
import { resetInput } from './resetInput';

export const SchedulingType: FC<FieldProps> =
    ({ formikProps }) => {
        const name = 'schedulingType';
        const error = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='Trigger'
                margin='dense'
                placeholder='Select a trigger'
                options={getSchedulingTypeOptions(formikProps.values)}
                value={inputValue(name, formikProps)}
                onChange={evt => {
                    formikProps.handleChange(evt);
                    resetInput('frequency', formikProps);
                }}
                onBlur={formikProps.handleBlur}
                error={!!error}
                helperText={error}
                fullWidth
            />
        );
    };

function getSchedulingTypeOptions(activity: Activity): IFormOption[] {
    return isUnitActivity(activity)
        ? UNIT_SCHEDULING_TYPES
        : SITE_SCHEDULING_TYPES;
}

function isUnitActivity(activity: Activity): boolean {
    return activity.scope === 'UNIT';
}

const UNIT_SCHEDULING_TYPES: Array<IFormOption<ActivitySchedulingType>> = [
    formOption('OPH', 'Oph'),
    formOption('YEARS', 'Yearly'),
];

const SITE_SCHEDULING_TYPES: Array<IFormOption<ActivitySchedulingType>> = [
    formOption('YEARS', 'Yearly'),
];
