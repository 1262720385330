import React from 'react';
import { TableCell as TableHeaderCell } from '@material-ui/core';
import TableDataCell from '../Tables/TableDataCell';
import { StyleRules, withStyles } from '@material-ui/core/styles';

const styles: StyleRules = {
    body: {
        fontSize: 14,
    },
    head: {
        color: '#b7b3b3',
    },
};
const decorateWithStyles = withStyles(styles);

export const StyledTableHeaderCell = decorateWithStyles(TableHeaderCell);
export const StyledTableDataCell = decorateWithStyles(TableDataCell);
