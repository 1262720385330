import { Component } from 'react';
import { FormikProps } from '../Formik';
import { Configuration } from '../../api/model-service/model-service';

export abstract class ConfigurationForm<P, S = never> extends Component<P, S> {

    private submitReason0: SubmitReason = null;

    protected submitForm(formikProps: FormikProps<Configuration>, reason: SubmitReason): void {
        if (formikProps.isSubmitting) {
            return;
        }

        this.submitReason0 = reason;
        formikProps.submitForm();
    }

    protected get submitReason(): SubmitReason {
        return this.submitReason0;
    }
}

export type SubmitReason = 'SAVE' | 'NEXT' | 'PRIMARY_COMPLETE' | 'SECONDARY_COMPLETE';
