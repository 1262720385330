import React, { cloneElement, FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { RadioGroupField } from '../RadioGroupField/RadioGroupField';
import { FieldProps } from './FieldProps';
import { Escalation } from '../../api/model-service/model-service';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';

export const Type: FC<FieldProps> =
    ({ formikProps, container }) => {
        const name = 'type';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <RadioGroupField
                    name={name}
                    label='Choose Escalation Type'
                    options={options}
                    value={inputValue(name, formikProps)}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    required
                />,
        });
    };

const options: Array<IFormOption<Escalation['type']>> = [
    formOption('fixPrice', 'Fixed Price Escalation'),
    formOption('details', 'Variable Price Escalation'),
];
