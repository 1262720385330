import React, { Component, ReactNode } from 'react';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import {
    activityCostDisplay,
    activityTypeDisplay,
    IScope,
    IScopeSchema,
    ScopeSchedulingType,
} from '../../data/model/configuration';
import { formatNumber } from '../../data/utils/number';
import { IconButton } from '../Buttons/IconButton';
import { Check, Clear, Edit } from '@material-ui/icons';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { TextField } from '../TextField/TextField';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
    isCatalyst,
    isGenSetSparkPlugs,
    isGreenhouseCO2,
    isLubeOil,
} from '../StandardScopeFormSection/activity-filters';
import { AuthorizedToEditForm } from '../Form/AuthorizedToEditForm';

class ServiceTableRow0 extends Component<StyledComponentProps & ServiceTableRowProps, State> {
    public state = {
        editing: false,
    };

    public render(): ReactNode {
        const handleSubmit = (formActivity: IScope, actions: FormikActions<IScope>) => {
            const updatedActivity = this.isFrequencyModified(formActivity)
                ? { ...formActivity, frequencyModified: true }
                : formActivity;

            this.stopEditing();
            this.props.onChange(updatedActivity);
            actions.setSubmitting(false);
        };
        const reinitializeFormWhenActivityPropChanges = !this.isEditing();

        return (
            <Formik
                initialValues={this.props.activity}
                validationSchema={IScopeSchema}
                onSubmit={handleSubmit}
                enableReinitialize={reinitializeFormWhenActivityPropChanges}
            >
                {formikProps => this.renderRow(formikProps)}
            </Formik>
        );
    };

    private renderRow(formikProps: FormikProps<IScope>): ReactNode {
        const { activity, showCost, testName } = this.props;

        return (
            <TableDataRow testName={testName} testId={'' + activity.id}>
                <TableDataCell testName='service' component='th' scope='row'>
                    {activity.service}
                </TableDataCell>
                <TableDataCell testName='scope'>{activity.scope}</TableDataCell>
                <TableDataCell testName='type'>
                    {activityTypeDisplay(activity.type)}
                </TableDataCell>
                <TableDataCell testName='additionalAttribute'>
                    {activity.additionalAttribute}
                </TableDataCell>
                <TableDataCell testName='frequency' align='right'>
                    {this.isFrequencyEditable() && this.isEditing()
                        ? this.getFrequencyInput(formikProps)
                        : this.getFrequencyValue()
                    }
                </TableDataCell>
                <TableDataCell testName='frequencyOffset' align='right'>
                    {this.isEditing()
                        ? this.getFrequencyOffsetInput(formikProps)
                        : this.getFrequencyOffsetValue()
                    }
                </TableDataCell>
                <TableDataCell testName='occurrence' align='right'>
                    {formatNumber(activity.calculatedTotalOccurrence, 0, 2)}
                </TableDataCell>
                {showCost
                    ? <TableDataCell testName='cost' align='right'>
                        {activityCostDisplay(activity)}
                    </TableDataCell>
                    : null
                }
                {this.getActionsCell(formikProps)}
            </TableDataRow>
        );
    }

    private isFrequencyEditable(): boolean {
        const activity = this.props.activity;
        return isGenSetSparkPlugs(activity) ||
            isCatalyst(activity) ||
            isGreenhouseCO2(activity) ||
            isLubeOil(activity);
    }

    private getFrequencyValue(): ReactNode {
        if (this.isYearlyActivity(this.props.activity)) {
            return this.getYearlyFrequencyValue();
        } else {
            return this.getOphFrequencyValue();
        }
    }

    private getYearlyFrequencyValue(): ReactNode {
        return 'Yearly';
    }

    private getOphFrequencyValue(): ReactNode {
        const { activity, classes } = this.props;
        const formattedFrequency = formatNumber(activity.frequency, 0, 2);
        const frequencyValue = `${formattedFrequency} Oph`;

        return activity.frequencyModified
            ? <span className={classes.modifiedFrequency}>
                {frequencyValue}
            </span>
            : frequencyValue;
    }

    private getFrequencyOffsetValue(): ReactNode {
        const activity = this.props.activity;
        const frequencyOffset = activity.frequencyOffset;

        if (!Number.isFinite(frequencyOffset)) {
            return null;
        }

        const unit = this.isYearlyActivity(activity)
            ? 'Years'
            : 'Oph';
        const formattedOffset = formatNumber(frequencyOffset, 0, 2);
        return `${formattedOffset} ${unit}`;
    }

    private getFrequencyInput(formikProps: FormikProps<IScope>): ReactNode {
        const classes = this.props.classes;

        const activity = formikProps.values;
        const fieldName: keyof IScope = 'frequency';
        const frequency = activity[fieldName];

        const error = this.getInputError(fieldName, formikProps);
        const hasError = !!error;
        const helperText = hasError ? error : null;

        return (
            <TextField
                className={classes.input}
                type='number'
                name={fieldName}
                placeholder='250 or greater'
                value={frequency}
                inputProps={{ min: '250' }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={hasError}
                helperText={helperText}
            />
        );
    }

    private getFrequencyOffsetInput(formikProps: FormikProps<IScope>): ReactNode {
        const classes = this.props.classes;

        const activity = formikProps.values;
        const fieldName: keyof IScope = 'frequencyOffset';
        const frequencyOffset = activity[fieldName];
        const hasOffset = Number.isFinite(frequencyOffset);
        const value = hasOffset ? frequencyOffset : '';

        const error = this.getInputError(fieldName, formikProps);
        const hasError = !!error;
        const helperText = hasError ? error : null;

        const placeholder = this.isYearlyActivity(activity)
            ? '0 or more years'
            : '0 or more oph';

        return (
            <TextField
                className={classes.input}
                type='number'
                name={fieldName}
                placeholder={placeholder}
                value={value}
                inputProps={{ min: '0' }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={hasError}
                helperText={helperText}
                autoFocus
            />
        );
    }

    private getInputError(name: keyof Omit<IScope, 'perUnits'>,
                          formikProps: FormikProps<IScope>): string {
        const isTouchedInput = formikProps.touched[name];
        if (!isTouchedInput) {
            return null;
        }

        return formikProps.errors[name];
    }

    private isFrequencyModified(formActivity: IScope): boolean {
        const activity = this.props.activity;
        return activity.frequencyModified ||
            activity.frequency !== formActivity.frequency;
    }

    private isYearlyActivity(activity: IScope): boolean {
        return activity.schedulingType === ScopeSchedulingType.YEARS;
    }

    private getActionsCell(formikProps: FormikProps<IScope>): ReactNode {
        return (
            <AuthorizedToEditForm>
                <TableDataCell testName='actions'>
                    {this.getActionButtons(formikProps)}
                </TableDataCell>
            </AuthorizedToEditForm>
        );
    }

    private getActionButtons(formikProps: FormikProps<IScope>): ReactNode {
        return this.isEditing()
            ? <div style={{ display: 'flex' }}>
                {this.getSubmitButton(formikProps)}
                {this.getCancelButton(formikProps)}
            </div>
            : this.getEditButton();
    }

    private getSubmitButton(formikProps: FormikProps<IScope>): ReactNode {
        return (
            <IconButton testName='submit' color='primary' onClick={formikProps.submitForm}>
                <Check/>
            </IconButton>
        );
    }

    private getCancelButton(formikProps: FormikProps<IScope>): ReactNode {
        const handleClick = () => {
            this.stopEditing();
            formikProps.resetForm();
        };
        return (
            <IconButton testName='cancel' color='primary' onClick={handleClick}>
                <Clear/>
            </IconButton>
        );
    }

    private getEditButton(): ReactNode {
        const handleClick = () => this.startEditing();
        return (
            <IconButton testName='edit' color='primary' onClick={handleClick}>
                <Edit/>
            </IconButton>
        );
    }

    private startEditing(): void {
        this.setEditing(true);
    }

    private stopEditing(): void {
        this.setEditing(false);
    }

    private setEditing(editing: boolean): void {
        this.setState({ editing });
    }

    private isEditing(): boolean {
        return this.state.editing;
    }
}

export const ServiceTableRow = withStyles(getStyles)(ServiceTableRow0);

export interface ServiceTableRowProps {
    testName: string,
    activity: IScope,
    showCost: boolean,
    onChange: (activity: IScope) => void,
}

interface State {
    editing: boolean,
}

function getStyles(): StyleRules {
    return {
        input: { minWidth: '120px' },
        modifiedFrequency: { color: '#f44336' },
    };
}
