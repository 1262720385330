import React, { FC } from 'react';
import { OtherTermsConditionsProps } from './OtherTermsConditionsProps';
import { OtherTermsConditionsForm } from './OtherTermsConditionsForm';

export const MsaTermsConditions: FC<OtherTermsConditionsProps> =
    props =>
        <OtherTermsConditionsForm
            {...props}
            showProductivityImplClause={false}
            showSiteInventoryIncluded={false}
            showWarrantyPeriodService={false}
            showDepositChargeForReupPartsBlocks
            showPartsListChecked
            defaultMyPlantProduct='BASIC'
        />
;
