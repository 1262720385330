import { AnyAction } from '@reduxjs/toolkit';

export function reportCatalogError(err: Error): AnyAction {
    // TODO refactor legacy error reporting
    return {
        error: err,
        type: 'ERROR_CATALOG',
    };
}

export function reportConfigurationError(err: Error): AnyAction {
    return {
        error: err,
        type: 'ERROR_CONFIGURATION',
    };
}

export function reportModelError(err: Error): AnyAction {
    return {
        error: err,
        type: 'ERROR_MODEL',
    };
}
