import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { formOption } from '../../utils/formOption';
import { GasType } from '../../api/model-service/model-service';

export const GasTypeInput: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'engineInformation.gasType';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Gas Type'
                    placeholder='Select gas type'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

const options: Array<IFormOption<GasType>> = [
    formOption('NG'),
    formOption('BG'),
    formOption('APG'),
    formOption('LFG'),
    formOption('CMG'),
    formOption('WG'),
    formOption('OTHER'),
];
