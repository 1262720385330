import { array, number, object, ObjectSchema, yearMonth } from '../../utils/validators';

import { ActualizationCandidate, ActualizationPayload } from "../../api/model-service/actualization";
import { requiredMessage } from '../../utils/validationMessages';

const escalationLimitErrorText = 'The escalation value must be between to -10% and 10%'

function getCandidateSchema() {
    const candidateSchema = object<ActualizationCandidate>().shape({
        id: number().required('The actualization candidate must have an id'),
        lockVersion: number().required('The actualization candidate must have a lock version'),
    });

    return candidateSchema;
}

export function getSchema(): ObjectSchema<ActualizationPayload> {
    const schema = object<ActualizationPayload>().shape({
        candidates: array().of(getCandidateSchema()),
        actualizationPeriod: yearMonth()
            .required(requiredMessage)
            .past(),
        escalationRate: number()
            .min(-10, escalationLimitErrorText)
            .max(10, escalationLimitErrorText)
            .required(requiredMessage),
        activityCatalogId: number().required(requiredMessage),
        regionCatalogId: number().required(requiredMessage),    
    });

    return schema;
}

