import {
    AnnualCapUnit,
    BillingBase,
    BonusUnit,
    GaurenteeType,
    GaurenteeUnit,
    MyPlantProduct,
    ScopeTypes,
    ScopeTypesInputs,
} from './configuration_enums';
import { formatCurrency, formatNumber } from '../utils/number';
import { IPricing } from './configuration_pricing';
import { iv } from '../utils/form';
import { sunsetClause } from './configuration_enums';
import * as yup from 'yup';
import { values, isNull } from 'lodash';

export interface IConfigurationView {
    id: number;
    name: string;
    displayName: string;
    primaryConfiguration: boolean;
    state: string;
    catalogReference: string;
    description: string;
    numberOfUnits: number;
    activityCatalogId: number;
    contractCategory: string;
    unitStartCounter: number;
    unitEndCounter: number;
    modifiedOn: string;
    modifiedBy: string;
    createdOn: string;
    createdBy: string;
    units?: IUnit[];
    commercialOperationDate?: string;
}

export interface IConfigurationTerm {
    commercialOperationDate: string;
    effectiveContractStartDate: string;
    expirationDate?: string;
    maxDuration?: number;
    sunsetClauseType: sunsetClause;
    id?: number;
}

export interface IConfiguration {
    id?: number;
    name?: string;
    displayName?: string;
    contractCategory?: contractCatagory;
    primaryConfiguration?: boolean;
    equipmentCatalogId?: number;
    activityCatalogId?: number;
    costBreakdown?: any;
    state?: string;
    engineInformation?: IConfigurationEngine;
    units?: IUnit[];
    term?: IConfigurationTerm;
    unitStartCounter?: number;
    unitEndCounter?: number;
    expectedOperatingHoursPerYear?: number;
    expectedStartsPerYear?: number;
    standardScope?: IScope[];
    additionalScope?: IScope[];
    siteLevelScope?: IScope[];
    customerObligation?: ICustomerObligation;
    operation?: IConfigurationOperation;
    guarantees?: IConfigurationGaurantee[];
    guaranteeCap?: IConfigurationGauranteeCap;
    termsConditions?: IConfigurationTermsConditions;
    payment?: IConfigurationPayment;
    pricing?: IPricing;
    lockVersion?: number;
    catalogRegionId?:number;
}

export interface IEngineDetails {
    id: number;
    type: string;
    version: string;
    maintenanceSection: string;
    maintenanceSchedule: string;
    equipmentModel: string;
    electricalPower: number;
    mechanicalPower: number;
    oilVolume: number;
}

export interface IConfigurationEngine {
    engineType?: string;
    engineVersion?: string;
    maintenanceSection?: string;
    maintenanceSchedule?: string;
    equipmentModel?: string;
    electricalPower?: number;
    mechanicalPower?: number;
    oilVolume?: number;
    unitType?: string;
    gasType?: string;
    productProgram?: string;
    detailsID?: number;
    id?: number;
}

export interface IUnit {
    id?: number;
    serialNumber?: string;
    unitStartCounter?: number;
    unitEndCounter?: number;
    expectedOperatingHoursPerYear?: number;
    expectedStartsPerYear?: number;
    unitReadCounter?: number;
    unitReadDate?: string;
}

export interface ICustomerObligation {
    sparkPlugService?: boolean;
    coolingWaterAnalysis?: boolean;
    fuelGasAnalysis?: boolean;
    lubeOilSupply?: boolean;
    lubeOilDisposal?: boolean;
    lubeOilSampling?: boolean;
    plannedRoutineMaintenance?: boolean;
    assistanceToExtendedMaintenance?: boolean;
    machineryBreakdownInsurance?: boolean;
    id?: number;
}

export interface IConfigurationOperation {
    ehsComplianceValidated?: boolean;
    operationalExecutionStrategyValidated?: boolean;
    id?: number;
}

export interface IConfigurationGaurantee {
    id?: number;
    type?: GaurenteeType;
    value?: number;
    unit?: GaurenteeUnit;
    standardDefinitionAndWording?: boolean;
    nonStandardDescription?: string;
    bonus?: IBonusOrLd;
    bonusCap?: IBonusOrLdCap;
    ld?: IBonusOrLd;
    ldCap?: IBonusOrLdCap;
}

export interface IBonusOrLd {
    id?: number;
    unit?: BonusUnit;
    value?: number;
}

export interface IBonusOrLdCap {
    id?: number;
    unit?: AnnualCapUnit;
    value?: number;
}

export interface IConfigurationGauranteeCap {
    id?: number;
    annualLdCap?: {
        id?: number;
        unit?: string;
        value?: number;
    };
    contractLdCap?: {
        id?: number;
        unit?: string;
        value?: number;
    };
}

export interface IConfigurationTermsConditions {
    id?: number;
    assignmentWordingStandard?: boolean;
    contractGoverningLaw?: string;
    contractLanguage?: string;
    deliveryTerms?: string;
    deviationFromDoaApprovalInformation?: string;
    embeddedDerivatives?: boolean;
    limitationOfLiabilityAmount?: number;
    limitationOfLiabilityWordingStandard?: boolean;
    otherContractWordingStandard?: boolean;
    passageOfTitleWordingStandard?: boolean;
    productivityImplClause?: boolean;
    residualValueOrBuyBackGuaranteeIncluded?: boolean;
    secondContractLanguage?: string;
    siteInventoryIncluded?: boolean;
    suspensionWordingStandard?: boolean;
    myPlantProduct?: MyPlantProduct;
    myPlantWording?: boolean;
    terminationAmount?: number;
    terminationCategory?: string;
    terminationWordingStandard?: boolean;
    warrantyPeriodParts?: number;
    warrantyPeriodService?: number;
    warrantyWordingStandard?: boolean;
    depositChargeForReupPartsBlocks?: boolean;
    partsListChecked?: boolean;
}

export interface IConfigurationPayment {
    billingCurrency?: string;
    billingCurrencyFx?: number;
    billingInterval?: string;
    annualBillingStartDate?: string;
    paymentTerm?: number;
    minimumOperatingHoursPerYear?: number;
    minimumOperatingHoursPerYearViolationAgreement?: string;
    extraWorkDiscountMaterial?: number;
    extraWorkDiscountLabor?: number;
    otherSpecialPayments?: string;
    escalations: IConfigurationPaymentEscalation[];
    id?: number;
}

export interface IConfigurationPaymentEscalation {
    type?: string;
    billingBases?: BillingBase[];
    initialPriceEscalationDate?: string;
    priceEscalationLowerLimit?: number;
    priceEscalationUpperLimit?: number;
    fixedPriceEscalationValue?: number;
    indexRatio?: {
        price?: number;
        labor?: number;
        material?: number;
        lubeOil?: number;
    };
    laborIndex?: IMaterialIndex;
    materialIndex?: IMaterialIndex;
    lubeOilIndex?: IMaterialIndex;
    calculationMethod?: CalculationMethod;
    legacyEscalationFormula?: string;
    id?: number;
    open?: boolean;
}

export interface IMaterialIndex {
    name?: string;
    value?: number;
    date?: string;
}

export interface IScope {
    key?: string;
    scope?: string;
    service?: string;
    type?: ScopeTypes;
    unit?: ScopeUnit;
    schedulingType: ScopeSchedulingType;
    frequency?: number;
    frequencyModified: boolean;
    frequencyOffset: number;
    value?: number;
    cost?: number;
    fixedOccurrence: boolean;
    additionalAttribute?: string;
    occurrence?: number;
    id?: number;
    perUnits?: IUnitScope[];
    costPerEvent?: number;
    calculatedTotalOccurrence?: number;
    icValue?: number;
}

export enum ScopeUnit {
    EURO = 'EURO',
    HOURS = 'HOURS',
    LITERS = 'LITERS',
}

export enum ScopeSchedulingType {
    OPH = 'OPH',
    YEARS = 'YEARS',
}

export interface IUnitScope {
    unit: IUnit,
    occurrence: number,
}

const scopeFrequencySchema = yup.mixed()
    .when('schedulingType', {
        is: ScopeSchedulingType.OPH,
        then: yup.number()
            .required('Frequency is required')
            .integer('Must be an integer')
            .min(250, 'Must be ${min} or greater'),
        otherwise: yup.mixed()
            .test('isNull', 'Frequency cannot be filled for yearly triggers', isNull),
    });

const positiveIntegerSchema = yup.number()
    .nullable()
    .integer('Must be an integer')
    .min(0, 'Must be ${min} or greater');

export const IScopeSchema = yup.object().shape({
    frequency: scopeFrequencySchema,
    frequencyOffset: positiveIntegerSchema,
});

export const ScopeFormSchema = yup.object().shape({
    scope: yup.string().nullable().trim().required('Scope is required'),
    service: yup.string().nullable().trim().required('Service is required'),
    type: yup.string().nullable().required('Type is required')
        .oneOf(values(ScopeTypes)),
    schedulingType: yup.string().nullable().required('Trigger is required')
        .when('scope', (scope, schema) => {
            return scope === 'SITE'
                ? schema.oneOf([ScopeSchedulingType.YEARS])
                : schema.oneOf(values(ScopeSchedulingType));
        }),
    frequency: scopeFrequencySchema.nullable(),
    occurrence: positiveIntegerSchema,
    value: yup.number().nullable().required('Value is required'),
});

export enum contractCatagory {
    CSA_PREVENTIVE = 'CSA_PREVENTIVE',
    CSA_PREVENTIVE_AND_CORRECTIVE = 'CSA_PREVENTIVE_AND_CORRECTIVE',
    MSA_PREVENTIVE_AND_CORRECTIVE = 'MSA_PREVENTIVE_AND_CORRECTIVE',
    MSA_PREVENTIVE = 'MSA_PREVENTIVE',
}

export const contractCatagoryInputs = [
    iv(contractCatagory.CSA_PREVENTIVE, 'CSA Preventive'),
    iv(contractCatagory.CSA_PREVENTIVE_AND_CORRECTIVE, 'CSA Preventive & Corrective'),
    iv(contractCatagory.MSA_PREVENTIVE_AND_CORRECTIVE, 'MSA Preventive & Corrective'),
    iv(contractCatagory.MSA_PREVENTIVE, 'MSA Preventive'),
];

export const activityToTitle = (scope: IScope): string => {
    if (scope.frequency > 999) {
        return `${scope.service} (${formatNumber(scope.frequency, 0, 0)})`;
    } else {
        return scope.service;
    }
};

export const activityTypeDisplay = (type: any): string => {
    const activityOption = ScopeTypesInputs.find(
        element => element.value === type
    );
    return activityOption ? activityOption.label : '';
};

export const activityCostDisplay = (scope: IScope): string => {
    if (scope.unit && scope.unit !== 'EURO') {
        return `${formatNumber(scope.value, 0, 2)} ${scope.unit.toLowerCase()}`;
    } else {
        return formatCurrency('EUR', scope.value);
    }
};

export enum CalculationMethod {
    MONTHLY_SPECIFIC = 'Monthly specific',
    YEARLY_AVERAGE = 'Yearly average',
}
