import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ErrorType } from './error';
import {IUserInfo} from "../model/user";

// CREATE REDUX ACTION TYPES
const STORE = 'STORE';

export interface IUserAction {
    type: string;
    error?: Error | string;
    user?: IUserInfo;
    errorType?: ErrorType;
}

export const USER = {
    STORE: `${STORE}_USER`,
};

// END REDUX ACTION TYPE CREATION

// Async Redux-Thunk action
export const storeUser: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IUserAction>
> = (payload: IUserInfo) => {
    return async (dispatch: Dispatch<IUserAction>): Promise<Action> => {
        return dispatch({
            user: payload,
            type: USER[STORE],
        });
    }
};