import * as React from 'react';
import './NavigationList.scss';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EditIcon from '@material-ui/icons/Edit';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { createLinkTestProps } from "../../Utils/testProps";
import { Authority } from '../../../data/model/user';
import { Authorized } from '../../authorization';

const styles = () => ({
    button: {
        margin: '10px 0px',
        textTransform: 'capitalize',
        color: '#efefef',
        fontSize: 14,
    },
    selected: {
        color: 'aqua',
    },
    leftIcon: {
        marginRight: '10px',
    },
    rightIcon: {
        marginLeft: '0px',
    },
    iconSmall: {
        fontSize: 20,
    },
});

const NavigationList = (props: any) => {
    const { classes } = props;
    const { READ_MODELS, READ_CATALOGS, READ_REPORTS, WRITE_ACTUALIZATION } = Authority;

    const navItems = [
        {
            name: 'dashboard',
            testName: 'dashboard',
            icon: <DashboardIcon />,
            authority: READ_MODELS,
        },
        {
            name: 'models',
            testName: 'models',
            icon: <FolderIcon />,
            authority: READ_MODELS,
        },
        {
            name: 'actualization',
            testName: 'actualization',
            icon: <EditIcon />,
            authority: WRITE_ACTUALIZATION,
        },
        {
            name: 'catalogs',
            testName: 'catalogs',
            icon: <EqualizerIcon />,
            authority: READ_CATALOGS,
        },
        {
            name: 'reports',
            testName: 'reports',
            icon: <AssessmentIcon />,
            authority: READ_REPORTS,
        },
    ];

    return (
        <div className="navigation-list">
            <List component="nav" dense={true}>
                {navItems.map(item => {
                    return (
                        <Authorized for={item.authority} key={'nav-item-' + item.name}>
                            <NavigationLink item={item} classes={classes} />
                        </Authorized>
                    );
                })}
            </List>
        </div>
    );
};

export default withStyles(styles as any)(NavigationList);

const NavigationLink = props => {
    const { item, classes } = props;
    const testProps = createLinkTestProps(item.testName);

    return <NavLink to={{ pathname: '/' + item.name }} {...testProps}>
        <ListItem
            disableGutters
            onClick={item.clickAction}
            className="navigation-item"
        >
            <ListItemIcon
                classes={{ root: classes.button }}
                className="navigation-icon"
            >
                {item.icon}
            </ListItemIcon>
            <ListItemText
                classes={{ primary: classes.button }}
                className="navigation-txt"
            >
                {item.name}
            </ListItemText>
        </ListItem>
    </NavLink>;
};
