import { ERRORS, ErrorType, IErrorAction } from '../actions/error';
import { CATALOG } from '../actions/catalogs';
import { CONFIGURATIONS } from '../actions/configurations';
import { MODEL } from '../actions/models';

export const error = (
    state = InitialErrorState,
    action: IErrorAction
): IErrorState => {
    switch (action.type) {
        case ERRORS.CLEAR:
        case CATALOG.CLEARERROR:
        case CONFIGURATIONS.CLEARERROR:
            return Object.assign({}, state, {
                error: null,
                type: ErrorType.NONE,
            });
        case ERRORS.ERROR:
        case CATALOG.ERROR:
        case CONFIGURATIONS.ERROR:
        case ERRORS.MESSAGE:
        case MODEL.UPDATE:
        case MODEL.ERROR:
        case MODEL.TRANSITION_WITH_ERROR:
        case CATALOG.UPDATE:
            if (action.error)
                return Object.assign({}, state, {
                    error: action.error,
                    type: action.errorType || ErrorType.REQUEST,
                });
            else return state;
        default:
            return state;
            break;
    }
};

export interface IErrorState {
    error?: string;
    type: ErrorType;
}

export const InitialErrorState: IErrorState = {
    type: ErrorType.NONE,
};
