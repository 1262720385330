import { ComponentType } from 'react';
import { contractCatagory, IConfiguration } from '../../data/model/configuration';
import { CustomerObligationsFormFactoryProps } from '../CustomerObligationsForm/CustomerObligationsProps';
import { msaPreventiveFactory } from './msa-preventive-factory';
import { defaultFactory } from './default-factory';
import { OtherTermsConditionsProps } from '../OtherTermsConditionsForm/OtherTermsConditionsProps';
import { StandardScopeProps } from '../StandardScopeFormSection/StandardScopeProps';
import { csaPreventiveFactory } from './csa-preventive-factory';
import { csaPreventiveCorrectiveFactory } from './csa-preventive-corrective-factory';
import { msaPreventiveCorrectiveFactory } from './msa-preventive-corrective-factory';
import { CoveredUnitsProps } from '../CoveredUnitsFormSection/CoveredUnitsProps';
import { AdditionalScopeProps } from '../AdditionalScopeFormSection/AdditionalScopeProps';
import { PaymentEscalationProps } from '../PaymentEscalationFormSection/PaymentEscalationProps';
import { MigrateStandardScopeDialogProps } from '../MigrateStandardScopeDialog/MigrateStandardScopeDialog';
import { Configuration } from '../../api/model-service/model-service';
import { AddUnitsFormProps } from '../AddUnitsForm/AddUnitInterfaces';
import { GuaranteeFormFactoryProps } from '../GuaranteesForm/GuaranteesInterfaces';

export function createConfigurationFormSectionFactory(configuration: Configuration): ConfigurationFormSectionFactory {
    switch (configuration.contractCategory) {
        case 'MSA_PREVENTIVE':
            return msaPreventiveFactory;
        case 'MSA_PREVENTIVE_AND_CORRECTIVE':
            return msaPreventiveCorrectiveFactory;
        case 'CSA_PREVENTIVE':
            return csaPreventiveFactory;
        case 'CSA_PREVENTIVE_AND_CORRECTIVE':
            return csaPreventiveCorrectiveFactory;
        default:
            return defaultFactory;
    }
}

export interface ConfigurationFormSectionFactory {
    CoveredUnits: ComponentType<CoveredUnitsProps>,
    MigrateStandardScopeDialog: ComponentType<MigrateStandardScopeDialogProps>,
    AddUnitsForm: ComponentType<AddUnitsFormProps>,
    StandardScope: ComponentType<StandardScopeProps>,
    AdditionalScope: ComponentType<AdditionalScopeProps>,
    CustomerObligations: ComponentType<CustomerObligationsFormFactoryProps>,
    Guarantees: ComponentType<GuaranteeFormFactoryProps>,
    PaymentEscalation: ComponentType<PaymentEscalationProps>,
    OtherTermsConditions: ComponentType<OtherTermsConditionsProps>,
}
