import { PricingFormSectionFactory } from './abstract-factory';
import LdBonus from '../Configuration/Financials/Pricing/Forms/LdBonus';
import { DefaultCaps } from '../CapsFormSection/DefaultCaps';
import { DefaultPricingContributionMargin } from '../PricingContributionMarginFormSection/DefaultPricingContributionMargin';

export const defaultFactory: PricingFormSectionFactory = {
    PricingContributionMargin: DefaultPricingContributionMargin,
    LdBonus,
    Caps: DefaultCaps,
};
