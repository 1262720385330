import React from 'react';
import { StyleRulesCallback, TableCell, withStyles } from '@material-ui/core';

const cellStyles: StyleRulesCallback = theme => ({
    head: {
        backgroundColor: 'white',
        color: theme.palette.common.black,
    },
});

export const ResultsTableHeaderCell = withStyles(cellStyles)(TableCell);