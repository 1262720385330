import React from 'react';
import MaterialTableCell, { TableCellProps as MaterialTableCellProps } from '@material-ui/core/TableCell';
import { createDataTestProps } from '../Utils/testProps';

interface ITableDataCellProps extends MaterialTableCellProps {
    testName: string,
}

export const TableDataCell: React.FunctionComponent<ITableDataCellProps> =
    ({ testName, ...remainingTableCellProps }) =>
        <MaterialTableCell
            {...remainingTableCellProps}
            {...createDataTestProps(testName)}
        />
    ;

export default TableDataCell;
