import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { api } from '../api';
import {
    IConfiguration,
    IConfigurationView,
    IConfigurationTerm,
    IConfigurationEngine,
} from '../model/configuration';

import { getConfigurationCopy } from '../../api/model-service/configuration'

import { defaultsDeep, find, findIndex, has, isEmpty, mergeWith } from 'lodash';
import { ICatalogAction } from './catalogs';
import {
    Activity,
    CatalogState,
    CatalogType,
    EngineInformation,
    ICatalog,
} from '../model/catalog';
import * as React from 'react';
import { cleanConfiguration } from '../reducers/configurations';
import { ErrorType, setMessage } from './error';
import {
    IFormModel,
    IServiceModel,
    serviceModelToFormModel,
} from '../model/model';
import { parseErrors } from '../utils/error';
import { getModelDetails, MODEL } from './models';
import { sunsetClause } from '../model/configuration_enums';
import {
    activtyTypeToBreakdownKey,
    ITableBreakdown,
} from '../model/configuration_pricing';
import { ILock } from '../model/lockversion';

const CREATE = 'CREATE';
const READ = 'READ';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const ERROR = 'ERROR';
const MERGE = 'MERGE';
const CLEAR = 'CLEAR';
const CLEARERROR = 'CLEARERROR';
const FORMINIT = 'FORMINIT';
const CLONE = 'CLONE';
const GETONE = `GETONE`;

export interface IConfigurationAction {
    configuration?: IConfiguration;
    type: string;
    activityCatalogs?: ICatalog[];
    activities?: Activity[];
    engineInformation?: EngineInformation[];
    activeModel?: IServiceModel | IFormModel;
    error?: string;
    errorType?: ErrorType;
    draft?: boolean;
}

export const CONFIGURATIONS = {
    CREATE: `${CREATE}_CONFIGURATION`,
    DELETE: `${DELETE}_CONFIGURATION`,
    ERROR: `${ERROR}_CONFIGURATION`,
    READ: `${READ}_CONFIGURATION`,
    UPDATE: `${UPDATE}_CONFIGURATION`,
    CLEARERROR: `${CLEARERROR}_CONFIGURATION`,
    MERGE: `${MERGE}_CONFIGURATION`,
    FORMINIT: `${FORMINIT}_CONFIGURATION`,
    CLONE: `${CLONE}_CONFIGURATION`,
    CLEAR: `${CLEAR}_CONFIGURATION`,
};

// Async Redux-Thunk action
export const deleteConfiguration: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (configuration: IConfiguration | IConfigurationView, modelID: number) => {
    return async (
        dispatch: Dispatch<IConfigurationAction>
    ): Promise<Action> => {
        try {
            await api.model.fetch(
                `/configurations/${configuration.id}`,
                {},
                { method: 'DELETE' }
            );
            const data = await api.model.fetchJSON(
                `/models/${modelID}`,
                null,
                {}
            );
            return dispatch({
                activeModel: serviceModelToFormModel(data),
                type: MODEL[GETONE],
            });
        } catch (e) {
            return dispatch({
                error: `Failed to delete configuration ${configuration.displayName}`,
                type: MODEL[ERROR],
            });
        }
    };
};

// Async Redux-Thunk action
export const saveConfiguration: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (payload: IConfiguration, modelID: number, isDraft: boolean) => {
    return async (
        dispatch: ThunkDispatch<{}, {}, IConfigurationAction>
    ): Promise<Action> => {
        try {
            if (payload.id) {
                const lock: ILock = await api.model.fetchJSON(
                    `/configurations/${payload.id}/lockVersions`
                );
                if (lock.lockVersion !== payload.lockVersion) {
                    return dispatch({
                        error: `The configuration you are updating has been edited by 
                        someone else. Please track your changes and reload to get the latest`,
                        errorType: ErrorType.REQUEST,
                        type: CONFIGURATIONS[ERROR],
                    });
                }
            }

            const url = isDraft
                ? `/models/${modelID}/configurations?draft=true`
                : `/models/${modelID}/configurations?draft=false`;
            let method = 'POST';
            let type = CONFIGURATIONS[CREATE];

            if (payload.id) {
                method = 'PUT';
                type = CONFIGURATIONS[UPDATE];
            }

            if (payload.unitStartCounter) {
                payload.unitStartCounter = parseInt(
                    payload.unitStartCounter.toString(),
                    10
                );
            }

            if (payload.unitEndCounter) {
                payload.unitEndCounter = parseInt(
                    payload.unitEndCounter.toString(),
                    10
                );
            }

            if (payload.expectedOperatingHoursPerYear) {
                payload.expectedOperatingHoursPerYear = parseInt(
                    payload.expectedOperatingHoursPerYear.toString(),
                    10
                );
            }

            if (payload.expectedStartsPerYear) {
                payload.expectedStartsPerYear = parseInt(
                    payload.expectedStartsPerYear.toString(),
                    10
                );
            }

            if (payload.engineInformation.detailsID) {
                delete payload.engineInformation.detailsID;
            }

            const term: IConfigurationTerm = {
                commercialOperationDate: payload.term.commercialOperationDate,
                effectiveContractStartDate:
                    payload.term.effectiveContractStartDate,
                sunsetClauseType: payload.term.sunsetClauseType,
            };
            if (payload.term.sunsetClauseType === sunsetClause.MAX_DURATION) {
                term.maxDuration = payload.term.maxDuration;
            } else if (
                payload.term.sunsetClauseType === sunsetClause.EXPIRATION_DATE
            ) {
                term.expirationDate = payload.term.expirationDate;
            }

            payload.term = term;

            let gaurenteeLength =
                payload.guarantees && payload.guarantees.length;
            for (gaurenteeLength; gaurenteeLength >= 0; gaurenteeLength--) {
                if (
                    payload.guarantees[gaurenteeLength] &&
                    payload.guarantees[gaurenteeLength].ld &&
                    !payload.guarantees[gaurenteeLength].ld.unit &&
                    !payload.guarantees[gaurenteeLength].ld.value
                ) {
                    delete payload.guarantees[gaurenteeLength].ld;
                }

                if (
                    payload.guarantees[gaurenteeLength] &&
                    payload.guarantees[gaurenteeLength].ldCap &&
                    !payload.guarantees[gaurenteeLength].ldCap.unit &&
                    !payload.guarantees[gaurenteeLength].ldCap.value
                ) {
                    delete payload.guarantees[gaurenteeLength].ldCap;
                }
            }

            if (payload.guaranteeCap) {
                if (
                    payload.guaranteeCap.annualLdCap &&
                    !payload.guaranteeCap.annualLdCap.unit &&
                    !payload.guaranteeCap.annualLdCap.value
                ) {
                    delete payload.guaranteeCap.annualLdCap;
                }

                if (
                    payload.guaranteeCap.contractLdCap &&
                    !payload.guaranteeCap.contractLdCap.unit &&
                    !payload.guaranteeCap.contractLdCap.value
                ) {
                    delete payload.guaranteeCap.contractLdCap;
                }
            }
            payload.standardScope = payload.standardScope.map(scope => {
                scope.fixedOccurrence = false;
                return scope;
            });

            // adjusted to ensure fixedOccurrence has value LQWWQ-346
            if (payload.additionalScope) {
                payload.additionalScope = payload.additionalScope.map(scope => {
                    if (!scope.fixedOccurrence) {
                        scope.fixedOccurrence = false;
                    }
                    return scope;
                });
            }

            if (payload.payment && payload.payment.escalations) {
                payload.payment.escalations = payload.payment.escalations.map(
                    escalation => {
                        delete escalation.open;

                        if (escalation.type === 'fixPrice') {
                            escalation.fixedPriceEscalationValue = parseFloat(
                                escalation.fixedPriceEscalationValue.toString()
                            );

                            delete escalation.indexRatio;
                            delete escalation.laborIndex;
                            delete escalation.materialIndex;
                            delete escalation.lubeOilIndex;
                            delete escalation.priceEscalationLowerLimit;
                            delete escalation.priceEscalationUpperLimit;
                        }

                        if (escalation.type === 'details') {
                            escalation.indexRatio.price = parseInt(
                                escalation.indexRatio.price.toString(),
                                10
                            );
                            escalation.indexRatio.labor = parseInt(
                                escalation.indexRatio.labor.toString(),
                                10
                            );
                            escalation.indexRatio.material = parseInt(
                                escalation.indexRatio.material.toString(),
                                10
                            );
                            escalation.indexRatio.lubeOil = parseInt(
                                escalation.indexRatio.lubeOil.toString(),
                                10
                            );
                            if (
                                escalation.laborIndex &&
                                !isNaN(escalation.laborIndex.value)
                            ) {
                                escalation.laborIndex.value = parseInt(
                                    escalation.laborIndex.value.toString(),
                                    10
                                );
                            }

                            if (
                                escalation.materialIndex &&
                                !isNaN(escalation.materialIndex.value)
                            ) {
                                escalation.materialIndex.value = parseInt(
                                    escalation.materialIndex.value.toString(),
                                    10
                                );
                            }

                            if (
                                escalation.lubeOilIndex &&
                                !isNaN(escalation.lubeOilIndex.value)
                            ) {
                                escalation.lubeOilIndex.value = parseInt(
                                    escalation.lubeOilIndex.value.toString(),
                                    10
                                );
                            }

                            if (
                                escalation.priceEscalationLowerLimit &&
                                !isNaN(escalation.priceEscalationLowerLimit)
                            ) {
                                escalation.priceEscalationLowerLimit = Number.parseFloat(
                                    escalation.priceEscalationLowerLimit.toString());
                            } else {
                                delete escalation.priceEscalationLowerLimit;
                            }

                            if (
                                escalation.priceEscalationUpperLimit &&
                                !isNaN(escalation.priceEscalationUpperLimit)
                            ) {
                                escalation.priceEscalationUpperLimit = Number.parseFloat(
                                    escalation.priceEscalationUpperLimit.toString());
                            } else {
                                delete escalation.priceEscalationUpperLimit;
                            }

                            delete escalation.fixedPriceEscalationValue;
                        }

                        return escalation;
                    }
                );
            }

            if (payload.pricing) {
                if (Array.isArray(payload.pricing.fixedBillings)) {
                    payload.pricing.fixedBillings = payload.pricing.fixedBillings.map(
                        item => {
                            delete item.tableData;
                            item.rate = parseFloat(item.rate.toString());
                            return item;
                        }
                    );
                }

                if (Array.isArray(payload.pricing.milestoneBillings)) {
                    payload.pricing.milestoneBillings = payload.pricing.milestoneBillings.map(
                        item => {
                            delete item.tableData;
                            item.rate = parseFloat(item.rate.toString());
                            item.trigger = parseInt(
                                item.trigger.toString(),
                                10
                            );
                            return item;
                        }
                    );
                }

                if (Array.isArray(payload.pricing.usageBillings)) {
                    payload.pricing.usageBillings = payload.pricing.usageBillings.map(
                        item => {
                            delete item.tableData;
                            item.rate = parseFloat(item.rate.toString());
                            item.billingStartCounter = parseInt(
                                item.billingStartCounter.toString(),
                                10
                            );
                            item.billingEndCounter = parseFloat(
                                item.billingEndCounter.toString()
                            );
                            return item;
                        }
                    );
                }

                if (Array.isArray(payload.pricing.lds)) {
                    payload.pricing.lds = payload.pricing.lds.map(item => {
                        delete item.tableData;
                        item.amount = parseFloat(item.amount.toString());
                        return item;
                    });
                }

                if (Array.isArray(payload.pricing.bonuses)) {
                    payload.pricing.bonuses = payload.pricing.bonuses.map(
                        item => {
                            delete item.tableData;
                            item.amount = parseFloat(item.amount.toString());
                            return item;
                        }
                    );
                }

                if (Array.isArray(payload.pricing.monetaryCaps)) {
                    payload.pricing.monetaryCaps = payload.pricing.monetaryCaps.map(
                        item => {
                            delete item.tableData;
                            item.amount = parseFloat(item.amount.toString());
                            return item;
                        }
                    );
                }

                if (Array.isArray(payload.pricing.quantitativeCaps)) {
                    payload.pricing.quantitativeCaps = payload.pricing.quantitativeCaps.map(
                        item => {
                            delete item.tableData;
                            item.quantity = parseInt(
                                item.quantity.toString(),
                                10
                            );
                            return item;
                        }
                    );
                }
            }

            const errorCheck = await api.model
                .fetchJSON(url, payload, {
                    method,
                    headers: { 'Content-Type': 'application/json' },
                })
                .then(
                    json => {
                        return json;
                    },
                    error => {
                        return error.message;
                    }
                );

            if (errorCheck.length || !errorCheck) {
                return dispatch({
                    error: parseErrors(
                        errorCheck,
                        'An error without a server message has occured. Please contact a system administrator.'
                    ),
                    type: CONFIGURATIONS[ERROR],
                    errorType: ErrorType.REQUEST,
                });
            }

            // when we have a config, lets reload the model
            if (modelID) {
                dispatch(getModelDetails(modelID));
            }

            return dispatch({
                configuration: errorCheck,
                type,
            });
        } catch (e) {
            return dispatch({
                error: parseErrors(e),
                type: CONFIGURATIONS[ERROR],
                errorType: ErrorType.REQUEST,
            });
        }
    };
};

export const cloneConfig: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (
    configurationID: number,
    modelID: number,
    primaryConfiguration: boolean = false
) => {
    return async (
        dispatch: ThunkDispatch<{}, {}, IConfigurationAction>
    ): Promise<Action> => {
        try {
            const destModel = serviceModelToFormModel(
                await api.model.fetchJSON(`/models/${modelID}`, {}, {})
            );

            if (destModel.modelVersion !== 'IN_PROGRESS' && destModel.modelVersion !== 'OTR') {
                return dispatch(
                    setMessage('The destination Model must either be In-Progress or OTR.')
                );
            }

            const newConfig = await getConfigurationCopy(configurationID);

            if (isEmpty(destModel.configurationOverviews)) {
                primaryConfiguration = true;
            }

            newConfig.primaryConfiguration = primaryConfiguration;
            newConfig.name += ' Clone';

            if (destModel.modelVersion !== 'OTR' && destModel.configurationOverviews.find(c => c.id === configurationID) === undefined) {
                newConfig.units.forEach(unit => {
                    unit.unitReadCounter = undefined;
                    unit.unitReadDate = undefined;
                });
            }

            let errorMsg = null;
            const errorCheck = await api.model
                .fetchJSON(
                    `/models/${modelID}/configurations?draft=${
                        newConfig.state === 'COMPLETED' ? 'false' : 'true'
                    }`,
                    newConfig,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then(
                    json => {
                        return json;
                    },
                    error => {
                        errorMsg = error;
                        return error;
                    }
                );

            if (errorMsg) {
                let errorMsgTxt = null;
                if (Array.isArray(errorMsg)) {
                    errorMsgTxt = errorMsg[0].message;
                } else {
                    errorMsgTxt = errorMsg;
                }

                if (
                    errorMsgTxt ===
                    'There has to be at least one primary configuration.'
                ) {
                    return dispatch(
                        cloneConfig(configurationID, modelID, true)
                    );
                }

                return dispatch(setMessage(errorMsgTxt));
            }

            return dispatch({
                type: CONFIGURATIONS[CLONE],
                activeModel: destModel,
                error: errorMsg,
            });
        } catch (e) {
            dispatch({
                error: Array.isArray(e.message)
                    ? e.message.join(', ')
                    : e.message,
                type: CONFIGURATIONS[ERROR],
                errorType: ErrorType.REQUEST,
            });
        }
    };
};

export const updateTargetPricing: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (current: IConfiguration, updateField, updateValue) => {
    return async (
        dispatch: ThunkDispatch<{}, {}, IConfigurationAction>
    ): Promise<Action> => {
        try {
            if (updateField === 'userTargetCm') {
                updateValue = updateValue / 100.0;
            }

            const pricing = await api.model
                .fetchJSON(
                    `/configurations/${current.id}/pricingtarget`,
                    { [updateField]: updateValue },
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then(
                    json => {
                        return json;
                    },
                    error => {
                        return error;
                    }
                );

            if (current.pricing == null) {
                current.pricing = {};
            }

            pricing.userTargetCm = pricing.userTargetCm * 100;

            return dispatch({
                pricingTarget: pricing,
                configuration: current,
                type: CONFIGURATIONS[MERGE],
            });
        } catch (e) {
            dispatch({
                error: Array.isArray(e.message)
                    ? e.message.join(', ')
                    : e.message,
                type: CONFIGURATIONS[ERROR],
            });
        }
    };
};

export const clearConfiguration: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (current: IConfiguration, step: IConfiguration) => {
    return async (
        dispatch: Dispatch<IConfigurationAction>
    ): Promise<Action> => {
        return dispatch({
            type: CONFIGURATIONS[CLEAR],
        });
    };
};

export const mergeStep: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (current: IConfiguration, step: IConfiguration) => {
    return async (
        dispatch: Dispatch<IConfigurationAction>
    ): Promise<Action> => {
        const action: IConfigurationAction = {
            // TODO Remove the call to `defaultsDeep`.
            //  Calling `defaultsDeep` merges array elements e.g. from `standardScope`; the code only worked
            //  by accident until now because `current` was not treated like an immutable  object.
            configuration: isFixedStep(step)
                ? mergeWith({}, current, step, (destValue, srcValue) =>
                    (srcValue instanceof Array) ? srcValue : undefined)
                : defaultsDeep(step, current),
            type: CONFIGURATIONS[MERGE],
        };
        const configuration = action.configuration;

        if (
            (configuration.activityCatalogId > 0 &&
                configuration.activityCatalogId !== current.activityCatalogId) ||
            configuration.engineInformation.engineType ||
            configuration.engineInformation.maintenanceSection ||
            configuration.engineInformation.engineVersion
        ) {
            if (
                configuration.activityCatalogId > 0 &&
                configuration.activityCatalogId !== current.activityCatalogId
            ) {
                const { engineInformation } = await api.catalog.fetchJSON(
                    '/engine-information',
                    {
                        activityCatalogId: configuration.activityCatalogId,
                        equipmentCatalogId: configuration.equipmentCatalogId,
                    },
                    {}
                );

                action.engineInformation = engineInformation;
                const newEngine = getMatchingEngine(current.engineInformation, engineInformation);
                const nonCatalogInfo = copyNonCatalogEngineInfo(current.engineInformation);

                configuration.engineInformation = newEngine || nonCatalogInfo;
            }

            const engineDetailsID =
                configuration.engineInformation.detailsID ||
                (action.engineInformation &&
                    action.engineInformation.length > 0 &&
                    action.engineInformation[0].id) ||
                null;

            if (
                engineDetailsID &&
                (configuration.engineInformation.engineType !==
                    current.engineInformation.engineType ||
                    configuration.engineInformation.maintenanceSection !==
                        current.engineInformation.maintenanceSection ||
                    configuration.engineInformation.engineVersion !==
                        current.engineInformation.engineVersion)
            ) {
                action.activities = await api.catalog.fetchJSON(
                    `/activities/${engineDetailsID}`,
                    {},
                    {}
                );
            }
        }

        return dispatch(action);
    };
};

function copyNonCatalogEngineInfo(engineInformation: IConfigurationEngine): IConfigurationEngine {
    if (!engineInformation) {
        return {};
    }

    const oldValues: IConfigurationEngine = {
        gasType: engineInformation.gasType,
        id: engineInformation.id,
        unitType: engineInformation.unitType,
        productProgram: engineInformation.productProgram
    }

    return oldValues;
}

export const getEnginesAndActivities: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, ICatalogAction>
> = (
    configurationID,
    configuration: IConfiguration,
    activeOnly: boolean
) => {
    return async (dispatch: Dispatch<ICatalogAction>): Promise<Action> => {
        try {
            const query = {
                state: [
                    CatalogState.DEFAULT,
                    CatalogState.ACTIVE,
                    CatalogState.INACTIVE,
                ],
            };
            if (activeOnly) {
                query.state = [CatalogState.DEFAULT, CatalogState.ACTIVE];
            }

            if (configurationID) {
                configuration = await api.model.fetchJSON(
                    `/configurations/${configurationID}`,
                    null,
                    {}
                );
            } else {
                configuration = cleanConfiguration();
            }

            const equipmentCatalogs = await api.catalog.fetchJSON('/equipment-catalogs', query, {});
            const activeEquipmentCatalog = getActiveCatalog(configuration.equipmentCatalogId, equipmentCatalogs);

            const activityCatalogs = await api.catalog.fetchJSON('/activity-catalogs', query, {});
            const activeActivityCatalog = getActiveCatalog(configuration.activityCatalogId, activityCatalogs);

            const { engineInformation } = await api.catalog.fetchJSON(
                '/engine-information',
                {
                    activityCatalogId: activeActivityCatalog.id,
                    equipmentCatalogId: activeEquipmentCatalog.id,
                },
                {}
            );

            // lets search the engineInformation to get our engine ID
            const engineSearch = {
                version: configuration.engineInformation.engineVersion,
                maintenanceSection:
                    configuration.engineInformation.maintenanceSection,
                type: configuration.engineInformation.engineType,
            };

            const engineDetails = find(engineInformation, engineSearch);

            // if we have an engine then populate the activities otherwise leave it empty
            let activityDetails = [];
            if (engineDetails) {
                activityDetails = await api.catalog.fetchJSON(
                    `/activities/${engineDetails.id}`,
                    {},
                    {}
                );
            }

            if (engineInformation.length > 0) {
                let defaultEngineInfo = find(engineInformation, {
                    type: configuration.engineInformation.engineType,
                });
                if (!defaultEngineInfo) {
                    defaultEngineInfo = engineInformation[0];
                }

                configuration.activityCatalogId =
                    configuration.activityCatalogId || activeActivityCatalog.id;
                configuration.equipmentCatalogId =
                    configuration.equipmentCatalogId || activeEquipmentCatalog.id;

                return dispatch({
                    configuration,
                    engineInformation,
                    activityCatalogs,
                    activities: activityDetails,
                    type: CONFIGURATIONS[FORMINIT],
                });
            }
        } catch (e) {
            return dispatch({
                error: e.message,
                type: CONFIGURATIONS[ERROR],
            });
        }
    };
};

function getActiveCatalog(id: number, catalogs: ICatalog[]): ICatalog {
    const activeCatalog = find(catalogs, { id }) || getDefaultCatalog(catalogs);
    return activeCatalog;
}

function getDefaultCatalog(catalogs: ICatalog[]): ICatalog {
    return find<ICatalog>(catalogs, {
        state: CatalogState.DEFAULT,
    });
}

export const getOne: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IConfigurationAction>
> = (configurationID: number) => {
    return async (
        dispatch: Dispatch<IConfigurationAction>
    ): Promise<Action> => {
        try {
            const data = await api.model.fetchJSON(
                `/configurations/${configurationID}`,
                null,
                {}
            );

            return dispatch({
                configuration: data,
                type: CONFIGURATIONS[READ],
            });
        } catch (e) {
            return dispatch({
                error: e.message,
                type: CONFIGURATIONS[ERROR],
            });
        }
    };
};

const breakdownTypes = ['scope', 'service'];

export const prepareEventBreakdown = (
    model: IFormModel,
    conf: IConfiguration,
    frequencies: number[]
): ITableBreakdown => {
    const breakdown: ITableBreakdown = {
        frequencies: [],
        tableData: [],
        subtotals: {},
    };

    if (conf.state !== 'COMPLETED') {
        return breakdown;
    }

    conf.standardScope.map(activity => {
        const activityKey = activtyTypeToBreakdownKey(activity.type);

        const { frequency, scope, costPerEvent } = activity;
        if (breakdown.frequencies.indexOf(frequency) === -1) {
            breakdown.frequencies.push(frequency);
        }

        if (frequencies.indexOf(frequency) !== -1) {
            let entryPos = findIndex(breakdown.tableData, { scope });

            if (entryPos === -1) {
                entryPos = breakdown.tableData.length;
                breakdown.tableData.push({ scope });
            }

            if (!breakdown.tableData[entryPos][activityKey]) {
                breakdown.tableData[entryPos][activityKey] = 0;
            }

            breakdown.tableData[entryPos][activityKey] += costPerEvent;

            if (!breakdown.subtotals[activityKey]) {
                breakdown.subtotals[activityKey] = 0;
            }

            breakdown.subtotals[activityKey] += costPerEvent;
        }
    });

    conf.additionalScope.map(activity => {
        const activityKey = activtyTypeToBreakdownKey(activity.type);

        const { frequency, scope, costPerEvent } = activity;
        if (breakdown.frequencies.indexOf(frequency) === -1) {
            breakdown.frequencies.push(frequency);
        }

        if (frequencies.indexOf(frequency) !== -1) {
            let entryPos = findIndex(breakdown.tableData, { scope });

            if (entryPos === -1) {
                entryPos = breakdown.tableData.length;
                breakdown.tableData.push({ scope });
            }

            if (!breakdown.tableData[entryPos][activityKey]) {
                breakdown.tableData[entryPos][activityKey] = 0;
            }

            breakdown.tableData[entryPos][activityKey] += costPerEvent;

            if (!breakdown.subtotals[activityKey]) {
                breakdown.subtotals[activityKey] = 0;
            }

            breakdown.subtotals[activityKey] += costPerEvent;
        }
    });

    return breakdown;
};

export const prepareBreakdown = (
    conf: IConfiguration,
    model: IFormModel,
    breakdownValue: string,
    coveredUnit: number
): ITableBreakdown => {
    const breakdowns: ITableBreakdown = { tableData: [], subtotals: {} };

    if (conf.state !== 'COMPLETED') {
        return breakdowns;
    }
    conf.standardScope.map(activity => {
        const activityKey = activtyTypeToBreakdownKey(activity.type);

        const unitActivity =
            find(activity.perUnits, {
                unit: { id: conf.units[coveredUnit].id },
            }) || activity;
        if (!breakdowns.subtotals[activityKey]) {
            breakdowns.subtotals[activityKey] = 0;
        }

        breakdowns.subtotals[activityKey] += unitActivity[breakdownValue];

        breakdownTypes.map(type => {
            if (!breakdowns[type]) {
                breakdowns[type] = [];
            }

            const breakdownIndex = findIndex(breakdowns[type], {
                title: activity[type],
            });

            let breakdown = { title: activity[type], secondary: [] };
            if (breakdownIndex !== -1) {
                breakdown = breakdowns[type][breakdownIndex];
            }

            const secondaryType = type === 'scope' ? 'service' : 'scope';
            const secondaryIndex = findIndex(breakdown.secondary, {
                title: activity[secondaryType],
            });

            let secondary = { title: activity[secondaryType] };
            if (secondaryIndex !== -1) {
                secondary =
                    breakdowns[type][breakdownIndex].secondary[secondaryIndex];
            }

            if (!breakdown[activityKey]) {
                breakdown[activityKey] = 0;
            }

            if (!secondary[activityKey]) {
                secondary[activityKey] = 0;
            }

            breakdown[activityKey] += unitActivity[breakdownValue];
            secondary[activityKey] += unitActivity[breakdownValue];

            if (secondaryIndex === -1) {
                breakdown.secondary.push(secondary);
            } else {
                breakdown.secondary[secondaryIndex] = secondary;
            }

            if (breakdownIndex === -1) {
                breakdowns[type].push(breakdown);
            } else {
                breakdowns[type][breakdownIndex] = breakdown;
            }
        });
    });

    conf.additionalScope.map(activity => {
        const activityKey = activtyTypeToBreakdownKey(activity.type);

        const unitActivity =
            find(activity.perUnits, {
                unit: { id: conf.units[coveredUnit].id },
            }) || activity;

        if (!breakdowns.subtotals[activityKey]) {
            breakdowns.subtotals[activityKey] = 0;
        }

        breakdowns.subtotals[activityKey] += unitActivity[breakdownValue];

        breakdownTypes.map(type => {
            if (!breakdowns[type]) {
                breakdowns[type] = [];
            }

            const breakdownIndex = findIndex(breakdowns[type], {
                title: activity[type],
            });
            let breakdown = { title: activity[type], secondary: [] };
            if (breakdownIndex !== -1) {
                breakdown = breakdowns[type][breakdownIndex];
            }

            const secondaryType = type === 'scope' ? 'service' : 'scope';
            const secondaryIndex = findIndex(breakdown.secondary, {
                title: activity[secondaryType],
            });

            let secondary = { title: activity[secondaryType] };

            if (secondaryIndex !== -1) {
                secondary =
                    breakdowns[type][breakdownIndex].secondary[secondaryIndex];
            }

            if (!breakdown[activityKey]) {
                breakdown[activityKey] = 0;
            }

            if (!secondary[activityKey]) {
                secondary[activityKey] = 0;
            }

            breakdown[activityKey] += unitActivity[breakdownValue];
            secondary[activityKey] += unitActivity[breakdownValue];

            if (secondaryIndex === -1) {
                breakdown.secondary.push(secondary);
            } else {
                breakdown.secondary[secondaryIndex] = secondary;
            }

            if (breakdownIndex === -1) {
                breakdowns[type].push(breakdown);
            } else {
                breakdowns[type][breakdownIndex] = breakdown;
            }
        });
    });

    return breakdowns;
};

function isFixedStep(step: IConfiguration): boolean {
    return has(step, 'standardScope') ||
        has(step, 'additionalScope') ||
        has(step, 'siteLevelScope') ||
        has(step, 'pricing.referencePriceList') ||
        has(step, 'pricing.discountMatrix');
}


function getMatchingEngine (configurationEngine: IConfigurationEngine, engineInfo: EngineInformation[]) : IConfigurationEngine {
    if (!configurationEngine) {
        return null;
    }
    
    const catalogEngine = engineInfo.find(engine => engine.type === configurationEngine.engineType &&
            engine.version === configurationEngine.engineVersion &&
            engine.maintenanceSection === configurationEngine.maintenanceSection)

    if (!catalogEngine) {
        return null;
    }
    const newEngine: IConfigurationEngine = {
        ...configurationEngine,
        maintenanceSchedule: catalogEngine.maintenanceSchedule,
        equipmentModel: catalogEngine.equipmentModel,
        electricalPower: catalogEngine.electricalPower,
        mechanicalPower: catalogEngine.mechanicalPower,
        oilVolume: catalogEngine.oilVolume,
        detailsID: catalogEngine.id,
    };
    
    return newEngine;
}
