import React, { FunctionComponent, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { IFormModel } from '../../data/model/model';
import { AuthorizationConsumer } from './context';
import { Authority } from '../../data/model/user';
import { Model } from '../../api/model-service/model-service';

export const CaseAuthorizedToUpdateModel: FunctionComponent<ICaseAuthorizedToUpdateModelProps> =
    props =>
        <AuthorizationConsumer>
            {
                authorities => {
                    const model = props.model;
                    const authorized = isModel(model)
                        ? isAuthorizedToUpdateModel(authorities, model)
                        : isAuthorizedToUpdateIFormModel(authorities, model);
                    return props.children(authorized);
                }
            }
        </AuthorizationConsumer>;

export interface ICaseAuthorizedToUpdateModelProps {
    model: IFormModel | Model,
    children: (authorized: boolean) => ReactNode,
}

function isModel(model: IFormModel | Model): model is Model {
    return 'version' in model;
}

function isAuthorizedToUpdateIFormModel(authorities: Authority[], model: IFormModel): boolean {
    if (isEmpty(model)) {
        return false;
    }

    return !model.readOnly;
}

function isAuthorizedToUpdateModel(authorities: Authority[], model: Model): boolean {
    return !model.readOnly;
}
