import React, { FC } from 'react';
import { Button } from '../Buttons/Button';
import { StyledComponentProps, StyleRulesCallback, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Configuration } from '../../api/model-service/model-service';

const CompleteConfirmation0: FC<CompleteConfirmationProps & StyledComponentProps> =
    props =>
        <>
            <Typography variant='h6'>
                Your configuration, '{props.configuration.displayName}' is complete!
            </Typography>
            <Button
                testName='viewConfiguration'
                className={props.classes.button}
                color='secondary'
                component={buttonProps =>
                    <Link {...buttonProps} to={props.configurationDetailsUrl}/>
                }
            >
                View Configuration
            </Button>

            <Button
                testName='configurePricing'
                className={props.classes.button}
                color='primary'
                component={buttonProps =>
                    <Link {...buttonProps} to={props.configurePricingUrl}/>
                }
            >
                Configure Pricing
            </Button>
        </>;

interface CompleteConfirmationProps {
    configuration: Configuration,
    configurationDetailsUrl: string,
    configurePricingUrl: string,
}

const styles: StyleRulesCallback = theme => ({
    button: {
        marginRight: theme.spacing.unit * 2,
    },
});

export const CompleteConfirmation = withStyles(styles)(CompleteConfirmation0);
