import React, { FC } from 'react';
import { Dialog, DialogProps } from '../Dialogs/MyacDialog';
import { EditStandardScopeDialogForm, EditStandardScopeDialogFormProps } from './EditStandardScopeDialogForm';

export const EditStandardScopeDialog: FC<EditStandardScopeDialogProps> =
    props =>
        <Dialog
            testName='configureScope'
            renderOpenButton={props.renderOpenButton}
        >
            {close =>
                <EditStandardScopeDialogForm
                    allActivities={props.allActivities}
                    initialActivities={props.initialActivities}
                    initialScope={props.initialScope}
                    onSubmit={activities => {
                        close();
                        props.onSubmit(activities);
                    }}
                    onCancel={close}
                />
            }
        </Dialog>;

interface EditStandardScopeDialogProps extends ManagedEditFormProps {
    renderOpenButton: DialogProps['renderOpenButton']
}

type ManagedEditFormProps = Omit<EditStandardScopeDialogFormProps, 'onCancel'>;
