import * as React from 'react';

import { hasValue, iv } from '../utils/form';

import InProgressIcon from '@material-ui/icons/Schedule';
import CompleteIcon from '@material-ui/icons/CheckCircle';
import { PageRequest } from './pageRequest';
import { merge } from 'lodash';
import { IConfigurationView } from './configuration';

import _ from 'lodash';

export class ModelPageRequest extends PageRequest {

    private sortBy: ModelPageSort = ModelPageSort.createdOn;
    private hideEnded: boolean;
    private version: modelVersion;

    public setHideEnded(hideEnded: boolean): void {
        this.hideEnded = hideEnded;
        this.setPage(0);
    }

    public setVersion(version: modelVersion): void {
        this.version = version;
        this.setPage(0);
    }

    public getHideEnded(): boolean {
        return this.hideEnded;
    }

    public setSortBy(sortBy: ModelPageSort): void {
        this.sortBy = sortBy;
        super.setPage(0);
    }

    public getQuery(): {} {
        const params = merge(super.getQuery(), {
            sortBy: this.sortBy,
            hideEnded: this.hideEnded,
            version: this.version,
        });
        return params;
    }
}

export const AcceptableModelScanMimes = [
    'application/*',
    'image/*',
    'text/*',
];

export enum ModelPageSort {
    createdOn,
    createdBy,
    name,
    modifiedOn,
    modifiedBy,
}

export interface IModelReference {
    paperContractId?: string;
    oracleContractsNumber: string;
    contractManager: string;
    id?: number;
}

export interface IDBFile {
    fileName: string;
    fileType: string;
    id?: number;
}

export interface IAmendment {
    modelId:number;
    opportunityNumber:string;
    amendmentOpportunityNumber:string;
    version:modelVersion;
    actualEndDate:string;

}

export interface IModelAmendmentInformation {
    parentModel:IAmendment;
    siblings:IAmendment[];
    children:IAmendment[];

    hasAmendment():boolean;
}

export interface IServiceModel {
    opportunityNumber: string;
    projectName: string;
    version: modelVersion;
    description: string;
    customer: IModelCustomer;
    offering: IModelOffering;
    region: IModelRegion;
    cap: IModelCap;
    attachments: IDBFile[];
    reference: IModelReference;
    additionalScope?: any[];
    configurationOverviews?: IConfigurationView[];
    actualEndDate?: string;
    createdBy?: string;
    createdOn?: string;
    id?: number;
    modifiedBy?: string;
    modifiedOn?: string;
    financialPerformance?: any;
    lockVersion: number;
    parentModelId:number;
    readOnly:boolean;
    authorizedToCreateAmendment:boolean;
    authorizedToCloneFromModel: boolean;
    pendingActualization: boolean;
    amendmentOpportunityNumber:string;
    amendmentInformation:IModelAmendmentInformation;
}

export interface IModelAmendmentDetails {
    history: IAmendment[];
    deltaTotalModelCost?: number;
    deltaTotalModelBillingsConsidLDB?: number;
    deltaTotalModelCMConsidLDB?: number;
    deltaTotalModelCMPercentage?: number;
    changedAmendmentTCs?: any;//TODO
    changedParentTCs?: any;//TODO
}

export interface ITableModelView {
    id: number;
    opportunityNumber: string;
    serviceRegion: string;
    projectName: string;
    customerName: string;
    customerNumber: number;
    customerDunsNumber: number;
    version: string;
    modifiedOn: string;
    modifiedBy: string;
    createdOn: string;
    createdBy: string;
}

export interface ICounterReadingDeleteApproval {
    configurationId: number;
    unitSerialNumbers: string[];
}

export interface IFormModel {
    salesforceOpportunity: string;
    attachments: IDBFile[];
    projectName: string;
    modelVersion: modelVersion;
    description: string;
    customerName: string;
    customerNumber: number;
    customerPastDues: number;
    offeringType: contractType;
    nuWarrantDurationMonths: number;
    competitors: any[];
    financialPerformance: any;
    onOffshoreConstellation: boolean;
    dealInLineWithChannelPolicy: boolean;
    correctiveCapPerEvent: boolean;
    correctiveCorrectiveCapPerYear: boolean;
    correctiveCapOverTotalContractTerm: boolean;
    additionalScope?: any[];
    configurationOverviews?: IConfigurationView[];
    oracleContractNumber: string;
    contractManager: string;
    paperContractId: string;
    actualEndDate?: string;
    createdBy?: string;
    createdOn?: string;
    id?: number;
    modifiedBy?: string;
    modifiedOn?: string;
    referenceId: number;
    lockVersion: number;
    parentModelId:number;
    readOnly:boolean;
    counterReadingDeleteApprovals?: ICounterReadingDeleteApproval[];

    amendmentOpportunityNumber:string;
    amendmentInformation:IModelAmendmentInformation;
    pendingActualization: boolean;
    authorizedToCreateAmendment:boolean;
    authorizedToCloneFromModel:boolean;

    acceptableTransitionErrors?: string[],
}

export const formModelToServiceModel = (
    formInput: IFormModel
): IServiceModel => {
    const reference: IModelReference = {
        contractManager: formInput.contractManager,
        paperContractId: formInput.paperContractId,
        oracleContractsNumber: formInput.oracleContractNumber,
    };

    if (hasValue(formInput.referenceId)) {
        reference.id = formInput.referenceId;
    }

    return {
        opportunityNumber: formInput.salesforceOpportunity,
        projectName: formInput.projectName,
        version: formInput.modelVersion,
        attachments: formInput.attachments,
        description: formInput.description,
        customer: {
            name: formInput.customerName,
            dunsNumber: formInput.customerNumber,
            pastDues: formInput.customerPastDues,
        },
        offering: {
            nuWarrantyDuration: formInput.nuWarrantDurationMonths,
            type: formInput.offeringType,
        },
        region: {
            competitors: formInput.competitors,
            isInlineWithChannelPolicy: formInput.dealInLineWithChannelPolicy,
            isOnOffshoreConstellation: formInput.onOffshoreConstellation,
        },
        cap: {
            overTotalContractTerm: formInput.correctiveCapOverTotalContractTerm,
            perEvent: formInput.correctiveCapPerEvent,
            perYear: formInput.correctiveCorrectiveCapPerYear,
        },
        reference,
        additionalScope: formInput.additionalScope || [],
        configurationOverviews: formInput.configurationOverviews || [],
        actualEndDate: formInput.actualEndDate,
        createdBy: formInput.createdBy || null,
        createdOn: formInput.createdOn || null,
        id: formInput.id || null,
        modifiedBy: formInput.modifiedBy || null,
        modifiedOn: formInput.modifiedOn || null,
        lockVersion: formInput.lockVersion,
        parentModelId: formInput.parentModelId,
        readOnly:formInput.readOnly,
        authorizedToCreateAmendment:formInput.authorizedToCreateAmendment,
        authorizedToCloneFromModel:formInput.authorizedToCloneFromModel,
        amendmentOpportunityNumber:formInput.amendmentOpportunityNumber,
        amendmentInformation:formInput.amendmentInformation,
        pendingActualization:formInput.pendingActualization,
    };
};

export const serviceModelToFormModel = (
    serviceInput: IServiceModel
): IFormModel => {
    let rtn = {
        salesforceOpportunity: serviceInput.opportunityNumber,
        projectName: serviceInput.projectName,
        attachments: serviceInput.attachments,
        modelVersion: serviceInput.version,
        description: serviceInput.description,
        customerName: serviceInput.customer.name,
        customerNumber: serviceInput.customer.dunsNumber,
        customerPastDues: serviceInput.customer.pastDues,
        offeringType: serviceInput.offering.type,
        nuWarrantDurationMonths: serviceInput.offering.nuWarrantyDuration,
        competitors:
            serviceInput.region.competitors &&
            serviceInput.region.competitors.length > 0
                ? serviceInput.region.competitors.map(function(competitor, _) {
                      return competitor.name;
                  })
                : [],
        onOffshoreConstellation: serviceInput.region.isOnOffshoreConstellation,
        dealInLineWithChannelPolicy:
            serviceInput.region.isInlineWithChannelPolicy,
        correctiveCapPerEvent: serviceInput.cap.perEvent,
        correctiveCorrectiveCapPerYear: serviceInput.cap.perYear,
        correctiveCapOverTotalContractTerm:
            serviceInput.cap.overTotalContractTerm,
        additionalScope: serviceInput.additionalScope || [],
        configurationOverviews: serviceInput.configurationOverviews || [],
        actualEndDate: serviceInput.actualEndDate,
        createdBy: serviceInput.createdBy || null,
        createdOn: serviceInput.createdOn || null,
        id: serviceInput.id || null,
        modifiedBy: serviceInput.modifiedBy || null,
        modifiedOn: serviceInput.modifiedOn || null,
        financialPerformance: serviceInput.financialPerformance || null,
        oracleContractNumber:
            (serviceInput.reference &&
                serviceInput.reference.oracleContractsNumber) ||
            '',
        contractManager:
            (serviceInput.reference &&
                serviceInput.reference.contractManager) ||
            '',
        paperContractId:
            (serviceInput.reference &&
                serviceInput.reference.paperContractId) ||
            '',
        referenceId:
            (serviceInput.reference && serviceInput.reference.id) || null,
        lockVersion: serviceInput.lockVersion,

        amendmentInformation:serviceInput.amendmentInformation,
        amendmentOpportunityNumber:serviceInput.amendmentOpportunityNumber,
        parentModelId:serviceInput.parentModelId,
        readOnly:serviceInput.readOnly,
        authorizedToCreateAmendment:serviceInput.authorizedToCreateAmendment,
        authorizedToCloneFromModel:serviceInput.authorizedToCloneFromModel,
        pendingActualization:serviceInput.pendingActualization,
    };

    rtn.amendmentInformation.hasAmendment = function(){

        let hasAmendmentInfo = rtn.amendmentInformation.parentModel != null ||
            rtn.amendmentInformation.children.length > 0 ||
            rtn.amendmentInformation.siblings.length > 0;

        return hasAmendmentInfo;
    }

    return rtn;
};

export enum contractType {
    FIRST_TIME_CONTRACT = 'FIRST_TIME_CONTRACT',
    RENEWAL = 'RENEWAL',
    AMENDMENT = 'AMENDMENT',
}

export const contractTypeInputs = [
    iv(contractType.FIRST_TIME_CONTRACT, 'First-time Contract'),
    iv(contractType.RENEWAL, 'Renewal'),
    iv(contractType.AMENDMENT, 'Amendment'),
];

export enum modelVersion {
    IN_PROGRESS = 'IN_PROGRESS',
    AS_SOLD = 'AS_SOLD',
    HANDOVER = 'HANDOVER',
    OTR = 'OTR',
    ENDED = 'ENDED',
}

export const modelProgressIcon = (version: modelVersion) => {
    switch (version) {
        case modelVersion.IN_PROGRESS:
            return <InProgressIcon />;
        default:
            return <CompleteIcon />;
    }
};

export const modelVersionInputs = [
    iv(modelVersion.IN_PROGRESS, 'In-Progress', true),
    iv(modelVersion.AS_SOLD, 'As-Sold'),
    iv(modelVersion.HANDOVER, 'Handover'),
    iv(modelVersion.OTR, 'OTR'),
    iv(modelVersion.ENDED, 'Ended'),
];

export interface IModelCustomer {
    id?: number;
    name: string;
    dunsNumber?: number;
    pastDues: number;
}

export interface IModelOffering {
    nuWarrantyDuration: number;
    type: contractType;
    id?: number;
}

export interface IModelRegion {
    id?: number;
    competitors: IModelCompetitor[];
    isInlineWithChannelPolicy: boolean;
    isOnOffshoreConstellation: boolean;
}

export interface IModelCompetitor {
    id?: number;
    name: string;
}

export interface IModelCap {
    id?: number;
    overTotalContractTerm: boolean;
    perEvent: boolean;
    perYear: boolean;
}

export const formatCompetitors = competitors => {
    return competitors.map(v => {
        return { name: v.value };
    });
};

export const advanceModelStage = (version: modelVersion): modelVersion => {
    switch (version) {
        case modelVersion.IN_PROGRESS:
            return modelVersion.AS_SOLD;
        case modelVersion.AS_SOLD:
            return modelVersion.HANDOVER;
        case modelVersion.HANDOVER:
            return modelVersion.OTR;
        case modelVersion.OTR:
            return modelVersion.ENDED;
        default:
            return version;
    }
};
