import React, { Component, ReactNode } from 'react';
import ViewTitle from '../ViewTitle/ViewTitle';
import {
    Grid,
    LinearProgress,
    Paper,
    StyledComponentProps,
    StyleRulesCallback,
    TableBody,
    TableCell,
    TableHead,
    TableRow as TableHeaderRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import { TableDataRow } from '../Tables/TableDataRow';
import { Link } from 'react-router-dom';
import { fromServiceDateTime } from '../../data/utils/date';
import { History } from 'history';
import { Table } from '../Tables/Table';
import { TableDataCell } from '../Tables/TableDataCell';
import { ActualizationOverview } from '../../api/model-service/actualization';
import { State } from '../../data/store';
import { connect } from 'react-redux';
import { AppThunk } from '../../state/app-thunk';
import { getActualizationOverviews } from '../../state/actualization/getActualizationOverviews';
import { Button } from '../Buttons/Button';
import { Add } from '@material-ui/icons';
import { isEmpty, sortBy } from 'lodash';

class ActualizationOverviewPage0 extends Component<ComponentProps> {

    public componentDidMount(): void {
        this.props.onGetActualizationOverviews();
    }

    public render(): ReactNode {
        return (
            <>
                <ViewTitle title='Ongoing Actualizations'>
                    <Button
                        testName='newActualization'
                        color='primary'
                        className={this.props.classes.titleBtn}
                        component={props => <Link to='/actualization/new' {...props}/>}
                    >
                        New Actualization <Add/>
                    </Button>
                </ViewTitle>

                <Paper square classes={{ root: this.props.classes.paper }}>
                    {
                        this.props.loading
                            ? <Grid item xs={12}>
                                <LinearProgress className={this.props.classes.progressBar}/>
                            </Grid>
                            : this.renderTable()
                    }
                </Paper>
            </>
        );
    }

    private renderTable(): ReactNode {
        const actualizations = sortBy(this.props.actualizationList, [ 'id' ]);
        return (
            <Table testName='actualizationTable'>
                <TableHead>
                    <TableHeaderRow>
                        <TableCell>Actualization Id</TableCell>
                        <TableCell>User Name</TableCell>
                        <TableCell>Process Started</TableCell>
                    </TableHeaderRow>
                </TableHead>
                <TableBody>
                    {isEmpty(actualizations) && this.getNoDataRow()}
                    {actualizations.map(actualization => {
                        return (
                            <TableDataRow
                                testName='actualization'
                                testId={`${actualization.id}`}
                                key={actualization.id}
                            >
                                <TableDataCell testName='actualizationId'>
                                    <Link
                                        to={{
                                            pathname: '/actualization/view/' + actualization.id,
                                        }}
                                    >
                                        {actualization.id}
                                    </Link>
                                </TableDataCell>
                                <TableDataCell testName='userName'>
                                    {actualization.createdBy}
                                </TableDataCell>
                                <TableDataCell testName='startDate'>
                                    {fromServiceDateTime(actualization.createdOn).toLocaleDateString()}
                                </TableDataCell>
                            </TableDataRow>
                        );
                    })}
                </TableBody>
            </Table>
        )
    }

    private getNoDataRow(): ReactNode {
        return (
            <TableDataRow testName='noData' testId=''>
                <TableCell colSpan={3} align='center'>
                    <Typography variant='body2'>
                        There are no ongoing actualizations
                    </Typography>
                </TableCell>
            </TableDataRow>
        );
    }
}

type ComponentProps = Omit<ActionProps, 'onGetActualizationOverviews'> & ActualizationOverviewPageProps &
    StateProps & StyledComponentProps;

interface ActualizationOverviewPageProps {
    history: History,
    onGetActualizationOverviews: () => Promise<void>,
}

interface StateProps {
    loading: boolean,
    actualizationList: ActualizationOverview[],
}

interface ActionProps {
    onGetActualizationOverviews: () => AppThunk,
}

const styles: StyleRulesCallback = theme => ({
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        marginBottom: '33px',
        position: 'relative',
    },
    progressBar: {
        marginTop: theme.spacing.unit,
    },
    titleBtn: {
        marginLeft: '20px',
    },
});

export const ActualizationOverviewPage = withStyles(styles)(ActualizationOverviewPage0);

const mapDispatchToProps: ActionProps = {
    onGetActualizationOverviews: getActualizationOverviews,
};

function mapStateToProps(state: State): StateProps {
    const overviewsState = state.REFACTORED.actualizationOverviews;
    return {
        loading: overviewsState.loading,
        actualizationList: overviewsState.data,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActualizationOverviewPage);
