import React, { FC } from 'react';
import MaterialTable from '../../../../Tables/MaterialTable';
import { findIndex } from 'lodash';
import { hasValue } from "../../../../../data/utils/form";
import { LdBonusProps } from '../../../../LdBonusFormSection/LdBonusProps';

const isValidLDB = (ldb) : boolean => {
    return hasValue(ldb) && hasValue(ldb.frequency) && hasValue(ldb.amount);
};

const LdBonus: FC<LdBonusProps> = props => {
    const { validate, pricing, mergeConfig } = props;

    return (
        <React.Fragment>
            <div className="pt-3 pb-3 material-table">
                <MaterialTable
                    tableTestName="LDs"
                    rowTestName="LD"
                    addRowTestName="addLD"
                    editRowTestName="editLD"
                    title="LDs"
                    options={{
                        paging: false,
                        search: false,
                        showTitle: true,
                        toolbar: true,
                        actionsColumnIndex: -1,
                        toolbarButtonAlignment: 'left',
                    }}
                    columns={[
                        { title: 'Title', field: 'title' },
                        {
                            title: 'Amount € ',
                            field: 'amount',
                            type: 'numeric',
                        },
                        {
                            title: 'Frequency',
                            field: 'frequency',
                            lookup: {
                                YEARLY: 'Annually',
                                QUARTERLY: 'Quarter',
                                MONTHLY: 'Month',
                            },
                        },
                    ]}
                    data={pricing.lds}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (!isValidLDB(newData) || +newData.amount >= 0) {
                                        reject();
                                        return;
                                    }
                                    if (!pricing.lds) {
                                        pricing.lds = [];
                                    }
                                    pricing.lds.push(newData);
                                    mergeConfig(
                                        'pricing.lds',
                                        pricing.lds,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (!isValidLDB(newData) || +newData.amount >= 0) {
                                        reject();
                                        return;
                                    }
                                    const index = findIndex(
                                        pricing.lds,
                                        oldData
                                    );
                                    pricing.lds[index] = newData;
                                    mergeConfig(
                                        'pricing.lds',
                                        pricing.lds,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const index = findIndex(
                                        pricing.lds,
                                        oldData
                                    );
                                    pricing.lds.splice(index, 1);
                                    mergeConfig(
                                        'pricing.lds',
                                        pricing.lds,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                    }}
                />
            </div>
            <div className="pt-3 pb-3 material-table">
                <MaterialTable
                    tableTestName="Bonuses"
                    rowTestName="Bonus"
                    addRowTestName="addBonus"
                    editRowTestName="editBonus"
                    title="Bonus"
                    options={{
                        paging: false,
                        search: false,
                        showTitle: true,
                        toolbar: true,
                        actionsColumnIndex: -1,
                        toolbarButtonAlignment: 'left',
                    }}
                    columns={[
                        { title: 'Title', field: 'title' },
                        {
                            title: 'Amount € ',
                            field: 'amount',
                            type: 'numeric',
                        },
                        {
                            title: 'Frequency',
                            field: 'frequency',
                            lookup: {
                                YEARLY: 'Annually',
                                QUARTERLY: 'Quarter',
                                MONTHLY: 'Month',
                            },
                        },
                    ]}
                    data={pricing.bonuses}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (!isValidLDB(newData) || +newData.amount <= 0) {
                                        reject();
                                        return;
                                    }
                                    if (!pricing.bonuses) {
                                        pricing.bonuses = [];
                                    }
                                    pricing.bonuses.push(newData);
                                    mergeConfig(
                                        'pricing.bonuses',
                                        pricing.bonuses,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (!isValidLDB(newData) || +newData.amount <= 0) {
                                        reject();
                                        return;
                                    }
                                    const index = findIndex(
                                        pricing.bonuses,
                                        oldData
                                    );
                                    pricing.bonuses[index] = newData;
                                    mergeConfig(
                                        'pricing.bonuses',
                                        pricing.bonuses,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const index = findIndex(
                                        pricing.bonuses,
                                        oldData
                                    );
                                    pricing.bonuses.splice(index, 1);
                                    mergeConfig(
                                        'pricing.bonuses',
                                        pricing.bonuses,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default LdBonus;
