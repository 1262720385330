import { ProviderProps } from 'react';
import { connect } from 'react-redux';
import { AuthorizationContextValue, AuthorizationProvider } from './context';
import { get } from 'lodash';
import { IUserInfo } from '../../data/model/user';
import { hasValue } from '../../data/utils/form';

export const ReduxAuthorizationProvider = connect(mapStateToProps)(AuthorizationProvider);

function mapStateToProps(state): MappedProps {
    const authorities = getCurrentUserAuthorities(state);
    return {
        value: authorities,
    };
}

type MappedProps = Pick<AuthorizationProviderProps, 'value'>;
type AuthorizationProviderProps = ProviderProps<AuthorizationContextValue>;

function getCurrentUserAuthorities(state): AuthorizationContextValue {
    const currentUser = getCurrentUser(state);
    if (!currentUserExists(currentUser)) {
        return [];
    } else {
        return currentUser.authorities;
    }
}

function getCurrentUser(state): IUserInfo {
    return get(state, 'user.currentUser', null);
}

function currentUserExists(currentUser: IUserInfo): boolean {
    return hasValue(currentUser);
}

