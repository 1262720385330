import { uniqWith } from 'lodash';
import { IScope } from '../../data/model/configuration';
import { ScopeTypes } from '../../data/model/configuration_enums';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';

export const deduplicateAttributes = (activities: CatalogActivity[]): CatalogActivity [] => {
    return uniqWith(activities, (a1, a2) =>
        a1.scope === a2.scope &&
        a1.service === a2.service &&
        a1.frequency === a2.frequency &&
        a1.type === a2.type,
    );
};

export const isGenSetSparkPlugs = (activity: CatalogActivity | IScope): boolean => {
    return activity.scope === 'Gen Set_Spark Plugs';
};

export const isCatalyst = (activity: CatalogActivity | IScope): boolean => {
    return /^SCR\/Oxi Catalyst_/.test(activity.scope);
};

export const isGreenhouseCO2 = (activity: CatalogActivity | IScope): boolean => {
    return /^Greenhouse CO2 Set_/.test(activity.scope);
};

export const isLubeOil = (activity: CatalogActivity | IScope): boolean => {
    return activity.scope === 'Lube Oil';
};

export const isNotMajorOverhaul = (activity: CatalogActivity): boolean => {
    const isMajorOverhaul = /^Major Overhaul/.test(activity.service);
    return !isMajorOverhaul;
};

export const isGenSetActivity = (activity: CatalogActivity): boolean => {
    return /^Gen Set/.test(activity.scope);
};

export const isNotUnplanned = (activity: CatalogActivity): boolean => {
    const isUnplanned = /^Unplanned/.test(activity.service);
    return !isUnplanned;
};

export const isEngineOrSparkPlugsActivity = ({ scope }: CatalogActivity): boolean => {
    return scope === 'Gen Set_Engine' || scope === 'Gen Set_Spark Plugs';
};

export const isPartsActivity = (activity: CatalogActivity): boolean => {
    return activity.type === ScopeTypes.INNIOParts;
};
