import { Model } from './model-service';
import { fetch } from '../fetch';
import { expectOK } from '../utils/expect-ok';
import { handleRequestError } from '../utils/request-error';
import { expectJSON } from '../utils/expect-json';

export function getModel(request: GetModelRequest): Promise<Model> {
    const url = `${MODEL_ROOT}/models/${request.id}?skipFinancialData=${request.skipFinancialData}`;

    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetModelRequest {
    id: number,
    skipFinancialData: boolean,
}