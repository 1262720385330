import { AppThunk } from '../app-thunk';
import { startLoading, successGetCatalogsResponse } from './slice';
import { CatalogType, getAllCatalogsByType } from '../../api/catalog-service/catalog-overview';
import { errorResponse } from '../add-catalog/slice';
import { reportCatalogError } from '../error-actions';

export const getAllCatalogs = (type: CatalogType): AppThunk => dispatch => {
    dispatch(startLoading());
    return getAllCatalogsByType(type)
        .then(catalogs => {
            dispatch(successGetCatalogsResponse(catalogs));
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportCatalogError(err));
        })
};
