import * as React from 'react';
import './Branding.scss';

const logoStyleWhite = {
    fill: '#ffffff',
};

const logoStyleGreen = {
    fill: '#51b046',
    display: 'inline-block',
};

const logoStyleOrange = {
    fill: '#ef773c',
    display: 'inline-block',
};

const Branding = () => {
    return (
        <div className="innio-branding">
            <svg
                id="inni"
                xmlns="http://www.w3.org/2000/svg"
                width="91.75"
                height="32"
                viewBox="0 0 91.75 32"
            >
                <path
                    d="M7.78,30.76H0V1.24H7.78ZM89.43,1.24H81.65V30.76h7.78Zm-76.86,0V30.76h7.78V18.91L32.21,30.76H42.1ZM42.1,13.55V1.24H29.79ZM76.53,30.76V1.24H68.75V13.09L56.9,1.24H47ZM47,18.45V30.76H59.31Z"
                    style={logoStyleWhite}
                />
            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
            >
                <path
                    d="M16.87,7a9,9,0,0,1,8.34,12.4l5.17,5.18A16,16,0,0,0,8.3,2.49l5.17,5.17A9,9,0,0,1,16.87,7Z"
                    style={logoStyleOrange}
                />
                <path
                    d="M16.87,25A9,9,0,0,1,8.54,12.61L3.35,7.43A16,16,0,0,0,25.43,29.51l-5.17-5.17A9,9,0,0,1,16.87,25Z"
                    style={logoStyleGreen}
                />
            </svg>
            <span className="txt">Multi-Year Agreement Configurator</span>
        </div>
    );
};

export default Branding;
