import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../Buttons/Button';
import Dialog from '../Dialogs/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '../Buttons/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const DialogTitle = withStyles(theme => ({
    root: {
        margin: 0,
    },
    closeButton: {
        top: 8,
        right: 8,
        position: 'absolute',
    },
}))((props: any) => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose && (
                <IconButton
                    testName="close"
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
    },
}))(MuiDialogActions);

const Confirm = props => {
    if (!props.open) {
        return null;
    }
    return (
        <div>
            <Dialog
                testName={props.testName}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.handleClose}
                >
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>{props.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button testName="cancel" onClick={props.handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button testName="confirm" onClick={props.action} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Confirm;
