import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { CapUnit, GuaranteeType, GuaranteeUnit, LdBonusUnit } from '../../api/model-service/model-service';

export const annualCapUnitOptions: IFormOption<CapUnit>[] = [
    formOption('PERCENTAG', '% of annual billings'),
    formOption('EURO', '€'),
];

export const contractCapUnit: IFormOption<CapUnit>[] = [
    formOption('PERCENTAG', '% of total billings'),
    formOption('EURO', '€'),
];

export const guaranteeTypeOptions: IFormOption<GuaranteeType>[] = [
    formOption('UNIT_RELIABILITY', 'Unit Reliability'),
    formOption('UNIT_AVAILABILITY', 'Unit Availability'),
    formOption('SITE_RELIABILITY', 'Site Reliability'),
    formOption('SITE_AVAILABILITY', 'Site Availability'),
    formOption('STARTING_RELIABILITY', 'Starting Reliability'),
    formOption('ELECTRICAL_POWER', 'Electrical Power'),
    formOption('THERMAL_POWER', 'Thermal Power'),
    formOption('ELECTRICAL_EFFICIENCY', 'Electrical Efficiency'),
    formOption('THERMAL_EFFICIENCY', 'Thermal Efficiency'),
    formOption('TOTAL_EFFICIENCY', 'Total Efficiency'),
    formOption('LUBE_OIL_CONSUMPTION', 'Lube Oil Consumption'),
    formOption('UREA_CONSUMPTION', 'Urea Consumption'),
    formOption('EMISSIONS', 'Emissions'),
    formOption('REACTION_TIME_INITIATE', 'Reaction Time Initiate corr. Action'),
    formOption('REACTION_TIME_PERSONNEL', 'Reaction Time Personnel on site'),
    formOption('OTHER', 'Other'),
];

export const guaranteeUnitOptions: IFormOption<GuaranteeUnit>[] = [
    formOption('PERCENTAGE', '%'),
    formOption('OPHS', 'Ophs'),
    formOption('HOURS', 'Hours'),
    formOption('MWHRS', 'MWhrs'),
    formOption('LITERS', 'Liters'),
    formOption('NOX', 'NOx'),
];

export const defaultLdBonusUnitOptions: IFormOption<LdBonusUnit>[] = [
    formOption('EURO_PER_OPH', '€ per oph deviation'),
    formOption('EURO_PER_PERCENTAG', '€ per % deviation'),
    formOption('EURO_PER_ZERO_POINT_ONE_PERCENTAG', '€ per 0,1% deviation'),
    formOption('PERCENTAGE_ANNUAL_PER_OPH', '% of annual billings per oph deviation'),
    formOption('PERCENTAGE_ANNUAL_PER_PERCENTAG', '% of annual billings per % deviation'),
    formOption('PERCENTAGE_ANNUAL_PER_ZERO_POINT_ONE_PERCENTAG', '% of annual billings per 0,1% deviation'),
    formOption('EURO_PER_OTHER_GUARANTEED_UNIT', '€ per other guaranteed unit deviation'),
    formOption('PERCENTAGE_ANNUAL_PER_OTHER_GUARANTEED_UNIT', '% of annual billings per other guaranteed unit deviation'),
];

export const msaPreventiveLdBonusUnitOptions = [
    ...defaultLdBonusUnitOptions,
    formOption('EURO_PER_DAY_OF_DEVIATION', '€ per day of deviation'),
];

export const msaPreventiveGuaranteeTypeOptions = [
    ...guaranteeTypeOptions,
    formOption('PARTS_ON_TIME_DELIVERY', 'Parts on time delivery'),
];

export const msaPreventiveGuaranteeUnitOptions = [
    ...guaranteeUnitOptions,
    formOption('DAYS', 'Days'),
];