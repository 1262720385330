import React from 'react';
import { MTableBodyRow } from 'material-table';
import { createRowTestProps } from '../Utils/testProps';

interface ITableDataRowProps {
    testName: string,
    testId: string,
}

const MTableDataRow: React.FunctionComponent<ITableDataRowProps> =
    ({ testName, testId, ...remainingTableRowProps }) =>
        <MTableBodyRow
            {...remainingTableRowProps}
            {...createRowTestProps(testName, testId)}
        />
    ;

export default MTableDataRow;
