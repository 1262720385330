import React, { FC } from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { Authority } from '../../data/model/user';
import { Authorized } from '../authorization';

export const ServiceTableHeader: FC<ServiceTableHeaderProps> =
    ({ showCost }) =>
        <TableHead>
            <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Scope</TableCell>
                <TableCell>Activity Type</TableCell>
                <TableCell>Additional Attribute</TableCell>
                <TableCell align="right">Frequency</TableCell>
                <TableCell align="right">Offset</TableCell>
                <TableCell align="right">Occurrence</TableCell>
                {showCost
                    ? <TableCell align="right">Cost</TableCell>
                    : null
                }
                <Authorized for={Authority.WRITE_BILL_OF_SERVICES}>
                    <TableCell/>
                </Authorized>
            </TableRow>
        </TableHead>;

export interface ServiceTableHeaderProps {
    showCost: boolean,
}
