import * as moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

export const toServiceDate = (date: Date): string => {
    return moment(date).format(dateFormat);
};

export const fromServiceDateTime = (date: string): Date => {
    return new Date(Date.parse(date));
};

export const serviceToMoment = (date: string): moment.Moment => {
    return moment(date, dateFormat);
};

export function parseIsoDate(date: string): moment.Moment {
    return moment(date, moment.ISO_8601);
}

export function isoToDate(isoDate: string): string {
    if (!isoDate) {
        return isoDate;
    }

    return parseIsoDate(isoDate).format(dateFormat);
}

export function dateToIso(date: string): string {
    if (!date) {
        return date;
    }

    return moment(date, dateFormat).toISOString();
}

export function isoToLocaleDate(isoDate: string): string {
    if (!isoDate) {
        return isoDate;
    }

    return parseIsoDate(isoDate).format('l');
}
