import React from 'react';
import MaterialDialog, { DialogProps as MaterialDialogProps } from '@material-ui/core/Dialog';
import { createDialogTestProps } from '../Utils/testProps';

interface IDialogProps extends MaterialDialogProps {
    testName: string,
}

export const Dialog: React.FunctionComponent<IDialogProps> = ({ testName, ...materialDialogProps }) =>
    <MaterialDialog
        {...materialDialogProps}
        {...createDialogTestProps(testName)} />
    ;

export default Dialog;
