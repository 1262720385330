import React, { FunctionComponent, ReactNode } from 'react';
import { CaseAuthorizedToWriteCatalogs } from './CaseAuthorizedToWriteCatalogs';

export const AuthorizedToWriteCatalogs: FunctionComponent<IAuthorizedToWriteCatalogsProps> =
    props =>
        <CaseAuthorizedToWriteCatalogs>
            {
                authorized => authorized
                    ? props.children
                    : null
            }
        </CaseAuthorizedToWriteCatalogs>

export interface IAuthorizedToWriteCatalogsProps {
    children: ReactNode,
}
