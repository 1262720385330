import * as React from 'react';
import './UserMenu.scss';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {connect} from "react-redux";
import {IUserState} from "../../../data/reducers/user";
import { logout } from '../../../data/auth';
import { createButtonTestProps, createDataTestProps } from '../../Utils/testProps';
import { IUserInfo } from '../../../data/model/user';
import { hasValue } from '../../../data/utils/form';

const styles = (theme: any) => ({
    userListItem: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '5px',
            paddingRight: '5px',
        },
        paddingLeft: 7,
    },
    userListItemText: {
        color: 'white',
        fontSize: '11px',
    },
    collapseBg: {
        backgroundColor: 'rgba(0,0,0,0.2)',
    },
    iconColor: {
        color: 'white',
    },
});

interface IProps {
    classes: {
        userListItem: string;
        userListItemText: string;
        collapseBg: string;
        iconColor: string;
        userRoot: string;
    };
    user : IUserState;
}
interface IState {
    open: boolean;
    loggedIn: boolean;
}
class UserMenu extends React.Component<IProps, IState> {
    public state: IState = {
        open: false,
        loggedIn: true,
    };

    public handleClick(): void {
        this.setState(state => ({ open: !state.open }));
    }

    public render(): React.ReactElement {
        const { classes, user } = this.props;

        const toggleLogoutTestProps = createButtonTestProps('toggleLogout');

        const userName = this.getUserName(user);
        const userNameTestProps = createDataTestProps('currentUser');
        const authoritiesStr = this.getAuthoritiesStr(user);

        const logoutTestProps = createButtonTestProps('logout');

        return (
            <List className="user-list">
                <ListItem
                    disableGutters
                    button
                    onClick={() => this.handleClick()}
                    classes={{ root: classes.userListItem }}
                    {...toggleLogoutTestProps}
                >
                    <ListItemText
                        primary={userName}
                        classes={{ primary: classes.userListItemText }}
                        data-authorities={authoritiesStr}
                        {...userNameTestProps}
                    />
                    {this.state.open ? (
                        <ExpandLess className={classes.iconColor} />
                    ) : (
                        <ExpandMore className={classes.iconColor} />
                    )}
                </ListItem>
                <Collapse
                    in={this.state.open}
                    timeout="auto"
                    unmountOnExit
                    classes={{ container: classes.collapseBg }}
                >
                    <List disablePadding>
                        <ListItem
                            button
                            onClick={() => logout()}
                            {...logoutTestProps}
                        >
                            <ListItemText
                                primary="Log Out"
                                classes={{ primary: classes.userListItemText }}
                            />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        );
    }

    private getUserName(user: IUserState): string {
        return this.hasCurrentUser(user)
            ? user.currentUser.user_name
            : '';
    }

    private getAuthoritiesStr(user: IUserState): string {
        return this.getAuthorities(user).join(',');
    }

    private getAuthorities(user: IUserState): string[] {
        return this.hasCurrentUser(user)
            ? user.currentUser.authorities
            : [];
    }

    private hasCurrentUser(user: IUserState): boolean {
        return hasValue(user) && hasValue(user.currentUser);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        {}
    )(UserMenu)
);
