import React, { FC } from 'react';
import { Chip, StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Cancel } from '@material-ui/icons';
import { MultiValueProps } from 'react-select';
import { OptionType } from './OptionType';
import classNames from 'classnames';

// Our version of material-ui doesn't support these `disabled` Chip prop
const MultiValue0: FC<MultiValueProps<OptionType> & StyledComponentProps> =
    props => {
        const classes = props.classes;
        const disabled = props.selectProps.isDisabled;

        const cls = classNames(classes.chip, {
            [classes.focused]: props.isFocused,
            [classes.disabled]: disabled,
        });

        const handleDelete = !disabled
            ? props.removeProps.onClick
            : null;

        return (
            <Chip
                className={cls}
                label={props.children}
                onDelete={handleDelete}
                deleteIcon={<Cancel/>}
            />
        );
    };

const styles: StyleRulesCallback = theme => ({
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    focused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light'
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            0.08,
        ),
    },
    disabled: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
});

export const MultiValue = withStyles(styles)(MultiValue0);
