import { AppThunk } from '../app-thunk';
import { reportCatalogError } from '../error-actions';
import {
    getMigrationRecipe as getMigrationRecipeFromApi,
    GetMigrationRecipeRequest,
    MigrationRecipe,
} from '../../api/catalog-service/activityMigration';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';

export const getMigrationRecipe = (engineId: number, activities: CatalogActivity[]): AppThunk<MigrationRecipe> =>
    dispatch => {
        const request: GetMigrationRecipeRequest = {
            engineId, activities,
        };
        return getMigrationRecipeFromApi(request)
            .catch(err => {
                dispatch(reportCatalogError(err));
                throw err;
            })
    };
