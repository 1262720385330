import React, { FC } from 'react';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import { StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import ComparisonTable from './ComparisonTable';
import { ExtendedActualizationResult } from './ResultsTable';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { ActivityCatalog } from '../../api/catalog-service/activityCatalogs';

const ComparisonRow0: FC<ComponentProps> = ({actualizationResult, regionCatalogs, activityCatalogs, classes}) =>
    <TableDataRow classes={{root: classes.tableRow}} testName='comparisonDetails' testId={'' + actualizationResult.id}>
        <TableDataCell testName='comparisonDetails' colSpan={6}>
            <ComparisonTable regionCatalogs={regionCatalogs}
                             activityCatalogs={activityCatalogs}
                             actualizationResult={actualizationResult}/>
        </TableDataCell>
    </TableDataRow>;

type ComponentProps = ComparisonRowProps & StyledComponentProps;
export interface ComparisonRowProps {
    actualizationResult: ExtendedActualizationResult,
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalog[],
}

const style: StyleRulesCallback = theme => ({
    tableRow: {
        background: 'rgb(255, 252, 248)',
    },
});

export const ComparisonRow = withStyles(style)(ComparisonRow0);
