import React, { FC } from 'react';
import { MigrateStandardScopeDialog, MigrateStandardScopeDialogProps } from './MigrateStandardScopeDialog';
import { MigrationAction } from './migration-actions';
import { Activity } from '../../data/model/catalog';

export const MsaMigrateStandardScopeDialog: FC<MigrateStandardScopeDialogProps> =
    ({ onSubmit, ...remainingProps }) => {
        const handleSubmitWithZeroValue = (actions: MigrationAction[]) => {
            const actionsWithZeroValues = actions.map(setActivityValueToZero);
            onSubmit(actionsWithZeroValues);
        };

        return <MigrateStandardScopeDialog onSubmit={handleSubmitWithZeroValue} {...remainingProps}/>;
    };

function setActivityValueToZero(action: MigrationAction): MigrationAction {
    if (action.type !== 'REPLACE') {
        return action;
    }

    return {
        ...action,
        replacement: setValueToZero(action.replacement),
    };
}

function setValueToZero(activity: Activity): Activity {
    return {
        ...activity,
        value: 0,
        icValue: 0,
    };
}
