import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import {
    ValidationRuleCallback,
    ValidatorForm as BaseValidatorForm,
    ValidatorFormProps as BaseValidatorFormProps,
} from 'react-form-validator-core';
import { createFormTestProps } from '../Utils/testProps';

interface IValidatorFormProps extends BaseValidatorFormProps {
    testName: string,
}

interface IValidatorForm extends ForwardRefExoticComponent<IValidatorFormProps & RefAttributes<BaseValidatorForm>> {
    addValidationRule(name: string, callback: ValidationRuleCallback): void;
    isFormValid(isValid: boolean): boolean;
}

const ValidatorForm = forwardRef<BaseValidatorForm, IValidatorFormProps>((props, ref) => {
    const { testName, ...remainingValidatorFormProps } = props;
    return (
        <BaseValidatorForm
            ref={ref}
            {...remainingValidatorFormProps}
            {...createFormTestProps(testName)}
        />
    );
}) as IValidatorForm;

ValidatorForm.addValidationRule = BaseValidatorForm.addValidationRule;

export default ValidatorForm;
