import React, { cloneElement, FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';

export const BillingStartDate: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        // TODO hide if billing interval is not yearly?
        const billingInterval = inputValue('payment.billingInterval', formikProps);

        const name = 'payment.annualBillingStartDate';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Billing Start Date'
                    type='date'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage || '(Annual Billing Only)'}
                    margin='dense'
                    fullWidth
                    required={billingInterval === 'ANNUALLY'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: '9999-12-31',
                    }}
                />,
        });
    };
