import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';

export const ContractGoverningLaw: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.contractGoverningLaw';
        const errorMessage = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='Contract Governing Law'
                value={inputValue(name, formikProps)}
                options={options}
                disabled={disabled}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='dense'
                fullWidth
                required
            />
        );
    };

const options: IFormOption[] = [
    formOption('Austria', 'Austria'),
    formOption('Belgium', 'Belgium'),
    formOption('Czech', 'Czech'),
    formOption('Denmark', 'Denmark'),
    formOption('Germany', 'Germany'),
    formOption('Hungary', 'Hungary'),
    formOption('Italy', 'Italy'),
    formOption('Netherlands', 'Netherlands'),
    formOption('Norway', 'Norway'),
    formOption('Portugal', 'Portugal'),
    formOption('Russia', 'Russia'),
    formOption('Saudi Arabia', 'Saudi Arabia'),
    formOption('Singapore', 'Singapore'),
    formOption('South Africa', 'South Africa'),
    formOption('Spain', 'Spain'),
    formOption('Switzerland', 'Switzerland'),
    formOption('United Kingdom', 'United Kingdom'),
    formOption('United States', 'United States'),
];
