import React, { Component, ReactNode } from 'react';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import Dialog from '../Dialogs/Dialog';
import { DownloadLink } from '../links/DownloadLink';
import Button from '../Buttons/Button';
import {
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    Typography,
} from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { AcceptableCatalogUploadMimes } from '../../data/model/catalog';
import { DropzoneArea } from 'material-ui-dropzone';
import { RadioGroupField } from '../RadioGroupField/RadioGroupField';
import { IFormOption } from '../../data/utils/form';
import { CatalogState, CatalogType, CreateCatalogRequest } from '../../api/catalog-service/catalog-overview';
import { TextField } from '../TextField/TextField';
import { SelectField } from '../SelectField/SelectField';
import * as yup from 'yup';
import { SubmitButton } from '../Form/SubmitButton';
import { Form } from '../Form/Form';
import { isEmpty } from 'lodash';
import { clearError } from '../../state/add-catalog/slice';
import { addCatalog } from '../../state/add-catalog/add-catalog';
import { AppThunk } from '../../state/app-thunk';
import { connect } from 'react-redux';

const catalogTypeOptions: Array<IFormOption<CatalogType>> = [
    { value: 'REGION', label: 'Region' },
    { value: 'ACTIVITY', label: 'Activity' },
    { value: 'EQUIPMENT', label: 'Equipment' },
];

const catalogStateOptions: Array<IFormOption<CatalogState>> = [
    { value: 'DEFAULT', label: 'Default' },
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' },
];

class AddCatalogDialog0 extends Component<AddCatalogDialogProps & StyledComponentProps, DialogState> {
    public state = {
        open: false,
    };

    public render() {
        const initialValues: CreateCatalogRequest = {
            name: '',
            state: 'ACTIVE',
            type: this.props.defaultType,
            file: null,
        };
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={formValuesSchema}
                onSubmit={(...args) => this.handleSubmit(...args)}
                enableReinitialize
            >
                {(formikProps: FormikProps<CreateCatalogRequest>) =>
                    <>
                        {this.props.renderOpenButton(() => this.open())}
                        <Dialog
                            testName='createCatalog'
                            open={this.state.open}
                            onClose={() => this.handleCancel(formikProps)}
                            aria-labelledby='Edit Catalog'
                            maxWidth='md'
                            fullWidth
                        >
                            <Form testName='catalog' onSubmit={formikProps.handleSubmit}>
                                <DialogTitle id='form-dialog-title'>
                                    <Grid
                                        container
                                        justify='space-between'
                                        alignItems='center'
                                    >
                                        New Catalog
                                        <Typography variant='subtitle2'>
                                            <DownloadLink href={getTemplateDownloadUrl(formikProps.values.type)}>
                                                {clickHandler =>
                                                    <Button
                                                        testName='catalogTemplate'
                                                        color='primary'
                                                        onClick={clickHandler}
                                                        data-link={getTemplateDownloadUrl(formikProps.values.type)}
                                                    >
                                                        <CloudDownload
                                                            className={this.props.classes.leftIcon}
                                                        />
                                                        Catalog Template
                                                    </Button>
                                                }
                                            </DownloadLink>
                                        </Typography>
                                    </Grid>
                                </DialogTitle>

                                <DialogContent classes={{ root: 'dialogContent' }}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={6}>
                                            <DropzoneArea
                                                acceptedFiles={AcceptableCatalogUploadMimes}
                                                filesLimit={1}
                                                dropzoneText='Drag and drop your catalog file here'
                                                onChange={files => {
                                                    const file = isEmpty(files) ? null : files[0];
                                                    formikProps.setFieldTouched('file', true);
                                                    formikProps.setFieldValue('file', file);
                                                }}
                                            />
                                            {formikProps.touched.file
                                                ? <FormHelperText error>{formikProps.errors.file}</FormHelperText>
                                                : null
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectField
                                                name='type'
                                                label='Catalog Type'
                                                options={catalogTypeOptions}
                                                value={formikProps.values.type || ''}
                                                error={!!formikProps.errors.type}
                                                helperText={formikProps.errors.type}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                margin='normal'
                                                variant='outlined'
                                                fullWidth
                                            />
                                            <TextField
                                                name='name'
                                                label='Catalog Name'
                                                placeholder='e.g., Q2-1999-ClientName'
                                                value={formikProps.values.name || ''}
                                                error={!!formikProps.errors.name}
                                                helperText={formikProps.errors.name}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                margin='normal'
                                                variant='outlined'
                                                fullWidth
                                            />
                                            <RadioGroupField
                                                name='state'
                                                label='Availability'
                                                options={catalogStateOptions}
                                                value={formikProps.values.state}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>

                                <DialogActions>
                                    {formikProps.isSubmitting
                                        ? <CircularProgress className={this.props.classes.loadingSpinner} size={20}/>
                                        : null
                                    }
                                    <Button
                                        testName='cancel'
                                        color='default'
                                        disabled={formikProps.isSubmitting}
                                        onClick={() => this.handleCancel(formikProps)}
                                    >
                                        Cancel
                                    </Button>
                                    <SubmitButton
                                        testName='save'
                                        color='primary'
                                        disabled={!formikProps.isValid || formikProps.isSubmitting}
                                    >
                                        Save
                                    </SubmitButton>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    </>
                }
            </Formik>
        );
    }

    private open(): void {
        this.props.onOpen();
        this.setState({ open: true });
    }

    private close(): void {
        this.setState({ open: false });
    }

    private handleSubmit(values: CreateCatalogRequest, actions: FormikActions<CreateCatalogRequest>): void {
        this.props.onCreateCatalog(values)
            .then(() => {
                actions.resetForm();
                this.close();
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private handleCancel(formikProps: FormikProps<CreateCatalogRequest>): void {
        if (formikProps.isSubmitting) {
            return;
        }

        formikProps.resetForm();
        this.close();
    }
}

interface AddCatalogDialogProps extends Omit<ActionProps, 'onCreateCatalog'> {
    defaultType: CatalogType,
    renderOpenButton: (handleOpen: HandleOpen) => ReactNode,
    onCreateCatalog: (request: CreateCatalogRequest) => Promise<void>,
}

type HandleOpen = () => void;

interface DialogState {
    open: boolean,
}

const formValuesSchema: Record<keyof CreateCatalogRequest, any> = yup.object().shape({
    name: yup.string().nullable().trim().required('Name is required'),
    type: yup.string().nullable().trim()
        .required('Type is required')
        .oneOf([ 'REGION', 'ACTIVITY', 'EQUIPMENT' ]),
    state: yup.string().nullable()
        .required('State is required')
        .oneOf([ 'DEFAULT', 'ACTIVE', 'INACTIVE' ]),
    file: yup.mixed().required('The catalog file is required'),
});

const styles: StyleRules = ({
    leftIcon: {
        marginRight: '10px',
    },
    loadingSpinner: {
        verticalAlign: 'middle',
    },
});

export const AddCatalogDialog = withStyles(styles)(AddCatalogDialog0);

declare const CATALOG_ROOT: string;

function getTemplateDownloadUrl(type: CatalogType): string {
    return `${CATALOG_ROOT}/catalogs/templates/${type}`;
}

const mapDispatchToProps: ActionProps = {
    onOpen: clearError,
    onCreateCatalog: addCatalog,
};

interface ActionProps {
    onOpen: () => void,
    onCreateCatalog: (request: CreateCatalogRequest) => AppThunk,
}

export default connect(null, mapDispatchToProps)(AddCatalogDialog);
