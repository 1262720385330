import { isString, isUndefined } from 'lodash';

let acitvityTimeout;

export const fireOneDelayedAction = (timeout, callback) => {
    if (acitvityTimeout) {
        clearTimeout(acitvityTimeout);
    }

    acitvityTimeout = setTimeout(callback, timeout);
};

export const stringSet = (input, minLength: number) => {
    return isString(input) && input.length >= minLength;
};

export function hasValue(input) : boolean {
    return !isUndefined(input) && input !== null;
}

export const iv = (
    value: any,
    displayLabel: string,
    isDefault: boolean = false
): IFormOption => {
    return { value, label: displayLabel, isDefault };
};

export interface IFormOption<V extends string = string> {
    value: V;
    label: string;
    isDefault?: boolean;
}
