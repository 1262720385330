import React from 'react';
import { MTableCell } from 'material-table';
import { createDataTestProps } from '../Utils/testProps';

interface ITableDataCellProps {
    testName: string,
}

const MTableDataCell: React.FunctionComponent<ITableDataCellProps> =
    ({ testName, ...remainingTableCellProps }) =>
        <MTableCell
            {...remainingTableCellProps}
            {...createDataTestProps(testName)}
        />
    ;

export default MTableDataCell;
