import { ContentTypeError, createApiError, HttpStatusError } from '../errors';

function handleHttpStatusError(err: HttpStatusError): Promise<never> {
    return err.response.text()
        .then(text => {
            const json = parseJSON(text);
            throw createApiError(json);
        })
        .catch(e => {
            if (e.message) {
                throw e;
            }

            throw new Error('An unexpected error occurred');
        });
}

function parseJSON(text: string): string {
    try {
        return JSON.parse(text);
    } catch (e) {
        throw new Error(text);
    }
}

export function handleRequestError(err: RequestError): Promise<never> {
    if (err instanceof HttpStatusError) {
        return handleHttpStatusError(err);
    }

    return Promise.reject(err);
}

type RequestError = ContentTypeError | HttpStatusError;
