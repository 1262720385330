import React, { FunctionComponent, ReactNode } from 'react';
import { Authority } from '../../data/model/user';
import { CaseAuthorized } from './CaseAuthorized';

export const Authorized: FunctionComponent<IAuthorized> = props =>
    <CaseAuthorized for={props.for}>
        {
            authorized => authorized
                ? props.children
                : null
        }
    </CaseAuthorized>;

export interface IAuthorized {
    for: Authority,
    children: ReactNode,
}

