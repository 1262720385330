import { startLoading, successResponse, errorResponse } from './actualizationOverviewsSlice';
import {
    getActualizationOverviews as getActualizationOverviewsFromApi,
} from '../../api/model-service/actualization';
import { AppThunk } from '../app-thunk';
import { reportModelError } from '../error-actions';

export const getActualizationOverviews = (): AppThunk => dispatch => {
    dispatch(startLoading());
    return getActualizationOverviewsFromApi()
        .then(overviews => {
            dispatch(successResponse(overviews))
        })
        .catch(err => {
            dispatch(reportModelError(err));
            dispatch(errorResponse(err));
            throw err;
        });
};
