import React from 'react';

import { useEffect } from 'react';
import {
    CircularProgress,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles
} from "@material-ui/core";
import ViewTitle from "../ViewTitle/ViewTitle";
import {Link} from "react-router-dom";
import DataHighlight from "../DataHighlight/DataHighlight";
import {formatCurrency, formatNumber} from "../../data/utils/number";
import ModelIcon from '@material-ui/icons/UpdateOutlined';
import SectionTitle from "../SectionTitle/SectionTitle";
import {connect} from "react-redux";
import {getAmendmentDetails} from "../../data/actions/models";
import {hasValue} from "../../data/utils/form";
import {findRegionName} from "./Model";
import {getFormCatalogs} from "../../data/actions/catalogs";
import {CatalogType} from "../../data/model/catalog";

const styles = (theme: any) => ({
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 25,
        paddingBottom: 50,
        marginBottom: '33px',
        position: 'relative',
    },
    ModelName: {
        fontSize: '1.75rem',
    },
    modelVersionFlag: {
        fontSize: '50%',
        verticalAlign: 'middle',
        marginLeft: '10px',
        whiteSpace: 'nowrap',
    },
    dataHighlightRoot: {
        flexGrow: 1,
        marginTop: 20,
    },
    newConfigButton: {
        marginLeft: '20px',
    },
    metaExpandArea: {
        backgroundColor: 'white',
        marginTop: 20,
    },
    switchIcon: {
        color: 'black',
        display: 'none',
    },
    moreLabel: {
        color: '#0c6d10',
        textTransform: 'uppercase',
    },
    takeAways: {
        padding: theme.spacing.unit * 2,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -25,
        marginRight: -25,
    },
    stepper: {
        marginBottom: 15,
        marginTop: 15,
        borderRadius: '7px',
        padding: '10px 3px',
    },
    stepRoot: {
        padding: 0,
    },
    stepLabel: {
        color: 'grey',
        fontSize: 12,
        padding: '5px 10px',
    },
    stepLabelContainer: {
        backgroundColor: 'transparent',
        border: '1px solid #bdbdbd',
        borderRadius: 25,
    },
    stepIconContainer: {
        display: 'none',
    },
    stepActive: {
        color: 'white !important',
        fontWeight: 700,
        borderRadius: 25,
        padding: '5px 10px',
        backgroundColor: 'green',
        border: 'transparent !important',
    },
    stepCompleted: {
        color: 'white',
        backgroundColor: 'aqua',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.95)',
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    smallIcon: {
        position: 'relative',
        fontSize: 25,
        top: 2,
        marginRight: 10,
    },
    statusChip: {
        margin: theme.spacing.unit,
        marginLeft: 'auto',
    },
});

interface TCFieldsInterface {
    [index: number]: { name: string; label: string; adjustValue?    (value:string):string };
    forEach? (row: any): void,
    map? (row:any): any
}

const AmendmentDetail = ({activeModel,amendmentDetails,fetchAmendmentDetails,getOne,match,classes,history, catalogs}) => {

    const TCFields:TCFieldsInterface = [
        {"name":"projectName", "label":"Project Name"},
        {"name":"customerName", "label":"Customer Name"},
        {"name":"dunsNumber", "label":"DUNS Number"},
        {"name":"description", "label":"Description"},
        {"name":"type", "label":"Offering Type"},
        {"name":"nuWarrantyDuration", "label":"NU Warranty Duration"},
        {"name":"catalogRegionId", "label":"Service Region",adjustValue:(value:any)=>{
            return findRegionName(value,catalogs)
        }},
        {"name":"isOnOffshoreConstellation", "label":"On-/Offshore Constellation"},
        {"name":"oracleContractsNumber", "label":"Oracle Contracts #"},
        {"name":"contractManager", "label":"Contract Manager"},
        {"name":"paperContractId", "label":"Paper Contract ID"},
        {"name":"capPerEvent", "label":"Corrective Cap Per Event"},
        {"name":"capPerYear", "label":"Corrective Cap Per Year"},
        {"name":"capOverTotalContractTerm", "label":"Corrective Cap Over Total Contract-Term"},
    ];

    async function loadModel() {
        getOne(match.params.modelID);
    }

    async function loadADetails() {
        fetchAmendmentDetails(match.params.modelID).catch(()=>{
            history.push(`/models/${activeModel.id}`);
        });
    }

    //ensure the model & amendment details are loaded
    useEffect(() => {
        if (!activeModel.id || activeModel.id !== Number(match.params.modelID)) {
            loadModel().then((model) => {
                loadADetails();
            });
        } else {
            loadADetails();
        }

     }, []);

    const isNotBlankAndNotZero = (val) => {
        return val != null && val != 0;
    };

    if(activeModel.id && !activeModel.amendmentInformation.hasAmendment()) {//deal with model with no amendment
        history.push(`/models/${activeModel.id}`);
    }

    if(!activeModel.id || !amendmentDetails) {
        return (<CircularProgress/>);
    }

    const model = activeModel;

    const hasFinancials = amendmentDetails && (hasValue(amendmentDetails.deltaTotalModelCost)
        || hasValue(amendmentDetails.deltaTotalModelBillingsConsidLDB)
        || hasValue(amendmentDetails.deltaTotalModelCMConsidLDB)
        || hasValue(amendmentDetails.deltaTotalModelCMPercentage));

    let hasDiff = false;
    if(amendmentDetails.changedParentTCs && amendmentDetails.changedAmendmentTCs){
        TCFields.forEach((row) => {
            if (amendmentDetails.changedParentTCs[row.name] || amendmentDetails.changedAmendmentTCs[row.name]) {
                hasDiff = true;
            }
        });
    }

    return (
        <React.Fragment>
                <ViewTitle>
                    <Link to={`/models/${model.id}`}>{model.projectName}</Link>
                </ViewTitle>
                <Paper square classes={{ root: classes.paper }}>
                    <h1 className="modelName">
                        <ModelIcon
                            color="disabled"
                            className={classes.smallIcon}
                        />
                        Amendment {model.version==='OTR'?'History':'Details'}
                    </h1>

                    {(model.modelVersion!=='OTR') &&
                        <React.Fragment>
                            {hasFinancials &&
                                <React.Fragment>
                                    <SectionTitle title="Financials" />
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            spacing={16}
                                        >

                                            <DataHighlight
                                                testName="deltaTotalModelCost"
                                                labelText="Total Model Delta Costs"
                                                price
                                                value={formatCurrency(
                                                    'EUR',
                                                    amendmentDetails.deltaTotalModelCost
                                                )}
                                            />

                                            <DataHighlight
                                                testName="deltaTotalModelBillingsConsidLDB"
                                                labelText="Total Model Delta Billing Cons. LD/B"
                                                price
                                                value={formatCurrency(
                                                    'EUR',
                                                    amendmentDetails.deltaTotalModelBillingsConsidLDB
                                                )}
                                            />

                                            <DataHighlight
                                                testName="deltaTotalModelCMConsidLDB"
                                                labelText="Total Model Delta CM cons. LD/B"
                                                price
                                                value={formatCurrency(
                                                    'EUR',
                                                    amendmentDetails.deltaTotalModelCMConsidLDB
                                                )}
                                            />

                                            <DataHighlight
                                                testName="deltaTotalModelCMPercentage"
                                                labelText="Total Model Delta CM% cons. LD/B"
                                                value={
                                                    formatNumber(
                                                        amendmentDetails.deltaTotalModelCMPercentage * 100,
                                                        0,
                                                        2
                                                    ) + '%'
                                                }
                                            />
                                        </Grid>
                                </React.Fragment>
                            }

                            {hasDiff &&
                                <React.Fragment>
                                    <SectionTitle title="Model Comparison" />
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="flex-start"
                                        className={classes.dataHighlightRoot}
                                        spacing={16}

                                    >
                                        <Grid item xs={3}>
                                            <strong>Latest OTR Model</strong>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <strong>Current Model</strong>
                                        </Grid>

                                        {TCFields.map((row) => (
                                             <React.Fragment>
                                                {(amendmentDetails.changedParentTCs[row.name] || amendmentDetails.changedAmendmentTCs[row.name]) &&
                                                    <React.Fragment>
                                                        <Grid item xs={3}>
                                                            <DataHighlight
                                                                testName={`changedParentTCsOutput${row.name}`}
                                                                labelText={row.label}
                                                                fullwidth
                                                                value={getDisplayValue(amendmentDetails.changedParentTCs,row)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <DataHighlight
                                                                testName={`changedAmendmentTCsOutput${row.name}`}
                                                                labelText={row.label}
                                                                fullwidth
                                                                value={getDisplayValue(amendmentDetails.changedAmendmentTCs,row)}
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                                </React.Fragment>
                                            ))}
                                    </Grid>
                                </React.Fragment>
                            }

                            <SectionTitle title="History" />
                        </React.Fragment>
                    }


                    <Table style={{maxWidth:300}} className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Revision</TableCell>
                            <TableCell>Amendment Oppty #</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {amendmentDetails.history.map((row,index)=> (
                            <TableRow key={row.id}>
                                <TableCell><Link to={`/models/${row.modelId}`}>{index+1}</Link></TableCell>
                                <TableCell><Link to={`/models/${row.modelId}`}>{row.amendmentOpportunityNumber}</Link></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
    );
};

function getDisplayValue(dataTCs:any,tcField:any) : string {
    let value = dataTCs[tcField.name];

    if(tcField.adjustValue) {
        return tcField.adjustValue(value);
    }

    if (value === true) {
        return 'YES';
    } else if (value === false) {
        return 'NO';
    } else {
        return value;
    }
}

const mapStateToProps = state => {
    return {
        activeModel: state.models.activeModel,
        amendmentDetails:state.models.amendmentDetails,
        catalogs: state.catalogs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAmendmentDetails:(modelID) => {
            dispatch(getFormCatalogs(CatalogType.region, true));
            return dispatch(getAmendmentDetails(modelID));
        }
    };
};

export default withStyles(styles as any)(connect(
        mapStateToProps,
        mapDispatchToProps
    )(AmendmentDetail));
