import React, { Component, ReactNode } from 'react';
import { Escalation } from '../../api/model-service/model-service';
import { FixPriceEscalationCard } from './FixPriceEscalationCard';
import { VariablePriceEscalationCard } from './VariablePriceEscalationCard';
import Confirm from '../Utils/Confirm';
import { ActionMenuItem } from '../ActionMenu/ActionMenu';
import { EditEscalationDialog } from '../EditEscalationDialog/EditEscalationDialog';

export class EscalationCard extends Component<EscalationCardProps, ComponentState> {

    public state = {
        openEdit: false,
        openDelete: false,
    };

    public render(): ReactNode {
        const { escalation, testId } = this.props;
        const actions = this.getActions();

        return (
            <>
                {escalation.type === 'fixPrice'
                    ? <FixPriceEscalationCard
                        testId={testId}
                        escalation={escalation}
                        actions={actions}
                    />
                    : <VariablePriceEscalationCard
                        testId={testId}
                        escalation={escalation}
                        actions={actions}
                    />
                }
                <EditEscalationDialog
                    escalation={escalation}
                    open={this.state.openEdit}
                    onCancel={() => this.closeEdit()}
                    onSubmit={updatedEscalation => {
                        this.props.onChange(updatedEscalation);
                        this.closeEdit();
                    }}
                />
                <Confirm
                    testName='confirmDelete'
                    title='Delete Escalation'
                    message='Are you sure you want to delete this? It cannot be undone.'
                    action={() => {
                        this.props.onDelete();
                        this.closeDelete();
                    }}
                    open={this.state.openDelete}
                    handleClose={() => this.closeDelete()}
                />
            </>
        );
    }

    private getActions(): ActionMenuItem[] {
        return this.props.disabled
            ? []
            : [
                {
                    label: 'Edit',
                    testName: 'edit',
                    onClick: () => this.openEdit(),
                },
                {
                    label: 'Delete',
                    testName: 'delete',
                    onClick: () => this.openDelete(),
                },
            ];
    }

    private openEdit(): void {
        this.setState({ openEdit: true });
    }

    private closeEdit(): void {
        this.setState({ openEdit: false });
    }

    private openDelete(): void {
        this.setState({ openDelete: true });
    }

    private closeDelete(): void {
        this.setState({ openDelete: false });
    }
}

export interface EscalationCardProps {
    testId: string,
    escalation: Escalation,
    disabled: boolean,
    onChange: (escalation: Escalation) => void,
    onDelete: () => void,
}

interface ComponentState {
    openEdit: boolean,
    openDelete: boolean,
}
