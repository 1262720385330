import { startLoading, successResponse, errorResponse } from './createActualizationSlice';
import {
    putValidations as putValidationsFromApi,
    ValidationsRequest,
} from '../../api/model-service/actualization';
import { AppThunk } from '../app-thunk';
import { reportConfigurationError } from '../error-actions';

export const putValidationCandidates = (request: ValidationsRequest): AppThunk => dispatch => {
    dispatch(startLoading());
    return putValidationsFromApi(request)
        .then(candidates => {
            dispatch(successResponse(candidates))
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportConfigurationError(err));
            throw err;
        });
};
