import { defaultsDeep } from '../utils/defaults-deep-no-array-merge';
import { getToken } from '../../data/auth';
import { requestTokenIfExpired, requestTokenIfMissing } from './request-token';

declare const REQUEST_FETCH_DEFAULTS: object;

const FETCH_DEFAULT_OPTIONS = {
    mode: 'cors',
    ...REQUEST_FETCH_DEFAULTS,
};

export function fetch(url: string, options?: RequestInit): Promise<Response> {
    return getToken()
        .then(requestTokenIfMissing)
        .then(token => fetch0(url, options, token, 1));
}

function fetch0(url: string, options: RequestInit, token: string, retries: number): Promise<Response> {
    const authOptions: RequestInit = {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    };
    const optionsWithDefaults = defaultsDeep({}, options, authOptions, FETCH_DEFAULT_OPTIONS);
    return window.fetch(url, optionsWithDefaults)
        .then(response => {
            if (retries < 1) {
                return response;
            }

            return requestTokenIfExpired(response,
                newToken => fetch0(url, options, newToken, retries - 1));
        });
}

