import { fetch } from '../fetch';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';
import { ActivityType, ActivityUnit } from '../model-service/model-service';

declare const CATALOG_ROOT;

export function getCatalogActivities(request: GetCatalogActivitiesRequest): Promise<CatalogActivity[]> {
    const url = `${CATALOG_ROOT}/activities/${request.engineId}`;
    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetCatalogActivitiesRequest {
    engineId: number,
}

export interface CatalogActivity {
    key: string,
    scope: string,
    service: string,
    attribute: string,
    type: ActivityType,
    unit: ActivityUnit,
    frequency: number,
    value: number,
    icValue: number,
}
