import { renewToken } from '../../data/auth';

export function requestTokenIfMissing(token: string): Promise<string> {
    if (token) {
        return Promise.resolve(token);
    }

    return requestToken();
}

export function requestTokenIfExpired(response: Response, retry: RetryCallback): Promise<Response> {
    if (response.status !== 401) {
        return Promise.resolve(response);
    }

    return requestToken().then(retry);
}

type RetryCallback = (newToken: string) => Promise<Response>;

let tokenCheckPromise: Promise<string>;

function requestToken() {
    if (tokenCheckPromise) {
        return tokenCheckPromise;
    }

    tokenCheckPromise = renewToken().finally(() => {
        tokenCheckPromise = null;
    });
    return tokenCheckPromise;
}
