import React, { FC } from 'react';
import { OtherTermsConditionsProps } from './OtherTermsConditionsProps';
import { OtherTermsConditionsForm } from './OtherTermsConditionsForm';

export const DefaultTermsConditions: FC<OtherTermsConditionsProps> =
    props =>
        <OtherTermsConditionsForm
            {...props}
            showProductivityImplClause
            showSiteInventoryIncluded
            showWarrantyPeriodService
            showDepositChargeForReupPartsBlocks={false}
            showPartsListChecked={false}
            defaultMyPlantProduct='CARE'
        />
;
