import React from 'react';
import MaterialRadio, { RadioProps as MaterialRadioProps } from '@material-ui/core/Radio';
import { createInputTestProps } from '../Utils/testProps';
import { CaseAuthorizedToEditForm } from './formAuthorizationContext';

interface IRadioProps extends MaterialRadioProps {
    testName: string,
    testId: string,
}

const Radio: React.FunctionComponent<IRadioProps> =
    ({ testName, testId, inputProps, disabled, ...remainingRadioProps }) => {
        const fullInputProps: object = getFullInputProps(inputProps, testName, testId);

        return (
            <CaseAuthorizedToEditForm>
                {
                    authorizedToEdit => {
                        const radioDisabled = getRadioDisabled(authorizedToEdit, disabled);
                        return <MaterialRadio
                            disabled={radioDisabled}
                            inputProps={fullInputProps}
                            {...remainingRadioProps}
                        />;
                    }
                }
            </CaseAuthorizedToEditForm>
        );
    };

export default Radio;

function getFullInputProps(inputProps: object, testName: string, testId: string) {
    return {
        ...inputProps,
        ...createInputTestProps(testName, testId),
    };
}

function getRadioDisabled(authorizedToEdit: boolean, disabled: boolean) {
    return !authorizedToEdit || disabled;
}
