import React from 'react';
import MaterialStepButton, { StepButtonProps as MaterialStepButtonProps } from '@material-ui/core/StepButton';
import { createButtonTestProps } from '../Utils/testProps';

interface IStepButtonProps extends MaterialStepButtonProps {
    testName: string,
    testId: string,
}

const StepButton: React.FunctionComponent<IStepButtonProps> =
    ({ testName, testId, ...materialStepButtonProps }) =>
        <MaterialStepButton
            {...materialStepButtonProps}
            {...createButtonTestProps(testName, testId)} />
    ;

export default StepButton;
