import React, { FunctionComponent, ReactNode } from 'react';
import { AuthorizationConsumer } from './context';
import { Authority } from '../../data/model/user';

export const CaseAuthorized: FunctionComponent<ICaseAuthorized> = props =>
    <AuthorizationConsumer>
        {
            authorities => {
                const authorized = authorities.includes(props.for);
                return props.children(authorized)
            }
        }
    </AuthorizationConsumer>;

export interface ICaseAuthorized {
    for: Authority,
    children: (authorized: boolean) => ReactNode,
}
