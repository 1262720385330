import React, { FC } from 'react';
import { OptionProps } from 'react-select';
import { OptionType } from './OptionType';
import { MenuItem } from '../Popups/MenuItem';

export const Option: FC<OptionProps<OptionType>> =
    props =>
        <MenuItem
            testName='option'
            testId={props.data.value}
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component='div'
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>;
