import { createAction, createReducer } from '@reduxjs/toolkit';

interface AddCatalogDialogState {
    loading: boolean,
    error: string,
}

export const clearError = createAction('addCatalog/clearError');

export const startLoading = createAction('addCatalog/startLoading');

export const errorResponse = createAction<Error>('addCatalog/errorResponse');

export const successResponse = createAction('addCatalog/successResponse');

const initialState: AddCatalogDialogState = {
    loading: false,
    error: null,
};
const reducer = createReducer<AddCatalogDialogState>(initialState, builder =>
    builder
        .addCase(clearError, state => ({
            ...state,
            error: null,
        }))
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, () => ({
            loading: false,
            error: null,
        })),
);

export default reducer;
