import React, { FC } from 'react';
import { StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import ViewTitle from '../ViewTitle/ViewTitle';
import CatalogSection from '../CatalogSection/CatalogSection';
import { filterCatalogsByKeyword } from '../../state/catalog-overview/get-page';
import { AppThunk } from '../../state/app-thunk';
import { connect } from 'react-redux';

const CatalogPage0: FC<CatalogPageProps & StyledComponentProps> =
    props =>
        <>
            <ViewTitle search searchOnChange={props.onFilterByKeyword}>
                Catalogs
            </ViewTitle>
            <div className='right-content-wrap'>
                <Grid
                    spacing={24}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item sm={12}>
                        <Paper className={props.classes.root}>
                            <CatalogSection
                                sectionName='Regional Catalog'
                                tableTestName='regionalCatalogs'
                                type='REGION'
                            />
                        </Paper>
                    </Grid>
                    <Grid item sm={12}>
                        <Paper className={props.classes.root}>
                            <CatalogSection
                                sectionName='Activity Catalog'
                                tableTestName='activityCatalogs'
                                type='ACTIVITY'
                            />
                        </Paper>
                    </Grid>
                    <Grid item sm={12}>
                        <Paper className={props.classes.root}>
                            <CatalogSection
                                sectionName='Equipment Catalog'
                                tableTestName='equipmentCatalogs'
                                type='EQUIPMENT'
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>;

interface CatalogPageProps {
    onFilterByKeyword: (keyword: string) => Promise<void>,
}

const styles: StyleRulesCallback = theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        flexGrow: 1,
        marginBottom: '33px',
    },
});

export const CatalogPage = withStyles(styles)(CatalogPage0);

const mapDispatchToProps: ActionProps = {
    onFilterByKeyword: filterCatalogsByKeyword,
};

interface ActionProps {
    onFilterByKeyword: (keyword: string) => AppThunk,
}

export default connect(null, mapDispatchToProps)(CatalogPage);
