import React, { Component, ReactNode } from 'react';
import { ActualizationCandidate, GetActualizationCandidatesRequest } from '../../api/model-service/actualization';
import { State } from '../../data/store';
import { connect } from 'react-redux';
import {
    ActualizationCandidateFilter,
    ActualizationFilter,
    CandidateFilterOptions,
} from '../ActualizationFilter/ActualizationFilter';
import { ActualizationFormButtons } from '../ActualizationFormButtons/ActualizationFormButtons';
import { AppThunk } from '../../state/app-thunk';
import { ActualizationCandidates } from '../ActualizationCandidates/ActualizationCandidates';
import { getCandidateFilterOptions } from '../../state/actualization/getCandidateFilterOptions';
import { getActualizationCandidates } from '../../state/actualization/getActualizationCandidates';
import { Grid } from '@material-ui/core';
import { sortBy } from 'lodash';

export class ActualizationCandidatesForm extends Component<ComponentProps> {

    public componentDidMount(): void {
        this.props.onShowFilters();
    }

    public render(): ReactNode {
        const candidates = this.props.candidates || [];
        const sortedCandidates = sortBy(candidates, [ 'opportunityNumber', 'displayName' ]);
        return (
            <>
                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <ActualizationFilter
                            loading={this.props.loading}
                            filterOptions={this.props.filterOptions}
                            onSearch={filter => this.handleSearch(filter)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ActualizationCandidates
                            isInitialLoad={this.props.isInitialLoad}
                            candidates={sortedCandidates}
                            regions={this.props.filterOptions.catalogRegions}
                            contractManagers={this.props.filterOptions.contractManagers}
                        />
                    </Grid>
                </Grid>
                <ActualizationFormButtons
                    loading={this.props.loading}
                    disabled={!candidates.length}
                    onCancel={this.props.onCancel}
                    onProceed={() => this.props.onProceed(candidates)}
                />
            </>
        )
    }

    private handleSearch(filter: ActualizationCandidateFilter): Promise<void> {
        const regionIds: number[] = this.props.filterOptions.catalogRegions
            .filter(region => region.name === filter.region)
            .map(region => region.id);

        const request: GetActualizationCandidatesRequest = {
            contractCategory: filter.contractCategory,
            catalogRegionIds: regionIds,
            opportunityNumbers: filter.opportunityNumbers,
            engineTypes: filter.engineTypes,
            contractManagers: filter.contractManagers,
            actualizationPeriods: filter.actualizationPeriods,
        };

        return this.props.onSearch(request);
    }
}

type ComponentProps = ActualizationCandidatesFormProps & StateProps & Omit<ActionProps, 'onSearch'>;

export interface ActualizationCandidatesFormProps {
    onSearch: (request: GetActualizationCandidatesRequest) => Promise<void>,
    onCancel: () => void,
    onProceed: (candidates: ActualizationCandidate[]) => void,
}

function mapStateToProps(state: State): StateProps {
    const candidateFilterOptionsState = state.REFACTORED.candidateFilterOptions;
    const candidateState = state.REFACTORED.actualizationCandidates;
    return {
        loading: candidateFilterOptionsState.loading || candidateState.loading,
        filterOptions: candidateFilterOptionsState.data,
        candidates: candidateState.data,
        isInitialLoad: candidateState.isInitialLoad,
    }
}

interface StateProps {
    loading: boolean,
    filterOptions: CandidateFilterOptions,
    candidates: ActualizationCandidate[],
    isInitialLoad: boolean,
}

const mapDispatchToProps: ActionProps = {
    onShowFilters: getCandidateFilterOptions,
    onSearch: getActualizationCandidates,
};

interface ActionProps {
    onShowFilters: () => void,
    onSearch: (request: GetActualizationCandidatesRequest) => AppThunk,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ActualizationCandidatesForm);
