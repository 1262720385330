import { startLoading, successResponse, errorResponse } from './candidatesSlice';
import {
    getActualizationCandidates as getActualizationCandidatesFromApi,
    GetActualizationCandidatesRequest,
} from '../../api/model-service/actualization';
import { AppThunk } from '../app-thunk';
import { reportModelError } from '../error-actions';

export const getActualizationCandidates = (request: GetActualizationCandidatesRequest): AppThunk => dispatch => {
    dispatch(startLoading());
    return getActualizationCandidatesFromApi(request)
        .then(candidates => {
            dispatch(successResponse(candidates))
        })
        .catch(err => {
            dispatch(reportModelError(err));
            dispatch(errorResponse(err));
            throw err;
        });
};
