import { CATALOG, ICatalogAction } from '../actions/catalogs';
import { CatalogPageRequest, CatalogType, ICatalog } from '../model/catalog';
import { Page } from '../model/page';
import { IRequestPage } from './index';

export const catalogs = (
    state = InitialCatalogState,
    action: ICatalogAction
): ICatalogState => {
    switch (action.type) {
        case CATALOG.CATALOGDETAILS:
        case CATALOG.FORMINIT:
            return Object.assign({}, state, action);
        case CATALOG.INIT:
            const init = {};
            Object.keys(action.init).forEach(key => {
                init[key.toUpperCase()] = Object.assign(
                    {},
                    state[key.toUpperCase()],
                    { all: action.init[key] }
                );
            });
            return Object.assign({}, state, init);
            break;
        case CATALOG.DELETE:
        case CATALOG.CREATE:
        case CATALOG.UPDATE:
            let update = {};
            Object.keys(action.init).forEach(key => {
                update[key.toUpperCase()] = Object.assign(
                    {},
                    state[key.toUpperCase()],
                    { all: action.init[key] }
                );
            });

            update[action.requestingPage.getType()] = Object.assign(
                update[action.requestingPage.getType()],
                { data: action.pageData, pageData: action.requestingPage }
            );
            return Object.assign({}, state, update);
            break;

        case CATALOG.READ:
            const read = {};
            read[action.requestingPage.getType()] = {
                data: action.pageData,
                pageData: action.requestingPage,
                all: state[action.requestingPage.getType()].all,
            };
            return Object.assign({}, state, read);
            break;
        case CATALOG.CLEARERROR:
        case CATALOG.ERROR:
        default:
            return state;
            break;
    }
};

export interface ICatalogState {
    ACTIVITY: IRequestPage<ICatalog>;
    REGION: IRequestPage<ICatalog>;
    EQUIPMENT: IRequestPage<ICatalog>;
    error?: string;
}

export const InitialCatalogState: ICatalogState = {
    ACTIVITY: {
        page: new CatalogPageRequest().setType(CatalogType.activity),
        data: new Page<ICatalog>(),
        all: new Page<ICatalog>(),
    },
    REGION: {
        page: new CatalogPageRequest().setType(CatalogType.region),
        data: new Page<ICatalog>(),
        all: new Page<ICatalog>(),
    },
    EQUIPMENT: {
        page: new CatalogPageRequest().setType(CatalogType.equipment),
        data: new Page<ICatalog>(),
        all: new Page<ICatalog>(),
    },
};
