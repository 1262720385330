import { Configuration, UnitActivitySchedule } from './model-service';
import { fetch } from '../fetch';
import { expectOK } from '../utils/expect-ok';
import { handleRequestError } from '../utils/request-error';
import { expectJSON } from '../utils/expect-json';

export function getConfigurationCopy(id: number): Promise<Configuration> {
    const url = `${MODEL_ROOT}/configurations/${id}/copy`;

    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export function getConfiguration(request: GetConfigurationRequest): Promise<Configuration> {
    const url = `${MODEL_ROOT}/configurations/${request.id}?skipFinancialData=${request.skipFinancialData}`;

    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetConfigurationRequest {
    id: number,
    skipFinancialData: boolean,
}

export function getActivitySchedule(id: number): Promise<UnitActivitySchedule[]> {
    const url = `${MODEL_ROOT}/configurations/${id}/activitySchedule`;

    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export function createConfiguration(request: CreateUpdateConfigurationRequest): Promise<Configuration> {
    return createOrUpdateConfiguration(request, 'POST');
}

export function updateConfiguration(request: CreateUpdateConfigurationRequest): Promise<Configuration> {
    return createOrUpdateConfiguration(request, 'PUT');
}

export interface CreateUpdateConfigurationRequest {
    modelId: number,
    configuration: Configuration,
    skipFinancialData: boolean,
}

function createOrUpdateConfiguration(request: CreateUpdateConfigurationRequest,
                                     method: 'POST' | 'PUT'): Promise<Configuration> {

    const isDraft = request.configuration.state === 'DRAFT';

    const url = `${MODEL_ROOT}/models/${request.modelId}/configurations?draft=${isDraft}&skipFinancialData=${request.skipFinancialData}`;
    const requestOptions = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request.configuration),
    };

    return fetch(url, requestOptions)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}
