import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { IndexType } from './IndexType';
import { assertNever } from '../../utils/assertNever';

const laborIndexNameOptions: IFormOption[] = [
    formOption('AT - Kollektivvertrags-Mindestmonatslohn für Facharbeiter (Lohngruppe 2)'),
    formOption('DE - Metall- und Elektroindustrie für die Pfalz, Ausgangslohn Nr.7 / Vergütungsgruppe 7 / Lohngruppe 3 einschl. 10%'),
    formOption('IT - Indice sec ISTAT, indice retribuzioni contrattuali per contratto Totale economia al netto Dirigenti'),
    formOption('NL - Cao-lonen, contractuele loonkosten en arbeidsduur; SBI 2008 : 24-30, 33 Metalektro Onderwerpen Indexcijfers'),
    formOption('ES - Indice de Precios de Consumo – IPC'),
    formOption('ES - Indice de Precios Industriales – IPRI'),
    formOption('DK - Danske Nettoprisindeks'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - West'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - Mountain Plains'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - Southwest'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - Midwest'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - Southeast'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - Mid-Atlantic'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - New York'),
    formOption('US - Consumer Price Index for Urban consumers (CPI-U) - New England'),
    formOption('Not Listed'),
];

const materialIndexNameOptions: IFormOption[] = [
    formOption('DE - Index der Erzeugerpreise Gewerblicher Produkte für Verbrennungsmotoren und Turbinen Fachserie 17 Reihe 2 Lfd Nr GP-2811'),
    formOption('NL - Producentenprijsindex (PPI); Producten (PRODCOM): 28 Machines, apparaten en werktuigen'),
    formOption('ES - Indice de Precios de Consumo – IPC'),
    formOption('ES - Indice de Precios Industriales – IPRI'),
    formOption('DK - Danske Nettoprisindeks'),
    formOption('US - B.L.S. - US Import Price Indexes, Germany, Manufactured Articles'),
    formOption('Not Listed'),
];

const lubeOilIndexNameOptions: IFormOption[] = [
    formOption('DE - Index der Erzeugerpreise Gewerblicher Produkte für Schmieröle andere Öle Fachserie 17 Reihe 2 Lfd-Nr. 197 GP-Nr. 232018'),
    formOption('NL - Producentenprijsindex (PPI); Producten (PRODCOM): 19202 Stookolie en benzine; smeerolie'),
    formOption('Not Listed'),
];

export function getIndexNameOptions(type: IndexType): IFormOption[] {
    switch (type) {
        case 'labor':
            return laborIndexNameOptions;
        case 'material':
            return materialIndexNameOptions;
        case 'lubeOil':
            return lubeOilIndexNameOptions;
        default:
            return assertNever('Unknown index type', type);
    }
}
