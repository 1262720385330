import { fetch } from '../fetch';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';

declare const AUTH_ROOT: string;

export function getContractManagers(): Promise<UserMail[]> {
    const url = `${AUTH_ROOT}/users/roles/contract-managers`;
    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface UserMail {
    username: string,
    displayName: string,
    mail: string,
}
