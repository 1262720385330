import React, { FC } from 'react';
import { StandardScopeProps } from './StandardScopeProps';
import { deduplicateAttributes, isGenSetActivity, isNotMajorOverhaul } from './activity-filters';
import StandardActivitiesForm from '../StandardActivitiesForm/StandardActivitiesForm';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';

export const CsaPreventiveCorrectiveStandardScope: FC<StandardScopeProps> =
    props =>
        <StandardActivitiesForm {...props} defaultActivitiesFilter={getDefaultActivities}/>
;

function getDefaultActivities(activities: CatalogActivity[]): CatalogActivity[] {
    return deduplicateAttributes(
        activities
            .filter(isGenSetActivity)
            .filter(isNotMajorOverhaul),
    );
}
