import React, { FunctionComponent } from 'react';
import MaterialMenuItem, { MenuItemProps as MaterialMenuItemProps } from '@material-ui/core/MenuItem';
import { createButtonTestProps } from '../Utils/testProps';

interface IMenuItemProps extends MaterialMenuItemProps {
    testName: string,
    testId?: string,
}

export const MenuItem: FunctionComponent<IMenuItemProps> =
    ({ testName, testId, ...remainingMenuItemProps }) =>
        <MaterialMenuItem
            {...createButtonTestProps(testName, testId)}
            {...remainingMenuItemProps}
        />;
