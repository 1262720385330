import { AppThunk } from '../app-thunk';
import {
    CatalogType,
    getCatalogOverviews,
    GetCatalogOverviewsRequest,
} from '../../api/catalog-service/catalog-overview';
import { errorResponse, startLoading, successResponse } from './slice';

export const changeCatalogOverviewPageSize = (type: CatalogType, pageSize: number): AppThunk => (dispatch, getState) => {
    const previousRequest = getState().REFACTORED.catalogOverviews.catalogPagesByType[type].request;
    const pageRequest: GetCatalogOverviewsRequest = {
        ...previousRequest,
        length: pageSize,
        page: 0,
    };

    dispatch(startLoading(type, pageRequest));
    return getCatalogOverviews(pageRequest)
        .then(catalogPage => {
            dispatch(successResponse(type, catalogPage));
        })
        .catch(err => {
            dispatch(errorResponse(type, err));
        });
};
