import { array, mixed, object, ObjectSchema, string, yearMonth } from '../../utils/validators';
import { ActualizationCandidateFilter } from './ActualizationFilter';
import { requiredMessage } from '../../utils/validationMessages';
import { ContractCategory } from '../../api/model-service/model-service';

const opportunityNumberRegex = /^(A|B|\d)\d{6}$/i;

export const actualizationFilterSchema: ObjectSchema<ActualizationCandidateFilter> = object({
    region: string().required(requiredMessage),
    contractCategory: mixed<ContractCategory>().required(requiredMessage),
    opportunityNumbers: array(
        string().matches(opportunityNumberRegex, 'Invalid opportunity number'),
    ),
    engineTypes: array(string()),
    contractManagers: array(string()),
    actualizationPeriods: array(yearMonth()),
});
