import { Page } from './page';
import { PageRequest } from './pageRequest';
import { merge } from 'lodash';

export interface ICatalog {
    createdBy?: string;
    createdOn?: string;
    id?: number;
    modifiedBy?: string;
    modifiedOn?: string;
    name: string;
    state?: CatalogState;
    type: string;
    file?: File;
}

export class CatalogPageRequest extends PageRequest {
    private type: string;
    private sortBy: string = CatalogSort.createdOn;

    public setSortBy(sortBy: CatalogSort): void {
        this.sortBy = sortBy;
        super.setPage(0);
    }
    public getType(): string {
        return this.type;
    }
    public setType(type: string) {
        this.type = type;
        return this;
    }
    public getQuery(): {} {
        const params = merge(super.getQuery(), {
            type: this.type,
            sortBy: this.sortBy,
        });
        return params;
    }
}

export interface Activity {
    key: string;
    attribute: string;
    frequency: number;
    scope: string;
    service: string;
    type: string;
    unit: string;
    value: number;
    icValue?: number;
}

export interface EngineInformation {
    electricalPower: number;
    version: string;
    id: number;
    maintenanceSection: string;
    maintenanceSchedule: string;
    equipmentModel: string;
    mechanicalPower: number;
    oilVolume: number;
    type: string;
}

export const flipState = (state: CatalogState): CatalogState => {
    if (state === CatalogState.DEFAULT || state === CatalogState.ACTIVE) {
        return CatalogState.INACTIVE;
    } else {
        return CatalogState.ACTIVE;
    }
};

export const findDefault = (catalogs: ICatalog[]): ICatalog => {
    for (const index in catalogs) {
        if (catalogs[index].state === CatalogState.DEFAULT) {
            return catalogs[index];
        }
    }
    return { name: '', type: '' };
};

export const AcceptableCatalogUploadMimes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
];

export enum CatalogType {
    region = 'REGION',
    activity = 'ACTIVITY',
    equipment = 'EQUIPMENT',
}

export enum CatalogState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DEFAULT = 'DEFAULT',
}

export interface ICatalogStateUpdate {
    id: number;
    state: CatalogState;
}

export interface IBatchCatalogStateUpdate {
    defaultId?: number;
    changes?: ICatalogStateUpdate[];
}

export enum CatalogSort {
    createdBy = 'createdBy',
    createdOn = 'createdOn',
    id = 'id',
    modifiedBy = 'modifiedBy',
    modifiedOn = 'modifiedOn',
    name = 'name',
    state = 'state',
    type = 'type',
}
