import React from 'react';
import MaterialButton, { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import { createButtonTestProps } from '../Utils/testProps';

export interface IButtonProps extends MaterialButtonProps {
    testName: string,
    testId?: string,
}

export const Button: React.FunctionComponent<IButtonProps> = ({ testName, testId, ...materialButtonProps }) =>
    <MaterialButton
        {...materialButtonProps}
        {...createButtonTestProps(testName, testId)} />
    ;

export default Button;
