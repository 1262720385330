import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';

export const MinOperatingHoursYearViolationAgreement: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'payment.minimumOperatingHoursPerYearViolationAgreement';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Min Ophs/a Violation Agreement'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

const options: IFormOption[] = [
    formOption('Bill up to 30% of defined min. ophs'),
    formOption('Bill up to 35% of defined min. ophs'),
    formOption('Bill up to 40% of defined min. ophs'),
    formOption('Bill up to 45% of defined min. ophs'),
    formOption('Bill up to 50% of defined min. ophs'),
    formOption('Bill up to 55% of defined min. ophs'),
    formOption('Bill up to 60% of defined min. ophs'),
    formOption('Bill up to 70% of defined min. ophs'),
    formOption('Bill up to 75% of defined min. ophs'),    
    formOption('Bill up to 80% of defined min. ophs'),
    formOption('Bill up to 85% of defined min. ophs'),
    formOption('Bill up to 90% of defined min. ophs'),
    formOption('Bill up to 95% of defined min. ophs'),
    formOption('Bill up to 100% of defined min. ophs'),
    formOption('Bill predefined amount if min. oph not reached'),
    formOption('Call-off of Packages as per min. ophs'),
    formOption('Contract Suspension'),
    formOption('Parties will agree on consequences'),
];
