import React, { FC } from 'react';
import { ConfigurationFormButtonsProps } from './ConfigurationFormButtons';
import { StyledComponentProps } from '@material-ui/core';
import { Button } from '../Buttons/Button';

export const Save: FC<ConfigurationFormButtonsProps & StyledComponentProps> =
    props =>
        <Button
            testName='save'
            variant='outlined'
            color='primary'
            disabled={props.loading}
            onClick={props.onSave}
            className={props.classes.button}
        >
            Save
        </Button>;
