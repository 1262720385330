import * as React from 'react';
import './App.scss';

import View from './components/View/View';

import { Route, Router } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Configuration from './components/Configuration/Configuration';
import Dashboard from './components/Dashboard/Dashboard';
import Models from './components/Models/Models';
import Model from './components/Model/Model';
import Users from './components/Users/Users';
import Settings from './components/Settings/Settings';
import history from './data/history';
import Branding from "./components/View/Branding/Branding";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authenticateUser } from "./data/auth";
import { connect } from "react-redux";
import { storeUser } from "./data/actions/users";
import { Authority, IUserInfo } from "./data/model/user";
import { Authorized } from "./components/authorization";
import { ReportsPage } from './components/ReportsPage/ReportsPage';
import AmendmentDetail from "./components/Model/AmendmentDetail";
import { getModelDetails, IModelAction } from "./data/actions/models";
import { theme } from './theme';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import CatalogPage from './components/CatalogPage/CatalogPage';
import EditConfigurationPage from './components/EditConfigurationPage/EditConfigurationPage';
import ActualizationPage from './components/ActualizationPage/ActualizationPage';
import ActualizationOverviewPage from './components/ActualizationOverviewPage/ActualizationOverviewPage';

const { READ_MODELS, READ_CATALOGS, READ_REPORTS, WRITE_ACTUALIZATION } = Authority;

interface IAppProperties {
    setUser: (user:IUserInfo) => void
    getOne: (id: number) => ThunkAction<Promise<Action>, {}, {}, IModelAction>
}

class App extends React.Component<IAppProperties, any> {

    public state = {
        loaded : false,
        authenticated : false
    };

    public componentDidMount(): void {
        const {setUser} = this.props;

        this.setState({loaded : false, authenticated : false});
        authenticateUser().then((user) => {
            if (isUserAuthorized(user)) {
                this.setState({ loaded: true, authenticated: true });
                setUser(user);
            } else {
                this.setState({ loaded: true, authenticated: false });
            }
        }).catch((err) => {
            const isRedirect = err && err.redirecting;
            const loaded = !isRedirect;
            this.setState({loaded, authenticated : false});
        });
    }

    public render(): React.ReactElement {

        const {loaded, authenticated} = this.state;

        return (
            <MuiThemeProvider theme={theme}>
                {loaded && authenticated ? this.renderLoadedApp() : (loaded ? this.renderUnauthenticatedApp() : this.renderLoadingApp())}
            </MuiThemeProvider>
        );
    }

    private renderLoadingApp() : React.ReactFragment {
        return <React.Fragment>
            <div className={'auth-loading'}>
                <Branding />
                <CircularProgress />
            </div>
        </React.Fragment>;
    }

    private renderUnauthenticatedApp() : React.ReactFragment {
        return <React.Fragment>
            <div className={'auth-loading'}>
                <Branding />
                <h1 className={'error'}>Authentication failed</h1>
                <div>Please try again and <a href="/">reload</a> or contact your <a href="mailto:myac-support@innio.com">Administrator</a> to request access.</div>
            </div>
        </React.Fragment>;
    }

    private renderLoadedApp() : React.ReactFragment {
        return <Router history={history}>
            <React.Fragment>
                <Authorized for={READ_MODELS}>
                    <Route exact path="/" render={
                        props => <View testName="dashboard"><Dashboard {...props} /></View>
                    } />
                </Authorized>
                <Authorized for={READ_MODELS}>
                    <Route exact path="/dashboard" render={
                        props => <View testName="dashboard"><Dashboard {...props} /></View>
                    } />
                </Authorized>
                <Authorized for={READ_MODELS}>
                    <Route exact path="/models" render={
                        props => <View testName="models"><Models {...props} /></View>
                    } />
                </Authorized>
                <Authorized for={READ_MODELS}>
                    <Route exact path="/models/:modelID" render={
                        props =>
                            <View testName="models" testId={props.match.params.modelID}>
                                <Model {...props} />
                            </View>
                    } />
                </Authorized>

                {
                    // The following "edit" pages should be authorized for "write"
                    // roles. At this point however there is not enough information
                    // about the model version to determine whether the user can
                    // modify it.
                }

                <Route
                    exact
                    path="/models/:modelID/amendment-details"
                    render={
                        navProps =>
                            <View testName="amendmentDetails" testId={navProps.match.params.modelID}>
                                <AmendmentDetail {...this.props} {...navProps}/>
                            </View>
                    }
                />
                <Route
                    exact
                    path="/models/:modelID/configuration/new"
                    render={
                        props => {
                            const modelId = Number.parseInt(props.match.params.modelID, 10);
                            return (
                                <View testName="newConfiguration" testId={modelId}>
                                    <EditConfigurationPage modelId={modelId}/>
                                </View>
                            );
                        }
                    }
                />
                <Authorized for={READ_MODELS}>
                    <Route
                        exact
                        path="/models/:modelID/configuration/view/:configurationID/:tabID?/:action?"
                        render={
                            props => {
                                const { modelID, configurationID, tabID, action } = props.match.params;
                                const testId = [modelID, configurationID, tabID, action]
                                    .filter(param => !!param)
                                    .join(':');

                                return (
                                    <View testName="viewConfiguration" testId={testId}>
                                        <Configuration {...props} />
                                    </View>
                                )
                            }
                        }
                    />
                </Authorized>
                <Route
                    exact
                    path="/models/:modelID/configuration/edit/:configurationID"
                    render={
                        props => {
                            const params = props.match.params;
                            const modelID = Number.parseInt(params.modelID, 10);
                            const configurationID = Number.parseInt(params.configurationID, 10);
                            const testId = modelID + ':' + configurationID;
                            return (
                                <View testName="editConfiguration" testId={testId}>
                                    <EditConfigurationPage modelId={modelID} configurationId={configurationID} />
                                </View>
                            );
                        }
                    }
                />
                <Authorized for={READ_CATALOGS}>
                    <Route exact path="/catalogs" render={
                        () => <View testName="catalogs"><CatalogPage /></View>
                    } />
                </Authorized>
                <Route exact path="/users" render={
                    props => <View testName="users"><Users {...props} /></View>
                } />
                <Route exact path="/settings" render={
                    props => <View testName="settings"><Settings {...props} /></View>
                } />
                <Authorized for={READ_REPORTS}>
                    <Route exact path="/reports" render={
                        () => <View testName="reports"><ReportsPage/></View>
                    }/>
                </Authorized>
                <Authorized for={WRITE_ACTUALIZATION}>
                    <Route exact path="/actualization" render={props =>
                        <View testName="actualization"><ActualizationOverviewPage {...props}/></View>
                    }
                    />
                </Authorized>
                <Authorized for={WRITE_ACTUALIZATION}>
                    <Route
                        exact
                        path='/actualization/new'
                        render={props =>
                            <View testName='actualization'>
                                <ActualizationPage {...props}/>
                            </View>
                        }
                    />
                </Authorized>
                <Authorized for={WRITE_ACTUALIZATION}>
                    <Route
                        exact
                        path='/actualization/view/:id'
                        render={props => {
                            const actualizationId = Number.parseInt(props.match.params.id, 10);
                            return (
                                <View testName='actualization'>
                                    <ActualizationPage actualizationId={actualizationId} {...props}/>
                                </View>
                            );
                        }}
                    />
                </Authorized>
            </React.Fragment>
        </Router>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (user: IUserInfo) => {
            dispatch(storeUser(user));
        },
        getOne: id => {
            return dispatch(getModelDetails(id));
        }
    };
};

export default connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)

function isUserAuthorized(user: IUserInfo): boolean {
    return user && user.authorities && user.authorities.includes(Authority.ACCESS_APPLICATION);

}
