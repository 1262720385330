import React, { FC } from 'react';
import { TableHead, TableRow } from '@material-ui/core';
import { StyledTableHeaderCell } from '../StyledTableHeaderCell/StyledTableHeaderCell';

export const TableHeader: FC =
    () =>
        <TableHead>
            <TableRow>
                <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                <StyledTableHeaderCell>Opportunity&nbsp;#</StyledTableHeaderCell>
                <StyledTableHeaderCell>Contract Category</StyledTableHeaderCell>
                <StyledTableHeaderCell>Engine Type</StyledTableHeaderCell>
                <StyledTableHeaderCell>Region</StyledTableHeaderCell>
                <StyledTableHeaderCell>Contract Manager</StyledTableHeaderCell>
                <StyledTableHeaderCell>Actualization Period</StyledTableHeaderCell>
            </TableRow>
        </TableHead>;
