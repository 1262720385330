import React from 'react';
import { CustomerObligationsFormProps } from './CustomerObligationsProps';
import { Configuration } from '../../api/model-service/model-service';
import { Formik, FormikActions, FormikProps } from '../Formik';
import CustomerObligations from './CustomerObligations';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { ObjectSchema } from '../../utils/validators';
import { getCustomerObligationsSchema } from './schema';
import { Form } from '../Form/Form';
import { assoc } from 'lodash/fp';

export class CustomerObligationsForm extends ConfigurationForm<CustomerObligationsFormProps> {
    public render() {
        const props = this.props;
        const schema = getCustomerObligationsSchema();

        const initialValues = props.configuration.customerObligation
            ? props.configuration
            : this.defaultValues();
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(config, actions) => this.handleSubmit(config, actions, schema)}
            >
                {(formikProps: FormikProps<Configuration>) =>
                    <Form testName='customerObligations' onSubmit={formikProps.handleSubmit} noValidate>
                        <CustomerObligations
                            {...props} formikProps={formikProps}
                        />
                        <ConfigurationFormButtons
                            loading={formikProps.isSubmitting}
                            onBack={props.onBack}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onNext={() => this.submitForm(formikProps, 'NEXT')}
                            onReadOnlyNext={props.onNext}
                            onSecondaryComplete={this.isConfigurationComplete()
                                ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                : null
                            }
                        />
                    </Form>
                }
            </Formik>
        );
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }

    private handleSubmit(configuration: Configuration,
                         actions: FormikActions<Configuration>,
                         schema: ObjectSchema<Partial<Configuration>>): void {

        this.props.onSubmit(schema.cast(configuration) as Configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private defaultValues(): Configuration {
        return this.props.obligations.reduce(
            (configuration, obligation) =>
                assoc(obligation.name, obligation.default, configuration),
            this.props.configuration,
        );
    }
}
