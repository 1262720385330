import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';

declare const REDUX_DEVTOOLS_COMPOSE: Function | null;

const composeEnhancers = REDUX_DEVTOOLS_COMPOSE || compose;
const enhancers = composeEnhancers(applyMiddleware(thunk));
const store = createStore(reducers, enhancers);

export type State = ReturnType<typeof reducers>;

export default store;
