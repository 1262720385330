import React, {FC} from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { UnitProps } from './AddUnitInterfaces';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikProps } from '../Formik';
import { Configuration } from '../../api/model-service/model-service';
import { CaseAuthorized } from '../authorization/CaseAuthorized';
import { Authority } from '../../data/model/user';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';

export const UnitConfig: FC<FormikProps<Configuration> & UnitProps> = props => {
    const {
        index,
        disabled,
        handleChange,
        handleBlur,
        onDelete,
        showReadCounters,
        minOphOrStarts,
    } = props;
    const serialNumberName = `units[${index}].serialNumber`;
    const unitStartCounterName = `units[${index}].unitStartCounter`;
    const unitEndCounterName = `units[${index}].unitEndCounter`;
    const expectedOperatingHoursPerYearName = `units[${index}].expectedOperatingHoursPerYear`;
    const expectedStartsPerYearName = `units[${index}].expectedStartsPerYear`;
    const unitDateReadName = `units[${index}].unitReadDate`;
    const unitReadCounterName = `units[${index}].unitReadCounter`;

    return (
        <Grid
            key={`configuration_unit_${index}`}
            item
            container
            spacing={24}
            xs={12}
            className="unit"
        >
        {!disabled && props.values.units[index] && (
            <Grid item className="deleteIcon">
                <DeleteIcon
                    color="disabled"
                    onClick={() => { onDelete(index); }}
                />
            </Grid>
        )}
        <Grid item xs={12} sm={3} md={3}>
            <TextField
                id={`units-${index}`}
                label={`Unit ${index + 1} Serial Number`}
                name={serialNumberName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder='e.g. e4e6f611-429a-91b8-880a38566e0d'
                fullWidth
                value={inputValue(serialNumberName, props)}
                error={!!inputError(serialNumberName, props)}
                helperText={inputError(serialNumberName, props) }
                type='text'
                margin='dense'
            />
        </Grid>
        <Grid item xs={6} sm={2} md={2}>
            <TextField
                    id='unitStartCounter'
                    label='Unit Start Counter'
                    name={unitStartCounterName}
                    disabled={disabled}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    value={inputValue(unitStartCounterName, props)}
                    error={!!inputError(unitStartCounterName, props)}
                    helperText={inputError(unitStartCounterName, props)}
                    type='number'
                    margin='dense'
                    placeholder='0-300000'
                    InputProps = {{
                        endAdornment:(
                            <InputAdornment position="end">
                                (oph)
                            </InputAdornment>)
                    }}
            />

        </Grid>
        <Grid item xs={6} sm={2} md={2}>
            <TextField
                id='unitEndCounter'
                label='Unit End Counter'
                name={unitEndCounterName}
                disabled={disabled}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={inputValue(unitEndCounterName, props)}
                error={!!inputError(unitEndCounterName, props)}
                helperText={inputError(unitEndCounterName, props)}
                type='number'
                margin='dense'
                placeholder='1000-300000'
                InputProps = {{
                    endAdornment:(
                        <InputAdornment position="end">
                            (oph)
                        </InputAdornment>)
                }}
            />
        </Grid>
        <Grid item xs={6} sm={2} md={2}>
            <TextField
                id='expectedOperatingHoursPerYear'
                label='Expected Ophs/a'
                name={expectedOperatingHoursPerYearName}
                disabled={disabled}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={inputValue(expectedOperatingHoursPerYearName, props)}
                error={!!inputError(expectedOperatingHoursPerYearName, props)}
                helperText={inputError(expectedOperatingHoursPerYearName, props)}
                type='number'
                margin='dense'
                placeholder={`${minOphOrStarts || 0}-8,760`}
                />
        </Grid>
        <Grid item xs={6} sm={2} md={2}>
            <TextField
                id='expectedStartsPerYear'
                label='Expected Starts/a'
                name={expectedStartsPerYearName}
                disabled={disabled}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={inputValue(expectedStartsPerYearName, props)}
                error={!!inputError(expectedStartsPerYearName, props)}
                helperText={inputError(expectedStartsPerYearName, props)}
                type='number'
                margin='dense'
                placeholder={`${minOphOrStarts || 0}-99999`}
            />
        </Grid>
        {showReadCounters &&
            <CaseAuthorized for={Authority.WRITE_UNIT_READ_COUNTER}>
            {authorizedToEditReadCounter => {
                const inputsDisabled = !authorizedToEditReadCounter || disabled;
                return (
                    <>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                id='unitReadDate'
                                label='Counter Reading Date'
                                name={unitDateReadName}
                                disabled={inputsDisabled}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                value={inputValue(unitDateReadName, props)}
                                error={!!inputError(unitDateReadName, props)}
                                helperText={inputError(unitDateReadName, props)}
                                type='date'
                                margin='dense'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: '9999-12-31',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField
                                id='unitReadCounter'
                                label='Counter Reading'
                                name={unitReadCounterName}
                                disabled={inputsDisabled}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                value={inputValue(unitReadCounterName, props)}
                                error={!!inputError(unitReadCounterName, props)}
                                helperText={inputError(unitReadCounterName, props) }
                                type='number'
                                margin='dense'
                                placeholder={`${inputValue(unitStartCounterName, props)}-${props.values.units[index].unitEndCounter}`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps = {{
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            (oph)
                                        </InputAdornment>)
                                }}
                            />
                        </Grid>
                    </>
                );
            }}
            </CaseAuthorized>
        }
    </Grid>
    );
}
