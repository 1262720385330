import React, { FC } from 'react';
import { StandardScopeProps } from './StandardScopeProps';
import {
    deduplicateAttributes,
    isEngineOrSparkPlugsActivity,
    isNotMajorOverhaul,
    isPartsActivity,
} from './activity-filters';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';
import { Configuration } from '../../api/model-service/model-service';
import StandardActivitiesForm from '../StandardActivitiesForm/StandardActivitiesForm';

export const MsaPreventiveCorrectiveStandardScope: FC<StandardScopeProps> =
    ({ onSubmit, ...remainingProps }) => {
        const handleSubmitWithZeroValue: typeof onSubmit = configuration => {
            const zeroValueActivities = configuration.standardScope.map(activity => {
                return {
                    ...activity,
                    value: 0,
                };
            });

            const updatedConfiguration: Configuration = {
                ...configuration,
                standardScope: zeroValueActivities,
            };

            return onSubmit(updatedConfiguration);
        };

        return (
            <StandardActivitiesForm
                defaultActivitiesFilter={getDefaultActivities}
                onSubmit={handleSubmitWithZeroValue}
                {...remainingProps}
            />
        );
    }
;

function getDefaultActivities(activities: CatalogActivity[]): CatalogActivity[] {
    return deduplicateAttributes(
        activities
            .filter(isEngineOrSparkPlugsActivity)
            .filter(isPartsActivity)
            .filter(isNotMajorOverhaul),
    );
}
