import { combineReducers, createAction, createReducer } from '@reduxjs/toolkit';
import { CatalogOverview, CatalogType, GetCatalogOverviewsRequest } from '../../api/catalog-service/catalog-overview';
import { Page } from '../../api/types';

interface CatalogOverviewsById {
    [key: string]: CatalogOverview,
}

interface CatalogPage {
    request: GetCatalogOverviewsRequest,
    loading: boolean,
    error: string,
    data: Page<number>,
}

type CatalogPagesByType = Record<CatalogType, CatalogPage>;

interface CatalogOverviewsState {
    catalogsById: CatalogOverviewsById,
    catalogPagesByType: CatalogPagesByType,
}

export const startLoading = createAction(
    'catalogOverviews/startLoading',
    (type: CatalogType, request: GetCatalogOverviewsRequest) => ({
        payload: { type, request },
    }),
);

export const errorResponse = createAction(
    'catalogOverviews/errorResponse',
    (type: CatalogType, error: Error) => ({
        payload: { type, error },
    }),
);

export const successResponse = createAction(
    'catalogOverviews/successResponse',
    (type: CatalogType, data: Page<CatalogOverview>) => ({
        payload: { type, data },
    }),
);

export const detailsSuccessResponse = createAction(
    'catalogOverviews/detailsSuccessResponse',
    (data: CatalogOverview[]) => ({
        payload: { data },
    }),
);

const catalogsByIdReducer = createReducer<CatalogOverviewsById>({}, builder =>
    builder
        .addCase(successResponse, (state, action) => {
            return updateCatalogsDetails(state, action.payload.data.content);
        })
        .addCase(detailsSuccessResponse, (state, action) => {
            return updateCatalogsDetails(state, action.payload.data);
        }),
);

function updateCatalogsDetails(state: CatalogOverviewsById, catalogs: CatalogOverview[]): CatalogOverviewsById {
    const stateCopy = { ...state };
    catalogs.forEach(catalog => {
        stateCopy[catalog.id] = catalog;
    });
    return stateCopy;
}

const initialCatalogPage: CatalogPage = {
    request: null,
    loading: false,
    error: null,
    data: null,
};

const initialPagesByType: CatalogPagesByType = {
    ACTIVITY: initialCatalogPage,
    REGION: initialCatalogPage,
    EQUIPMENT: initialCatalogPage,
};

const catalogPagesByTypeReducer = createReducer<CatalogPagesByType>(initialPagesByType, builder =>
    builder
        .addCase(startLoading, (state, action) => {
            const type = action.payload.type;
            const newPage: CatalogPage = {
                ...state[type],
                loading: true,
                request: action.payload.request,
            };
            return {
                ...state,
                [type]: newPage,
            };
        })
        .addCase(errorResponse, (state, action) => {
            const type = action.payload.type;
            const newPage: CatalogPage = {
                ...state[type],
                loading: false,
                error: action.payload.error.message,
            };
            return {
                ...state,
                [type]: newPage,
            };
        })
        .addCase(successResponse, (state, action) => {
            const type = action.payload.type;
            const responseData = action.payload.data;
            const catalogIds = responseData.content.map(catalog => catalog.id);

            const newPage: CatalogPage = {
                ...state[type],
                loading: false,
                error: null,
                data: {
                    ...responseData,
                    content: catalogIds,
                },
            };

            return {
                ...state,
                [type]: newPage,
            };
        }),
);

const catalogOverviewsReducer = combineReducers<CatalogOverviewsState>({
    catalogsById: catalogsByIdReducer,
    catalogPagesByType: catalogPagesByTypeReducer,
});
export default catalogOverviewsReducer;
