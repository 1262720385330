import { getActualization as getActualizationApi } from '../../api/model-service/actualization';
import { AppThunk } from '../app-thunk';
import { reportModelError } from '../error-actions';
import { errorResponse, startLoading, successResponse } from './createActualizationSlice';

export const getActualization = (id: number): AppThunk => dispatch => {
    dispatch(startLoading());
    return getActualizationApi(id)
        .then(response => {
            dispatch(successResponse(response))
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportModelError(err));
            throw err;
        });
};
