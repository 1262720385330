import React, {FC} from 'react';

import Grid from '@material-ui/core/Grid';
import { CustomerObligationsProps } from './CustomerObligationsProps';
import { RadioGroupField } from '../RadioGroupField/RadioGroupField';
import { inputValue } from '../../utils/inputValue';
import { booleanOptions } from '../../utils/booleanOptions';
import { isModelAsSold } from '../../utils/isModelAsSold';

const CustomerObligations: FC<CustomerObligationsProps> = props => {
    const { formikProps, model, obligations } = props;
    const disabled = isModelAsSold(model);

    return (
        <React.Fragment>
            <Grid container spacing={24}>
                {obligations.map(item => {
                    return (<Grid key={item.name} item xs={6} sm={3}>
                                <RadioGroupField
                                    disabled={disabled}
                                    name={item.name}
                                    label={item.label}
                                    options={booleanOptions}
                                    value={inputValue(item.name, formikProps)}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                />
                            </Grid>);
                })}
            </Grid>
        </React.Fragment>
    );
};

export default CustomerObligations;
