import React, { Component, ReactNode } from 'react';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { Button } from '../Buttons/Button';
import { flatMap, groupBy, isEmpty, omit, uniqWith } from 'lodash';
import { EditStandardScopeDialog } from '../EditStandardScopeDialog';
import { EditStandardScopeTable } from '../EditStandardScopeTable/EditStandardScopeTable';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';
import { Activity, Configuration } from '../../api/model-service/model-service';
import { FormikProps } from '../Formik';
import { ErrorFormHelperText } from '../ErrorFormHelperText/ErrorFormHelperText';
import { inputError } from '../../utils/inputError';
import { Add } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { StandardActivityCard } from '../StandardActivityCard/StandardActivityCard';

class StandardActivitiesInputs0 extends Component<StandardActivitiesInputsProps & StyledComponentProps> {

    public componentDidMount(): void {
        const { formikProps, defaultActivities } = this.props;
        const currentActivities = formikProps.values.standardScope;
        const hasNoCurrentActivities = isEmpty(currentActivities);
        const hasDefaultActivities = !isEmpty(defaultActivities);

        if (hasNoCurrentActivities && hasDefaultActivities) {
            const defaultScopes = defaultActivities
                .map(activity => this.catalogToDomainActivity(activity));
            this.updateActivities(defaultScopes);
        }
    }

    public render(): ReactNode {
        const disabled = this.props.disabled;
        const formikProps = this.props.formikProps;
        const configuration = formikProps.values;
        const allActivitiesAsScopes = this.getAllActivitiesAsScopes();
        const error = inputError('standardScope', formikProps);

        const activitiesByScope: Record<string, Activity[]> = groupBy(configuration.standardScope, 'scope')
        const scopes = Object.keys(activitiesByScope);
        scopes.sort();

        return (
            <>
                {!disabled
                    ? <EditStandardScopeDialog
                        allActivities={allActivitiesAsScopes}
                        initialActivities={configuration.standardScope}
                        onSubmit={selectedActivities => {
                            this.handleChangeDialogActivities(selectedActivities);
                        }}
                        renderOpenButton={open =>
                            <Button
                                testName='configureScope'
                                color='primary'
                                onClick={open}
                                disabled={formikProps.isSubmitting}
                            >
                                Configure Scope
                                <Add className={this.props.classes.rightIcon}/>
                            </Button>
                        }
                    />
                    : null
                }
                <Grid container spacing={16} classes={{ container: this.props.classes.cardContainer }}>
                    {scopes.map((scope, idx) => {
                        const activities = activitiesByScope[scope];
                        return (
                            <Grid key={scope} item xs={12} sm={6} md={4}>
                                <EditStandardScopeDialog
                                    allActivities={allActivitiesAsScopes}
                                    initialActivities={configuration.standardScope}
                                    initialScope={scope}
                                    onSubmit={selectedActivities => {
                                        this.handleChangeDialogActivities(selectedActivities);
                                    }}
                                    renderOpenButton={open =>
                                        <StandardActivityCard
                                            testId={'' + idx}
                                            activities={activities}
                                            disabled={disabled}
                                            onOpenEdit={open}
                                            onDelete={() => this.removeActivitiesByScope(scope)}
                                        />
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <EditStandardScopeTable
                            allActivities={allActivitiesAsScopes}
                            selectedActivities={configuration.standardScope}
                            disabled={disabled}
                            onChange={updatedActivities => {
                                this.handleChangeTableActivities(updatedActivities);
                            }}
                        />
                    </Grid>
                    {typeof error === 'string' && !!error
                        ? <Grid item xs={12}>
                            <ErrorFormHelperText testName='standardScope'>{error}</ErrorFormHelperText>
                        </Grid>
                        : null
                    }
                </Grid>
            </>
        );
    }

    private removeActivitiesByScope(scope: string): void {
        const remainingActivities = this.props.formikProps.values.standardScope
            .filter(activity => activity.scope !== scope);
        this.updateActivities(remainingActivities);
    }

    private updateActivities(activities: Activity[]): void {
        this.props.formikProps.setFieldValue('standardScope', activities);
    }

    private getAllActivitiesAsScopes(): Activity[] {
        return this.props.catalogActivities
            .map(activity => this.catalogToDomainActivity(activity))
    }

    private handleChangeDialogActivities(selectedActivities: Activity[]): void {
        const syncedActivities = this.syncSelectedScopesAndServices(selectedActivities);
        this.updateActivities(syncedActivities);
    }

    private syncSelectedScopesAndServices(selectedActivities: Activity[]): Activity[] {
        const selectedScopesAndServices = uniqWith(selectedActivities, isEqualScopeAndService);
        const activities = flatMap(selectedScopesAndServices, activityKey => {
            const scope = activityKey.scope;
            const service = activityKey.service;

            const current = this.findCurrentActivitiesByScopeService(scope, service);
            if (!isEmpty(current)) {
                return current;
            }

            const attribute = this.findFirstNonEmptyAttributeInCatalog(scope, service);
            const fromCatalog: Activity[] = this.findCatalogActivitiesByScopeServiceEqualOrEmptyAttribute(scope, service, attribute)
                .map(activity => this.catalogToDomainActivity(activity));

            return this.syncWithPersistedActivities(fromCatalog);
        });
        return activities;
    }

    private handleChangeTableActivities(selectedActivities: Activity[]): void {
        const syncedActivities = this.syncWithPersistedActivities(selectedActivities);
        this.updateActivities(syncedActivities);
    }

    private syncWithPersistedActivities(activities: Activity[]): Activity[] {
        const persistedOrNewActivities = activities.map(activity => {
            const persisted = this.findEqualPersistedActivity(activity);
            if (persisted) {
                return persisted;
            }

            return activity;
        });

        return persistedOrNewActivities;
    }

    private findEqualPersistedActivity(activity: Activity): Activity {
        return this.props.persistedActivities.find(persistedActivity => {
            return isEqualActivities(activity, persistedActivity);
        })
    }

    private findFirstNonEmptyAttributeInCatalog(scope: string, service: string): string {
        const activities: CatalogActivity[] = this.props.catalogActivities;

        const matchingActivities = activities.filter(activity => {
            return activity.scope === scope &&
                activity.service === service &&
                !isEmpty(activity.attribute);
        });
        if (isEmpty(matchingActivities)) {
            return null;
        }

        matchingActivities.sort((a, b) => {
            return a.attribute.localeCompare(b.attribute);
        });
        return matchingActivities[0].attribute;
    }

    private findCurrentActivitiesByScopeService(scope: string, service: string): Activity[] {
        const currentActivities = this.getCurrentActivities();
        return currentActivities.filter(activity => {
            return activity.scope === scope &&
                activity.service === service;
        });
    }

    private findCatalogActivitiesByScopeServiceEqualOrEmptyAttribute(scope: string,
                                                                     service: string,
                                                                     attribute: string): CatalogActivity[] {
        const { catalogActivities } = this.props;
        return catalogActivities.filter(activity => {
            return activity.scope === scope &&
                activity.service === service &&
                (activity.attribute === attribute || isEmpty(activity.attribute));
        })
    }

    private getCurrentActivities(): Activity[] {
        const configuration = this.props.formikProps.values;
        return configuration.standardScope;
    }

    private catalogToDomainActivity(catalogActivity: CatalogActivity): Activity {
        const { attribute, ...remainingFields } = catalogActivity;
        return {
            schedulingType: 'OPH',
            occurrence: null,
            fixedOccurrence: false,
            frequencyModified: false,
            frequencyOffset: null,
            additionalAttribute: attribute,
            ...remainingFields,
        };
    }
}

const styles: StyleRules = {
    cardContainer: {
        paddingBottom: 30,
        paddingTop: 30,
    },
    rightIcon: {
        marginLeft: '10px',
    },
};

export const StandardActivitiesInputs = withStyles(styles)(StandardActivitiesInputs0);

export interface StandardActivitiesInputsProps {
    disabled: boolean,
    formikProps: FormikProps<Configuration>,
    persistedActivities: Activity[],
    defaultActivities: CatalogActivity[],
    catalogActivities: CatalogActivity[],
}

function isEqualScopeAndService(a: Activity, b: Activity): boolean {
    return a.scope === b.scope &&
        a.service === b.service;
}

function isEqualActivities(a: Activity, b: Activity): boolean {
    return a.scope === b.scope &&
        a.service === b.service &&
        a.additionalAttribute === b.additionalAttribute &&
        a.frequency === b.frequency &&
        a.type === b.type;
}
