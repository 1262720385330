import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { EngineFieldProps } from './EngineFieldProps';

export const MaintenanceSection: FC<EngineFieldProps> =
    ({ formikProps, container, options, onChange, disabled }) => {
        const name = 'engineInformation.maintenanceSection';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Maintenance Section'
                    placeholder='Select maintenance section'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={onChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };
