import { createURLSearchParams } from '../utils/url-search-params';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';
import { fetch } from '../fetch';

declare const CATALOG_ROOT: string;

export function getCatalogEngineInformation(request: GetCatalogEngineInformationRequest):
    Promise<CatalogEngineInformationResponse> {

    const url = new URL(`${CATALOG_ROOT}/engine-information`);
    const query = createURLSearchParams(request);
    url.search = query.toString();

    return fetch(url.toString())
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetCatalogEngineInformationRequest {
    activityCatalogId: number,
    equipmentCatalogId: number,
}

export interface CatalogEngineInformationResponse {
    engineInformation: CatalogEngineInformation[],
    missingActivityErrors: MissingActivityError[],
}

export interface CatalogEngineInformation {
    id: number,
    type: string,
    version: string,
    maintenanceSection: string,
    maintenanceSchedule: string,
    equipmentModel: string,
    electricalPower: number,
    mechanicalPower: number,
    oilVolume: number,
}

export interface MissingActivityError {
    equipmentModel: string,
    message: string,
}
