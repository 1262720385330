import React, { Component, ReactNode } from 'react';
import { Activity } from '../../api/model-service/model-service';
import Confirm from '../Utils/Confirm';
import { ActionMenuItem } from '../ActionMenu/ActionMenu';
import { EditAdditionalActivityDialog } from '../EditAdditionalActivityDialog/EditAdditionalActivityDialog';
import { ActionCard } from '../ActionCard/ActionCard';
import { formatCurrency, formatNumber } from '../../data/utils/number';
import { Typography } from '@material-ui/core';
import { activityTypeOptions } from '../../utils/activityTypeOptions';
import { hasValue } from '../../data/utils/form';

export class AdditionalActivityCard extends Component<AdditionalActivityCardProps, ComponentState> {

    public state = {
        openEdit: false,
        openDelete: false,
    };

    public render(): ReactNode {
        const { activity, testId } = this.props;
        const actions = this.getActions();

        return (
            <>
                <ActionCard
                    testName='additionalScope'
                    testId={testId}
                    title={activity.scope}
                    actions={actions}
                >
                    <Typography variant='caption'>
                        Service: <strong>{activity.service}</strong>
                        <br/>
                        Type: <strong>{formatType(activity)}</strong>
                        <br/>
                        Frequency: <strong>{formatFrequency(activity)}</strong>
                        <br/>
                        Occurrence: <strong>{formatOccurrence(activity)}</strong>
                        <br/>
                        Value: <strong>{formatValue(activity)}</strong>
                    </Typography>
                </ActionCard>
                <EditAdditionalActivityDialog
                    activity={activity}
                    open={this.state.openEdit}
                    onCancel={() => this.closeEdit()}
                    onSubmit={updatedActivity => {
                        this.props.onChange(updatedActivity);
                        this.closeEdit();
                    }}
                />
                <Confirm
                    testName='confirmDelete'
                    title='Delete Additional Scope'
                    message={
                        `Are you sure you want to delete ${activity.scope} ${activity.service}? It cannot be undone.`
                    }
                    action={() => {
                        this.props.onDelete();
                        this.closeDelete();
                    }}
                    open={this.state.openDelete}
                    handleClose={() => this.closeDelete()}
                />
            </>
        );
    }

    private getActions(): ActionMenuItem[] {
        return this.props.disabled
            ? []
            : [
                {
                    label: 'Edit',
                    testName: 'edit',
                    onClick: () => this.openEdit(),
                },
                {
                    label: 'Delete',
                    testName: 'delete',
                    onClick: () => this.openDelete(),
                },
            ];
    }

    private openEdit(): void {
        this.setState({ openEdit: true });
    }

    private closeEdit(): void {
        this.setState({ openEdit: false });
    }

    private openDelete(): void {
        this.setState({ openDelete: true });
    }

    private closeDelete(): void {
        this.setState({ openDelete: false });
    }
}

export interface AdditionalActivityCardProps {
    testId: string,
    activity: Activity,
    disabled?: boolean,
    onChange: (activity: Activity) => void,
    onDelete: () => void,
}

interface ComponentState {
    openEdit: boolean,
    openDelete: boolean,
}

function formatType(activity: Activity): string {
    return activityTypeOptions.find(option => option.value === activity.type).label;
}

function formatOccurrence(activity: Activity): string {
    return hasValue(activity.occurrence) && activity.occurrence > 0
        ? formatNumber(activity.occurrence, 0, 0)
        : 'Contract Duration';
}

function formatFrequency(activity: Activity): string {
    if (activity.schedulingType === 'YEARS') {
        return 'Yearly';
    }

    const oph = formatNumber(activity.frequency, 0, 0);
    return oph + ' Oph';
}

function formatValue(activity: Activity): string {
    if (activity.type !== 'INNIO_LABOR') {
        return formatCurrency('EUR', activity.value);
    }

    const hours = formatNumber(activity.value, 0, 2);
    return `${hours} hours`;
}
