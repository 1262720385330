import React from 'react';
import MaterialTablePagination, {
    TablePaginationProps as MaterialTablePaginationProps
} from '@material-ui/core/TablePagination';
import { createButtonTestProps, createInputTestProps } from '../Utils/testProps';

export const TablePagination: React.FunctionComponent<MaterialTablePaginationProps> = props => {
    const { backIconButtonProps, nextIconButtonProps, SelectProps, ...remainingProps } = props;

    const backButtonTestProps = createButtonTestProps("prevPage");
    const nextButtonTestProps = createButtonTestProps("nextPage");
    const selectPageTestProps = createInputTestProps("selectPage");

    return (
        <MaterialTablePagination
            backIconButtonProps={{ ...backIconButtonProps, ...backButtonTestProps }}
            nextIconButtonProps={{ ...nextIconButtonProps, ...nextButtonTestProps }}
            SelectProps={{ ...SelectProps, ...selectPageTestProps }}
            {...remainingProps}
        />
    )
};

export default TablePagination;
