import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { TextField } from '../TextField/TextField';

export const Service: FC<FieldProps> =
    ({ formikProps }) => {
        const name = 'service';
        const error = inputError(name, formikProps);
        return (
            <TextField
                name={name}
                label='Service Name'
                placeholder='Describe the Service'
                value={inputValue(name, formikProps)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!error}
                helperText={error}
                margin='dense'
                fullWidth
            />
        );
    };

