import React, { FC } from 'react';
import { ConfigurationFormButtonsProps } from './ConfigurationFormButtons';
import { StyledComponentProps } from '@material-ui/core';
import { Button } from '../Buttons/Button';

export const ReadOnlyNext: FC<ConfigurationFormButtonsProps & StyledComponentProps> =
    props =>
        <Button
            testName='next'
            variant='contained'
            color='primary'
            disabled={props.loading}
            onClick={props.onReadOnlyNext}
            className={props.classes.button}
        >
            Next
        </Button>;
