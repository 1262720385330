import { Configuration, Unit, Model } from "../../api/model-service/model-service";
import { array, object, ObjectSchema, string, number } from '../../utils/validators';
import { formatNumber } from "../../data/utils/number";
import { isString } from "lodash";

function getUnitValidationSchema(model: Model, config: Configuration): ObjectSchema<Unit> {
    const minOphOrStarts = getMinOphOrStarts(model);
    const validateReadCounters = enableReadCounters(model);

    const unitSchema: ObjectSchema<Unit> = object({
        id: number(),
        serialNumber: shouldValidateSerialNumber(model) ? string()
            .test('unit-serial-number', 'The serial number must be a 7 or 8 digit number', (value) => /^\d{7,8}$/.test(value)) : null,
        unitStartCounter: number()
            .min(0, 'Number must be 0 or positive')
            .max(300000, `Number must be below ${formatNumber(300000, 0, 0)}`)
            .required('Unit Start Counter is required')
            .test('lower-start-counter', 'The Unit Start Counter must be less than the Unit End Counter', function(value) {
                if (!value && value !== 0) {
                    return true;
                }
                
                return parseIntIfString(value) < parseIntIfString(this.parent.unitEndCounter);
            }),

        unitEndCounter: number()
            .min(1000, 'Number must be 1000 or positive')
            .max(300000, `Number must be below ${formatNumber(300000, 0, 0)}`)
            .required('Unit End Counter is required'),
        unitReadCounter: validateReadCounters ? number()
            .test('read-counter-between-start-and-end', 'The Read Counter must be between the Unit Start and End Counters', function(value) {
                if (!value && value !== 0) {
                    return true;
                }

                const readCounter = parseIntIfString(value);

                const startCounter = parseIntIfString(this.parent.unitStartCounter);
                const endCounter = parseIntIfString(this.parent.unitEndCounter);

                return startCounter <= readCounter &&
                        readCounter <= endCounter;
            }): null,
        unitReadDate: validateReadCounters ? string()
            .test('read-date-after-cod', 'The read date must be after the commercial operation date', value => {
                if(!value) {
                    return true;
                }

                return config.term.commercialOperationDate <= value
            }): null,
        expectedOperatingHoursPerYear: number()
            .min(minOphOrStarts, `Annual ophs must be at least ${minOphOrStarts}`)
            .max(8760, `Number must be below ${formatNumber(8760, 0, 0)}`)
            .required('Expected Ophs/a is required'),
        expectedStartsPerYear: number()
            .min(minOphOrStarts, `Number must be at least ${minOphOrStarts}`)
            .max(99999, `Number must be below ${formatNumber(99999, 0, 0)}`,)
            .required('Serial Number is required'),
    });

    return unitSchema;
}

function shouldValidateSerialNumber(model: Model): boolean {
    return model.version === 'OTR' || model.version === 'HANDOVER';
}

export function formValuesSchema(model: Model, config: Configuration): ObjectSchema<Partial<Configuration>> {
    return object().shape({
        units: array().of(getUnitValidationSchema(model, config))
            .min(1),
    });
}

export function enableReadCounters(model: Model): boolean {
    return model.version === 'OTR' || model.version === 'ENDED'
}

export function getMinOphOrStarts(model: Model): number {
    return model.version === 'OTR' || model.version === 'ENDED'
        ? 0
        : 100;
}

function parseIntIfString(numberOrString: number | string): number {
    return isString(numberOrString)
        ? Number.parseInt(numberOrString, 10)
        : numberOrString;
}
