import { createCatalog, CreateCatalogRequest } from '../../api/catalog-service/catalog-overview';
import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { getCatalogOverviewInitialPage } from '../catalog-overview/get-page';
import { reportCatalogError } from '../error-actions';
import { getDefaultCatalog } from '../default-catalog/get-default-catalog';

export const addCatalog = (request: CreateCatalogRequest): AppThunk => dispatch => {
    dispatch(startLoading());
    return createCatalog(request)
        .then(catalog => {
            dispatch(successResponse());
            dispatch(getCatalogOverviewInitialPage(catalog.type))
            dispatch(getDefaultCatalog(catalog.type));
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportCatalogError(err));
            throw err;
        });
};
