import { ContractCategory } from '../api/model-service/model-service';
import { assertNever } from './assertNever';

// TODO use in ContractCategoryInput.tsx
export function contractCategoryName(contractCategory: ContractCategory): string {
    switch (contractCategory) {
        case 'CSA_PREVENTIVE':
            return 'CSA Preventive';
        case 'CSA_PREVENTIVE_AND_CORRECTIVE':
            return 'CSA Preventive & Corrective';
        case 'MSA_PREVENTIVE_AND_CORRECTIVE':
            return 'MSA Preventive & Corrective';
        case 'MSA_PREVENTIVE':
            return 'MSA Preventive';
        default:
            return assertNever('Unknown contract category', contractCategory);
    }
}
