import React, { FC, ReactNode } from 'react';
import Dialog from '../Dialogs/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '../Tables/Table';
import { Button } from '../Buttons/Button';
import {
    DialogActions,
    StyledComponentProps,
    StyleRulesCallback,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import TableDataCell from '../Tables/TableDataCell';
import TableDataRow from '../Tables/TableDataRow';
import { ActualizationCandidate, ActualizationPayload } from '../../api/model-service/actualization';
import { FormikActions } from '../Formik';

const styles: StyleRulesCallback = () => ({
    dialogContent: {
        backgroundColor: '#f5f5f5',
        paddingTop: 20,
    },
    lowerText: {
        marginTop: 20,
    },
});

const SkipOrMaintainCandidatesDialog: FC<SkipOrMaintainCandidatesDialogProps & StyledComponentProps> = (props) => {
    const canSkip = props.payload && props.skippableCandidates &&
        props.payload.candidates.length !== props.skippableCandidates.length;

    return (<Dialog
        testName='SkipOrMaintainCandidates'
        maxWidth='lg'
        open={props.open}
    >
        <DialogTitle>
            Skip or Re-Run candidates
        </DialogTitle>
        <DialogContent classes={{ root: props.classes.dialogContent }}>
            <Typography variant='body1'>
                The following actualization candidates have already undergone the process of actualization:
            </Typography>
            <Table testName='candidates'>
                <TableHead>
                    <TableRow>
                        <TableCell>Opportunity Number</TableCell>
                        <TableCell>Configuration Name</TableCell>
                        <TableCell>Actualization Period</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.skippableCandidates && props.skippableCandidates.map(candidate =>
                        <TableDataRow
                            testName='candidate'
                            testId={'' + candidate.id}
                            key={candidate.id}>
                            {renderCandidate(candidate)}
                        </TableDataRow>,
                    )}
                </TableBody>
            </Table>
            <Typography variant='body1' classes={{ root: props.classes.lowerText }}>
                If you select <strong>Skip</strong>, they will be removed from the current actualization process.{' '}
                If you select <strong>Re-Run</strong> they will be kept and actualized again.
            </Typography>
        </DialogContent>

        <DialogActions>
            <Button
                testName='cancel'
                onClick={() => props.onCancel()}
            >
                Cancel
            </Button>
            <Button
                testName='reRun'
                color='primary'
                variant='outlined'
                onClick={() => onRerun(props)}
            >
                Re-run
            </Button>
            <Button
                testName='skip'
                color='primary'
                variant='contained'
                disabled={!canSkip}
                onClick={() => onSkip(props)}
            >
                Skip
            </Button>
        </DialogActions>
    </Dialog>);
};

function onSkip(props: SkipOrMaintainCandidatesDialogProps) {
    const { payload, actions } = props;
    const candidates = payload.candidates.filter(candidate =>
        candidate.actualizationPeriod !== payload.actualizationPeriod);

    const newPayload: ActualizationPayload = {
        ...payload,
        candidates,
    };

    props.onSubmit(newPayload, actions);
}

function onRerun(props: SkipOrMaintainCandidatesDialogProps) {
    props.onSubmit(props.payload, props.actions);
}

function renderCandidate(candidate: ActualizationCandidate): ReactNode {
    return (
        <>
            <TableDataCell testName='opportunityNumber'>{candidate.opportunityNumber}</TableDataCell>
            <TableDataCell testName='displayName'>{candidate.displayName}</TableDataCell>
            <TableDataCell testName='actualizationPeriod'>{candidate.actualizationPeriod}</TableDataCell>
        </>
    );
}

export interface SkipOrMaintainCandidatesDialogProps {
    skippableCandidates: ActualizationCandidate[],
    open: boolean,
    actions: FormikActions<ActualizationPayload>,
    payload: ActualizationPayload,
    onSubmit: (payload: ActualizationPayload, actions: FormikActions<ActualizationPayload>) => void,
    onCancel: () => void,
}

export default withStyles(styles)(SkipOrMaintainCandidatesDialog);
