import React, { FC } from 'react';
import { CoveredUnitsProps } from './CoveredUnitsProps';
import ConfigurationDetailsForm from '../ConfigurationDetailsForm/ConfigurationDetailsForm';

export const NonSavedCoveredUnits: FC<CoveredUnitsProps> =
    props =>
        <ConfigurationDetailsForm
            {...props}
            savedContractCategoryOptions={[]}
        />;
