import React, { FC, ReactNode } from 'react';
import { StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core/styles';
import { CatalogOverview, CatalogState, CatalogType } from '../../api/catalog-service/catalog-overview';
import { CatalogTableNoData } from './CatalogTableNoData';
import { isEmpty } from 'lodash';
import { CATALOG_DOWNLOAD_URL } from '../../data/api';
import { DownloadLink } from '../links/DownloadLink';
import { CaseAuthorizedToWriteCatalogs } from '../authorization/CaseAuthorizedToWriteCatalogs';
import { StyledTableDataCell } from './TableCell';
import { TableBody, TableFooter, TableRow, Tooltip } from '@material-ui/core';
import { Check, Clear, CloudDownload } from '@material-ui/icons';
import { TableHeader } from './TableHeader';
import { isoToLocaleDate } from '../../data/utils/date';
import Table from '../Tables/Table';
import TableDataRow from '../Tables/TableDataRow';
import TablePagination from '../Tables/TablePagination';
import IconButton from '../Buttons/IconButton';

const TableFooterRow = TableRow;

const CatalogTable0: FC<CatalogsTableProps & StyledComponentProps> =
    props => {
        const { catalogs } = props;
        if (isEmpty(catalogs)) {
            return <CatalogTableNoData/>;
        }

        const { classes } = props;
        return <div className={classes.root}>
            <Table testName={props.testName} className={classes.table}>
                <TableHeader/>
                <TableBody>
                    {catalogs.map(catalog => {
                        const catalogDownloadUrl = `${CATALOG_DOWNLOAD_URL}/${catalog.id}`;
                        return (
                            <TableDataRow testName='catalog' testId={'' + catalog.id} key={catalog.id}>
                                <StyledTableDataCell testName='name' component='th' scope='row'>
                                    <DownloadLink href={catalogDownloadUrl}>
                                        {clickHandler =>
                                            <a onClick={clickHandler}
                                               href=''
                                               data-link={catalogDownloadUrl}
                                            >
                                                <CloudDownload className={classes.smallDownloadIcon}/>
                                                {getName(catalog)}
                                            </a>
                                        }
                                    </DownloadLink>
                                </StyledTableDataCell>
                                <StyledTableDataCell testName='createdOn'>
                                    {isoToLocaleDate(catalog.createdOn)}
                                </StyledTableDataCell>
                                <StyledTableDataCell testName='createdBy' align='right'>
                                    {catalog.createdBy}
                                </StyledTableDataCell>
                                <CaseAuthorizedToWriteCatalogs>
                                    {authorizedToEdit => getToggleStateBtn(props, catalog, authorizedToEdit)}
                                </CaseAuthorizedToWriteCatalogs>
                            </TableDataRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    {props.totalPages > 1
                        ? <TableFooterRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[ 5, 10, 25 ]}
                                count={props.totalCatalogs}
                                page={props.currentPage}
                                rowsPerPage={props.pageSize}
                                onChangePage={(e, page) => props.onChangePage(page)}
                                onChangeRowsPerPage={evt => {
                                    const pageSize = Number.parseInt(evt.target.value, 10);
                                    props.onChangePageSize(pageSize);
                                }}
                                SelectProps={{ native: true }}
                            />
                        </TableFooterRow>
                        : null
                    }
                </TableFooter>
            </Table>
        </div>
    };

const styles: StyleRulesCallback = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    smallDownloadIcon: {
        marginRight: '10px',
        fontSize: 18,
        top: 4,
        position: 'relative',
    },
    tooltip: {
        cursor: 'pointer',
    },
});
export const CatalogTable = withStyles(styles)(CatalogTable0);

export interface CatalogsTableProps {
    testName: string,
    catalogs: CatalogOverview[],
    totalCatalogs: number,
    currentPage: number,
    totalPages: number,
    pageSize: number,
    onChangePage: (page: number) => void,
    onChangePageSize: (pageSize: number) => void,
    onChangeCatalogState: (id: number, type: CatalogType, state: CatalogState) => void,
}

function getName(catalog: CatalogOverview): string {
    const name = catalog.name;
    return catalog.state === 'DEFAULT'
        ? `${name} (default)`
        : name;
}

function getStateDescription(catalog: CatalogOverview): string {
    const modifiedDate = isoToLocaleDate(catalog.modifiedOn);
    const stateStr = isActive(catalog) ? 'Activated' : 'Deactivated';
    return `${stateStr} by ${catalog.modifiedBy} - ${modifiedDate}`;
}

function getToggleStateBtn(
    props: CatalogsTableProps & StyledComponentProps, catalog: CatalogOverview, authorizedToEdit: boolean): ReactNode {

    const switchStateBtnTestName = isActive(catalog) ? 'setInactive' : 'setActive';
    const switchStateBtnIcon = getStateToggleBtnIcon(props, catalog, authorizedToEdit);
    return (
        <StyledTableDataCell testName='state' align='right'>
            <Tooltip
                title={getStateDescription(catalog)}
                placement='right'
            >
                <IconButton
                    testName={switchStateBtnTestName}
                    disabled={isDisabled(authorizedToEdit)}
                    onClick={() => {
                        const newState: CatalogState = isActive(catalog) ? 'INACTIVE' : 'ACTIVE';
                        props.onChangeCatalogState(catalog.id, catalog.type, newState);
                    }}
                >
                    {switchStateBtnIcon}
                </IconButton>
            </Tooltip>
        </StyledTableDataCell>
    );
}

function getStateToggleBtnIcon(
    props: CatalogsTableProps & StyledComponentProps, catalog: CatalogOverview, authorizedToEdit: boolean): ReactNode {

    const currentlyActiveBtnColor = isDisabled(authorizedToEdit) ? 'disabled' : 'primary';
    const currentlyInactiveBtnColor = isDisabled(authorizedToEdit) ? 'disabled' : 'error';

    return isActive(catalog)
        ? <Check
            color={currentlyActiveBtnColor}
            classes={{ root: props.classes.tooltip }}
        />
        : <Clear
            color={currentlyInactiveBtnColor}
            classes={{ root: props.classes.tooltip }}
        />;
}

function isActive(catalog: CatalogOverview): boolean {
    return catalog.state === 'ACTIVE' || catalog.state === 'DEFAULT';
}

// TODO also disable while changing catalog state
function isDisabled(authorizedToEdit): boolean {
    return !authorizedToEdit;
}
