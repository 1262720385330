import React, { FC } from 'react';
import TableHeaderCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableHeaderRow from '@material-ui/core/TableRow';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';

const EditStandardScopeTableHeader0: FC<StyledComponentProps> =
    ({ classes }) => {
        const headClass = classes.head;
        return (
            <TableHead>
                <TableHeaderRow>
                    <TableHeaderCell className={headClass} align='left'>
                        Scope
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        Service
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        Additional Attribute
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        INNIO Parts
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        INNIO Labor
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        Other Provider
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        Freight
                    </TableHeaderCell>
                    <TableHeaderCell className={headClass} align='left'>
                        Customs
                    </TableHeaderCell>
                </TableHeaderRow>
            </TableHead>
        );
    };

export const EditStandardScopeTableHeader = withStyles(getClasses)(EditStandardScopeTableHeader0);

function getClasses() {
    return {
        head: {
            fontSize: 12,
            padding: 10,
        },
    };
}
