import React, { FC } from 'react';
import { ControlProps } from 'react-select';
import { createErrorTestProps } from '../Utils/testProps';
import { OptionType } from './OptionType';
import { ControlInput } from './ControlInput';
import { StyledMultiSelectFieldProps } from './StyledMultiSelectFieldProps';
import { StyledComponentProps, TextField, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const Control0: FC<ControlProps<OptionType> & StyledComponentProps> = props => {
    const {
        name,
        label,
        error,
        helperText,
        required,
    } = props.selectProps.customProps as StyledMultiSelectFieldProps;

    const isValid = !error;
    const helperTextProps = isValid
        ? {}
        : createErrorTestProps(name);

    return (
        <TextField
            label={label}
            error={error}
            disabled={props.selectProps.isDisabled}
            required={required}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                inputComponent: ControlInput,
            }}
            inputProps={{
                className: props.classes.controlInput,
                inputRef: props.innerRef,
                children: props.children,
                ...props.innerProps,
            }}
            helperText={helperText}
            FormHelperTextProps={helperTextProps}
            fullWidth
        />
    );
};

const styles: StyleRules = {
    controlInput: {
        display: 'flex',
        padding: 0,
    },
};
export const Control = withStyles(styles)(Control0);
