import { array, object, ObjectSchema, string, number, boolean, mixed } from '../../utils/validators';
import { Configuration, Guarantee, UnitValuePair } from '../../api/model-service/model-service';

export function getGuaranteeValidationSchema(): ObjectSchema<Guarantee> {
    const guaranteeSchema: ObjectSchema<Guarantee> = object().shape({
        type: string().required(),
        unit: string(),
        value: mixed()
            .when('unit', {
                is: value => value === 'PERCENTAGE',
                then: number()
                    .min(1, 'Number must be at least 1')
                    .max(99, 'Number must less than 100'),
                othewise: number()
                    .min(1, 'Number must be at least 1')
                    
            }).required(),
        standardDefinitionAndWording: boolean(),
        nonStandardDescription: mixed().when('standardDefinitionAndWording', {
            is: value => !value,
            then: string()
                .max(200, 'Description cannot exceed 200 characters')
                .required('This field is required'),
            otherwise: string()
        }),
        ld: getUnitValuePairSchema(),
        ldCap: getUnitValuePairSchema(),
        bonus: getUnitValuePairSchema(),
        bonusCap: getUnitValuePairSchema(),
     });

    return guaranteeSchema;
}

function getUnitValuePairSchema() {
    return object().shape({
        value: number()
            .min(1, 'Value must be greater or equal to 1')
    })
}

export function formValuesSchema(): ObjectSchema<Partial<Configuration>> {
    return object().shape({
        guarantees: array().of(getGuaranteeValidationSchema()),
    });
}
