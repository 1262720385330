import { combineReducers } from 'redux';
import { CatalogPageRequest } from '../model/catalog';
import { Page } from '../model/page';

import { catalogs } from './catalog';
import { models } from './model';
import { error } from './error';
import { user } from './user';
import { configurations } from './configurations';
import refactoredReducer from '../../state/rootReducer';

export interface IRequestPage<T> {
    page: CatalogPageRequest;
    pageData?: CatalogPageRequest;
    data: Page<T>;
    all: Page<T>;
}

export const reducers = combineReducers({
    catalogs,
    models,
    error,
    configurations,
    user,
    REFACTORED: refactoredReducer,
});
