import React, { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { CaseAuthorized } from '../authorization/CaseAuthorized';
import { Authority } from '../../data/model/user';

export const ServiceTableNoDataRow: FC<ServiceTableNoDataRowProps> =
    ({ showCost }) => {
        return (
            <TableRow>
                <CaseAuthorized for={Authority.WRITE_BILL_OF_SERVICES}>
                    {authorizedToEdit => {
                        const cellCount = getCellCount(authorizedToEdit, showCost);
                        return <TableCell align='center' colSpan={cellCount}>No Data</TableCell>;
                    }}
                </CaseAuthorized>
            </TableRow>
        );
    };

export interface ServiceTableNoDataRowProps {
    showCost: boolean,
}

function getCellCount(authorizedToEdit: boolean, showCost: boolean): number {
    const maxCellCount = 9;

    let cellCount = maxCellCount;
    if (!authorizedToEdit) {
        cellCount -= 1;
    }
    if (!showCost) {
        cellCount -= 1;
    }

    return cellCount;
}
