import {defaultsDeep, merge} from 'lodash';
import {getToken, renewToken} from "./auth";
import {hasValue} from "./utils/form";
import { parseErrors } from './utils/error';
import { ApiError } from './error';
import { Activity } from './model/catalog';

// API URLS ARE SET IN WEBPACK CONFIG
declare var CATALOG_ROOT: string;
declare var MODEL_ROOT: string;
declare var AUTH_ROOT: string;
declare var REQUEST_FETCH_DEFAULTS: any;

const FETCH_INIT_DEFAULTS: any = merge(
    {
        mode: 'cors',
    },
    REQUEST_FETCH_DEFAULTS
);

export const addQuery = (url, queryParams) => {
    if (queryParams) {
        Object.keys(queryParams).forEach(key => {
            if (queryParams[key]) {
                const paramVal = queryParams[key];

                if (paramVal) {
                    if (Array.isArray(paramVal)) {
                        paramVal.map(val => url.searchParams.append(key, val));
                    } else {
                        url.searchParams.append(key, paramVal);
                    }
                }
            }
        });
    }
    return url;
};

type SuccessParser = (response: Response) => Promise<unknown>;
type ErrorParser = (response: Response, endpoint: URL) => Promise<never>;

const parseJsonSuccess: SuccessParser = response =>
    response.json()
        .then(body => {
            return parseResponse(body);
        })
        .catch(err => {
            return undefined;
        });

const parseJsonError: ErrorParser = (response, endpoint) =>
    response.json()
        .then(body => {
            throw parseResponse(body);
        })
        .catch(e => {
            const apiErrorMessage = e && e.message ? parseErrors(e.message) : null;
            const message = apiErrorMessage ||
                `Failed to retrieve endpoint ${endpoint} with status ${response.statusText}`;
            throw new Error(message);
        });

const parseResponse = (body : string) => {
    try {
        if (typeof body === 'string') {
            return JSON.parse(body);
        }
    } catch (e) {
        // empty
    }
    return body;
};

// to change request METHOD set property key "method" on setup
const requestRaw = (baseURL: string) => (
    path: string = '',
    params: any = {},
    setup: any = {}
) => {

        const endpoint: URL = new URL(`${baseURL}${path}`);

        if (
            setup.method === 'HEAD' ||
            setup.method === 'GET' ||
            !setup.method
        ) {
            addQuery(endpoint, params);
        } else {
            if (
                setup.headers &&
                setup.headers['Content-Type'] === 'application/json'
            ) {
                setup.body = JSON.stringify(params);
            } else {
                setup.body = params;
            }
        }

        const fetchSettings = defaultsDeep(setup, FETCH_INIT_DEFAULTS);
        return fetchCall(endpoint, fetchSettings, parseJsonSuccess, parseJsonError);

};

const fetchCall = async (endpoint : URL,
                         fetchSettings : RequestInit,
                         successParser : SuccessParser,
                         errorParser : ErrorParser,
                         retries : number = 3) => {
    return getToken()
        .then((token) => {
            if (!hasValue(token)) {
                return getNewToken();
            } else {
                return token;
            }
        })
        .then((token) => {
            const enrichedSettings = ensureTokenInSettings(fetchSettings, token);
            return fetch(endpoint.href, enrichedSettings);
        }).then(response => {
            if (response.status === 204) {
                return;
            } else if (
                response.status === 200 ||
                response.status === 201
            ) {
                return successParser(response);
            } else if (response.status === 401) {
                return getNewToken().then(token => {
                    const newSettings = ensureTokenInSettings(fetchSettings, token);
                    if (retries > 1) {
                        return fetchCall(endpoint, newSettings, successParser, errorParser, retries--);
                    } else {
                        return Promise.reject('Cannot access server. Please contact your administrator');
                    }
                })
            } else if (response.status === 403) {
                return Promise.reject('You are not authorized to perform this action. ' +
                    'Please contact your administrator to request access.')
            } else if (
                response.status === 400 ||
                response.status === 409 ||
                response.status === 412 ||
                response.status === 500
            ) {
                return errorParser(response, endpoint);
            } else {
                throw new Error(
                    'Failed to retrieve endpoint ' +
                    endpoint +
                    ' with status ' +
                    response.statusText
                );
            }
        });
};

let tokenCheckPromise : Promise<string>;

const ensureTokenInSettings = (fetchSettings, token) => {
    return merge({}, fetchSettings, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

const getNewToken = async () => {
    if (!hasValue(tokenCheckPromise)) {
        tokenCheckPromise = renewToken().finally(() => {
            tokenCheckPromise = undefined;
        });
    }
    return tokenCheckPromise;
};

const fetchCatalogRaw = (
    path: string = '',
    params: any = {},
    setup: any = {}
): any => {
    return requestRaw(CATALOG_ROOT)(path || `/catalogs`, params, setup);
};

const fetchMigrationRecipe = (engineId: number,
                              activities: Array<Pick<Activity, 'key' | 'attribute'>>): Promise<MigrationRecipe> => {
    const path = `/activities/${engineId}/migration-recipes`;
    const body = {
        migrationActivities: activities,
    };
    const opts = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return requestRaw(CATALOG_ROOT)(path, body, opts);
};

export interface MigrationRecipe {
    migratedActivities: {
        [key:string]: Activity,
    },
    removedKeys: string[],
    replacementOptions: {
        [key:string]: Activity[],
    },
}

const fetchModelRaw = (
    path: string = '',
    params: any = {},
    setup: any = {}
): any => {
    return requestRaw(MODEL_ROOT)(path || '/models', params, setup);
};

const fetchAuthenticationRaw = (
    path: string = '',
    params: any = {},
    setup: any = {}
): any => {
    return requestRaw(AUTH_ROOT)(path || `/users`, params, setup);
};

export const download = (url: string): Promise<File> => {
    const endpoint = new URL(url);
    const fetchSettings = {};
    return fetchCall(endpoint, fetchSettings, downloadSuccessParser, downloadErrorParser);
};

const downloadSuccessParser: SuccessParser = response => {
    const fileName = getDownloadedFilename(response);
    return response.blob()
        .then(contents => {
            return new File([contents], fileName);
        });
};

const downloadErrorParser: ErrorParser = (response, endpoint) => {
    return response.text()
        .then(body => {
            const message = 'Error downloading ' + endpoint;
            const status = response.status;
            throw new ApiError(message, status, body);
        })
};

const getDownloadedFilename = (response: Response): string => {
    return response.headers.get('MYAC-Content-Filename');
};

export const CATALOG_TEMPLATE_URL = `${CATALOG_ROOT}/catalogs/templates`;
export const CATALOG_DOWNLOAD_URL = `${CATALOG_ROOT}/catalogs/files`;

export const api = {
    catalog: {
        fetch: fetchCatalogRaw,
        fetchJSON: fetchCatalogRaw,
        fetchMigrationRecipe,
    },
    model: {
        fetch: fetchModelRaw,
        fetchJSON: fetchModelRaw,
    },
    authentication: {
        fetchJSON: fetchAuthenticationRaw
    }
};
