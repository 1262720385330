import React from 'react';
import { Divider as MaterialDivider } from '@material-ui/core';
import { withVariablePriceEscalation } from './withEscalationType';

export const Divider = withVariablePriceEscalation(() =>
    <>
        <br/>
        <MaterialDivider/>
        <br/>
    </>,
);
