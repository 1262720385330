import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '../Dialogs/Dialog';
import { ChangeDefaultCatalogForm, ChangeDefaultCatalogFormProps } from './ChangeDefaultCatalogForm';
import { State } from '../../data/store';
import { changeDefaultCatalog } from '../../state/default-catalog-dialog/change-default-catalog';
import { getAllCatalogs } from '../../state/default-catalog-dialog/get-all-catalogs';
import { clearCatalogs } from '../../state/default-catalog-dialog/slice';

export class ChangeDefaultCatalogDialog extends Component<ChangeDefaultCatalogDialogProps, DialogState> {
    public state = {
        open: false,
    };

    public render() {
        return (
            <>
                {this.props.renderOpenButton(() => this.handleOpen())}
                <Dialog
                    testName='setDefaultCatalog'
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    aria-labelledby='Set Default Catalog'
                    maxWidth='md'
                >
                    <ChangeDefaultCatalogForm
                        type={this.props.type}
                        catalogs={this.props.catalogs}
                        onCancel={() => this.handleClose()}
                        onSubmit={id => this.handleSubmit(id)}
                    />
                </Dialog>
            </>
        );
    }

    private handleOpen(): void {
        this.props.onOpen();
        this.setState({ open: true });
    }

    private handleClose(): void {
        this.props.onClose();
        this.setState({ open: false });
    }

    private handleSubmit(id: number): Promise<void> {
        return this.props.onChangeDefault(id).then(() => this.handleClose());
    }
}

export interface ChangeDefaultCatalogDialogProps extends Pick<ChangeDefaultCatalogFormProps, 'type' | 'catalogs'> {
    renderOpenButton: (handleOpen: HandleOpen) => ReactNode,
    onOpen: HandleOpen,
    onClose: HandleOpen,
    onChangeDefault: (id: number) => Promise<void>,
}

type HandleOpen = () => void;

interface DialogState {
    open: boolean;
}

function mapStateToProps(state: State): StateProps {
    const defaultCatalogDialog = state.REFACTORED.defaultCatalogDialog;
    return {
        catalogs: defaultCatalogDialog.data,
    };
}

type StateProps = Pick<ChangeDefaultCatalogDialogProps, 'catalogs'>;

function mapDispatchToProps(dispatch, props: ChangeDefaultCatalogDialogProps): ActionProps {
    return {
        onOpen: () => dispatch(getAllCatalogs(props.type)),
        onClose: () => dispatch(clearCatalogs()),
        onChangeDefault: id => {
            return dispatch(changeDefaultCatalog(id, props.type))
        },
    };
}

type ActionProps = Pick<ChangeDefaultCatalogDialogProps, 'onOpen' | 'onClose' | 'onChangeDefault'>;

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDefaultCatalogDialog);
