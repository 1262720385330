import * as React from 'react';
import './Models.scss';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ViewTitle from '../ViewTitle/ViewTitle';
import ModelsTable from './ModelsTable/ModelsTable';
import ModelDialog from '../Model/ModelDialog/ModelDialog';
import { connect } from 'react-redux';
import { PageRequest, QueryType } from '../../data/model/pageRequest';
import { clearActiveModel, getModelList } from '../../data/actions/models';
import { IFormModel, ModelPageRequest } from '../../data/model/model';

import AddIcon from '@material-ui/icons/Add';
import { AuthorizedToCreateModel } from '../authorization/AuthorizedToCreateModel';
import {FormControlLabel} from "@material-ui/core";
import Switch from "../Form/Switch";

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        marginBottom: '33px',
    },
    rootPaper: {
        padding: '25px 33px',
    },
    grow: {
        flexGrow: 1,
    },
    newModelButton: {
        marginLeft: '20px',
    },
});

class Models extends React.Component<any, any> {

    public componentDidMount(): void {
        this.props.models.modelListPage.setLength(25);
        this.props.models.modelListPage.keyword = '';
        this.props.models.modelListPage.setQueryType(QueryType.ByAll);
        this.props.models.modelListPage.setVersion(null);
        this.props.gotoPage(0, this.props.models.modelListPage);

        if (this.props.models) {
            this.props.clearCurrentModel(this.props.models.activeModel);
        }
    }

    public render(): React.ReactElement {
        const { gotoPage, lookup, clearCurrentModel, models } = this.props;
        const { modelList, modelListPage, activeModel } = models;

        return (
            <React.Fragment>
                <ViewTitle
                    search
                    searchOnChange={keyword => lookup(keyword, modelListPage)}
                    title="Models"
                >
                    <AuthorizedToCreateModel>
                        <ModelDialog
                            onClick={() => {
                                clearCurrentModel(activeModel);
                            }}
                            page={modelListPage}
                            variant="contained"
                            icon={<AddIcon />}
                            title="New Model"
                        />
                    </AuthorizedToCreateModel>

                    <FormControlLabel
                        control={<Switch testName="hideEndedSwtich" checked={this.props.models.modelListPage.getHideEnded()} onChange={(e)=>{
                            const hideEnded = this.props.models.modelListPage.getHideEnded();

                            this.props.models.modelListPage.setHideEnded(!hideEnded);
                            this.props.gotoPage(0, this.props.models.modelListPage);

                        }} />}
                        label="Hide Ended Models"
                      />

                </ViewTitle>
                <div className="right-content-wrap">
                    <Paper>
                        <ModelsTable
                            modelList={modelList}
                            goto={(e: any, page: number) =>
                                gotoPage(page, modelListPage)
                            }
                            changePageLength={pageLength => {
                                modelListPage.setLength(
                                    pageLength.target.value
                                );
                                gotoPage(0, modelListPage);
                            }}
                        />
                    </Paper>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        models: state.models,
    };
};

let searchTimeout;
const mapDispatchToProps = dispatch => {
    return {
        clearCurrentModel: (activeModel: IFormModel) => {
            dispatch(clearActiveModel(activeModel));
        },
        gotoPage: (page: number, pageRequest: ModelPageRequest) => {
            if (pageRequest) {
                pageRequest.setPage(page);
                dispatch(getModelList(pageRequest));
            }
        },
        lookup: (keyword: string, page: PageRequest) => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
            searchTimeout = setTimeout(() => {
                page.setKeyword(keyword);
                dispatch(getModelList(page));
            }, 300);
        },
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Models)
);
