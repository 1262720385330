import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';

export const DeliveryTerms: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.deliveryTerms';
        const errorMessage = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='Delivery Terms'
                value={inputValue(name, formikProps)}
                options={options}
                disabled={disabled}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='dense'
                fullWidth
                required
            />
        );
    };

const options: IFormOption[] = [
    formOption('EXW', 'EXW'),
    formOption('FCA', 'FCA'),
    formOption('CPT', 'CPT'),
    formOption('CIP', 'CIP'),
    formOption('DAT', 'DAT'),
    formOption('DAP', 'DAP'),
    formOption('DDP', 'DDP'),
    formOption('FAS', 'FAS'),
    formOption('FOB', 'FOB'),
    formOption('CFR', 'CFR'),
    formOption('CIF', 'CIF'),
];
