import React, { FC } from 'react';
import { GuaranteeFormFactoryProps } from '../GuaranteesForm/GuaranteesInterfaces';
import { msaPreventiveGuaranteeTypeOptions, msaPreventiveGuaranteeUnitOptions, msaPreventiveLdBonusUnitOptions } from '../GuaranteesForm/GuaranteeOptions';
import { GuaranteesForm } from '../GuaranteesForm/GuaranteesForm';

export const MsaGuarantees: FC<GuaranteeFormFactoryProps> =
    props =>
        <GuaranteesForm
            {...props}
            guaranteeTypeOptions={msaPreventiveGuaranteeTypeOptions}
            guaranteeUnitOptions={msaPreventiveGuaranteeUnitOptions}
            ldBonusUnitOptions={msaPreventiveLdBonusUnitOptions}
        />;
