import * as React from 'react';
import './ModelDialog.scss';
import Grid from '@material-ui/core/Grid';
import Button from '../../Buttons/Button';
import moment from 'moment'
import Dialog from '../../Dialogs/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FFInput } from '../../Form/input';
import {
    AcceptableModelScanMimes,
    advanceModelStage,
    contractType,
    contractTypeInputs,
    formatCompetitors,
    IFormModel,
    ModelPageRequest,
    modelVersion,
    modelVersionInputs,
} from '../../../data/model/model';
import {hasValue, iv} from '../../../data/utils/form';
import { merge, sortBy, isEmpty } from 'lodash';
import { dissoc } from 'lodash/fp';
import ValidatorForm from '../../Form/ValidatorForm';
import {
    DialogActions, Fade,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    RadioGroup,
    Typography,
    withStyles,
} from '@material-ui/core';
import Radio from '../../Form/Radio';
import { connect } from 'react-redux';
import { getFormCatalogs } from '../../../data/actions/catalogs';
import { CatalogType } from '../../../data/model/catalog';
import {
    attachModelAttachment,
    getModelDetails,
    initModelForm,
    submitModel,
    transitionModelState,
} from '../../../data/actions/models';
import { withRouter } from 'react-router';

import classNames from 'classnames';
import { DropzoneArea } from 'material-ui-dropzone/dist';
import { createRef } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormEditAuthorizationProvider } from '../../Form/formAuthorizationContext';
import { AuthorizedToEditForm } from '../../Form/AuthorizedToEditForm';
import { IConfigurationView } from '../../../data/model/configuration';
import { isoToDate, dateToIso } from '../../../data/utils/date';
import DeleteUnitCounterDialog from '../DeleteCounterDialog/DeleteUnitCounterDialog';
import { IDeleteUnitCounterDialogProps, IUnitWithConfigId } from '../DeleteCounterDialog/Interfaces';
import { sunsetClause } from '../../../data/model/configuration_enums';

const styles = (theme: any) => ({
    leftIcon: {
        marginRight: '10px',
    },
    rightIcon: {
        marginLeft: '10px',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    withoutLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    textField: {
        flexBasis: 200,
    },
    dialogContent: {
        backgroundColor: '#f5f5f5',
    },
    dialogButton: {
        marginLeft: '20px',
    },
    spincontainer : {
        margin: '5px',
    }
});

class ModelDialog extends React.Component<any, ModelDialogState> {
    public form;
    public fileInputRef: any = createRef();
    public state: ModelDialogState = {
        open: false,
        competitors: [],
        catalogRegionID: null,
        correctiveCapOverTotalContractTerm: 'no',
        correctiveCorrectiveCapPerYear: 'no',
        correctiveCapPerEvent: 'no',
        dealInLineWithChannelPolicy: 'yes',
        onOffshoreConstellation: 'no',
        modelVersion: modelVersion.IN_PROGRESS,
        offeringType: contractType.FIRST_TIME_CONTRACT,
        actualEndDate: null,
        disabled: true,
        loading: false,
        hasDeleteReadCounterApproval: false,
        unitsWithInvalidReadCounters: [],
        isInvalidCounterReadingDialogOpen: false,
    };

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    public validate(): Promise<boolean> {
        if (this.state.open) {
            if (this.state.unitsWithInvalidReadCounters.length && 
                !this.state.hasDeleteReadCounterApproval &&
                this.state.modelVersion === modelVersion.HANDOVER) {
                    this.setState({ isInvalidCounterReadingDialogOpen: true })
                    return Promise.reject(false);
            }

            return this.form.current.isFormValid(false);
        }

        return Promise.reject(false);
    }

    public handleChange(name: keyof ModelDialogState, event: any): void {
        if (event.target) {
            if (
                event.target.name === 'description' &&
                event.target.value.length > 500
            ) {
                event.target.value = event.target.value.substring(0, 500);
            }

            this.setState({ [name]: event.target.value } as any);
        } else {
            this.setState({ [name]: event } as any);
        }
    }

    public componentDidMount(): void {
        const { configurationOverviews } = this.props.activeModel;
        if (configurationOverviews) {
            const unitsWithInvalidReadCounters = configurationOverviews
                .flatMap(config =>
                        config.units
                            .filter(unit => !!unit.unitReadCounter && !!unit.unitReadDate)
                            .filter(unit => unit.unitStartCounter > unit.unitReadCounter ||
                                unit.unitReadDate < config.commercialOperationDate)
                            .map(unit => {
                                return {
                                    ...unit,
                                    configurationId: config.id,
                                    configurationName: config.displayName,
                                    commercialOperationDate: config.commercialOperationDate,
                                }
                            }));
            
            this.setState({ unitsWithInvalidReadCounters });
        }

        this.props.populateRegionsAndCompetitors(true);
    }
    public validatorListener(result): void {
        this.form.current
            .isFormValid()
            .then(valid => this.setState({ disabled: !valid }));
    }

    public handleClickOpen(): void {
        if (this.props.onClick) {
            this.props.onClick();
        }
        this.setState({
            open: true,
        });
        if (this.props.edit) {
            const state = Object.assign({}, this.props.activeModel);
            state.correctiveCapOverTotalContractTerm = state.correctiveCapOverTotalContractTerm
                ? 'yes'
                : 'no';
            state.correctiveCorrectiveCapPerYear = state.correctiveCorrectiveCapPerYear
                ? 'yes'
                : 'no';
            state.correctiveCapPerEvent = state.correctiveCapPerEvent
                ? 'yes'
                : 'no';
            state.onOffshoreConstellation = state.onOffshoreConstellation
                ? 'yes'
                : 'no';
            state.dealInLineWithChannelPolicy = state.dealInLineWithChannelPolicy
                ? 'yes'
                : 'no';

            state.competitors = state.competitors
                ? state.competitors.map((competitor, index) => {
                      return { value: competitor, label: competitor };
                  })
                : [];

            state.disabled = false;

            state.actualEndDate = this.props.activeModel.actualEndDate;

            this.props.populateRegionsAndCompetitors(
                this.props.activeModel.state === modelVersion.IN_PROGRESS
            );

            this.setState(state);
        }
    }
    public handleClose(): void {
        this.setState({ open: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    public updateAttachment(attachments): void {
        if (attachments && attachments.length > 0) {
            this.setState({ attachments});
        } else {
            this.setState({ attachments: null });
        }
    }

    public handleSave(): void {
        this.validate().then(isValid => {
            const {
                activeModel,
                submit,
                getOne,
                submitAttachment,
                page,
            } = this.props;

            if (!isValid) {
                return;
            }

            try {
                const stateToSubmit = this.getStateOnSave();
                const modelToSubmit = stateToSubmission(
                    stateToSubmit,
                    activeModel
                );
                submit(modelToSubmit, page).then(result => {
                    if (result.type !== 'ERROR_MODEL') {
                        if (this.state.attachments && this.state.attachments.filter(attachment => !hasValue(attachment.id)).length > 0) {
                            submitAttachment(
                                this.state.attachments,
                                result.activeModel
                            )
                                .then(() => {
                                    if (getOne && hasValue(activeModel) && hasValue(activeModel.id)) {
                                        getOne(activeModel.id);
                                    }
                                    this.handleClose();
                                    this.resetForm();
                                })
                                .catch(() => {
                                    if (getOne && hasValue(activeModel) && hasValue(activeModel.id)) {
                                        getOne(activeModel.id);
                                    }
                                    this.handleClose();
                                    this.resetForm();
                                });
                        } else {
                            if (getOne && hasValue(activeModel) && hasValue(activeModel.id)) {
                                getOne(activeModel.id);
                            }
                            this.handleClose();
                            this.resetForm();
                        }
                    }
                });
            } catch (e) {
                return;
            }
        });
    }

    private prepareCounterReadingDeleteApprovals(modelToSubmit: IFormModel): IFormModel {
        if (!this.state.hasDeleteReadCounterApproval) {
            return modelToSubmit;
        }
        const approvalMap = this.state.unitsWithInvalidReadCounters
                .reduce((acc, approval) => {
                    if(!acc[approval.configurationId]) {
                        acc[approval.configurationId] = {
                            configurationId: approval.configurationId,
                            unitSerialNumbers: []
                        };
                    }
                    acc[approval.configurationId].unitSerialNumbers.push(approval.serialNumber)
                    return acc;
                }, {});
            
        modelToSubmit.counterReadingDeleteApprovals = Object.values(approvalMap);
        return modelToSubmit;      
    }
    
    public handleTransition(): void {
        this.setState({loading : true});
        this.validate().then(isValid => {
            const {
                activeModel,
                transitionModel,
                submit,
                submitAttachment,
            } = this.props;

            if (!isValid) {
                return;
            }

            //get our modelToSubmit but remove the actualEndDate
            let modelToSubmit:IFormModel = stateToSubmission(this.state, activeModel);
            //let actualEndDate = modelToSubmit.actualEndDate;
            modelToSubmit.actualEndDate = null;

            return submit(
                modelToSubmit,
                this.props.page
            ).then(result => {
                if (result.type !== 'ERROR_MODEL') {

                    //get our modelToSubmit but this time leave the actualEndDate
                    const today = moment().format('YYYY-MM-DD');
                    modelToSubmit = stateToSubmission(this.state, activeModel);

                    modelToSubmit = this.prepareCounterReadingDeleteApprovals(modelToSubmit);
                    
                    if(modelToSubmit.amendmentInformation && modelToSubmit.amendmentInformation.parentModel) {
                        modelToSubmit.amendmentInformation.parentModel.actualEndDate = this.state.parentActualEndDate || dateToIso(today);
                    }

                    //move the default here vs. trigger event from the UI. The UI was causing an overwrite
                    if(!modelToSubmit.actualEndDate) {
                        modelToSubmit.actualEndDate = dateToIso(today);
                    }

                    if (this.state.attachments) {
                        return submitAttachment(
                            this.state.attachments,
                            result.activeModel
                        )
                            .then(() => {
                                transitionModel(modelToSubmit);
                            })
                            .catch(() => {
                                transitionModel(modelToSubmit);
                            });
                    } else {
                        return transitionModel(
                            stateToSubmission(this.state, activeModel)
                        );
                    }
                }
            }).then(() => {
                this.handleClose();
            });
        }).finally(() => {
            this.setState({loading : false});
        });
    }
    public render(): React.ReactElement {
        const {activeModel, classes, regions, contractManagers} = this.props;

        const regionList = [];

        const sortedRegions =
            regions &&
            sortBy(regions, ['name']).map(regionCatalog => {
                sortBy(regionCatalog.regions, ['name']).map(region => {
                    if (
                        regionCatalog.state === 'DEFAULT' ||
                        regionCatalog.state === 'ACTIVE' ||
                        this.state.regionCatalogID === region.id
                    ) {
                        regionList.push(
                            iv(
                                region.id,
                                `${region.name} - ${regionCatalog.name}`
                            )
                        );
                    }
                });
            });

        const nextModelVersion = modelVersionInputs.find(
            e => e.value === advanceModelStage(this.state.modelVersion)
        );

        let transitionButtonText = '';
        if (nextModelVersion) {
            transitionButtonText = `Save to ${nextModelVersion.label}`;
        }

        const hasPrimaryConfiguration = this.hasPrimaryConfiguration();

        const isInAsSold = this.state.modelVersion === modelVersion.AS_SOLD;

        const openDialogBtn = this.props.edit
            ? this.getEditModelBtn()
            : this.getAddModelBtn();

        // readonly is different from disabled in that in doesn't disable
        // the "open dialog" button
        const authorizedToEdit = !this.props.readonly;

        return (
            <FormEditAuthorizationProvider authorizedToEdit={authorizedToEdit}>
                {this.props.inStepper ? (
                    <Typography
                        variant="caption"
                        onClick={() => this.handleClickOpen()}
                    >
                        {this.props.title}
                    </Typography>
                ) : (
                    openDialogBtn
                )}

                {/* </Tooltip> */}
                <Dialog
                    testName="model"
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    aria-labelledby={this.props.title}
                    maxWidth="lg"
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">
                        {this.props.title}
                    </DialogTitle>
                    <DialogContent classes={{ root: 'dialogContent' }}>
                        <ValidatorForm
                            testName="model"
                            ref={this.form}
                            className={classes.container}
                            instantValidate
                            onSubmit={() => {
                                // empty
                            }}
                            autoComplete="off"
                        >
                            {this.getAsSoldToHandoverFields(hasPrimaryConfiguration)}

                            {this.getHandoverToOtrFields(hasPrimaryConfiguration)}

                            {this.getOtrToEndedFields(hasPrimaryConfiguration)}

                            {/* Standard Fields for Model */}
                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FFInput
                                        id="opportunityNumber"
                                        label="Opportunity Number"
                                        name="salesforceOpportunity"
                                        validators={[
                                            'minStringLength:1',
                                            this.props.edit
                                                ? 'matchRegexp:^(A|B|\\d)\\d{6}$'
                                                : 'matchRegexp:^\\d{7}$',
                                        ]}
                                        errorMessages={[
                                            'This field is required',
                                            'The Salesforce opportunity ID must be be 7 digits.',
                                        ]}
                                        validatorListener={result =>
                                            this.validatorListener(result)
                                        }
                                        disabled={this.props.edit}
                                        onChange={e =>
                                            this.handleChange(
                                                'salesforceOpportunity',
                                                e
                                            )
                                        }
                                        placeholder=""
                                        fullWidth
                                        required
                                        margin="dense"
                                        value={this.state.salesforceOpportunity}
                                        helperText="Match Salesforce Opportunity Number"
                                    />
                                </Grid>
                                {activeModel.amendmentOpportunityNumber &&
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FFInput
                                            id="amendmentOpportunityNumber"
                                            label="Amendment Oppty Number"
                                            name="amendmentOpportunityNumber"
                                            disabled={true}
                                            placeholder=""
                                            fullWidth
                                            required
                                            margin="dense"
                                            value={activeModel.amendmentOpportunityNumber}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6} md={3}>
                                    <FFInput
                                        id="projectName"
                                        label="Project Name"
                                        name="projectName"
                                        validators={['maxStringLength:50']}
                                        errorMessages={[
                                            'The project name must be 50 characters or less.',
                                        ]}
                                        validatorListener={result =>
                                            this.validatorListener(result)
                                        }
                                        disabled={isInAsSold}
                                        value={this.state.projectName}
                                        onChange={e =>
                                            this.handleChange('projectName', e)
                                        }
                                        fullWidth
                                        required
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FFInput
                                        id="customerName"
                                        name="customerName"
                                        label="Customer Name"
                                        validators={['maxStringLength:50']}
                                        errorMessages={[
                                            'The customer name must be 50 characters or less.',
                                        ]}
                                        validatorListener={result =>
                                            this.validatorListener(result)
                                        }
                                        value={this.state.customerName}
                                        onChange={e =>
                                            this.handleChange('customerName', e)
                                        }
                                        disabled={
                                            isInAsSold
                                                ? false
                                                : this.props.disabled
                                        }
                                        required={
                                            this.props.edit &&
                                            this.state.modelVersion !==
                                                modelVersion.IN_PROGRESS
                                        }
                                        placeholder=""
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FFInput
                                        id="customerNumber"
                                        name="customerNumber"
                                        label="Customer DUNS Number"
                                        validatorListener={result =>
                                            this.validatorListener(result)
                                        }
                                        value={this.state.customerNumber}
                                        onChange={e =>
                                            this.handleChange(
                                                'customerNumber',
                                                e
                                            )
                                        }
                                        disabled={
                                            isInAsSold
                                                ? false
                                                : this.props.disabled
                                        }
                                        required={
                                            this.props.edit &&
                                            this.state.modelVersion !==
                                                modelVersion.IN_PROGRESS
                                        }
                                        placeholder=""
                                        fullWidth
                                        margin="dense"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item sm={12} md={12}>
                                    <FFInput
                                        id="description"
                                        name="description"
                                        label="Description"
                                        value={this.state.description}
                                        validators={['maxStringLength:500']}
                                        errorMessages={[
                                            'Description cannot exceed 500 characters',
                                        ]}
                                        onChange={e =>
                                            this.handleChange('description', e)
                                        }
                                        disabled={isInAsSold}
                                        placeholder=""
                                        fullWidth
                                        multiline
                                        rowsMax="4"
                                        margin="dense"
                                        helperText={`${(this.state
                                            .description &&
                                            this.state.description.length) ||
                                            0}/500`}
                                    />
                                </Grid>
                                {this.state.modelVersion !==
                                    modelVersion.IN_PROGRESS && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FFInput
                                            id="contractManager"
                                            label="Contract Manager"
                                            name="contractManager"
                                            required={
                                                this.props.edit
                                            }
                                            validatorListener={result => this.validatorListener(result)}
                                            value={this.state.contractManager}
                                            onChange={e => this.handleChange('contractManager', e)}
                                            select={true}
                                            placeholder=""
                                            fullWidth
                                            disabled={isInAsSold ? false : this.props.disabled}
                                            margin="dense"
                                            options={this.getContractManagerList(contractManagers)}
                                        />
                                    </Grid>
                                )}
                                {this.state.modelVersion ===
                                    modelVersion.HANDOVER ||
                                    (this.state.modelVersion ===
                                        modelVersion.OTR && (
                                        <Grid item xs={12} sm={3}>
                                            <FFInput
                                                id="oracleContractNumber"
                                                label="Oracle Contracts #"
                                                name="oracleContractNumber"
                                                validators={[
                                                    'matchRegexp:^SER_\\w{2}(_\\w{2})?_\\d{5}$',
                                                ]}
                                                errorMessages={[
                                                    'Oracle Contracts # must be in the format SER_XX_00000 Or SER_XX_YY_00000',
                                                ]}
                                                validatorListener={result =>
                                                    this.validatorListener(
                                                        result
                                                    )
                                                }
                                                onChange={e =>
                                                    this.handleChange(
                                                        'oracleContractNumber',
                                                        e
                                                    )
                                                }
                                                placeholder=""
                                                fullWidth
                                                required
                                                disabled={isInAsSold}
                                                margin="dense"
                                                value={
                                                    this.state
                                                        .oracleContractNumber
                                                }
                                            />
                                        </Grid>
                                    ))}
                                {this.state.modelVersion !==
                                    modelVersion.IN_PROGRESS && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FFInput
                                            id="paperContractId"
                                            label="Paper Contract ID"
                                            name="paperContractId"
                                            onChange={e =>
                                                this.handleChange(
                                                    'paperContractId',
                                                    e
                                                )
                                            }
                                            placeholder=""
                                            fullWidth
                                            disabled={isInAsSold}
                                            margin="dense"
                                            value={this.state.paperContractId}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6} md={3}>
                                    <FFInput
                                        id="offeringType"
                                        label="Offering Type"
                                        name="offeringType"
                                        value={this.state.offeringType}
                                        disabled={this.props.edit}
                                        onChange={e =>
                                            this.handleChange('offeringType', e)
                                        }
                                        select={true}
                                        fullWidth
                                        required
                                        margin="dense"
                                        options={contractTypeInputs}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FFInput
                                        id="nuWarrantDurationMonths"
                                        name="nuWarrantDurationMonths"
                                        label="NU Warranty Duration"
                                        validators={[
                                            'matchRegexp:^([1-5]?[0-9]|60)$',
                                        ]}
                                        errorMessages={[
                                            'NU Warrenty Duration is in months and should be between 0 to 60, inclusive.',
                                        ]}
                                        validatorListener={result =>
                                            this.validatorListener(result)
                                        }
                                        value={
                                            this.state.nuWarrantDurationMonths
                                        }
                                        onChange={e =>
                                            this.handleChange(
                                                'nuWarrantDurationMonths',
                                                e
                                            )
                                        }
                                        placeholder="0-60"
                                        type="number"
                                        fullWidth
                                        disabled={isInAsSold}
                                        required
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    Months
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <br />
                                <Grid item xs={6} sm={6} md={3}>
                                    <FormControl margin="dense">
                                        <FormLabel>
                                            On-/Offshore Constellation?
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="On-/Offshore Constellation?"
                                            name="onOffshoreConstellation"
                                            onChange={e =>
                                                this.handleChange(
                                                    'onOffshoreConstellation',
                                                    e
                                                )
                                            }
                                            value={
                                                this.state
                                                    .onOffshoreConstellation
                                            }
                                            row
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={
                                                    <Radio
                                                        testName="onOffshoreConstellation"
                                                        testId="yes"
                                                        color="primary"
                                                    />
                                                }
                                                label="Yes"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={
                                                    <Radio
                                                        testName="onOffshoreConstellation"
                                                        testId="no"
                                                        color="primary"
                                                    />
                                                }
                                                label="No"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    <FormControl fullWidth margin="dense">
                                        <FormLabel component="label">
                                            Corrective Cap Per Event
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="Corrective Cap Per Event"
                                            name="correctiveCapPerEvent"
                                            onChange={e =>
                                                this.handleChange(
                                                    'correctiveCapPerEvent',
                                                    e
                                                )
                                            }
                                            value={
                                                this.state.correctiveCapPerEvent
                                            }
                                            row
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={
                                                    <Radio
                                                        testName="correctiveCapPerEvent"
                                                        testId="yes"
                                                        color="primary"
                                                    />
                                                }
                                                label="Yes"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={
                                                    <Radio
                                                        testName="correctiveCapPerEvent"
                                                        testId="no"
                                                        color="primary"
                                                    />
                                                }
                                                label="No"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    <FormControl fullWidth margin="dense">
                                        <FormLabel component="label">
                                            Corrective Cap Per Year
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="Corrective Cap Per Year"
                                            name="correctiveCorrectiveCapPerYear"
                                            onChange={e =>
                                                this.handleChange(
                                                    'correctiveCorrectiveCapPerYear',
                                                    e
                                                )
                                            }
                                            value={
                                                this.state
                                                    .correctiveCorrectiveCapPerYear
                                            }
                                            row
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={
                                                    <Radio
                                                        testName="correctiveCorrectiveCapPerYear"
                                                        testId="yes"
                                                        color="primary"
                                                    />
                                                }
                                                label="Yes"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={
                                                    <Radio
                                                        testName="correctiveCorrectiveCapPerYear"
                                                        testId="no"
                                                        color="primary"
                                                    />
                                                }
                                                label="No"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    <FormControl fullWidth margin="dense">
                                        <FormLabel component="label">
                                            Corrective Cap Over Total
                                            Contract-Term
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="Corrective Cap Over Total Contract-Term"
                                            name="correctiveCapOverTotalContractTerm"
                                            onChange={e =>
                                                this.handleChange(
                                                    'correctiveCapOverTotalContractTerm',
                                                    e
                                                )
                                            }
                                            value={
                                                this.state
                                                    .correctiveCapOverTotalContractTerm
                                            }
                                            row
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={
                                                    <Radio
                                                        testName="correctiveCapOverTotalContractTerm"
                                                        testId="yes"
                                                        color="primary"
                                                    />
                                                }
                                                label="Yes"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={
                                                    <Radio
                                                        testName="correctiveCapOverTotalContractTerm"
                                                        testId="no"
                                                        color="primary"
                                                    />
                                                }
                                                label="No"
                                                labelPlacement="end"
                                                disabled={isInAsSold}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        { this.state.loading ? (

                            <React.Fragment>
                                <div className={classes.spincontainer}>
                                    <Fade
                                    in={true}
                                    style={{
                                        transitionDelay: '100ms',
                                    }}
                                    unmountOnExit
                                    >
                                    <CircularProgress />
                                    </Fade>
                                </div>
                            </React.Fragment>
                            ) : (
                            <React.Fragment>
                                <Button
                                    testName="cancel"
                                    onClick={() => this.handleClose()}
                                    color="default"
                                >
                                    Cancel
                                </Button>
                                <AuthorizedToEditForm>
                                    <Button
                                        testName="save"
                                        onClick={() => this.handleSave()}
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                    {hasPrimaryConfiguration &&
                                        transitionButtonText &&
                                        (this.props.edit &&
                                            this.state.modelVersion !==
                                                modelVersion.ENDED) && (
                                            <Button
                                                testName="transitionToState"
                                                onClick={() => this.handleTransition()}
                                                color="primary"
                                                variant={'outlined'}
                                            >
                                                {transitionButtonText}
                                            </Button>
                                        )}
                                </AuthorizedToEditForm>
                            </React.Fragment>
                        )}
                    </DialogActions>
                </Dialog>
            </FormEditAuthorizationProvider>
        );
    }

    private getAddModelBtn(): React.ReactNode {
        return this.getOpenDialogBtn('newModel');
    }

    private getEditModelBtn(): React.ReactNode {
        const testName = this.props.readonly
            ? 'viewModel'
            : 'editModel';
        return this.getOpenDialogBtn(testName);
    }

    private getContractManagerList(contractManagers) {
        return contractManagers && sortBy(contractManagers, ['username']).map(cm => iv(cm.username, cm.displayName));
    }

    private getOpenDialogBtn(testName: string): React.ReactNode {
        const { classes } = this.props;

        return (
            <Button
                testName={testName}
                className={classNames(
                    classes.dialogButton,
                    'modalDialogTitle'
                )}
                color="primary"
                onClick={() => this.handleClickOpen()}
                disabled={this.props.disabled}
            >
                {this.props.icon ? this.props.icon : null}&nbsp;
                {this.props.hideTitle && this.props.icon
                    ? null
                    : this.props.title}
            </Button>
        );
    }

    private resetForm(): void {
        this.setState({
            paperContractId: undefined,
            customerNumber: undefined,
            oracleContractNumber: undefined,
            salesforceOpportunity: undefined,
            projectName: undefined,
            customerName: undefined,
            description: undefined,
            contractManager: undefined,
            customerPastDues: undefined,
            offeringType: undefined,
            nuWarrantDurationMonths: undefined,
            regionCatalogID: undefined,
            onOffshoreConstellation: undefined,
            effectiveContractStartDate: undefined,
            commercialOperationDate: undefined,
            sunsetClause: undefined,
            maxDurationYears: undefined,
            expirationDate: undefined,
            correctiveCapPerEvent: undefined,
            correctiveCorrectiveCapPerYear: undefined,
            correctiveCapOverTotalContractTerm: undefined,
            actualEndDate: undefined,
        });
    }

    private getAsSoldToHandoverFields(hasPrimaryConfiguration: boolean): React.ReactNode {
        if (!this.canTransitionFromAsSoldToHandover(hasPrimaryConfiguration)) {
            return null;
        }

        const { classes, contractManagers } = this.props;
        return (
            <div className="form-standout">
                <Typography variant="h6">
                    Save to 'Handover'
                </Typography>
                <Typography
                    variant="body1"
                    gutterBottom
                >
                    The below fields need to be filled
                    out properly before advancing to
                    'Handover'. Also, please make sure
                    the primary configuration units have
                    their serial numbers.
                </Typography>

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FFInput
                            id="customerName"
                            name="customerName"
                            label="Customer Name"
                            validators={[
                                'maxStringLength:50',
                            ]}
                            errorMessages={[
                                'The customer name must be 50 characters or less.',
                            ]}
                            validatorListener={result => this.validatorListener(result)}
                            value={this.state.customerName}
                            onChange={e => this.handleChange('customerName', e)}
                            required
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FFInput
                            name="customerNumber"
                            label="Customer DUNS Number"
                            validatorListener={result => this.validatorListener(result)}
                            value={this.state.customerNumber}
                            onChange={e => this.handleChange('customerNumber', e)}
                            fullWidth
                            required
                            margin="dense"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FFInput
                            id="contractManager"
                            label="Contract Manager"
                            name="contractManager"
                            required
                            validatorListener={result => this.validatorListener(result)}
                            value={this.state.contractManager}
                            onChange={e => this.handleChange('contractManager', e)}
                            select={true}
                            placeholder=""
                            fullWidth
                            margin="dense"
                            options={this.getContractManagerList(contractManagers)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FFInput
                            id="paperContractId"
                            label="Paper Contract ID"
                            name="paperContractId"
                            onChange={e => this.handleChange('paperContractId', e)}
                            fullWidth
                            margin="dense"
                            value={this.state.paperContractId}
                        />
                    </Grid>
                    <AuthorizedToEditForm>
                        <Grid item xs={6} sm={6} md={3}>
                            <label>
                                Scanned Paper Contract
                            </label>
                            <DropzoneArea
                                acceptedFiles={
                                    AcceptableModelScanMimes
                                }
                                dropzoneText="Drop your attachment here"
                                onChange={e => this.updateAttachment(e)}
                                filesLimit={25}
                                maxFileSize={100000000}
                                onDrop={e => true}
                            />
                        </Grid>    
                    </AuthorizedToEditForm>
                </Grid>
            </div>
        );
    }

    private canTransitionFromAsSoldToHandover(hasPrimaryConfiguration: boolean) {
        return hasPrimaryConfiguration && this.isModelVersion(modelVersion.AS_SOLD);
    }

    private getHandoverToOtrFields(hasPrimaryConfiguration: boolean): React.ReactNode {
        if (!this.canTransitionFromHandoverToOtr(hasPrimaryConfiguration)) {
            return null;
        }

        const today = moment().format('YYYY-MM-DD');

        let showAmendmentParentEndDate
            = (this.props.activeModel.amendmentInformation &&
                            this.props.activeModel.amendmentInformation.parentModel &&
                            !this.props.activeModel.amendmentInformation.parentModel.actualEndDate);

        const deleteCounterReadingDialogProps: IDeleteUnitCounterDialogProps = {
            units: this.state.unitsWithInvalidReadCounters,
            open: this.state.isInvalidCounterReadingDialogOpen,
            onCancel: this.onCounterDeletionCancel.bind(this),
            onProceed: this.onCounterDeletionApproval.bind(this),
        }

        return (
            <div className="form-standout">
                <DeleteUnitCounterDialog {...deleteCounterReadingDialogProps}></DeleteUnitCounterDialog>

                <Typography variant="h6">
                    Save to 'OTR'
                </Typography>
                <Typography
                    variant="body1"
                    gutterBottom
                >
                    Decide on final text before UAT.
                </Typography>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={3}>
                        <FFInput
                            label="Oracle Contracts #"
                            name="oracleContractNumber"
                            validators={[
                                'matchRegexp:^SER_\\w{2}(_\\w{2})?_\\d{5}$',
                            ]}
                            errorMessages={[
                                'Oracle Contracts # must be in the format SER_XX_00000 Or SER_XX_YY_00000',
                            ]}
                            validatorListener={result => this.validatorListener(result)}
                            onChange={e => this.handleChange('oracleContractNumber', e)}
                            fullWidth
                            required
                            margin="dense"
                            value={this.state.oracleContractNumber}
                        />
                        {showAmendmentParentEndDate &&
                             <FFInput
                                label="Parent Actual End Date"
                                name="parentActualEndDate"
                                validators={[
                                    'pastOrPresent'
                                ]}
                                errorMessages={[
                                    'Actual end date must be in the past or today',
                                ]}
                                validatorListener={result => this.validatorListener(result)}
                                onChange={e => {
                                    const date = e.target.value;
                                    const isoDate = dateToIso(date);
                                    this.handleChange('parentActualEndDate', isoDate);
                                }}

                                type="date"
                                fullWidth
                                required
                                margin="dense"
                                value={isoToDate(this.state.parentActualEndDate  || today)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: today
                                }}
                            />
                        }

                    </Grid>
                </Grid>
            </div>
        );
    }

    private canTransitionFromHandoverToOtr(hasPrimaryConfiguration: boolean): boolean {
        return hasPrimaryConfiguration && this.isModelVersion(modelVersion.HANDOVER);
    }

    private getOtrToEndedFields(hasPrimaryConfiguration: boolean): React.ReactNode {
        if (!this.canTransitionFromOtrToEnded(hasPrimaryConfiguration)) {
            return null;
        }

        const today = moment().format('YYYY-MM-DD');
        return (
            <div className="form-standout">
                <Typography variant="h6">
                    Save to 'Ended'
                </Typography>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={3}>
                        <FFInput
                            label="Actual End Date"
                            name="actualEndDate"
                            validators={[
                                'pastOrPresent'
                            ]}
                            errorMessages={[
                                'Actual end date must be in the past or today',
                            ]}
                            validatorListener={result => this.validatorListener(result)}
                            onChange={e => {
                                const date = e.target.value;
                                const isoDate = dateToIso(date);
                                this.handleChange('actualEndDate', isoDate);
                            }}
                            type="date"
                            fullWidth
                            required
                            margin="dense"
                            value={isoToDate(this.state.actualEndDate || moment().startOf('day').toISOString())}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: today
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    private onCounterDeletionApproval() {
        this.setState({ 
            hasDeleteReadCounterApproval: true,
            isInvalidCounterReadingDialogOpen: false
        }, () => this.handleTransition());
    }

    private onCounterDeletionCancel() {
        this.setState({ 
            isInvalidCounterReadingDialogOpen: false
        });
    }

    private getStateOnSave() {
        const nextTransitionFields = this.getNextTransitionFields();

        const removeField = (state, field) => dissoc(field, state);

        const stateCopyWithoutTransitionFields = nextTransitionFields.reduce(removeField, this.state);
        return stateCopyWithoutTransitionFields;
    }

    private getNextTransitionFields(): string[] {
        const hasPrimaryConfiguration = this.hasPrimaryConfiguration();

        if (this.canTransitionFromOtrToEnded(hasPrimaryConfiguration)) {
            return ['actualEndDate'];
        }

        return [];
    }

    private canTransitionFromOtrToEnded(hasPrimaryConfiguration: boolean): boolean {
        return hasPrimaryConfiguration && this.isModelVersion(modelVersion.OTR);
    }

    private isModelVersion(version: modelVersion): boolean {
        return this.state.modelVersion === version;
    }

    private hasPrimaryConfiguration(): boolean {
        if (!this.hasConfigurations()) {
            return false;
        }

        const { activeModel } = this.props;

        const isCompletedAndPrimaryConfig = (overview: IConfigurationView) =>
            overview.state === 'COMPLETED' && overview.primaryConfiguration;

        const hasPrimaryConfiguration = activeModel.configurationOverviews
            .some(isCompletedAndPrimaryConfig);
        return hasPrimaryConfiguration;
    }

    private hasConfigurations(): boolean {
        const { activeModel } = this.props;
        return hasValue(activeModel) && !isEmpty(activeModel.configurationOverviews);
    }
}
const stateToSubmission = (appstate: any, activeModel: IFormModel) => {
    const state = Object.assign({}, appstate);
    state.correctiveCapOverTotalContractTerm =
        state.correctiveCapOverTotalContractTerm === 'yes';
    state.correctiveCorrectiveCapPerYear =
        state.correctiveCorrectiveCapPerYear === 'yes';
    state.correctiveCapPerEvent = state.correctiveCapPerEvent === 'yes';
    state.dealInLineWithChannelPolicy =
        state.dealInLineWithChannelPolicy === 'yes';
    state.onOffshoreConstellation = state.onOffshoreConstellation === 'yes';
    state.competitors = formatCompetitors(state.competitors);
    delete state.open;
    const model = merge(activeModel, state);
    // remote empty attachments
    const containsNewAttachments = model && model.attachments && model.attachments.filter(attachment => hasValue(attachment) && !hasValue(attachment.id)).length > 0;
    if (containsNewAttachments) {
        model.attachments = [];
    }
    return model;
};

export interface ModelDialogState {
    open: boolean;
    competitors: any;
    regionCatalogID?: number;
    catalogRegionID?: number;
    correctiveCapOverTotalContractTerm: string;
    correctiveCorrectiveCapPerYear: string;
    correctiveCapPerEvent: string;
    dealInLineWithChannelPolicy: string;
    onOffshoreConstellation: string;
    modelVersion: modelVersion;
    offeringType: contractType;
    actualEndDate?: string;
    disabled: boolean;
    loading: boolean;
    hasDeleteReadCounterApproval: boolean;
    unitsWithInvalidReadCounters: IUnitWithConfigId[];
    isInvalidCounterReadingDialogOpen: boolean;
    attachments?: any;
    parentActualEndDate?: string;
    salesforceOpportunity?: string;
    projectName?: string;
    customerName?: string;
    customerNumber?: string;
    description?: string;
    oracleContractNumber?: string;
    paperContractId?: string;
    nuWarrantDurationMonths?: number;
    contractManager?: string;
    customerPastDues?: string;
    effectiveContractStartDate?: string;
    commercialOperationDate?: string;
    sunsetClause?: sunsetClause;
    maxDurationYears?: any;
    expirationDate?: string;
}

const mapStateToProps = state => {
    return {
        activeModel: state.models.activeModel,
        competitors: state.models.competitors,
        regions: state.catalogs.regions,
        modelListPage: state.models.modelListPage,
        contractManagers: state.models.contractManagers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        populateRegionsAndCompetitors: (activeOnly: boolean) => {
            dispatch(getFormCatalogs(CatalogType.region, activeOnly));
            dispatch(initModelForm());
        },
        submit: (model: IFormModel, page: ModelPageRequest) => {
            return dispatch(submitModel(model, page));
        },
        transitionModel: (model: IFormModel) => {
            return dispatch(transitionModelState(model));
        },
        submitAttachment: (data, model) => {
            return dispatch(attachModelAttachment(data, model));
        },
        getOne: id => {
            return dispatch(getModelDetails(id));
        },
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(ModelDialog))
);
