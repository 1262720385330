import React, { FunctionComponent, ReactNode } from 'react';
import { CaseAuthorizedToUpdateModel } from './CaseAuthorizedToUpdateModel';
import { IFormModel } from '../../data/model/model';

export const AuthorizedToUpdateModel: FunctionComponent<IAuthorizedToUpdateModelProps> =
    props =>
        <CaseAuthorizedToUpdateModel model={props.model}>
            {
                authorized => authorized
                    ? props.children
                    : null
            }
        </CaseAuthorizedToUpdateModel>;

export interface IAuthorizedToUpdateModelProps {
    model: IFormModel,
    children: ReactNode,
}
