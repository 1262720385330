import React, { Component, ReactNode } from 'react';
import MaterialDialog, { DialogProps as MaterialDialogProps } from '@material-ui/core/Dialog';
import { isFunction } from 'lodash';
import { createDialogTestProps, ITestProps } from '../Utils/testProps';

export class Dialog extends Component<DialogProps, DialogState> {

    public state = {
        open: false,
    };

    public render(): ReactNode {
        return (
            <>
                {this.renderButton()}
                {this.renderDialog()}
            </>
        );
    }

    private renderButton(): ReactNode {
        const openHandler = this.getOpenHandler();
        return this.props.renderOpenButton(openHandler);
    }

    private renderDialog(): ReactNode {
        const open = this.isOpen();
        const closeHandler = this.getCloseHandler();
        const dialogProps = this.getMaterialDialogProps();
        const testProps = this.getTestProps();
        return (
            <MaterialDialog {...dialogProps} {...testProps} open={open} onClose={closeHandler}>
                {this.renderDialogContent()}
            </MaterialDialog>
        );
    }

    private getMaterialDialogProps(): ManagedDialogProps {
        const { renderOpenButton, children, testName, ...dialogProps } = this.props;
        return dialogProps;
    }

    private getTestProps(): ITestProps {
        return createDialogTestProps(this.props.testName);
    }

    private renderDialogContent(): ReactNode {
        const children = this.props.children;

        if (isFunction(children)) {
            const closeHandler = this.getCloseHandler();
            return children(closeHandler);
        } else {
            return children;
        }
    }

    private getOpenHandler(): ToggleFunction {
        return () => this.open();
    }

    private open(): void {
        this.setOpen(true);
    }

    private getCloseHandler(): ToggleFunction {
        return () => this.close();
    }

    private close(): void {
        this.setOpen(false);
    }

    private setOpen(open: boolean): void {
        this.setState({ open });
    }

    private isOpen(): boolean {
        return this.state.open;
    }
}

export interface DialogProps extends ManagedDialogProps {
    renderOpenButton: (open: ToggleFunction) => ReactNode,
    children: DialogContent,
    testName: string,
}

type ManagedDialogProps = Omit<MaterialDialogProps, 'open' | 'onClose' | 'children'>;
type DialogContent = DialogContentRenderer | ReactNode;
type DialogContentRenderer = (close: ToggleFunction) => ReactNode;
type ToggleFunction = () => void;

interface DialogState {
    open: boolean,
}
