import * as React from 'react';
import './ConfigCard.scss';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import IconButton from '../../Buttons/IconButton';
import Button from '../../Buttons/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '../../Cards/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import FlagOutlineIcon from '@material-ui/icons/FlagOutlined';
import FlagIcon from '@material-ui/icons/Flag';
import { contractCatagoryInputs, } from '../../../data/model/configuration';
import ActionMenu from '../../Utils/ActionMenu';
import { CircularProgress } from '@material-ui/core';
import { canChangePrimaryConfiguration } from '../../../data/actions/models';
import { IFormOption } from '../../../data/utils/form';
import { find } from 'lodash';
import { modelVersion } from '../../../data/model/model';
import { fromServiceDateTime } from '../../../data/utils/date';
import { sunsetClause } from '../../../data/model/configuration_enums';
import { CaseAuthorizedToUpdateModel } from '../../authorization/CaseAuthorizedToUpdateModel';
import { Authorized } from '../../authorization';
import { Authority } from '../../../data/model/user';
import { CaseAuthorized } from '../../authorization/CaseAuthorized';
import { withRouter } from 'react-router-dom';

const styles = (theme: any) => ({
    card: {
        border: '2px solid #fff',
    },
    primaryCard: {
        border: '2px solid #ff9100',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
        padding: '4px 8px',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardHeader: {
        padding: '13px',
    },
    cardContent: {
        padding: '4px 16px',
    },
    cardAction: {
        marginTop: '0px',
        marginRight: '0px',
    },
    cardAvatar: {
        marginRight: '5px',
    },
    cardTitle: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        lineHeight: '120%',
        fontSize: '1.15rem',
        color: '#4caf50',
    },
    flagIcon: {
        position: 'relative',
        fontSize: 25,
        top: 5,
        marginBottom: 10,
        cursor: 'pointer',
    },
});

class ConfigCard extends React.Component<any, any> {
    public state = {
        expanded: false,
        loading: false,
    };

    public handleExpandClick() : void {
        this.setState(state => ({ expanded: !state.expanded }));
    }

    public showLoading() : void {
        this.setState({ loading: true }, () => {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 1000);
        });
    };

    public render() : React.ReactFragment {
        const { classes, config, model } = this.props;

        const configViewLink = (props: any) => (
                <Link
                    to={`${model.id}/configuration/view/${config.id}`}
                    {...props}
                />
            );
        const configEditLink = this.getConfigEditLink();

        const contractCategory = find<IFormOption>(contractCatagoryInputs, {
            value: config.contractCategory,
        }) || { label: 'none' };

        const authorizedActionMenu =
            <CaseAuthorizedToUpdateModel model={model}>
                {authorizedToEdit => this.getActionMenu(authorizedToEdit)}
            </CaseAuthorizedToUpdateModel>;

        const primaryConfigurationToggle =
            <CaseAuthorizedToUpdateModel model={model}>
                {authorizedToEdit => this.getPrimaryConfigurationToggle(authorizedToEdit)}
            </CaseAuthorizedToUpdateModel>;

        return (
            <Grid item>
                <Card
                    testName="configuration"
                    testId={'' + config.id}
                    classes={
                        config.primaryConfiguration
                            ? { root: classes.primaryCard }
                            : { root: classes.card }
                    }
                    square
                    elevation={(!!config.primaryConfiguration && 5) || 1}
                >
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            action: classes.cardAction,
                            avatar: classes.cardAvatar,
                            title: classes.cardTitle,
                        }}
                        action={authorizedActionMenu}
                        title={
                            <React.Fragment>
                                {primaryConfigurationToggle}
                                <br />
                                <Link
                                    to={`${model.id}/configuration/view/${
                                        config.id
                                    }`}
                                >
                                    {' '}
                                    {config.displayName}{' '}
                                </Link>
                            </React.Fragment>
                        }
                    />
                    <CardContent classes={{ root: classes.cardContent }}>
                        <Typography component="p">
                            {config.description}
                        </Typography>
                    </CardContent>
                    <CardActions
                        className={classes.actions}
                        disableActionSpacing
                    >
                        {config.state !== 'COMPLETED' ? (
                            <Button
                                testName="continue"
                                size="small"
                                color="primary"
                                component={configEditLink}
                            >
                                Continue
                            </Button>
                        ) : (
                            <React.Fragment>
                                <Button
                                    testName="viewDetails"
                                    size="small"
                                    color="primary"
                                    component={configViewLink}
                                >
                                    View Details
                                </Button>
                            </React.Fragment>
                        )}
                        <IconButton
                            testName="showMore"
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={() => this.handleExpandClick()}
                            aria-expanded={this.state.expanded}
                            aria-label="Show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse
                        in={this.state.expanded}
                        timeout="auto"
                        unmountOnExit
                    >
                        <CardContent>
                            <Typography variant="caption">
                                Updated By: <strong>{config.modifiedBy}</strong>
                                <br />
                                Updated On: <strong>{config.modifiedOn}</strong>
                                <br />
                                Number of Units:{' '}
                                <strong>{config.numberOfUnits}</strong>
                                <br />
                                Catalog Reference:{' '}
                                <strong>{config.catalogReference}</strong>
                                <br />
                                Contract Category:{' '}
                                <strong>
                                    {(contractCategory &&
                                        contractCategory.label) ||
                                        ''}
                                </strong>
                                <br />
                                Created By: <strong>{config.createdBy}</strong>
                                <br />
                                Sunset Clause:{' '}
                                <strong>{this.getSunsetClauseDisplay(config)}</strong>
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
        );
    }

    private getSunsetClauseDisplay(config) : string {
        let sunsetClauseDisplay = 'None';
        switch (config.sunsetClauseType) {
            case sunsetClause.EXPIRATION_DATE:
                if (config.term) {
                    sunsetClauseDisplay = `Expiration Date ${fromServiceDateTime(
                        config.term.expirationDate
                    ).toLocaleDateString()}`;
                } else if (config.sunsetClauseExpirationDate) {
                    sunsetClauseDisplay = `Expiration Date ${fromServiceDateTime(
                        config.sunsetClauseExpirationDate
                    ).toLocaleDateString()}`;
                }
                break;
            case sunsetClause.MAX_DURATION:
                if (config.term) {
                    sunsetClauseDisplay = `Max Duration ${
                        config.term.maxDuration
                        } Years`;
                } else if (config.sunsetClauseMaxDuration) {
                    sunsetClauseDisplay = `Max Duration ${
                        config.sunsetClauseMaxDuration
                        } Years`;
                }
                break;
            default:
                // no action required
        }
        return sunsetClauseDisplay;
    }

    private getActionMenu(authorizedToEdit: boolean): React.ReactNode {
        const { config, model } = this.props;
        const actionMenuTestName = 'configurationActions';
        const actionMenuTestId = '' + config.id;

        if (!authorizedToEdit) {
            return model.authorizedToCloneFromModel
                ? this.getCloneActionMenu(actionMenuTestName, actionMenuTestId)
                : null;
        }

        const isModelInProgress = model.modelVersion === modelVersion.IN_PROGRESS;
        if (isModelInProgress) {
            return this.getEditCloneDeleteActionMenu(actionMenuTestName, actionMenuTestId);
        }

        return (
            <CaseAuthorized for={Authority.WRITE_MODELS_IN_PROGRESS}>
                {
                    authorized => authorized
                        ? this.getEditCloneActionMenu(actionMenuTestName, actionMenuTestId)
                        : this.getEditActionMenu(actionMenuTestName, actionMenuTestId)
                }
            </CaseAuthorized>
        );
    }

    private getCloneActionMenu(actionMenuTestName: string, actionMenuTestId: string): React.ReactNode {
        const { model, cloneThis, searchModelsForClone, getOne } = this.props;

        return (
            <Authorized for={Authority.WRITE_MODELS_IN_PROGRESS}>
                <ActionMenu
                    testName={actionMenuTestName}
                    testId={actionMenuTestId}
                    model={model}
                    getOne={getOne}
                    searchModelsForClone={searchModelsForClone}
                    cloneThis={cloneThis}
                />
            </Authorized>
        );
    }

    private getEditCloneDeleteActionMenu(actionMenuTestName: string, actionMenuTestId: string): React.ReactNode {
        const { config, model, cloneThis, deleteThis, searchModelsForClone, getOne } = this.props;
        return (
            <ActionMenu
                testName={actionMenuTestName}
                testId={actionMenuTestId}
                model={model}
                getOne={getOne}
                openEdit={() => this.redirectToConfigurationEdit()}
                searchModelsForClone={searchModelsForClone}
                cloneThis={cloneThis}
                deleteThis={deleteThis}
                deleteTitle={'Delete Configuration'}
                deleteMessage={`Are you sure you want to delete ${config.displayName}? This cannot be undone.`}
            />
        );
    }

    private getEditCloneActionMenu(actionMenuTestName: string, actionMenuTestId: string): React.ReactNode {
        const { model, cloneThis, searchModelsForClone, getOne } = this.props;
        return (
            <ActionMenu
                testName={actionMenuTestName}
                testId={actionMenuTestId}
                model={model}
                getOne={getOne}
                openEdit={() => this.redirectToConfigurationEdit()}
                searchModelsForClone={searchModelsForClone}
                cloneThis={cloneThis}
            />
        );
    }

    private getEditActionMenu(actionMenuTestName: string, actionMenuTestId: string): React.ReactNode {
        return (
            <ActionMenu
                testName={actionMenuTestName}
                testId={actionMenuTestId}
                openEdit={() => this.redirectToConfigurationEdit()}
            />
        );
    }

    private redirectToConfigurationEdit(): void {
        const { config, model, history } = this.props;
        const modelId = model.id;
        const configId = config.id;
        const configEditUrl = `${modelId}/configuration/edit/${configId}`;
        history.push(configEditUrl);
    }

    private getConfigEditLink() {
        const { config, model } = this.props;

        const ConfigEditLink = (props: any) => {
            const modelId = model.id;
            const configId = config.id;
            const configEditUrl = `${modelId}/configuration/edit/${configId}`;
            return <Link to={configEditUrl} {...props}/>;
        };
        return ConfigEditLink;
    }

    private getPrimaryConfigurationToggle(authorizedToEdit: boolean): React.ReactNode {
        const isLoading = this.state.loading;
        if (isLoading) {
            return <CircularProgress/>;
        }

        const { config, setPrimary, model } = this.props;

        const changePrimaryConfig = () => {
            if (canChangePrimaryConfiguration(model, config)) {
                this.showLoading();
            }

            // It looks strange that this is not inside the "can change" clause above,
            // but it looks like the reason behind it is to let the user get an error
            // when trying to change the primary config in an invalid scenario.
            setPrimary(config);
        };
        const authorizedClickHandler = authorizedToEdit
            ? changePrimaryConfig
            : null;

        if (config.primaryConfiguration) {
            const title = authorizedToEdit
                ? 'Remove as primary'
                : 'Configuration is primary';
            return (
                <Tooltip
                    title={title}
                    placement="top"
                    onClick={authorizedClickHandler}
                >
                    <FlagIcon color="secondary" className={this.props.classes.flagIcon}/>
                </Tooltip>
            );
        }

        const title = authorizedToEdit
            ? 'Select as primary'
            : 'Configuration is not primary';
        return (
            <Tooltip
                title={title}
                placement="top"
                onClick={authorizedClickHandler}
            >
                <FlagOutlineIcon color="disabled" className={this.props.classes.flagIcon}/>
            </Tooltip>
        );
    }
}

export default withStyles(styles as any)(withRouter(ConfigCard));
