import React, { FunctionComponent, ReactNode } from 'react';
import { CaseAuthorizedToCreateModel } from './CaseAuthorizedToCreateModel';

export const AuthorizedToCreateModel: FunctionComponent<IAuthorizedToCreateModelProps> =
    props =>
        <CaseAuthorizedToCreateModel>
            {
                authorized => authorized
                    ? props.children
                    : null
            }
        </CaseAuthorizedToCreateModel>

export interface IAuthorizedToCreateModelProps {
    children: ReactNode,
}
