import React from 'react';
import { Authority } from '../../data/model/user';

const defaultAuthorities: AuthorizationContextValue = [];
export type AuthorizationContextValue = Authority[];

const context = React.createContext(defaultAuthorities);
context.displayName = 'AuthorizationContext';

export const AuthorizationProvider = context.Provider;
export const AuthorizationConsumer = context.Consumer;
