import React from 'react';
import MaterialTextField, { TextFieldProps as MaterialTextFieldProps } from '@material-ui/core/TextField';
import { createErrorTestProps, createInputTestProps } from '../Utils/testProps';
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';
import { MapWithRequiredOnUnionType } from '../../utils/MapWithRequiredOnUnionType';
import { nextId } from '../../utils/id-generator';

export const TextField: React.FunctionComponent<TextFieldProps> =
    props => {
        const {
            id,
            disabled,
            inputProps,
            FormHelperTextProps,
            ...remainingTextFieldProps
        } = props;

        const fieldId = id || nextId(remainingTextFieldProps.name);

        const testName = remainingTextFieldProps.name;
        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(testName),
        };

        const isValid = !remainingTextFieldProps.error;
        const fullHelperTextProps = isValid
            ? FormHelperTextProps
            : {
                ...FormHelperTextProps,
                ...createErrorTestProps(testName),
            };

        return (
            <CaseAuthorizedToEditForm>
                {authorizedToEdit => {
                    const inputDisabled = getInputDisabled(authorizedToEdit, disabled);
                    return (
                        <MaterialTextField
                            id={fieldId}
                            disabled={inputDisabled}
                            inputProps={fullInputProps}
                            FormHelperTextProps={fullHelperTextProps}
                            {...remainingTextFieldProps}
                        />
                    );
                }}
            </CaseAuthorizedToEditForm>
        );
    };

export type TextFieldProps = MapWithRequiredOnUnionType<MaterialTextFieldProps, 'name'>;

function getInputDisabled(authorizedToEdit: boolean, disabled: boolean) {
    return !authorizedToEdit || disabled;
}
