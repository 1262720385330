import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '../../Tabs/Tab';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '../../Tables/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeaderCell from '@material-ui/core/TableCell';
import TableDataCell from '../../Tables/TableDataCell';
import TableHead from '@material-ui/core/TableHead';
import TableHeaderRow from '@material-ui/core/TableRow';
import TableDataRow from '../../Tables/TableDataRow';
import TablePagination from '../../Tables/TablePagination';
import ConfigIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '../../Form/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { formatCurrency } from '../../../data/utils/number';

const decorateWithStyles = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 12,
    },
}));

const StyledTableHeaderCell = decorateWithStyles(TableHeaderCell);
const StyledTableDataCell = decorateWithStyles(TableDataCell);

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        flexGrow: 1,
    },
    appBarTabs: {
        flexGrow: 3,
    },
});

function TabContainer(props: any) {
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className="p2"
            >
                <FormControl>
                    <NativeSelect
                        testName="financialPerformanceBreakdownType"
                        classes={{ root: 'smallNativeSelect' }}
                        value={props.breakdownDelim}
                        onChange={props.onChange}
                    >
                        <option value="month">View by Month</option>
                        <option value="quarter">View by Quarter</option>
                        <option value="year">View by Year</option>
                    </NativeSelect>
                </FormControl>
            </Grid>
            {props.children}
        </React.Fragment>
    );
}

const breakdownPeriod = (dateString: string, delimiter: string): string => {
    const date = moment(dateString, 'YYYY-MM-DD');

    if (delimiter === 'quarter') {
        return `Q${date.quarter()} ${date.format('YYYY')}`;
    } else if (delimiter === 'year') {
        return date.format('YYYY');
    } else {
        return date.format('MM-YYYY');
    }
};

class FinPerformance extends React.Component<any, any> {
    public state = {
        breakdownDelimiter: 'quarter',
        page: 0,
        perPage: 10,
        tab: 0,
    };

    public handleChange(name, raw: boolean, event: any, page?: any): void {
        if (name === 'tab') {
            const newTab = this.state.tab === 0 ? 1 : 0;
            this.setState({ tab: newTab });
        } else if (name === 'page') {
            this.setState({ page });
        } else {
            if (raw) {
                this.setState({ [name]: event });
            } else {
                this.setState({ [name]: event.target.value });
            }
        }
    }
    
    public render(): React.ReactElement {
        const { classes, data, totalModelCost } = this.props;
        const { breakdownDelimiter, page, perPage, tab } = this.state;

        const rows: any[] = [];

        const chart = {
            title: {
                style: {
                    display: 'none',
                },
            },
            chart: {
                zoomType: 'xy',
            },
            xAxis: {
                categories: [],
            },
            tooltip: {
                pointFormatter: function() {
                    return `${this.category}: ${formatCurrency(
                        'EUR',
                        this.y
                    )}</b><br/>`;
                },
            },
            yAxis: {
                minorGridLineColor: '#dbf7dd',
                minorTickInterval: 'auto',
                title: { text: 'Cost (€)' },
            },
            series: [
                {
                    data: [],
                    color: '#4caf50',
                    name: 'Cost',
                    visible: totalModelCost > 0,
                },
                {
                    data: [],
                    color: '#4c6eaf',
                    name: 'Billings Cons. LD/B',
                },
                {
                    data: [],
                    color: '#af4c9d',
                    name: 'Margin (Cumulative)',
                    visible: totalModelCost > 0,
                },
                {
                    data: [],
                    color: '#adaf4c',
                    name: 'Deferred (Cumulative)',
                    visible: totalModelCost > 0,
                },
            ],
        };

        let currentPeriod = null;
        if (data.length > 0) {
            currentPeriod = breakdownPeriod(data[0].month, breakdownDelimiter);
        }

        let cost = 0;
        let billings = 0;
        let margin = 0;
        let cumMargin = 0;
        let deferred = 0;
        let cumDeferred = 0;

        const rowsMap = {};
        const rowsOrder = [];
        data.map(monthEntry => {
            const period = breakdownPeriod(
                monthEntry.month,
                breakdownDelimiter
            );

            // Reset Data
            if (period !== currentPeriod) {
                cost = 0;
                billings = 0;
                margin = 0;
                cumMargin = 0;
                deferred = 0;
                cumDeferred = 0;
            }

            cost += monthEntry.modelCost;
            billings += monthEntry.modelBillingsConsidLDB;
            margin += monthEntry.modelMargin;
            // always use the commutative value raw since the final value for the grouping would be the correct value
            cumMargin = monthEntry.modelCumMargin;
            deferred += monthEntry.modelDeferredCost;
            // always use the commutative value raw since the final value for the grouping would be the correct value
            cumDeferred = monthEntry.modelCumDeferredCost;

            // we now do this everytime but replace the value for the data in the rows by period value

            // add rows order if not yet added to map
            if (!rowsMap[period]) {
                rowsOrder.push(period);
            }

            // replace the period row in the map
            rowsMap[period] = {
                period,
                cost,
                billings,
                margin,
                cumMargin,
                deferred,
                cumDeferred,
            };

            // lets track the period so we can reset values below
            currentPeriod = period;
        });

        // convert map to array using the order just in case the browser dones't keep the order on Object.values
        rowsOrder.forEach(pid => rows.push(rowsMap[pid]));

        // do the chart data after we get calculate the rows correctly
        rows.forEach(periodRow => {
            // Chart Data Entry
            // push cumulative
            chart.xAxis.categories.push((' ' + periodRow.period).slice(1)); // done to clone the string
            chart.series[0].data.push(periodRow.cost);
            chart.series[1].data.push(periodRow.billings);
            chart.series[2].data.push(periodRow.cumMargin);
            chart.series[3].data.push(periodRow.cumDeferred);
        });

        return (
            <div className={classes.root}>
                <Paper>
                    <AppBar position="static" color="default">
                        <Tabs
                            variant="standard"
                            value={tab}
                            onChange={e => this.handleChange('tab', false, e)}
                        >
                            <Tab testName="chartTab" label="Chart" />
                            <Tab testName="tableTab" label="Table" />
                        </Tabs>
                    </AppBar>
                    {tab === 1 && (
                        <TabContainer
                            breakdownDelim={breakdownDelimiter}
                            onChange={e =>
                                this.handleChange(
                                    'breakdownDelimiter',
                                    false,
                                    e
                                )
                            }
                        >
                            <div>
                                <Table testName="financialPerformance">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <StyledTableHeaderCell>
                                                Period
                                            </StyledTableHeaderCell>
                                            <StyledTableHeaderCell>
                                                Cost
                                            </StyledTableHeaderCell>
                                            <StyledTableHeaderCell>
                                                Billings Cons. LD/B
                                            </StyledTableHeaderCell>
                                            <StyledTableHeaderCell align="right">
                                                Margin
                                            </StyledTableHeaderCell>
                                            <StyledTableHeaderCell align="right">
                                                Cum. Margin
                                            </StyledTableHeaderCell>
                                            <StyledTableHeaderCell align="right">
                                                Deferred
                                            </StyledTableHeaderCell>
                                            <StyledTableHeaderCell align="right">
                                                Cum. Deferred
                                            </StyledTableHeaderCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .slice(
                                                page * perPage,
                                                page * perPage + perPage
                                            )
                                            .map(row => (
                                                <TableDataRow
                                                    testName="financialPerformancePeriod"
                                                    testId={row.period.replace(/\s/g, '-')}
                                                >
                                                    <StyledTableDataCell
                                                        testName="period"
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.period}
                                                    </StyledTableDataCell>
                                                    <StyledTableDataCell testName="cost">
                                                        {formatCurrency(
                                                            'EUR',
                                                            row.cost
                                                        )}
                                                    </StyledTableDataCell>
                                                    <StyledTableDataCell testName="billings">
                                                        {formatCurrency(
                                                            'EUR',
                                                            row.billings
                                                        )}
                                                    </StyledTableDataCell>
                                                    <StyledTableDataCell testName="margin" align="right">
                                                        {formatCurrency(
                                                            'EUR',
                                                            row.margin
                                                        )}
                                                    </StyledTableDataCell>
                                                    <StyledTableDataCell testName="cumMargin" align="right">
                                                        {formatCurrency(
                                                            'EUR',
                                                            row.cumMargin
                                                        )}
                                                    </StyledTableDataCell>
                                                    <StyledTableDataCell testName="deferred" align="right">
                                                        {formatCurrency(
                                                            'EUR',
                                                            row.deferred
                                                        )}
                                                    </StyledTableDataCell>
                                                    <StyledTableDataCell testName="cumDeferred" align="right">
                                                        {formatCurrency(
                                                            'EUR',
                                                            row.cumDeferred
                                                        )}
                                                    </StyledTableDataCell>
                                                </TableDataRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={perPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={(e, p) =>
                                    this.handleChange('page', true, e, p)
                                }
                                onChangeRowsPerPage={e =>
                                    this.handleChange('perPage', false, e)
                                }
                            />
                        </TabContainer>
                    )}
                    {tab === 0 && (
                        <TabContainer
                            breakdownDelim={breakdownDelimiter}
                            onChange={e =>
                                this.handleChange(
                                    'breakdownDelimiter',
                                    false,
                                    e
                                )
                            }
                        >
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chart}
                            />
                        </TabContainer>
                    )}
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(FinPerformance);
