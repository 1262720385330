import { iv } from '../utils/form';
import { ScopeTypesInputs } from './configuration_enums';
import { find } from 'lodash';

export enum ReferencePrice {
    GROSS = 'GROSS',
    Dnet = 'Dnet',
}

export enum DiscountMatrix {
    CHANNEL_PARTNER = 'CHANNEL_PARTNER',
    KEY_ACCOUNT = 'KEY_ACCOUNT',
}

export interface IPricing {
    pricingTarget?: IPricingTarget;
    toolTargetPrice?: number,
    toolTargetDiscount?: number;
    actualDiscount?: number;
    referencePriceList?: ReferencePrice;
    discountMatrix?: DiscountMatrix;
    usageBillings?: IPricingBilling[];
    fixedBillings?: IPricingBilling[];
    milestoneBillings?: IPricingBilling[];
    packageBillings?: IPricingBilling[];
    lds?: IPricingMisc[];
    bonuses?: IPricingMisc[];
    monetaryCaps?: IPricingMisc[];
    quantitativeCaps?: IPricingMisc[];
    description?: string;
    id?: number;
}

export interface IPricingTarget {
    toolTargetPrice?: number,
    userTargetPrice?: number;
    userTargetCm?: number;
}

export interface IPricingBilling {
    alwaysBilled?: boolean,
    tableData?: any;
    billingEndCounter?: number;
    billingStartCounter?: number;
    trigger?: number;
    uom?: PricingUOM;
    scope?: string;
    frequency?: number;
    rate?: number;
    id?: number;
}

export interface IPricingMisc {
    tableData?: any; // added by the table library we use for pricing
    title: string;
    amount?: number;
    frequency?: PricingFrequency;
    quantity?: number;
    id?: number;
}

export interface ITableBreakdown {
    frequencies?: number[];
    tableData: ITableBreakdownRow[];
    subtotals: IBreakdownRow;
}

export interface ITableBreakdownRow extends IBreakdownRow {
    secondary?: IBreakdownRow[];
    scope?: IBreakdownRow[] | string;
    service?: IBreakdownRow[];
}

export interface IBreakdownRow {
    title?: string;
    parts?: number;
    labor?: number;
    otherProvider?: number;
    freight?: number;
    customs?: number;
}

export enum PricingFrequency {
    YEARLY = 'YEARLY',
    QUARTERLY = 'QUARTERLY',
    MONTHLY = 'MONTHLY',
}

export enum PricingUOM {
    OPH = 'OPH',
}

export const PricingUOMInputs = [iv('OPH', 'OPH', true)];

export const activtyTypeToBreakdownKey = (activityType: string): string => {
    return (' ' + find(ScopeTypesInputs, { value: activityType }).label)
        .slice(1)
        .toLowerCase()
        .replace('innio', '')
        .replace('other provider', 'otherProvider')
        .trim();
};

