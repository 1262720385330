import { startLoading, successResponse, errorResponse } from './candidateFilterOptionsSlice';
import { getCandidateFilters as getCandidateFiltersFromApi } from '../../api/model-service/actualization';
import { CatalogRegion, getRegionCatalogs, GetRegionCatalogsRequest } from '../../api/catalog-service/regionCatalogs';
import { reportConfigurationError } from '../error-actions';
import { AppThunk } from '../app-thunk';
import { getContractManagers, UserMail } from '../../api/authenticationService/users';

export const getCandidateFilterOptions = (): AppThunk => dispatch => {
    const regionCatalogsRequest: GetRegionCatalogsRequest = {
        state: [ 'ACTIVE', 'DEFAULT' ],
    };

    dispatch(startLoading());
    const apiRequests = Promise.all([
        getCandidateFiltersFromApi(),
        getRegionCatalogs(regionCatalogsRequest),
        getContractManagers(),
    ]);

    return apiRequests
        .then(([ candidateFilters, regionCatalogs, contractManagers ]) => {
            const candidateRegions: CatalogRegion[] = regionCatalogs
                .flatMap(catalog => catalog.regions)
                .filter(region => candidateFilters.catalogRegionIds.includes(region.id));

            const candidateManagers: UserMail[] = contractManagers
                .filter(manager => candidateFilters.contractManagers.includes(manager.username));

            dispatch(successResponse({
                catalogRegions: candidateRegions,
                contractCategories: candidateFilters.contractCategories,
                engineTypes: candidateFilters.engineTypes,
                contractManagers: candidateManagers,
                actualizationPeriods: candidateFilters.actualizationPeriods,
            }))
        })
        .catch(err => {
            dispatch(reportConfigurationError(err));
            dispatch(errorResponse(err));
            throw err;
        });
};
