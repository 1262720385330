import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';
import { MultiSelectField } from '../MultiSelectField/MultiSelectField';
import { arrayInputValue } from '../../utils/arrayInputValue';

export const EngineType: FC<EngineTypeInputProps> =
    ({ formikProps, engineTypes, disabled }) => {
        const name = 'engineTypes';
        const errorMessage = inputError(name, formikProps);
        const options: IFormOption[] = engineTypes.map(type => formOption(type));
        return (
            <MultiSelectField
                name={name}
                label='Engine Types'
                placeholder='Select engine types'
                value={arrayInputValue(name, formikProps)}
                options={options}
                onChange={updatedValue => {
                    formikProps.setFieldValue(name, updatedValue);
                }}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                disabled={disabled}
            />
        );
    };

export interface EngineTypeInputProps extends FieldProps {
    engineTypes: string[],
}
