import React, { Component, ReactNode } from 'react';
import { ActualizationCandidate } from '../../api/model-service/actualization';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import TablePagination from '../Tables/TablePagination';
import Table from '../Tables/Table';
import { Paper, TableBody, TableFooter, TableRow, Typography } from '@material-ui/core';
import { TableHeader } from './TableHeader';
import { countCandidateModels } from './candidateModels';
import { isEmpty } from 'lodash';
import { CatalogRegion } from '../../api/catalog-service/regionCatalogs';
import { contractCategoryName } from '../../utils/contractCategoryName';
import { UserMail } from '../../api/authenticationService/users';
import { yearMonthLabel } from '../../utils/yearMonthLabel';
import { createDataTestProps } from '../Utils/testProps';

export class ActualizationCandidates extends Component<ActualizationCandidatesProps, ComponentState> {

    private static PAGE_SIZE = 100;
   
    public state = { page: 0 };

    public componentDidUpdate(previousProps): void {
        if (previousProps.candidates.length !== this.props.candidates.length) {
            this.setState({ page: 0 });
        }
    }

    public render(): ReactNode {
        const { candidates, isInitialLoad } = this.props;
        if (isInitialLoad) {
            return null;
        }

        const hasCandidates = !isEmpty(candidates);
        const displayedCandidates = this.createPageSlice(candidates);
        return (
            <Paper elevation={2} square>
                <Table testName='candidates'>
                    <TableHeader/>
                    <TableBody>
                        {!hasCandidates && this.getNoDataRow()}
                        {displayedCandidates.map(candidate =>
                            <TableDataRow testName='candidate' testId={'' + candidate.id} key={candidate.id}>
                                <TableDataCell testName='displayName'>{candidate.displayName}</TableDataCell>
                                <TableDataCell testName='opportunityNumber'>
                                    {candidate.opportunityNumber}
                                </TableDataCell>
                                <TableDataCell testName='contractCategory'>
                                    {contractCategoryName(candidate.contractCategory)}
                                </TableDataCell>
                                <TableDataCell testName='engineType'>{candidate.engineType}</TableDataCell>
                                <TableDataCell testName='regionName'>{this.formatRegion(candidate)}</TableDataCell>
                                <TableDataCell testName='contractManager'>
                                    {this.formatContractManager(candidate)}
                                </TableDataCell>
                                <TableDataCell testName='actualizationPeriod'>
                                    {this.formatActualizationPeriod(candidate)}
                                </TableDataCell>
                            </TableDataRow>)
                        }
                    </TableBody>
                    <TableFooter>
                        {this.getTotalPages() > 1
                            ? <TableRow>
                                <TablePagination
                                    colSpan={3}
                                    rowsPerPageOptions={[]}
                                    count={candidates.length}
                                    page={this.state.page}
                                    rowsPerPage={ActualizationCandidates.PAGE_SIZE}
                                    onChangePage={(e, page) => this.showPage(page)}
                                    SelectProps={{ native: true }}
                                />
                            </TableRow>
                            : null
                        }
                        {hasCandidates && this.getSummaryRow()}
                    </TableFooter>
                </Table>
            </Paper>
        );
    }

    private getNoDataRow(): ReactNode {
        return (
            <TableDataRow testName='noData' testId=''>
                <TableDataCell testName='noData' colSpan={7} align='center'>
                    <Typography variant='body2' {...createDataTestProps('filterNoResult')}>
                        There are no actualization candidates found for the selected search filters
                    </Typography>
                </TableDataCell>
            </TableDataRow>
        );
    }

    private getSummaryRow(): ReactNode {
        const { candidates } = this.props;
        return (
            <TableDataRow testName='summary' testId=''>
                <TableDataCell testName='summary' colSpan={7}>
                    <Typography variant='body2' color='primary'>
                        {candidates.length} configurations from {countCandidateModels(candidates)}{' '}
                        models have been selected
                    </Typography>
                </TableDataCell>
            </TableDataRow>
        );
    }

    private formatRegion(candidate: ActualizationCandidate): string {
        return this.props.regions
            .find(region => region.id === candidate.catalogRegionId)
            .name;
    }

    private formatContractManager(candidate: ActualizationCandidate): string {
        const contractManager = this.props.contractManagers
            .find(manager => manager.username === candidate.contractManager) ||
            { displayName: 'Unknown User'};

        return contractManager.displayName;
    }

    private formatActualizationPeriod(candidate: ActualizationCandidate): string {
        const { actualizationPeriod } = candidate;
        return actualizationPeriod
            ? yearMonthLabel(actualizationPeriod)
            : null;
    }

    private getTotalPages(): number {
        return Math.ceil(this.props.candidates.length / ActualizationCandidates.PAGE_SIZE);
    }

    private createPageSlice(candidates: ActualizationCandidate[]): ActualizationCandidate[] {
        const { page } = this.state;
        const sliceStart = page * ActualizationCandidates.PAGE_SIZE;
        const sliceEnd = (page + 1) * ActualizationCandidates.PAGE_SIZE;
        return candidates.slice(sliceStart, sliceEnd);
    }

    private showPage(page: number): void {
        this.setState({ page });
    }
}

interface ActualizationCandidatesProps {
    candidates: ActualizationCandidate[],
    regions: CatalogRegion[],
    contractManagers: UserMail[],
    isInitialLoad: boolean,
}

interface ComponentState {
    page: number,
}
