import React, { FC } from 'react';
import { MyPlantProduct as MyPlantProductType } from '../../api/model-service/model-service';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { FieldProps } from './FieldProps';

export const MyPlantProduct: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.myPlantProduct';
        const errorMessage = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='MyPlant Product'
                value={inputValue(name, formikProps)}
                disabled={disabled}
                options={options}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='dense'
                fullWidth
                required
            />
        );
    };

const options: Array<IFormOption<MyPlantProductType>> = [
    formOption('NOT_INCLUDED', 'Not Included'),
    formOption('BASIC', 'Basic'),
    formOption('CARE', 'Care'),
    formOption('PROFESSIONAL', 'Professional'),
    formOption('CONNECT', 'Connect'),
    formOption('PROTECT', 'Protect'),
];
