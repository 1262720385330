import * as React from 'react';
import './ModelsTable.scss';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../Tables/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeaderCell from '@material-ui/core/TableCell';
import TableDataCell from '../../Tables/TableDataCell';
import TableHead from '@material-ui/core/TableHead';
import TableHeaderRow from '@material-ui/core/TableRow';
import TableDataRow from '../../Tables/TableDataRow';
import TablePagination from '../../Tables/TablePagination';
import Typography from '@material-ui/core/Typography';

import ModelIcon from '@material-ui/icons/FolderOutlined';
import NoIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import { TableFooter } from '@material-ui/core';
import { fromServiceDateTime } from '../../../data/utils/date';
import {
    modelProgressIcon,
    modelVersionInputs,
} from '../../../data/model/model';
import { find } from 'lodash';

const decorateWithStyles = withStyles(theme => ({
    head: {
        backgroundColor: '#f7f7f7',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 12,
    },
}));

const StyledTableHeaderCell = decorateWithStyles(TableHeaderCell);
const StyledTableDataCell = decorateWithStyles(TableDataCell);
const TableFooterRow = TableHeaderRow;

const styles = (theme: any) => ({
    tableRoot: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    modelNameCell: {
        padding: 30,
        fontSize: 16,
        fontWeight: 500,
        paddingLeft: 60,
    },
    newRow: {
        backgroundColor: '#f7f7f7',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    smallIcon: {
        position: 'relative',
        fontSize: 18,
        top: 3,
        left: -8,
        marginLeft: -20,
    },
    noContentRoot: {
        padding: theme.spacing.unit * 15,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const LatestModelsTable = (props: any) => {
    const { classes } = props;

    //consider empty states. if row=num, then, if row=0, show empty state
    const rows = props.modelList && props.modelList.content && props.modelList.content.length
            ? props.modelList.content
            : [];

    if (!rows.length) {
        return (
            <React.Fragment>
                <div className={classes.noContentRoot}>
                    <Typography
                        variant="h5"
                        align="center"
                        color="textSecondary"
                        gutterBottom
                    >
                        No Models Found.
                    </Typography>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className={classes.tableRoot}>
                <Table testName="models" className={classes.table}>
                    <TableHead>
                        <TableHeaderRow>
                            <StyledTableHeaderCell>Project Name</StyledTableHeaderCell>
                            <StyledTableHeaderCell>Customer</StyledTableHeaderCell>
                            <StyledTableHeaderCell>Updated</StyledTableHeaderCell>
                            <StyledTableHeaderCell>Status</StyledTableHeaderCell>
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => {
                            const version = find(modelVersionInputs, {
                                value: row.version,
                            }) || { label: 'none' };
                            return (
                                <TableDataRow testName="model" testId={row.opportunityNumber}>
                                    <StyledTableDataCell
                                        testName="projectName"
                                        className={classes.modelNameCell}
                                        component="th"
                                        scope="row"
                                    >
                                        <ModelIcon
                                            color="disabled"
                                            className={props.classes.smallIcon}
                                        />
                                        <Link
                                            to={{
                                                pathname: '/models/' + row.id,
                                            }}
                                        >
                                            {row.projectName}
                                        </Link>
                                    </StyledTableDataCell>
                                    <StyledTableDataCell testName="customerName">
                                        {row.customerName}
                                    </StyledTableDataCell>
                                    <StyledTableDataCell testName="modifiedOn">
                                        {fromServiceDateTime(
                                            row.modifiedOn
                                        ).toLocaleDateString()}
                                        <Tooltip
                                            title={row.modifiedBy}
                                            placement="top-start"
                                        >
                                            <span>&nbsp;*</span>
                                        </Tooltip>
                                    </StyledTableDataCell>
                                    <StyledTableDataCell testName="version">
                                        {version.label}
                                    </StyledTableDataCell>
                                </TableDataRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        {props.modelList && props.modelList.totalPages > 1 ? (
                            <TableFooterRow>
                                <TablePagination
                                    rowsPerPageOptions={[25, 50, 100]}
                                    colSpan={3}
                                    count={props.modelList.totalElements}
                                    page={props.modelList.number}
                                    rowsPerPage={props.modelList.size}
                                    onChangePage={props.goto}
                                    onChangeRowsPerPage={props.changePageLength}
                                    SelectProps={{ native: true }}
                                />
                            </TableFooterRow>
                        ) : (
                            ''
                        )}
                    </TableFooter>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default withStyles(styles as any)(LatestModelsTable);
