import React, { FC } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { Button } from '../Buttons/Button';
import { ActualizationFormButtonsProps } from './ActualizationFormButtons';

export const Proceed: FC<ActualizationFormButtonsProps & StyledComponentProps> =
    props => 
        <Button
            testName='proceed'
            variant='contained'
            color='primary'
            disabled={props.loading || props.disabled}
            onClick={props.onProceed}
            className={props.classes.button}
        >
            Proceed
        </Button>;
