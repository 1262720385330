import React from 'react';
import MaterialCheckbox, { CheckboxProps as MaterialCheckboxProps } from '@material-ui/core/Checkbox';
import { createInputTestProps } from '../Utils/testProps';

export interface ICheckboxProps extends MaterialCheckboxProps {
    testName: string,
    testId?: string,
}

export const Checkbox: React.FunctionComponent<ICheckboxProps> =
    ({ testName, testId, inputProps, ...remainingCheckboxProps }) => {
        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(testName, testId),
        }

        return (
            <MaterialCheckbox
                inputProps={fullInputProps}
                {...remainingCheckboxProps}
            />
        );
    };

export default Checkbox;
