import * as React from 'react';
import './View.scss';

import Branding from './Branding/Branding';
import NavigationList from './NavigationList/NavigationList';
import UserMenu from './UserMenu/UserMenu';
import Footer from '../Footer/Footer';
import { withStyles } from '@material-ui/core/styles';
import { createPageTestProps } from '../Utils/testProps';

const styles = (theme: any) => ({
    header: {
        padding: '0px 0px 33px 0',
    },
});

const View = (props: any) => {
    const { testName, testId } = props;
    const testProps = createPageTestProps(testName, testId);

    return (
        <div className="wrap" {...testProps}>
            <div className="left">
                <Branding />
                <NavigationList />
                <UserMenu />
            </div>
            <div className="right">
                <div className="rightRoot">{props.children}</div>
                <Footer />
            </div>
        </div>
    );
};

export default withStyles(styles)(View);
