import React, { cloneElement } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';
import { InputAdornment } from '@material-ui/core';
import { withVariablePriceEscalation } from './withEscalationType';

export const PriceEscalationUpperLimit = withVariablePriceEscalation<FieldProps>(
    ({ formikProps, container }) => {
        const name = 'priceEscalationUpperLimit';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Upper Limit'
                    type='number'
                    placeholder='0.0 - 10.0'
                    value={inputValue(name, formikProps)}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    inputProps={{
                        min: '0',
                        max: '10',
                    }}
                />,
        });
    },
);
