import React, { FC } from 'react';
import { CustomerObligationsFormFactoryProps } from './CustomerObligationsProps';
import { getMsaCustomerObligations } from './CustomerObligationsInterfaces';
import { CustomerObligationsForm } from './CustomerObligationsForm';

export const MsaCustomerObligationsFactory: FC<CustomerObligationsFormFactoryProps> =
    props => {
        const obligations = getMsaCustomerObligations();
        return <CustomerObligationsForm obligations={obligations}
            {...props}
        />
    }
        
;