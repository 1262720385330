import { array, date, mixed, number, object, ObjectSchema, string } from '../../utils/validators';
import { BillingInterval, Configuration, Escalation, Payment } from '../../api/model-service/model-service';
import { integerMessage, rangeMessage, requiredMessage } from '../../utils/validationMessages';

export function getPaymentSchema(): ObjectSchema<Partial<Configuration>> {
    const paymentSchema: ObjectSchema<Payment> = object({
        id: number(),
        billingCurrency: string().required(requiredMessage),
        billingCurrencyFx: mixed().when('billingCurrency', {
            is: 'EUR',
            then: null,
            otherwise: number().required(requiredMessage),
        }),
        billingInterval: mixed<BillingInterval>()
            .required(requiredMessage)
            .oneOf([ 'MONTHLY', 'QUARTERLY', 'CALENDAR_YEAR', 'ANNUALLY', 'PACKAGES' ]),
        annualBillingStartDate: mixed().when('billingInterval', {
            is: 'ANNUALLY',
            then: date().required(requiredMessage),
            otherwise: date(),
        }),
        paymentTerm: number().required(requiredMessage).integer(integerMessage),
        minimumOperatingHoursPerYear: number()
            .required(requiredMessage)
            .integer(integerMessage)
            .min(0, rangeMessage(0, 8760))
            .max(8760, rangeMessage(0, 8760)),
        minimumOperatingHoursPerYearViolationAgreement: string(),
        extraWorkDiscountMaterial: number()
            .required(requiredMessage)
            .min(0, rangeMessage(0, 99))
            .max(99, rangeMessage(0, 99)),
        extraWorkDiscountLabor: number()
            .required(requiredMessage)
            .min(0, rangeMessage(0, 99))
            .max(99, rangeMessage(0, 99)),
        otherSpecialPayments: string()
            .max(500, 'Field must be between 0 and 500 characters long')
            .trim(),
        escalations: array<Escalation>()
            .required(requiredMessage)
            .min(1, 'At least one escalation must be defined'),
    });

    return object({
        payment: paymentSchema,
    });
}
