import { FormikProps } from 'formik';
import { isNil, path } from 'lodash/fp';

export function inputValue(valuePath: string, formikProps: FormikProps<object>): string {
    const value: any = path(valuePath, formikProps.values);
    if (isNil(value)) {
        return '';
    }

    if (value === true) {
        return 'yes';
    }

    if (value === false) {
        return 'no';
    }

    return '' + value;
}
