import React, { Component, ReactNode } from 'react';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { Region } from './Region';
import { uniq } from 'lodash';
import { Form } from '../Form/Form';
import { SubmitButton } from '../Form/SubmitButton';
import { CatalogRegion } from '../../api/catalog-service/regionCatalogs';
import { actualizationFilterSchema } from './schema';
import { ContractCategory } from '../../api/model-service/model-service';
import { ContractCategoryInput } from './ContractCategoryInput';
import { EngineType } from './EngineType';
import { ContractManagers } from './ContractManagers';
import { ActualizationPeriods } from './ActualizationPeriods';
import { OpportunityNumbers } from './OpportunityNumbers';
import { UserMail } from '../../api/authenticationService/users';

class ActualizationFilter0 extends Component<ActualizationFilterProps & StyledComponentProps> {

    public render(): ReactNode {
        const regions: string[] = this.getRegionNames();
        const initialValues: ActualizationCandidateFilter = {
            region: null,
            contractCategory: null,
            opportunityNumbers: [],
            engineTypes: [],
            contractManagers: [],
            actualizationPeriods: [],
        };
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={actualizationFilterSchema}
                onSubmit={(filter, actions) => this.handleSubmit(filter, actions)}
                enableReinitialize
            >
                {(formikProps: FormikProps<ActualizationCandidateFilter>) => {
                    const disabled = this.props.loading || formikProps.isSubmitting;
                    const filterOptions = this.props.filterOptions;

                    return (
                        <Form testName='actualizationFilter' noValidate onSubmit={formikProps.handleSubmit}>
                            <Grid container spacing={24}>
                                <Grid container spacing={24} item xs={12} md={10}>
                                    <Grid item xs={12} md={6}>
                                        <Region disabled={disabled} regions={regions} formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ContractCategoryInput
                                            disabled={disabled}
                                            contractCategories={filterOptions.contractCategories}
                                            formikProps={formikProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <OpportunityNumbers disabled={disabled} formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <EngineType
                                            disabled={disabled}
                                            engineTypes={filterOptions.engineTypes}
                                            formikProps={formikProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ContractManagers
                                            disabled={disabled}
                                            contractManagers={filterOptions.contractManagers}
                                            formikProps={formikProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ActualizationPeriods
                                            disabled={disabled}
                                            actualizationPeriods={filterOptions.actualizationPeriods}
                                            formikProps={formikProps}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <SubmitButton
                                        testName='search'
                                        variant='contained'
                                        color='secondary'
                                        className={this.props.classes.submitBtn}
                                        disabled={disabled}
                                    >
                                        Search
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    private getRegionNames(): string[] {
        const names = this.props.filterOptions.catalogRegions.map(region => region.name);
        return uniq(names);
    }

    private handleSubmit(filter: ActualizationCandidateFilter,
                         actions: FormikActions<ActualizationCandidateFilter>): void {

        this.props.onSearch(actualizationFilterSchema.cast(filter))
            .finally(() => {
                actions.setSubmitting(false);
            });
    }
}

export interface ActualizationFilterProps {
    loading: boolean,
    filterOptions: CandidateFilterOptions,
    onSearch: (filter: ActualizationCandidateFilter) => Promise<void>,
}

export interface CandidateFilterOptions {
    contractCategories: ContractCategory[],
    engineTypes: string[],
    catalogRegions: CatalogRegion[],
    contractManagers: UserMail[],
    actualizationPeriods: string[],
}

export interface ActualizationCandidateFilter {
    // TODO rename to regionName
    region: string,
    contractCategory: ContractCategory,
    opportunityNumbers: string[],
    engineTypes: string[],
    contractManagers: string[],
    actualizationPeriods: string[],
}

const styles: StyleRules = {
    submitBtn: {
        marginTop: '17px',
        marginBottom: '4px',
    },
};

export const ActualizationFilter = withStyles(styles)(ActualizationFilter0);
