import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './EditPricingConfig.scss';

import Paper from '@material-ui/core/Paper';
import Button from '../../../Buttons/Button';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import ValidatorForm from '../../../Form/ValidatorForm';
import { ErrorType } from '../../../../data/actions/error';
import { FormEditAuthorizationProvider } from '../../../Form/formAuthorizationContext';
import { AuthorizedToEditForm } from '../../../Form/AuthorizedToEditForm';
import { createPricingFormSectionFactory } from '../../../PricingFormSection/abstract-factory';
import { LinearProgress } from '@material-ui/core';

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 33,
        paddingBottom: 0,
        paddingRight: 0,
        marginBottom: '33px',
    },
    child: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 20,
        paddingBottom: 50,
        marginBottom: '33px',
        marginTop: '33px',
    },
    panelRoot: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '15%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    progressBar: {
        marginTop: theme.spacing.unit,
    },
});

function getSteps() {
    return ['Pricing & Contribution Margin (CM)', 'LD’s & Bonus', 'Caps'];
}

interface IState {
    expanded:string,
    activeStep:number,
    loading: boolean,
}

class EditPricingConfig extends React.Component<any, IState> {
    public state = {
        expanded: 'panel1',
        activeStep: 0,
        loading: false,
    };

    private form = [];

    public constructor(props) {
        super(props);

        for (const step of getSteps()) {
            this.form.push(React.createRef<typeof ValidatorForm>());
        }
    }

    public getStepContent(step: number): React.ReactElement {
        const {
            configuration,
            mergeConfig,
            updateTargetPricing,
            pricingTarget,
        } = this.props;

        const factory = createPricingFormSectionFactory(configuration);
        const pricing = configuration.pricing || {};

        switch (step) {
            case 0:
                // Pricing & Contribution Margin (CM)
                return (
                    <React.Fragment>
                        <ValidatorForm
                            testName="pricingAndContributionMargin"
                            ref={this.form[step]}
                            instantValidate
                            onSubmit={() => {
                                // empty
                            }}
                            autoComplete="off"
                        >
                            <factory.PricingContributionMargin
                                validate={() => this.checkFormValid()}
                                pricing={pricing}
                                mergeConfig={mergeConfig}
                                updateTargetPricing={updateTargetPricing}
                                pricingTarget={pricingTarget}
                                configuration={configuration}
                            />
                        </ValidatorForm>
                    </React.Fragment>
                );
            case 1:
                // LD’s & Bonus
                return (
                    <React.Fragment>
                        <ValidatorForm
                            testName="ldsAndBonus"
                            ref={this.form[step]}
                            instantValidate
                            onSubmit={() => {
                                // empty
                            }}
                            autoComplete="off"
                        >
                            <factory.LdBonus
                                validate={() => this.checkFormValid()}
                                pricing={pricing}
                                mergeConfig={mergeConfig}
                            />
                        </ValidatorForm>
                    </React.Fragment>
                );
            case 2:
                // Caps
                return (
                    <React.Fragment>
                        <ValidatorForm
                            testName="caps"
                            ref={this.form[step]}
                            instantValidate
                            onSubmit={() => {
                                // empty
                            }}
                            autoComplete="off"
                        >
                            <factory.Caps
                                validate={() => this.checkFormValid()}
                                pricing={pricing}
                                mergeConfig={mergeConfig}
                            />
                        </ValidatorForm>
                    </React.Fragment>
                );
            default:
                return <React.Fragment>Unknown step</React.Fragment>;
        }
    }

    public checkFormValid(): Promise<boolean> {
        return this.form[this.state.activeStep].current
            .isFormValid(false);
    }

    public setStep(step): void {
        this.setState({
            activeStep: step,
        });
    }

    public handleNext(authorizedToSave: boolean): void {
        this.setState(
            state => ({
                activeStep: state.activeStep + 1,
            }),
            () => {
                const isDoneEditing = this.state.activeStep === getSteps().length;
                if (!isDoneEditing) {
                    return;
                }
                this.saveAndClose(authorizedToSave);
            }
        );
    }

    public saveAndClose(authorizedToSave: boolean):void {
        if (authorizedToSave) {
            this.handleSave().then(() => this.handleClose());
        } else {
            this.handleClose();
        }
    }

    private handleSave(): Promise<void> {
        this.setState({ loading: true });

        return this.props.saveCompleted(this.props.configuration)
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    private handleClose(): void {
        this.props.handleClose();
        this.setState({ activeStep: 0 });
    }

    public handleBack(): void {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    }

    public render(): React.ReactElement {
        const { classes, authorizedToEdit } = this.props;
        const steps = getSteps();
        const { activeStep, loading } = this.state;
        const isLastStep = activeStep === steps.length - 1;

        const nextBtnTestName = this.getNextBtnTestName(isLastStep);
        const nextBtnLabel = this.getNextBtnLabel(isLastStep);

        return (
            <FormEditAuthorizationProvider authorizedToEdit={authorizedToEdit}>
                <Paper square classes={{ root: classes.child }} elevation={5}>
                    <Typography variant="h6">Configure Pricing...</Typography>
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        orientation="vertical"
                    >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel onClick={() => this.setStep(index)}>
                                    {label}
                                </StepLabel>
                                <StepContent>
                                    <React.Fragment>
                                        {this.getStepContent(index)}
                                    </React.Fragment>
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                testName="back"
                                                disabled={activeStep === 0 || loading}
                                                onClick={() =>
                                                    this.handleBack()
                                                }
                                                className={classes.button}
                                            >
                                                Back
                                            </Button>
                                            <AuthorizedToEditForm>
                                                <Button
                                                    testName="save"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={loading}
                                                    onClick={() => {
                                                        this.checkFormValid().then(isValid => {
                                                            if (isValid) {
                                                                this.handleSave();
                                                            } else {
                                                                this.props.sendMessage(
                                                                    `There was an error saving the form, please review the inputs.`,
                                                                    ErrorType.REQUEST
                                                                );
                                                            }
                                                        });
                                                    }}
                                                    className={classes.button}
                                                >
                                                    Save
                                                </Button>
                                            </AuthorizedToEditForm>
                                            &nbsp;

                                            <Button
                                                testName={nextBtnTestName}
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={() => {
                                                    this.checkFormValid().then(isValid => {
                                                        if (isValid) {
                                                            this.handleNext(authorizedToEdit);
                                                        } else {
                                                            this.props.sendMessage(
                                                                `There was an error saving the form, please review the inputs.`,
                                                                ErrorType.REQUEST
                                                            );
                                                        }
                                                    });
                                                }}
                                                className={classes.button}
                                            >
                                                {nextBtnLabel}
                                            </Button>

                                            <AuthorizedToEditForm>
                                                &nbsp;
                                                {this.hasPricing() && (activeStep + 1 !== getSteps().length) &&
                                                    <Button
                                                        testName={'calculateBillingsAndCM'}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={loading}
                                                        onClick={() => {
                                                            this.checkFormValid().then(isValid => {
                                                                if (isValid) {
                                                                    this.saveAndClose(authorizedToEdit);
                                                                } else {
                                                                    this.props.sendMessage(
                                                                        `There was an error saving the form, please review the inputs.`,
                                                                        ErrorType.REQUEST
                                                                    );
                                                                }
                                                            });
                                                        }}
                                                        className={classes.button}
                                                    >
                                                        Calculate Billings and CM
                                                    </Button>
                                                }
                                            </AuthorizedToEditForm>
                                        </div>
                                        {loading && <LinearProgress className={classes.progressBar}/>}
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
            </FormEditAuthorizationProvider>
        );
    }

    private hasPricing(): boolean {
        return this.props.configuration.pricing;
    }

    private getNextBtnTestName(isLastStep: boolean): string {
        if (!isLastStep) {
            return 'next';
        }

        if (this.props.authorizedToEdit) {
            return 'calculateBillingsAndCM';
        } else {
            return 'close';
        }
    }

    private getNextBtnLabel(isLastStep: boolean): string {
        if (!isLastStep) {
            return 'Next';
        }

        if (this.props.authorizedToEdit) {
            return 'Calculate Billings and CM';
        } else {
            return 'Close';
        }
    }
}

export default withStyles(styles)(EditPricingConfig);
