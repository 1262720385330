export function createURLSearchParams(params: object): URLSearchParams {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach(name => {
        const value = params[name];

        if (!Array.isArray(value)) {
            searchParams.append(name, value);
            return;
        }

        value.forEach(valueItem => {
            searchParams.append(name, valueItem);
        });
    });

    return searchParams;
}
