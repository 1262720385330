import { ContractCategory } from './model-service';
import { fetch } from '../fetch';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';
import { createURLSearchParams } from '../utils/url-search-params';

declare const MODEL_ROOT;

export function getCandidateFilters(): Promise<ActualizationApiFilterOptions> {
    const url = `${MODEL_ROOT}/actualizations/filters`;
    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface ActualizationApiFilterOptions {
    contractCategories: ContractCategory[],
    catalogRegionIds: number[],
    engineTypes: string[],
    contractManagers: string[],
    actualizationPeriods: string[],
}

export function getActualizationCandidates(request: GetActualizationCandidatesRequest):
    Promise<ActualizationCandidate[]> {

    const url = new URL(`${MODEL_ROOT}/actualizations/candidates`);
    const query = createURLSearchParams(request);
    url.search = query.toString();

    return fetch(url.toString())
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export function createActualization(request: ActualizationPayload):
    Promise<Actualization> {

    const url = new URL(`${MODEL_ROOT}/actualizations`);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    };

    return fetch(url.toString(), requestOptions)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}


export function putValidations(request: ValidationsRequest):
    Promise<Actualization> {

    const url = new URL(`${MODEL_ROOT}/actualizations/validations`);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    };

    return fetch(url.toString(), requestOptions)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export function getActualizationOverviews():
    Promise<ActualizationOverview[]> {

    const url = new URL(`${MODEL_ROOT}/actualizations`);

    return fetch(url.toString())
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export function getActualization(id: number): Promise<Actualization> {
    const url = `${MODEL_ROOT}/actualizations/${id}`;
    return fetch(url)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetActualizationCandidatesRequest {
    catalogRegionIds: number[],
    contractCategory: ContractCategory,
    opportunityNumbers?: string[],
    engineTypes?: string[],
    contractManagers?: string[],
    actualizationPeriods?: string[],
}

export interface ValidationsRequest {
    id: number,
    candidates: ActualizationCandidate[],
    action: ValidationRequestAction,
}

export type ValidationRequestAction = 'MAKE_PRIMARY' | 'DISCARD';

export interface ActualizationCandidate {
    id: number,
    modelId: number,
    lockVersion: number,
    displayName: string,
    opportunityNumber: string,
    contractCategory: ContractCategory,
    engineType: string,
    catalogRegionId: number,
    activityCatalogId: number,
    contractManager: string,
    actualizationPeriod: string,
    projectName: string,
    equipmentModel: string,
    expectedOperatingHoursPerYear: number,
    cost: number,
    billings: number,
    oracleContractsNumber: string,
}

export interface ActualizationPayload {
    candidates: ActualizationCandidate[],
    actualizationPeriod: string,
    escalationRate: number,
    activityCatalogId: number,
    regionCatalogId: number,    
}

export interface Actualization {
    id?: number,
    lockVersion?: number,
    actualizationPeriod?: string,
    escalationRate?: number,
    activityCatalogId?: number,
    regionCatalogId?: number,
    createdOn?: string,
    createdBy?: string,
    results: ActualizationResult[],
    comparisonFinished: boolean,
}

export interface ActualizationOverview {
    id: number,
    createdBy: string,
    createdOn: string,
}

export interface ActualizationResult {
    id?: number,
    lockVersion?: number,
    state: ActualizationResultState,
    errors: string[],
    clone: ActualizationCandidate,
    candidate: ActualizationCandidate,
    ok: boolean,
    delta: ActualizationResultDelta,
    comparisonFinished: boolean,
}

export interface ActualizationResultDelta {
    cost: number,
    costPercentage: number,
    expectedOperatingHoursPerYear: number,
    billings: number,
    billingsPercentage: number,
}

export type ActualizationResultState =  'PENDING' | 'MADE_PRIMARY' | 'DISCARDED';
