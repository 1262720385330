import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { getEquipmentCatalogs, GetEquipmentCatalogsRequest } from '../../api/catalog-service/equipmentCatalogs';
import { reportCatalogError } from '../error-actions';

export const getDefaultEquipmentCatalog = (): AppThunk => dispatch => {
    const request: GetEquipmentCatalogsRequest = {
        state: [ 'DEFAULT' ],
    };

    dispatch(startLoading());
    return getEquipmentCatalogs(request)
        .then(response => {
            if (response.length !== 1) {
                throw new Error('Could not find default equipment catalog');
            }

            dispatch(successResponse(response[0]))
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportCatalogError(err));
            throw err;
        })
};
