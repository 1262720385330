import React, { FC } from 'react';
import { StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { IconButton } from '../Buttons/IconButton';

const Caret0: FC<ComponentProps & StyledComponentProps> = props => (
    <IconButton
        testName='expand'
        className={props.classes.chevron}
        disabled={props.disabled}
        onClick={props.onClick}
    >
        <ChevronRight style={props.isExpanded ? expanded : collapsed}/>
    </IconButton>
);

const styles: StyleRulesCallback = () => ({
    chevron: {
        padding: 0,
        marginLeft: '2px',
    },
});

const expanded = {
    transition: 'all, 200ms ease 0s',
    transform: 'rotate(90deg)',
};

const collapsed = {
    transition: 'all, 200ms ease 0s',
    transform: 'rotate(0deg)',
};

interface ComponentProps {
    isExpanded?: boolean,
    disabled?: boolean,
    onClick: () => void,
}

export const Caret = withStyles(styles)(Caret0);
