import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { getActivityCatalogs, GetActivityCatalogsRequest } from '../../api/catalog-service/activityCatalogs';
import { reportCatalogError } from '../error-actions';

export const getActiveActivityCatalogs = (): AppThunk => dispatch => {
    const request: GetActivityCatalogsRequest = {
        state: [ 'ACTIVE', 'DEFAULT' ],
    };

    dispatch(startLoading());
    return getActivityCatalogs(request)
        .then(response => {
            dispatch(successResponse(response))
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportCatalogError(err));
            throw err;
        })
};
