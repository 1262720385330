import * as React from 'react';
import './Configuration.scss';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '../Buttons/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '../Tabs/Tab';
import Tooltip from '@material-ui/core/Tooltip';

import DataHighlight from '../DataHighlight/DataHighlight';
import BillOfServices from './BillOfServices/BillOfServices';
import {
    getOne,
    mergeStep,
    saveConfiguration,
    updateTargetPricing,
} from '../../data/actions/configurations';
import {
    canChangePrimaryConfiguration,
    getModelDetails,
    setPrimaryConfigurations,
} from '../../data/actions/models';
import { connect } from 'react-redux';
import ActivitySchedule from './ActivitySchedule/ActivitySchedule';
import Financials from './Financials/Financials';

import FlagOutlineIcon from '@material-ui/icons/FlagOutlined';
import FlagIcon from '@material-ui/icons/Flag';
import EditIcon from '@material-ui/icons/Edit';
import ModelIcon from '@material-ui/icons/FolderOutlined';
import { formatCurrency, formatNumber } from '../../data/utils/number';
import { defaultsDeep, find } from 'lodash';
import {
    IConfiguration,
    IConfigurationView, IUnit,
} from '../../data/model/configuration';
import ValidatorForm from '../Form/ValidatorForm';
import { IFormModel } from '../../data/model/model';
import { hasValue, iv } from '../../data/utils/form';
import { FFInput } from '../Form/input';
import { CaseAuthorizedToUpdateModel } from '../authorization/CaseAuthorizedToUpdateModel';
import { isoToLocaleDate } from '../../data/utils/date';
import { setMessage } from '../../data/actions/error';
import { ReferencePrice } from '../../data/model/configuration_pricing';
import {createDataTestProps} from '../Utils/testProps';

const styles = (theme: any) => ({
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 25,
        paddingBottom: 50,
        marginBottom: '33px',
    },
    configPaper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 25,
        paddingBottom: 50,
        marginBottom: 33,
        marginTop: 20,
    },
    takeAways: {
        margin: '10px 0 18px 0',
    },
    caption: {
        color: '#7d7d7d',
        fontSize: 11,
        lineHeight: 'normal',
        marginBottom: 3,
    },
    smallIcon: {
        fontSize: 25,
        top: 2,
        marginRight: 10,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -25,
        marginRight: -25,
    },
    statusChip: {
        margin: theme.spacing.unit,
        marginLeft: 'auto',
    },
    flagIcon: {
        fontSize: 28,
        top: 5,
        marginRight: 5,
        marginLeft: 5,
    },
});

function TabContainer(props: any) {
    return (
        <Typography component="div" style={{ padding: '25px 5px' }}>
            {props.children}
        </Typography>
    );
}

const TAB_MAP = {
    financials: 2,
    as: 1,
    bos: 0,
};

class Configuration extends React.Component<any, any> {
    public state = {
        tabValue: 0,
        currentUnit: 0,
        loading: true,
    };

    public changeUnit(event): void {
        this.setState({ currentUnit: event.target.value });
    }

    public handleTabChange(event: any, tabValue: any): void {
        this.setState({ tabValue });
    }

    public showLoading(): void {
        this.setState({ loading: true });
    }

    public componentWillMount(): void {
        let tabValue = 0;

        if (this.props.match.params.tabID) {
            tabValue = TAB_MAP[this.props.match.params.tabID];
            if (!tabValue) tabValue = 0;
        }

        this.setState({
            tabValue,
        });
    }

    public componentDidMount(): void {
        this.props.getConfiguration(this.props.match.params.configurationID);
        this.props.populateModel(this.props.match.params.modelID);
    }

    public formChange(name: string, step, rawValue?, autoUpdate?): void {
        const { configuration, updateStep, match } = this.props;
        const path = name.split('.');
        const value = rawValue === true ? step : step.target.value;

        let update = {};

        if (name === 'pricing.referencePriceList' && 
            value !== ReferencePrice.Dnet) {
            update = {
                pricing: {
                    referencePriceList: value,
                    discountMatrix: null
                }       
            }

            updateStep(configuration, update, autoUpdate, match.params.modelID);
            return;
        }

        if (path.length === 3) {
            update = { [path[0]]: { [path[1]]: { [path[2]]: value } } };
        } else if (path.length === 2) {
            update = { [path[0]]: { [path[1]]: value } };
        } else {
            update = { [name]: value };
        }


        updateStep(configuration, update, autoUpdate, match.params.modelID);
    }

    public render(): React.ReactElement {
        const {
            classes,
            model,
            configuration,
            match,
            sendMessage,
            saveCompleted,
            updateTarget,
            pricingTarget,
        } = this.props;
        const { tabValue, currentUnit } = this.state;

        return (
            <React.Fragment>
                <Paper square classes={{ root: classes.paper }}>
                    <Link to={`/models/${match.params.modelID}`}>
                        <h2 className="modelName">
                            <ModelIcon
                                color="disabled"
                                className={this.props.classes.smallIcon}
                            />
                            {`${model.projectName}`}
                        </h2>
                    </Link>
                    {model.financialPerformance &&
                    model.financialPerformance.totalModelCost ? (
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={16}
                        >
                            <DataHighlight
                                testName="totalModelCost"
                                labelText="Total Model Cost"
                                price
                                value={formatCurrency(
                                    'EUR',
                                    model.financialPerformance.totalModelCost
                                )}
                            />
                            <DataHighlight
                                testName="totalModelBillingsConsidLDB"
                                labelText="Total Model Billings cons. LD/B"
                                price
                                value={formatCurrency(
                                    'EUR',
                                    model.financialPerformance
                                        .totalModelBillingsConsidLDB
                                )}
                            />
                            <DataHighlight
                                testName="totalModelCMConsidLDB"
                                labelText="Total Model CM cons. LD/B"
                                price
                                value={formatCurrency(
                                    'EUR',
                                    model.financialPerformance
                                        .totalModelCMConsidLDB
                                )}
                            />
                            <DataHighlight
                                testName="totalModelCMPercentage"
                                labelText="Total Model CM% cons. LD/B"
                                price
                                value={
                                    formatNumber(
                                        model.financialPerformance
                                            .totalModelCMPercentage * 100,
                                        0,
                                        2
                                    ) + '%'
                                }
                            />
                        </Grid>
                    ) : (
                        ''
                    )}

                    <Paper
                        square
                        classes={{ root: classes.configPaper }}
                        elevation={5}
                    >
                        <Typography variant="overline" color="secondary">
                            Configuration
                        </Typography>
                        <h2 className="modelName" {...createDataTestProps('configurationName')}>
                            {configuration.displayName}
                            <CaseAuthorizedToUpdateModel model={model}>
                                {
                                    authorizedToEdit =>
                                        <React.Fragment>
                                            {this.getPrimaryConfigurationToggle(authorizedToEdit)}
                                            {this.getEditOrViewButton(authorizedToEdit)}
                                        </React.Fragment>
                                }
                            </CaseAuthorizedToUpdateModel>
                        </h2>
                        <Typography variant="body1" gutterBottom>
                            {configuration.description}
                        </Typography>

                        <Divider classes={{ root: classes.divider }} />

                        <Grid
                            className={classes.takeAways}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={16}
                        >
                            {
                                <DataHighlight
                                    testName="totalConfigurationCost"
                                    labelText="Total Configuration Cost"
                                    price
                                    value={formatCurrency(
                                        'EUR',
                                        configuration.costBreakdown
                                            ? configuration.costBreakdown
                                                  .totalConfigurationCost
                                            : 0
                                    )}
                                />
                            }
                            <DataHighlight
                                testName="numberOfUnits"
                                labelText="Number of Units"
                                price
                                value={formatNumber(
                                    configuration.units
                                        ? configuration.units.length
                                        : 0,
                                    0,
                                    0
                                )}
                            />
                            <DataHighlight
                                testName="catalogReference"
                                labelText="Catalog Reference"
                                price
                                value={
                                    configuration.engineInformation
                                        ? configuration.engineInformation
                                              .catalogReference
                                        : ''
                                }
                            />
                            {configuration.units.length > 0 && (
                                <Grid item xs={12} sm={12}>
                                    <ValidatorForm
                                        testName="unitsToDisplay"
                                        onSubmit={() => false}
                                        autoComplete="off"
                                    >
                                        <FFInput
                                            select
                                            options={configuration.units.map(
                                                (unit, index) =>
                                                    iv(
                                                        index,
                                                        `${index + 1}: ${
                                                            unit.serialNumber
                                                        }`
                                                    )
                                            )}
                                            id={`units`}
                                            label={`Unit To Display`}
                                            name="units"
                                            onChange={e => this.changeUnit(e)}
                                            value={currentUnit}
                                            type="text"
                                            margin="dense"
                                        />
                                    </ValidatorForm>
                                </Grid>
                            )}
                            {configuration.units[currentUnit] !== undefined && (
                                <Grid item container spacing={16}>
                                    <DataHighlight
                                        testName="unitStartCounter"
                                        labelText="Unit Start (oph)"
                                        price
                                        value={formatNumber(
                                            (configuration.units[currentUnit] &&
                                                configuration.units[currentUnit]
                                                    .unitStartCounter) ||
                                                0,
                                            0,
                                            0
                                        )}
                                    />
                                    <DataHighlight
                                        testName="unitEndCounter"
                                        labelText="Unit End (oph)"
                                        price
                                        value={formatNumber(
                                            (configuration.units[currentUnit] &&
                                                configuration.units[currentUnit]
                                                    .unitEndCounter) ||
                                                0,
                                            0,
                                            0
                                        )}
                                    />
                                    <DataHighlight
                                        testName="expectedOperatingHoursPerYear"
                                        labelText="Expected Ophs/a"
                                        price
                                        value={formatNumber(
                                            (configuration.units[currentUnit] &&
                                                configuration.units[currentUnit]
                                                    .expectedOperatingHoursPerYear) ||
                                                0,
                                            0,
                                            0
                                        )}
                                    />
                                    <DataHighlight
                                        testName="expectedStartsPerYear"
                                        labelText="Expected Starts/a"
                                        price
                                        value={formatNumber(
                                            (configuration.units[currentUnit] &&
                                                configuration.units[currentUnit]
                                                    .expectedStartsPerYear) ||
                                                0,
                                            0,
                                            0
                                        )}
                                    />
                                    {this.getUnitReadCounterFields(configuration.units[currentUnit])}
                                </Grid>
                            )}
                        </Grid>

                        <Tabs
                            value={this.state.tabValue}
                            onChange={(event, tab) =>
                                this.handleTabChange(event, tab)
                            }
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab testName="billOfServicesTab" label="Bill of Services" />
                            {configuration.state === 'COMPLETED' && (
                                <Tab testName="activityScheduleTab" label="Activity Schedule" />
                            )}
                            {configuration.state === 'COMPLETED' && (
                                <Tab testName="financialsTab" label="Financials" />
                            )}
                        </Tabs>
                        <Divider />
                        {configuration.units[currentUnit] !== undefined &&
                            tabValue === 0 && (
                                <TabContainer>
                                    <BillOfServices
                                        configuration={configuration}
                                        model={model}
                                        currentUnit={currentUnit}
                                    />
                                </TabContainer>
                            )}
                        {configuration.units[currentUnit] === undefined &&
                            tabValue === 0 && (
                                <TabContainer>
                                    Please select a unit to see the bill of
                                    services.
                                </TabContainer>
                            )}
                        {tabValue === 1 && (
                            <TabContainer>
                                <ActivitySchedule
                                    configuration={configuration}
                                    currentUnit={currentUnit}
                                />
                            </TabContainer>
                        )}
                        {tabValue === 2 && (
                            <TabContainer>
                                <Financials
                                    match={match}
                                    configuration={configuration}
                                    sendMessage={sendMessage}
                                    saveCompleted={saveCompleted(model.id)}
                                    currentUnit={currentUnit}
                                    mergeConfig={(
                                        name,
                                        step,
                                        rawValue,
                                        autoUpdate
                                    ) =>
                                        this.formChange(
                                            name,
                                            step,
                                            rawValue,
                                            autoUpdate
                                        )
                                    }
                                    model={model}
                                    updateTargetPricing={(conf, field, value) =>
                                        updateTarget(conf, field, value)
                                    }
                                    pricingTarget={pricingTarget}
                                />
                            </TabContainer>
                        )}
                    </Paper>
                </Paper>
            </React.Fragment>
        );
    }

    private getPrimaryConfigurationToggle(authorizedToEdit): React.ReactNode {
        const { model, configuration, setPrimaryConfig } = this.props;

        const changePrimaryConfiguration = () => {
            if (canChangePrimaryConfiguration(model, configuration)) {
                this.showLoading();
            }

            // It looks strange that this is not inside the "can change" clause above,
            // but it looks like the reason behind it is to let the user get an error
            // when trying to change the primary config in an invalid scenario.
            setPrimaryConfig(model)(configuration);
        };
        const authorizedOnClickHandler = authorizedToEdit
            ? changePrimaryConfiguration
            : null;

        if (configuration.primaryConfiguration) {
            const title = authorizedToEdit
                ? 'Remove this configuration as primary'
                : 'This configuration is primary';
            return (
                <Tooltip title={title} placement="top">
                    <FlagIcon
                        color="secondary"
                        className={this.props.classes.flagIcon}
                        onClick={authorizedOnClickHandler}
                    />
                </Tooltip>
            );
        }

        const title = authorizedToEdit
            ? 'Make this configuration primary'
            : 'This configuration is not primary';
        return (
            <Tooltip title={title} placement="top">
                <FlagOutlineIcon
                    color="disabled"
                    className={this.props.classes.flagIcon}
                    onClick={authorizedOnClickHandler}
                />
            </Tooltip>
        );
    }

    private getEditOrViewButton(authorizedToEdit): React.ReactNode {
        const { match } = this.props;

        const EditOrViewConfigDetailsLink = props => {
            const modelId = match.params.modelID;
            const configId = match.params.configurationID;
            const editOrViewUrl = `/models/${modelId}/configuration/edit/${configId}`;
            return <Link to={editOrViewUrl} {...props}/>;
        };

        const editOrViewBtn = authorizedToEdit
            ? <Button testName="edit" color="primary" component={EditOrViewConfigDetailsLink}>
                <EditIcon/> Edit
            </Button>
            : <Button testName="view" color="primary" component={EditOrViewConfigDetailsLink}>
                View Details
            </Button>;
        return editOrViewBtn;
    }

    private getUnitReadCounterFields(unit: IUnit): React.ReactNode {
        const hasReadCounter = hasValue(unit.unitReadCounter);
        if (!hasReadCounter) {
            return null;
        }

        return (
            <>
                <DataHighlight
                    testName="unitReadCounter"
                    labelText="Unit Counter Reading (oph)"
                    price
                    value={
                        formatNumber(unit.unitReadCounter, 0, 0)
                    }
                />
                <DataHighlight
                    testName="unitReadDate"
                    labelText="Unit Counter Reading Date"
                    price
                    value={
                        isoToLocaleDate(unit.unitReadDate)
                    }
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        pricingTarget: state.configurations.pricingTarget || {},
        configuration: state.configurations.configuration,
        model: state.models.activeModel,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (msg, type) => {
            dispatch(setMessage(msg, type));
        },
        updateStep: (current, step, autoUpdate, model) => {
            const rtn = dispatch(mergeStep(current, step));
            if (autoUpdate) {
                dispatch(
                    saveConfiguration(defaultsDeep(step, current), model, false)
                );
            }
            return rtn;
        },
        saveCompleted: model => configuration => {
            return dispatch(saveConfiguration(configuration, model, false));
        },
        getConfiguration: id => dispatch(getOne(id)),
        populateModel: (id: number) => dispatch(getModelDetails(id)),
        setPrimaryConfig: (model: IFormModel) => (
            configuration: IConfigurationView | IConfiguration
        ) => {
            dispatch(setPrimaryConfigurations(model, configuration));
        },
        updateTarget: (current, field, value) =>
            dispatch(updateTargetPricing(current, field, value)),
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Configuration)
);
