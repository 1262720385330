import React, { FC } from 'react';
import Checkbox, { ICheckboxProps } from '../Form/Checkbox';
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';
import { FormControlLabel } from '@material-ui/core';

export const CheckboxField: FC<CheckboxFieldProps> =
    props => {
        const {
            label,
            disabled,
            ...checkboxProps
        } = props;

        return (
            <CaseAuthorizedToEditForm>
                {authorizedToEdit => {
                    const inputDisabled = getInputDisabled(authorizedToEdit, disabled);
                    return (
                        <FormControlLabel
                            label={label}
                            labelPlacement='end'
                            disabled={inputDisabled}
                            control={<Checkbox {...checkboxProps} />}
                        />
                    );
                }}
            </CaseAuthorizedToEditForm>
        )
    };

interface CheckboxFieldProps extends ICheckboxProps {
    label: string,
}

function getInputDisabled(authorizedToEdit: boolean, disabled: boolean) {
    return !authorizedToEdit || disabled;
}
