import React, { FC } from 'react';
import ViewTitle from '../ViewTitle/ViewTitle';
import { Grid } from '@material-ui/core';
import { ReportDownloadButton } from '../ReportDownloadButton/ReportDownloadButton';

export const ReportsPage: FC =
    () => {
        const financialForecastUrl = getModelReportUrl(ReportType.FINANCIAL_FORECAST);
        const billingForecastUrl = getModelReportUrl(ReportType.BILLING_FORECAST);
        const costForecastUrl = getModelReportUrl(ReportType.COST_FORECAST);
        const modelAndConfigurationUrl = getModelReportUrl(ReportType.MODEL_AND_CONFIGURATION);
        const pricingUrl = getModelReportUrl(ReportType.PRICING_CONFIGURATION);
        const guaranteesUrl = getModelReportUrl(ReportType.GUARANTEES);
        const scopeConfigurationUrl = getModelReportUrl(ReportType.SCOPE_CONFIGURATION);
        
        return (
            <>
                <ViewTitle title='Reports'/>
                <div className='right-content-wrap'>
                    <Grid container spacing={8}>
                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={financialForecastUrl}
                                testName='downloadFinancialForecastReport'
                            >
                                Financial Forecast
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={billingForecastUrl}
                                testName='downloadBillingForecastReport'
                            >
                                Billing Forecast
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={costForecastUrl}
                                testName='downloadCostForecastReport'
                            >
                                Cost Forecast
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={modelAndConfigurationUrl}
                                testName='downloadModelAndConfigurationReport'
                            >
                                Model and Configuration
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={guaranteesUrl}
                                testName='downloadGuaranteesReport'
                            >
                                Guarantees
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>
                        
                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={scopeConfigurationUrl}
                                testName='downloadScopeConfigurationReport'
                            >
                                Scope Configuration
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                 href={pricingUrl}
                                 testName='downloadPricingConfigurationReport'
                             >
                                 Pricing Configuration
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>
                    </Grid>
                </div>
             
            </>
        );
    };

function getModelReportUrl(type: ReportType) {
    return `${REPORT_ROOT}/reports?type=${type}`;
}

enum ReportType {
    FINANCIAL_FORECAST = 'FINANCIAL_FORECAST',
    BILLING_FORECAST = 'BILLING_FORECAST',
    COST_FORECAST = 'COST_FORECAST',
    MODEL_AND_CONFIGURATION = 'MODEL_AND_CONFIGURATION',
    PRICING_CONFIGURATION = 'PRICING_CONFIGURATION',
    GUARANTEES = "GUARANTEES",
    SCOPE_CONFIGURATION = 'SCOPE_CONFIGURATION',
}
