import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { formOption } from '../../utils/formOption';

export const ProductProgram: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'engineInformation.productProgram';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Product Program'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

const options: IFormOption[] = generateOptions();

function generateOptions(): IFormOption[] {
    const years: IFormOption[] = [];

    let firstYear = 2000;
    const currentYear = new Date().getFullYear();
    while (firstYear <= currentYear) {
        years.push(formOption('' + firstYear));
        firstYear += 1;
    }

    return years;
}
