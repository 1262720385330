import * as React from 'react';
import './DataHighlight.scss';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CloudDownload } from '@material-ui/icons';
import { createDataTestProps } from "../Utils/testProps";

const styles = () => ({
    item: {
        marginRight: 20,
    },
    subtitle2: {
        color: '#7d7d7d',
        fontSize: 11,
        lineHeight: 'normal',
        marginBottom: 3,
    },
    body1: {
        fontSize: 16,
    },
    price: {
        fontSize: '1.5em',
        color: '#ff9100',
    },
    link: {
        marginLeft: 15,
    },
});

interface IDataHighlightProps {
    testName: string,
    labelText: React.ReactNode,
    value: React.ReactNode,
    price?: boolean,
    downloadLink?: string,
    fullwidth?:boolean
    classes: {
        item: string,
        subtitle2: string,
        price: string,
        body1: string,
        link: string,
    },
}

const DataHighlight: React.FunctionComponent<IDataHighlightProps> = props => {
    const { testName, classes, fullwidth } = props;

    const dataTestProps = createDataTestProps(testName);
    const linkTestProps = createDataTestProps('download');

    return (
        <Grid item xs={4} sm={3} md={fullwidth?8:2} classes={{ item: classes.item }}>
            <Typography
                variant="subtitle2"
                classes={{ subtitle2: classes.subtitle2 }}
            >
                {props.labelText}
            </Typography>
            <Typography
                variant="body1"
                classes={
                    props.price
                        ? { body1: classes.price }
                        : { body1: classes.body1 }
                }
                {...dataTestProps}
            >
                {props.value}
                {props.downloadLink && (
                    <span className={classes.link}>
                        <a href={props.downloadLink} target={'_blank'} {...linkTestProps}>
                            <CloudDownload/>
                        </a>
                    </span>
                )}
            </Typography>
        </Grid>
    );
};

export default withStyles(styles as any)(DataHighlight);
