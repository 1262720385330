import { boolean, mixed, MixedSchema, number, object, ObjectSchema, string } from '../../utils/validators';
import { Activity, ActivitySchedulingType, ActivityType, ActivityUnit } from '../../api/model-service/model-service';
import { integerMessage, minMessage, requiredMessage } from '../../utils/validationMessages';

export function getAdditionalScopeSchema(): ObjectSchema<Activity> {
    return object().shape({
        scope: string().required(requiredMessage),
        service: string().trim().required(requiredMessage),
        type: mixed<ActivityType>()
            .required(requiredMessage)
            .oneOf([ 'INNIO_PARTS', 'INNIO_LABOR', 'OTHER_PROVIDER', 'FREIGHT', 'CUSTOMS' ]),
        additionalAttribute: string(),
        schedulingType: mixed<ActivitySchedulingType>().required(requiredMessage)
            .when('scope', (scope, schema: MixedSchema<ActivitySchedulingType>) => {
                return scope === 'SITE'
                    ? schema.oneOf([ 'YEARS' ])
                    : schema.oneOf([ 'OPH', 'YEARS' ]);
            }),
        frequency: mixed().when('schedulingType', {
            is: 'OPH',
            then: number()
                .required(requiredMessage)
                .integer(integerMessage)
                .min(250, minMessage),
            otherwise: mixed().strip(true),
        }),
        frequencyOffset: number(),
        frequencyModified: boolean(),
        occurrence: number().integer(integerMessage).min(0, minMessage),
        fixedOccurrence: boolean(),
        value: number().required(requiredMessage),
        unit: mixed<ActivityUnit>().oneOf([ 'EURO', 'HOURS' ]),
        icValue: number(),
    });
}
