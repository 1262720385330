import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './data/store';
import { ReduxAuthorizationProvider} from "./components/authorization";
import App from './App';

ReactDOM.render(
    <Provider store={store}>
        <ReduxAuthorizationProvider>
            <App />
        </ReduxAuthorizationProvider>
    </Provider>,
    document.getElementById('mya-web-app')
);

if (module.hot) {
    module.hot.accept(() => {});
}
