import React, { cloneElement } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { RadioGroupField } from '../RadioGroupField/RadioGroupField';
import { FieldProps } from './FieldProps';
import { CalculationMethod as CalculationMethodType } from '../../api/model-service/model-service';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';
import { withVariablePriceEscalation } from './withEscalationType';

export const CalculationMethod = withVariablePriceEscalation<FieldProps>(
    ({ formikProps, container }) => {
        const name = 'calculationMethod';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <RadioGroupField
                    name={name}
                    label='Calculation method'
                    options={options}
                    value={inputValue(name, formikProps)}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    required
                />,
        });
    },
);

const options: Array<IFormOption<CalculationMethodType>> = [
    formOption('MONTHLY_SPECIFIC', 'Monthly specific'),
    formOption('YEARLY_AVERAGE', 'Yearly average'),
];
