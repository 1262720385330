import {
    CatalogState,
    CatalogType,
    getAllCatalogsByType,
    updateCatalogState,
    UpdateCatalogStateRequest,
} from '../../api/catalog-service/catalog-overview';
import { AppThunk } from '../app-thunk';
import { detailsSuccessResponse } from './slice';
import { getDefaultCatalog } from '../default-catalog/get-default-catalog';
import { reportCatalogError } from '../error-actions';

export const changeCatalogState = (id: number, type: CatalogType, state: CatalogState): AppThunk => dispatch => {
    const request = createRequest(id, state);
    return updateCatalogState(request)
        .then(() => getAllCatalogsByType(type))
        .then(catalogs => {
            dispatch(detailsSuccessResponse(catalogs));
            dispatch(getDefaultCatalog(type));
        })
        .catch(err => {
            dispatch(reportCatalogError(err));
        });
    // TODO handle loading
};

function createRequest(id: number, state: CatalogState): UpdateCatalogStateRequest {
    return isDefaultStateChange(state)
        ? { defaultId: id }
        : {
            changes: [ { id, state } ],
        };
}

function isDefaultStateChange(state: CatalogState): boolean {
    return state === 'DEFAULT';
}
