import React, { FC } from 'react';
import { ActionCard } from '../ActionCard/ActionCard';
import { GuaranteeCardProps } from './GuaranteesInterfaces';
import { StyledComponentProps, Typography } from '@material-ui/core';
import { find } from 'lodash';
import { guaranteeTypeOptions, guaranteeUnitOptions } from './GuaranteeOptions'
import Confirm from '../Utils/Confirm';
import { GuaranteeCardState } from './GuaranteesInterfaces'
import { GuaranteeEditDialog } from './GuaranteeEditDialog';

export class GuaranteeCard extends React.Component<GuaranteeCardProps & StyledComponentProps, GuaranteeCardState> {
    constructor(props) {
        super(props);
        this.state = { 
            isConfirmDialogOpen: false,
            isEditDialogOpen: false, 
        };
    }
    
    private openConfirmDialog() {
        this.setState({ isConfirmDialogOpen: true});     
    }

    private closeConfirmDialog() {
        this.setState({ isConfirmDialogOpen: false });
    }

    private openEditGuaranteeDialog() {
        this.setState({ isEditDialogOpen: true});     
    }

    private closeCloseEditDialog() {
        this.setState({ isEditDialogOpen: false });
    }

    public render () {
        const { 
            guarantee,   
            index,
            ldBonusUnitOptions,
            onDelete,
            onSubmit,
            disabled 
        } = this.props;

        const {
            type,
            value,
            standardDefinitionAndWording,
            nonStandardDescription,
        } = guarantee;

        const nonStandardDescriptionSection = standardDefinitionAndWording
            ? null
            : <React.Fragment>
                <br />
                <span>
                    Non-Standard Guarantee Description:
                    <br />
                    <strong>{nonStandardDescription}</strong>
                    <br />
                </span>
            </React.Fragment>;
        
        const title = find(guaranteeTypeOptions, { value: type });
        const unit = find(guaranteeUnitOptions, { value: guarantee.unit });
        const actions = !disabled ? [
                { testName: 'edit', label: 'Edit', onClick: this.openEditGuaranteeDialog.bind(this) },
                { testName: 'delete', label: 'Delete', onClick: this.openConfirmDialog.bind(this) },
            ]: [];

        return (<ActionCard
            testName={`${index}`}
            title={title.label}
            actions={actions}
        >
            <GuaranteeEditDialog edit={false} ldBonusUnitOptions={ldBonusUnitOptions} 
                        guarantee={guarantee}
                        guaranteeTypeOptions={this.props.guaranteeTypeOptions}
                        guaranteeUnitOptions={this.props.guaranteeUnitOptions}
                        isNewGuarantee={false}
                        close={this.closeCloseEditDialog.bind(this)}
                        open={this.state.isEditDialogOpen}
                        onSubmit={(guarantee) => {
                            onSubmit(guarantee);
                            this.closeCloseEditDialog();
                            return Promise.resolve();
                        }}/>
            <Confirm handleClose={this.closeConfirmDialog.bind(this)} 
                action={() => { onDelete(index)} }
                open={this.state.isConfirmDialogOpen}
                title={'Delete Guarantee'}
                message={`Are you sure you want to delete ${title.label}? It cannot be undone.`}>

            </Confirm>
            <Typography variant="caption">
                Guaranteed Value:{' '}
                <strong>
                    {value} {unit ? unit.label : guarantee.unit}
                </strong>
                <br />
                Standard Definition and Wording:{' '}
                <strong>
                    {standardDefinitionAndWording ? 'Yes' : 'No'}
                </strong>
                { nonStandardDescriptionSection }
            </Typography>
        </ActionCard>);
    }
}
