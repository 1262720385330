import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '../../Form/NativeSelect';
import Select from '@material-ui/core/Select';

import MaterialTable from '../../Tables/MaterialTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PriceConfigIcon from '@material-ui/icons/Settings';

import SectionTitle from '../../SectionTitle/SectionTitle';
import EditPricingConfig from './Pricing/EditPricingConfig';
import { formatCurrency } from '../../../data/utils/number';
import {
    prepareBreakdown,
    prepareEventBreakdown,
} from '../../../data/actions/configurations';
import { ScopeTypesInputs } from '../../../data/model/configuration_enums';
import { sortBy } from 'lodash';
import { activtyTypeToBreakdownKey } from '../../../data/model/configuration_pricing';
import {createButtonTestProps, createDataTestProps, createRowTestProps} from "../../Utils/testProps";
import FinancialDataPoint from './FinancialDataPoint';
import SubtotalRow from "../../Tables/SubtotalRow";
import SubtotalDataCell from "../../Tables/SubtotalDataCell";
import { CaseAuthorizedToUpdateModel } from '../../authorization/CaseAuthorizedToUpdateModel';

const styles = (theme: any) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
        border: '1px solid lightgrey',
    },
    divider: {
        margin: '15px 0',
    },
    takeAways: {
        padding: theme.spacing.unit * 2,
    },
    takeAwayRoot: {
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 5,
    },
    detailRow: {
        backgroundColor: '#f5f5f5',
    },
    configIcon: {
        position: 'relative',
        top: 2,
        marginRight: 3,
    },
    configPriceButton: {
        cursor: 'pointer',
        fontSize: 18,
    },
});

class Financials extends React.Component<any, any> {
    public state = {
        includeCost: true,
        checked: false,
        breakDownCost: 'cost',
        breakDownType: 'scope',
        selectedActivtiyFrequencies: [],
        breakDownCoveredUnit: 0,
    };

    public handleCostChange(name: any, event: any): void {
        this.setState({ [name]: event.target.value });
    }

    public handleCollapse(): void {
        this.setState(state => ({ checked: !state.checked }));
    }

    public handleChangeMultiple(name, event): void {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(parseInt(options[i].value, 10));
            }
        }
        this.setState({
            [name]: value,
        });
    }

    public componentWillMount(): void {
        this.setState({ checked: this.props.match.params.action === 'config' });
    }

    public render(): React.ReactElement {
        const {
            classes,
            configuration,
            sendMessage,
            saveCompleted,
            mergeConfig,
            model,
            updateTargetPricing,
            pricingTarget,
        } = this.props;
        const {
            checked,
            breakDownType,
            breakDownCost,
            selectedActivtiyFrequencies,
            breakDownCoveredUnit,
        } = this.state;

        const unitLevelBreakdown = [
            {
                equipment: 'Site Level',
                usn: 'NA',
                tec: formatCurrency(
                    'EUR',
                    configuration.costBreakdown
                        ? configuration.costBreakdown.totalSiteLevelCost
                        : 0
                ),
            },
        ];

        if (
            configuration.costBreakdown &&
            configuration.costBreakdown.equipmentCosts
        ) {
            sortBy(configuration.costBreakdown.equipmentCosts, [
                o => o.equipment.id,
            ]).map((equipmentCosts, index) => {
                unitLevelBreakdown.push({
                    equipment: `Unit ${index + 1}`,
                    usn: equipmentCosts.equipment.serialNumber,
                    tec: formatCurrency('EUR', equipmentCosts.equipmentCost),
                });
            });
        }

        const breakdown = prepareBreakdown(
            configuration,
            model,
            breakDownCost,
            breakDownCoveredUnit
        );

        const eventBreakdown = prepareEventBreakdown(
            model,
            configuration,
            selectedActivtiyFrequencies
        );

        return (
            <React.Fragment>
                {configuration.pricing && configuration.pricing.totalBillings !== 0 && (
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={16}
                        classes={{ container: classes.takeAwayRoot }}
                    >
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper
                                className={classes.takeAways}
                                elevation={1}
                                square
                            >
                                <FinancialDataPoint
                                    testName="totalBillings"
                                    label="Total Configuration Billings"
                                    amount={configuration.pricing.totalBillings}
                                    currency="EUR"
                                />
                                <br />
                                <FinancialDataPoint
                                    testName="aggregatedPerOPH"
                                    label="Aggregated Price / Oph"
                                    amount={configuration.pricing.aggregatedPerOPH}
                                    currency="EUR"
                                />
                                <br />
                                <FinancialDataPoint
                                    testName="aggregatedPerMWH"
                                    label="Aggregated Price / MWh"
                                    amount={configuration.pricing.aggregatedPerMWH}
                                    currency="EUR"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper
                                className={classes.takeAways}
                                elevation={1}
                                square
                            >
                                <FinancialDataPoint
                                    testName="totalCM"
                                    label="Total CM"
                                    amount={configuration.pricing.totalCM}
                                    currency="EUR"
                                />
                                <br />
                                <FinancialDataPoint
                                    testName="totalCMPercentage"
                                    label="Total CM%"
                                    percentage={configuration.pricing.totalCMPercentage}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper
                                className={classes.takeAways}
                                elevation={1}
                                square
                            >
                                <FinancialDataPoint
                                    testName="totalCMConsidLDB"
                                    label="Total CM Cons, LD/B"
                                    amount={configuration.pricing.totalCMConsidLDB}
                                    currency="EUR"
                                />
                                <br />
                                <FinancialDataPoint
                                    testName="totalCMPercentageConsidLDB"
                                    label="Total CM% Cons, LD/B"
                                    percentage={configuration.pricing.totalCMPercentageConsidLDB}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper
                                className={classes.takeAways}
                                elevation={1}
                                square
                            >
                                <FinancialDataPoint
                                    testName="totalLD"
                                    label="Total LD’s"
                                    amount={configuration.pricing.totalLD}
                                    currency="EUR"
                                />
                                <br />
                                <FinancialDataPoint
                                    testName="totalBonus"
                                    label="Total Bonus"
                                    amount={configuration.pricing.totalBonus}
                                    currency="EUR"
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )}
                <CaseAuthorizedToUpdateModel model={model}>
                    {
                        authorizedToEdit => {
                            const btnLabel = authorizedToEdit
                                ? 'Configure Pricing'
                                : 'View Pricing';
                            const btnExplanation = authorizedToEdit
                                ? ' to calculate billings and CM.'
                                : '';

                            return (
                                <React.Fragment>
                                    <Typography variant="body2">
                                        <a
                                            onClick={() => this.handleCollapse()}
                                            className={this.props.classes.configPriceButton}
                                            {...createButtonTestProps('configurePricing') }
                                        >
                                            <PriceConfigIcon
                                                className={this.props.classes.configIcon}
                                                fontSize="inherit"
                                            />
                                            &nbsp;{btnLabel}
                                        </a>
                                        {btnExplanation}
                                    </Typography>
                                    <Collapse in={checked}>
                                        <EditPricingConfig
                                            authorizedToEdit={authorizedToEdit}
                                            configuration={configuration}
                                            sendMessage={sendMessage}
                                            saveCompleted={saveCompleted}
                                            mergeConfig={mergeConfig}
                                            handleClose={() => this.handleCollapse()}
                                            updateTargetPricing={updateTargetPricing}
                                            pricingTarget={pricingTarget}
                                        />
                                    </Collapse>
                                </React.Fragment>
                            );
                        }
                    }
                </CaseAuthorizedToUpdateModel>
                <br />
                <br />
                <SectionTitle title="Split by Unit" />
                <MaterialTable
                    tableTestName="unitCostBreakdown"
                    rowTestName="unitCost"
                    options={{
                        paging: true,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                    }}
                    columns={[
                        { title: 'Equipment', field: 'equipment' },
                        { title: 'Unit Serial Number', field: 'usn' },
                        {
                            title: 'Total Equipment Cost',
                            field: 'tec',
                            type: 'numeric',
                        },
                    ]}
                    data={unitLevelBreakdown}
                    title="Split by Unit"
                />
                <br />
                <br />
                <SectionTitle title="Breakdown Dashboard">
                    <FormControl>
                        <FormControlLabel
                            value="start"
                            control={
                                <NativeSelect
                                    testName="breakDownType"
                                    value={breakDownType}
                                    onChange={event =>
                                        this.handleCostChange(
                                            'breakDownType',
                                            event
                                        )
                                    }
                                    classes={{ root: 'smallNativeSelect' }}
                                >
                                    <option value="scope">Scope</option>
                                    <option value="service">Service</option>
                                </NativeSelect>
                            }
                            label=""
                            labelPlacement="start"
                            classes={{ root: 'smallNativeSelectLabel' }}
                        />
                    </FormControl>
                    &nbsp;&nbsp;&nbsp;
                    <FormControl>
                        <NativeSelect
                            testName="breakDownCost"
                            value={breakDownCost}
                            onChange={event =>
                                this.handleCostChange('breakDownCost', event)
                            }
                            classes={{ root: 'smallNativeSelect' }}
                            defaultValue="cost"
                        >
                            <option value="cost">Absolute</option>
                            <option value="costPerOPH">Cost Per OPH</option>
                            <option value="costPerMWH">Cost Per MWH</option>
                        </NativeSelect>
                    </FormControl>
                    &nbsp;&nbsp;&nbsp;
                    <FormControl>
                        <NativeSelect
                            testName="breakDownCoveredUnit"
                            value={breakDownCoveredUnit}
                            onChange={event =>
                                this.handleCostChange(
                                    'breakDownCoveredUnit',
                                    event
                                )
                            }
                            classes={{ root: 'smallNativeSelect' }}
                            defaultValue="cost"
                        >
                            {configuration.units.map((unit, index) => (
                                <option key={index} value={index}>
                                    Unit {index + 1}: {unit.serialNumber}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </SectionTitle>
                <MaterialTable
                    tableTestName="breakdownDashboard"
                    rowTestName="activity"
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                    }}
                    columns={[
                        {
                            title: `${breakDownType
                                .charAt(0)
                                .toUpperCase()}${breakDownType.substr(1)}`,
                            field: 'title',
                        },
                        {
                            title: 'INNIO Parts',
                            field: 'parts',
                            type: 'numeric',
                        },
                        {
                            title: 'INNIO Labor',
                            field: 'labor',
                            type: 'numeric',
                        },
                        {
                            title: 'Other Provider',
                            field: 'otherProvider',
                            type: 'numeric',
                        },
                    ]}
                    data={
                        breakdown[breakDownType]
                            ? breakdown[breakDownType].map((row, index) => {
                                  ScopeTypesInputs.map(type => {
                                      const activityKey = activtyTypeToBreakdownKey(
                                          type.value
                                      );
                                      row[activityKey] = formatCurrency(
                                          'EUR',
                                          row[activityKey]
                                      );
                                      row.index = index;
                                  });
                                  return row;
                              })
                            : []
                    }
                    title="Breakdown Dashboard"
                    detailPanel={rowData => {
                        return rowData.secondary.map((row, secondaryIndex) => {
                            const colStyle = {
                                padding: '5px',
                                flex: 1,
                                color: '#848484',
                            };
                            const rowTestId = `${rowData.index}-${secondaryIndex}`;
                            const rowTestProps = createRowTestProps('secondaryActivity', rowTestId);
                            const titleTestProps = createDataTestProps('title');
                            const partsTestProps = createDataTestProps('parts');
                            const laborTestProps = createDataTestProps('labor');
                            const otherProviderTestProps = createDataTestProps('otherProvider');

                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#fffcf8',
                                        padding: '5px 18px 5px 65px',
                                        textAlign: 'right',
                                    }}
                                    key={row.id}
                                    {...rowTestProps}
                                >
                                    <div
                                        style={{
                                            ...colStyle,
                                            textAlign: 'left',
                                        }}
                                        {...titleTestProps}
                                    >
                                        {row.title}
                                    </div>
                                    <div
                                        style={{
                                            ...colStyle,
                                            paddingRight: 70,
                                        }}
                                        {...partsTestProps}
                                    >
                                        {formatCurrency('EUR', row.parts)}
                                    </div>
                                    <div
                                        style={{
                                            ...colStyle,
                                            paddingRight: 70,
                                        }}
                                        {...laborTestProps}
                                    >
                                        {formatCurrency('EUR', row.labor)}
                                    </div>
                                    <div style={colStyle} {...otherProviderTestProps}>
                                        {formatCurrency(
                                            'EUR',
                                            row.otherProvider
                                        )}
                                    </div>
                                </div>
                            );
                        });
                    }}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
                <Paper elevation={2}>
                    <SubtotalRow testName="breakdownDashboardSubtotals">
                        <SubtotalDataCell
                            testName="subtotalParts"
                            amount={breakdown.subtotals.parts}
                            currency="EUR"
                        />
                        <SubtotalDataCell
                            testName="subtotalLabor"
                            amount={breakdown.subtotals.labor}
                            currency="EUR"
                        />
                        <SubtotalDataCell
                            testName="subtotalOtherProvider"
                            amount={breakdown.subtotals.otherProvider}
                            currency="EUR"
                        />
                    </SubtotalRow>
                </Paper>
                <br />
                <br />
                {eventBreakdown && eventBreakdown.frequencies && (
                    <SectionTitle title="Breakdown by Event">
                        <FormControl>
                            <Select
                                value={selectedActivtiyFrequencies}
                                multiple
                                native
                                onChange={event =>
                                    this.handleChangeMultiple(
                                        'selectedActivtiyFrequencies',
                                        event
                                    )
                                }
                                classes={{ root: 'smallNativeSelect multipleSelect' }}
                            >
                                {eventBreakdown.frequencies.sort((a, b) => a - b).map(freq => (
                                    <option key={freq} value={freq}>
                                        {freq}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </SectionTitle>
                )}
                {eventBreakdown && eventBreakdown.tableData && (
                    <MaterialTable
                        tableTestName="eventBreakdown"
                        rowTestName="activity"
                        options={{
                            paging: false,
                            search: false,
                            showTitle: false,
                            toolbar: false,
                        }}
                        columns={[
                            { title: 'Scope', field: 'scope' },
                            {
                                title: 'INNIO Parts',
                                field: 'parts',
                                type: 'numeric',
                            },
                            {
                                title: 'INNIO Labor',
                                field: 'labor',
                                type: 'numeric',
                            },
                            {
                                title: 'Other Provider',
                                field: 'otherProvider',
                                type: 'numeric',
                            },
                        ]}
                        data={eventBreakdown.tableData.map(row => {
                            ScopeTypesInputs.map(type => {
                                const activityKey = activtyTypeToBreakdownKey(
                                    type.value
                                );
                                row[activityKey] = formatCurrency(
                                    'EUR',
                                    row[activityKey]
                                );
                            });
                            return row;
                        })}
                        title="Breakdown Dashboard"
                    />
                )}
                {eventBreakdown && eventBreakdown.subtotals && (
                    <Paper elevation={2}>
                        <SubtotalRow testName="eventBreakdownSubtotals">
                            <SubtotalDataCell
                                testName="subtotalParts"
                                amount={eventBreakdown.subtotals.parts}
                                currency="EUR"
                            />
                            <SubtotalDataCell
                                testName="subtotalLabor"
                                amount={eventBreakdown.subtotals.labor}
                                currency="EUR"
                            />
                            <SubtotalDataCell
                                testName="subtotalOtherProvider"
                                amount={eventBreakdown.subtotals.otherProvider}
                                currency="EUR"
                            />
                        </SubtotalRow>
                    </Paper>
                )}
            </React.Fragment>
        );
    }
}

export default withStyles(styles as any)(Financials);
