import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

import Timeline from 'react-calendar-timeline';

// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import './ActivitySchedule.scss';
import * as moment from 'moment';

import { serviceToMoment } from '../../../data/utils/date';
import {
    activityCostDisplay,
    activityToTitle,
    activityTypeDisplay,
} from '../../../data/model/configuration';
import { hasValue } from '../../../data/utils/form';
import { getActivitySchedule } from '../../../api/model-service/configuration';
import { Grid, LinearProgress } from '@material-ui/core';

const styles = (theme: any) => ({
    schedulePopover: {
        padding: '15px',
        fontSize: 12,
    },
});

const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start_time',
    itemTimeEndKey: 'end_time',
    groupLabelKey: 'title',
};

class ActivitySchedule extends React.Component<any, any> {
    public state = {
        anchorEl: null,
        item: null,
        loading: true,
        activitySchedule: [],
    };

    public componentDidMount() {
        getActivitySchedule(this.props.configuration.id)
            .then(activitySchedule => {
                this.setState({ activitySchedule });
            })
            .finally(() => {
                this.setState({ loading : false });
            });
    }

    public render(): React.ReactElement {
        const { anchorEl, item, loading } = this.state;

        if (loading) {
            return (
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            );
        };

        const { classes, configuration } = this.props;
        const open = Boolean(anchorEl);

        const groups = [];
        const items = [];
        let lastIndex = 0;

        let visibleTimeStart = moment();

        let groupID = 1;
        if (configuration.activityScheduleSiteLevel) {
            groups.push({
                id: groupID,
                title: 'Site',
                stackItems: true,
            });

            configuration.activityScheduleSiteLevel.activityDates.map(
                (siteLevelActivityDate, index) => {
                    const date = serviceToMoment(siteLevelActivityDate.date);
                    if (visibleTimeStart.isAfter(date)) {
                        visibleTimeStart = date;
                    }
                    lastIndex = index;
                    items.push({
                        id: lastIndex,
                        group: groupID,
                        canResize: false,
                        canMove: false,
                        title: activityToTitle(siteLevelActivityDate.activity),
                        scope: siteLevelActivityDate.activity.scope,
                        type: activityTypeDisplay(
                            siteLevelActivityDate.activity.type
                        ),
                        value: siteLevelActivityDate.cost,
                        unit: 'EURO',
                        majorOverhaulException: siteLevelActivityDate.majorOverhaulException,
                        className: 'activity',
                        start_time: date.startOf('d').toDate(),
                        end_time: date
                            .startOf('d')
                            .add(1, 'd')
                            .toDate(),
                    });
                }
            );
        }

        lastIndex++;
        groupID = 2;
        if (this.state.activitySchedule) {
            this.state.activitySchedule.map((activityRow, index) => {
                groups.push({
                    id: groupID,
                    title: `Unit (${activityRow.unit.serialNumber})`,
                    stackItems: true,
                });

                activityRow.activityDates.map(activityDate => {
                    const date = serviceToMoment(activityDate.date);
                    if (visibleTimeStart.isAfter(date)) {
                        visibleTimeStart = date;
                    }
                    items.push({
                        id: lastIndex,
                        group: groupID,
                        canResize: false,
                        canMove: false,
                        title: activityToTitle(activityDate.activity),
                        scope: activityDate.activity.scope,
                        type: activityTypeDisplay(activityDate.activity.type),
                        value: activityDate.cost,
                        unit: 'EURO',
                        majorOverhaulException: activityDate.majorOverhaulException,
                        className: 'activity',
                        start_time: date.startOf('d').toDate(),
                        end_time: date
                            .startOf('d')
                            .add(1, 'd')
                            .subtract('1', 'ms')
                            .toDate(),
                    });
                    lastIndex++;
                });

                groupID += 1;
            });
        }

        const visibleTimeEnd =
            configuration && configuration.schedule
                ? serviceToMoment(configuration.schedule.endDate).add(1, 'w')
                : moment().add(1, 'y');
        visibleTimeStart.subtract(1, 'w');

        const itemCostExplanation = this.getMajorOverhaulCostExplanation();

        return (
            <React.Fragment>
                <Timeline
                    groups={groups}
                    items={items}
                    keys={keys}
                    sidebarContent={<h3>Service</h3>}
                    itemsSorted
                    itemTouchSendsClick={false}
                    stackItems
                    itemHeightRatio={1}
                    showCursorLine
                    defaultTimeStart={visibleTimeStart.toDate()}
                    defaultTimeEnd={visibleTimeEnd.toDate()}
                    itemRenderer={this.itemRenderer}
                    traditionalZoom
                />

                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => this.handleClose()}
                    classes={{ paper: classes.schedulePopover }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {item && (
                        <React.Fragment>
                            Service: {item.title}
                            <br />
                            Scope: {item.scope}
                            <br />
                            Type: {item.type}
                            <br /> Cost: {activityCostDisplay(item)} {itemCostExplanation}
                        </React.Fragment>
                    )}
                </Popover>
            </React.Fragment>
        );
    }

    private itemRenderer = ({
        item,
        timelineContext,
        itemContext,
        getItemProps,
    }) => {
        return (
            <div
                {...getItemProps({
                    style: {
                        color: 'black',
                        borderColor: '#4caf50',
                        borderStyle: 'solid',
                        borderWidth: 1,
                    },
                })}
                onClick={event => this.handleClick(event, item)}
            >
                <div
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: 'hidden',
                        paddingLeft: 3,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {itemContext.title}
                </div>
            </div>
        );
    };

    private handleClick(event, item): void {
        this.setState({
            anchorEl: event.currentTarget,
            item,
        });
    }

    private handleClose(): void {
        this.setState({
            anchorEl: null,
        });
    }

    private getMajorOverhaulCostExplanation(): string {
        return this.currentItemHasMajorOverhaulException()
            ? '(within 50 ophs of Major Overhaul event)'
            : null;
    }

    private currentItemHasMajorOverhaulException(): boolean {
        const item = this.state.item;
        return hasValue(item) && item.majorOverhaulException;
    }
}

export default withStyles(styles as any)(ActivitySchedule);
