import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { NativeSelectProps } from '@material-ui/core/NativeSelect';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    NativeSelect,
} from '@material-ui/core';
import { IFormOption } from '../../data/utils/form';
import { createInputTestProps, createErrorTestProps } from '../Utils/testProps';

type NativeSelectValidatorProps = NativeSelectProps & {
    helperText?: React.ReactNode,
    label?: React.ReactNode,
    options?: IFormOption[],
    noPlaceholderOption?: boolean,
};

export default class NativeSelectValidator
        extends ValidatorComponent<NativeSelectValidatorProps> {

    public render(): React.ReactElement {
        const {
            name,
            error,
            helperText,
            label,
            inputProps,
            options,
            noPlaceholderOption,
            fullWidth,

            // Extract the props that don't belong in the text field even
            // if they're not used.
            errorMessages,
            validators,
            validatorListener,
            withRequiredValidator,

            ...nativeSelectProps
        } = this.props;

        const { isValid } = this.state;
        const hasPlaceholderOption = !noPlaceholderOption;

        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(name),
        };

        const helperTextProps = isValid
            ? null
            : createErrorTestProps(name);

        const errorOrHint = (!isValid && this.getErrorMessage()) || helperText;

        return (
            <FormControl fullWidth={fullWidth} error={!isValid || error}>
                <InputLabel shrink htmlFor={inputProps.id}>
                    {label}
                </InputLabel>
                <NativeSelect inputProps={fullInputProps} {...nativeSelectProps}>
                    {hasPlaceholderOption && label && (
                        <option key="" value="" label={`Select ${label}`} />
                    )}
                    {options &&
                        options.length &&
                        options.map(option => {
                            if (option) {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                );
                            }
                        })}
                </NativeSelect>
                <FormHelperText {...helperTextProps}>
                    {errorOrHint}
                </FormHelperText>
            </FormControl>
        );
    }
}
