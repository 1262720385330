import Dialog from '../../Dialogs/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC, ReactNode } from 'react';
import { DialogActions, TableHead, TableRow, TableCell, TableBody, withStyles, StyleRulesCallback, StyledComponentProps, Typography } from '@material-ui/core';
import { Button } from '../../Buttons/Button';
import Table from '../../Tables/Table';
import TableDataRow from '../../Tables/TableDataRow';
import TableDataCell from '../../Tables/TableDataCell';
import { IUnitWithConfigId, IDeleteUnitCounterDialogProps } from './Interfaces';

const styles: StyleRulesCallback = () => ({
    dialogContent: {
        backgroundColor: '#f5f5f5',
        paddingTop: 20,
    },
    lowerText: {
        marginTop: 20,
    }
});

const DeleteUnitCounterDialog: FC<IDeleteUnitCounterDialogProps & StyledComponentProps> = (props) =>
    <Dialog
        testName='DeleteUnitCounterDialog'
        maxWidth='lg'
        open={props.open}
        >
        <DialogTitle id='invalid-counter-reading-dialog-title'>
            Invalid Unit Counter Readings
        </DialogTitle>    
        <DialogContent classes={{ root: props.classes.dialogContent }}>
            <Typography variant="body1">
                Invalid units have been found given the current ammended model.
                The following units have either: a higher start oph value than their read counter value 
                or a higher commercial operation date than their read counter date. 
            </Typography>
            <Table testName='counterReadingViolations'>
                <TableHead>
                    <TableRow>
                        <TableCell>Configuration Name</TableCell>
                        <TableCell>Unit Serial Number</TableCell>
                        <TableCell>Unit Read Counter</TableCell>
                        <TableCell>Unit Start Counter</TableCell>
                        <TableCell>Unit Counter Read Date</TableCell>
                        <TableCell>Commercial Operation Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.units && props.units.map(unit =>
                        <TableDataRow
                            testName='invalidUnitCounterReading'
                            testId={unit.serialNumber}
                            key={unit.id}>
                            {renderUnit(unit)}
                        </TableDataRow>
                    )}
                </TableBody>
            </Table>
            <Typography variant="body1" classes={{root: props.classes.lowerText}}>
                By clicking proceed you agree to deleting the afformentioned read counter values and their respective read dates.
            </Typography>
        </DialogContent>

        <DialogActions>
            <Button testName='cancel' onClick={props.onCancel}>Cancel</Button>
            <Button testName='proceed' onClick={props.onProceed} color='primary'>Proceed</Button>
        </DialogActions>
    </Dialog>

function renderUnit(unit: IUnitWithConfigId): ReactNode {
    return (
        <>
            <TableDataCell testName='configurationName'>{unit.configurationName}</TableDataCell>
            <TableDataCell testName='unitSerialNumber'>{unit.serialNumber}</TableDataCell>
            <TableDataCell testName='unitReadCounter'>{unit.unitReadCounter}</TableDataCell>
            <TableDataCell testName='unitStartCounter'>{unit.unitStartCounter}</TableDataCell>
            <TableDataCell testName='unitReadDate'>{unit.unitReadDate}</TableDataCell>
            <TableDataCell testName='commercialOperationDate'>{unit.commercialOperationDate}</TableDataCell>
        </>
    );
}

export default withStyles(styles)(DeleteUnitCounterDialog);