import React from 'react';
import MaterialNativeSelect, { NativeSelectProps as MaterialNativeSelectProps } from '@material-ui/core/NativeSelect';
import { createInputTestProps } from '../Utils/testProps';

interface INativeSelectProps extends MaterialNativeSelectProps {
    testName: string,
}

const NativeSelect: React.FunctionComponent<INativeSelectProps> =
    ({ testName, inputProps, ...remainingNativeSelectProps }) => {
        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(testName),
        }

        return (
            <MaterialNativeSelect
                inputProps={fullInputProps}
                {...remainingNativeSelectProps}
            />
        );
    };

export default NativeSelect;
