import { upperFirst } from 'lodash'

type PropType = 'inp' | 'err' | 'btn' | 'form' | 'dlg' |
    'page' | 'tbl' | 'row' | 'data' | 'card' | 'link' | 'menu';

function createTestProps(type: PropType, name: string, id?: string): ITestProps {
    const ttype = type + upperFirst(name);
    return id
        ? { 'data-ttype': ttype, 'data-tid': id }
        : { 'data-ttype': ttype };
}

export interface ITestProps {
    'data-ttype': string,
    'data-tid'?: string,
};

type TestPropCreator = (name: string, id?: string) => ITestProps;

export const createInputTestProps: TestPropCreator = (name, id) =>
    createTestProps('inp', name, id);

export const createErrorTestProps: TestPropCreator = (name, id) =>
    createTestProps('err', name, id);

export const createButtonTestProps: TestPropCreator = (name, id) =>
    createTestProps('btn', name, id);

export const createFormTestProps: TestPropCreator = (name, id) =>
    createTestProps('form', name, id);

export const createDialogTestProps: TestPropCreator = (name, id) =>
    createTestProps('dlg', name, id);

export const createPageTestProps: TestPropCreator = (name, id) =>
    createTestProps('page', name, id);

export const createTableTestProps: TestPropCreator = (name, id) =>
    createTestProps('tbl', name, id);

export const createRowTestProps: TestPropCreator = (name, id) =>
    createTestProps('row', name, id);

export const createDataTestProps: TestPropCreator = (name, id) =>
    createTestProps('data', name, id);

export const createCardTestProps: TestPropCreator = (name, id) =>
    createTestProps('card', name, id);

export const createLinkTestProps: TestPropCreator = (name, id) =>
    createTestProps('link', name, id);

export const createMenuTestProps: TestPropCreator = (name, id) =>
    createTestProps('menu', name, id);
