import React from 'react';
import { MTableEditRow as BaseMTableEditRow } from 'material-table';
import { createRowTestProps } from '../Utils/testProps';

interface ITableEditRowProps {
    testName: string,
    testId?: string
}

const MTableEditRow: React.FunctionComponent<ITableEditRowProps> =
    ({ testName, testId, ...remainingTableCellProps }) =>
        <BaseMTableEditRow
            {...remainingTableCellProps}
            {...createRowTestProps(testName, testId)}
        />
    ;

export default MTableEditRow;
