import { ConfigurationFormSectionFactory } from './abstract-factory';
import { MsaCustomerObligationsFactory } from '../CustomerObligationsForm/MsaCustomerObligationsFactory';
import { MsaTermsConditions } from '../OtherTermsConditionsForm/MsaTermsConditions';
import { MsaGuarantees } from '../GuaranteeFormSection/MsaGuarantees';
import { MsaPreventiveStandardScope } from '../StandardScopeFormSection/MsaPreventiveStandardScope';
import { MsaMigrateStandardScopeDialog } from '../MigrateStandardScopeDialog/MsaMigrateStandardScopeDialog';
import { MsaCoveredUnits } from '../CoveredUnitsFormSection/MsaCoveredUnits';
import { PaymentEscalationForm } from '../PaymentEscalationForm/PaymentEscalationForm';
import { AdditionalActivitiesForm } from '../AdditionalActivitiesForm/AdditionalActivitiesForm';
import { DefaultAddUnitsForm } from '../AddUnitsForm/DefaultAddUnitsForm';

export const msaPreventiveFactory: ConfigurationFormSectionFactory = {
    CoveredUnits: MsaCoveredUnits,
    MigrateStandardScopeDialog: MsaMigrateStandardScopeDialog,
    AddUnitsForm: DefaultAddUnitsForm,
    StandardScope: MsaPreventiveStandardScope,
    AdditionalScope: AdditionalActivitiesForm,
    CustomerObligations: MsaCustomerObligationsFactory,
    Guarantees: MsaGuarantees,
    PaymentEscalation: PaymentEscalationForm,
    OtherTermsConditions: MsaTermsConditions,
};
