import React, { FC, ReactNode } from 'react';
import { TextField, TextFieldProps } from '../TextField/TextField';
import { SelectProps as MaterialSelectProps } from '@material-ui/core/Select';
import { InputLabelProps as MaterialInputLabelProps } from '@material-ui/core/InputLabel';
import { IFormOption } from '../../data/utils/form';

export const SelectField: FC<SelectFieldProps> =
    props => {
        const {
            placeholder,
            options,
            SelectProps,
            InputLabelProps,
            ...remainingTextFieldProps
        } = props;

        const selectOptions = getSelectOptions(options);
        const fullSelectProps: MaterialSelectProps = { ...SelectProps, native: true };
        const fullInputLabelProps: MaterialInputLabelProps = { shrink: true, ...InputLabelProps };

        return (
            <TextField
                {...remainingTextFieldProps}
                select
                SelectProps={fullSelectProps}
                InputLabelProps={fullInputLabelProps}
            >
                {placeholder
                    ? <option value=''>{placeholder}</option>
                    : null
                }
                {selectOptions}
            </TextField>
        )
    };

export type SelectFieldProps = MapWithSelectOptionsOnUnionType<TextFieldProps>;

type MapWithSelectOptionsOnUnionType<U> = U extends TextFieldProps ? WithSelectOptions<U> : never;

type WithSelectOptions<T> = Omit<T, 'children' | 'select' | 'SelectProps'> & {
    options: IFormOption[],
    SelectProps?: ManagedSelectProps,
}

type ManagedSelectProps = Omit<MaterialSelectProps, 'native'>;

function getSelectOptions(options: IFormOption[]): ReactNode {
    return options.map(opt =>
        <option key={opt.value} value={opt.value}>{opt.label}</option>
    )
}
