import React, { FC } from 'react';
import { Grid, LinearProgress, StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import { Back } from './Back';
import { Proceed } from './Proceed';
import { Cancel } from './Cancel';
import { Next } from './Next';
import { Close } from './Close';

const ActualizationFormButtons0: FC<ActualizationFormButtonsProps & StyledComponentProps> =
    props =>
        <div className={props.classes.container}>
            <Grid container justify='space-between'>
                <Grid item xs={8}>
                    {props.onCancel && <Cancel {...props}/>}
                    {props.onBack && <Back {...props}/>}
                    {props.onProceed && <Proceed {...props}/>}
                    {props.onNext && <Next {...props}/>}
                    {props.onClose && <Close {...props}/>}
                </Grid>
                {props.loading
                    ? <Grid item xs={12}>
                        <LinearProgress className={props.classes.progressBar}/>
                    </Grid>
                    : null
                }
            </Grid>
        </div>
;

export interface ActualizationFormButtonsProps {
    loading?: boolean,
    disabled?: boolean,
    onCancel?: () => void,
    onBack?: () => void,
    onProceed?: () => void,
    onNext?: () => void,
    onClose?: () => void,
}

const styles: StyleRulesCallback = theme => ({
    button: {
        marginRight: theme.spacing.unit * 2,
    },
    container: {
        marginBottom: theme.spacing.unit * 3,
        marginTop: theme.spacing.unit * 6,
        paddingTop: theme.spacing.unit * 3,
        borderTop: '2px solid grey',
    },
    progressBar: {
        marginTop: theme.spacing.unit,
    },
});

export const ActualizationFormButtons = withStyles(styles)(ActualizationFormButtons0);
