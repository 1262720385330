import React from 'react';
import BaseMaterialTable, {MaterialTableProps} from 'material-table';
import {createTableTestProps} from '../Utils/testProps';
import MTableDataRow from "./MTableDataRow";
import MTableDataCell from "./MTableDataCell";
import MTableEditRow from "./MTableEditRow";
import { hasValue } from "../../data/utils/form";
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';

interface ITableProps extends MaterialTableProps {
    tableTestName: string,
    rowTestName: string,
    addRowTestName?: string,
    editRowTestName?: string,
}

const MaterialTable: React.FunctionComponent<ITableProps> = props => {
    const {
        tableTestName,
        rowTestName,
        addRowTestName,
        editRowTestName,
        components,
        editable,
        ...remainingTableRowProps
    } = props;

    const componentsWithRowAndCell = {
        Row: getRowComponentWithTestName(rowTestName),
        Cell: getCellComponent(),
        EditRow: getEditRowComponentWithTestName(addRowTestName, editRowTestName),
        ...components,
    };

    return (
        <div {...createTableTestProps(tableTestName)}>
            <CaseAuthorizedToEditForm>
                {
                    authorizedToEdit => {
                        const authorizedEditableProp = getAuthorizedEditableProp(authorizedToEdit, editable);

                        return (
                            <BaseMaterialTable
                                components={componentsWithRowAndCell}
                                editable={authorizedEditableProp}
                                {...remainingTableRowProps}
                            />
                        );
                    }
                }
            </CaseAuthorizedToEditForm>
        </div>
    );
};
export default MaterialTable;

function getRowComponentWithTestName(rowTestName) {
    return props =>
        <MTableDataRow
            testName={rowTestName}
            testId={props.index.toString()}
            {...props}
        />;
}

function getCellComponent() {
    return props =>
        <MTableDataCell
            testName={props.columnDef.field}
            {...props}
        />;
}

function getEditRowComponentWithTestName(addRowTestName, editRowTestName) {
    return props => {
        const testName = props.mode === 'add' ? addRowTestName : editRowTestName;
        const testId = hasValue(props.index)
            ? props.index.toString()
            : null;
        return <MTableEditRow testName={testName} testId={testId} {...props} />;
    };
}

function getAuthorizedEditableProp(authorizedToEdit, editable) {
    const authorizedEditableProp = authorizedToEdit
        ? editable
        : null;
    return authorizedEditableProp;
}
