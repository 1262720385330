import { green, orange, red } from '@material-ui/core/colors';
import { createMuiTheme, } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        contrastThreshold: 3,
        default: '#f7f7f7',
        test: '#333',
        error: red,
        primary: green,
        secondary: orange,
        tonalOffset: 0.2,
    },
    typography: {
        h4: {
            fontWeight: 500,
        },
        useNextVariants: true,
    }
} as any);
