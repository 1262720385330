import React, { FC } from 'react';
import { Dialog } from '../Dialogs/Dialog';
import {
    ActivityReplacement,
    MigrateStandardScopeDialogContentProps,
    MigrateStandardScopeDialogForm,
} from './MigrateStandardScopeDialogForm';
import { isEmpty } from 'lodash';
import { ReplaceAction } from './migration-actions';

export const MigrateStandardScopeDialog: FC<MigrateStandardScopeDialogProps> =
    ({ open, ...remainingProps }) => {
        const canSeamlesslyMigrate =
            isEmpty(remainingProps.removed) && isEmpty(remainingProps.replaced);

        if (canSeamlesslyMigrate) {
            const seamlessReplacementActions = getReplacementActions(remainingProps.migrated);
            setTimeout(() => {
                remainingProps.onSubmit(seamlessReplacementActions);
            }, 0);

            return null;
        }

        return (
            <Dialog
                testName='migrateStandardScope'
                maxWidth='lg'
                open={open}
            >
                <MigrateStandardScopeDialogForm {...remainingProps}/>
            </Dialog>
        );
    }
;

export interface MigrateStandardScopeDialogProps extends MigrateStandardScopeDialogContentProps {
    open: boolean,
}

function getReplacementActions(migrated: ActivityReplacement[]): ReplaceAction[] {
    return migrated.map(replacement => {
        return {
            type: 'REPLACE',
            key: replacement.oldScope.key,
            replacement: replacement.replacementOptions[0],
        };
    });
}

