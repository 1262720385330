import { CatalogType, getDefaultCatalog as getDefaultCatalogFromApi } from '../../api/catalog-service/catalog-overview';
import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';

export const getDefaultCatalog = (type: CatalogType): AppThunk => dispatch => {
    dispatch(startLoading(type));
    return getDefaultCatalogFromApi(type)
        .then(catalog => {
            dispatch(successResponse(type, catalog));
        })
        .catch(err => {
            dispatch(errorResponse(type, err));
        });
};
