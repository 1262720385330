import React from 'react';
import MaterialTable, { TableProps as MaterialTableProps } from '@material-ui/core/Table';
import { createTableTestProps } from '../Utils/testProps';

interface ITableProps extends MaterialTableProps {
    testName: string,
}

export const Table: React.FunctionComponent<ITableProps> =
    ({ testName, ...remainingTableProps }) =>
        <MaterialTable
            {...remainingTableProps}
            {...createTableTestProps(testName)}
        />
    ;

export default Table;
