import * as React from 'react';
import {RefObject} from 'react';
import './CreateAmendmentDialog.scss';

import Button from '../../Buttons/Button';
import Dialog from '../../Dialogs/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FFInput} from '../../Form/input';
import {IFormModel,} from '../../../data/model/model';
import ValidatorForm from '../../Form/ValidatorForm';
import {DialogActions, Fade, withStyles,} from '@material-ui/core';
import {connect} from 'react-redux';
import {amendModel,} from '../../../data/actions/models';

import classNames from 'classnames';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: any) => ({
    dialogContent: {
        backgroundColor: '#f5f5f5',
    },
    dialogButton: {
        marginLeft: '20px',
    },
    spincontainer: {
        margin: '5px',
    },
});

interface CreateAmendmentDialogState {
    open: boolean;
    disabled: boolean;
    loading: boolean;
    amendmentNumber: number|string;
}

class CreateAmendmentDialog extends React.Component<any, CreateAmendmentDialogState> {
    public form: RefObject<any>;
    public state: CreateAmendmentDialogState = {
        open: false,
        disabled: true,
        loading: false,
        amendmentNumber: null,
    };

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    public validate(): Promise<boolean> {
        if (this.state.open) {
            return this.form.current.isFormValid(false);
        }

        return Promise.reject(false);
    }

    public validatorListener(): void {
        this.form.current
            .isFormValid()
            .then(valid => this.setState({disabled: !valid}));
    }

    public handleClickOpen(): void {
        if (this.props.onClick) {
            this.props.onClick();
        }
        this.setState({
            open: true,
        });
    }

    public handleClose(): void {
        this.setState({open: false});

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    public handleSave(): void {
        this.validate().then(isValid => {
            const {
                activeModel,
                amendModel
            } = this.props;

            if (!isValid) {
                return;
            }

            amendModel(activeModel, this.state.amendmentNumber).then((res) => {
                if (res.type !== 'ERROR_MODEL') {
                    this.handleClose();
                }
            });
        });
    }

    public render(): React.ReactElement {
        const {classes} = this.props;


        const openDialogBtn = this.getOpenDialogBtn();

        return (
            <React.Fragment>
                {openDialogBtn}

                {/* </Tooltip> */}
                <Dialog
                    testName="model"
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    aria-labelledby={this.props.title}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">
                        {this.props.title}
                    </DialogTitle>
                    <DialogContent classes={{root: 'dialogContent'}}>
                        <ValidatorForm
                            testName="model"
                            ref={this.form}
                            className={classes.container}
                            instantValidate
                            onSubmit={() => {
                                // empty
                            }}
                            autoComplete="off"
                        >
                            <FFInput
                                id="amendmentNumber"
                                name="amendmentNumber"
                                label="Amendment Number"
                                validatorListener={() =>
                                    this.validatorListener()
                                }
                                value={this.state.amendmentNumber}

                                validators={[
                                    'matchRegexp:^\\d{7}$'
                                ]}
                                errorMessages={[
                                    'Amendment Number # must 7 digits',
                                ]}

                                onChange={(e) => this.setState({amendmentNumber: e.target.value})}

                                required={true}
                                placeholder=""
                                fullWidth
                                margin="dense"
                                type="text"
                            />
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {this.state.loading ? (
                            <React.Fragment>
                                <div className={classes.spincontainer}>
                                    <Fade
                                        in={true}
                                        style={{
                                            transitionDelay: '100ms',
                                        }}
                                        unmountOnExit
                                    >
                                        <CircularProgress/>
                                    </Fade>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button
                                    testName="cancel"
                                    onClick={() => this.handleClose()}
                                    color="default"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    testName="save"
                                    onClick={() => this.handleSave()}
                                    color="primary"
                                >
                                    Create Amendment
                                </Button>
                            </React.Fragment>
                        )}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    private getOpenDialogBtn(): React.ReactNode {
        const {classes} = this.props;

        return (
            <Button
                testName={"amendModel"}
                className={classNames(
                    classes.dialogButton,
                    'modalDialogTitle'
                )}
                color="primary"
                onClick={() => this.handleClickOpen()}
                disabled={this.props.disabled}
            >
                {this.props.icon ? this.props.icon : null}&nbsp;
                {this.props.hideTitle && this.props.icon
                    ? null
                    : this.props.title}
            </Button>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeModel: state.models.activeModel,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        amendModel: (model: IFormModel, amendmenNumber: Number) => {
            return dispatch(amendModel(model, amendmenNumber));
        }
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CreateAmendmentDialog)
);
