import React, { FC } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { GuaranteeEditForm } from './GuaranteeEditForm';
import { GuaranteeEditDialogProps } from './GuaranteesInterfaces';
import { DialogTitle, StyleRulesCallback, withStyles, StyledComponentProps } from '@material-ui/core';
import { Dialog } from '../Dialogs/Dialog';

const styles: StyleRulesCallback = () => ({
    dialogContent: {
        backgroundColor: '#f5f5f5',
        paddingTop: 20,
    },
    lowerText: {
        marginTop: 20,
    }
});

const GuaranteeEditDialog0: FC<GuaranteeEditDialogProps & StyledComponentProps> =
    (props) =>
        <Dialog
            testName='newGuarantee'
            open={props.open}
        >
            <DialogTitle>
                {props.isNewGuarantee? 'Add Guarantee' : 'Edit Guarantee'}
            </DialogTitle>
            <DialogContent classes={{ root: props.classes.dialogContent }}>
                <GuaranteeEditForm
                    ldBonusUnitOptions={props.ldBonusUnitOptions}
                    guaranteeTypeOptions={props.guaranteeTypeOptions}
                    guaranteeUnitOptions={props.guaranteeUnitOptions}
                    guarantee={props.guarantee}
                    close={props.close}
                    onSubmit={guarantee => {
                        props.onSubmit(guarantee);
                        return Promise.resolve();
                    }}
                />
            </DialogContent>
        </Dialog>;

export const GuaranteeEditDialog = withStyles(styles)(GuaranteeEditDialog0);