import React, { FC } from 'react';
import { Grid, LinearProgress, StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import { AuthorizedToEditForm } from '../Form/AuthorizedToEditForm';
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';
import { Back } from './Back';
import { Save } from './Save';
import { Next } from './Next';
import { PrimaryComplete } from './PrimaryComplete';
import { View } from './View';
import { SecondaryComplete } from './SecondaryComplete';
import { ReadOnlyNext } from './ReadOnlyNext';

const ConfigurationFormButtons0: FC<ConfigurationFormButtonsProps & StyledComponentProps> =
    props =>
        <div className={props.classes.container}>
            <Grid container justify='space-between'>
                <Grid item xs={8}>
                    {props.onBack && <Back {...props}/>}

                    <CaseAuthorizedToEditForm>
                        {authorizedToEdit => authorizedToEdit && !props.disabled
                            ? <>
                                {props.onSave && <Save {...props}/>}
                                {props.onNext && <Next {...props}/>}
                                {props.onPrimaryComplete && <PrimaryComplete {...props}/>}
                            </>
                            : <>
                                {props.onReadOnlyNext && <ReadOnlyNext {...props}/>}
                                {props.viewConfigurationUrl && <View {...props}/>}
                            </>
                        }
                    </CaseAuthorizedToEditForm>
                </Grid>

                {props.onSecondaryComplete && !props.disabled
                    ? <AuthorizedToEditForm>
                        <Grid item xs={4} container justify='flex-end'>
                            <Grid item>
                                <SecondaryComplete {...props}/>
                            </Grid>
                        </Grid>
                    </AuthorizedToEditForm>
                    : null
                }

                {props.loading
                    ? <Grid item xs={12}>
                        <LinearProgress className={props.classes.progressBar}/>
                    </Grid>
                    : null
                }
            </Grid>
        </div>
;

export interface ConfigurationFormButtonsProps {
    disabled?: boolean,
    loading?: boolean,
    viewConfigurationUrl?: string,
    onBack?: () => void,
    onSave?: () => void,
    onNext?: () => void,
    onReadOnlyNext?: () => void,
    onPrimaryComplete?: () => void,
    onSecondaryComplete?: () => void,
}

const styles: StyleRulesCallback = theme => ({
    button: {
        marginRight: theme.spacing.unit * 2,
    },
    container: {
        marginBottom: theme.spacing.unit * 3,
        marginTop: theme.spacing.unit * 6,
        paddingTop: theme.spacing.unit * 3,
        borderTop: '2px solid grey',
    },
    progressBar: {
        marginTop: theme.spacing.unit,
    },
});

export const ConfigurationFormButtons = withStyles(styles)(ConfigurationFormButtons0);
