import React, { FC } from 'react'
import { createFormTestProps } from '../Utils/testProps';

export const Form: FC<FormProps> =
    ({ testName, ...formProps }) => {
        const testProps = createFormTestProps(testName);
        return <form {...testProps} {...formProps} />
    };

type FormProps = JSX.IntrinsicElements['form'] & {
    testName: string,
};
