import React from 'react';
import MaterialIconButton, { IconButtonProps as MaterialIconButtonProps } from '@material-ui/core/IconButton';
import { createButtonTestProps } from '../Utils/testProps';

interface IIconButtonProps extends MaterialIconButtonProps {
    testName: string,
}

export const IconButton: React.FunctionComponent<IIconButtonProps> = ({ testName, ...materialIconButtonProps }) =>
    <MaterialIconButton
        {...materialIconButtonProps}
        {...createButtonTestProps(testName)} />
    ;

export default IconButton;
