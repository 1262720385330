import React, { FC } from 'react';
import { TableHead, TableRow } from '@material-ui/core';
import { StyledTableHeaderCell } from './TableCell';

export const TableHeader: FC =
    () =>
        <TableHead>
            <TableRow>
                <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                <StyledTableHeaderCell>Uploaded On</StyledTableHeaderCell>
                <StyledTableHeaderCell align="right">
                    Uploaded By
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align="right">
                    Availability
                </StyledTableHeaderCell>
            </TableRow>
        </TableHead>;

