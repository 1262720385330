import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { hasValue, IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { ContractCategory } from '../../api/model-service/model-service';

export const ContractCategoryInput: FC<ContractCategoryProps> =
    ({ formikProps, container, savedContractCategoryOptions, disabled }) => {
        const isSavedConfiguration = hasValue(formikProps.values.id);
        const options = isSavedConfiguration ? savedContractCategoryOptions : allContractCategoryOptions;

        const name = 'contractCategory';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Contract Category'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

interface ContractCategoryProps extends FieldProps {
    savedContractCategoryOptions: IFormOption[],
}

export const allContractCategoryOptions: Array<IFormOption<ContractCategory>> = [
    formOption('CSA_PREVENTIVE', 'CSA Preventive'),
    formOption('CSA_PREVENTIVE_AND_CORRECTIVE', 'CSA Preventive & Corrective'),
    formOption('MSA_PREVENTIVE_AND_CORRECTIVE', 'MSA Preventive & Corrective'),
    formOption('MSA_PREVENTIVE', 'MSA Preventive'),
];
