import React, { FC } from 'react';
import { ExtendedActualizationResult } from './ResultsTable';
import Table from '../Tables/Table';
import { ComparisonTableHeader } from './ComparisonTableHeader';
import { StyledComponentProps, StyleRulesCallback, TableBody, withStyles } from '@material-ui/core';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { ActivityCatalog } from '../../api/catalog-service/activityCatalogs';
import { ActualizationCandidate } from '../../api/model-service/actualization';
import { yearMonthLabel } from '../../utils/yearMonthLabel';
import { formatCurrencyWithZero, formatNumber, formatPercentage } from '../../data/utils/number';

const ComparisonTable: FC<ComponentProps> = props => {
    const { actualizationResult, activityCatalogs, regionCatalogs } = props;
    return (
        <Table testName='comparisonTable'>
            <ComparisonTableHeader/>
            <TableBody>
                <TableDataRow testName='comparisonOriginal' testId={'' + actualizationResult.id}>
                    <TableDataCell testName='type'>Original</TableDataCell>
                    <TableDataCell testName='actualizationPeriod'>
                        {formatActualizationPeriod(actualizationResult.candidate)}
                    </TableDataCell>
                    <TableDataCell testName='activityCatalogName'>
                        {formatActivityCatalogName(actualizationResult.candidate.activityCatalogId, activityCatalogs)}
                    </TableDataCell>
                    <TableDataCell testName='regionCatalogName'>
                        {formatRegionCatalogName(actualizationResult.candidate.catalogRegionId, regionCatalogs)}
                    </TableDataCell>
                    <TableDataCell testName='expectedOperatingHoursPerYear'>
                        {formatNumber(actualizationResult.candidate.expectedOperatingHoursPerYear, 0, 0)}
                    </TableDataCell>
                    <TableDataCell testName='cost'>
                        {formatCurrencyWithZero('EUR', actualizationResult.candidate.cost)}
                    </TableDataCell>
                    <TableDataCell testName='billings'>
                        {formatCurrencyWithZero('EUR', actualizationResult.candidate.billings)}
                    </TableDataCell>
                </TableDataRow>
                <TableDataRow testName='comparisonClone' testId={'' + actualizationResult.id}>
                    <TableDataCell testName='type'>Clone</TableDataCell>
                    <TableDataCell testName='actualizationPeriod'>
                        {formatActualizationPeriod(actualizationResult.clone)}
                    </TableDataCell>
                    <TableDataCell testName='activityCatalogName'>
                        {formatActivityCatalogName(actualizationResult.clone.activityCatalogId, activityCatalogs)}
                    </TableDataCell>
                    <TableDataCell testName='regionCatalogName'>
                        {formatRegionCatalogName(actualizationResult.clone.catalogRegionId, regionCatalogs)}
                    </TableDataCell>
                    <TableDataCell testName='expectedOperatingHoursPerYear'>
                        {formatNumber(actualizationResult.clone.expectedOperatingHoursPerYear, 0, 0)}
                    </TableDataCell>
                    <TableDataCell testName='cost'>
                        {formatCurrencyWithZero('EUR', actualizationResult.clone.cost)}
                    </TableDataCell>
                    <TableDataCell testName='billings'>
                        {formatCurrencyWithZero('EUR', actualizationResult.clone.billings)}
                    </TableDataCell>
                </TableDataRow>
                <TableDataRow testName='comparisonDelta' testId={'' + actualizationResult.id}>
                    <TableDataCell testName='type' colSpan={4}>Delta</TableDataCell>
                    <TableDataCell testName='expectedOperatingHoursPerYear'>
                        {formatNumber(actualizationResult.delta.expectedOperatingHoursPerYear, 0, 0)}
                    </TableDataCell>
                    <TableDataCell testName='cost'>
                        {formatCurrencyWithZero('EUR', actualizationResult.delta.cost)}
                    </TableDataCell>
                    <TableDataCell testName='billings'>
                        {formatCurrencyWithZero('EUR', actualizationResult.delta.billings)}
                    </TableDataCell>
                </TableDataRow>
                <TableDataRow testName='comparisonDeltaPercentage' testId={'' + actualizationResult.id}>
                    <TableDataCell testName='type' classes={{ root: props.classes.lastRowCell }} colSpan={5}>
                        Delta (%)
                    </TableDataCell>
                    <TableDataCell
                        testName='cost'
                        classes={{ root: props.classes.lastRowCell }}
                    >
                        {formatPercentage(actualizationResult.delta.costPercentage, 2, 2)}
                    </TableDataCell>
                    <TableDataCell
                        testName='billings'
                        classes={{ root: props.classes.lastRowCell }}
                    >
                        {formatPercentage(actualizationResult.delta.billingsPercentage, 2, 2)}
                    </TableDataCell>
                </TableDataRow>
            </TableBody>
        </Table>
    )
};

const formatActivityCatalogName = (activityCatalogId: number, activityCatalogs: ActivityCatalog[]): string => {
    const catalog = activityCatalogs.find(cat => cat.id === activityCatalogId);

    return catalog ? catalog.name : null;
};

const formatRegionCatalogName = (regionCatalogId: number, regionCatalogs: RegionCatalog[]): string => {
    const catalog = regionCatalogs.find(
        cat => cat.regions.find(reg => reg.id === regionCatalogId));

    return catalog ? catalog.name : null;
};

function formatActualizationPeriod(candidate: ActualizationCandidate): string {
    const { actualizationPeriod } = candidate;
    return actualizationPeriod
        ? yearMonthLabel(actualizationPeriod)
        : null;
}

const style: StyleRulesCallback = theme => ({
    lastRowCell: {
        borderBottom: 'none',
    },
});

type ComponentProps = ComparisonTableProps & StyledComponentProps;

interface ComparisonTableProps {
    actualizationResult: ExtendedActualizationResult,
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalog[],
}

export default withStyles(style)(ComparisonTable);
