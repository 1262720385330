import React from 'react';
import MaterialTableRow, { TableRowProps as MaterialTableRowProps } from '@material-ui/core/TableRow';
import { createRowTestProps } from '../Utils/testProps';

interface ITableDataRowProps extends MaterialTableRowProps {
    testName: string,
    testId: string,
}

export const TableDataRow: React.FunctionComponent<ITableDataRowProps> =
    ({ testName, testId, ...remainingTableRowProps }) =>
        <MaterialTableRow
            {...remainingTableRowProps}
            {...createRowTestProps(testName, testId)}
        />
    ;

export default TableDataRow;
