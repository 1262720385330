import { createAction, createReducer } from '@reduxjs/toolkit';
import { CatalogOverview, CatalogType } from '../../api/catalog-service/catalog-overview';

interface DefaultCatalog {
    loading: boolean,
    error: string,
    data: CatalogOverview,
}

export type DefaultCatalogState = Record<CatalogType, DefaultCatalog>;

export const startLoading = createAction(
    'defaultCatalog/startLoading',
    (type: CatalogType) => ({
        payload: { type },
    }),
);

export const errorResponse = createAction(
    'defaultCatalog/errorResponse',
    (type: CatalogType, error: Error) => ({
        payload: { type, error },
    }),
);

export const successResponse = createAction(
    'defaultCatalog/successResponse',
    (type: CatalogType, data: CatalogOverview) => ({
        payload: { type, data },
    }),
);

const initialDefaultCatalog: DefaultCatalog = {
    loading: false,
    error: null,
    data: null,
};
const initialState: DefaultCatalogState = {
    ACTIVITY: initialDefaultCatalog,
    REGION: initialDefaultCatalog,
    EQUIPMENT: initialDefaultCatalog,
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, (state, action) => {
            const type = action.payload.type;
            const newDefaultCatalog: DefaultCatalog = {
                ...state[type],
                loading: true,
            };
            return {
                ...state,
                [type]: newDefaultCatalog,
            };
        })
        .addCase(errorResponse, (state, action) => {
            const type = action.payload.type;
            const newDefaultCatalog: DefaultCatalog = {
                ...state[type],
                loading: false,
                error: action.payload.error.message,
            };
            return {
                ...state,
                [type]: newDefaultCatalog,
            };
        })
        .addCase(successResponse, (state, action) => {
            const type = action.payload.type;
            const newDefaultCatalog: DefaultCatalog = {
                loading: false,
                error: null,
                data: action.payload.data,
            };
            return {
                ...state,
                [type]: newDefaultCatalog,
            };
        }),
);
export default reducer;

