import React, { Component, MouseEventHandler, ReactNode } from 'react';
import { download } from '../../data/api';

export class DownloadLink extends Component<DownloadLinkProps, State> {

    public state = {
        loading: false
    };

    private setLoadingFlagTimer;

    public render(): ReactNode {
        const clickHandler = evt => this.handleClick(evt);
        const loading = this.isLoadingFlagSet();
        return this.props.children(clickHandler, loading);
    }

    private handleClick(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        evt.preventDefault();

        this.startLoading();

        const url = this.props.href;
        download(url)
            .then(file => {
                const temporaryFileUrl = URL.createObjectURL(file);
                const temporaryAnchor = document.createElement('a');
                temporaryAnchor.href = temporaryFileUrl;
                temporaryAnchor.download = file.name;
                temporaryAnchor.click();
                URL.revokeObjectURL(temporaryFileUrl);

                this.stopLoading();
            })
            .catch(() => {
                this.stopLoading();
            });
    }

    private startLoading(): void {
        if (this.isLoadingScheduled() || this.isLoadingFlagSet()) {
            return;
        }

        this.startSetLoadingFlagTimer();
    }

    private stopLoading(): void {
        if (this.isLoadingFlagSet()) {
            this.setLoadingFlag(false);
        }

        if (this.isLoadingScheduled()) {
            this.cancelSetLoadingFlagTimer();
        }
    }

    private isLoadingScheduled(): boolean {
        return !!this.setLoadingFlagTimer;
    }

    private startSetLoadingFlagTimer(): void {
        this.setLoadingFlagTimer = setTimeout(() => {
            this.setLoadingFlag(true);
            this.clearSetLoadingFlagTimer();
        }, 500);
    }

    private cancelSetLoadingFlagTimer(): void {
        window.clearTimeout(this.setLoadingFlagTimer);
        this.clearSetLoadingFlagTimer();
    }

    private clearSetLoadingFlagTimer() {
        this.setLoadingFlagTimer = null;
    }

    private isLoadingFlagSet(): boolean {
        return this.state.loading;
    }

    private setLoadingFlag(loading: boolean) {
        this.setState({ loading });
    }
}

export interface DownloadLinkProps {
    href: string,
    children: (clickHandler: MouseEventHandler, loading: boolean) => ReactNode,
}

interface State {
    loading: boolean,
}
