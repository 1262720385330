import React, { FunctionComponent } from 'react';
import MaterialMenu, { MenuProps as MaterialMenuProps } from '@material-ui/core/Menu';
import { createMenuTestProps } from '../Utils/testProps';

interface IMenuProps extends MaterialMenuProps {
    testName: string,
    testId: string,
}

export const Menu: FunctionComponent<IMenuProps> =
    ({ testName, testId, ...remainingMenuProps }) =>
        <MaterialMenu
            {...createMenuTestProps(testName, testId)}
            {...remainingMenuProps}
        />;
