import React, { Component, ReactNode } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { ActualizationFormButtons } from '../ActualizationFormButtons/ActualizationFormButtons';
import { Actualization, ActualizationCandidate, ActualizationPayload } from '../../api/model-service/actualization';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { ActivityCatalog } from '../../api/catalog-service/activityCatalogs';
import { State } from '../../data/store';
import { connect } from 'react-redux';
import { Form } from '../Form/Form';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { ObjectSchema } from '../../utils/validators';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputError } from '../../utils/inputError';
import { getActiveRegionCatalogs } from '../../state/regionCatalogs/getRegionCatalogs';
import { getActiveActivityCatalogs } from '../../state/activityCatalogs/getActivityCatalogs';
import { TextField } from '../TextField/TextField';
import { getSchema } from './schema';
import { CatalogOverview } from '../../api/catalog-service/catalog-overview';
import SkipOrMaintainCandiatesDialog from './SkipOrMaintainCandiatesDialog';
import { createActualization } from '../../state/actualization/createActualization';
import { AppThunk } from '../../state/app-thunk';

export class ActualizationParametersForm extends Component<ComponentProps, ActializationParametersFormState> {
    constructor(props) {
        super(props);
        this.state = { 
            isSkipOrMaintainCandidatesDialogOpen: false,
            actions: null,
            payload: null,
            skippableCandidates: [],
        }
    }

    public componentDidMount() {
        this.props.onShowActivityCatalogs();
        this.props.onShowRegionCatalogs();
    }

    public render(): ReactNode {
        const { loading, activityCatalogs, regionCatalogs } = this.props;

        const schema = getSchema();
        const activityOptions: IFormOption[] = activityCatalogs.map(catalog =>
            formOption('' + catalog.id, catalog.name));

        const regionsOptions: IFormOption[] = regionCatalogs.map(catalog =>
            formOption('' + catalog.id, catalog.name));

        const initialValues = this.getInitialValues();
        const inputDisabled = this.isInputDisabled();

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(payload, actions) => this.handleSubmit(payload, actions, schema)}
                enableReinitialize
            >
                {(formikProps: FormikProps<ActualizationPayload>) => {
                    return (
                        <Form testName='actualizationParameters'>
                            <SkipOrMaintainCandiatesDialog
                                skippableCandidates={this.state.skippableCandidates}
                                open={this.state.isSkipOrMaintainCandidatesDialogOpen}
                                payload={this.state.payload}
                                onSubmit={(payload, actions) => { this.submit(payload, actions) }}
                                onCancel={() => { this.onCancelSkipCandidates(formikProps)}}
                                actions={this.state.actions}
                            />

                            <Grid container spacing={24}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <SelectField
                                        name={'activityCatalogId'}
                                        label='Activity Catalog Version'
                                        placeholder='Select Activity Catalog'
                                        value={inputValue('activityCatalogId', formikProps)}
                                        options={activityOptions}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={!!inputError('activityCatalog', formikProps)}
                                        helperText={inputError('activityCatalog', formikProps)}
                                        disabled={inputDisabled}
                                        margin='dense'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <SelectField
                                        name={'regionCatalogId'}
                                        label='Region Catalog Version'
                                        placeholder='Select Region Catalog'
                                        value={inputValue('regionCatalogId', formikProps)}
                                        options={regionsOptions}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={!!inputError('regionCatalogId', formikProps)}
                                        helperText={inputError('regionCatalogId', formikProps)}
                                        disabled={inputDisabled}
                                        margin='dense'
                                        fullWidth
                                        required    
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField
                                        name={'escalationRate'}
                                        label='Escalation Rate'
                                        type='number'
                                        value={inputValue('escalationRate', formikProps)}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={!!inputError('escalationRate', formikProps)}
                                        helperText={inputError('escalationRate', formikProps)}
                                        disabled={inputDisabled}
                                        margin='dense'
                                        fullWidth
                                        required
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                        }}
                                        inputProps={{
                                            min: '-10',
                                            max: '10',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField
                                        name={'actualizationPeriod'}
                                        label='Actualization Period'
                                        type='month'
                                        value={inputValue('actualizationPeriod', formikProps)}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        error={!!inputError('actualizationPeriod', formikProps)}
                                        helperText={inputError('actualizationPeriod', formikProps)}
                                        disabled={inputDisabled}
                                        margin='dense'
                                        fullWidth
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            max: this.getMaxPeriod(),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <ActualizationFormButtons
                                loading={loading || formikProps.isSubmitting}
                                disabled={loading || formikProps.isSubmitting}
                                onCancel={this.isShowingExistingActualization()
                                    ? null
                                    : this.props.onCancel
                                }
                                onBack={this.isShowingExistingActualization()
                                    ? null
                                    : this.props.onBack
                                }
                                onNext={this.isShowingExistingActualization()
                                    ? this.props.onNext
                                    : null
                                }
                                onProceed={this.isShowingExistingActualization()
                                    ? null
                                    : () => formikProps.submitForm()
                                }
                            />
                        </Form>
                    );
                }}   
            </Formik>
        )
    }

    private getInitialValues(): ActualizationPayload {
        const candidates = this.props.candidates;

        if (this.isShowingExistingActualization()) {
            const actualization = this.props.actualization;
            return {
                candidates,
                activityCatalogId: actualization.activityCatalogId,
                regionCatalogId: actualization.regionCatalogId,
                actualizationPeriod: actualization.actualizationPeriod,
                escalationRate: actualization.escalationRate,
            };
        }

        return {
            candidates,
            activityCatalogId: this.getDefaultCatalogId(this.props.activityCatalogs),
            regionCatalogId: this.getDefaultCatalogId(this.props.regionCatalogs),
            actualizationPeriod: null,
            escalationRate: 0,
        };
    }

    private isInputDisabled(): boolean {
        return this.props.loading || this.isShowingExistingActualization();
    }

    private isShowingExistingActualization(): boolean {
        return !!this.props.actualization;
    }

    private getDefaultCatalogId(catalogs: CatalogOverview[]): number {
        const defaultCatalog = (catalogs || []).find(catalog => catalog.state === 'DEFAULT');
        return defaultCatalog ? defaultCatalog.id : null;
    }
    
    private onCancelSkipCandidates(formikProps: FormikProps<ActualizationPayload>) {
        formikProps.setSubmitting(false);
        this.setState({ isSkipOrMaintainCandidatesDialogOpen: false })
    }
    private getMaxPeriod(): string {
        const maxActualizationPeriod = new Date();
        maxActualizationPeriod.setMonth(maxActualizationPeriod.getMonth() - 1);
        return maxActualizationPeriod.toISOString().substring(0, 7);
    }

    private handleSubmit(actualizationPayload: ActualizationPayload,
                            actions: FormikActions<ActualizationPayload>,
                            schema: ObjectSchema<ActualizationPayload>): void {
        const payload = schema.cast(actualizationPayload) as ActualizationPayload;
    
        const skippableCandidates = payload.candidates
            .filter(candidate => candidate.actualizationPeriod === payload.actualizationPeriod);
    
        if (!skippableCandidates.length) {
            this.submit(payload, actions)
        } else {
            this.setState({ 
                isSkipOrMaintainCandidatesDialogOpen: true,
                payload,
                actions,
                skippableCandidates,
            });
        }
    }

    private submit(actualizationPayload: ActualizationPayload, actions: FormikActions<ActualizationPayload>) {
        this.setState({ isSkipOrMaintainCandidatesDialogOpen: false });

        this.props.onCreateActualization(actualizationPayload)
            .then(this.props.onProceed)
            .finally(() => {
                actions.setSubmitting(false);
            });
    }
}

const mapDispatchToProps: ActionProps = {
    onShowRegionCatalogs: getActiveRegionCatalogs,
    onShowActivityCatalogs: getActiveActivityCatalogs,
    onCreateActualization: createActualization,
};

function mapStateToProps(state: State): StateProps {
    const candidateState = state.REFACTORED.actualizationCandidates;
    const regionCatalogs = state.REFACTORED.regionCatalogs;
    const activityCatalogs = state.REFACTORED.activityCatalogs;
    const actualizationState = state.REFACTORED.actualizationResult;

    return {
        loading: candidateState.loading ||
            regionCatalogs.loading ||
            activityCatalogs.loading ||
            actualizationState.loading,
        regionCatalogs: regionCatalogs.data,
        activityCatalogs: activityCatalogs.data,
        candidates: candidateState.data,
        actualization: actualizationState.data,
    };
}

export interface ActualizationParametersFormProps {
    onCancel: () => void,
    onProceed: () => void,
    onNext: () => void,
    onBack: () => void,
    onCreateActualization: (payload: ActualizationPayload) => Promise<void>,
}

interface StateProps {
    loading: boolean,
    candidates: ActualizationCandidate[],
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalog[],
    actualization: Actualization,
}

interface ActionProps {
    onShowRegionCatalogs: () => void,
    onShowActivityCatalogs: () => void,
    onCreateActualization: (payload: ActualizationPayload) => AppThunk,
}

interface ActializationParametersFormState {
    skippableCandidates: ActualizationCandidate[],
    isSkipOrMaintainCandidatesDialogOpen: boolean,
    payload: ActualizationPayload,
    actions: FormikActions<ActualizationPayload>,
}

type ComponentProps = ActualizationParametersFormProps & StateProps & Omit<ActionProps, 'onCreateActualization'>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ActualizationParametersForm);
