import React, { FunctionComponent, ReactNode } from 'react';
import { Authority } from '../../data/model/user';
import { CaseAuthorized } from './CaseAuthorized';

export const CaseAuthorizedToWriteCatalogs: FunctionComponent<ICaseAuthorizedToWriteCatalogs> =
    props =>
        <CaseAuthorized for={Authority.WRITE_CATALOGS}>
            {props.children}
        </CaseAuthorized>;

export interface ICaseAuthorizedToWriteCatalogs {
    children: (authorized: boolean) => ReactNode,
}
