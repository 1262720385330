import { ConfigurationFormSectionFactory } from './abstract-factory';
import { DefaultCustomerObligationsFactory } from '../CustomerObligationsForm/DefaultCustomerObligationsFactory';
import { DefaultTermsConditions } from '../OtherTermsConditionsForm/DefaultTermsConditions';
import { DefaultGuarantees } from '../GuaranteeFormSection/DefaultGuarantees';
import { CsaPreventiveStandardScope } from '../StandardScopeFormSection/CsaPreventiveStandardScope';
import { MigrateStandardScopeDialog } from '../MigrateStandardScopeDialog/MigrateStandardScopeDialog';
import { CsaCoveredUnits } from '../CoveredUnitsFormSection/CsaCoveredUnits';
import { PaymentEscalationForm } from '../PaymentEscalationForm/PaymentEscalationForm';
import { AdditionalActivitiesForm } from '../AdditionalActivitiesForm/AdditionalActivitiesForm';
import { DefaultAddUnitsForm } from '../AddUnitsForm/DefaultAddUnitsForm';

export const csaPreventiveFactory: ConfigurationFormSectionFactory = {
    CoveredUnits: CsaCoveredUnits,
    MigrateStandardScopeDialog,
    AddUnitsForm: DefaultAddUnitsForm,
    StandardScope: CsaPreventiveStandardScope,
    AdditionalScope: AdditionalActivitiesForm,
    CustomerObligations: DefaultCustomerObligationsFactory,
    Guarantees: DefaultGuarantees,
    PaymentEscalation: PaymentEscalationForm,
    OtherTermsConditions: DefaultTermsConditions,
};
