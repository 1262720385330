import React from 'react';
import MaterialInputBase, { InputBaseProps as MaterialInputBaseProps } from '@material-ui/core/InputBase';
import { createInputTestProps } from '../Utils/testProps';

interface IInputBaseProps extends MaterialInputBaseProps {
    testName: string,
}

const InputBase: React.FunctionComponent<IInputBaseProps> =
    ({ testName, inputProps, ...remainingInputBaseProps }) => {
        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(testName),
        };

        return (
            <MaterialInputBase
                inputProps={fullInputProps}
                {...remainingInputBaseProps}
            />
        );
    };

export default InputBase;
