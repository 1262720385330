import { createAction, createReducer } from '@reduxjs/toolkit';
import { ActualizationCandidate } from '../../api/model-service/actualization';

interface ActualizationCandidateState {
    isInitialLoad: boolean,
    loading: boolean,
    error: string,
    data: ActualizationCandidate[],
}

export const startLoading = createAction('actualizationCandidates/startLoading');
export const errorResponse = createAction<Error>('actualizationCandidates/errorResponse');
export const successResponse = createAction<ActualizationCandidate[]>('actualizationCandidates/successResponse');
export const clearData = createAction('actualizationCandidates/clearData');

const initialState: ActualizationCandidateState = {
    isInitialLoad: true,
    loading: false,
    error: null,
    data: [],
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            isInitialLoad: false,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            isInitialLoad: false,
            error: null,
            data: action.payload,
        }))
        .addCase(clearData, () => initialState),
);
export default reducer;
