export const parseErrors = (
    errors,
    defaultMessage = 'An unknown error occured. Please contact the system administrators'
) => {
    if (Array.isArray(errors)) {
        return errors
            .map(error => {
                return `${error.property}: ${error.message}`;
            })
            .join(',  ');
    }
    if (Array.isArray(errors && errors.errors)) {
        return errors.errors
            .map(error => {
                return `${error.property}: ${error.message}`;
            })
            .join(',  ');
    } else {
        if (!errors) {
            return defaultMessage;
        }

        return errors;
    }
};
