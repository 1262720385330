import { IFormOption, iv } from '../utils/form';
import {formOption} from "../../utils/formOption";

export enum UnitType {
    JMS = 'JMS',
    JMC = 'JMC',
    JGS = 'JGS',
    JGC = 'JGC',
}

export enum GasType {
    NG = 'NG',
    BG = 'BG',
    APG = 'APG',
    LFG = 'LFG',
    CMG = 'CMG',
    WG = 'WG',
    OTHER = 'OTHER',
}

export enum sunsetClause {
    NONE = 'NONE',
    MAX_DURATION = 'MAX_DURATION',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
}

export const sunsetClauseInputs: IFormOption[] = [
    iv(sunsetClause.NONE, 'None'),
    iv(sunsetClause.MAX_DURATION, 'Maximal Duration', true),
    iv(sunsetClause.EXPIRATION_DATE, 'Expiration Date'),
];

export const UnitTypeInputs: IFormOption[] = [
    iv(UnitType.JMS, 'JMS'),
    iv(UnitType.JMC, 'JMC'),
    iv(UnitType.JGS, 'JGS'),
    iv(UnitType.JGC, 'JGC'),
];

export const GasTypeInputs: IFormOption[] = [
    iv(GasType.NG, 'NG'),
    iv(GasType.BG, 'BG'),
    iv(GasType.APG, 'APG'),
    iv(GasType.LFG, 'LFG'),
    iv(GasType.CMG, 'CMG'),
    iv(GasType.WG, 'WG'),
    iv(GasType.OTHER, 'OTHER'),
];

export enum MyPlantProduct {
    NOT_INCLUDED = 'NOT_INCLUDED',
    BASIC = 'BASIC',
    CARE = 'CARE',
    PROFESSIONAL = 'PROFESSIONAL',
    CONNECT = 'CONNECT',
    PROTECT = 'PROTECT',
}

export const MyPlantProductInputs: IFormOption[] = [
    iv(MyPlantProduct.NOT_INCLUDED, 'Not Included'),
    iv(MyPlantProduct.BASIC, 'Basic'),
    iv(MyPlantProduct.CARE, 'Care'),
    iv(MyPlantProduct.PROFESSIONAL, 'Professional'),
    iv(MyPlantProduct.CONNECT, 'Connect'),
    iv(MyPlantProduct.PROTECT, 'Protect'),
];

export enum AnnualCapUnit {
    PERCENTAG = 'PERCENTAG',
    EURO = 'EURO',
}

export const AnnualCapUnitInputs: IFormOption[] = [
    iv(AnnualCapUnit.PERCENTAG, '% of annual billings'),
    iv(AnnualCapUnit.EURO, '€'),
];

export const ContractCapUnitInputs: IFormOption[] = [
    iv(AnnualCapUnit.PERCENTAG, '% of total billings'),
    iv(AnnualCapUnit.EURO, '€'),
];

export enum GaurenteeType {
    UNIT_RELIABILITY = 'UNIT_RELIABILITY',
    UNIT_AVAILABILITY = 'UNIT_AVAILABILITY',
    SITE_RELIABILITY = 'SITE_RELIABILITY',
    SITE_AVAILABILITY = 'SITE_AVAILABILITY',
    STARTING_RELIABILITY = 'STARTING_RELIABILITY',
    ELECTRICAL_POWER = 'ELECTRICAL_POWER',
    THERMAL_POWER = 'THERMAL_POWER',
    ELECTRICAL_EFFICIENCY = 'ELECTRICAL_EFFICIENCY',
    THERMAL_EFFICIENCY = 'THERMAL_EFFICIENCY',
    TOTAL_EFFICIENCY = 'TOTAL_EFFICIENCY',
    LUBE_OIL_CONSUMPTION = 'LUBE_OIL_CONSUMPTION',
    UREA_CONSUMPTION = 'UREA_CONSUMPTION',
    EMISSIONS = 'EMISSIONS',
    REACTION_TIME_INITIATE = 'REACTION_TIME_INITIATE',
    REACTION_TIME_PERSONNEL = 'REACTION_TIME_PERSONNEL',
    OTHER = 'OTHER',
    PARTS_ON_TIME_DELIVERY = 'PARTS_ON_TIME_DELIVERY'
}

export enum GaurenteeUnit {
    PERCENTAGE = 'PERCENTAGE',
    OPHS = 'OPHS',
    HOURS = 'HOURS',
    MWHRS = 'MWHRS',
    LITERS = 'LITERS',
    NOX = 'NOX',
    DAYS = 'DAYS',
}

export enum BonusUnit {
    EURO_PER_OPH = 'EURO_PER_OPH',
    EURO_PER_PERCENTAG = 'EURO_PER_PERCENTAG',
    EURO_PER_ZERO_POINT_ONE_PERCENTAG = 'EURO_PER_ZERO_POINT_ONE_PERCENTAG',
    PERCENTAGE_ANNUAL_PER_OPH = 'PERCENTAGE_ANNUAL_PER_OPH',
    PERCENTAGE_ANNUAL_PER_PERCENTAG = 'PERCENTAGE_ANNUAL_PER_PERCENTAG',
    PERCENTAGE_ANNUAL_PER_ZERO_POINT_ONE_PERCENTAG = 'PERCENTAGE_ANNUAL_PER_ZERO_POINT_ONE_PERCENTAG',
    EURO_PER_OTHER_GUARANTEED_UNIT = 'EURO_PER_OTHER_GUARANTEED_UNIT',
    PERCENTAGE_ANNUAL_PER_OTHER_GUARANTEED_UNIT = 'PERCENTAGE_ANNUAL_PER_OTHER_GUARANTEED_UNIT',
    EURO_PER_DAY_OF_DEVIATION = 'EURO_PER_DAY_OF_DEVIATION',
}

export enum BillingBase {
    ALL_PAYMENTS = 'ALL_PAYMENTS',
    USAGE_OPH = 'USAGE_OPH',
    USAGE_MWH = 'USAGE_MWH',
    MILESTONE = 'MILESTONE',
    FIXED_PERIODIC = 'FIXED_PERIODIC',
    PACKAGES = 'PACKAGES',
}

export const BillingBaseInputs: IFormOption[] = [
    iv(BillingBase.ALL_PAYMENTS, 'All Payments'),
    iv(BillingBase.USAGE_OPH, 'Usage - Oph'),
    iv(BillingBase.USAGE_MWH, 'Usage - MWh'),
    iv(BillingBase.MILESTONE, 'Milestone(s)'),
    iv(BillingBase.FIXED_PERIODIC, 'Fixed Periodic'),
    iv(BillingBase.PACKAGES, 'Packages'),
];

export const MonetaryInputs: string[] = [
    'Corrective cap per event',
    'Corrective cap per year',
    'Corrective cap over total contract term',
];

export enum BillingInterval {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    CALENDAR_YEAR = 'CALENDAR_YEAR',
    ANNUALLY = 'ANNUALLY',
    PACKAGES = 'PACKAGES',
}

export const BillingCurrencyInputs: IFormOption[] = [
    iv('USD', 'USD', true),
    iv('EUR', 'EUR', true),
    iv('DKK', 'DKK', true),
];

export const BillingIntervalInputs: IFormOption[] = [
    iv(BillingInterval.MONTHLY, 'Monthly', true),
    iv(BillingInterval.QUARTERLY, 'Quarterly'),
    iv(BillingInterval.CALENDAR_YEAR, 'Calendar Year'),
    iv(BillingInterval.ANNUALLY, 'Annually'),
    iv(BillingInterval.PACKAGES, 'Packages'),
];

export const ContractGoverningLawInputs: IFormOption[] = [
    iv('Austria', 'Austria'),
    iv('Belgium', 'Belgium'),
    iv('Czech', 'Czech'),
    iv('Denmark', 'Denmark'),
    iv('Germany', 'Germany'),
    iv('Hungary', 'Hungary'),
    iv('Italy', 'Italy'),
    iv('Netherlands', 'Netherlands'),
    iv('Norway', 'Norway'),
    iv('Portugal', 'Portugal'),
    iv('Russia', 'Russia'),
    iv('Saudi Arabia', 'Saudi Arabia'),
    iv('Singapore', 'Singapore'),
    iv('South Africa', 'South Africa'),
    iv('Spain', 'Spain'),
    iv('Switzerland', 'Switzerland'),
    iv('United Kingdom', 'United Kingdom'),
    iv('United States', 'United States'),
];

export const ContractLanguageInputs: IFormOption[] = [
    iv('Czech', 'Czech'),
    iv('Danish', 'Danish'),
    iv('Dutch', 'Dutch'),
    iv('English', 'English'),
    iv('German', 'German'),
    iv('Hungarian', 'Hungarian'),
    iv('Italian', 'Italian'),
    iv('Portuguese', 'Portuguese'),
    iv('Spanish', 'Spanish'),
];

export const TerminationCategoryInputs: IFormOption[] = [
    iv(
        'Standard (default, breach, insolvency, force majeure)',
        'Standard (default, breach, insolvency, force majeure)'
    ),
    iv('Convenience', 'Convenience'),
    iv(
        'Missing guarantees / performances',
        'Missing guarantees / performances'
    ),
    iv('Non-competitive price', 'Non-competitive price'),
    iv('Others (economic shut down….)', 'Others (economic shut down….)'),
];

export const DeliveryTermsInputs: IFormOption[] = [
    iv('EXW', 'EXW'),
    iv('FCA', 'FCA'),
    iv('CPT', 'CPT'),
    iv('CIP', 'CIP'),
    iv('DAT', 'DAT'),
    iv('DAP', 'DAP'),
    iv('DDP', 'DDP'),
    iv('FAS', 'FAS'),
    iv('FOB', 'FOB'),
    iv('CFR', 'CFR'),
    iv('CIF', 'CIF'),
];

export const CostIndexLaborInputs: IFormOption[] = [
    iv(
        'AT - Kollektivvertrags-Mindestmonatslohn für Facharbeiter (Lohngruppe 2)',
        'AT - Kollektivvertrags-Mindestmonatslohn für Facharbeiter (Lohngruppe 2)'
    ),
    iv(
        'DE - Metall- und Elektroindustrie für die Pfalz, Ausgangslohn Nr.7 / Vergütungsgruppe 7 / Lohngruppe 3 einschl. 10%',
        'DE - Metall- und Elektroindustrie für die Pfalz, Ausgangslohn Nr.7 / Vergütungsgruppe 7 / Lohngruppe 3 einschl. 10%'
    ),
    iv(
        'IT - Indice sec ISTAT, indice retribuzioni contrattuali per contratto Totale economia al netto Dirigenti',
        'IT - Indice sec ISTAT, indice retribuzioni contrattuali per contratto Totale economia al netto Dirigenti'
    ),
    iv(
        'NL - Cao-lonen, contractuele loonkosten en arbeidsduur; SBI 2008 : 24-30, 33 Metalektro Onderwerpen Indexcijfers',
        'NL - Cao-lonen, contractuele loonkosten en arbeidsduur; SBI 2008 : 24-30, 33 Metalektro Onderwerpen Indexcijfers'
    ),
    iv(
        'ES - Indice de Precios de Consumo – IPC',
        'ES - Indice de Precios de Consumo – IPC'
    ),
    iv(
        'ES - Indice de Precios Industriales – IPRI',
        'ES - Indice de Precios Industriales – IPRI'
    ),
    iv('DK - Danske Nettoprisindeks', 'DK - Danske Nettoprisindeks'),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - West',
        'US - Consumer Price Index for Urban consumers (CPI-U) - West'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - Mountain Plains',
        'US - Consumer Price Index for Urban consumers (CPI-U) - Mountain Plains'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - Southwest',
        'US - Consumer Price Index for Urban consumers (CPI-U) - Southwest'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - Midwest',
        'US - Consumer Price Index for Urban consumers (CPI-U) - Midwest'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - Southeast',
        'US - Consumer Price Index for Urban consumers (CPI-U) - Southeast'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - Mid-Atlantic',
        'US - Consumer Price Index for Urban consumers (CPI-U) - Mid-Atlantic'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - New York',
        'US - Consumer Price Index for Urban consumers (CPI-U) - New York'
    ),
    iv(
        'US - Consumer Price Index for Urban consumers (CPI-U) - New England',
        'US - Consumer Price Index for Urban consumers (CPI-U) - New England'
    ),

    iv('Not Listed', 'Not Listed'),
];

export const CostIndexMaterialInputs: IFormOption[] = [
    iv(
        'DE - Index der Erzeugerpreise Gewerblicher Produkte für Verbrennungsmotoren und Turbinen Fachserie 17 Reihe 2 Lfd Nr GP-2811',
        'DE - Index der Erzeugerpreise Gewerblicher Produkte für Verbrennungsmotoren und Turbinen Fachserie 17 Reihe 2 Lfd Nr GP-2811'
    ),
    iv(
        'NL - Producentenprijsindex (PPI); Producten (PRODCOM): 28 Machines, apparaten en werktuigen',
        'NL - Producentenprijsindex (PPI); Producten (PRODCOM): 28 Machines, apparaten en werktuigen'
    ),
    iv(
        'ES - Indice de Precios de Consumo – IPC',
        'ES - Indice de Precios de Consumo – IPC'
    ),
    iv(
        'ES - Indice de Precios Industriales – IPRI',
        'ES - Indice de Precios Industriales – IPRI'
    ),
    iv('DK - Danske Nettoprisindeks', 'DK - Danske Nettoprisindeks'),
    iv(
        'US - B.L.S. - US Import Price Indexes, Germany, Manufactured Articles',
        'US - B.L.S. - US Import Price Indexes, Germany, Manufactured Articles'
    ),
    iv('Not Listed', 'Not Listed'),
];

export const CostIndexLubOilInputs: IFormOption[] = [
    iv(
        'DE - Index der Erzeugerpreise Gewerblicher Produkte für Schmieröle andere Öle Fachserie 17 Reihe 2 Lfd-Nr. 197 GP-Nr. 232018',
        'DE - Index der Erzeugerpreise Gewerblicher Produkte für Schmieröle andere Öle Fachserie 17 Reihe 2 Lfd-Nr. 197 GP-Nr. 232018'
    ),
    iv(
        'NL - Producentenprijsindex (PPI); Producten (PRODCOM): 19202 Stookolie en benzine; smeerolie',
        'NL - Producentenprijsindex (PPI); Producten (PRODCOM): 19202 Stookolie en benzine; smeerolie'
    ),
    iv('Not Listed', 'Not Listed'),
];

export enum ScopeTypes {
    INNIOParts = 'INNIO_PARTS',
    INNIOLabor = 'INNIO_LABOR',
    OtherProvider = 'OTHER_PROVIDER',
    Freight = 'FREIGHT',
    Customs = 'CUSTOMS',
}

export const ScopeTypesInputs: IFormOption[] = [
    iv('INNIO_PARTS', 'INNIO Parts', true),
    iv('INNIO_LABOR', 'INNIO Labor'),
    iv('OTHER_PROVIDER', 'Other Provider'),
    iv('FREIGHT', 'Freight'),
    iv('CUSTOMS', 'Customs'),
];

const productProgram = [];
const productProgramInputs = (): IFormOption[] => {
    if (productProgram.length > 0) {
        return productProgram;
    }

    const limit = new Date().getFullYear();
    let start = 2000;
    while (start <= limit) {
        productProgram.push(iv(start.toString(), start.toString()));
        start += 1;
    }
    return productProgram;
};

export default productProgramInputs;
