import React, { FC } from 'react';
import { Card } from '../Cards/Card';
import { ActionMenu, ActionMenuItem } from '../ActionMenu/ActionMenu';
import { CardContent, CardHeader, StyledComponentProps, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

const ActionCard0: FC<ActionCardProps & StyledComponentProps> =
    props => {
        const { classes, testName, testId } = props;
        return (
            <Card
                testName={testName}
                testId={testId}
                classes={{ root: classes.card }}
                elevation={1}
                square
            >
                <CardHeader
                    classes={{
                        action: classes.actions,
                        title: classes.title,
                    }}
                    action={!isEmpty(props.actions)
                        ? <ActionMenu
                            testName={`${testName}Actions`}
                            testId={testId}
                            actions={props.actions}
                        />
                        : null
                    }
                    title={props.title}
                />
                <CardContent classes={{ root: classes.content }}>
                    {props.children}
                </CardContent>
            </Card>
        );
    };

interface ActionCardProps {
    testName: string,
    testId?: string,
    title: string,
    actions?: ActionMenuItem[],
}

const styles: StyleRules = {
    card: {
        border: '2px solid #fff',
    },
    actions: {
        display: 'flex',
        padding: '4px 8px',
    },
    content: {
        padding: '4px 16px',
    },
    title: {
        fontSize: '1rem',
    },
};
export const ActionCard = withStyles(styles)(ActionCard0);
