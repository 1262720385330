import React, { FC } from 'react';
import { StandardScopeProps } from './StandardScopeProps';
import { deduplicateAttributes, isGenSetActivity, isNotMajorOverhaul, isNotUnplanned } from './activity-filters';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';
import StandardActivitiesForm from '../StandardActivitiesForm/StandardActivitiesForm';

export const CsaPreventiveStandardScope: FC<StandardScopeProps> =
    props =>
        <StandardActivitiesForm {...props} defaultActivitiesFilter={getDefaultActivities}/>
;

function getDefaultActivities(activities: CatalogActivity[]): CatalogActivity[] {
    return deduplicateAttributes(
        activities
            .filter(isGenSetActivity)
            .filter(isNotUnplanned)
            .filter(isNotMajorOverhaul),
    );
}
