import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { formOption } from '../../utils/formOption';
import { ActivityCatalog as ActivityCatalogType } from '../../api/catalog-service/activityCatalogs';
import { IFormOption } from '../../data/utils/form';

export const ActivityCatalog: FC<ActivityCatalogProps> =
    ({ formikProps, container, activityCatalogs, disabled }) => {
        const name = 'activityCatalogId';
        const errorMessage = inputError(name, formikProps);
        const options: IFormOption[] = activityCatalogs
            .map(catalog => formOption('' + catalog.id, catalog.name));
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Catalog Version'
                    placeholder='Select catalog'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

interface ActivityCatalogProps extends FieldProps {
    activityCatalogs: ActivityCatalogType[],
}
