import React, { FC } from 'react';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import { Typography } from '@material-ui/core';

export const NoDataRow: FC<RowProps> = (props) =>
    <TableDataRow testName='noData' testId=''>
        <TableDataCell testName='noData' colSpan={props.colSpan} align='center'>
            <Typography variant='body2'>No results to validate</Typography>
        </TableDataCell>
    </TableDataRow>;

interface RowProps {
    colSpan: number,
}
