import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';
import { MultiSelectField } from '../MultiSelectField/MultiSelectField';
import { arrayInputValue } from '../../utils/arrayInputValue';
import { yearMonthLabel } from '../../utils/yearMonthLabel';

export const ActualizationPeriods: FC<ActualizationPeriodInputProps> =
    ({ formikProps, actualizationPeriods, disabled }) => {
        const name = 'actualizationPeriods';
        const errorMessage = inputError(name, formikProps);
        const options: IFormOption[] = actualizationPeriods.map(period => {
            return formOption(period, yearMonthLabel(period));
        });
        return (
            <MultiSelectField
                name={name}
                label='Actualization Periods'
                placeholder='Select actualization periods'
                value={arrayInputValue(name, formikProps)}
                options={options}
                onChange={updatedValue => {
                    formikProps.setFieldValue(name, updatedValue);
                }}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                disabled={disabled}
            />
        );
    };

export interface ActualizationPeriodInputProps extends FieldProps {
    actualizationPeriods: string[],
}
