import React, { FC } from 'react';
import { Button, IButtonProps } from '../Buttons/Button';
import { CaseAuthorizedToEditForm } from './formAuthorizationContext';

export const SubmitButton: FC<SubmitButtonProps> =
    props =>
        <CaseAuthorizedToEditForm>
            {authorizedToEdit => authorizedToEdit
                ? <Button type='submit' {...props} />
                : null
            }
        </CaseAuthorizedToEditForm>;

type SubmitButtonProps = Omit<IButtonProps, 'type'>;
