import React, { FC } from 'react';
import { MenuProps } from 'react-select';
import { Paper, StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import { OptionType } from './OptionType';

const Menu0: FC<MenuProps<OptionType> & StyledComponentProps> =
    props =>
        <Paper
            {...props.innerProps}
            className={props.classes.menu}
            square
        >
            {props.children}
        </Paper>;

const styles: StyleRulesCallback = theme => ({
    menu: {
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
});
export const Menu = withStyles(styles)(Menu0);
