import React, { FC } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { Button } from '../Buttons/Button';
import { ActualizationFormButtonsProps } from './ActualizationFormButtons';

export const Close: FC<ActualizationFormButtonsProps & StyledComponentProps> =
    props =>
        <Button
            testName='close'
            variant='contained'
            color='primary'
            disabled={props.loading || props.disabled}
            onClick={props.onClose}
            className={props.classes.button}
        >
            Close
        </Button>;
