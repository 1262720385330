import { CatalogOverview, CatalogState } from './catalog-overview';
import { createURLSearchParams } from '../utils/url-search-params';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';
import { fetch } from '../fetch';

declare const CATALOG_ROOT: string;

export function getEquipmentCatalogs(request: GetEquipmentCatalogsRequest): Promise<EquipmentCatalog[]> {
    const url = new URL(`${CATALOG_ROOT}/equipment-catalogs`);
    const query = createURLSearchParams(request);
    url.search = query.toString();

    return fetch(url.toString())
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetEquipmentCatalogsRequest {
    state: CatalogState[],
}

export interface EquipmentCatalog extends CatalogOverview {
    type: 'EQUIPMENT',
}
