import * as React from 'react';
import { MenuItem } from '../Popups/MenuItem';
import { TextFieldProps } from '@material-ui/core/TextField';
import TextValidator from './TextValidator';
import { ValidatorComponentProps } from 'react-form-validator-core';
import ValidatorForm from './ValidatorForm';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteOutlinedIcon from '@material-ui/icons/Close';
import NativeSelectValidator from './NativeSelectValidator';
import { IFormOption } from '../../data/utils/form';
import { CaseAuthorizedToEditForm } from './formAuthorizationContext';
import moment from 'moment';
import { parseIsoDate } from '../../data/utils/date';
import { MapWithRequiredOnUnionType } from '../../utils/MapWithRequiredOnUnionType';

type FFInputProps = MapWithRequiredOnUnionType<TextFieldProps, 'name'> & ValidatorComponentProps & {
    options?: IFormOption[],
    noPlaceholderOption?: boolean,
    controlledInput?: boolean,
    formHelpTextProps?: object,
    selectProps?: object,
    canClear?: boolean,
    errorMessages?: string[],
};

export const FFInput: React.FunctionComponent<FFInputProps> = props =>
    <CaseAuthorizedToEditForm>
        {
            authorizedToEdit =>
                <AuthorizedFFInput authorizedToEdit={authorizedToEdit} {...props} />
        }
    </CaseAuthorizedToEditForm>;

type AuthorizedFFInputProps = FFInputProps & {
    authorizedToEdit: boolean,
}

const AuthorizedFFInput: React.FunctionComponent<AuthorizedFFInputProps> = props => {
    const {
        options,
        noPlaceholderOption,
        controlledInput,
        autoFocus,
        required,
        authorizedToEdit,
        disabled,
        formHelpTextProps,
        placeholder,
        fullWidth,
        selectProps,
        helperText,
        variant,
        onChange,
        value,
        name,
        label,
        id,
        select,
        type,
        validatorListener,
        InputProps,
        inputProps,
        InputLabelProps,
        onFocus,
        onBlur,
        canClear,
    } = props;

    let {
        defaultValue,
        errorMessages,
        margin,
        rowsMax,
        validators,
    } = props;

    if (!margin) {
        margin = 'normal';
    }

    let multiline = false;
    if (rowsMax && rowsMax >= 1) {
        multiline = true;
        rowsMax = rowsMax || 3;
    } else {
        rowsMax = 1;
    }

    if (!defaultValue) {
        defaultValue = '';
    }

    if (options && options.length > 0) {
        for (const option of options) {
            if (option && option.isDefault) {
                defaultValue = option.value;
                break;
            }
        }
    }

    if (!validators) {
        validators = [];
    }
    if (!errorMessages) {
        errorMessages = [];
    }

    if (required) {
        if (label) {
            errorMessages.push(label + ' is required');
        } else {
            errorMessages.push('This field is required');
        }
        validators.push('customRequired');
    }

    const InputPropsModded = InputProps || {};
    if (select && canClear) {
        if (!InputPropsModded.endAdornment) {
            InputPropsModded.endAdornment = (
                <InputAdornment position="end">
                    <DeleteOutlinedIcon
                        color="disabled"
                        onClick={() => onChange && onChange(null)}
                    />
                </InputAdornment>
            );
        }
    }

    const inputDisabled = getInputDisabled(authorizedToEdit, disabled);

    if (select && !multiline) {
        const fullInputProps = {
            ...inputProps,
            name,
            id,
        };

        return (
            <React.Fragment>
                <NativeSelectValidator
                    name={name}
                    required={required}
                    label={label}
                    inputProps={fullInputProps}
                    onChange={onChange}
                    value={!controlledInput && value == null ? defaultValue : value}
                    variant={variant}
                    options={options}
                    noPlaceholderOption={noPlaceholderOption}
                    validators={validators}
                    errorMessages={errorMessages}
                    validatorListener={validatorListener}
                    helperText={helperText}
                    placeholder={placeholder}
                    fullWidth={fullWidth ? true : false}
                    disabled={inputDisabled}
                />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <TextValidator
                    autoFocus={autoFocus}
                    id={id}
                    select={select ? true : false}
                    type={type}
                    label={label}
                    name={name}
                    required={required}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    multiline={multiline}
                    validators={validators}
                    errorMessages={errorMessages}
                    onChange={onChange || null}
                    FormHelperTextProps={formHelpTextProps || {}}
                    placeholder={placeholder || ''}
                    helperText={helperText || ''}
                    disabled={inputDisabled}
                    variant={variant as any || 'standard'}
                    fullWidth={fullWidth ? true : false}
                    value={!controlledInput && value == null ? defaultValue : value}
                    rowsMax={rowsMax || 1}
                    margin={margin || 'normal'}
                    SelectProps={selectProps || {}}
                    validatorListener={validatorListener}
                    InputLabelProps={InputLabelProps}
                    InputProps={InputPropsModded}
                    inputProps={inputProps}
                >
                    {options && options.length
                        ? options.map((option, key) => {
                              if (option) {
                                  return (
                                      <MenuItem
                                          key={key}
                                          value={option.value}
                                          testName="option"
                                          testId={'' + option.value}
                                      >
                                          {option.label}
                                      </MenuItem>
                                  );
                              }
                          })
                        : ''}
                </TextValidator>
            </React.Fragment>
        );
    }
};

function getInputDisabled(authorizedToEdit: boolean, disabled: boolean) {
    return !authorizedToEdit || disabled;
}

ValidatorForm.addValidationRule('customRequired', val => {
    return val !== null && val !== '';
});

ValidatorForm.addValidationRule('pastOrPresent', val => {
    const dateValue = parseIsoDate(val);
    if (!dateValue.isValid()) {
        return false;
    }

    const now = moment();
    return dateValue.isSameOrBefore(now);
});
