import React, { FC } from 'react';
import { IScope } from '../../data/model/configuration';
import { TableBody } from '@material-ui/core';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { Table } from '../Tables/Table';
import { ServiceTableHeader } from './ServiceTableHeader';
import { ServiceTableNoDataRow } from './ServiceTableNoDataRow';
import { isEmpty } from 'lodash';
import { ServiceTableRow } from './ServiceTableRow';

const ServiceTable0: FC<StyledComponentProps & ServiceTableProps> =
    ({ activities, showCost, onChange, tableTestName, rowTestName, classes }) =>
        <Table testName={tableTestName} className={classes.table}>
            <ServiceTableHeader showCost={showCost}/>
            <TableBody>
                {isEmpty(activities)
                    ? <ServiceTableNoDataRow showCost={showCost}/>
                    : null
                }
                {activities.map((activity, idx) =>
                    <ServiceTableRow
                        key={activity.id}
                        testName={rowTestName}
                        activity={activity}
                        showCost={showCost}
                        onChange={updatedActivity => {
                            const updatedActivities = immutableSet(idx, updatedActivity, activities);
                            onChange(updatedActivities);
                        }}
                    />
                )}
            </TableBody>
        </Table>;

export const ServiceTable = withStyles(getClasses)(ServiceTable0);

export interface ServiceTableProps {
    tableTestName: string,
    rowTestName: string,
    activities: IScope[],
    showCost: boolean,
    onChange: (activities: IScope[]) => void,
}

function getClasses(): StyleRules {
    return {
        table: {
            minWidth: 700,
        },
    };
}

function immutableSet(idx: number, updatedActivity: IScope, activities: IScope[]): IScope[] {
    const activitiesCopy = activities.slice();
    activitiesCopy[idx] = updatedActivity;
    return activitiesCopy;
}
