import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';
import { ContractCategory } from '../../api/model-service/model-service';
import { contractCategoryName } from '../../utils/contractCategoryName';

export const ContractCategoryInput: FC<ContractCategoryInputProps> =
    ({ formikProps, contractCategories, disabled }) => {
        const name = 'contractCategory';
        const errorMessage = inputError(name, formikProps);
        const options: Array<IFormOption<ContractCategory>> =
            contractCategories.map(category => {
                return formOption(category, contractCategoryName(category));
            });
        return (
            <SelectField
                name={name}
                label='Contract Category'
                value={inputValue(name, formikProps)}
                placeholder='Select a contract category'
                options={options}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                disabled={disabled}
                margin='dense'
                fullWidth
                required
            />
        );
    };

export interface ContractCategoryInputProps extends FieldProps {
    contractCategories: ContractCategory[],
}
