import React, { FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { InputAdornment } from '@material-ui/core';
import { FieldProps } from './FieldProps';

export const LimitationOfLiabilityAmount: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.limitationOfLiabilityAmount';
        const errorMessage = inputError(name, formikProps);
        return (
            <TextField
                name={name}
                label='Limitation of Liability Amount'
                type='number'
                value={inputValue(name, formikProps)}
                disabled={disabled}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage || '(% of CV)'}
                margin='dense'
                fullWidth
                required
                InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                }}
            />
        );
    };
