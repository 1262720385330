import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { getModel as getModelFromApi } from '../../api/model-service/model';
import { reportModelError } from '../error-actions';

export const getModelForEdit = (id: number): AppThunk => dispatch => {
    dispatch(startLoading());
    return getModelFromApi({id, skipFinancialData: true})
        .then(model => {
            dispatch(successResponse(model));
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportModelError(err));
            throw err;
        })
};
