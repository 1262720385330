import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { TextField } from '../TextField/TextField';
import { InputAdornment } from '@material-ui/core';
import { Activity } from '../../api/model-service/model-service';

export const Value: FC<FieldProps> =
    ({ formikProps }) => {
        const name = 'value';
        const error = inputError(name, formikProps);
        return (
            <TextField
                name={name}
                type='number'
                label='Value'
                value={inputValue(name, formikProps)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!error}
                helperText={error}
                margin='dense'
                fullWidth
                InputProps={{
                    endAdornment:
                        <InputAdornment position='end'>
                            {isLabor(formikProps.values) ? 'hours' : '€'}
                        </InputAdornment>
                    ,
                }}
            />
        );
    };

function isLabor(activity: Activity) {
    return activity.type === 'INNIO_LABOR';
}
