export class HttpStatusError extends Error {
    public readonly response: Response;
    public readonly status: number;

    public constructor(response: Response, message?: string) {
        super(message);
        this.response = response;
        this.status = response.status;
    }
}

export class ContentTypeError extends Error {
    public constructor(public readonly contentType: string, message?: string) {
        super(message);
    }
}

export class ApiError extends Error {
}

export class ConcurrentModificationError extends ApiError {
}

export function createApiError(apiResponseBody): ApiError {
    let message: string = null;

    if (typeof apiResponseBody.message === 'string') {
        message = apiResponseBody.message;
    }

    if (apiResponseBody.message instanceof Array) {
        message = apiResponseBody.message
            .map(error => `${error.property}: ${error.message}`)
            .join('\n');
    }

    if (message && isOptimisticLockError(message)) {
        message = 'Another user modified the form you are currently editing and ' +
            'your changes would override his. Please reload the page to see ' +
            'the latest data and make your modifications again.';
        return new ConcurrentModificationError(message);
    }

    return new ApiError(message);
}

function isOptimisticLockError(message: string): boolean {
    return message.includes('org.hibernate.StaleObjectStateException');
}
