import { createAction, createReducer } from '@reduxjs/toolkit';
import { Model } from '../../api/model-service/model-service';

interface ModelDetailsState {
    loading: boolean,
    error: string,
    data: Model,
}

export const startLoading = createAction('modelDetails/startLoading');
export const errorResponse = createAction<Error>('modelDetails/errorResponse');
export const successResponse = createAction<Model>('modelDetails/successResponse');

const initialState: ModelDetailsState = {
    loading: false,
    error: null,
    data: null,
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            error: null,
            data: action.payload,
        })),
);
export default reducer;
