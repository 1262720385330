import React, { FC } from 'react';
import { PricingContributionMarginProps } from './PricingContributionMarginProps';
import PricingContributionMargin from '../Configuration/Financials/Pricing/Forms/PricingContributionMargin';

export const DefaultPricingContributionMargin: FC<PricingContributionMarginProps> =
    props =>
        <PricingContributionMargin
            {...props}
            showToolTargetPrice
            showToolTargetDiscount={false}
            showActualDiscount={false}
            showReferencePriceList={false}
            showDiscountMatrix={false}
            showPackageBilling={false}
            showUserTargets={true}
        />
;
