import { Activity, EngineInformation, ICatalog } from '../model/catalog';
import { IConfiguration } from '../model/configuration';
import {
    CONFIGURATIONS,
    IConfigurationAction,
} from '../actions/configurations';
import { defaultsDeep } from 'lodash';
import {
    BillingBase,
    BillingInterval,
    MyPlantProduct,
} from '../model/configuration_enums';
import { toServiceDate } from '../utils/date';
import moment from 'moment';
export const configurations = (
    state = InitialConfigurationState,
    action: IConfigurationAction
): IConfigurationState => {
    switch (action.type) {
        case CONFIGURATIONS.CREATE:
        case CONFIGURATIONS.UPDATE:
        case CONFIGURATIONS.FORMINIT:
        case CONFIGURATIONS.DELETE:
        case CONFIGURATIONS.MERGE:
        case CONFIGURATIONS.READ:
            state.configuration = action.configuration;
            return Object.assign({}, defaultsDeep(action, state));
        case CONFIGURATIONS.CLEAR:
            return InitialConfigurationState;
        case CONFIGURATIONS.CLEARERROR:
        case CONFIGURATIONS.ERROR:
        default:
            return state;
    }
};

export interface IConfigurationState {
    configuration: IConfiguration;
    activities?: Activity[];
    activityCatalogs?: ICatalog[];
    type?: string;
    engineInformation?: EngineInformation[];
    breakdown?: any;
}

export const cleanConfiguration = () => {
    return {
        name: '',
        equipmentCatalogId: 0,
        activityCatalogId: 0,
        units: [],
        unitCount: 0,
        engineInformation: { productProgram: moment().format('YYYY') },
        primaryConfiguration: false,
        standardScope: [],
        additionalScope: [],
        unitStartCounter: 0,
        unitEndCounter: 59999,
        customerObligation: {
            sparkPlugService: true,
            coolingWaterAnalysis: true,
            fuelGasAnalysis: true,
            lubeOilSupply: true,
            lubeOilDisposal: true,
            lubeOilSampling: true,
            plannedRoutineMaintenance: false,
            assistanceToExtendedMaintenance: false,
            machineryBreakdownInsurance: false,
        },
        operation: {
            ehsComplianceValidated: true,
            operationalExecutionStrategyValidated: true,
        },
        guarantees: [],
        guaranteeCap: {},
        term: {
            expirationDate: toServiceDate(
                moment()
                    .add(5, 'y')
                    .toDate()
            ),
            sunsetClauseType: sunsetClause.NONE,
            commercialOperationDate: toServiceDate(
                moment()
                    .add(3, 'm')
                    .toDate()
            ),
            effectiveContractStartDate: toServiceDate(
                moment()
                    .add(2, 'm')
                    .toDate()
            ),
        },
        termsConditions: {
            assignmentWordingStandard: true,
            contractGoverningLaw: 'Germany',
            contractLanguage: 'German',
            deliveryTerms: 'DAP',
            deviationFromDoaApprovalInformation: '',
            embeddedDerivatives: false,
            limitationOfLiabilityAmount: 100,
            limitationOfLiabilityWordingStandard: true,
            otherContractWordingStandard: true,
            passageOfTitleWordingStandard: true,
            productivityImplClause: true,
            residualValueOrBuyBackGuaranteeIncluded: false,
            secondContractLanguage: '',
            siteInventoryIncluded: false,
            suspensionWordingStandard: true,
            terminationAmount: 15,
            terminationWordingStandard: true,
            myPlantWording: true,
            myPlantProduct: MyPlantProduct.CONNECT,
            warrantyPeriodParts: 1,
            warrantyPeriodService: 1,
            warrantyWordingStandard: true,
        },
        payment: {
            billingCurrency: 'EUR',
            billingCurrencyFx: 0,
            billingInterval: BillingInterval.MONTHLY,
            annualBillingStartDate: null,
            paymentTerm: 15,
            minimumOperatingHoursPerYearViolationAgreement:
                'Bill up to 100% of defined min. ophs',
            extraWorkDiscountMaterial: 0,
            extraWorkDiscountLabor: 0,
            escalations: [],
        },
    };
};

export const InitialConfigurationState: IConfigurationState = {
    configuration: cleanConfiguration(),
};

import { sunsetClause } from '../model/configuration_enums';
