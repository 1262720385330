import React, { FC } from 'react';
import { CoveredUnitsProps } from './CoveredUnitsProps';
import { contractCatagory, contractCatagoryInputs } from '../../data/model/configuration';
import ConfigurationDetailsForm from '../ConfigurationDetailsForm/ConfigurationDetailsForm';

const csaContractCategories = [
    contractCatagory.CSA_PREVENTIVE,
    contractCatagory.CSA_PREVENTIVE_AND_CORRECTIVE,
];
const isCsaCategoryOption = option => csaContractCategories.includes(option.value);
const csaContractCategoryOptions = contractCatagoryInputs.filter(isCsaCategoryOption);

export const CsaCoveredUnits: FC<CoveredUnitsProps> =
    props =>
        <ConfigurationDetailsForm
            {...props}
            savedContractCategoryOptions={csaContractCategoryOptions}
        />;
