import { object, ObjectSchema, number, boolean } from '../../utils/validators';
import { CustomerObligation, Configuration } from '../../api/model-service/model-service';

export function getCustomerObligationsSchema(): ObjectSchema<Partial<Configuration>> {
    const schema = object().shape({
        customerObligation: object({
                id: number(),
                sparkPlugService: boolean(),
                coolingWaterAnalysis: boolean(),
                fuelGasAnalysis: boolean(),
                lubeOilSupply: boolean(),
                lubeOilDisposal: boolean(),
                lubeOilSampling: boolean(),
                plannedRoutineMaintenance: boolean(),
                assistanceToExtendedMaintenance: boolean(),
                machineryBreakdownInsurance: boolean(),
            }),
        });

    return schema;
}
