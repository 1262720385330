export class PageRequest {
    private keyword: string;
    private userName: string;
    private length: number = 10;
    private page: number = 0;
    private sort: SortOrder = SortOrder.Desc;
    private queryType: QueryType = QueryType.ByAll;
    private maxPage: number;

    public setQueryType(queryType:QueryType) {
        this.queryType = queryType;
    }

    public setKeyword(keyword: string): this {
        this.page = 0;
        this.keyword = keyword;
        return this;
    }

    public setUserName(userName: string): this {
        this.page = 0;
        this.userName = userName;
        return this;
    }

    public setLength(length: number): this {
        this.page = 0;
        this.length = length;
        return this;
    }
    public setPage(page: number): this {
        this.page = page;
        return this;
    }
    public setSort(sort: SortOrder): this {
        this.page = 0;
        this.sort = sort;
        return this;
    }
    public setMaxPage(max: number): this {
        this.maxPage = max;
        return this;
    }
    public nextPage(): this {
        if (this.maxPage - 1 <= this.page) {
            this.page += 1;
        }
        return this;
    }

    public prevPage(): this {
        if (this.page <= 0) {
            this.page = 0;
        } else {
            this.page -= 1;
        }
        return this;
    }
    public getQuery(): {} {
        return this.queryType === QueryType.ByAll ? {
            keyword: this.keyword,
            length: `${this.length}`,
            page: `${this.page}`,
            sort: this.sort,
        } : {
            userName: this.userName,
            length: `${this.length}`,
            page: `${this.page}`,
            sort: this.sort,
        };
    }
}

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export enum QueryType {
    ByUser = 'ByUser',
    ByAll = 'ALL',
}
