import React, { FunctionComponent, ReactNode } from 'react';

const defaultAuthorizedToEdit = true;
const context = React.createContext(defaultAuthorizedToEdit);
context.displayName = 'FormEditAuthorizationContext';

export const FormEditAuthorizationProvider: FunctionComponent<IFormEditAuthorizationProvider> =
    props =>
        <context.Provider value={props.authorizedToEdit}>
            {props.children}
        </context.Provider>;

export interface IFormEditAuthorizationProvider {
    authorizedToEdit: boolean,
    children: ReactNode,
}

export const CaseAuthorizedToEditForm = context.Consumer;
