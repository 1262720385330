import React, { Component, ReactNode } from 'react';
import { Configuration, Model } from '../../api/model-service/model-service';
import ViewTitle from '../ViewTitle/ViewTitle';
import { Link } from 'react-router-dom';
import {
    LinearProgress,
    Paper,
    Stepper,
    StyledComponentProps,
    StyleRulesCallback,
    withStyles,
} from '@material-ui/core';
import { FolderOutlined } from '@material-ui/icons';
import { FormStep } from './FormStep';
import { State } from '../../data/store';
import { getModelForEdit } from '../../state/modelDetails/getModel';
import { getConfigurationForEdit } from '../../state/configurationDetails/getConfiguration';
import { connect } from 'react-redux';
import { hasValue } from '../../data/utils/form';
import { saveConfiguration } from '../../state/configurationDetails/saveConfiguration';
import { createConfigurationFormSectionFactory } from '../ConfigurationFormSection/abstract-factory';
import { CompleteConfirmation } from './CompleteConfirmation';
import { CaseAuthorizedToUpdateModel } from '../authorization/CaseAuthorizedToUpdateModel';
import { FormEditAuthorizationProvider } from '../Form/formAuthorizationContext';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { getActiveRegionCatalogs } from '../../state/regionCatalogs/getRegionCatalogs';
import { getActiveActivityCatalogs } from '../../state/activityCatalogs/getActivityCatalogs';
import { ActivityCatalog } from '../../api/catalog-service/activityCatalogs';
import { EquipmentCatalog } from '../../api/catalog-service/equipmentCatalogs';
import { getDefaultEquipmentCatalog } from '../../state/defaultEquipmentCatalog/getDefaultEquipmentCatalog';
import { isEmpty } from 'lodash';
import { clearData } from '../../state/configurationDetails/slice';

class EditConfigurationPage0 extends Component<ComponentProps, ComponentState> {

    public state = {
        step: 0,
    };

    public componentDidMount(): void {
        this.props.onShowModel(this.props.modelId);

        if (this.hasConfigurationId()) {
            this.props.onShowExistingConfiguration(this.props.configurationId);
        } else {
            this.props.onShowNewConfiguration();
        }

        this.props.onShowRegionCatalogs();
        this.props.onShowActivityCatalogs();
        this.props.onShowDefaultEquipmentCatalog();
    }

    public render(): ReactNode {
        return (
            <>
                <ViewTitle>
                    <Link to='/Models'>Models</Link>
                </ViewTitle>
                <Paper square classes={{ root: this.props.classes.paper }}>
                    {this.props.loading
                        ? this.renderLoading()
                        : this.renderForm()
                    }
                </Paper>
            </>
        );
    }

    private renderLoading(): ReactNode {
        return <LinearProgress/>;
    }

    private renderForm(): ReactNode {
        if (!this.isModelAndConfigurationLoaded()) {
            return null;
        }

        const props = this.props;
        return (
            <CaseAuthorizedToUpdateModel model={props.model}>
                {authorizedToEdit =>
                    <FormEditAuthorizationProvider authorizedToEdit={authorizedToEdit}>
                        <Link to={`/models/${props.modelId}`}>
                            <h2 className='modelName'>
                                <FolderOutlined color='disabled' className={props.classes.smallIcon}/>
                                {props.model.projectName}
                            </h2>
                        </Link>
                        <Paper
                            square
                            classes={{ root: props.classes.stepperContainer }}
                            elevation={5}
                        >
                            <Stepper activeStep={this.state.step} orientation='vertical'>
                                {this.getSteps().map((step, idx) =>
                                    <FormStep
                                        key={idx}
                                        label={step.label}
                                        clickable={this.isConfigurationCompleted()}
                                        onClick={() => this.showStep(idx)}
                                    >
                                        {step.content(authorizedToEdit)}
                                    </FormStep>,
                                )}
                            </Stepper>

                            {this.isFormFinished() && this.renderCompleteConfirmation()}
                        </Paper>
                    </FormEditAuthorizationProvider>
                }
            </CaseAuthorizedToUpdateModel>
        );
    }

    private getSteps(): StepDescriptor[] {
        const model = this.props.model;
        const configuration = this.getConfiguration();
        const formFactory = createConfigurationFormSectionFactory(configuration);
        const onBack = () => this.showPreviousStep();
        const onNext = () => this.showNextStep();
        const onComplete = () => this.showCompleteConfirmation();
        const onSubmit = updatedConfiguration => this.handleSubmit(updatedConfiguration);
        return [
            {
                label: 'Configuration Details',
                content: () =>
                    <formFactory.CoveredUnits
                        configuration={configuration}
                        regionCatalogs={this.props.regionCatalogs}
                        activityCatalogs={this.props.activityCatalogs}
                        model={model}
                        onSubmit={onSubmit}
                        onNext={onNext}
                        onComplete={onComplete}
                    />,
            },
            {
                label: 'Add Covered Units',
                content: authorizedToEdit =>
                    <formFactory.AddUnitsForm
                        configuration={configuration}
                        onSubmit={onSubmit}
                        onBack={onBack}
                        onNext={onNext}
                        onComplete={onComplete}
                        model={model}
                        authorizedToEdit={authorizedToEdit}
                    />,
            },
            {
                label: 'Standard Scope',
                content: authorizedToEdit =>
                    <formFactory.StandardScope
                        model={this.props.model}
                        configuration={configuration}
                        authorizedToEdit={authorizedToEdit}
                        onSubmit={onSubmit}
                        onBack={onBack}
                        onNext={onNext}
                        onComplete={onComplete}
                    />,
            },
            {
                label: 'Additional Scope',
                content: authorizedToEdit =>
                    <formFactory.AdditionalScope
                        configuration={configuration}
                        model={model}
                        authorizedToEdit={authorizedToEdit}
                        onSubmit={onSubmit}
                        onBack={onBack}
                        onNext={onNext}
                        onComplete={onComplete}
                    />,
            },
            {
                label: 'Customer Obligations',
                content: () =>
                    <formFactory.CustomerObligations
                        configuration={configuration}
                        model={model}
                        onSubmit={onSubmit}
                        onBack={onBack}
                        onNext={onNext}
                        onComplete={onComplete}
                    />,
            },
            {
                label: 'Guarantees',
                content: authorizedToEdit =>
                    <formFactory.Guarantees
                        configuration={configuration}
                        model={model}
                        authorizedToEdit={authorizedToEdit}
                        onSubmit={onSubmit}
                        onBack={onBack}
                        onNext={onNext}
                        onComplete={onComplete}
                    />,
            },
            {
                label: 'Payment & Escalation',
                content: authorizedToEdit =>
                    <formFactory.PaymentEscalation
                        configuration={configuration}
                        model={model}
                        authorizedToEdit={authorizedToEdit}
                        onSubmit={onSubmit}
                        onBack={onBack}
                        onNext={onNext}
                        onComplete={onComplete}
                    />,
            },
            {
                label: 'Other Terms & Conditions',
                content: () =>
                    <formFactory.OtherTermsConditions
                        model={model}
                        configuration={configuration}
                        onSubmit={onSubmit}
                        viewConfigurationUrl={this.getConfigurationDetailsUrl()}
                        onBack={onBack}
                        onComplete={onComplete}
                    />,
            },
        ];
    }

    private renderCompleteConfirmation(): ReactNode {
        return (
            <Paper
                square
                elevation={1}
                className={this.props.classes.completeConfirmationContainer}
            >
                <CompleteConfirmation
                    configuration={this.getConfiguration()}
                    configurationDetailsUrl={this.getConfigurationDetailsUrl()}
                    configurePricingUrl={this.getConfigurePricingUrl()}
                />
            </Paper>
        )
    }

    private handleSubmit(updatedConfiguration: Configuration): Promise<void> {
        if (this.isFirstConfiguration()) {
            const primaryConfiguration: Configuration = {
                ...updatedConfiguration,
                primaryConfiguration: true,
            };
            return this.props.onSubmit(primaryConfiguration);
        }

        return this.props.onSubmit(updatedConfiguration);
    }

    private isFirstConfiguration(): boolean {
        return isEmpty(this.props.model.configurationOverviews);
    }

    private showStep(step: number): void {
        this.setState({ step });
    }

    private showPreviousStep(): void {
        this.setState(prevState => ({
            step: prevState.step - 1,
        }));
    }

    private showNextStep(): void {
        this.setState(prevState => ({
            step: prevState.step + 1,
        }));
    }

    private showCompleteConfirmation(): void {
        const stepCount = this.getSteps().length;
        this.setState({ step: stepCount });
    }

    private isFormFinished(): boolean {
        const stepCount = this.getSteps().length;
        return this.state.step >= stepCount;
    }

    private isConfigurationCompleted(): boolean {
        return this.getConfiguration().state === 'COMPLETED';
    }

    private getConfiguration(): Configuration {
        if (this.props.configuration) {
            return this.props.configuration;
        }

        if (!this.hasConfigurationId()) {
            return this.getNewConfiguration();
        }

        return null;
    }

    private getNewConfiguration(): Configuration {
        return {
            state: 'DRAFT',
            equipmentCatalogId: this.props.defaultEquipmentCatalog.id,
        }
    }

    private isModelAndConfigurationLoaded(): boolean {
        return this.isModelLoaded() && this.isConfigurationLoaded();
    }

    private isModelLoaded(): boolean {
        return hasValue(this.props.model);
    }

    private isConfigurationLoaded(): boolean {
        const hasConfiguration = hasValue(this.props.configuration);
        return !this.hasConfigurationId() || hasConfiguration;
    }

    private hasConfigurationId(): boolean {
        return hasValue(this.props.configurationId);
    }

    // These 2 urls have to be created here because the "new configuration" page
    // doesn't have a configuration ID parameter even after the configuration is saved.
    private getConfigurePricingUrl(): string {
        const detailsUrl = this.getConfigurationDetailsUrl();
        return `${detailsUrl}/financials/config`;
    }

    private getConfigurationDetailsUrl(): string {
        const modelId = this.props.modelId;
        const configurationId = this.getConfiguration().id;
        return `/models/${modelId}/configuration/view/${configurationId}`;
    }
}

type ComponentProps = EditConfigurationPageProps & StyledComponentProps & StateProps & ActionProps;

interface EditConfigurationPageProps {
    modelId: number,
    configurationId?: number,
}

interface ComponentState {
    step: number,
}

interface StepDescriptor {
    label: string,
    content: (authorizedToEdit: boolean) => ReactNode,
}

const styles: StyleRulesCallback = theme => ({
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 25,
        paddingBottom: 50,
        marginBottom: '33px',
        position: 'relative',
    },
    smallIcon: {
        position: 'relative',
        fontSize: 25,
        top: 4,
        marginRight: 10,
    },
    stepperContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: '10px 5px',
        paddingBottom: 50,
        marginBottom: '33',
        marginTop: '15',
    },
    completeConfirmationContainer: {
        padding: theme.spacing.unit * 3,
        margin: theme.spacing.unit * 3,
        border: '2px solid #ff9100',
        borderRadius: 5,
    },
});
export const EditConfigurationPage = withStyles(styles)(EditConfigurationPage0);

function mapStateToProps(state: State): StateProps {
    const modelDetailsState = state.REFACTORED.modelDetails;
    const configurationDetailsState = state.REFACTORED.configurationDetails;
    const regionCatalogsState = state.REFACTORED.regionCatalogs;
    const activityCatalogsState = state.REFACTORED.activityCatalogs;
    const defaultEquipmentCatalogState = state.REFACTORED.defaultEquipmentCatalog;

    return {
        model: modelDetailsState.data,
        configuration: configurationDetailsState.data,
        regionCatalogs: regionCatalogsState.data,
        activityCatalogs: activityCatalogsState.data,
        defaultEquipmentCatalog: defaultEquipmentCatalogState.data,
        loading: modelDetailsState.loading || configurationDetailsState.loading ||
            regionCatalogsState.loading || activityCatalogsState.loading || defaultEquipmentCatalogState.loading,
    };
}

interface StateProps {
    model: Model,
    configuration: Configuration,
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalog[],
    defaultEquipmentCatalog: EquipmentCatalog,
    loading: boolean,
}

function mapDispatchToProps(dispatch, ownProps: EditConfigurationPageProps): ActionProps {
    return {
        onShowModel: id => dispatch(getModelForEdit(id)),
        onShowNewConfiguration: () => dispatch(clearData()),
        onShowExistingConfiguration: id => dispatch(getConfigurationForEdit(id)),
        onShowRegionCatalogs: () => dispatch(getActiveRegionCatalogs()),
        onShowActivityCatalogs: () => dispatch(getActiveActivityCatalogs()),
        onShowDefaultEquipmentCatalog: () => dispatch(getDefaultEquipmentCatalog()),
        onSubmit: configuration => dispatch(saveConfiguration(ownProps.modelId, configuration, true)),
    };
}

interface ActionProps {
    onShowModel: (id: number) => void,
    onShowNewConfiguration: () => void,
    onShowExistingConfiguration: (id: number) => void,
    onShowRegionCatalogs: () => void,
    onShowActivityCatalogs: () => void,
    onShowDefaultEquipmentCatalog: () => void,
    onSubmit: (configuration: Configuration) => Promise<void>,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditConfigurationPage);
