import React, { FC } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { Button } from '../Buttons/Button';
import { ActualizationFormButtonsProps } from './ActualizationFormButtons';

export const Back: FC<ActualizationFormButtonsProps & StyledComponentProps> =
    props =>
        <Button
            testName='back'
            disabled={props.loading}
            onClick={props.onBack}
            className={props.classes.button}
        >
            Back
        </Button>;
