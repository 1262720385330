import * as React from 'react';
import './Users.scss';

import { withStyles } from '@material-ui/core/styles';
import SectionTitle from '../SectionTitle/SectionTitle';

const styles = (theme: any) => ({
    header: {
        padding: '0px 0px 33px 0',
    },
});

const Users = props => {
    const { classes } = this.props;

    return (
        <React.Fragment>
            <SectionTitle search>Users</SectionTitle>
        </React.Fragment>
    );
};

export default withStyles(styles)(Users);
