import { Page, SortDirection } from '../types';
import { createURLSearchParams } from '../utils/url-search-params';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { fetch } from '../fetch';
import { formData } from '../utils/form-data';
import { handleRequestError } from '../utils/request-error';

declare const CATALOG_ROOT: string;

export function getCatalogOverviews(request: GetCatalogOverviewsRequest): Promise<Page<CatalogOverview>> {
    const url = new URL(`${CATALOG_ROOT}/catalogs`);
    const query = createURLSearchParams(request);
    url.search = query.toString();

    return fetch(url.toString())
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json() as Promise<Page<CatalogOverview>>);
}

export interface GetCatalogOverviewsRequest {
    page: number,
    length: number,
    type: CatalogType,
    sortBy?: keyof CatalogOverview,
    sort?: SortDirection,
    keyword?: string,
}

export interface CatalogOverview {
    id: number,
    name: string,
    type: CatalogType,
    state: CatalogState,
    createdOn: string,
    createdBy: string,
    modifiedOn: string,
    modifiedBy: string,
}

export type CatalogType = 'ACTIVITY' | 'REGION' | 'EQUIPMENT';

export type CatalogState = 'DEFAULT' | 'ACTIVE' | 'INACTIVE';

export function getAllCatalogsByType(type: CatalogType): Promise<CatalogOverview[]> {
    // TODO there should be a dedicated call for this instead of using large page sizes
    return getCatalogOverviews(getAllCatalogsRequest(type))
        .then(page => page.content);
}

function getAllCatalogsRequest(type: CatalogType): GetCatalogOverviewsRequest {
    return {
        page: 0,
        length: 100000,
        type,
    };
}

export function updateCatalogState(request: UpdateCatalogStateRequest): Promise<void> {
    const url = `${CATALOG_ROOT}/catalogs`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    };
    // TODO the API should return the modified catalogs
    return fetch(url, requestOptions)
        .then(expectOK)
        .then(() => null);
}

export interface UpdateCatalogStateRequest {
    changes?: CatalogStateChange[],
    defaultId?: number,
}

interface CatalogStateChange {
    id: number,
    state: CatalogState,
}

export function createCatalog(request: CreateCatalogRequest): Promise<CatalogOverview> {
    const url = `${CATALOG_ROOT}/catalogs`;
    const requestOptions = {
        method: 'POST',
        body: formData(request),
    };
    return fetch(url, requestOptions)
        .then(expectJSON)
        .then(expectOK)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface CreateCatalogRequest {
    name: string,
    type: CatalogType,
    state: CatalogState,
    file: File,
}

export function getDefaultCatalog(type: CatalogType): Promise<CatalogOverview> {
    // TODO we need an API that returns just the default catalog instead of filtering all of them
    return getAllCatalogsByType(type)
        .then(catalogs => catalogs.find(isDefault))
}

function isDefault(catalog: CatalogOverview): boolean {
    return catalog.state === 'DEFAULT';
}
