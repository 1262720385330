import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { arrayInputValue } from '../../utils/arrayInputValue';
import { CreatableMultiSelectField } from '../MultiSelectField/CreatableMultiSelectField';

export const OpportunityNumbers: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'opportunityNumbers';
        const errorMessage = inputError(name, formikProps);
        return (
            <CreatableMultiSelectField
                name={name}
                label='Opportunity Numbers'
                placeholder='Enter an opportunity number and press Tab'
                value={arrayInputValue(name, formikProps)}
                onChange={updatedValue => {
                    formikProps.setFieldValue(name, updatedValue);
                }}
                onBlur={() => {
                    formikProps.setFieldTouched(name);
                }}
                error={!!errorMessage}
                helperText={errorMessage}
                disabled={disabled}
            />
        );
    };
