import * as yup from 'yup';
import { arrayMessage, booleanMessage, dateMessage, numberMessage, stringMessage } from './validationMessages';
import { hasValue } from '../data/utils/form';
import moment from 'moment';

const mixed = <T = any>(): yup.MixedSchema<T> =>
    yup.mixed<T>().nullable().transform(emptyAsNull);

// tslint:disable-next-line:variable-name
const number = (): yup.NumberSchema =>
    yup.number().typeError(numberMessage).nullable().transform(emptyAsNull);

// tslint:disable-next-line:variable-name
const string = (): yup.StringSchema =>
    yup.string().typeError(stringMessage).nullable().transform(emptyAsNull);

const date = (): yup.StringSchema =>
    yup.string().typeError(dateMessage).nullable().transform(emptyAsNull)
        .test('valid-date', dateMessage, value => {
            if (!hasValue(value)) {
                return true;
            }

            return moment(value, 'YYYY-MM-DD').isValid();
        });

const array = <T>(itemSchema?: yup.Schema<T>): yup.ArraySchema<T> =>
    yup.array(itemSchema).typeError(arrayMessage).nullable().transform(emptyAsNull);

const object = <T extends object | null | undefined>(fields?: yup.ObjectSchemaDefinition<T>): yup.ObjectSchema<T> =>
    yup.object(fields).nullable().transform(emptyAsNull);

// tslint:disable-next-line:variable-name
const boolean = (): yup.BooleanSchema =>
    yup.boolean()
        .typeError(booleanMessage)
        .nullable()
        .transform(emptyAsNull)
        .transform((parsedValue, originalValue) => {
            if (originalValue === 'yes') {
                return true;
            }

            if (originalValue === 'no') {
                return false;
            }

            return parsedValue;
        });

const emptyAsNull = (parsedValue, originalValue) =>
    originalValue === '' || originalValue === undefined ? null : parsedValue;

export * from 'yup'
export * from './yearMonthValidator';
export { mixed, number, string, date, object, array, boolean };
