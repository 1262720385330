import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';
import { fetch } from '../fetch';
import { CatalogActivity } from './catalogActivities';

declare const CATALOG_ROOT: string;

export function getMigrationRecipe(request: GetMigrationRecipeRequest): Promise<MigrationRecipe> {
    const url = `${CATALOG_ROOT}/activities/${request.engineId}/migration-recipes`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            migrationActivities: request.activities,
        }),
    };
    return fetch(url, requestOptions)
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetMigrationRecipeRequest {
    engineId: number,
    activities: CatalogActivity[],
}

export interface MigrationRecipe {
    migratedActivities: {
        [key: string]: CatalogActivity,
    },
    removedKeys: string[],
    replacementOptions: {
        [key: string]: CatalogActivity[],
    },
}
