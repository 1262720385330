import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

export enum ErrorType {
    NONE = 'NONE',
    REQUEST = 'REQUEST',
    UI = 'UI',
    MESSAGE = 'MESSAGE',
}

const ERROR = 'ERROR';
const CLEAR = 'CLEAR';
const MESSAGE = 'MESSAGE';

export interface IErrorAction {
    error?: Error | string;
    type: string;
    errorType?: ErrorType;
}

export const ERRORS = {
    ERROR: `${ERROR}_ERROR`,
    REQUEST: `${ERROR}_ERROR`,
    MESSAGE: `${MESSAGE}_ERROR`,
    CLEAR: `${CLEAR}_ERROR`,
};

export const clearError: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IErrorAction>
> = () => {
    return async (dispatch: Dispatch<IErrorAction>): Promise<Action> => {
        return dispatch({
            type: ERRORS[CLEAR],
        });
    };
};

export const setMessage: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IErrorAction>
> = (error: string, type) => {
    if (!type) {
        type = ErrorType.REQUEST;
    }

    return async (dispatch: Dispatch<IErrorAction>): Promise<Action> => {
        return dispatch({
            type: ERRORS[type],
            errorType: type,
            error,
        });
    };
};
