import { ComponentType } from 'react';
import { contractCatagory, IConfiguration } from '../../data/model/configuration';
import { msaFactory } from './msa-factory';
import { defaultFactory } from './default-factory';
import { CapsProps } from '../CapsFormSection/CapsProps';
import { PricingContributionMarginProps } from '../PricingContributionMarginFormSection/PricingContributionMarginProps';
import { LdBonusProps } from '../LdBonusFormSection/LdBonusProps';

export function createPricingFormSectionFactory(configuration: IConfiguration): PricingFormSectionFactory {
    const contractCategory = configuration.contractCategory;
    const isMsaCategory = contractCategory === contractCatagory.MSA_PREVENTIVE ||
        contractCategory === contractCatagory.MSA_PREVENTIVE_AND_CORRECTIVE;

    return isMsaCategory
        ? msaFactory
        : defaultFactory;
}

export interface PricingFormSectionFactory {
    PricingContributionMargin: ComponentType<PricingContributionMarginProps>,
    LdBonus: ComponentType<LdBonusProps>,
    Caps: ComponentType<CapsProps>,
}
