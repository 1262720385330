import * as React from 'react';
import './Settings.scss';

import { withStyles } from '@material-ui/core/styles';
import SectionTitle from '../SectionTitle/SectionTitle';

const styles = (theme: any) => ({
    header: {
        padding: '0px 0px 33px 0',
    },
});

const Settings = props => {
    const { classes } = this.props;

    return (
        <React.Fragment>
            <SectionTitle>Settings</SectionTitle>
        </React.Fragment>
    );
};

export default withStyles(styles)(Settings);
