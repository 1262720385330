import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';

export const PaymentTerm: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'payment.paymentTerm';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Payment Term'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage || '(Days Net)'}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

const options: IFormOption[] = [
    formOption('10'),
    formOption('14'),
    formOption('15'),
    formOption('20'),
    formOption('21'),
    formOption('25'),
    formOption('30'),
    formOption('40'),
    formOption('45'),
    formOption('60'),
    formOption('75'),
    formOption('90'),
    formOption('120'),
];
