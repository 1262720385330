import React, { cloneElement, FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';
import { InputAdornment } from '@material-ui/core';

export const ExtraWorkDiscountLabor: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'payment.extraWorkDiscountLabor';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Extra Work Discount Labor'
                    type='number'
                    placeholder='0-99'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    inputProps={{
                        min: '0',
                        max: '99',
                    }}
                />,
        });
    };
