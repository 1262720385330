import { hasValue } from './utils/form';
import { AccessTokenExpired, ApiError } from './error';
import { checkAccessToken, exchangeAuthorizationCodeForTokens, refreshAccessToken } from './auth-api';
import { IUserInfo } from './model/user';
import { parseJwt } from './jwt';
import { authInfo, authRoot } from './auth-declarations';

const APP_ACCESS_TOKEN_STORAGE_KEY: string = 'MYAC_ACCESS_TOKEN';
const APP_REFRESH_TOKEN_STORAGE_KEY: string = 'MYAC_REFRESH_TOKEN';
const APP_URL_BEFORE_AUTH_KEY: string = 'MYAC_URL_BEFORE_AUTH';

export function authenticateUser(): Promise<IUserInfo> {
    if (isRedirectedFromSSO()) {
        return exchangeAuthCodeAndStoreTokens();
    } else if (hasStoredAccessToken()) {
        return validateStoredAccessToken()
            .catch(err => {
                if (err instanceof AccessTokenExpired) {
                    return refreshAndStoreNewTokens();
                } else {
                    throw err;
                }
            });
    } else {
        storeCurrentUrl();
        return redirectToLoginPage();
    }
}

export function logout(): void {
    deleteStoredAccessToken();
    deleteStoredRefreshToken();
    redirectToLogoutPage();
}

function hasStoredAccessToken() {
    return hasValue(getStoredAccessToken());
}

function getStoredAccessToken() {
    return window.sessionStorage.getItem(APP_ACCESS_TOKEN_STORAGE_KEY);
}

function storeAccessToken(accessToken: string): void {
    window.sessionStorage.setItem(APP_ACCESS_TOKEN_STORAGE_KEY, accessToken);
}

function deleteStoredAccessToken(): void {
    window.sessionStorage.removeItem(APP_ACCESS_TOKEN_STORAGE_KEY);
}

function isRedirectedFromSSO(): boolean {
    return hasValue(getAuthCode());
}

function getAuthCode() {
    const url = new URL(window.location.href);
    return url.searchParams.get("code");
}

function validateStoredAccessToken(): Promise<IUserInfo> {
    const accessToken = getStoredAccessToken();
    return checkAccessToken(accessToken);
}

function refreshAndStoreNewTokens(): Promise<IUserInfo> {
    const refreshToken = getStoredRefreshToken();
    return refreshAccessToken(refreshToken)
        .then(tokens => {
            storeAccessToken(tokens.access_token);
            storeRefreshToken(tokens.refresh_token);

            return parseJwt(tokens.access_token) as IUserInfo;
        })
        .catch(err => {
            if (err instanceof ApiError) {
                return redirectToLoginPage();
            }
        });
}

function exchangeAuthCodeAndStoreTokens(): Promise<IUserInfo> {
    const authCode = getAuthCode();
    return exchangeAuthorizationCodeForTokens(authCode)
        .then(tokens => {
            const accessToken = tokens.access_token;
            const refreshToken = tokens.refresh_token;
            storeAccessToken(accessToken);
            storeRefreshToken(refreshToken);
            restoreUrlBeforeAuth();
            deleteStoredUrlBeforeAuth();

            return parseJwt(accessToken) as IUserInfo;
        });
}

function restoreUrlBeforeAuth(): void {
    const originalUrl = getStoredUrlBeforeAuth();
    if (originalUrl) {
        window.location.replace(originalUrl);
    }
}

function getStoredRefreshToken() {
    return window.sessionStorage.getItem(APP_REFRESH_TOKEN_STORAGE_KEY);
}

function storeRefreshToken(refreshToken: string): void {
    window.sessionStorage.setItem(APP_REFRESH_TOKEN_STORAGE_KEY, refreshToken);
}

function deleteStoredRefreshToken(): void {
    window.sessionStorage.removeItem(APP_REFRESH_TOKEN_STORAGE_KEY);
}

function storeCurrentUrl(): void {
    const url = window.location.href;
    window.sessionStorage.setItem(APP_URL_BEFORE_AUTH_KEY, url);
}

function getStoredUrlBeforeAuth(): string {
    return window.sessionStorage.getItem(APP_URL_BEFORE_AUTH_KEY);
}

function deleteStoredUrlBeforeAuth(): void {
    window.sessionStorage.removeItem(APP_URL_BEFORE_AUTH_KEY);
}

function redirectToLoginPage(): Promise<any> {
    const encodedClientId = encodeURIComponent(authInfo.clientId);
    const encodedRedirectUri = encodeURIComponent(authInfo.redirectUri);
    const redirectUrl = `${authRoot}/oauth/authorize?client_id=${encodedClientId}&` +
        `response_type=code&redirect_uri=${encodedRedirectUri}`;
    window.location.replace(redirectUrl);

    return Promise.reject({ redirecting: true });
}

function redirectToLogoutPage(): void {
    // Currently we don't have our own logout page in the app
    // and redirecting to "/" doesn't work because the user will be
    // immediately logged back in making it seem like the logout
    // is broken. This is an ok alternative for now.
    window.location.assign('https://www.innio.com');
}

export const getToken = () => Promise.resolve(getStoredAccessToken());

export function renewToken(): Promise<string> {
    return refreshAndStoreNewTokens()
        .then(() => getStoredAccessToken());
}
