import React, { cloneElement } from 'react';
import { FieldProps } from './FieldProps';
import { Typography } from '@material-ui/core';
import { IndexRatio } from './IndexRatio';
import { IndexType } from './IndexType';
import { IndexName } from './IndexName';
import { IndexDate } from './IndexDate';
import { withVariablePriceEscalation } from './withEscalationType';

export const Index = withVariablePriceEscalation<IndexProps>(
    ({ type, title, formikProps, container }) => {
        return cloneElement(container, {
            children:
                <>
                    <Typography variant='h6'>{title}</Typography>
                    <IndexRatio
                        type={type}
                        title={`${title} Index Ratio`}
                        formikProps={formikProps}
                        container={<></>}
                    />
                    <IndexName type={type} title={`${title} Index Name`} formikProps={formikProps}/>
                    <IndexDate type={type} title={`${title} Index Date`} formikProps={formikProps}/>
                </>,
        });
    },
);

export interface IndexProps extends FieldProps {
    type: IndexType,
    title: string,
}
