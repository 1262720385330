import React, { FunctionComponent, ReactNode } from 'react';
import { Authority } from '../../data/model/user';
import { CaseAuthorized } from './CaseAuthorized';

export const CaseAuthorizedToCreateModel: FunctionComponent<ICaseAuthorizedToCreateModel> =
    props =>
        <CaseAuthorized for={Authority.WRITE_MODELS_IN_PROGRESS}>
            {props.children}
        </CaseAuthorized>;

export interface ICaseAuthorizedToCreateModel {
    children: (authorized: boolean) => ReactNode,
}

