import React, { FunctionComponent } from 'react';
import { formatCurrency } from "../../data/utils/number";
import { createDataTestProps } from "../Utils/testProps";

interface ISubtotalDataCell {
    testName: string,
    amount: number,
    currency: string,
}

const SubtotalDataCell: FunctionComponent<ISubtotalDataCell> = props => {
    const testProps = createDataTestProps(props.testName);
    const value = formatCurrency(props.currency, props.amount);
    return (
        <div {...testProps}>
            {value}
        </div>
    );
};
export default SubtotalDataCell;