import React, { FC } from 'react';
import { guaranteeUnitOptions, guaranteeTypeOptions, defaultLdBonusUnitOptions } from '../GuaranteesForm/GuaranteeOptions';
import { GuaranteesForm } from '../GuaranteesForm/GuaranteesForm';
import { GuaranteeFormFactoryProps } from '../GuaranteesForm/GuaranteesInterfaces';

export const DefaultGuarantees: FC<GuaranteeFormFactoryProps> =
    props =>
        <GuaranteesForm
            {...props}
            guaranteeTypeOptions={guaranteeTypeOptions}
            guaranteeUnitOptions={guaranteeUnitOptions}
            ldBonusUnitOptions={defaultLdBonusUnitOptions}
        />;
