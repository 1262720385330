import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { IndexType } from './IndexType';
import { CalculationMethod, Escalation } from '../../api/model-service/model-service';
import { FormikProps } from '../Formik';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { TextField } from '../TextField/TextField';
import { hasIndexRatio } from './hasIndexRatio';

export const IndexDate: FC<IndexNameProps> =
    ({ type, title, formikProps }) => {
        if (!hasIndexRatio(type, formikProps)) {
            return null;
        }

        const name = `${type}Index.date`;
        const value = inputValue(name, formikProps);
        const errorMessage = inputError(name, formikProps);

        // TODO hide field if no calculation method selected yet?
        const calculationMethod =
            inputValue('calculationMethod', formikProps) as CalculationMethod;
        return calculationMethod === 'YEARLY_AVERAGE'
            ? <SelectField
                name={name}
                label={title}
                value={value}
                options={yearOptions}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='normal'
                fullWidth
                required
            />
            : <TextField
                name={name}
                label={title}
                type='month'
                value={value}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='normal'
                fullWidth
                required
                InputLabelProps={{
                    shrink: true,
                }}
            />;
    };

export interface IndexNameProps {
    type: IndexType;
    title: string,
    formikProps: FormikProps<Escalation>,
}

const yearOptions: IFormOption[] = [];
for (let year = 1999; year <= 2040; year += 1) {
    const january = year + '-01';
    yearOptions.push(formOption(january, '' + year));
}
