import React, { ReactNode } from 'react';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Activity, Configuration } from '../../api/model-service/model-service';
import { Form } from '../Form/Form';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { Button } from '../Buttons/Button';
import { append, replace } from '../../utils/immutableArray';
import { AdditionalScopeProps } from '../AdditionalScopeFormSection/AdditionalScopeProps';
import { EditAdditionalActivityDialog } from '../EditAdditionalActivityDialog/EditAdditionalActivityDialog';
import { dissoc } from 'lodash/fp';
import { AdditionalActivityCard } from '../AdditionalActivityCard/AdditionalActivityCard';
import { isModelAsSold } from '../../utils/isModelAsSold';

class AdditionalActivitiesForm0 extends ConfigurationForm<AdditionalScopeProps & StyledComponentProps, ComponentState> {

    private static defaultValues(configuration: Configuration): Configuration {
        return {
            ...configuration,
            additionalScope: [],
            siteLevelScope: [],
        };
    }

    public state = {
        openAddActivityDialog: false,
    };

    public render(): ReactNode {
        const props = this.props;

        const initialValues = props.configuration.additionalScope
            ? props.configuration
            : AdditionalActivitiesForm0.defaultValues(props.configuration);

        const disabled = this.isDisabled();

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(config, actions) => this.handleSubmit(config, actions)}
                enableReinitialize
            >
                {(formikProps: FormikProps<Configuration>) =>
                    <Form testName='additionalActivities' noValidate>
                        {!disabled
                            ? <Grid item xs={12}>
                                <Button
                                    testName='addAdditionalScope'
                                    color='primary'
                                    onClick={() => this.openAddActivityDialog()}
                                >
                                    Add Additional Scope
                                    <Add className={props.classes.rightIcon}/>
                                </Button>
                                <EditAdditionalActivityDialog
                                    open={this.state.openAddActivityDialog}
                                    onSubmit={activity => {
                                        this.addNewActivityToUnitOrSite(formikProps, activity);
                                        this.closeAddEscalationDialog();
                                    }}
                                    onCancel={() => this.closeAddEscalationDialog()}
                                />
                            </Grid>
                            : null
                        }

                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={16}
                            classes={{ container: this.props.classes.cardsContainer }}
                        >
                            {this.getActivityCards(formikProps, 'additionalScope')}
                            {this.getActivityCards(formikProps, 'siteLevelScope')}
                        </Grid>
                        <ConfigurationFormButtons
                            loading={formikProps.isSubmitting}
                            onBack={props.onBack}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onNext={() => this.submitForm(formikProps, 'NEXT')}
                            onReadOnlyNext={props.onNext}
                            onSecondaryComplete={this.isConfigurationComplete()
                                ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                : null
                            }
                        />
                    </Form>
                }
            </Formik>
        )
    }

    private isDisabled() {
        return !this.props.authorizedToEdit || isModelAsSold(this.props.model);
    }

    private handleSubmit(configuration: Configuration, actions: FormikActions<Configuration>): void {
        this.props.onSubmit(configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private addNewActivityToUnitOrSite(formikProps: FormikProps<Configuration>, activity: Activity): void {
        // If the ID is present the backend might delete the activity when moving it
        // from 'unit' to 'site' or vice-versa.
        const newActivity = dissoc('id', activity);

        if (this.isUnitLevel(newActivity)) {
            this.appendToUnitActivities(newActivity, formikProps);
        } else {
            this.appendToSiteActivities(newActivity, formikProps);
        }
    }

    private appendToUnitActivities(activity: Activity, formikProps: FormikProps<Configuration>): void {
        const activitiesCopy = append(formikProps.values.additionalScope, activity);
        formikProps.setFieldValue('additionalScope', activitiesCopy);
    }

    private appendToSiteActivities(activity: Activity, formikProps: FormikProps<Configuration>): void {
        const activitiesCopy = append(formikProps.values.siteLevelScope, activity);
        formikProps.setFieldValue('siteLevelScope', activitiesCopy);
    }

    private getActivityCards(formikProps: FormikProps<Configuration>, activityListField: AdditionalActivityField): React.ReactNode {
        const disabled = this.isDisabled();
        const activities: Activity[] = formikProps.values[activityListField];
        return activities.map((activity, idx) => {
            const activityKey = this.getAdditionalActivityKey(activity);

            return (
                <Grid key={activityKey} item xs={12} sm={6} md={4}>
                    <AdditionalActivityCard
                        disabled={disabled}
                        testId={activityKey}
                        activity={activity}
                        onChange={updatedActivity => {
                            if (this.isScopeDifferent(updatedActivity, activity)) {
                                this.removeActivityFromUnitAndSite(formikProps, updatedActivity);
                                this.addNewActivityToUnitOrSite(formikProps, updatedActivity);
                            } else {
                                this.replaceActivity(formikProps, idx, updatedActivity, activityListField);
                            }
                        }}
                        onDelete={() => {
                            this.removeActivity(formikProps, activity, activityListField);
                        }}
                    />
                </Grid>
            );
        })
    }

    private isScopeDifferent(a: Activity, b: Activity): boolean {
        return a.scope !== b.scope;
    }

    private removeActivityFromUnitAndSite(formikProps: FormikProps<Configuration>, activityToRemove: Activity): void {
        this.removeActivity(formikProps, activityToRemove, 'additionalScope');
        this.removeActivity(formikProps, activityToRemove, 'siteLevelScope');
    }

    private removeActivity(formikProps: FormikProps<Configuration>,
                           activityToRemove: Activity,
                           activityListField: AdditionalActivityField): void {

        const remainingActivities: Activity[] = formikProps.values[activityListField]
            .filter(activity => activity.id !== activityToRemove.id);
        formikProps.setFieldValue(activityListField, remainingActivities);
    }

    private replaceActivity(formikProps: FormikProps<Configuration>,
                            index: number,
                            updatedActivity: Activity,
                            activityListField: AdditionalActivityField): void {

        const activities: Activity[] = formikProps.values[activityListField];
        const activitiesCopy = replace(activities, index, updatedActivity);
        formikProps.setFieldValue(activityListField, activitiesCopy);
    }

    private isUnitLevel(activity: Activity): boolean {
        return activity.scope === 'UNIT';
    }

    private getAdditionalActivityKey(activity: Activity): string {
        const { scope, service, type, frequency } = activity;
        return `${scope}-${service}-${type}-${frequency}`
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }

    private openAddActivityDialog(): void {
        this.setState({ openAddActivityDialog: true });
    }

    private closeAddEscalationDialog(): void {
        this.setState({ openAddActivityDialog: false });
    }
}

interface ComponentState {
    openAddActivityDialog: boolean,
}

type AdditionalActivityField = 'additionalScope' | 'siteLevelScope';

const styles: StyleRules = {
    cardsContainer: {
        paddingBottom: 30,
        paddingTop: 30,
    },
    rightIcon: {
        marginLeft: '10px',
    },
};

export const AdditionalActivitiesForm = withStyles(styles)(AdditionalActivitiesForm0);
