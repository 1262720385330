import * as React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '../../../../Buttons/Button';

import MaterialTable from '../../../../Tables/MaterialTable';
import MTableEditRow from '../../../../Tables/MTableEditRow';
import {FFInput} from '../../../../Form/input';
import {defaultsDeep, findIndex} from 'lodash';
import {hasValue, iv} from '../../../../../data/utils/form';
import {debounce} from "../../../../../utils/UtilFunctions";
import {PricingContributionMarginProps} from '../../../../PricingContributionMarginFormSection/PricingContributionMarginProps';
import {DiscountMatrix, ReferencePrice} from '../../../../../data/model/configuration_pricing';
import {CaseAuthorizedToEditForm} from '../../../../Form/formAuthorizationContext';

const isValidBilling = (billing) : boolean => {
    return hasValue(billing.rate) &&
        billing.rate !== "";
};

const isValidUsageBilling = (billing) : boolean => {
    return isValidBilling(billing) &&
        hasValue(billing.billingEndCounter) &&
        billing.billingEndCounter !== "" &&
        hasValue(billing.billingStartCounter) &&
        billing.billingStartCounter !== "" &&
        hasValue(billing.uom);
};

const isValidFixedBilling = (billing) : boolean => {
    return isValidBilling(billing) &&
        hasValue(billing.frequency) &&
        hasValue(billing.scope);
};

const isValidMilestoneBilling = (billing) : boolean => {
    return isValidBilling(billing) &&
        hasValue(billing.trigger) &&
        billing.trigger !== "";
};

const styles = (theme: any) => ({
    requiredMissing: {
        borderColor: 'red',
        borderStyle: 'solid',
    },
});

let defaultUsageBasedBillings = {};

const debouncedUpdateTargetPricing = debounce((props, name, value) => {
    props.updateTargetPricing(
        props.configuration,
        name,
        value
    );
}, 1000);

function getTestProps(props) {
    const testName = props.mode === 'add'
        ? 'addBilling'
        : 'editBilling';
    const testId = hasValue(props.index)
        ? props.index.toString()
        : null;

    return { testName, testId };
}

const referencePriceOptions = [
    iv(ReferencePrice.GROSS, 'GROSS'),
    iv(ReferencePrice.Dnet, 'Dnet'),
];

const discountMatrixOptions = [
    iv(DiscountMatrix.CHANNEL_PARTNER, 'Channel Partner'),
    iv(DiscountMatrix.KEY_ACCOUNT, 'Key Account'),
];

class PricingContributionMargin extends React.Component<PricingContributionMarginBaseProps, any> {
    public state = {
        focused: '',
        mounted: false,
        userTargetPrice: null,
        userTargetCm: null,
        editing: null,
    };

    public componentDidMount(): void {
        this.setState({ mounted: true });
    }

    public render(): React.ReactElement {
        const {
            validate,
            pricing,
            mergeConfig,
            configuration,
            pricingTarget,
        } = this.props;

        if (configuration.units.length > 0) {
            defaultUsageBasedBillings = {
                billingStartCounter:
                    configuration.units[configuration.units.length - 1]
                        .unitStartCounter,
                billingEndCounter:
                    configuration.units[configuration.units.length - 1]
                        .unitEndCounter,
                uom: 'OPH',
            };
        }

        let billingOnRowAdd = (fieldName:string,validateData:(data:any)=>boolean) => (newData?:any) =>
            new Promise<void>((resolve, reject) => {

                if(fieldName==='usageBillings') {
                    newData = defaultsDeep(
                        newData,
                        Object.assign(
                            {},
                            defaultUsageBasedBillings
                        )
                    );
                }

                if (!newData.uom) {
                    newData.uom = 'OPH';
                }

                if (!validateData(newData)) {
                    reject();
                    return;
                }

                validate().then(valid => {
                    if (!valid) {
                        reject();
                        return;
                    }

                    if (!pricing[fieldName]) {
                        pricing[fieldName] = [];
                    }
                    pricing[fieldName].push(newData);
                    mergeConfig(
                        'pricing.' + fieldName,
                        pricing[fieldName],
                        true
                    );
                    resolve();
                });
            });

        let billingOnRowUpdate = (fieldName:string,validateData:(data:any)=>boolean) => (newData:any, oldData:any) =>
            new Promise<void>((resolve, reject) => {
                if (!validateData(newData)) {
                    reject();
                    return;
                }

                validate().then(valid => {
                    if (!valid) {
                        reject();
                        return;
                    }

                    const index = findIndex(
                        pricing[fieldName],
                        oldData
                    );
                    pricing[fieldName][
                        index
                        ] = newData;
                    mergeConfig(
                        'pricing.' + fieldName,
                        pricing[fieldName],
                        true
                    );
                    resolve();
                });
            });

        let billingOnRowDelete = (fieldName:string) => (oldData:any) =>
            new Promise<void>((resolve, reject) => {
                validate().then(valid => {
                    if (!valid) {
                        reject();
                        return;
                    }

                    const index = findIndex(
                        pricing[fieldName],
                        oldData
                    );
                    pricing[fieldName].splice(
                        index,
                        1
                    );
                    mergeConfig(
                        'pricing.' + fieldName,
                        pricing[fieldName],
                        true
                    );
                    resolve();
                });
            });

        return (
            <React.Fragment>
                <Grid container spacing={24}>
                    {this.props.showToolTargetPrice
                        ? <Grid item xs={4} sm={4}>
                            <FFInput
                                label="Tool Target Price"
                                name="pricing.toolTargetPrice"
                                value={getNonNullValue(pricing.toolTargetPrice)}
                                validators={[
                                    'minNumber:0',
                                ]}
                                errorMessages={[
                                    'Must be 0 or greater',
                                ]}
                                onChange={e => mergeConfig('pricing.toolTargetPrice', e)}
                                margin="dense"
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        : null
                    }
                    {this.props.showToolTargetDiscount
                        ? <Grid item xs={4} sm={4}>
                            <FFInput
                                label="Tool Target Discount"
                                name="pricing.toolTargetDiscount"
                                value={getNonNullValue(pricing.toolTargetDiscount)}
                                validators={[
                                    'minNumber:0',
                                    'maxNumber:100',
                                ]}
                                errorMessages={[
                                    'Must be 0 or greater',
                                    'Must be 100 or less',
                                ]}
                                required
                                onChange={e => mergeConfig('pricing.toolTargetDiscount', e)}
                                margin="dense"
                                type="number"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        : null
                    }
                    {this.props.showActualDiscount
                        ? <Grid item xs={4} sm={4}>
                            <FFInput
                                label="Actual Discount"
                                name="pricing.actualDiscount"
                                value={getNonNullValue(pricing.actualDiscount)}
                                validators={[
                                    'minNumber:0',
                                    'maxNumber:100',
                                ]}
                                errorMessages={[
                                    'Must be 0 or greater',
                                    'Must be 100 or less',
                                ]}
                                required
                                onChange={e => mergeConfig('pricing.actualDiscount', e)}
                                margin="dense"
                                type="number"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        : null
                    }
                    {this.props.showReferencePriceList
                        ? <Grid item xs={4} sm={4}>
                            <CaseAuthorizedToEditForm>
                                {authorizedToEdit =>
                                    <FFInput
                                        name="pricing.referencePriceList"
                                        fullWidth
                                        select
                                        required
                                        errorMessages={['The price list reference is required']}
                                        options={referencePriceOptions}
                                        label="Reference Price List *"
                                        disabled={!authorizedToEdit}
                                        value={pricing.referencePriceList}
                                        onChange={selectedOption => {
                                            mergeConfig('pricing.referencePriceList', selectedOption);
                                        }}
                                    />
                                }
                            </CaseAuthorizedToEditForm>
                        </Grid>
                        : null
                    }
                    {this.props.showDiscountMatrix &&
                        pricing.referencePriceList === ReferencePrice.Dnet
                        ? <Grid item xs={4} sm={4}>
                            <CaseAuthorizedToEditForm>
                                {authorizedToEdit =>
                                    <FFInput
                                        name="pricing.discountMatrix"
                                        fullWidth
                                        required
                                        select
                                        errorMessages={['Discount matrix value is required']}
                                        options={discountMatrixOptions}
                                        label="Discount Matrix *"
                                        disabled={!authorizedToEdit}
                                        value={pricing.discountMatrix}
                                        onChange={selectedOption => {
                                            mergeConfig('pricing.discountMatrix', selectedOption);
                                        }}
                                    />
                                }
                            </CaseAuthorizedToEditForm>
                        </Grid>
                        : null
                    }
                    {this.props.showUserTargets ?
                        <>
                            <Grid item xs={4} sm={4}>
                                <FFInput
                                    label="User Target Price / Oph"
                                    name="pricing.pricingTarget.userTargetPrice"
                                    value={
                                        this.state.userTargetPrice ||
                                        (this.state.editing !== 'userTargetPrice' && pricingTarget && pricingTarget.userTargetPrice) ||
                                        ''
                                    }
                                    onChange={e => this.updatePricing('userTargetPrice', e)}
                                    onFocus={e => this.startEditing('userTargetPrice')}
                                    onBlur={e => this.stopEditing() }
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                €
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                <FFInput
                                    label="User Target CM"
                                    margin="dense"
                                    name="pricing.pricingTarget.userTargetCm"
                                    value={
                                        this.state.userTargetCm ||
                                        (this.state.editing !== 'userTargetCm' && pricingTarget && pricingTarget.userTargetCm) ||
                                        ''
                                    }
                                    onChange={e => this.updatePricing('userTargetCm', e)}
                                    onFocus={e => this.startEditing('userTargetCm')}
                                    onBlur={e => this.stopEditing() }
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid> 
                        </>: null
                    }
                    <Grid item xs={12} sm={12} md={12}>
                        <Button
                            testName="delegationOfAuthority"
                            target="_blank"
                            href="http://s.bl-1.com/h/cjDgGQt8?url=http://edms.pw.ge.com/dctmquality/home/components/drl/drl.jsp?objectId=0900f5ea80975093&showRendition=true"
                        >
                            Delegation of Authority (DoA)
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FFInput
                            id="description"
                            label="Pricing Description"
                            name="description"
                            placeholder=""
                            validators={['maxStringLength:1000']}
                            errorMessages={[
                                'The description must be under 1000 characters',
                            ]}
                            onChange={e =>
                                mergeConfig('pricing.description', e)
                            }
                            fullWidth
                            margin="dense"
                            multiline
                            rowsMax="3"
                            value={pricing.description}
                            helperText={`${(pricing.description &&
                                pricing.description.length) ||
                                0}/1000`}
                        />
                    </Grid>
                </Grid>
                <div className="pt-3 pb-3 material-table">
                    <MaterialTable
                        tableTestName="usageBasedBillingsPerUnit"
                        rowTestName="billing"
                        title="Usage Based Billings / Unit"
                        options={{
                            paging: false,
                            search: false,
                            showTitle: true,
                            toolbar: true,
                            actionsColumnIndex: -1,
                            toolbarButtonAlignment: 'left',
                        }}
                        components={{
                            EditRow: editProps => {
                                const props = {...editProps};
                                if (!props.data) {
                                    props.data = {
                                        rate : undefined,
                                        uom : 'OPH',
                                        billingStartCounter : configuration.units[
                                                                configuration.units.length - 1
                                                                    ].unitStartCounter,
                                        billingEndCounter : configuration.units[
                                                                configuration.units.length - 1
                                                                    ].unitEndCounter,
                                    }
                                }

                                return (
                                    <MTableEditRow {...getTestProps(props)} {...props} />
                                )
                            }
                        }}
                        columns={[
                            { title: 'Title', field: 'title' },
                            {
                                title: 'Rate € ',
                                field: 'rate',
                                type: 'numeric',
                            },
                            {
                                title: 'UOM',
                                field: 'uom',
                                lookup: {
                                    OPH: 'Oph',
                                },
                            },
                            {
                                title: 'Billing Start',
                                field: 'billingStartCounter',
                                type: 'numeric',
                            },
                            {
                                title: 'Billing End',
                                field: 'billingEndCounter',
                                type: 'numeric',
                            },
                        ]}
                        data={pricing.usageBillings}
                        editable={{
                            onRowAdd: billingOnRowAdd('usageBillings',isValidUsageBilling),
                            onRowUpdate: billingOnRowUpdate('usageBillings',isValidUsageBilling),
                            onRowDelete: billingOnRowDelete('usageBillings'),
                        }}
                    />
                </div>
                <div className="pt-3 pb-3 material-table">
                    <MaterialTable
                        tableTestName="fixedBillings"
                        rowTestName="billing"
                        title="Fixed Billings"
                        options={{
                            paging: false,
                            search: false,
                            showTitle: true,
                            toolbar: true,
                            actionsColumnIndex: -1,
                            toolbarButtonAlignment: 'left',
                        }}
                        columns={[
                            { title: 'Title', field: 'title' },
                            {
                                title: 'Rate € ',
                                field: 'rate',
                                type: 'numeric',
                            },
                            {
                                title: 'Frequency',
                                field: 'frequency',
                                lookup: {
                                    YEARLY: 'Annually',
                                    QUARTERLY: 'Quarter',
                                    MONTHLY: 'Month',
                                },
                            },
                            {
                                title: 'Scope',
                                field: 'scope',
                                lookup: { UNIT: 'Unit', SITE: 'Site' },
                            },
                        ]}
                        components={{
                            EditRow: props => <MTableEditRow {...getTestProps(props)} {...props} />
                        }}
                        data={pricing.fixedBillings}
                        editable={{
                            onRowAdd: billingOnRowAdd('fixedBillings',isValidFixedBilling),
                            onRowUpdate: billingOnRowUpdate('fixedBillings',isValidFixedBilling),
                            onRowDelete: billingOnRowDelete('fixedBillings'),
                        }}
                    />
                </div>
                <div className="pt-3 pb-3 material-table">
                    <MaterialTable
                        tableTestName="milestoneBillingsPerUnit"
                        rowTestName="billing"
                        title={"Milestone Billings / Unit"}
                        options={{
                            paging: false,
                            search: false,
                            showTitle: true,
                            toolbar: true,
                            actionsColumnIndex: -1,
                            toolbarButtonAlignment: 'left',
                        }}
                        columns={[
                            {title: 'Title', field: 'title'},
                            {
                                title: 'Rate € ',
                                field: 'rate',
                                type: 'numeric',
                            },
                            {
                                title: 'Trigger',
                                field: 'trigger',
                                type: 'numeric',
                            },
                            {
                                title: 'UOM',
                                field: 'uom',
                                lookup: {
                                    OPH: 'Oph',
                                },
                            },
                        ]}
                        components={{
                            EditRow: editProps => {
                                const props = {...editProps};
                                if (!props.data) {
                                    props.data = {
                                        rate: undefined,
                                        uom: 'OPH',
                                        trigger: undefined,
                                    }
                                }

                                return (
                                    <MTableEditRow {...getTestProps(props)} {...props} />
                                )
                            }
                        }}
                        data={pricing.milestoneBillings}
                        editable={{
                            onRowAdd: billingOnRowAdd('milestoneBillings',isValidMilestoneBilling),
                            onRowUpdate: billingOnRowUpdate('milestoneBillings',isValidMilestoneBilling),
                            onRowDelete: billingOnRowDelete('milestoneBillings')
                        }}
                    />
                </div>

                <div className="pt-3 pb-3 material-table">
                    {this.props.showPackageBilling &&
                        <MaterialTable
                            tableTestName="packageBillingsPerUnit"
                            rowTestName="billing"
                            title={"Package Billings / Unit"}
                            options={{
                                paging: false,
                                search: false,
                                showTitle: true,
                                toolbar: true,
                                actionsColumnIndex: -1,
                                toolbarButtonAlignment: 'left',
                            }}
                            columns={[
                                {title: 'Title', field: 'title'},
                                {
                                    title: 'Rate € ',
                                    field: 'rate',
                                    type: 'numeric',
                                },
                                {
                                    title: 'Trigger',
                                    field: 'trigger',
                                    type: 'numeric',
                                },
                                {
                                    title: 'UOM',
                                    field: 'uom',
                                    lookup: {
                                        OPH: 'Oph',
                                    },
                                },
                                {
                                    title: 'Standalone Package',
                                    field: 'alwaysBilled',
                                    type: 'boolean'
                                },
                            ]}
                            components={{
                                EditRow: editProps => {
                                    const props = {...editProps};
                                    if (!props.data) {
                                        props.data = {
                                            rate: undefined,
                                            uom: 'OPH',
                                            trigger: undefined,
                                            alwaysBilled: false,
                                        }
                                    }

                                    return (
                                        <MTableEditRow {...getTestProps(props)} {...props} />
                                    )
                                }
                            }}
                            data={pricing.packageBillings}
                            editable={{
                                onRowAdd: billingOnRowAdd('packageBillings',isValidMilestoneBilling),
                                onRowUpdate: billingOnRowUpdate('packageBillings',isValidMilestoneBilling),
                                onRowDelete: billingOnRowDelete('packageBillings')
                            }}
                        />
                    }
                </div>
            </React.Fragment>
        );
    }

    private updatePricing(name, event): void {
        const value = event.target.value;
        this.setState({ [name]: value });

        debouncedUpdateTargetPricing(this.props, name, +value);
    }

    private startEditing(name : string) : void {
        this.setState({ editing : name});
    }

    private stopEditing() : void {
        this.setState({ editing : null, userTargetCm : null, userTargetPrice: null});
    }
}

export default withStyles(styles)(PricingContributionMargin);

export interface PricingContributionMarginBaseProps extends PricingContributionMarginProps {
    showToolTargetPrice: boolean,
    showToolTargetDiscount: boolean,
    showActualDiscount: boolean,
    showReferencePriceList: boolean,
    showDiscountMatrix: boolean,
    showPackageBilling:boolean;
    showUserTargets: boolean;
}

function getNonNullValue(value: any): string {
    return hasValue(value)
        ? '' + value
        : '';
}
