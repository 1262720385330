import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';

export const Region: FC<RegionProps> =
    ({ formikProps, regions, disabled }) => {
        const name = 'region';
        const errorMessage = inputError(name, formikProps);
        const options: IFormOption[] = regions.map(region => formOption(region));
        return (
            <SelectField
                name={name}
                label='Region'
                value={inputValue(name, formikProps)}
                placeholder='Select a region'
                options={options}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                disabled={disabled}
                margin='dense'
                fullWidth
                required
            />
        );
    };

export interface RegionProps extends FieldProps {
    regions: string[],
}
