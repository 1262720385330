import React, { FC } from 'react';
import { FixedPriceEscalation } from '../../api/model-service/model-service';
import { Typography } from '@material-ui/core';
import { ActionCard } from '../ActionCard/ActionCard';
import { isoToLocaleDate } from '../../data/utils/date';
import { ActionMenuItem } from '../ActionMenu/ActionMenu';

export interface FixPriceEscalationCardProps {
    testId: string,
    escalation: FixedPriceEscalation,
    actions: ActionMenuItem[],
}

export const FixPriceEscalationCard: FC<FixPriceEscalationCardProps> =
    ({ testId, escalation, actions }) =>
        <ActionCard
            testName='escalation'
            testId={testId}
            title='Fixed Price Escalation'
            actions={actions}
        >
            <Typography variant='caption'>
                Billing Base: <strong>{escalation.billingBases.join(', ')}</strong>
                <br/>
                Value: <strong>{escalation.fixedPriceEscalationValue}%</strong>
                <br/>
                Initial Date: <strong>{isoToLocaleDate(escalation.initialPriceEscalationDate)}</strong>
            </Typography>
        </ActionCard>;
