import React, { cloneElement, FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';

export const OtherSpecialPayments: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'payment.otherSpecialPayments';
        const errorMessage = inputError(name, formikProps);
        const value = inputValue(name, formikProps);
        const defaultHelperText = `${value.length}/500`;
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Other Special Payments'
                    value={value}
                    disabled={disabled}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage || defaultHelperText}
                    margin='dense'
                    rowsMax={8}
                    multiline
                    fullWidth
                />,
        });
    };
