import { createAction, createReducer } from '@reduxjs/toolkit';
import { CandidateFilterOptions } from '../../components/ActualizationFilter/ActualizationFilter';

interface CandidateFiltersState {
    loading: boolean,
    error: string,
    data: CandidateFilterOptions,
}

export const startLoading = createAction('candidateFilterOptions/startLoading');
export const errorResponse = createAction<Error>('candidateFilterOptions/errorResponse');
export const successResponse = createAction<CandidateFilterOptions>(
    'candidateFilterOptions/successResponse');

const initialData: CandidateFilterOptions = {
    contractCategories: [],
    catalogRegions: [],
    engineTypes: [],
    contractManagers: [],
    actualizationPeriods: [],
};

const initialState: CandidateFiltersState = {
    loading: false,
    error: null,
    data: initialData,
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            error: null,
            data: action.payload,
        })),
);
export default reducer;
