import React, { Component, ReactNode } from 'react';
import { Table } from '../Tables/Table';
import { TableHeader } from './TableHeader';
import {
    Link,
    StyledComponentProps,
    StyleRulesCallback,
    TableBody,
    TableFooter,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import { TablePagination } from '../Tables/TablePagination';
import { ActualizationResult } from '../../api/model-service/actualization';
import { Checkbox } from '../Form/Checkbox';
import { isEmpty } from 'lodash';
import { ComparisonRow } from './ComparisonRow';
import withStyles from '@material-ui/core/styles/withStyles';
import { NoDataRow } from './NoDataRow';
import { Caret } from './Caret';
import { CatalogRegion, RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { ActivityCatalog } from '../../api/catalog-service/activityCatalogs';

export class ResultsTable extends Component<ComponentProps, TableState> {
    private static PAGE_SIZE = 100;

    private static getAllCollapsedState(results: ExtendedActualizationResult[]): TableState['expanded'] {
        return results.reduce((expanded, result) => {
            expanded[result.id] = false;
            return expanded;
        }, {});
    }

    private static getAllExpandedState(results: ExtendedActualizationResult[]): TableState['expanded'] {
        return results.reduce((expanded, result) => {
            const canExpand = result.comparisonFinished;
            expanded[result.id] = canExpand;
            return expanded;
        }, {});
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            page: 0,
            expanded: ResultsTable.getAllCollapsedState(props.results),
        }
    }

    public componentDidUpdate(previousProps: ComponentProps, prevState: TableState): void {
        if (previousProps.results.length !== this.props.results.length) {
            this.setState({
                page: 0,
                expanded: this.mergeExpandedState(prevState),
            });
        }
    }

    public render(): ReactNode {
        const {testName, onSelectAll} = this.props;
        const displayedResults = this.createPageSlice();
        const hasResults = !isEmpty(displayedResults);

        return (
            <Table testName={testName}>
                <TableHeader
                    allExpanded={this.isAllExpanded()}
                    allSelected={this.isAllSelected()}
                    onSelectAll={evt => onSelectAll(evt.target.checked)}
                    onExpandAll={expand => this.handleToggleAllComparisons(expand)}
                />
                <TableBody>
                    {!hasResults && <NoDataRow colSpan={6}/>}
                    {displayedResults.map(actualizationResult =>
                        <React.Fragment key={this.getRowKey(actualizationResult)}>
                            <TableDataRow
                                classes={{root: this.props.classes.resultsRow}}
                                testName={testName}
                                testId={'' + actualizationResult.id}
                            >
                                <TableDataCell
                                    testName='opportunityNumber'
                                    classes={{root: this.props.classes.tableCell}}
                                >
                                    <Checkbox
                                        testName='selectedItem'
                                        testId={'' + actualizationResult.id}
                                        checked={actualizationResult.isSelected}
                                        onChange={evt => {
                                            this.props.onSelected(actualizationResult.id, evt.target.checked);
                                        }}
                                    />
                                    <span className={this.props.classes.spanClass}>{actualizationResult.candidate.opportunityNumber}</span>
                                    {this.getToggleButton(actualizationResult)}
                                </TableDataCell>
                                <TableDataCell testName='projectName'>
                                    {actualizationResult.candidate.projectName}
                                </TableDataCell>
                                <TableDataCell testName='displayName'>
                                    <Link
                                        target='_blank'
                                        href={this.getConfigurationDetailsUrl(actualizationResult)}
                                    >
                                        {actualizationResult.candidate.displayName}
                                    </Link>
                                </TableDataCell>
                                <TableDataCell testName='equipmentModel'>
                                    {actualizationResult.candidate.equipmentModel}
                                </TableDataCell>
                                <TableDataCell testName='regionName'>
                                    {this.formatCatalogRegionName(actualizationResult.candidate.catalogRegionId)}
                                </TableDataCell>
                                <TableDataCell testName='oracleContractsNumber'>
                                    {actualizationResult.candidate.oracleContractsNumber}
                                </TableDataCell>
                            </TableDataRow>
                            {this.isExpanded(actualizationResult) &&
                                <ComparisonRow regionCatalogs={this.props.regionCatalogs}
                                               activityCatalogs={this.props.activityCatalogs}
                                               actualizationResult={actualizationResult}/>
                            }
                        </React.Fragment>
                    )}
                </TableBody>
                <TableFooter>
                    {this.getTotalPages() > 1
                        ? <TableRow>
                            <TablePagination
                                colSpan={3}
                                rowsPerPageOptions={[]}
                                count={this.props.results.length}
                                page={this.state.page}
                                rowsPerPage={ResultsTable.PAGE_SIZE}
                                onChangePage={(e, page) => this.showPage(page)}
                                SelectProps={{native: true}}
                            />
                        </TableRow>
                        : null
                    }
                </TableFooter>
            </Table>
        );
    }

    private getRowKey(result: ExtendedActualizationResult): string {
        const expanded = this.isExpanded(result);
        return `${result.id}-${result.isSelected}-${expanded}`;
    }

    private getToggleButton(actualizationResult: ExtendedActualizationResult): ReactNode {
        if (actualizationResult.comparisonFinished) {
            return (
                <Caret
                    isExpanded={this.isExpanded(actualizationResult)}
                    onClick={() => this.handleToggleComparison(actualizationResult)}
                />
            );
        }

        return (
            <Tooltip title='Comparison is not ready yet' placement='top'>
                <span>
                    <Caret
                        disabled
                        onClick={() => this.handleToggleComparison(actualizationResult)}
                    />
                </span>
            </Tooltip>
        );
    }

    private mergeExpandedState(prevState: TableState): TableState['expanded'] {
        return this.props.results.reduce((expanded, result) => {
            const id = result.id;
            expanded[id] = prevState.expanded[id] || false;
            return expanded;
        }, {});
    }

    private handleToggleAllComparisons(expand: boolean): void {
        const expanded = expand
            ? ResultsTable.getAllExpandedState(this.props.results)
            : ResultsTable.getAllCollapsedState(this.props.results);
        this.setState({ expanded });
    }

    private handleToggleComparison(actualizationResult: ExtendedActualizationResult): void {
        if (!actualizationResult.comparisonFinished) {
            return;
        }

        this.setState(prevState => {
            const id = actualizationResult.id;
            const wasExpanded = prevState.expanded[id];
            const expanded = {
                ...prevState.expanded,
                [id]: !wasExpanded,
            };
            return { expanded };
        });
    }

    private isExpanded(actualizationResult: ExtendedActualizationResult): boolean {
        return this.state.expanded[actualizationResult.id];
    }

    private formatCatalogRegionName(regionId: number): string {
        const region = this.props.regionCatalogs
            .reduce((acc: CatalogRegion[], cat) => acc.concat(cat.regions), [])
            .find(cat => cat.id === regionId);

        return region ? region.name : null;
    }

    private getConfigurationDetailsUrl(result: ActualizationResult): string {
        // TODO use 2 links: one for the original and one for the clone
        const candidate = result.clone || result.candidate;
        return `/models/${candidate.modelId}/configuration/view/${candidate.id}`;
    }

    private getTotalPages(): number {
        return Math.ceil(this.props.results.length / ResultsTable.PAGE_SIZE);
    }

    private createPageSlice(): ExtendedActualizationResult[] {
        const {page} = this.state;
        const sliceStart = page * ResultsTable.PAGE_SIZE;
        const sliceEnd = (page + 1) * ResultsTable.PAGE_SIZE;

        return this.props.results.slice(sliceStart, sliceEnd);
    }

    private showPage(page: number): void {
        this.setState({page});
    }

    private isAllSelected(): boolean {
        const results = this.props.results;
        return !isEmpty(results) && results.every(result => result.isSelected);
    }

    private isAllExpanded(): boolean {
        const expandableResults = this.props.results.filter(result => result.comparisonFinished);
        return !isEmpty(expandableResults) &&
            expandableResults.every(result => this.isExpanded(result));
    }
}

interface TableState {
    page: number,
    expanded: {
        [id: string]: boolean,
    },
}

export interface ExtendedActualizationResult extends ActualizationResult {
    isSelected: boolean,
}

type ComponentProps = TableProps & StyledComponentProps;

export interface TableProps {
    results: ExtendedActualizationResult[],
    testName: string,
    onSelectAll: (checked: boolean) => void,
    onSelected: (id: number, checked: boolean) => void,
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalog[],
}

const styles: StyleRulesCallback = theme => ({
    resultsRow: {
        background: 'white',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.07)',

        },
    },
    spanClass: {
        verticalAlign: 'middle',
    },
    tableCell: {
        whiteSpace: 'nowrap',
    }
});

export default withStyles(styles)(ResultsTable);
