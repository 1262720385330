import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthorizedToWriteCatalogs } from '../authorization/AuthorizedToWriteCatalogs';
import { CatalogsTableProps, CatalogTable } from '../CatalogTable/CatalogTable';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { CatalogOverview, CatalogType } from '../../api/catalog-service/catalog-overview';
import { Page } from '../../api/types';
import { State } from '../../data/store';
import { getCatalogOverviewInitialPage, getCatalogOverviewPage } from '../../state/catalog-overview/get-page';
import { changeCatalogOverviewPageSize } from '../../state/catalog-overview/change-page-size';
import { changeCatalogState } from '../../state/catalog-overview/change-state';
import ChangeDefaultCatalogDialog from '../ChangeDefaultCatalogDialog/ChangeDefaultCatalogDialog';
import Button from '../Buttons/Button';
import AddCatalogDialog from '../AddCatalogDialog/AddCatalogDialog';
import { Add } from '@material-ui/icons';
import { getDefaultCatalog } from '../../state/default-catalog/get-default-catalog';

class CatalogSection0 extends Component<CatalogSectionProps & StyledComponentProps & StateProps & ActionProps> {

    public componentDidMount(): void {
        this.props.onGetInitialPage();
        this.props.onGetDefaultCatalog();
    }

    public render() {
        const { catalogPage, defaultCatalog, classes } = this.props;
        const defaultCatalogName = defaultCatalog && defaultCatalog.name
            ? `Default: ${defaultCatalog.name}`
            : 'No Default Set';

        return (
            <>
                <AppBar
                    className={classes.appBar}
                    position="static"
                    color="default"
                    elevation={1}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.grow}
                        >
                            {this.props.sectionName}
                            <Typography
                                variant="subtitle2"
                                color="inherit"
                            >
                                {defaultCatalogName}&nbsp;
                                <AuthorizedToWriteCatalogs>
                                    <ChangeDefaultCatalogDialog
                                        type={this.props.type}
                                        renderOpenButton={open =>
                                            <Tooltip title='Update Default Catalog' placement='right'>
                                                <Button
                                                    testName='change'
                                                    color='primary'
                                                    size='small'
                                                    onClick={open}
                                                >
                                                    Change
                                                </Button>
                                            </Tooltip>
                                        }
                                    />
                                </AuthorizedToWriteCatalogs>
                            </Typography>
                        </Typography>
                        <AuthorizedToWriteCatalogs>
                            <AddCatalogDialog
                                defaultType={this.props.type}
                                renderOpenButton={open =>
                                    <Button testName='createNewCatalog' color='primary' onClick={open}>
                                        Create New Catalog
                                        <Add className={this.props.classes.rightIcon} />
                                    </Button>
                                }
                            />
                        </AuthorizedToWriteCatalogs>
                    </Toolbar>
                </AppBar>
                <CatalogTable
                    testName={this.props.tableTestName}
                    catalogs={catalogPage.content}
                    totalCatalogs={catalogPage.totalElements}
                    currentPage={catalogPage.number}
                    totalPages={catalogPage.totalPages}
                    pageSize={catalogPage.size}
                    onChangePage={this.props.onChangePage}
                    onChangePageSize={this.props.onChangePageSize}
                    onChangeCatalogState={this.props.onChangeCatalogState}
                />
            </>
        );
    }
}

export interface CatalogSectionProps {
    sectionName: string,
    tableTestName: string,
    type: CatalogType,
}

const styles: StyleRules = {
    appBar: {
        padding: '15px 3px',
    },
    grow: {
        flexGrow: 1,
    },
    rightIcon: {
        marginLeft: '10px',
    },
};
export const CatalogSection = withStyles(styles)(CatalogSection0);

function mapStateToProps(state: State, props: CatalogSectionProps): StateProps {
    return {
        catalogPage: getCatalogPage(state, props.type),
        defaultCatalog: getDefaultCatalogFromState(state, props.type),
    };
}

interface StateProps {
    catalogPage: Page<CatalogOverview>,
    defaultCatalog: CatalogOverview,
}

function getCatalogPage(state: State, type: CatalogType): Page<CatalogOverview> {
    const catalogOverviews = state.REFACTORED.catalogOverviews;
    const catalogsById = catalogOverviews.catalogsById;
    const idPage = catalogOverviews.catalogPagesByType[type].data;
    if (!idPage) {
        return {
            content: [],
            totalElements: 0,
            first: true,
            last: true,
            number: 0,
            size: 0,
            totalPages: 0,
        };
    }

    return {
        ...idPage,
        content: idPage.content.map(id => catalogsById[id]),
    };
}

function getDefaultCatalogFromState(state: State, type: CatalogType): CatalogOverview {
    return state.REFACTORED.defaultCatalogs[type].data;
}

function mapDispatchToProps(dispatch, props: CatalogSectionProps): ActionProps {
    return {
        onGetInitialPage: () => dispatch(getCatalogOverviewInitialPage(props.type)),
        onGetDefaultCatalog: () => dispatch(getDefaultCatalog(props.type)),
        onChangePage: page => dispatch(getCatalogOverviewPage(props.type, page)),
        onChangePageSize: pageSize => dispatch(changeCatalogOverviewPageSize(props.type, pageSize)),
        onChangeCatalogState: (id, type, state) => dispatch(changeCatalogState(id, type, state)),
    };
}

type ActionProps = Pick<CatalogsTableProps, 'onChangeCatalogState' | 'onChangePage' | 'onChangePageSize'> & {
    onGetInitialPage: () => void,
    onGetDefaultCatalog: () => void,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogSection);
