import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { reportCatalogError } from '../error-actions';
import {
    getCatalogEngineInformation as getCatalogEngineInformationFromApi,
    GetCatalogEngineInformationRequest,
} from '../../api/catalog-service/engineInformation';

export const getCatalogEngineInformation = (equipmentCatalogId: number, activityCatalogId: number): AppThunk =>
    dispatch => {
        const request: GetCatalogEngineInformationRequest = {
            equipmentCatalogId,
            activityCatalogId,
        };

        dispatch(startLoading());
        return getCatalogEngineInformationFromApi(request)
            .then(response => {
                dispatch(successResponse(response))
            })
            .catch(err => {
                dispatch(errorResponse(err));
                dispatch(reportCatalogError(err));
                throw err;
            })
    };
