import React, { cloneElement } from 'react';
import { inputValue } from '../../utils/inputValue';
import { FieldProps } from './FieldProps';
import { withVariablePriceEscalation } from './withEscalationType';

export const LegacyEscalationFormula = withVariablePriceEscalation<FieldProps>(
    ({ formikProps, container }) => {
        const value = inputValue('legacyEscalationFormula', formikProps);
        if (!value) {
            return null;
        }

        return cloneElement(container, {
            children: value,
        });
    },
);
