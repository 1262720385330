import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { getConfiguration as getConfigurationFromApi } from '../../api/model-service/configuration';
import { reportConfigurationError } from '../error-actions';

export const getConfigurationForEdit = (id: number): AppThunk => dispatch => {
    dispatch(startLoading());
    return getConfigurationFromApi({id, skipFinancialData: true})
        .then(configuration => {
            dispatch(successResponse(configuration));
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportConfigurationError(err));
            throw err;
        })
};
