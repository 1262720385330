import React from 'react';
import MaterialTab, { TabProps as MaterialTabProps } from '@material-ui/core/Tab';
import { createButtonTestProps } from '../Utils/testProps';

interface ITabProps extends MaterialTabProps {
    testName: string,
}

const Tab: React.FunctionComponent<ITabProps> = ({ testName, ...materialTabProps }) =>
    <MaterialTab
        {...materialTabProps}
        {...createButtonTestProps(testName)} />
    ;

export default Tab;
