import React from 'react';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { CapUnit, Configuration, Guarantee, GuaranteeCap, UnitValuePair } from '../../api/model-service/model-service';
import { GuaranteesFormProps } from './GuaranteesInterfaces';
import { formValuesSchema } from './schema';
import { ObjectSchema } from '../../utils/validators';
import { Form } from '../Form/Form';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { SelectField } from '../SelectField/SelectField';
import { annualCapUnitOptions, contractCapUnit } from './GuaranteeOptions';
import { TextField } from '../TextField/TextField';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '../Buttons/Button';
import { GuaranteeCard } from './GuaranteeCard';
import { assoc, isEmpty } from 'lodash/fp';
import { GuaranteeEditDialog } from './GuaranteeEditDialog';
import { isModelAsSold } from '../../utils/isModelAsSold';

function defaultValues(configuration: Configuration): Configuration {
    return {
        ...configuration,
        guaranteeCap: {
            annualLdCap: { unit: null, value: null },
            contractLdCap: { unit: null, value: null },
        },
        guarantees: [],
    };
}

const styles = (theme: any) => ({
    button: {
        margin: theme.spacing.unit,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    scopeCardsWrapper: {
        paddingBottom: 30,
        paddingTop: 30,
    },
});

interface GuaranteeFormState {
    shouldShowNewGuaranteeDialog: boolean,
}
class GuaranteesForm0 extends ConfigurationForm<GuaranteesFormProps & StyledComponentProps, GuaranteeFormState> {
    constructor(props) {
        super(props);
        this.state = { shouldShowNewGuaranteeDialog: false };
    }

    private toggleOpenNewCofig() {
        this.setState( { shouldShowNewGuaranteeDialog: !this.state.shouldShowNewGuaranteeDialog });
    }
    
    public render() {
        const props = this.props;
        const {
            model,
            configuration,
            authorizedToEdit,
            ldBonusUnitOptions,
            guaranteeTypeOptions,
            guaranteeUnitOptions
        } = this.props;
        const disabled = !authorizedToEdit || isModelAsSold(model);
        const initialValues = isEmpty(configuration.guaranteeCap) ? defaultValues(configuration) : configuration;
        const schema = formValuesSchema();

        return (<Formik 
            initialValues={initialValues}
            validationSchema={schema} 
            onSubmit={(config, actions) => {
                this.handleSubmit(config, actions, schema)
            }}
            enableReinitialize>
            {(formikProps: FormikProps<Configuration>) => {
                const { handleChange, handleBlur, values } = formikProps;

                return <Form testName="guarantees" noValidate>
                    {!disabled && (
                        <>
                        <GuaranteeEditDialog edit={false} 
                            ldBonusUnitOptions={ldBonusUnitOptions}
                            guaranteeTypeOptions={guaranteeTypeOptions}
                            guaranteeUnitOptions={guaranteeUnitOptions}
                            guarantee={null}
                            isNewGuarantee={true}
                            close={this.toggleOpenNewCofig.bind(this)}
                            open={this.state.shouldShowNewGuaranteeDialog}
                            onSubmit={(guarantee) => {
                                this.newGuarantee(formikProps, guarantee),
                                this.toggleOpenNewCofig();
                                return Promise.resolve();
                            }}/>
                        <Button
                            testName="addGuarantee"
                            color="primary"
                            onClick={() => this.toggleOpenNewCofig()}
                        >
                            Add Guarantee
                            <AddIcon />
                        </Button>
                        </>
                    )}
                    <Grid container spacing={16} classes={{ container: props.classes.scopeCardsWrapper}}>
                        {values.guarantees.map((guarantee, index) => {
                            return (
                                <Grid
                                    key={`configuration_guarantee_${index}`}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                >
                                    <GuaranteeCard disabled={disabled} 
                                        guarantee={ guarantee }
                                        index={index}
                                        key={index}
                                        onSubmit={(editedGuarantee: Guarantee) => {
                                            this.editGuarantee(formikProps, editedGuarantee, index);
                                        }}
                                        onDelete={(index) => this.deleteCard(formikProps, index)}
                                        ldBonusUnitOptions={ldBonusUnitOptions}
                                        guaranteeTypeOptions={guaranteeTypeOptions}
                                        guaranteeUnitOptions={guaranteeUnitOptions}
                                        />
                                </Grid>
                            );    
                        })
                    }
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={6} sm={3}>
                            <SelectField
                                id='guaranteeCap.annualLdCap.unit'
                                label='Total Annual LDs Cap Unit'
                                name='guaranteeCap.annualLdCap.unit'
                                placeholder='Select Total Annual LDs Cap Unit'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                value={inputValue('guaranteeCap.annualLdCap.unit', formikProps)}
                                error={!!inputError('guaranteeCap.annualLdCap.unit', formikProps)}
                                helperText={inputError('guaranteeCap.annualLdCap.unit', formikProps)}
                                fullWidth
                                margin='dense'
                                options={annualCapUnitOptions}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                id='guaranteeCap.annualLdCap.value'
                                label='Total Annual LDs Cap Value'
                                name='guaranteeCap.annualLdCap.value'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                value={inputValue('guaranteeCap.annualLdCap.value', formikProps)}
                                placeholder="1+"
                                fullWidth
                                margin='dense'
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <SelectField
                                id='guaranteeCap.contractLdCap.unit'
                                label='Total Contract LDs Cap Unit'
                                name='guaranteeCap.contractLdCap.unit'
                                value={inputValue('guaranteeCap.contractLdCap.unit', formikProps)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                fullWidth
                                margin='dense'
                                placeholder='Select Total Contract LDs Cap Unit'
                                options={contractCapUnit}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                id='guaranteeCap.contractLdCap.value'
                                label='Total Contract LDs Cap Value'
                                name='guaranteeCap.contractLdCap.value'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled}
                                placeholder='1+'
                                fullWidth
                                value={inputValue('guaranteeCap.contractLdCap.value', formikProps)}
                                margin='dense'
                                type='number'
                            />
                        </Grid>
                    </Grid>
                    <ConfigurationFormButtons
                                loading={formikProps.isSubmitting}
                                onBack={props.onBack}
                                onSave={() => this.submitForm(formikProps, 'SAVE')}
                                onNext={() => this.submitForm(formikProps, 'NEXT')}
                                onReadOnlyNext={props.onNext}
                                onSecondaryComplete={this.isConfigurationComplete()
                                    ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                    : null
                                }
                            />
            
                </Form>
            }}

        </Formik>);
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }

    private newGuarantee(props: FormikProps<Configuration>, guarantee: Guarantee): Promise<void> {
        const guarantees = [...props.values.guarantees]
        guarantees.push(guarantee);
        const updatedConfig: Configuration = assoc('guarantees', guarantees, this.props.configuration);
        props.setValues(updatedConfig);
        return Promise.resolve();
    }

    private editGuarantee(props: FormikProps<Configuration>, guarantee: Guarantee, index: number) {
        const guarantees = [...props.values.guarantees]
        guarantees[index] = guarantee;
        const updatedConfig: Configuration = assoc('guarantees', guarantees, this.props.configuration);
        props.setValues(updatedConfig);
    }
    
    private deleteCard(props: FormikProps<Configuration>, index: number) {
        const guarantees: Guarantee[] = [...props.values.guarantees];
        
        if (index > guarantees.length) {
            return;
        }
        
        guarantees.splice(index, 1);
        const updatedConfig = assoc('guarantees', guarantees, props.values);
        props.setValues(updatedConfig);
    }

    private handleSubmit(rawConfiguration: Configuration,
                         actions: FormikActions<Configuration>,
                         schema: ObjectSchema<Partial<Configuration>>): void {

        const configuration = this.normalizeGuaranteeCap(schema.cast(rawConfiguration) as Configuration);

        this.props.onSubmit(configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private normalizeGuaranteeCap(configuration: Configuration): Configuration {
        const guaranteeCap = configuration.guaranteeCap;
        const normalizedCap = {
            ...guaranteeCap,
            annualLdCap: this.normalizeCap('annualLdCap', guaranteeCap),
            contractLdCap: this.normalizeCap('contractLdCap', guaranteeCap),
        };
        return {
            ...configuration,
            guaranteeCap: normalizedCap,
        };
    }

    private normalizeCap(type: 'annualLdCap' | 'contractLdCap',
                         guaranteeCap: GuaranteeCap): UnitValuePair<CapUnit> {

        if (!guaranteeCap) {
            return null;
        }

        const cap = guaranteeCap[type];
        if (!cap) {
            return null;
        }

        if (!cap.unit && !cap.value) {
            return null;
        }

        return cap;
    }
}

export const GuaranteesForm = withStyles(styles)(GuaranteesForm0);
