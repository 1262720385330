import * as React from 'react';
import './Footer.scss';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '../Buttons/Button';
import Typography from '@material-ui/core/Typography';
import ErrorWrapper from '../Error/ErrorWrapper';
import { Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { clearError, ErrorType } from '../../data/actions/error';
import { createDataTestProps } from '../Utils/testProps';

const styles = () => ({
    container: {
        padding: '10px 0px',
    },
    copyright: {
        marginRight: 'auto',
        textAlign: 'right',
    },
});

interface IProps {
    classes: {
        container: string;
        copyright: string;
    };
}

declare const VERSION: string;

let varient = 'error';

function FooterLinks() {
    return (
        <React.Fragment>
            <Grid item>
                <Button testName="terms" size="small" href="https://www.innio.com/en/terms">
                    Terms
                </Button>
            </Grid>
            <Grid item>
                <Button testName="privacy" size="small" href="https://www.innio.com/en/privacy">
                    Privacy
                </Button>
            </Grid>
            <Grid item>
                <Button
                    testName="contactUs"
                    size="small"
                    href="https://www.innio.com/en/company/contact"
                >
                    Contact Us
                </Button>
            </Grid>
        </React.Fragment>
    );
}

const Footer = (props: any) => {
    const { classes, error, clearError } = props;

    if (ErrorType.NONE !== error.type) {
        if (ErrorType.MESSAGE === error.type) {
            varient = 'success';
        } else if (ErrorType.UI === error.type) {
            varient = 'warning';
        } else {
            varient = 'error';
        }
    }

    const versionTestProps = createDataTestProps('appVersion');
    const version = <span {...versionTestProps}>{VERSION}</span>;

    return (
        <div style={{ padding: '20px 30px', borderTop: '1px solid #ececec' }}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={40}
            >
                <Grid item classes={{ item: classes.copyright }}>
                    <Typography variant="caption">
                        Copyright&copy; {new Date().getFullYear()} INNIO -
                        Currently v{version}
                    </Typography>
                </Grid>
                <FooterLinks />
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={error.type !== ErrorType.NONE}
                autoHideDuration={varient === 'success' ? 6000 : null}
                onClose={() => clearError()}
            >
                <ErrorWrapper
                    onClose={() => clearError()}
                    variant={varient}
                    className={classes.margin}
                    message={
                        (error.error && error.error.message) || error.error
                    }
                />
            </Snackbar>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        error: state.error,
    };
};

let searchTimeout;
const mapDispatchToProps = dispatch => {
    return {
        clearError: () => dispatch(clearError()),
    };
};

export default withStyles(styles as any)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Footer)
);
