import { combineReducers } from '@reduxjs/toolkit';
import catalogOverviewsReducer from './catalog-overview/slice';
import addCatalogDialogReducer from './add-catalog/slice';
import defaultCatalogsReducer from './default-catalog/slice';
import defaultCatalogDialogReducer from './default-catalog-dialog/slice';
import modelDetailsReducer from './modelDetails/slice';
import configurationDetailsReducer from './configurationDetails/slice';
import regionCatalogsReducer from './regionCatalogs/slice';
import activityCatalogsReducer from './activityCatalogs/slice';
import defaultEquipmentCatalogReducer from './defaultEquipmentCatalog/slice';
import catalogEngineInformationReducer from './catalogEngineInformation/slice';
import catalogActivitiesReducer from './catalogActivities/slice';
import candidateFilterOptionsReducer from './actualization/candidateFilterOptionsSlice';
import actualizationCandidatesReducer from './actualization/candidatesSlice';
import actualizationResultReducer from './actualization/createActualizationSlice';
import getActualizationOverviewsReducer from './actualization/actualizationOverviewsSlice';

const reducer = combineReducers({
    catalogOverviews: catalogOverviewsReducer,
    addCatalogDialog: addCatalogDialogReducer,
    defaultCatalogs: defaultCatalogsReducer,
    defaultCatalogDialog: defaultCatalogDialogReducer,
    modelDetails: modelDetailsReducer,
    configurationDetails: configurationDetailsReducer,
    regionCatalogs: regionCatalogsReducer,
    activityCatalogs: activityCatalogsReducer,
    defaultEquipmentCatalog: defaultEquipmentCatalogReducer,
    catalogEngineInformation: catalogEngineInformationReducer,
    catalogActivities: catalogActivitiesReducer,
    candidateFilterOptions: candidateFilterOptionsReducer,
    actualizationCandidates: actualizationCandidatesReducer,
    actualizationResult: actualizationResultReducer,
    actualizationOverviews: getActualizationOverviewsReducer,
});

export default reducer;
