import { createAction, createReducer } from '@reduxjs/toolkit';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';

interface ConfigurationDetailsState {
    loading: boolean,
    error: string,
    data: CatalogActivity[],
}

export const startLoading = createAction('catalogActivities/startLoading');
export const errorResponse = createAction<Error>('catalogActivities/errorResponse');
export const successResponse = createAction<CatalogActivity[]>('catalogActivities/successResponse');

const initialState: ConfigurationDetailsState = {
    loading: false,
    error: null,
    data: [],
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            error: null,
            data: action.payload,
        })),
);
export default reducer;
