import React, { cloneElement, FC } from 'react';
import { inputError } from '../../utils/inputError';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { MultiSelectField } from '../MultiSelectField/MultiSelectField';
import { FieldProps } from './FieldProps';
import { BillingBase as BillingBaseType } from '../../api/model-service/model-service';
import { arrayInputValue } from '../../utils/arrayInputValue';

export const BillingBase: FC<FieldProps> =
    ({ formikProps, container }) => {
        const name = 'billingBases';
        const errorMessage = inputError(name, formikProps);
        const value = arrayInputValue(name, formikProps);
        return cloneElement(container, {
            children:
                <MultiSelectField
                    name={name}
                    label='Billing Base'
                    placeholder='Select Billing Bases'
                    value={value}
                    options={options}
                    onChange={updatedValue => {
                        formikProps.setFieldValue(name, updatedValue);
                    }}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    required
                />,
        });
    };

const options: Array<IFormOption<BillingBaseType>> = [
    formOption('ALL_PAYMENTS', 'All Payments'),
    formOption('USAGE_OPH', 'Usage - Oph'),
    formOption('USAGE_MWH', 'Usage - MWh'),
    formOption('MILESTONE', 'Milestone(s)'),
    formOption('FIXED_PERIODIC', 'Fixed Periodic'),
    formOption('PACKAGES', 'Packages'),
];
