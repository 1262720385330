import * as React from 'react';
import './SectionTitle.scss';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '../Form/InputBase';
import IconButton from '../Buttons/IconButton';
import {createDataTestProps} from '../Utils/testProps';

const SectionTitle = props => {
    const testProps = props.testName ? createDataTestProps(props.testName) : {};
    if (props.search) {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="section-title-root"
                >
                    <div>
                        <h2 className="section-title">{props.title}</h2>
                        {props.action}
                    </div>
                    <div>
                        <InputBase testName="sectionTitleSearch" placeholder="" />
                        <IconButton testName="search" aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                        {props.children}
                    </div>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className="section-title-root"
            >
                <div>
                    <h2 {...testProps} className="section-title">{props.title}</h2>
                    {props.action}
                </div>
                <div>{props.children}</div>
            </Grid>
        </React.Fragment>
    );
};
export default SectionTitle;
