import React, { FC, ReactNode } from 'react';
import { Authority } from '../../data/model/user';
import { CaseAuthorized } from './CaseAuthorized';
import { IFormModel } from '../../data/model/model';

export const CaseAuthorizedToUpdateBilOfServices: FC<CaseAuthorizedToUpdateBilOfServicesProps> =
    props =>
        <CaseAuthorized for={Authority.WRITE_BILL_OF_SERVICES}>
            {authorized => props.children(authorized && !isReadOnly(props.model))}
        </CaseAuthorized>;

export interface CaseAuthorizedToUpdateBilOfServicesProps {
    model: IFormModel,
    children: (authorized: boolean) => ReactNode,
}

function isReadOnly(model: IFormModel): boolean {
    return !model || model.readOnly;
}
