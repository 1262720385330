import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { TextField } from '../TextField/TextField';
import { InputAdornment } from '@material-ui/core';
import { Activity } from '../../api/model-service/model-service';

export const Frequency: FC<FieldProps> =
    ({ formikProps }) => {
        if (isYearlyTrigger(formikProps.values)) {
            return null;
        }

        const name = 'frequency';
        const error = inputError(name, formikProps);
        return (
            <TextField
                name={name}
                type='number'
                label='Frequency'
                value={inputValue(name, formikProps)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!error}
                helperText={error}
                margin='dense'
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position='end'>(oph)</InputAdornment>,
                }}
            />
        );
    };

function isYearlyTrigger(activity: Activity) {
    return activity.schedulingType === 'YEARS';
}
