import { PricingFormSectionFactory } from './abstract-factory';
import LdBonus from '../Configuration/Financials/Pricing/Forms/LdBonus';
import { MsaPreventiveCaps } from '../CapsFormSection/MsaPreventiveCaps';
import { MsaPricingContributionMargin } from '../PricingContributionMarginFormSection/MsaPricingContributionMargin';

export const msaFactory: PricingFormSectionFactory = {
    PricingContributionMargin: MsaPricingContributionMargin,
    LdBonus,
    Caps: MsaPreventiveCaps,
};
