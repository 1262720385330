import { defaultsDeep } from 'lodash';
import { IUserInfo } from './model/user';
import { ApiError, AccessTokenExpired } from './error';
import { authInfo, authRoot, requestFetchDefaults } from './auth-declarations';

const fetchInitDefaults: object = {
    mode: 'cors',
    ...requestFetchDefaults,
};

export function checkAccessToken(accessToken: string): Promise<IUserInfo> {
    const url = getCheckAccessTokenUrl(accessToken);
    const requestOpts = getCheckAccessTokenRequestOptions(accessToken);
    return window.fetch(url, requestOpts)
        .then(response => {
            const status = response.status;
            if (status === 200) {
                return response.json();
            }

            return response.text()
                .then(body => {
                    throw new AccessTokenExpired(
                        'Error refreshing access token', response.status, body);
                });
        });
}

function getCheckAccessTokenUrl(token) {
    const encodedToken = encodeURI(token);
    const url = `${authRoot}/oauth/check_token?token=${encodedToken}`;
    return url;
}

function getCheckAccessTokenRequestOptions(token) {
    const requestOpts = {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    };
    return withRequestDefaults(requestOpts);
}

export function refreshAccessToken(refreshToken: string): Promise<ITokens> {
    const formData = getRefreshTokenData(refreshToken);
    const errorMessage = 'Error refreshing access token';
    return authenticate(formData, errorMessage);
}


export function exchangeAuthorizationCodeForTokens(authCode: string): Promise<ITokens> {
    const formData = getAuthorizationCodeData(authCode);
    const errorMessage = 'Authentication error';
    return authenticate(formData, errorMessage);
}

interface ITokens {
    access_token: string,
    refresh_token: string,
}

function getRefreshTokenData(refreshToken: string): FormData {
    const data = new FormData();
    data.append('grant_type', 'refresh_token');
    data.append('refresh_token', refreshToken);
    data.append('redirect_uri', authInfo.redirectUri);
    return data;
}

function getAuthorizationCodeData(authCode: string): FormData {
    const data = new FormData();
    data.append('grant_type', 'authorization_code');
    data.append('code', authCode);
    data.append('redirect_uri', authInfo.redirectUri);
    return data;
}

function authenticate(formData, errorMessage: string) {
    const url = getAuthenticateUrl();
    const requestOpts = getAuthenticateRequestOptions(formData);
    return window.fetch(url, requestOpts)
        .then(response => {
            const status = response.status;
            if (status === 200) {
                return response.json();
            }

            return response.text()
                .then(body => {
                    throw new ApiError(errorMessage, response.status, body);
                });
        });
}

function getAuthenticateUrl(): string {
    return `${authRoot}/oauth/token`;
}

function getAuthenticateRequestOptions(data: FormData): RequestInit {
    const credentials = btoa(authInfo.clientId + ':' + authInfo.clientSecret);
    const requestOpts = {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Basic ${credentials}`
        }),
        body: data
    };
    return defaultsDeep(requestOpts, fetchInitDefaults);
}

function withRequestDefaults(requestOpts) {
    return defaultsDeep(requestOpts, fetchInitDefaults);
}
