import React, { ChangeEvent, Component, FormEvent, ReactNode } from 'react';
import { DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid } from '@material-ui/core';
import { Form } from '../Form/Form';
import { IFormOption, iv } from '../../data/utils/form';
import { SelectField } from '../SelectField/SelectField';
import { isEmpty, uniq, uniqWith } from 'lodash';
import { CheckboxField } from '../CheckboxField/CheckboxField';
import { Button } from '../Buttons/Button';
import { SubmitButton } from '../Form/SubmitButton';
import { Activity } from '../../api/model-service/model-service';

export class EditStandardScopeDialogForm extends Component<EditStandardScopeDialogFormProps, State> {
    public state = {
        activities: this.props.initialActivities,
        selectedScope: this.props.initialScope || '',
    };

    public render(): ReactNode {
        const { selectedScope } = this.state;
        const scopeOptions = this.getScopeOptions();
        const handleScopeChange = evt => this.handleScopeChange(evt);
        const handleSubmit = evt => this.handleSubmit(evt);
        const handleCancel = () => this.handleCancel();

        return (
            <Form testName='editStandardScope' onSubmit={handleSubmit}>
                <DialogTitle id='form-dialog-title'>
                    <Grid
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                    >
                        Configure Scope
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                            <SelectField
                                id='scope'
                                label='Scope'
                                name='scope'
                                margin='dense'
                                autoFocus={true}
                                placeholder='Select Scope'
                                options={scopeOptions}
                                value={selectedScope}
                                onChange={handleScopeChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl margin='dense'>
                                <FormLabel>Services</FormLabel>
                                {this.getSelectedScopeServiceFields()}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button testName='cancel' onClick={handleCancel}>Cancel</Button>
                    <SubmitButton testName='apply' color='primary'>Apply</SubmitButton>
                </DialogActions>
            </Form>
        )
    }

    private handleScopeChange(evt: ChangeEvent<HTMLSelectElement>): void {
        this.selectScope(evt.target.value);
    }

    private handleSubmit(evt: FormEvent): void {
        evt.preventDefault();
        this.props.onSubmit(this.state.activities);
    }

    private handleCancel(): void {
        this.props.onCancel();
    }

    private getScopeOptions(): IFormOption[] {
        const scopesWithDuplicates = this.props.allActivities
            .map(activity => activity.scope);
        const uniqueScopes = uniq(scopesWithDuplicates);
        return uniqueScopes.map(scope => iv(scope, scope));
    }

    private getSelectedScopeServiceFields(): ReactNode {
        if (!this.isScopeSelected()) {
            return null;
        }

        const activities = this.getSortedScopeActivities(this.state.selectedScope);
        return activities.map((activity, idx) => {
            const isSelected = this.isSelectedActivity(activity);
            const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
                this.toggleActivity(activity, evt.target.checked);
            };
            return (
                <CheckboxField
                    key={idx}
                    label={activity.service}
                    testName='type'
                    testId={'' + idx}
                    checked={isSelected}
                    onChange={onChange}
                />
            );
        });
    }

    private isScopeSelected(): boolean {
        return !isEmpty(this.state.selectedScope);
    }

    private selectScope(scope: string): void {
        this.setState({ selectedScope: scope });
    }

    private getSortedScopeActivities(scope: string): Activity[] {
        const matchesScope = activity => activity.scope === scope;
        const matchingActivities = this.props.allActivities.filter(matchesScope);

        const isEqualScopeAndService = (activity: Activity, other: Activity) =>
            activity.scope === other.scope &&
            activity.service === other.service;
        const uniqueActivities = uniqWith(matchingActivities, isEqualScopeAndService);

        const serviceComparator = (a: Activity, b: Activity) =>
            a.service.localeCompare(b.service);
        uniqueActivities.sort(serviceComparator);

        return uniqueActivities;
    }

    private toggleActivity(activity: Activity, selected: boolean): void {
        if (selected) {
            this.selectActivity(activity);
        } else {
            this.deselectActivity(activity);
        }
    }

    private selectActivity(activity: Activity): void {
        const selectedActivities = this.getMatchingActivities(activity);
        const newActivities = this.state.activities.concat(selectedActivities);
        this.setActivities(newActivities);
    }

    private getMatchingActivities(activity: Activity): Activity[] {
        const matchesActivity = (otherActivity: Activity) =>
            otherActivity.scope === activity.scope &&
            otherActivity.service === activity.service &&
            otherActivity.additionalAttribute === activity.additionalAttribute;
        return this.props.allActivities.filter(matchesActivity);
    }

    private deselectActivity(activity: Activity): void {
        const doesntMatchDeselectedActivity = (otherActivity: Activity) =>
            otherActivity.scope !== activity.scope ||
            otherActivity.service !== activity.service;
        const remainingActivities = this.state.activities.filter(doesntMatchDeselectedActivity);
        this.setActivities(remainingActivities);
    }

    private setActivities(activities: Activity[]): void {
        this.setState({ activities });
    }

    private isSelectedActivity(activity: Activity): boolean {
        const matchActivity = (other: Activity) =>
            activity.scope === other.scope &&
            activity.service === other.service;
        return this.state.activities.some(matchActivity);
    }
}

export interface EditStandardScopeDialogFormProps {
    allActivities: Activity[],
    initialActivities: Activity[],
    initialScope?: string,
    onSubmit: (activities: Activity[]) => void,
    onCancel: () => void,
}

interface State {
    activities: Activity[],
    selectedScope: string,
}
