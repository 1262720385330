import React, { Component, ReactNode } from 'react';
import ViewTitle from '../ViewTitle/ViewTitle';
import { Paper, Stepper, StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core';
import ActualizationCandidatesForm from '../ActualizationCandidatesForm/ActualizationCandidatesForm';
import { FormStep } from '../EditConfigurationPage/FormStep';
import { History } from 'history';
import { clearData as clearCandidatesData } from '../../state/actualization/candidatesSlice';
import { clearData as clearActualizationData } from '../../state/actualization/createActualizationSlice';
import { connect } from 'react-redux';
import ActualizationParametersForm from '../ActualizationParametersForm/ActualizationParametersForm';
import ActualizationResults from '../ActualizationResults/ActualizationResults';
import { getActualization } from '../../state/actualization/getActualization';
import { hasValue } from '../../data/utils/form';

class ActualizationPage0 extends Component<ComponentProps, ComponentState> {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    public componentDidMount(): void {
        this.resetData();
    }

    public componentDidUpdate(prevProps: ComponentProps): void {
        const currentActualizationId = this.props.actualizationId;
        if (prevProps.actualizationId !== currentActualizationId) {
            this.resetData();
        }
    }

    public render(): ReactNode {
        return (
            <>
                <ViewTitle>Actualization</ViewTitle>
                <Paper square classes={{ root: this.props.classes.paper }}>
                    {this.renderForm()}
                </Paper>
            </>
        );
    }

    private renderForm(): ReactNode {
        return (
            <Stepper activeStep={this.state.step} orientation='vertical'>
                {this.getSteps().map((step, idx) =>
                    <FormStep
                        key={idx}
                        label={step.label}
                        clickable={false}
                    >
                        {step.content}
                    </FormStep>,
                )}
            </Stepper>
        );
    }

    private getSteps(): StepDescriptor[] {
        return [
            {
                label: 'Initiation',
                content:
                    <ActualizationCandidatesForm
                        onCancel={() => this.redirectToDashboard()}
                        onProceed={() => this.showNextStep()}
                    />,
            },
            {
                label: 'Auto actualization parameters',
                content:
                    <ActualizationParametersForm
                        onCancel={() => this.redirectToDashboard()}
                        onBack={() => this.showPreviousStep()}
                        onProceed={() => this.showNextStep()}
                        onNext={() => this.showNextStep()}
                    />,
            },
            {
                label: 'Actualization Results',
                content:
                    <ActualizationResults
                        onBack={() => this.showPreviousStep()}
                        onClose={() => this.redirectToDashboard()}
                    />,
            },
        ];
    }

    private redirectToDashboard(): void {
        this.props.history.push('/');
    }

    private showPreviousStep(): void {
        this.setState(prevState => ({
            step: prevState.step - 1,
        }));
    }

    private showNextStep() {
        this.setState(prevState => ({
            step: prevState.step + 1,
        }));
    }

    private resetData(): void {
        this.props.onReset();
        this.setState(this.getInitialState());

        if (this.isShowingExistingActualization()) {
            this.props.onShowActualization(this.props.actualizationId);
        }
    }

    private getInitialState(): ComponentState {
        const initialStep = this.isShowingExistingActualization() ? 2 : 0;
        return { step: initialStep };
    }

    private isShowingExistingActualization(): boolean {
        return hasValue(this.props.actualizationId);
    }
}

type ComponentProps = ActualizationPageProps & ActionProps & StyledComponentProps;

interface ActualizationPageProps {
    history: History,
    actualizationId?: number,
}

interface ComponentState {
    step: number,
}

interface StepDescriptor {
    label: string,
    content: ReactNode,
}

const styles: StyleRulesCallback = theme => ({
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        marginBottom: '33px',
        position: 'relative',
    },
});
export const ActualizationPage = withStyles(styles)(ActualizationPage0);

interface ActionProps {
    onReset: () => void,
    onShowActualization: (id: number) => void,
}

function mapDispatchToProps(dispatch): ActionProps {
    return {
        onReset: () => {
            dispatch(clearCandidatesData());
            dispatch(clearActualizationData());
        },
        onShowActualization: id => dispatch(getActualization(id)),
    };
}

export default connect(null, mapDispatchToProps)(ActualizationPage);
