import React, { FunctionComponent, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { formatCurrency, formatPercentage } from "../../../data/utils/number";
import { createDataTestProps } from "../../Utils/testProps";
import { hasValue } from "../../../data/utils/form";

interface IFinancialLabeled {
    label: string,
    testName: string,
}

interface IFinancialCurrencyDataPoint extends IFinancialLabeled {
    amount: number,
    currency: string,
}

interface IFinancialPercentageDataPoint extends IFinancialLabeled {
    percentage: number,
}

type FinancialDataPointProps = IFinancialCurrencyDataPoint | IFinancialPercentageDataPoint;

const FinancialDataPoint: FunctionComponent<FinancialDataPointProps> = props => {
    const testProps = createDataTestProps(props.testName);
    const value = "percentage" in props
        ? formatPercentageWithDecimalDigits(props.percentage)
        : formatCurrency(props.currency, props.amount);

    return (
        <Fragment>
            <Typography variant="caption">{props.label}</Typography>
            <Typography variant="h6" color="default" {...testProps}>{value}</Typography>
        </Fragment>
    );
};
export default FinancialDataPoint;

function formatPercentageWithDecimalDigits(percentage) {
    return hasValue(percentage)
        ? formatPercentage(percentage, 0, 2)
        : '';
}
