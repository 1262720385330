import * as React from 'react';

import IconButton from '../Buttons/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu } from '../Popups/Menu';
import { MenuItem } from '../Popups/MenuItem';
import CloneDialog from '../Configuration/CloneDialog/CloneDialog';
import Confirm from './Confirm';
import { IFormModel } from '../../data/model/model';
import { hasValue } from '../../data/utils/form';

export interface ActionMenuProps {
    testName: string,
    testId: string,
    model?: IFormModel,
    getOne?: (id: string) => any,
    deleteThis?: () => void,
    cloneThis?: (id: string) => Promise<any>,
    openEdit?: () => void,
    deleteTitle?: string,
    deleteMessage?: string,
    searchModelsForClone?: (input: string) => Promise<any>
}

class ActionMenu extends React.Component<ActionMenuProps, any> {
    public state = {
        anchorEl: null,
        cloneDialogOpen: false,
        destModelID: null,
        deleteDialogOpen: false,
    };

    public handleClick(event): void {
        this.setState({ anchorEl: event.currentTarget });
    }

    public handleClose(e?): void {
        if (e && e.keyCode === 9) {
            return;
        }
        this.setState({ anchorEl: null });
    }

    public handleCloneOpen(): void {
        this.setState({ cloneDialogOpen: true });
    }

    public handleDeleteClose(): void {
        this.setState({ deleteDialogOpen: false });
        this.handleClose();
    }

    public handleDeleteOpen(): void {
        this.setState({ deleteDialogOpen: true });
    }

    public handleSaveToCurrentModel() {
        this.handleCloneSave('' + this.props.model.id);
    }
    public handleSaveToDestinationModel() {
        this.handleCloneSave(this.state.destModelID);
    }

    private handleCloneSave(modelID: string): void {
        const { cloneThis, getOne } = this.props;

        cloneThis(modelID)
            .then(res => {
                if (!res.error) {
                    // success
                    this.setState({ cloneDialogOpen: false });
                }
            })
            .then(result => {
                if (modelID) {
                    // after we clone we load the model
                    getOne(modelID);
                }
            }).finally(() => this.handleClose());
    }

    public handleCloneClose(): void {
        this.setState({ cloneDialogOpen: false, destModelID: null });
        this.handleClose();
    }

    public handleCloneFormChange(e): void {
        const destModelID = e.value;

        this.setState({ destModelID });
    }

    public handleDeleteThis() : void {
        const {
            deleteThis
        } = this.props;

        deleteThis();
        this.setState({ deleteDialogOpen: false });
        this.handleClose();
    }

    public render(): React.ReactElement {
        const { anchorEl, deleteDialogOpen } = this.state;
        const {
            testName,
            testId,
            deleteThis,
            cloneThis,
            openEdit,
            deleteTitle,
            deleteMessage,
        } = this.props;

        return (
            <div>
                {deleteThis || openEdit || cloneThis ? (
                    <IconButton
                        testName="showActions"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={e => this.handleClick(e)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                ) : (
                    ''
                )}
                <Menu
                    testName={testName}
                    testId={testId}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={e => this.handleClose(e)}
                >
                    {openEdit ? (
                        <MenuItem
                            testName="edit"
                            onClick={() => {
                                openEdit();
                                this.setState({ anchorEl: null });
                            }}
                        >
                            Edit
                        </MenuItem>
                    ) : (
                        ''
                    )}
                    {cloneThis ? (
                        <MenuItem
                            testName="clone"
                            onClick={() => this.handleCloneOpen()}
                        >
                            Clone
                        </MenuItem>
                    ) : (
                        ''
                    )}
                    {deleteThis ? (
                        <MenuItem
                            testName="delete"
                            onClick={() => this.handleDeleteOpen()}
                        >
                            Delete
                        </MenuItem>
                    ) : (
                        ''
                    )}
                </Menu>

                <Confirm
                    testName="confirmDelete"
                    title={deleteTitle}
                    message={deleteMessage}
                    action={() => this.handleDeleteThis()}
                    open={deleteDialogOpen}
                    handleClose={() => this.handleDeleteClose()}
                />

                {this.getCloneDialog()}
            </div>
        );
    }

    private getCloneDialog(): React.ReactNode {
        if (!this.props.cloneThis) {
            return null;
        }

        return (
            <CloneDialog
                modelVersion={this.props.model.modelVersion}
                open={this.state.cloneDialogOpen}
                handleClose={() => this.handleCloneClose()}
                modelOptionsPromise={search =>
                    this.handleSearchPromise(search)
                }
                disableCloneButton={!hasValue(this.state.destModelID)}
                handleChange={e => this.handleCloneFormChange(e)}
                handleSave={() => this.handleSaveToDestinationModel()}
                handleSaveToCurrentModel={() => this.handleSaveToCurrentModel()}
            />
        );
    }

    private handleSearchPromise(inputValue): Promise<any[]> {
        return this.props.searchModelsForClone(inputValue)
            .then(results => results.modelList.content
                        .map(r => {
                            return {label: r.projectName, value: r.id};
                        }));
    }
}

export default ActionMenu;
