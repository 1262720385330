import React, { FC } from 'react';
import { Step, StepContent, StepLabel, StyledComponentProps, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const FormStep0: FC<StepProps & StyledComponentProps> =
    ({ classes, label, children, clickable, onClick, ...stepProps }) =>
        // Material-ui steppers pass some control props into each step and
        // they have to be preserved.
        <Step {...stepProps}>
            <StepLabel
                onClick={clickable ? onClick : null}
                classes={{
                    disabled: clickable
                        ? classes.pointer
                        : classes.noPointer,
                }}
            >
                {label}
            </StepLabel>
            <StepContent>{children}</StepContent>
        </Step>;

// We have to be careful that our own prop name don't conflict with the ones
// passed by the material-ui Stepper into each Step.
export interface StepProps {
    label: string,
    clickable?: boolean,
    onClick?: () => void,
}

const styles: StyleRules = {
    pointer: {
        cursor: 'pointer !important',
    },
    noPointer: {
        cursor: 'default',
    },
};
export const FormStep = withStyles(styles)(FormStep0);
