import React, { cloneElement, FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';

export const BillingCurrencyFx: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const currency = inputValue('payment.billingCurrency', formikProps);
        if (currency === 'EUR') {
            return null;
        }

        const name = 'payment.billingCurrencyFx';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Billing Currency FX'
                    type='number'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };
