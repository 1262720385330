import React, { FC } from 'react';
import { StyledComponentProps, StyleRulesCallback, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const CatalogTableNoData0: FC<StyledComponentProps> =
    props =>
        <div className={props.classes.noContentRoot}>
            <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                gutterBottom
            >
                No Catalogs Found.
            </Typography>
        </div>;

const styles: StyleRulesCallback = theme => ({
    noContentRoot: {
        padding: theme.spacing.unit * 15,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

export const CatalogTableNoData = withStyles(styles)(CatalogTableNoData0);
