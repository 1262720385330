import React, { ComponentType } from 'react';
import { Escalation } from '../../api/model-service/model-service';
import { FieldProps } from './FieldProps';

export const withEscalationType = (type: Escalation['type']) =>
    <P extends WithFormikProps>(Component: ComponentType<P>): ComponentType<P> =>
        (props: P) => {
            if (props.formikProps.values.type !== type) {
                return null;
            }

            return <Component {...props} />;
        };

type WithFormikProps = Pick<FieldProps, 'formikProps'>;

export const withFixedPriceEscalation = withEscalationType('fixPrice');
export const withVariablePriceEscalation = withEscalationType('details');
