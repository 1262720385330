import React, { ReactNode } from 'react';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Configuration, EngineInformation } from '../../api/model-service/model-service';
import { Form } from '../Form/Form';
import { StyledComponentProps } from '@material-ui/core';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { StandardScopeProps } from '../StandardScopeFormSection/StandardScopeProps';
import { StandardActivitiesInputs } from './StandardActivitiesInputs';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';
import { isModelAsSold } from '../../utils/isModelAsSold';
import { getStandardScopeSchema } from './schema';
import { State } from '../../data/store';
import { getCatalogActivities } from '../../state/catalogActivities/getCatalogActivities';
import { connect } from 'react-redux';

export class StandardActivitiesForm extends ConfigurationForm<ComponentProps> {

    private static defaultValues(configuration: Configuration): Configuration {
        return {
            ...configuration,
            standardScope: [],
        }
    }

    public componentDidMount(): void {
        const { equipmentCatalogId, activityCatalogId, engineInformation } = this.props.configuration;
        this.props.onRequestActivities(equipmentCatalogId, activityCatalogId, engineInformation);
    }

    public render(): ReactNode {
        const props = this.props;

        const initialValues = props.configuration.standardScope
            ? props.configuration
            : StandardActivitiesForm.defaultValues(props.configuration);

        const schema = getStandardScopeSchema();

        const disabled = !this.props.authorizedToEdit || isModelAsSold(this.props.model);

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(config, actions) => this.handleSubmit(config, actions)}
                enableReinitialize
            >
                {(formikProps: FormikProps<Configuration>) =>
                    <Form testName='standardActivities' noValidate>
                        {!this.props.loading
                            ? <StandardActivitiesInputs
                                disabled={disabled}
                                formikProps={formikProps}
                                persistedActivities={initialValues.standardScope}
                                catalogActivities={this.props.catalogActivities}
                                defaultActivities={
                                    this.props.defaultActivitiesFilter(this.props.catalogActivities)
                                }
                            />
                            : null
                        }
                        <ConfigurationFormButtons
                            loading={this.props.loading || formikProps.isSubmitting}
                            onBack={props.onBack}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onNext={() => this.submitForm(formikProps, 'NEXT')}
                            onReadOnlyNext={props.onNext}
                            onSecondaryComplete={this.isConfigurationComplete()
                                ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                : null
                            }
                        />
                    </Form>
                }
            </Formik>
        )
    }

    private handleSubmit(configuration: Configuration, actions: FormikActions<Configuration>): void {
        this.props.onSubmit(configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }
}

type ComponentProps = StandardActivitiesFormProps & StateProps & ActionProps & StyledComponentProps;

interface StandardActivitiesFormProps extends StandardScopeProps {
    defaultActivitiesFilter: (activities: CatalogActivity[]) => CatalogActivity[],
}

interface StateProps {
    catalogActivities: CatalogActivity[],
    loading: boolean,
}

function mapStateToProps(state: State): StateProps {
    const catalogActivitiesState = state.REFACTORED.catalogActivities;
    return {
        catalogActivities: catalogActivitiesState.data,
        loading: catalogActivitiesState.loading,
    };
}

interface ActionProps {
    onRequestActivities: (equipmentCatalogId: number,
                          activityCatalogId: number,
                          engineInformation: EngineInformation) => void,
}

const mapDispatchToProps: ActionProps = {
    onRequestActivities: getCatalogActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardActivitiesForm);
