export class ApiError extends Error {

    constructor(message: string,
                public readonly status: number,
                public readonly body?: any) {
        super(message);
    }
}

export class AccessTokenExpired extends ApiError {
}