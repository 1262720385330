import {
    ActualizationPayload,
    createActualization as createActualizationApi,
} from '../../api/model-service/actualization';
import { AppThunk } from "../app-thunk";
import { reportConfigurationError } from "../error-actions";
import { errorResponse, startLoading, successResponse } from './createActualizationSlice';

export const createActualization = (request: ActualizationPayload): AppThunk => dispatch => {
    dispatch(startLoading());
    return createActualizationApi(request)
        .then(response => {
            dispatch(successResponse(response))
        })
        .catch(err => {
            dispatch(errorResponse(err));
            dispatch(reportConfigurationError(err));
            throw err;
        });
};
