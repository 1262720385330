import { createAction, createReducer } from '@reduxjs/toolkit';
import { CatalogOverview } from '../../api/catalog-service/catalog-overview';

interface ChangeDefaultCatalogDialogState {
    loading: boolean,
    error: string,
    data: CatalogOverview[],
}

export const startLoading = createAction('defaultCatalogDialog/startLoading');

export const errorResponse = createAction<Error>('defaultCatalogDialog/errorResponse');

export const successGetCatalogsResponse =
    createAction<CatalogOverview[]>('defaultCatalogDialog/successGetCatalogsResponse');

export const successChangeDefaultResponse = createAction('defaultCatalogDialog/successChangeDefaultResponse');

export const clearCatalogs = createAction('defaultCatalogDialog/clearCatalogs');

const initialState: ChangeDefaultCatalogDialogState = {
    loading: false,
    error: null,
    data: [],
};
const reducer = createReducer<ChangeDefaultCatalogDialogState>(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successGetCatalogsResponse, (state, action) => ({
            ...state,
            loading: false,
            error: null,
            data: action.payload,
        }))
        .addCase(successChangeDefaultResponse, state => ({
            ...state,
            loading: false,
            error: null,
        }))
        .addCase(clearCatalogs, state => ({
            ...state,
            data: [],
        })),
);

export default reducer;
