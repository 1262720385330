import { IFormOption } from '../data/utils/form';
import { ActivityType } from '../api/model-service/model-service';
import { formOption } from './formOption';

export const activityTypeOptions: Array<IFormOption<ActivityType>> = [
    formOption('INNIO_PARTS', 'INNIO Parts'),
    formOption('INNIO_LABOR', 'INNIO Labor'),
    formOption('OTHER_PROVIDER', 'Other Provider'),
    formOption('FREIGHT', 'Freight'),
    formOption('CUSTOMS', 'Customs'),
];
