import { ConfigurationDetailsFormProps } from './ConfigurationDetailsForm';
import {
    Configuration,
    ConfigurationState,
    ContractCategory,
    GasType,
    SunsetClauseType,
    UnitType,
} from '../../api/model-service/model-service';
import { date, mixed, number, object, ObjectSchema, string, StringSchema } from '../../utils/validators';
import { rangeMessage, requiredMessage } from '../../utils/validationMessages';
import { hasValue, IFormOption } from '../../data/utils/form';
import { allContractCategoryOptions } from './ContractCategoryInput';

export function getConfigurationDetailsSchema(props: ConfigurationDetailsFormProps): ObjectSchema<Configuration> {
    return object({
        id: number(),
        name: string().required(requiredMessage),
        contractCategory: mixed<ContractCategory>()
            .required(requiredMessage)
            .when('id', (id: number, schema: StringSchema) => {
                const options: IFormOption[] = hasValue(id)
                    ? props.savedContractCategoryOptions
                    : allContractCategoryOptions;

                return schema.oneOf(options.map(option => option.value));
            }),
        equipmentCatalogId: number().required(requiredMessage),
        activityCatalogId: number().required(requiredMessage),
        catalogRegionId: number().required(requiredMessage),
        state: mixed<ConfigurationState>().oneOf([ 'DRAFT', 'COMPLETED' ]),
        engineInformation: object().shape({
            catalogEngineId: number(),
            engineType: string().required(requiredMessage),
            engineVersion: string().required(requiredMessage),
            maintenanceSection: string().required(requiredMessage),
            maintenanceSchedule: string().required(requiredMessage),
            equipmentModel: string().required(requiredMessage),
            electricalPower: number().required(requiredMessage),
            mechanicalPower: number().required(requiredMessage),
            oilVolume: number().required(requiredMessage),
            unitType: mixed<UnitType>()
                .required(requiredMessage)
                .oneOf([ 'JGC', 'JGS', 'JMC', 'JMS' ]),
            gasType: mixed<GasType>()
                .required(requiredMessage)
                .oneOf([ 'NG', 'BG', 'APG', 'LFG', 'CMG', 'WG', 'OTHER' ]),
            productProgram: string().required(),
        }),
        term: object().shape({
            effectiveContractStartDate: date().required(requiredMessage),
            commercialOperationDate: date()
                .required(requiredMessage)
                .when(
                    'effectiveContractStartDate',
                    (effectiveContractStartDate: string, schema: StringSchema) => {
                        return schema.test(
                            'commercial-operation-date',
                            'The field must be greater than or equal to the effective contract start date',
                            commercialOperationDate => commercialOperationDate >= effectiveContractStartDate,
                        );
                    }),
            sunsetClauseType: mixed<SunsetClauseType>()
                .required(requiredMessage)
                .oneOf([ 'EXPIRATION_DATE', 'MAX_DURATION', 'NONE' ]),
            maxDuration: mixed().when('sunsetClauseType', {
                is: 'MAX_DURATION',
                then: number()
                    .required(requiredMessage)
                    .min(1, rangeMessage(1, 99))
                    .max(99, rangeMessage(1, 99)),
                otherwise: mixed().strip(true),
            }),
            expirationDate: mixed().when('sunsetClauseType', {
                is: 'EXPIRATION_DATE',
                then: date().required(requiredMessage),
                otherwise: mixed().strip(true),
            }),
        }),
    })
}
