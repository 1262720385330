export interface IUserInfo {
    user_name : string;
    nickname : string;
    authorities : Authority[];
}

export interface IUserMailData {
    username: string;
    displayName: string;
    mail: string;
}

export enum Authority {
    ACCESS_APPLICATION = 'ACCESS_APPLICATION',
    READ_CATALOGS = 'READ_CATALOGS',
    READ_MODELS = 'READ_MODELS',
    READ_REPORTS = 'READ_REPORTS',
    WRITE_CATALOGS = 'WRITE_CATALOGS',
    WRITE_MODELS_IN_PROGRESS = 'WRITE_MODELS_IN_PROGRESS',
    WRITE_MODELS_AS_SOLD = 'WRITE_MODELS_AS_SOLD',
    WRITE_MODELS_HANDOVER = 'WRITE_MODELS_HANDOVER',
    WRITE_MODELS_OTR = 'WRITE_MODELS_OTR',
    WRITE_BILL_OF_SERVICES = 'WRITE_BILL_OF_SERVICES',
    WRITE_UNIT_READ_COUNTER = 'WRITE_UNIT_READ_COUNTER',
    WRITE_AMENDMENT = 'WRITE_AMENDMENT',
    WRITE_ACTUALIZATION = 'WRITE_ACTUALIZATION',
}
