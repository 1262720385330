// Defined by webpack.
declare const AUTH_ROOT: string;
declare const REQUEST_FETCH_DEFAULTS: object;
declare const AUTH_INFO_REDIRECT_URI: string;
// Only defined directly for non-deployments (storybook, testing),
// otherwise inserted via webpack-dotenv (see below)
declare const AUTH_INFO_CLIENT_ID: string | undefined;
declare const AUTH_INFO_CLIENT_SECRET: string | undefined;

export const authRoot = AUTH_ROOT;
export const requestFetchDefaults = REQUEST_FETCH_DEFAULTS;
export const authInfo: IAuthInfo = {
    redirectUri: AUTH_INFO_REDIRECT_URI,

    // inserted via webpack-dotenv for deployments
    clientId: AUTH_INFO_CLIENT_ID || process.env.AUTH_INFO_CLIENT_ID,
    clientSecret: AUTH_INFO_CLIENT_SECRET || process.env.AUTH_INFO_CLIENT_SECRET,
};

export interface IAuthInfo {
    clientSecret: string;
    clientId: string;
    redirectUri: string;
}

