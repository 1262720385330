import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { formOption } from '../../utils/formOption';
import { SunsetClauseType } from '../../api/model-service/model-service';

export const SunsetClauseTypeInput: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'term.sunsetClauseType';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Sunset Clause Defined?'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

const options: Array<IFormOption<SunsetClauseType>> = [
    formOption('NONE', 'None'),
    formOption('MAX_DURATION', 'Maximal Duration'),
    formOption('EXPIRATION_DATE', 'Expiration Date'),
];
