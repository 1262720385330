import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { FieldProps } from './FieldProps';
import { IFormOption } from '../../data/utils/form';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { Activity } from '../../api/model-service/model-service';
import { FormikProps } from '../Formik';
import { formOption } from '../../utils/formOption';
import { resetInput } from './resetInput';

export const ScopeField: FC<FieldProps> =
    ({ formikProps }) => {
        const name = 'scope';
        const error = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='Scope Level'
                placeholder='Select a scope'
                options={options}
                value={inputValue(name, formikProps)}
                onChange={evt => {
                    formikProps.handleChange(evt);
                    resetSchedulingType(formikProps);
                }}
                onBlur={formikProps.handleBlur}
                error={!!error}
                helperText={error}
                margin='dense'
                autoFocus
                fullWidth
            />
        );
    };

export const options: IFormOption[] = [
    formOption('UNIT', 'Unit'),
    formOption('SITE', 'Site'),
];

function resetSchedulingType(formikProps: FormikProps<Activity>): void {
    resetInput('schedulingType', formikProps);
    resetInput('frequency', formikProps);
}

