import React, { Component, ReactNode } from 'react';
import { Table } from '../Tables/Table';
import { ErrorTableHeader } from './ErrorTableHeader';
import { Link, StyledComponentProps, TableBody, TableFooter, TableRow, Typography } from '@material-ui/core';
import { TableDataRow } from '../Tables/TableDataRow';
import { TableDataCell } from '../Tables/TableDataCell';
import { TablePagination } from '../Tables/TablePagination';
import { ActualizationResult } from '../../api/model-service/actualization';
import { Checkbox } from '../Form/Checkbox';
import { isEmpty } from 'lodash';
import { ExtendedActualizationResult } from './ResultsTable';
import { NoDataRow } from './NoDataRow';

export class ErrorResultsTable extends Component<ComponentProps, TableState> {
    private static PAGE_SIZE = 100;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
        }
    }

    public componentDidUpdate(previousProps): void {
        if (previousProps.results.length !== this.props.results.length) {
            this.setState({
                page: 0,
            });
        }
    }

    public render(): ReactNode {
        const {testName, onSelectAll} = this.props;
        const displayedResults = this.createPageSlice();
        const hasResults = !isEmpty(displayedResults);

        return (
            <Table testName={testName}>
                <ErrorTableHeader
                    allSelected={this.isAllSelected()}
                    onSelectAll={evt => onSelectAll(evt.target.checked)}
                />
                <TableBody>
                    {!hasResults && <NoDataRow colSpan={4}/>}
                    {displayedResults.map(actualizationResult =>
                        <TableDataRow
                            testName={testName}
                            testId={'' + actualizationResult.id}
                            key={this.getRowKey(actualizationResult)}
                           >
                            <TableDataCell testName='opportunityNumber'>
                                <Checkbox
                                    testName='selectedItem'
                                    testId={'' + actualizationResult.id}
                                    checked={actualizationResult.isSelected}
                                    onClick={(evt) => evt.stopPropagation()}
                                    onChange={evt => {
                                        this.props.onSelected(actualizationResult.id, evt.target.checked);
                                    }}
                                />
                                {actualizationResult.candidate.opportunityNumber}
                            </TableDataCell>
                            <TableDataCell testName='displayName'>
                                <Link target='_blank'
                                      href={this.getConfigurationDetailsUrl(actualizationResult)}
                                      onClick={(evt) => evt.stopPropagation()}>
                                    {actualizationResult.candidate.displayName}
                                </Link>
                            </TableDataCell>

                            <TableDataCell testName='errors'>
                                {this.buildErrorNodes(actualizationResult)}
                            </TableDataCell>
                        </TableDataRow>,
                    )}
                </TableBody>
                <TableFooter>
                    {this.getTotalPages() > 1
                        ? <TableRow>
                            <TablePagination
                                colSpan={3}
                                rowsPerPageOptions={[]}
                                count={this.props.results.length}
                                page={this.state.page}
                                rowsPerPage={ErrorResultsTable.PAGE_SIZE}
                                onChangePage={(e, page) => this.showPage(page)}
                                SelectProps={{native: true}}
                            />
                        </TableRow>
                        : null
                    }
                </TableFooter>
            </Table>
        );
    }

    private getRowKey(result: ExtendedActualizationResult): string {
        return `${result.id}-${result.isSelected}`;
    }

    private buildErrorNodes(badResult: ActualizationResult): ReactNode[] {
        return badResult.errors.map(error => <Typography key={error}>{error}</Typography>);
    }

    private getConfigurationDetailsUrl(result: ActualizationResult): string {
        // TODO use 2 links: one for the original and one for the clone
        const candidate = result.clone || result.candidate;
        return `/models/${candidate.modelId}/configuration/view/${candidate.id}`;
    }

    private getTotalPages(): number {
        return Math.ceil(this.props.results.length / ErrorResultsTable.PAGE_SIZE);
    }

    private createPageSlice(): ExtendedActualizationResult[] {
        const {page} = this.state;
        const sliceStart = page * ErrorResultsTable.PAGE_SIZE;
        const sliceEnd = (page + 1) * ErrorResultsTable.PAGE_SIZE;

        return this.props.results.slice(sliceStart, sliceEnd);
    }

    private showPage(page: number): void {
        this.setState({page});
    }

    private isAllSelected(): boolean {
        const results = this.props.results;
        return !isEmpty(results) && results.every(result => result.isSelected);
    }
}

interface TableState {
    page: number,
}

type ComponentProps = TableProps & StyledComponentProps;

export interface TableProps {
    results: ExtendedActualizationResult[],
    testName: string,
    onSelectAll: (checked: boolean) => void,
    onSelected: (id: number, checked: boolean) => void,
}
