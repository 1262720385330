import React, { ChangeEvent, Component, ReactNode } from 'react';
import { Configuration, ContractCategory, EngineInformation, Model } from '../../api/model-service/model-service';
import { FormikProps } from '../Formik';
import { Grid } from '@material-ui/core';
import { Form } from '../Form/Form';
import { Name } from './Name';
import { ContractCategoryInput } from './ContractCategoryInput';
import { hasValue, IFormOption } from '../../data/utils/form';
import { ServiceRegion } from './ServiceRegion';
import { ActivityCatalog } from './ActivityCatalog';
import {
    CatalogEngineInformation,
    CatalogEngineInformationResponse,
} from '../../api/catalog-service/engineInformation';
import { EngineType } from './EngineType';
import { EngineVersion } from './EngineVersion';
import { MaintenanceSection } from './MaintenanceSection';
import { MaintenanceSchedule } from './MaintenanceSchedule';
import { ElectricalPower } from './ElectricalPower';
import { MechanicalPower } from './MechanicalPower';
import { OilVolume } from './OilVolume';
import { UnitTypeInput } from './UnitTypeInput';
import { GasTypeInput } from './GasTypeInput';
import { ProductProgram } from './ProductProgram';
import { EffectiveContractStartDate } from './EffectiveContractStartDate';
import { CommercialOperationDate } from './CommercialOperationDate';
import { SunsetClauseTypeInput } from './SunsetClauseType';
import { MaxDuration } from './MaxDuration';
import { ExpirationDate } from './ExpirationDate';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { State } from '../../data/store';
import { connect } from 'react-redux';
import { ActivityCatalog as ActivityCatalogType } from '../../api/catalog-service/activityCatalogs';
import { getCatalogEngineInformation } from '../../state/catalogEngineInformation/getCatalogEngineInformation';
import { formOption } from '../../utils/formOption';
import { uniqBy } from 'lodash';
import { isModelAsSold } from '../../utils/isModelAsSold';

export class ConfigurationDetailsInputs extends Component<ComponentProps> {

    public state = {};

    public componentDidMount(): void {
        if (this.hasActivityCatalog()) {
            const configuration = this.props.formikProps.values;
            this.props.getCatalogEngineInformation(
                configuration.equipmentCatalogId, configuration.activityCatalogId);
        }
    }

    public componentDidUpdate(prevProps: ComponentProps): void {
        const configuration = this.props.formikProps.values;

        if (this.contractCategoryGroupChanged(prevProps) && this.isNewConfiguration()) {
            this.updateDefaultActivityCatalog();
        }

        if (this.activityCatalogChanged(prevProps) && this.hasActivityCatalog()) {
            this.props.getCatalogEngineInformation(
                configuration.equipmentCatalogId, configuration.activityCatalogId);
        }

        if (this.catalogEngineInformationChanged(prevProps)) {
            const engineInformation = configuration.engineInformation;
            this.updateEngineInformation(
                engineInformation.engineType, engineInformation.engineVersion, engineInformation.maintenanceSection);
        }
    }

    public render(): ReactNode {
        const formikProps = this.props.formikProps;
        const disabled = isModelAsSold(this.props.model);
        return (
            <Form testName='configurationDetails' noValidate>
                <Grid container spacing={24}>
                    <Name disabled={disabled} formikProps={formikProps} container={<Grid item xs={12} sm={4}/>}/>
                    <ContractCategoryInput
                        savedContractCategoryOptions={this.props.savedContractCategoryOptions}
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={4}/>}
                    />
                    <ServiceRegion
                        regionCatalogs={this.props.regionCatalogs}
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={4}/>}
                    />
                    <ActivityCatalog
                        activityCatalogs={this.props.activityCatalogs}
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={4}/>}
                    />
                    <EngineType
                        options={this.getEngineTypeOptions()}
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={4}/>}
                        onChange={evt => this.handleEngineTypeChange(evt)}
                    />
                    <EngineVersion
                        options={this.getEngineVersionOptions()}
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={4}/>}
                        onChange={evt => this.handleEngineVersionChange(evt)}
                    />
                    <MaintenanceSection
                        options={this.getMaintenanceSectionOptions()}
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={4}/>}
                        onChange={evt => this.handleMaintenanceSectionChange(evt)}
                    />
                    <MaintenanceSchedule
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={3}/>}
                    />
                    <ElectricalPower
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={3}/>}
                    />
                    <MechanicalPower
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={3}/>}
                    />
                    <OilVolume
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={3}/>}
                    />
                    <UnitTypeInput
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={6} md={3}/>}
                    />
                    <GasTypeInput
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={6} md={3}/>}
                    />
                    <ProductProgram
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={12} sm={6} md={3}/>}
                    />
                    <EffectiveContractStartDate
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={6} md={3}/>}
                    />
                    <CommercialOperationDate
                        disabled={disabled} formikProps={formikProps}
                        container={<Grid item xs={12} sm={6} md={3}/>}
                    />
                    <SunsetClauseTypeInput
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={6} md={3}/>}
                    />
                    <MaxDuration
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={6} md={3}/>}
                    />
                    <ExpirationDate
                        disabled={disabled}
                        formikProps={formikProps}
                        container={<Grid item xs={6} md={3}/>}
                    />
                </Grid>
            </Form>
        );
    }

    private hasActivityCatalog(): boolean {
        return hasValue(this.props.formikProps.values.activityCatalogId);
    }

    private getEngineTypeOptions(): IFormOption[] {
        return uniqBy(this.getEngineInformation(), 'type')
            .map(info => formOption(info.type));
    }

    private getEngineVersionOptions(): IFormOption[] {
        const engineType = this.props.formikProps.values.engineInformation.engineType;
        const engineInformationMatchingType = this.filterCatalogEngineInformation(engineType);
        return uniqBy(engineInformationMatchingType, 'version')
            .map(info => formOption(info.version));
    }

    private getMaintenanceSectionOptions(): IFormOption[] {
        const { engineType, engineVersion } = this.props.formikProps.values.engineInformation;
        const engineInformationMatchingTypeAndVersion =
            this.filterCatalogEngineInformation(engineType, engineVersion);
        return uniqBy(engineInformationMatchingTypeAndVersion, 'maintenanceSection')
            .map(info => formOption(info.maintenanceSection));
    }

    private getEngineInformation(): CatalogEngineInformation[] {
        const catalogEngineInformation = this.props.catalogEngineInformation;
        return hasValue(catalogEngineInformation)
            ? catalogEngineInformation.engineInformation
            : [];
    }

    private handleEngineTypeChange(evt: ChangeEvent<HTMLSelectElement>): void {
        this.updateEngineInformation(evt.target.value);
    }

    private handleEngineVersionChange(evt: ChangeEvent<HTMLSelectElement>): void {
        const engineInformation = this.props.formikProps.values.engineInformation;
        this.updateEngineInformation(engineInformation.engineType, evt.target.value);
    }

    private handleMaintenanceSectionChange(evt: ChangeEvent<HTMLSelectElement>): void {
        const engineInformation = this.props.formikProps.values.engineInformation;
        this.updateEngineInformation(engineInformation.engineType, engineInformation.engineVersion, evt.target.value);
    }

    private updateEngineInformation(type: string, version?: string, maintenanceSection?: string): void {
        const selectedEngineInformation =
            this.findCatalogEngineInformation(type, version, maintenanceSection);
        const updatedEngineInformation: EngineInformation = selectedEngineInformation
            ? {
                ...this.props.formikProps.values.engineInformation,
                catalogEngineId: selectedEngineInformation.id,
                engineType: selectedEngineInformation.type,
                engineVersion: selectedEngineInformation.version,
                maintenanceSection: selectedEngineInformation.maintenanceSection,
                maintenanceSchedule: selectedEngineInformation.maintenanceSchedule,
                equipmentModel: selectedEngineInformation.equipmentModel,
                electricalPower: selectedEngineInformation.electricalPower,
                mechanicalPower: selectedEngineInformation.mechanicalPower,
                oilVolume: selectedEngineInformation.oilVolume,
            }
            : {
                ...this.props.formikProps.values.engineInformation,
                catalogEngineId: null,
                engineType: null,
                engineVersion: null,
                maintenanceSection: null,
                maintenanceSchedule: null,
                equipmentModel: null,
                electricalPower: null,
                mechanicalPower: null,
                oilVolume: null,
            };
        this.setEngineInformationFields(updatedEngineInformation);
    }

    private findCatalogEngineInformation(type: string,
                                         version: string,
                                         maintenanceSection: string): CatalogEngineInformation {

        return this.getEngineInformation().find(engine =>
            engine.type === type &&
            (!version || engine.version === version) &&
            (!maintenanceSection || engine.maintenanceSection === maintenanceSection),
        );
    }

    private filterCatalogEngineInformation(type: string, version?: string): CatalogEngineInformation[] {
        return this.getEngineInformation().filter(engine =>
            (!type || engine.type === type) &&
            (!version || engine.version === version),
        );
    }

    private setEngineInformationFields(updatedEngineInformation: EngineInformation): void {
        const formikProps = this.props.formikProps;
        formikProps.setFieldValue('engineInformation.catalogEngineId', updatedEngineInformation.catalogEngineId);
        formikProps.setFieldValue('engineInformation.engineType', updatedEngineInformation.engineType);
        formikProps.setFieldValue('engineInformation.engineVersion', updatedEngineInformation.engineVersion);
        formikProps.setFieldValue('engineInformation.maintenanceSection', updatedEngineInformation.maintenanceSection);
        formikProps.setFieldValue('engineInformation.maintenanceSchedule', updatedEngineInformation.maintenanceSchedule);
        formikProps.setFieldValue('engineInformation.equipmentModel', updatedEngineInformation.equipmentModel);
        formikProps.setFieldValue('engineInformation.electricalPower', updatedEngineInformation.electricalPower);
        formikProps.setFieldValue('engineInformation.mechanicalPower', updatedEngineInformation.mechanicalPower);
        formikProps.setFieldValue('engineInformation.oilVolume', updatedEngineInformation.oilVolume);
    }

    private activityCatalogChanged(prevProps: ComponentProps): boolean {
        return prevProps.formikProps.values.activityCatalogId !==
            this.props.formikProps.values.activityCatalogId;
    }

    private catalogEngineInformationChanged(prevProps: ComponentProps): boolean {
        return prevProps.catalogEngineInformation !== this.props.catalogEngineInformation;
    }

    private contractCategoryGroupChanged(prevProps: ComponentProps): boolean {
        const wasMsaContract = this.isMsaCategory(prevProps.formikProps.values.contractCategory);
        const isMsaContract = this.isMsaCategory(this.props.formikProps.values.contractCategory);
        return wasMsaContract !== isMsaContract;
    }

    private isMsaCategory(contractCategory: ContractCategory): boolean {
        return contractCategory.startsWith('MSA_');
    }

    private isNewConfiguration(): boolean {
        return !hasValue(this.props.formikProps.values.id);
    }

    private updateDefaultActivityCatalog(): void {
        const formikProps = this.props.formikProps;
        const defaultActivityCatalog = this.isMsaCategory(formikProps.values.contractCategory)
            ? this.getMsaDefaultCatalog()
            : this.getCsaDefaultCatalog();
        const catalogId = defaultActivityCatalog ? defaultActivityCatalog.id : null;
        formikProps.setFieldValue('activityCatalogId', catalogId);
    }

    private getMsaDefaultCatalog(): ActivityCatalogType {
        const msaCatalog = this.props.activityCatalogs.find(
            catalog => catalog.name.trim().toLowerCase().startsWith('msa'));
        return msaCatalog || this.getCsaDefaultCatalog();
    }

    private getCsaDefaultCatalog(): ActivityCatalogType {
        return this.props.activityCatalogs.find(
            catalog => catalog.state === 'DEFAULT');
    }
}

type ComponentProps = ConfigurationDetailsInputsProps & StateProps & ActionProps;

export interface ConfigurationDetailsInputsProps {
    model: Model,
    formikProps: FormikProps<Configuration>,
    savedContractCategoryOptions: IFormOption[],
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalogType[],
}

export interface StateProps {
    catalogEngineInformation: CatalogEngineInformationResponse,
    loading: boolean,
}

function mapStateToProps(state: State): StateProps {
    const catalogEngineInformationState = state.REFACTORED.catalogEngineInformation;
    return {
        catalogEngineInformation: catalogEngineInformationState.data,
        loading: catalogEngineInformationState.loading,
    };
}

interface ActionProps {
    getCatalogEngineInformation: (equipmentCatalogId: number, activityCatalogId: number) => void,
}

const mapDispatchToProps: ActionProps = {
    getCatalogEngineInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationDetailsInputs);
