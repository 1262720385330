import {
    IFormModel,
    IModelCompetitor,
    IServiceModel,
    ModelPageRequest,
} from '../model/model';
import { IModelAction, MODEL } from '../actions/models';
import { Page } from '../model/page';
import { PageRequest, SortOrder } from '../model/pageRequest';
import { ICatalogRegion } from '../model/catalog_region';
import { CONFIGURATIONS } from '../actions/configurations';
import {IUserMailData} from "../model/user";

export const models = (
    state: IModelState = InitialModelState,
    action: IModelAction
): IModelState => {
    switch (action.type) {
        case MODEL.UPDATE:
        case MODEL.GETONE:
        case CONFIGURATIONS.CLONE:
        case MODEL.HANDOVER:
        case MODEL.TRANSITION:
        case MODEL.TRANSITION_WITH_ERROR:
            return Object.assign({}, state, {
                activeModel: action.activeModel,
                amendmentDetails:null
            });
        case MODEL.AMENDMENT_DETAILS:
            return Object.assign({}, state, {
                amendmentDetails: action.amendmentDetails,
            });
        case MODEL.INIT:
            return Object.assign({}, state, {
                regions: action.regions,
                competitors: action.competitors,
                contractManagers: action.contractManagers
            });
        case MODEL.DELETE:
        case MODEL.CREATE:
        case MODEL.READ:
            return Object.assign({}, state, {
                modelList: action.modelList,
                modelListPage: action.modelListPage,
            });
        case MODEL.ALTER:
            const newState: any = {};
            newState.activeModel = Object.assign({}, state.activeModel, {
                [action.key]: action.value,
            });
            return Object.assign({}, state, newState);
        case MODEL.ERROR:
        default:
            return state;
    }
};

export interface IModelState {
    modelList: Page<IServiceModel>;
    modelListPage: ModelPageRequest;
    activeModel: IFormModel;
    competitors: IModelCompetitor[];
    regions: ICatalogRegion[];
    contractManagers: IUserMailData[];
}

export const InitialModelState: IModelState = {
    activeModel: {} as IFormModel,
    competitors: new Array(),
    modelListPage: new ModelPageRequest().setLength(25).setSort(SortOrder.Desc),
    modelList: new Page<IServiceModel>(),
    regions: [],
    contractManagers: []
};
