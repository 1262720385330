import { IndexType } from './IndexType';
import { Escalation } from '../../api/model-service/model-service';
import { FormikProps } from '../Formik';
import { inputValue } from '../../utils/inputValue';

export function hasIndexRatio(type: IndexType, formikProps: FormikProps<Escalation>): boolean {
    const indexRatio = inputValue(`indexRatio.${type}`, formikProps);
    const ratioNumber = parseFloat(indexRatio);
    return !Number.isNaN(ratioNumber) &&
        Number.isFinite(ratioNumber) &&
        ratioNumber !== 0;
}
