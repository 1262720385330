import React, { Component, ReactNode } from 'react';
import { Activity, ActivityUnit } from '../../api/model-service/model-service';
import { Button } from '../Buttons/Button';
import { SubmitButton } from '../Form/SubmitButton';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form } from '../Form/Form';
import { ObjectSchema } from 'yup';
import { Dialog } from '../Dialogs/Dialog';
import { ScopeField } from './ScopeField';
import { Service } from './Service';
import { Type } from './Type';
import { SchedulingType } from './SchedulingType';
import { Frequency } from './Frequency';
import { Occurrence } from './Occurrence';
import { Value } from './Value';
import { getAdditionalScopeSchema } from './schema';

export class EditAdditionalActivityDialog extends Component<EditAdditionalActivityDialogProps> {

    private static defaultValues(): Activity {
        return {
            scope: 'UNIT',
            service: null,
            type: null,
            additionalAttribute: null,
            schedulingType: 'OPH',
            frequency: null,
            frequencyOffset: null,
            frequencyModified: false,
            occurrence: null,
            fixedOccurrence: null,
            value: null,
            unit: null,
            icValue: null,
        };
    }

    private static addMissingFields(activity: Activity): Activity {
        const fixedOccurrence = Number.isFinite(activity.occurrence);

        return {
            ...activity,
            fixedOccurrence,
            icValue: activity.value,
        };
    }

    public render(): ReactNode {
        const initialValues = this.props.activity || EditAdditionalActivityDialog.defaultValues();
        const schema = getAdditionalScopeSchema();

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(scope, actions) => this.handleSubmit(scope, actions, schema)}
            >
                {formikProps =>
                    <Dialog
                        testName={this.isEditDialog() ? 'editAdditionalScope' : 'addAdditionalScope'}
                        open={this.props.open}
                        onClose={() => this.handleCancel(formikProps)}
                        maxWidth='md'
                        fullWidth
                    >
                        <Form testName='additionalScope' onSubmit={formikProps.handleSubmit}>
                            <DialogTitle>
                                <Grid
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='center'
                                >
                                    Configure Additional Scope
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <ScopeField formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Service formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Type formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SchedulingType formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Frequency formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Occurrence formikProps={formikProps}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Value formikProps={formikProps}/>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button testName='cancel' onClick={this.props.onCancel}>Cancel</Button>
                                <SubmitButton testName='apply' color='primary'>Apply</SubmitButton>
                            </DialogActions>
                        </Form>
                    </Dialog>
                }
            </Formik>
        );
    }

    private isEditDialog(): boolean {
        return !!this.props.activity;
    }

    private handleSubmit(activity: Activity,
                         actions: FormikActions<Activity>,
                         schema: ObjectSchema<Activity>): void {

        this.props.onSubmit(EditAdditionalActivityDialog.addMissingFields(schema.cast(activity)));
        actions.resetForm();
        actions.setSubmitting(false);
    }

    private handleCancel(formikProps: FormikProps<Activity>): void {
        if (formikProps.isSubmitting) {
            return;
        }

        formikProps.resetForm();
        this.props.onCancel();
    }
}

export interface EditAdditionalActivityDialogProps {
    open: boolean,
    activity?: Activity,
    onCancel: () => void,
    onSubmit: (scope: Activity) => void,
}
