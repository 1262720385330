export const stringMessage = 'Field must be text';
export const dateMessage = 'Field must be a valid date';
export const numberMessage = 'Field must be a valid number';
export const booleanMessage = 'Field must be "Yes" or "No"';
export const arrayMessage = 'Field must be a list';
export const requiredMessage = 'Field is required';
export const integerMessage = 'Field must be an integer';
export const yearMonthMessage = 'Field must be a year-month value';
export const rangeMessage = (min, max) => `Field must be between ${min} and ${max}`;
export const minMessage = 'Field must be ${min} or greater';
export const pastMessage = 'Field must be in the past';
