import { createAction, createReducer } from '@reduxjs/toolkit';
import { Actualization } from '../../api/model-service/actualization';

interface ActualizationState {
    loading: boolean,
    error: string,
    data: Actualization,
}

export const startLoading = createAction('createActualization/startLoading');
export const errorResponse = createAction<Error>('createActualization/errorResponse');
export const successResponse = createAction<Actualization>('createActualization/successResponse');
export const clearData = createAction('createActualization/clearData');

const initialState: ActualizationState = {
    loading: false,
    error: null,
    data: null,
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            error: null,
            data: action.payload,
        }))
        .addCase(clearData, () => initialState),
);

export default reducer;
