import React, { cloneElement } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';
import { InputAdornment } from '@material-ui/core';
import { withFixedPriceEscalation } from './withEscalationType';

export const FixedPriceEscalationValue = withFixedPriceEscalation<FieldProps>(
    ({ formikProps, container }) => {
        const name = 'fixedPriceEscalationValue';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Fixed Value'
                    type='number'
                    value={inputValue(name, formikProps)}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    inputProps={{
                        min: '0',
                        max: '10',
                    }}
                />,
        });
    },
);
