import React, { cloneElement, FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputValue } from '../../utils/inputValue';
import { TextField } from '../TextField/TextField';

export const OilVolume: FC<FieldProps> =
    ({ formikProps, container }) => {
        const name = 'engineInformation.oilVolume';
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Oil Volume'
                    value={inputValue(name, formikProps)}
                    margin='dense'
                    fullWidth
                    disabled
                />,
        });
    };
