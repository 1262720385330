import React from 'react';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Guarantee } from '../../api/model-service/model-service';
import { ObjectSchema } from '../../utils/validators';
import { GuaranteeEditFormProps } from './GuaranteesInterfaces';
import { Form } from '../Form/Form';
import { Grid, DialogActions } from '@material-ui/core';
import { SelectField } from '../SelectField/SelectField';
import { annualCapUnitOptions } from './GuaranteeOptions';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { RadioGroupField } from '../RadioGroupField/RadioGroupField';
import { TextField } from '../TextField/TextField';
import { booleanOptions } from '../../utils/booleanOptions';
import { getGuaranteeValidationSchema } from './schema';
import Button from '../Buttons/Button';
import { SubmitButton } from '../Form/SubmitButton';

export class GuaranteeEditForm extends React.Component<GuaranteeEditFormProps> {

    private getInitialValues(): Guarantee {
        return {
            type: null,
            value: null,
            unit: null,
            standardDefinitionAndWording: true,
            nonStandardDescription: null,
            ld: null,
            ldCap: null,
            bonus: null,
            bonusCap: null,
        }
    }

    public render() {
        const schema: ObjectSchema<Guarantee> = getGuaranteeValidationSchema();
        const initialValues: Guarantee = this.props.guarantee || this.getInitialValues();

        return (<Formik 
            initialValues={initialValues}
            validationSchema={schema} 
            onSubmit={(config, actions) => {
                this.handleSubmit(config, actions, schema)
            }}
            enableReinitialize>
                {
                (formikProps: FormikProps<Guarantee>) => {
                    const {handleChange, handleBlur } = formikProps;

                    return(<Form testName='editGuarantee' onSubmit={formikProps.handleSubmit} noValidate>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                    fullWidth
                                    label='Guarantee Type'
                                    placeholder='Select Guarantee Type'
                                    margin='dense'
                                    name='type'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={inputValue('type', formikProps)}
                                    error={!!inputError('type', formikProps)}
                                    helperText={inputError('type', formikProps)}
                                    options={this.props.guaranteeTypeOptions}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <RadioGroupField
                                    name='standardDefinitionAndWording'
                                    label='Standard Definition and Wording'
                                    options={booleanOptions}
                                    value={inputValue('standardDefinitionAndWording', formikProps)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                    fullWidth
                                    label='Guarantee Unit'
                                    placeholder='Select Guarantee Unit'
                                    margin='dense'
                                    name='unit'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={inputValue('unit', formikProps)}
                                    options={this.props.guaranteeUnitOptions}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label='Guaranteed Value'
                                    placeholder='1+'
                                    fullWidth
                                    type='number'
                                    name='value'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={inputValue('value', formikProps)}
                                    error={!!inputError('value', formikProps)}
                                    helperText={inputError('value', formikProps)}
                                    margin='dense'
                                    />
                                </Grid>
                                {inputValue('standardDefinitionAndWording', formikProps) === 'no' &&
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            label='Non-Standard Guarantee Description'
                                            placeholder=''
                                            fullWidth
                                            required
                                            margin='dense'
                                            name='nonStandardDescription'
                                            value={inputValue('nonStandardDescription', formikProps)}
                                            error={!!inputError('nonStandardDescription', formikProps)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            rowsMax='3'
                                            helperText={inputError('nonStandardDescription', formikProps)}
                                            />
                                    </Grid>
                                }
                                
                                <Grid item xs={6} sm={8}>
                                    <SelectField
                                        fullWidth
                                        label='LDs Unit'
                                        margin='dense'
                                        name={'ld.unit'}
                                        placeholder='Select LDs Unit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('ld.unit', formikProps)}
                                        options={this.props.ldBonusUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        label='LDs Value'
                                        placeholder='1+'
                                        margin='dense'
                                        type='number'
                                        name='ld.value'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('ld.value', formikProps)}
                                        error={!!inputError('ld.value', formikProps)}
                                        helperText={inputError('ld.value', formikProps)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={8}>
                                    <SelectField
                                        fullWidth
                                        label='LDs Cap Unit'
                                        margin='dense'
                                        placeholder='Select LDs Cap Unit'
                                        name='ldCap.unit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('ldCap.unit', formikProps)}
                                        options={annualCapUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        label='LDs Cap Value'
                                        placeholder='1+'
                                        margin='dense'
                                        type='number'
                                        name='ldCap.value'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('ldCap.value', formikProps)}
                                        error={!!inputError('ldCap.value', formikProps)}
                                        helperText={inputError('ldCap.value', formikProps)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={8}>
                                    <SelectField
                                        fullWidth
                                        label='Bonus Unit'
                                        margin='dense'
                                        placeholder='Select Bonus Unit'
                                        name='bonus.unit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('bonus.unit', formikProps)}
                                        error={!!inputError('bonus.unit', formikProps)}
                                        helperText={inputError('bonus.unit', formikProps)}
                                        options={this.props.ldBonusUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        label='Bonus Value'
                                        placeholder='1+'
                                        margin='dense'
                                        type='number'
                                        name='bonus.value'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('bonus.value', formikProps)}
                                        error={!!inputError('bonus.value', formikProps)}
                                        helperText={inputError('bonus.value', formikProps)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={8}>
                                    <SelectField
                                        fullWidth
                                        label="Cap Unit"
                                        placeholder='Select Cap Unit'
                                        margin="dense"
                                        name="bonusCap.unit"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('bonusCap.unit', formikProps)}
                                        options={annualCapUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        label='Cap Value'
                                        placeholder='1+'
                                        margin='dense'
                                        type='number'
                                        name='bonusCap.value'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={inputValue('bonusCap.value', formikProps)}
                                        error={!!inputError('bonusCap.value', formikProps)}
                                        helperText={inputError('bonusCap.value', formikProps)}
                                        fullWidth
                                    />
                                </Grid>
                        </Grid>
                        <DialogActions>
                            <Button testName='cancel' onClick={() => this.props.close()}>Cancel</Button>
                            <SubmitButton testName='apply' color='primary'>Confirm Edits</SubmitButton>
                        </DialogActions>
                    </Form>);
                }
            }
            </Formik>
        )
    }

    private handleSubmit(guarantee: Guarantee,
        actions: FormikActions<Guarantee>,
        schema: ObjectSchema<Partial<Guarantee>>): void {

        this.props.onSubmit(schema.cast(guarantee))
            .finally(() => {
                actions.setSubmitting(false);
            });
    }
}
