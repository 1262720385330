

import {IUserAction, USER} from "../actions/users";
import {IUserInfo} from "../model/user";

export const user = (
    state: IUserState = InitialUserState,
    action: IUserAction
): IUserState => {
    switch (action.type) {

        case USER.STORE:
            const newState: any = {};
            newState.currentUser = Object.assign({}, state.currentUser, action.user);
            return Object.assign({}, state, newState);
        default:
            return state;
    }
};

export interface IUserState {
    currentUser : IUserInfo;
}

export const InitialUserState: IUserState = {
    currentUser: undefined as IUserInfo,
};
