import { Activity } from '../api/model-service/model-service';
import { CatalogActivity } from '../api/catalog-service/catalogActivities';

export function activityToCatalogActivity(activity: Activity): CatalogActivity {
    return {
        key: activity.key,
        scope: activity.scope,
        service: activity.service,
        attribute: activity.additionalAttribute,
        type: activity.type,
        unit: activity.unit,
        frequency: activity.frequency,
        value: activity.value,
        icValue: activity.icValue,
    }
}
