import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { warrantyPeriodOptions } from './warrantyPeriodOptions';
import { FieldProps } from './FieldProps';

export const WarrantyPeriodParts: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.warrantyPeriodParts';
        const errorMessage = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='Warranty Period Parts (Years)'
                value={inputValue(name, formikProps)}
                disabled={disabled}
                options={warrantyPeriodOptions}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='dense'
                fullWidth
            />
        );
    };
