import { CatalogType } from '../../api/catalog-service/catalog-overview';
import { AppThunk } from '../app-thunk';
import { changeCatalogState } from '../catalog-overview/change-state';
import { errorResponse, startLoading, successChangeDefaultResponse } from './slice';

export const changeDefaultCatalog = (id: number, type: CatalogType): AppThunk => dispatch => {
    dispatch(startLoading());
    return dispatch(changeCatalogState(id, type, 'DEFAULT'))
        .then(() => {
            dispatch(successChangeDefaultResponse());
        })
        .catch(err => {
            dispatch(errorResponse(err));
        });
};
