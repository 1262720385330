import React, { FC, ReactNode } from 'react'
import Table from '../Tables/Table';
import TableBody from '@material-ui/core/TableBody';
import TableDataCell from '../Tables/TableDataCell';
import TableDataRow from '../Tables/TableDataRow';
import Checkbox from '../Form/Checkbox';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import { groupBy, isEmpty, sortBy, uniq } from 'lodash';
import { SelectField } from '../SelectField/SelectField';
import { iv } from '../../data/utils/form';
import { EditStandardScopeTableHeader } from './EditStandardScopeTableHeader';
import { Activity, ActivityType } from '../../api/model-service/model-service';

const orderedActivityTypes: ActivityType[] = [
    'INNIO_PARTS',
    'INNIO_LABOR',
    'OTHER_PROVIDER',
    'FREIGHT',
    'CUSTOMS',
];

const EditStandardScopeTable0: FC<StyledComponentProps & EditStandardScopeTableProps> =
    props => {
        const { allActivities, selectedActivities, onChange, disabled, classes } = props;

        const activitiesByRowKey = groupBy(allActivities, getActivityRowKey);
        const sortedRowKeys = sortBy(Object.keys(activitiesByRowKey));

        const selectedActivitiesByRowKey = groupBy(selectedActivities, getActivityRowKey);

        return (
            <Table testName='standardScope' className={classes.table}>
                <EditStandardScopeTableHeader/>
                <TableBody>
                    {sortedRowKeys.map(activityRowKey => {
                        const selectedActivitiesForKey = selectedActivitiesByRowKey[activityRowKey];
                        const hasSelectedActivitiesForKey = !isEmpty(selectedActivitiesForKey);
                        if (!hasSelectedActivitiesForKey) {
                            return null;
                        }

                        const activitiesForKey = activitiesByRowKey[activityRowKey];
                        const firstActivity = activitiesForKey[0];
                        return (
                            <TableDataRow
                                key={activityRowKey}
                                testName='standardActivity'
                                testId={activityRowKey}
                                className={classes.row}
                            >
                                <TableDataCell testName='scope' component='th' scope='row' className={classes.cell}>
                                    {firstActivity.scope}
                                </TableDataCell>
                                <TableDataCell testName='serviceAndFrequency' align='left' className={classes.cell}>
                                    {firstActivity.service} ({firstActivity.frequency})
                                </TableDataCell>
                                <TableDataCell testName='additionalAttribute' align='left' className={classes.cell}>
                                    {renderAdditionalAttributeInputField(
                                        props, activityRowKey, activitiesForKey, selectedActivitiesForKey)}
                                </TableDataCell>
                                {orderedActivityTypes.map(
                                    type => {
                                        const activityWithType = findActivityByType(type, activitiesForKey);

                                        const activityWithTypeExists = !!activityWithType;
                                        const typeDisabled = disabled || !activityWithTypeExists;

                                        const activityWithTypeIsSelected = !!findActivityByType(
                                            type, selectedActivitiesForKey);
                                        const cellKey = `${activityRowKey}-${type}`;
                                        return (
                                            <TableDataCell
                                                key={cellKey}
                                                testName={type}
                                                className={classes.cell}
                                                align='left'
                                            >
                                                <Checkbox
                                                    testName='type'
                                                    testId={type}
                                                    disabled={typeDisabled}
                                                    checked={activityWithTypeIsSelected}
                                                    onChange={evt => {
                                                        const checked = evt.target.checked;
                                                        if (!checked) {
                                                            const selectedWithoutThisType = selectedActivities.filter(selectedActivity => {
                                                                const activityIsOnDifferentRow = activityDoesntMatchKey(activityRowKey, selectedActivity);
                                                                const activityHasDifferentType = !activityHasType(type, selectedActivity);
                                                                return activityIsOnDifferentRow || activityHasDifferentType;
                                                            });
                                                            onChange(selectedWithoutThisType);
                                                        } else {
                                                            const selectedActivitiesCopy = selectedActivities.slice();
                                                            selectedActivitiesCopy.push(activityWithType);
                                                            onChange(selectedActivitiesCopy);
                                                        }
                                                    }}
                                                />
                                            </TableDataCell>
                                        );
                                    }
                                )}
                            </TableDataRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    };

export const EditStandardScopeTable = withStyles(getClasses)(EditStandardScopeTable0);

function getClasses() {
    return {
        head: {
            fontSize: 12,
            padding: 10,
        },
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#fbfbfb',
            },
        },
        cell: {
            fontSize: 12,
            padding: 10,
        }
    };
}

export interface EditStandardScopeTableProps {
    allActivities: Activity[],
    selectedActivities: Activity[],
    onChange: (activities: Activity[]) => void,
    disabled?: boolean,
}

function renderAdditionalAttributeInputField(props: EditStandardScopeTableProps,
                                             activityKey: string,
                                             activitiesForKey: Activity[],
                                             selectedActivitiesForKey: Activity[]): ReactNode {

    const selectedActivitiesWithAttribute = filterWithNonEmptyAttribute(selectedActivitiesForKey);
    const noActivityWithAttributeIsSelected = isEmpty(selectedActivitiesWithAttribute);
    if (noActivityWithAttributeIsSelected) {
        return null;
    }

    const additionalAttributes = getDistinctAdditionalAttributes(activitiesForKey);
    const attributeOptions = additionalAttributes.map(attr => iv(attr, attr));

    const selectedAttribute = selectedActivitiesWithAttribute[0].additionalAttribute;

    return (
        <SelectField
            name='attribute'
            label=''
            value={selectedAttribute}
            options={attributeOptions}
            fullWidth={true}
            disabled={props.disabled}
            onChange={evt => {
                const newAttribute = evt.target.value;

                const selectedWithNewAttribute = props.selectedActivities.map(selectedActivity => {
                    const activityIsOnDifferentRow = activityDoesntMatchKey(activityKey, selectedActivity);
                    const activityHasNoAttribute = !activityHasAttribute(selectedActivity);
                    if (activityIsOnDifferentRow || activityHasNoAttribute) {
                        return selectedActivity;
                    }

                    const type = selectedActivity.type;
                    const newActivity = findActivityByTypeAndAttribute(type, newAttribute, activitiesForKey);
                    return newActivity;
                });

                props.onChange(selectedWithNewAttribute);
            }}
        />
    );
}

function getDistinctAdditionalAttributes(activities: Activity[]): string[] {
    const additionalAttributes = filterWithNonEmptyAttribute(activities)
        .map(activity => activity.additionalAttribute);

    return uniq(additionalAttributes);
}

function filterWithNonEmptyAttribute(activities: Activity[]): Activity[] {
    return activities.filter(activityHasAttribute);
}

function activityHasAttribute(activity: Activity): boolean {
    return !isEmpty(activity.additionalAttribute);
}

function findActivityByType(type: ActivityType, activities: Activity[]): Activity {
    const activityWithType = activities.find(activity => activityHasType(type, activity));
    return activityWithType;
}

function activityHasType(type: ActivityType, activity: Activity): boolean {
    return activity.type === type;
}

function activityDoesntMatchKey(activityKey: string, selectedActivity: Activity): boolean {
    return getActivityRowKey(selectedActivity) !== activityKey;
}

/*
 As discussed with the business the way to generate new activity keys,
 are to always keep the structure scope_service_type_frequency. Here
 we remove the type part since it should be groupped by the algorithm.
*/
function getActivityRowKey(activity: Activity): string {
    const split = activity.key.split('_');
    split.splice(2,1);
    return split.join('_');
}

function findActivityByTypeAndAttribute(type: ActivityType, attribute: string, activities: Activity[]): Activity {
    return activities.find(activity => {
        return activity.type === type &&
            activity.additionalAttribute === attribute;
    });
}
