import React, { FC } from 'react';
import { TextField } from '../TextField/TextField';
import { MenuItem } from '../Popups/MenuItem';
import Button from '../Buttons/Button';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { CatalogOverview, CatalogType } from '../../api/catalog-service/catalog-overview';
import { SubmitButton } from '../Form/SubmitButton';
import { isEmpty } from 'lodash';

export const ChangeDefaultCatalogForm: FC<ChangeDefaultCatalogFormProps> =
    props => {
        const catalogs = props.catalogs;
        const defaultCatalog = findDefaultCatalog(catalogs);
        const defaultId = defaultCatalog ? defaultCatalog.id : null;
        const initialValues: DefaultIdValues = {
            id: defaultId,
        };
        const handleSubmit = (values: DefaultIdValues, actions: FormikActions<DefaultIdValues>) => {
            props.onSubmit(values.id)
                .finally(() => {
                    actions.setSubmitting(false);
                });
        };
        return (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {(formikProps: FormikProps<DefaultIdValues>) =>
                    <form onSubmit={formikProps.handleSubmit}>
                        <DialogTitle id='form-dialog-title'>
                            Set Default Catalog
                        </DialogTitle>
                        <DialogContent classes={{ root: 'dialogContent' }}>
                            <TextField
                                name='id'
                                label='Select Catalog'
                                placeholder='Catalog2019.2'
                                variant='outlined'
                                value={formikProps.values.id || ''}
                                onChange={evt => {
                                    const id = Number.parseInt(evt.target.value, 10);
                                    formikProps.setFieldValue('id', id);
                                }}
                                margin='normal'
                                select
                                fullWidth
                            >
                                {!isEmpty(catalogs)
                                    ? catalogs.map(catalog => {
                                        const isDefaultCatalog = isDefault(catalog);
                                        return (
                                            <MenuItem
                                                key={catalog.id}
                                                value={catalog.id}
                                                testName='selectCatalog'
                                                testId={'' + catalog.id}
                                            >
                                                {catalog.name}
                                                {isDefaultCatalog
                                                    ? <span>&nbsp;(current default)</span>
                                                    : null
                                                }
                                            </MenuItem>
                                        );
                                    })
                                    : 'No Catalogs'}
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color='default'
                                testName='cancel'
                                onClick={() => props.onCancel()}
                            >
                                Cancel
                            </Button>
                            <SubmitButton testName='save' color='primary'>Save</SubmitButton>
                        </DialogActions>
                    </form>
                }
            </Formik>
        );
    };

export interface ChangeDefaultCatalogFormProps {
    type: CatalogType,
    catalogs: CatalogOverview[],
    onCancel: () => void,
    onSubmit: (id: number) => Promise<void>,
}

interface DefaultIdValues {
    id: number,
}

function findDefaultCatalog(catalogs: CatalogOverview[]): CatalogOverview {
    return catalogs.find(isDefault);
}

function isDefault(catalog: CatalogOverview): boolean {
    return catalog.state === 'DEFAULT';
}
