import * as React from 'react';
import './ViewTitle.scss';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '../Form/InputBase';
import IconButton from '../Buttons/IconButton';

/*

Configure the Reusable Section Header for various scenarios.

*/

const styles = (theme: any) => ({
    searchInput: {
        backgroundColor: '#efefef',
        paddingLeft: 5,
        paddingRight: 5,
    },
});

interface IProps {
    search?: boolean,
    searchOnChange?: (keyword: string) => void,
    classes: {
        searchInput: string,
    },
    title?: string,
    children?: React.ReactNode,
}

const ViewTitle = (props: IProps) => {
    const { classes } = props;

    if (props.search) {
        return (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className="view-title-root"
            >
                <div className="view-title-left">
                    {props.title}
                    {props.children}
                </div>
                <div>
                    <InputBase
                        testName="pageHeaderSearch"
                        classes={{ input: classes.searchInput }}
                        placeholder=""
                        onChange={e => {
                            props.searchOnChange(e.target.value);
                        }}
                    />
                    <IconButton testName="search" aria-label="Search">
                        <SearchIcon />
                    </IconButton>
                </div>
            </Grid>
        );
    }

    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className="view-title-root"
        >
            <div className="view-title-left view-title-left-model-detail">
                {props.title}
                {props.children}
            </div>
        </Grid>
    );
};

export default withStyles(styles as any)(ViewTitle);
