import React, { FC } from 'react';
import { PricingContributionMarginProps } from './PricingContributionMarginProps';
import PricingContributionMargin from '../Configuration/Financials/Pricing/Forms/PricingContributionMargin';

export const MsaPricingContributionMargin: FC<PricingContributionMarginProps> =
    props =>
        <PricingContributionMargin
            {...props}
            showToolTargetPrice={false}
            showToolTargetDiscount
            showActualDiscount
            showReferencePriceList
            showDiscountMatrix
            showPackageBilling={true}
            showUserTargets={false}
        />
;
