import React, { Component, ReactNode } from 'react';
import { Activity } from '../../api/model-service/model-service';
import Confirm from '../Utils/Confirm';
import { ActionMenuItem } from '../ActionMenu/ActionMenu';
import { ActionCard } from '../ActionCard/ActionCard';
import { Typography } from '@material-ui/core';
import { uniq } from 'lodash';

export class StandardActivityCard extends Component<StandardActivityCardProps, ComponentState> {

    public state = {
        openDelete: false,
    };

    public render(): ReactNode {
        const { activities, testId } = this.props;
        const actions = this.getActions();

        const scope = activities[0].scope;
        const services = uniq(activities.map(activity => activity.service));
        services.sort();

        return (
            <>
                <ActionCard
                    testName='standardScope'
                    testId={testId}
                    title={scope}
                    actions={actions}
                >
                    <Typography variant='caption'>
                        Service: <strong>{services.join(', ')}</strong>
                    </Typography>
                </ActionCard>
                <Confirm
                    testName='confirmDelete'
                    title='Delete Standard Scope'
                    message={`Are you sure you want to delete ${scope} ? It cannot be undone.`}
                    action={() => {
                        this.props.onDelete();
                        this.closeDelete();
                    }}
                    open={this.state.openDelete}
                    handleClose={() => this.closeDelete()}
                />
            </>
        );
    }

    private getActions(): ActionMenuItem[] {
        return this.props.disabled
            ? []
            : [
                {
                    label: 'Edit',
                    testName: 'edit',
                    onClick: this.props.onOpenEdit,
                },
                {
                    label: 'Delete',
                    testName: 'delete',
                    onClick: () => this.openDelete(),
                },
            ];
    }

    private openDelete(): void {
        this.setState({ openDelete: true });
    }

    private closeDelete(): void {
        this.setState({ openDelete: false });
    }
}

export interface StandardActivityCardProps {
    testId: string,
    activities: Activity[],
    disabled?: boolean,
    onOpenEdit: () => void,
    onDelete: () => void,
}

interface ComponentState {
    openDelete: boolean,
}
