import React, { FC } from 'react';
import Select from 'react-select';
import { KnownKeys } from '../../utils/KnownKeys';
import { WithRequired } from '../../utils/WithRequired';
import { IFormOption } from '../../data/utils/form';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Control } from './Control';
import { StyledMultiSelectFieldProps } from './StyledMultiSelectFieldProps';
import { MultiValue } from './MultiValue';
import { Menu } from './Menu';
import { Option } from './Option';
import { createInputTestProps } from '../Utils/testProps';
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';

export const MultiSelectField: FC<StyledMultiSelectFieldProps> =
    props => {
        const {
            classes,
            label,
            value,
            error,
            disabled,
            helperText,
            required,
            onChange,
            ...remainingSelectProps
        } = props;

        const testProps = createInputTestProps(remainingSelectProps.name);
        const selectValue = getSelectValue(value, remainingSelectProps.options);
        const selectOnChange = getSelectOnChange(onChange);

        return (
            <CaseAuthorizedToEditForm>
                {authorizedToEdit =>
                    <div {...testProps}>
                        <Select
                            {...remainingSelectProps}
                            value={selectValue}
                            onChange={selectOnChange}
                            components={{
                                Control,
                                MultiValue,
                                Menu,
                                Option,
                            }}
                            isDisabled={getInputDisabled(authorizedToEdit, disabled)}
                            customProps={props}
                            styles={{
                                input: styles => ({
                                    ...styles,
                                    '& input': {
                                        font: 'inherit',
                                    },
                                }),
                                valueContainer: styles => ({
                                    ...styles,
                                    padding: 0,
                                }),
                            }}
                            isMulti
                        />
                    </div>
                }
            </CaseAuthorizedToEditForm>
        );
    };

type SelectProps = Pick<Select['props'], KnownKeys<Select['props']>>;

type SelectWithoutManagedProps = Omit<SelectProps,
    'components' | 'isMulti' | 'options' | 'isDisabled' | 'value' | 'onChange'>;

export interface MultiSelectFieldProps extends WithRequired<SelectWithoutManagedProps, 'name'> {
    label: string,
    error: boolean,
    helperText: TextFieldProps['helperText'],
    value: string[],
    options: IFormOption[],
    required?: boolean,
    disabled?: SelectProps['isDisabled'],
    onChange: ChangeHandler,
}

type ChangeHandler = (value: string[]) => void;


function getSelectValue(value: string[], options: IFormOption[]): IFormOption[] {
    return value.map(val =>
        options.find(option => option.value === val),
    );
}

function getSelectOnChange(onChange: ChangeHandler): SelectProps['onChange'] {
    return (optionsOrNull: IFormOption[]): void => {
        const options = optionsOrNull || [];
        const updatedValue = options.map(option => option.value);
        onChange(updatedValue);
    };
}

function getInputDisabled(authorizedToEdit: boolean, disabled: boolean): boolean {
    return !authorizedToEdit || disabled;
}
