import {
    createConfiguration,
    CreateUpdateConfigurationRequest,
    getConfiguration,
    updateConfiguration,
} from '../../api/model-service/configuration';
import { AppThunk } from '../app-thunk';
import { Configuration } from '../../api/model-service/model-service';
import { hasValue } from '../../data/utils/form';
import { successResponse } from './slice';
import { reportConfigurationError } from '../error-actions';
import { intersection, isEmpty, isEqual } from 'lodash';
import { ConcurrentModificationError } from '../../api/errors';

function isNewConfiguration(configuration: Configuration): boolean {
    return !hasValue(configuration.id);
}

export const saveConfiguration = (modelId: number, configuration: Configuration, skipFinancialData: boolean = false): AppThunk => (dispatch, getState) => {
    const oldConfiguration = getState().REFACTORED.configurationDetails.data;

    // Ideally this check should be implemented by hand to correctly
    // compare sets of objects and missing values
    if (isEqual(oldConfiguration, configuration)) {
        return Promise.resolve();
    }

    const request: CreateUpdateConfigurationRequest = { modelId, configuration, skipFinancialData };
    const apiClient = isNewConfiguration(configuration)
        ? createConfiguration
        : updateConfiguration;

    return apiClient(request)
        .then(updatedConfiguration => {
            dispatch(successResponse(updatedConfiguration));
        })
        .catch(err => {
            if (err instanceof ConcurrentModificationError) {
                return dispatch(handleConcurrentModificationError(modelId, configuration, err));
            }

            throw err;
        })
        .catch(err => {
            dispatch(reportConfigurationError(err));
            throw err;
        })
};

const handleConcurrentModificationError =
    (modelId: number, formConfiguration: Configuration, err: ConcurrentModificationError): AppThunk =>
        async (dispatch, getState) => {
            const oldConfiguration = getState().REFACTORED.configurationDetails.data;
            const latestConfiguration = await getConfiguration({ id: formConfiguration.id, skipFinancialData: true });
            const modifiedKeysFromLatest = getModifiedKeys(oldConfiguration, latestConfiguration);
            const modifiedKeysFromForm = getModifiedKeys(oldConfiguration, formConfiguration);
            const concurrentlyModifiedKeys = intersection(modifiedKeysFromForm, modifiedKeysFromLatest);
            if (!isEmpty(concurrentlyModifiedKeys)) {
                throw err;
            }

            const latestConfigurationWithUpdates = modifiedKeysFromForm.reduce(
                (updatedConfiguration, key) => ({
                    ...updatedConfiguration,
                    [key]: formConfiguration[key],
                }),
                latestConfiguration,
            );

            return dispatch(saveConfiguration(modelId, latestConfigurationWithUpdates, true));
        };

function getModifiedKeys(oldConfiguration: Configuration, newConfiguration: Configuration): string[] {
    const modifiedKeys = Object.keys(oldConfiguration).filter(key => {
        const oldValue = oldConfiguration[key];
        const newValue = newConfiguration[key];
        return !isEqual(oldValue, newValue);
    });
    const newKeys = Object.keys(newConfiguration)
        .filter(key => !oldConfiguration.hasOwnProperty(key));

    return modifiedKeys.concat(newKeys);
}
