import React from 'react';
import MaterialFormHelperText, { FormHelperTextProps as MaterialFormHelperTextProps } from '@material-ui/core/FormHelperText';
import { createErrorTestProps } from '../Utils/testProps';
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';

export const ErrorFormHelperText: React.FunctionComponent<FormHelperTextProps> =
    props => {
        const {
            testName,
            disabled,
            ...remainingFormHelperTextProps
        } = props;

        const fullHelperTextProps = {
            ...remainingFormHelperTextProps,
            ...createErrorTestProps(testName),
        };

        return (
            <CaseAuthorizedToEditForm>
                {authorizedToEdit => {
                    const inputDisabled = getInputDisabled(authorizedToEdit, disabled);
                    return (
                        <MaterialFormHelperText
                            error
                            disabled={inputDisabled}
                            {...fullHelperTextProps}
                        />
                    );
                }}
            </CaseAuthorizedToEditForm>
        );
    };

export interface FormHelperTextProps extends Omit<MaterialFormHelperTextProps, 'error'> {
    testName: string,
}

function getInputDisabled(authorizedToEdit: boolean, disabled: boolean) {
    return !authorizedToEdit || disabled;
}
