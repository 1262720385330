import React, { ReactNode } from 'react';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { PaymentEscalationProps } from '../PaymentEscalationFormSection/PaymentEscalationProps';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Configuration } from '../../api/model-service/model-service';
import { Form } from '../Form/Form';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { BillingCurrency } from './BillingCurrency';
import { BillingCurrencyFx } from './BillingCurrencyFx';
import { BillingInterval } from './BillingInterval';
import { BillingStartDate } from './BillingStartDate';
import { PaymentTerm } from './PaymentTerm';
import { MinOperatingHoursYear } from './MinOperatingHoursYear';
import { MinOperatingHoursYearViolationAgreement } from './MinOperatingHoursYearViolationAgreement';
import { ExtraWorkDiscountMaterial } from './ExtraWorkDiscountMaterial';
import { ExtraWorkDiscountLabor } from './ExtraWorkDiscountLabor';
import { OtherSpecialPayments } from './OtherSpecialPayments';
import { getPaymentSchema } from './schema';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { ObjectSchema } from 'yup';
import { Button } from '../Buttons/Button';
import { EditEscalationDialog } from '../EditEscalationDialog/EditEscalationDialog';
import { EscalationCard } from '../EscalationCard/EscalationCard';
import { append, remove, replace } from '../../utils/immutableArray';
import { EscalationErrors } from './EscalationErrors';
import { isModelAsSold } from '../../utils/isModelAsSold';

class PaymentEscalationForm0 extends ConfigurationForm<PaymentEscalationProps & StyledComponentProps, ComponentState> {

    private static defaultValues(configuration: Configuration): Configuration {
        return {
            ...configuration,
            payment: {
                billingCurrency: 'EUR',
                billingCurrencyFx: 0,
                billingInterval: 'MONTHLY',
                annualBillingStartDate: null,
                paymentTerm: 15,
                minimumOperatingHoursPerYear: null,
                minimumOperatingHoursPerYearViolationAgreement: 'Bill up to 100% of defined min. ophs',
                extraWorkDiscountMaterial: 0,
                extraWorkDiscountLabor: 0,
                otherSpecialPayments: null,
                escalations: [],
            },
        }
    }

    public state = {
        openAddEscalationDialog: false,
    };

    public render(): ReactNode {
        const props = this.props;

        const initialValues = props.configuration.payment
            ? props.configuration
            : PaymentEscalationForm0.defaultValues(props.configuration);

        const schema = getPaymentSchema();

        const disabled = !props.authorizedToEdit || isModelAsSold(props.model);

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(config, actions) => this.handleSubmit(config, actions, schema)}
                enableReinitialize
            >
                {(formikProps: FormikProps<Configuration>) =>
                    <Form testName='paymentEscalation' noValidate>
                        <Grid container spacing={24}>
                            <BillingCurrency
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <BillingCurrencyFx
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <BillingInterval
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <BillingStartDate
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <PaymentTerm
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <MinOperatingHoursYear
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <MinOperatingHoursYearViolationAgreement
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <ExtraWorkDiscountMaterial
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <ExtraWorkDiscountLabor
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={6} sm={3}/>}
                            />
                            <OtherSpecialPayments
                                disabled={disabled}
                                formikProps={formikProps}
                                container={<Grid item xs={12}/>}
                            />

                            {!disabled
                                ? <Grid item xs={12}>
                                    <Button
                                        testName='addEscalation'
                                        color='primary'
                                        onClick={() => this.openAddEscalationDialog()}
                                    >
                                        Add Escalation<Add/>
                                    </Button>
                                    <EditEscalationDialog
                                        open={this.state.openAddEscalationDialog}
                                        onSubmit={escalation => {
                                            const escalations = formikProps.values.payment.escalations;
                                            const updatedEscalations = append(escalations, escalation);
                                            formikProps.setFieldValue('payment.escalations', updatedEscalations);

                                            this.closeAddEscalationDialog();
                                        }}
                                        onCancel={() => this.closeAddEscalationDialog()}
                                    />
                                </Grid>
                                : null
                            }

                            {formikProps.values.payment.escalations.map((escalation, idx) =>
                                <Grid key={idx} item md={4}>
                                    <EscalationCard
                                        testId={'' + idx}
                                        disabled={disabled}
                                        escalation={escalation}
                                        onChange={updatedEscalation => {
                                            const escalations = formikProps.values.payment.escalations;
                                            const updatedEscalations = replace(escalations, idx, updatedEscalation);
                                            formikProps.setFieldValue('payment.escalations', updatedEscalations);
                                        }}
                                        onDelete={() => {
                                            const escalations = formikProps.values.payment.escalations;
                                            const updatedEscalations = remove(escalations, idx);
                                            formikProps.setFieldValue('payment.escalations', updatedEscalations);
                                        }}
                                    />
                                </Grid>,
                            )}
                            <EscalationErrors formikProps={formikProps} container={<Grid item xs={12}/>}/>
                        </Grid>
                        <ConfigurationFormButtons
                            loading={formikProps.isSubmitting}
                            onBack={props.onBack}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onNext={() => this.submitForm(formikProps, 'NEXT')}
                            onReadOnlyNext={props.onNext}
                            onSecondaryComplete={this.isConfigurationComplete()
                                ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                : null
                            }
                        />
                    </Form>
                }
            </Formik>
        )
    }

    private handleSubmit(configuration: Configuration,
                         actions: FormikActions<Configuration>,
                         schema: ObjectSchema<Partial<Configuration>>): void {

        this.props.onSubmit(schema.cast(configuration) as Configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }

    private openAddEscalationDialog(): void {
        this.setState({ openAddEscalationDialog: true });
    }

    private closeAddEscalationDialog(): void {
        this.setState({ openAddEscalationDialog: false });
    }
}

interface ComponentState {
    openAddEscalationDialog: boolean,
}

const styles: StyleRules = {
    addEscalationBtn: {
        margin: '30px 0',
    },
};

export const PaymentEscalationForm = withStyles(styles)(PaymentEscalationForm0);
