export function append<T>(array: T[], item: T): T[] {
    const arrayCopy = array.slice();
    arrayCopy.push(item);
    return arrayCopy;
}

export function replace<T>(array: T[], index: number, item: T): T[] {
    const arrayCopy = array.slice();
    arrayCopy.splice(index, 1, item);
    return arrayCopy;
}

export function remove<T>(array: T[], index: number): T[] {
    const arrayCopy = array.slice();
    arrayCopy.splice(index, 1);
    return arrayCopy;
}
