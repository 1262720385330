import {
    Activity,
    ActivitySchedulingType,
    ActivityType,
    ActivityUnit,
    Configuration,
} from '../../api/model-service/model-service';
import { array, boolean, mixed, number, object, ObjectSchema, string } from '../../utils/validators';
import { integerMessage, minMessage, requiredMessage } from '../../utils/validationMessages';

export function getStandardScopeSchema(): ObjectSchema<Partial<Configuration>> {
    const standardScopeSchema: ObjectSchema<Activity> = object({
        id: number(),
        key: string().required(requiredMessage),
        scope: string().required(requiredMessage),
        service: string().required(requiredMessage),
        additionalAttribute: string(),
        type: mixed<ActivityType>()
            .required(requiredMessage)
            .oneOf([ 'INNIO_PARTS', 'INNIO_LABOR', 'OTHER_PROVIDER', 'FREIGHT', 'CUSTOMS' ]),
        schedulingType: mixed<ActivitySchedulingType>()
            .required(requiredMessage)
            .oneOf([ 'OPH' ]),
        frequency: number().required(requiredMessage).integer(integerMessage),
        frequencyOffset: number(),
        frequencyModified: boolean(),
        occurrence: number().integer(integerMessage).min(0, minMessage),
        fixedOccurrence: boolean(),
        value: number().required(requiredMessage),
        unit: mixed<ActivityUnit>().oneOf([ 'EURO', 'HOURS', 'LITERS' ]),
        icValue: number(),
    });

    return object({
        standardScope: array(standardScopeSchema)
            .min(1, 'At least one standard scope must be defined'),
    });
}
