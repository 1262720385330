import React, { FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { MultiSelectField } from '../MultiSelectField/MultiSelectField';
import { FieldProps } from './FieldProps';

export const TerminationCategory: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.terminationCategory';
        const errorMessage = inputError(name, formikProps);
        const inputValueString = inputValue(name, formikProps);
        const value = inputValueString.split('|');
        return (
            <MultiSelectField
                name={name}
                label='Termination Category'
                placeholder='Select Termination Category'
                value={value}
                disabled={disabled}
                options={options}
                onChange={updatedValue => {
                    const updatedValueStr = updatedValue.join('|');
                    formikProps.setFieldValue(name, updatedValueStr);
                }}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                required
            />
        );
    };

const options: IFormOption[] = [
    formOption(
        'Standard (default, breach, insolvency, force majeure)',
        'Standard (default, breach, insolvency, force majeure)',
    ),
    formOption('Convenience', 'Convenience'),
    formOption(
        'Missing guarantees / performances',
        'Missing guarantees / performances',
    ),
    formOption('Non-competitive price', 'Non-competitive price'),
    formOption('Others (economic shut down….)', 'Others (economic shut down….)'),
];
