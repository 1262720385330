import React from 'react';
import MaterialSwitch, { SwitchProps as MaterialSwitchProps } from '@material-ui/core/Switch';
import { createInputTestProps } from '../Utils/testProps';

interface ISwitchProps extends MaterialSwitchProps {
    testName: string,
}

const Switch: React.FunctionComponent<ISwitchProps> =
    ({ testName, inputProps, ...remainingSwitchProps }) => {
        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(testName),
        }

        return (
            <MaterialSwitch
                inputProps={fullInputProps}
                {...remainingSwitchProps}
            />
        );
    };

export default Switch;
