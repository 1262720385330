import { CatalogOverview, CatalogState } from './catalog-overview';
import { createURLSearchParams } from '../utils/url-search-params';
import { expectOK } from '../utils/expect-ok';
import { expectJSON } from '../utils/expect-json';
import { handleRequestError } from '../utils/request-error';
import { fetch } from '../fetch';

declare const CATALOG_ROOT: string;

export function getRegionCatalogs(request: GetRegionCatalogsRequest): Promise<RegionCatalog[]> {
    const url = new URL(`${CATALOG_ROOT}/region-catalogs`);
    const query = createURLSearchParams(request);
    url.search = query.toString();

    return fetch(url.toString())
        .then(expectOK)
        .then(expectJSON)
        .then(response => response.json())
        .catch(handleRequestError);
}

export interface GetRegionCatalogsRequest {
    state: CatalogState[],
}

export interface RegionCatalog extends CatalogOverview {
    type: 'REGION',
    regions: CatalogRegion[],
}

export interface CatalogRegion {
    id: number,
    name: string,
}
