import * as React from 'react';
import './AttachmentsDownload.scss';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CloudDownload } from '@material-ui/icons';
import {IDBFile} from "../../data/model/model";
import { DownloadLink } from '../links/DownloadLink';

const styles = () => ({
    item: {
        marginRight: 20,
    },
    subtitle2: {
        color: '#7d7d7d',
        fontSize: 11,
        lineHeight: 'normal',
        marginBottom: 3,
    },
    body1: {
        fontSize: 16,
    },
    price: {
        fontSize: '1.5em',
        color: '#ff9100',
    },
    link: {
        position: 'absolute',
        left: '0px',
        top: '0px',
    },
    filecontainer : {
        marginRight: 20,
        maxHeight: '200px',
        overflowY: 'scroll',
    },
    file : {
        position: 'relative',
        paddingLeft: '30px',
    },
    fileTitle : {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

interface IAttachmentDownloadProps {
    classes : any;
    files : IDBFile[];
    labelText : string;
}

const AttachmentsDownload = (props: IAttachmentDownloadProps) => {
    const { classes } = props;

    return (
        <Grid item xs={4} sm={3} md={2} classes={{ item: classes.filecontainer }}>
            <Typography
                variant="subtitle2"
                classes={{ subtitle2: classes.subtitle2 }}
            >
                {props.labelText}
            </Typography>
            {
                props.files && ( props.files.map(file => (<div key={file.id} className={classes.file} title={file.fileName}>
                        <Typography
                            variant="body1"
                            className={
                                classes.fileTitle
                            }
                        >
                            {file.fileName}
                            <span className={classes.link}>
                                <DownloadLink href={getDownloadUrl(file)}>
                                    {
                                        onClick =>
                                            <a
                                                data-link={getDownloadUrl(file)}
                                                href=""
                                                onClick={onClick}
                                                target="_blank"
                                            >
                                                <CloudDownload />
                                            </a>
                                    }
                                </DownloadLink>
                                </span>
                        </Typography>
                    </div>
                )))
            }
        </Grid>
    );
};

export default withStyles(styles as any)(AttachmentsDownload);

function getDownloadUrl(file) {
    return `${MODEL_ROOT}/attachments/${file.id}`;
}
