import { AppThunk } from '../app-thunk';
import {
    CatalogType,
    getCatalogOverviews,
    GetCatalogOverviewsRequest,
} from '../../api/catalog-service/catalog-overview';
import { errorResponse, startLoading, successResponse } from './slice';

export const getCatalogOverviewInitialPage = (type: CatalogType): AppThunk => dispatch => {
    const pageRequest: GetCatalogOverviewsRequest = {
        page: 0,
        length: 10,
        type,
        sortBy: 'createdOn',
        sort: 'DESC',
    };

    dispatch(startLoading(type, pageRequest));
    return getCatalogOverviews(pageRequest)
        .then(catalogPage => {
            dispatch(successResponse(type, catalogPage));
        })
        .catch(err => {
            dispatch(errorResponse(type, err));
        });
};

export const getCatalogOverviewPage = (type: CatalogType, page: number): AppThunk => (dispatch, getState) => {
    const previousRequest = getState().REFACTORED.catalogOverviews.catalogPagesByType[type].request;
    const pageRequest: GetCatalogOverviewsRequest = { ...previousRequest, page };

    dispatch(startLoading(type, pageRequest));
    return getCatalogOverviews(pageRequest)
        .then(catalogPage => {
            dispatch(successResponse(type, catalogPage));
        })
        .catch(err => {
            dispatch(errorResponse(type, err));
        });
};

export const filterCatalogsByKeyword = (keyword: string): AppThunk => dispatch => {
    const activityRequest = dispatch(filterCatalogOverviewPageByKeyword('ACTIVITY', keyword));
    const regionRequest = dispatch(filterCatalogOverviewPageByKeyword('REGION', keyword));
    const equipmentRequest = dispatch(filterCatalogOverviewPageByKeyword('EQUIPMENT', keyword));
    return Promise.all([ activityRequest, regionRequest, equipmentRequest ])
        .then(() => null);
};

const filterCatalogOverviewPageByKeyword = (type: CatalogType, keyword: string): AppThunk => (dispatch, getState) => {
    const previousRequest = getState().REFACTORED.catalogOverviews.catalogPagesByType[type].request;
    const pageRequest: GetCatalogOverviewsRequest = {
        ...previousRequest,
        page: 0,
        keyword,
    };

    dispatch(startLoading(type, pageRequest));
    return getCatalogOverviews(pageRequest)
        .then(catalogPage => {
            dispatch(successResponse(type, catalogPage));
        })
        .catch(err => {
            dispatch(errorResponse(type, err));
        });
};
