import * as React from 'react';
import './Model.scss';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '../Buttons/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '../Buttons/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

import ViewTitle from '../ViewTitle/ViewTitle';
import SectionTitle from '../SectionTitle/SectionTitle';
import ModelDialog from '../Model/ModelDialog/ModelDialog';
import ConfigCard from '../Configuration/ConfigCard/ConfigCard';
import DataHighlight from '../DataHighlight/DataHighlight';
import FinPerformance from '../Model/FinPerformance/FinPerformance';
import ModelIcon from '@material-ui/icons/FolderOutlined';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import {
    contractTypeInputs,
    IFormModel,
    ModelPageRequest,
    modelVersion,
    modelVersionInputs,
} from '../../data/model/model';
import {
    getModelDetails,
    getModelList,
    setPrimaryConfigurations,
    transitionModelState,
} from '../../data/actions/models';
import { connect } from 'react-redux';
import { fromServiceDateTime, isoToLocaleDate } from '../../data/utils/date';
import {
    cloneConfig,
    deleteConfiguration,
    mergeStep,
} from '../../data/actions/configurations';
import { cleanConfiguration } from '../../data/reducers/configurations';
import { IConfigurationView } from '../../data/model/configuration';
import { formatCurrency, formatNumber } from '../../data/utils/number';

import _ from 'lodash';
import { Fade, LinearProgress, Typography } from '@material-ui/core';
import AttachmentsDownload from "../AttachmentsDownload/AttachmentsDownload";
import { AuthorizedToUpdateModel } from '../authorization/AuthorizedToUpdateModel';
import { CaseAuthorizedToUpdateModel } from '../authorization/CaseAuthorizedToUpdateModel';
import { createDataTestProps } from '../Utils/testProps';
import CreateAmendmentDialog from "./CreateAmendmentDialog/CreateAmendmentDialog";
import {GavelRounded, Warning} from "@material-ui/icons";
import {IUserMailData} from "../../data/model/user";

const styles = (theme: any) => ({
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        padding: 25,
        paddingBottom: 50,
        marginBottom: '33px',
        position: 'relative',
    },
    ModelName: {
        fontSize: '1.75rem',
    },
    modelVersionFlag: {
        fontSize: '50%',
        verticalAlign: 'middle',
        marginLeft: '10px',
        whiteSpace: 'nowrap',
    },
    dataHighlightRoot: {
        flexGrow: 1,
        marginTop: 20,
    },
    newConfigButton: {
        marginLeft: '20px',
    },
    metaExpandArea: {
        backgroundColor: 'white',
        marginTop: 20,
    },
    switchIcon: {
        color: 'black',
        display: 'none',
    },
    moreLabel: {
        color: '#0c6d10',
        textTransform: 'uppercase',
    },
    takeAways: {
        padding: theme.spacing.unit * 2,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -25,
        marginRight: -25,
    },
    stepper: {
        marginBottom: 15,
        marginTop: 15,
        borderRadius: '7px',
        padding: '10px 3px',
    },
    stepRoot: {
        padding: 0,
    },
    stepLabel: {
        color: 'grey',
        fontSize: 12,
        padding: '5px 10px',
    },
    stepLabelContainer: {
        backgroundColor: 'transparent',
        border: '1px solid #bdbdbd',
        borderRadius: 25,
    },
    stepIconContainer: {
        display: 'none',
    },
    stepActive: {
        color: 'white !important',
        fontWeight: 700,
        borderRadius: 25,
        padding: '5px 10px',
        backgroundColor: 'green',
        border: 'transparent !important',
    },
    stepCompleted: {
        color: 'white',
        backgroundColor: 'aqua',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.95)',
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    smallIcon: {
        position: 'relative',
        fontSize: 25,
        top: 2,
        marginRight: 10,
    },
    statusChip: {
        margin: theme.spacing.unit,
        marginLeft: 'auto',
    },
    actualizationWarning: {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: theme.spacing.unit,
    },
    verticalMiddle: {
        verticalAlign: 'middle',
    },
});

function getSteps() {
    return ['In-Progress', 'As-Sold', 'Handover', 'OTR', 'Ended'];
}

class Model extends React.Component<any, any> {
    public state = {
        completed: {},
        checked: false,
        open: true,
        loaded: false,
    };

    public handleCollapse(): void {
        this.setState(state => ({ checked: !state.checked }));
    }

    public componentDidMount(): void {
        this.props
            .getOne(this.props.match.params.modelID)
            .then(() => this.setState({ loaded: true }));
    }

    public render() {
        const {
            classes,
            model,
            getOne,
            match,
            clearActiveConfiguration,
            setPrimaryConfig,
            deleteConf,
            cloneConfiguration,
            catalogs,
            searchModelsForClone,
            contractManagers
        } = this.props;

        const { checked } = this.state;

        const newConfigLink = (props: any) => (
            <Link
                onClick={clearActiveConfiguration}
                to={`/models/${match.params.modelID}/configuration/new`}
                {...props}
            />
        );

        const newConfig = this.isModelVersion(modelVersion.IN_PROGRESS) ?
            <AuthorizedToUpdateModel model={model}>
                <Button
                    testName="newConfiguration"
                    color="primary"
                    classes={{ root: classes.newConfigButton }}
                    component={newConfigLink}>
                    New Configuration
                    <AddIcon />
                </Button>
            </AuthorizedToUpdateModel>
            : null;

        const offeringType = contractTypeInputs.find(
            e => e.value === model.offeringType
        ) || { label: 'none' };

        if (!this.state.loaded) {
            return (
                <React.Fragment>
                    <ViewTitle>
                        <Link to="/Models">Models</Link>
                    </ViewTitle>
                    <Paper square classes={{ root: classes.paper }}>
                        <Fade
                            in={true}
                            style={{
                                transitionDelay: '800ms',
                            }}
                            unmountOnExit
                        >
                            <LinearProgress />
                        </Fade>
                    </Paper>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <ViewTitle>
                    <Link to="/Models">Models</Link>
                </ViewTitle>
                <Paper square classes={{ root: classes.paper }}>
                    <h1 className="modelName">
                        <ModelIcon
                            color="disabled"
                            className={this.props.classes.smallIcon}
                        />
                        {model.projectName}
                        {this.getEndedFlag()}
                        <CaseAuthorizedToUpdateModel model={model}>
                            {
                                authorizedToEdit => {
                                    const readonly = !authorizedToEdit;
                                    const icon = authorizedToEdit ? <EditIcon/> : null;
                                    const title = authorizedToEdit ? 'Edit' : 'View Details';
                                    return (
                                        <ModelDialog
                                            readonly={readonly}
                                            edit={true}
                                            variant="contained"
                                            icon={icon}
                                            title={title}
                                        />
                                    );
                                }
                            }
                        </CaseAuthorizedToUpdateModel>
                        {model.authorizedToCreateAmendment
                            ? <CreateAmendmentDialog
                                edit={true}
                                variant="contained"
                                icon={<GavelRounded />}
                                title={"Create Amendment"}
                            />
                            : null
                        }
                        {this.getActualizationWarning()}
                    </h1>
                    {this.hasFinancialPerformance(model) ? (
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={16}
                            >
                                <DataHighlight
                                    testName="totalModelCost"
                                    labelText="Total Model Cost"
                                    price
                                    value={formatCurrency(
                                        'EUR',
                                        model.financialPerformance
                                            .totalModelCost
                                    )}
                                />
                                <DataHighlight
                                    testName="totalModelBillingsConsidLDB"
                                    labelText="Total Model Billings cons. LD/B"
                                    price
                                    value={formatCurrency(
                                        'EUR',
                                        model.financialPerformance
                                            .totalModelBillingsConsidLDB
                                    )}
                                />
                                <DataHighlight
                                    testName="totalModelCMConsidLDB"
                                    labelText="Total Model CM cons. LD/B"
                                    price
                                    value={formatCurrency(
                                        'EUR',
                                        model.financialPerformance
                                            .totalModelCMConsidLDB
                                    )}
                                />
                                <DataHighlight
                                    testName="totalModelCMPercentage"
                                    labelText="Total Model CM% cons. LD/B"
                                    price
                                    value={
                                        formatNumber(
                                            model.financialPerformance
                                                .totalModelCMPercentage * 100,
                                            0,
                                            2
                                        ) + '%'
                                    }
                                />
                            </Grid>
                        ) : ''}
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.dataHighlightRoot}
                        spacing={16}
                    >
                        <DataHighlight
                            testName="opportunityNumber"
                            labelText="Opportunity #"
                            value={model.salesforceOpportunity}
                        />

                        {model.amendmentOpportunityNumber &&
                            <DataHighlight
                                testName="amendmentOpportunityNumber"
                                labelText="Amendment Oppty #"
                                value={model.amendmentOpportunityNumber}
                            />
                        }

                        {model.paperContractId && (
                            <DataHighlight
                                testName="paperContractId"
                                labelText="Paper Contract ID"
                                value={model.paperContractId}
                            />
                        )}
                        {model.attachments && model.attachments.length > 0 && (
                            <AttachmentsDownload
                                labelText="Attachments"
                                files={model.attachments}
                            />
                        )}
                        <DataHighlight
                            testName="oracleContractNumber"
                            labelText="Oracle Contracts #"
                            value={model.oracleContractNumber}
                        />
                        <DataHighlight
                            testName="customerName"
                            labelText="Customer"
                            value={model.customerName}
                        />
                        <DataHighlight
                            testName="customerNumber"
                            labelText="Customer DUNS #"
                            value={model.customerNumber}
                        />

                        {model.attachment && (
                            <DataHighlight
                                testName="scannedPaperContract"
                                labelText="Scanned Paper Contract"
                                value={model.customerNumber}
                            />
                        )}
                    </Grid>
                    <Collapse
                        in={checked}
                        collapsedHeight="1px"
                        classes={{ container: classes.metaExpandArea }}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={16}
                        >
                            <DataHighlight
                                testName="description"
                                labelText="Description"
                                value={model.description}
                                fullwidth
                            />
                            {model.contractManager && (
                                <DataHighlight
                                    testName="contractManager"
                                    labelText="Contract Manager"
                                    value={findContractManagerName(model.contractManager, contractManagers)}
                                />
                            )}
                            <DataHighlight
                                testName="modifiedOn"
                                labelText="Updated on"
                                value={fromServiceDateTime(
                                    model.modifiedOn
                                ).toLocaleDateString()}
                            />
                            <DataHighlight
                                testName="modifiedBy"
                                labelText="Updated by"
                                value={model.modifiedBy}
                            />
                            <DataHighlight
                                testName="createdOn"
                                labelText="Created on"
                                value={fromServiceDateTime(
                                    model.createdOn
                                ).toLocaleDateString()}
                            />
                            <DataHighlight
                                testName="createdBy"
                                labelText="Created by"
                                value={model.createdBy}
                            />
                            <DataHighlight
                                testName="offeringType"
                                labelText="Offering Type"
                                value={offeringType.label}
                            />
                            <DataHighlight
                                testName="nuWarrantDurationMonths"
                                labelText="NU Warranty Duration"
                                value={model.nuWarrantDurationMonths}
                            />
                            <DataHighlight
                                testName="onOffshoreConstellation"
                                labelText="On-/Offshore Constellation"
                                value={
                                    model.onOffshoreConstellation ? 'Yes' : 'No'
                                }
                            />
                            <DataHighlight
                                testName="correctiveCapPerEvent"
                                labelText="Corrective Cap Per Event"
                                value={
                                    model.correctiveCapPerEvent ? 'Yes' : 'No'
                                }
                            />
                            <DataHighlight
                                testName="correctiveCapPerYear"
                                labelText="Corrective Cap Per Year"
                                value={
                                    model.correctiveCorrectiveCapPerYear
                                        ? 'Yes'
                                        : 'No'
                                }
                            />
                            <DataHighlight
                                testName="correctiveCapOverTotalContractTerm"
                                labelText="Corrective Cap Over Total Contract-Term"
                                value={
                                    model.correctiveCapOverTotalContractTerm
                                        ? 'Yes'
                                        : 'No'
                                }
                            />
                            {this.getActualEndDateDataField()}
                        </Grid>
                    </Collapse>
                    <Button
                        testName={checked ? 'less' : 'more'}
                        color="primary"
                        onClick={() => this.handleCollapse()}
                    >
                        {checked ? 'less' : 'more'}
                    </Button>

                    {model.amendmentOpportunityNumber && model.amendmentInformation.hasAmendment() &&
                        <Link style={{marginLeft: 50}} to={`/models/${model.id}/amendment-details`}>VIEW AMENDMENT {(model.modelVersion==='OTR')?'HISTORY':'DETAILS'}</Link>
                    }

                    <CaseAuthorizedToUpdateModel model={model}>
                        {
                            authorizedToEdit => this.getStatusStepper(authorizedToEdit)
                        }
                    </CaseAuthorizedToUpdateModel>


                    <SectionTitle title="Configurations" testName='configurationName'>
                        {newConfig}
                    </SectionTitle>

                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={16}
                    >
                        {model.configurationOverviews ? (
                            model.configurationOverviews.map(
                                (listItem: any) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            key={listItem.id}
                                        >
                                            {model.state !==
                                            modelVersion.AS_SOLD ? (
                                                <ConfigCard
                                                    getOne={id => {
                                                        getOne(id).then(() => {
                                                            const {
                                                                history,
                                                            } = this.props;
                                                            if (
                                                                match.modelID !==
                                                                id
                                                            ) {
                                                                history.push(
                                                                    '/models/' +
                                                                        id
                                                                );
                                                            }
                                                        });
                                                    }}
                                                    searchModelsForClone={
                                                        searchModelsForClone
                                                    }
                                                    config={listItem}
                                                    model={model}
                                                    setPrimary={setPrimaryConfig(
                                                        model
                                                    )}
                                                    cloneThis={selectedModelID => {
                                                        const destModelID =
                                                            selectedModelID ||
                                                            model.id;

                                                        return cloneConfiguration(
                                                            listItem.id,
                                                            destModelID
                                                        );
                                                    }}
                                                    deleteThis={() =>
                                                        deleteConf(
                                                            listItem,
                                                            model.id
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <ConfigCard
                                                    key={listItem.id}
                                                    config={listItem}
                                                    model={model}
                                                />
                                            )}
                                        </Grid>
                                    );
                                }
                            )
                        ) : (
                            <div>You have no configurations.</div>
                        )}
                    </Grid>
                    <Divider classes={{ root: classes.divider }} />
                    {/* Financial Chart and Table */}
                    {this.hasFinancialPerformance(model) ? (
                            <React.Fragment>
                                <SectionTitle title="Financial Performance" />
                                <FinPerformance
                                    data={
                                        model.financialPerformance
                                            .finanfialBreakdown || []
                                    }
                                    totalModelCost={model.financialPerformance.totalModelCost}
                                />
                            </React.Fragment>
                        ) : ''}
                </Paper>
            </React.Fragment>
        );
    }

    private hasFinancialPerformance(model: IFormModel): boolean {
        const financialPerformance = model.financialPerformance;
        if (!financialPerformance) {
            return false;
        }

        return financialPerformance.totalModelCost !== 0 ||
            financialPerformance.totalModelBillingsConsidLDB !== 0;
    }

    private getStatusStepper(authorizedToEdit: boolean): React.ReactNode {
        const { classes, getOne } = this.props;

        const steps = getSteps();
        const hasPrimaryConfiguration = this.hasPrimaryConfiguration();
        const activeStep = this.getActiveStep();
        const transitionIndex = this.getTransitionIndex(activeStep);

        return (
            <Stepper
                nonLinear
                color="primary"
                activeStep={activeStep}
                classes={{ root: classes.stepper }}
            >
                {steps.map((label, index) => (
                    <Step
                        key={label}
                        classes={{
                            root: classes.stepRoot,
                        }}
                    >
                        <StepButton
                            testName="updateModelVersion"
                            testId={index.toString()}
                            onClick={() => {
                                if (index !== transitionIndex) {
                                    return;
                                }
                            }}
                            completed={this.state.completed[index]}
                            disabled={this.isStepDisabled(
                                authorizedToEdit,
                                label,
                                index,
                                transitionIndex
                            )}
                        >
                            <StepLabel
                                icon={null}
                                classes={{
                                    label: classes.stepLabel,
                                    labelContainer:
                                    classes.stepLabelContainer,
                                    iconContainer:
                                    classes.stepIconContainer,
                                    active: classes.stepActive,
                                    completed: classes.stepCompleted,
                                }}
                                {...this.createTestProps(activeStep, index)}
                                className={'handoverStepLabel'}
                            >
                                {index === transitionIndex &&
                                hasPrimaryConfiguration &&
                                !this.isStepDisabled(
                                    authorizedToEdit,
                                    label,
                                    index,
                                    transitionIndex
                                ) ? (
                                    <ModelDialog
                                        getOne={getOne}
                                        edit={true}
                                        variant="contained"
                                        title={label}
                                        inStepper
                                    />
                                ) : (
                                    label
                                )}
                            </StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        );
    }

    private createTestProps(activeStep: number, index: number): object {
        return activeStep === index ? createDataTestProps('current') : {};
    }

    private hasPrimaryConfiguration() {
        const { model } = this.props;
        const isPrimaryConfigOverview = configOverview => configOverview.primaryConfiguration;
        return model.configurationOverviews.some(isPrimaryConfigOverview);
    }

    private getActiveStep() {
        const modelVersion = this.props.model.modelVersion;

        const transitionOptionMatchesModelVersion = option => {
            return option.value === modelVersion;
        };

        const activeStep = modelVersionInputs.findIndex(transitionOptionMatchesModelVersion);

        // The activeStep should always be valid, but I wanted this refactored function
        // to match the behavior of the old implementation regarding the default.
        const isValidActiveStep = activeStep >= 0;
        const defaultActiveStep = 0;

        return isValidActiveStep ? activeStep : defaultActiveStep;
    }

    private getTransitionIndex(activeStep: number) {
        return activeStep + 1;
    }

    private isStepDisabled(
        authorizedToEdit: boolean,
        stepLabel: string,
        index: number,
        transitionIndex: number
    ): boolean {
        if (!authorizedToEdit) {
            return true;
        }

        const { model } = this.props;

        if (model && model.configurationOverviews) {
            if (stepLabel === 'As-Sold') {
                let rtn = true;
                model.configurationOverviews.forEach(config => {
                    if (
                        config.state === 'COMPLETED' &&
                        config.primaryConfiguration
                    ) {
                        rtn = false;
                        return;
                    }
                });

                return rtn;
            }
        }

        return index > transitionIndex;
    }

    private getEndedFlag(): React.ReactNode {
        if (!this.isEndedModel()) {
            return null;
        }

        const flagClass = this.props.classes.modelVersionFlag;
        const testProps = createDataTestProps('modelVersionFlag', modelVersion.ENDED);
        return <span className={flagClass} {...testProps}>(OTR-Ended)</span>;
    }

    private getActualEndDateDataField(): React.ReactNode {
        const { model } = this.props;

        return this.isEndedModel()
            ? <DataHighlight
                testName="actualEndDate"
                labelText="Actual End Date"
                value={isoToLocaleDate(model.actualEndDate)}
            />
            : null;
    }

    private isEndedModel(): boolean {
        return this.isModelVersion(modelVersion.ENDED);
    }

    private isModelVersion(version: modelVersion): boolean {
        return this.props.model.modelVersion === version;
    }

    private getActualizationWarning(): React.ReactNode {
        const model = this.props.model;
        if (!model.readOnly || !model.pendingActualization) {
            return null;
        }

        return (
            <Typography
                variant='subtitle1'
                component='span'
                color='secondary'
                className={this.props.classes.actualizationWarning}
            >
                <Warning className={this.props.classes.verticalMiddle}/>
                &nbsp;
                <span className={this.props.classes.verticalMiddle}>
                        Model cannot be modified during an actualization
                    </span>
            </Typography>
        );
    }
}

export const findRegionName = (id: number, catalogs: any) => {
    if (catalogs && id) {
        let rtn = null;
        _.forEach(catalogs.regions, cat => {
            _.forEach(cat.regions, reg => {
                if (reg.id === id) {
                    rtn = reg.name + ' - ' + cat.name;
                }
            });
        });

        if (rtn) {
            return rtn;
        }
    }

    return 'Region Not Found';
};

const findContractManagerName = (username: string, contractManagers: IUserMailData[]) => {
    let contractManager;

    if (username && contractManagers) {
        contractManager = contractManagers.find(cm => cm.username === username);
    }

    return contractManager ? contractManager.displayName : 'Contract Manager not found';
};

const mapStateToProps = state => {
    return {
        model: state.models.activeModel,
        regions: state.models.regions,
        catalogs: state.catalogs,
        contractManagers: state.models.contractManagers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOne: id => {
            return dispatch(getModelDetails(id));
        },
        searchModelsForClone: keyword => {
            const pageRequest = new ModelPageRequest();
            pageRequest.setKeyword(keyword);
            pageRequest.setVersion(modelVersion.IN_PROGRESS);
            return dispatch(getModelList(pageRequest));
        },
        clearActiveConfiguration: () => {
            dispatch(mergeStep({}, cleanConfiguration()));
        },
        setPrimaryConfig: (model: IFormModel) => (
            configuration: IConfigurationView
        ) => {
            dispatch(setPrimaryConfigurations(model, configuration));
        },
        transitionModel: (model: IFormModel) => {
            dispatch(transitionModelState(model));
        },
        cloneConfiguration: (configID, modelID) => {
            return dispatch(cloneConfig(configID, modelID));
        },
        deleteConf: (configuration, modelID) => {
            return dispatch(deleteConfiguration(configuration, modelID));
        },
    };
};

export default withStyles(styles as any)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Model)
);
