import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '../../Form/Switch';
import { sortBy } from 'lodash';
import { Formik, FormikActions, FormikProps } from '../../Formik';
import SectionTitle from '../../SectionTitle/SectionTitle';
import { IConfiguration, IScope, IUnitScope } from '../../../data/model/configuration';
import { ServiceTable } from '../../ServiceTable';
import { Button } from '../../Buttons/Button';
import { connect } from 'react-redux';
import { saveConfiguration } from '../../../data/actions/configurations';
import { CircularProgress } from "@material-ui/core";
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { compose } from 'lodash/fp';
import { FormEditAuthorizationProvider } from '../../Form/formAuthorizationContext';
import { CaseAuthorizedToUpdateBilOfServices } from '../../authorization/CaseAuthorizedToUpdateBillOfServices';

class BillOfServices extends React.Component<any, any> {
    public state = {
        includeCost: true,
    };

    public handleCostChange(name: string, event: any): void {
        this.setState({ [name]: event.target.checked });
    }

    public render(): React.ReactElement {
        const { configuration, classes } = this.props;

        const sortedStandardActivities = this.sortActivities(configuration.standardScope);
        const sortedAdditionalActivities = this.sortActivities(configuration.additionalScope);
        const sortedSiteActivities = this.sortActivities(configuration.siteLevelScope);

        const initialValues = {
            standardActivities: this.addUnitData(sortedStandardActivities),
            additionalActivities: this.addUnitData(sortedAdditionalActivities),
            siteActivities: sortedSiteActivities,
        };

        const handleSubmit = (activitiesBag, actions) => {
            this.handleSubmit(activitiesBag, actions);
        };

        return (
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<ActivitiesBag>) =>
                    <React.Fragment>
                        <SectionTitle title="Scope and Services">
                            <FormControlLabel
                                label="Include Cost"
                                labelPlacement="start"
                                control={
                                    <Switch
                                        testName="includeCost"
                                        checked={this.state.includeCost}
                                        onChange={e =>
                                            this.handleCostChange('includeCost', e)
                                        }
                                        value="includeCost"
                                        color="primary"
                                    />
                                }
                            />
                        </SectionTitle>
                        <CaseAuthorizedToUpdateBilOfServices model={this.props.model}>
                            {authorized =>
                                <FormEditAuthorizationProvider authorizedToEdit={authorized}>
                                    <div className={classes.overflowContainer}>
                                        <ServiceTable
                                            tableTestName="scopeAndServices"
                                            rowTestName="standardActivity"
                                            activities={formikProps.values.standardActivities}
                                            showCost={this.state.includeCost}
                                            onChange={updatedStandardActivities => {
                                                formikProps.setFieldValue('standardActivities', updatedStandardActivities);
                                            }}
                                        />
                                    </div>

                                    <br />
                                    <br />

                                    <SectionTitle title="Covered Additional Scope (Unit Level)"/>
                                    <div className={classes.overflowContainer}>
                                        <ServiceTable
                                            tableTestName="additionalScopeUnit"
                                            rowTestName="additionalActivity"
                                            activities={formikProps.values.additionalActivities}
                                            showCost={this.state.includeCost}
                                            onChange={updatedAdditionalActivities => {
                                                formikProps.setFieldValue('additionalActivities', updatedAdditionalActivities);
                                            }}
                                        />
                                    </div>

                                    <br />
                                    <br />

                                    <SectionTitle title="Covered Additional Scope (Site Level)"/>
                                    <div className={classes.overflowContainer}>
                                        <ServiceTable
                                            tableTestName="additionalScopeSite"
                                            rowTestName="additionalActivity"
                                            activities={formikProps.values.siteActivities}
                                            showCost={this.state.includeCost}
                                            onChange={updatedSiteActivities => {
                                                formikProps.setFieldValue('siteActivities', updatedSiteActivities);
                                            }}
                                        />
                                    </div>
                                </FormEditAuthorizationProvider>
                            }
                        </CaseAuthorizedToUpdateBilOfServices>
                        <br/>
                        <br/>

                        {this.getFormButtons(formikProps)}
                    </React.Fragment>
                }
            </Formik>
        );
    }

    private getFormButtons(formikProps: FormikProps<ActivitiesBag>): React.ReactNode {
        return (
            <>
                {this.getCancelButton(formikProps)}
                {this.getSubmitButton(formikProps)}
                {this.getLoadingSpinner(formikProps)}
            </>
        );
    }

    private getCancelButton(formikProps: FormikProps<ActivitiesBag>): React.ReactNode {
        if (!formikProps.dirty) {
            return null;
        }

        const classes = this.props.classes;
        const disabled = formikProps.isSubmitting;
        return (
            <Button
                className={classes.formButton}
                testName="cancel"
                variant="outlined"
                disabled={disabled}
                onClick={() => formikProps.resetForm()}
            >
                Cancel
            </Button>
        );
    }

    private getSubmitButton(formikProps: FormikProps<ActivitiesBag>): React.ReactNode {
        if (!formikProps.dirty) {
            return null;
        }

        const classes = this.props.classes;
        const disabled = formikProps.isSubmitting || !formikProps.isValid;
        return (
            <Button
                className={classes.formButton}
                testName="save"
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={formikProps.submitForm}
            >
                Save
            </Button>
        );
    };

    private getLoadingSpinner(formikProps: FormikProps<ActivitiesBag>): React.ReactNode {
        if (!formikProps.isSubmitting) {
            return null;
        }

        const classes = this.props.classes;
        return <CircularProgress className={classes.loadingSpinner} size={20}/>;
    }

    private sortActivities(activities: IScope[]): IScope[] {
        return sortBy(activities, ['scope', 'service', 'frequency']);
    }

    private handleSubmit(activitiesBag: ActivitiesBag, actions: FormikActions<ActivitiesBag>): void {
        this.updateConfiguration(activitiesBag)
            .then(() => {
                actions.setSubmitting(false);
                actions.resetForm();
            });
    }

    private updateConfiguration(activitiesBag: ActivitiesBag): Promise<void> {
        const configuration = this.props.configuration;
        const updatedConfiguration: IConfiguration = {
            ...configuration,
            standardScope: activitiesBag.standardActivities,
            additionalScope: activitiesBag.additionalActivities,
            siteLevelScope: activitiesBag.siteActivities,
        };
        return this.props.updateConfiguration(updatedConfiguration)
    }

    private addUnitData(activities: IScope[]): IScope[] {
        const unitId = this.getUnitId();

        return activities.map(activity => {
            const unitData = this.getUnitData(unitId, activity);
            return {
                ...activity,
                calculatedTotalOccurrence: unitData ? unitData.occurrence : undefined,
            }
        });
    }

    private getUnitId(): number {
        const { configuration, currentUnit } = this.props;
        const unit = configuration.units[currentUnit];
        return unit ? unit.id : null;
    }

    private getUnitData(unitId: number, activity: IScope): IUnitScope {
        const hasUnitId = unitData => unitData.unit.id === unitId;
        return activity.perUnits.find(hasUnitId);
    }
}

interface ActivitiesBag {
    standardActivities: IScope[],
    additionalActivities: IScope[],
    siteActivities: IScope[],
}

const enhance = compose(
    withStyles(getStyles),
    connect(null, mapDispatchToProps),
);

export default enhance(BillOfServices);

function mapDispatchToProps(dispatch, props) {
    function updateConfiguration(configuration: IConfiguration): Promise<void> {
        const modelId = props.model.id;
        const isDraft = configuration.state === 'DRAFT';

        // `saveConfiguration` resolves even in case of errors so
        // we can't detect any failure here.
        return dispatch(saveConfiguration(configuration, modelId, isDraft))
            .then(() => null);
    }

    return { updateConfiguration }
}

function getStyles(theme: Theme): StyleRules {
    return {
        overflowContainer: { overflow: 'auto' },
        formButton: { marginRight: theme.spacing.unit * 2 },
        loadingSpinner: { verticalAlign: 'middle' },
    }
}
