import React, { FC } from 'react';
import { ConfigurationFormButtonsProps } from './ConfigurationFormButtons';
import { StyledComponentProps } from '@material-ui/core';
import { Button } from '../Buttons/Button';
import { Link } from 'react-router-dom';

export const View: FC<ConfigurationFormButtonsProps & StyledComponentProps> =
    props =>
        <Button
            testName='viewConfiguration'
            component={buttonProps =>
                <Link to={props.viewConfigurationUrl} {...buttonProps} />
            }
            color='secondary'
            className={props.classes.button}
        >
            View Configuration
        </Button>;
