import React, { FC } from 'react';
import { CalculationMethod, VariablePriceEscalation } from '../../api/model-service/model-service';
import { Typography } from '@material-ui/core';
import { ActionCard } from '../ActionCard/ActionCard';
import { assertNever } from '../../utils/assertNever';
import { isoToLocaleDate } from '../../data/utils/date';
import { hasValue } from '../../data/utils/form';
import { ActionMenuItem } from '../ActionMenu/ActionMenu';

export interface VariablePriceEscalationProps {
    testId: string,
    escalation: VariablePriceEscalation,
    actions: ActionMenuItem[],
}

export const VariablePriceEscalationCard: FC<VariablePriceEscalationProps> =
    ({ testId, escalation, actions }) =>
        <ActionCard
            testName='escalation'
            testId={testId}
            title='Variable Price Escalation'
            actions={actions}
        >
            <Typography variant='caption'>
                Billing Base: <strong>{escalation.billingBases.join(', ')}</strong>
                <br/>
                Calculation method: <strong>{formatCalculationMethod(escalation.calculationMethod)}</strong>
                <br/>
                Fixed Ratio: <strong>{escalation.indexRatio.price}%</strong>
                <br/>
                Labor: <strong>{formatIndex('labor', escalation)}</strong>
                <br/>
                Material: <strong>{formatIndex('material', escalation)}</strong>
                <br/>
                Lube Oil: <strong>{formatIndex('lubeOil', escalation)}</strong>
                <br/>
                <br/>
                Initial Date: <strong>{isoToLocaleDate(escalation.initialPriceEscalationDate)}</strong>
                <br/>
                Lower Limit: <strong>{formatLimit(escalation.priceEscalationLowerLimit)}</strong>
                <br/>
                Upper Limit: <strong>{formatLimit(escalation.priceEscalationUpperLimit)}</strong>
                <br/>
                {escalation.legacyEscalationFormula
                    ? <>
                        Calculation Equation: <strong>{escalation.legacyEscalationFormula}</strong>
                    </>
                    : null
                }
            </Typography>
        </ActionCard>;

function formatCalculationMethod(method: CalculationMethod): string {
    switch (method) {
        case 'MONTHLY_SPECIFIC':
            return 'Monthly specific';
        case 'YEARLY_AVERAGE':
            return 'Yearly average';
        default:
            return assertNever('Unknown calculation method', method);
    }
}

function formatIndex(type: 'labor' | 'material' | 'lubeOil', escalation: VariablePriceEscalation): string {
    const indexRatio = escalation.indexRatio[type];
    const index = escalation[`${type}Index`];
    return indexRatio > 0
        ? `${indexRatio}% ${index.name} @ ${index.date}`
        : '0%';
}

function formatLimit(limit: number): string {
    return hasValue(limit)
        ? `${limit}%`
        : 'None';
}
