const locale = require('browser-locale')() || 'en-DE';

export const formatCurrency = (currency: string, value: number) => {
    if (!value) return '---';

    return formatCurrencyWithZero(currency, value);
};

export const formatCurrencyWithZero = (currency: string, value: number) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(value);
};

export const formatNumber = (
    value: number,
    minDigits: number,
    maxDigits: number
) => {
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
    }).format(value);
};

export const formatPercentage = (value: number, minDigits: number, maxDigits: number): string => {
    return new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
    }).format(value);
};
