import React, { ChangeEvent, FC } from 'react';
import { TableHead, TableRow } from '@material-ui/core';
import { Checkbox } from '../Form/Checkbox';
import {ResultsTableHeaderCell} from './ResultsTableHeaderCell';

export const ErrorTableHeader: FC<TableHeaderProps> =
    props =>
        <TableHead>
            <TableRow>
                <ResultsTableHeaderCell>
                    <Checkbox
                        testName='selectAll'
                        onClick={(evt) => evt.stopPropagation()}
                        checked={props.allSelected}
                        onChange={props.onSelectAll}
                    />
                    Opportunity&nbsp;#</ResultsTableHeaderCell>
                <ResultsTableHeaderCell>Configuration Name</ResultsTableHeaderCell>

                <ResultsTableHeaderCell>Errors</ResultsTableHeaderCell>
            </TableRow>
        </TableHead>;

export interface TableHeaderProps {
    allSelected: boolean,
    onSelectAll: (evt: ChangeEvent<HTMLInputElement>) => void,
}

