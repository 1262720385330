import React, { Component, ReactNode } from 'react';
import { Escalation, FixedPriceEscalation, VariablePriceEscalation } from '../../api/model-service/model-service';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Dialog } from '../Dialogs/Dialog';
import { DialogActions, DialogContent, Grid } from '@material-ui/core';
import { Type } from './Type';
import { BillingBase } from './BillingBase';
import { CalculationMethod } from './CalculationMethod';
import { InitialPriceEscalationDate } from './InitialPriceEscalationDate';
import { IndexRatio } from './IndexRatio';
import { PriceEscalationUpperLimit } from './PriceEscalationUpperLimit';
import { PriceEscalationLowerLimit } from './PriceEscalationLowerLimit';
import { Divider } from './Divider';
import { Index } from './Index';
import { FixedPriceEscalationValue } from './FixedPriceEscalationValue';
import { LegacyEscalationFormula } from './LegacyEscalationFormula';
import { Button } from '../Buttons/Button';
import { SubmitButton } from '../Form/SubmitButton';
import { Form } from '../Form/Form';
import { getEscalationSchema } from './schema';
import { ObjectSchema } from 'yup';
import { GlobalErrors } from './GlobalErrors';

export class EditEscalationDialog extends Component<EditEscalationDialogProps> {

    private static defaultValues(): FixedPriceEscalation & VariablePriceEscalation {
        const thisYear = new Date().getFullYear();
        const januaryThisYear = thisYear + '-01';
        return {
            type: null,
            billingBases: [],
            initialPriceEscalationDate: null,
            priceEscalationLowerLimit: null,
            priceEscalationUpperLimit: null,
            fixedPriceEscalationValue: null,
            calculationMethod: null,
            indexRatio: {
                price: null,
                labor: null,
                material: null,
                lubeOil: null,
            },
            laborIndex: {
                name: null,
                date: januaryThisYear,
            },
            materialIndex: {
                name: null,
                date: januaryThisYear,
            },
            lubeOilIndex: {
                name: null,
                date: januaryThisYear,
            },
            legacyEscalationFormula: null,
        };
    }

    public render(): ReactNode {
        const initialValues = this.props.escalation || EditEscalationDialog.defaultValues();
        const schema = getEscalationSchema();

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(escalation, actions) => this.handleSubmit(escalation, actions, schema)}
                enableReinitialize
            >
                {(formikProps: FormikProps<Escalation>) =>
                    <Dialog
                        testName='addEscalation'
                        open={this.props.open}
                        onClose={() => this.handleCancel(formikProps)}
                        maxWidth='md'
                        fullWidth
                    >
                        <Form testName='escalation' noValidate onSubmit={formikProps.handleSubmit}>

                            <DialogContent>
                                <Grid container spacing={24}>
                                    <Type formikProps={formikProps} container={<Grid item xs={6} sm={12}/>}/>
                                    <BillingBase formikProps={formikProps} container={<Grid item xs={12}/>}/>
                                    <FixedPriceEscalationValue
                                        formikProps={formikProps}
                                        container={<Grid item xs={8} sm={4}/>}
                                    />
                                    <CalculationMethod formikProps={formikProps} container={<Grid item xs={8}/>}/>
                                    <InitialPriceEscalationDate
                                        formikProps={formikProps}
                                        container={<Grid item xs={4}/>}
                                    />
                                    <IndexRatio
                                        type='price'
                                        title='Price Index Fixed Ratio'
                                        formikProps={formikProps}
                                        container={<Grid item xs={12} sm={4}/>}
                                    />
                                    <PriceEscalationUpperLimit
                                        formikProps={formikProps}
                                        container={<Grid item xs={12} sm={4}/>}
                                    />
                                    <PriceEscalationLowerLimit
                                        formikProps={formikProps}
                                        container={<Grid item xs={12} sm={4}/>}
                                    />
                                </Grid>
                                <Divider formikProps={formikProps}/>
                                <Grid container spacing={24}>
                                    <Index
                                        type='labor'
                                        title='Labor'
                                        formikProps={formikProps}
                                        container={<Grid item xs={12} sm={4}/>}
                                    />
                                    <Index
                                        type='material'
                                        title='Material'
                                        formikProps={formikProps}
                                        container={<Grid item xs={12} sm={4}/>}
                                    />
                                    <Index
                                        type='lubeOil'
                                        title='Lube Oil'
                                        formikProps={formikProps}
                                        container={<Grid item xs={12} sm={4}/>}
                                    />
                                    <GlobalErrors formikProps={formikProps} container={<Grid item xs={12}/>}/>
                                    <LegacyEscalationFormula
                                        formikProps={formikProps}
                                        container={<Grid item xs={12}/>}
                                    />
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    testName='cancel'
                                    disabled={formikProps.isSubmitting}
                                    onClick={() => this.handleCancel(formikProps)}
                                >
                                    Cancel
                                </Button>
                                <SubmitButton
                                    testName={this.isEditDialog() ? 'update' : 'add'}
                                    color='primary'
                                    disabled={formikProps.isSubmitting}
                                >
                                    {this.isEditDialog() ? 'Update' : 'Add'}
                                </SubmitButton>
                            </DialogActions>

                        </Form>
                    </Dialog>
                }
            </Formik>
        );
    }

    private isEditDialog(): boolean {
        return !!this.props.escalation;
    }

    private handleSubmit(escalation: Escalation,
                         actions: FormikActions<Escalation>,
                         schema: ObjectSchema<Escalation>): void {
        this.props.onSubmit(schema.cast(escalation));
        actions.resetForm();
        actions.setSubmitting(false);
    }

    private handleCancel(formikProps: FormikProps<Escalation>): void {
        if (formikProps.isSubmitting) {
            return;
        }

        formikProps.resetForm();
        this.props.onCancel();
    }
}

interface EditEscalationDialogProps {
    open: boolean,
    escalation?: Escalation,
    onCancel: () => void,
    onSubmit: (escalation: Escalation) => void,
}
