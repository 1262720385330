import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { State } from '../../data/store';
import { ActualizationFormButtons } from '../ActualizationFormButtons/ActualizationFormButtons';
import {
    Actualization,
    ActualizationCandidate,
    ActualizationResult,
    ValidationsRequest,
} from '../../api/model-service/actualization';
import { Grid, Paper, StyledComponentProps, StyleRulesCallback, Typography, withStyles } from '@material-ui/core';
import ResultsTable, { ExtendedActualizationResult } from './ResultsTable';
import { Button } from '../Buttons/Button';
import { AppThunk } from '../../state/app-thunk';
import { putValidationCandidates } from '../../state/actualization/putValidations';
import { isEmpty, sortBy } from 'lodash';
import { ErrorResultsTable } from './ErrorResultsTable';
import { getActiveRegionCatalogs } from '../../state/regionCatalogs/getRegionCatalogs';
import { getActiveActivityCatalogs } from '../../state/activityCatalogs/getActivityCatalogs';
import { ActivityCatalog } from '../../api/catalog-service/activityCatalogs';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { refreshActualization } from '../../state/actualization/refreshActualization';

class ActualizationResults0 extends Component<ComponentProps, ComponentState> {

    private static getSelectedCandidates(results: ExtendedActualizationResult[]): ActualizationCandidate[] {
        return results
            .filter(result => result.isSelected)
            .map(result => result.candidate);
    }

    private static sortResults(results: ExtendedActualizationResult[]): ExtendedActualizationResult[] {
        return sortBy(results, [ 'candidate.opportunityNumber', 'candidate.displayName' ]);
    }

    private static getAllDeselectedState(results: ActualizationResult[]): ComponentState['selected'] {
        return results.reduce((selected, result) => {
            selected[result.id] = false;
            return selected;
        }, {});
    }

    private comparisonPoll: ReturnType<typeof window.setInterval>;

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            selected: this.getInitialSelected(),
        };
    }

    public componentDidMount(): void {
        this.props.onLoadActivityCatalogs();
        this.props.onLoadRegionCatalogs();
        if (!this.isComparisonFinished()) {
            this.startComparisonPolling();
        }
    }

    public componentDidUpdate(previousProps: ComponentProps, prevState: ComponentState): void {
        const currentActualizationResult = this.props.actualizationResult;
        const actualizationChanged = previousProps.actualizationResult !== currentActualizationResult;
        if (actualizationChanged && this.isComparisonFinished()) {
            this.stopComparisonPolling();
        }
    }

    public componentWillUnmount(): void {
        this.stopComparisonPolling();
    }

    public render(): ReactNode {
        return (
            <>
                {!!this.props.actualizationResult
                    ? <Grid container spacing={24}>
                        {this.hasResultsToValidate()
                            ? this.getResultTables()
                            : this.getNoDataPlaceholder()
                        }
                    </Grid>
                    : null
                }

                <ActualizationFormButtons
                    loading={this.props.loading}
                    onBack={this.props.onBack}
                    onClose={this.props.onClose}
                />
            </>
        );
    }

    private hasResultsToValidate(): boolean {
        return this.props.actualizationResult.results.some(result => result.state === 'PENDING');
    }

    private getResultTables(): ReactNode {
        const successes = ActualizationResults0.sortResults(this.getSuccessResults());
        const errors = ActualizationResults0.sortResults(this.getErrorResults());
        return <>
            <Grid item classes={{item: this.props.classes.resultGridItem}} lg={12} xl={8}>
                <Paper elevation={2} square>
                    <ResultsTable
                        results={successes}
                        testName='successfulResultsTable'
                        onSelected={(id, checked) => this.changeState(id, checked)}
                        onSelectAll={checked => this.selectAll(successes, checked)}
                        activityCatalogs={this.props.activityCatalogs}
                        regionCatalogs={this.props.regionCatalogs}
                    />
                </Paper>
                <br/>
                {this.getSuccessTableButtons(successes)}
            </Grid>
            <Grid item classes={{item: this.props.classes.resultGridItem}} lg={12} xl={4}>
                <Paper elevation={2} square>
                    <ErrorResultsTable
                        results={errors}
                        testName='errorResultsTable'
                        onSelected={(id, checked) => this.changeState(id, checked)}
                        onSelectAll={checked => this.selectAll(errors, checked)}
                    />
                </Paper>
                <br/>
                {this.getErrorTableButtons(errors)}
            </Grid>
        </>;
    }

    private getNoDataPlaceholder(): ReactNode {
        return (
            <Grid item xs={12}>
                <Typography variant='subtitle1' align='center'>
                    All results are validated
                </Typography>
            </Grid>
        );
    }

    private getSuccessTableButtons(successResults: ExtendedActualizationResult[]): React.ReactNode {
        if (isEmpty(successResults)) {
            return null;
        }

        const noResultsSelected = successResults.every(result => !result.isSelected);
        const disabled = this.props.loading || noResultsSelected;
        return (
            <>
                <Button
                    testName='markAsPrimary'
                    variant='contained'
                    color='primary'
                    className={this.props.classes.actionBtn}
                    disabled={disabled}
                    onClick={() => this.handleMarkAsPrimary(successResults)}
                >
                    Mark As Primary
                </Button>
                <Button
                    testName='discard'
                    testId='success'
                    variant='contained'
                    color='secondary'
                    className={this.props.classes.actionBtn}
                    disabled={disabled}
                    onClick={() => this.handleDiscard(successResults)}
                >
                    Discard
                </Button>
            </>
        );
    }

    private getErrorTableButtons(errorResults: ExtendedActualizationResult[]): React.ReactNode {
        if (isEmpty(errorResults)) {
            return null;
        }

        const noResultsSelected = errorResults.every(result => !result.isSelected);
        const disabled = this.props.loading || noResultsSelected;
        return (
            <Button
                testName='discard'
                testId='error'
                variant='contained'
                color='secondary'
                className={this.props.classes.actionBtn}
                disabled={disabled}
                onClick={() => this.handleDiscard(errorResults)}
            >
                Discard
            </Button>
        );
    }

    private handleDiscard(results: ExtendedActualizationResult[]): void {
        const candidates: ActualizationCandidate[] = ActualizationResults0
            .getSelectedCandidates(results);

        this.props.onDiscard({
            id: this.props.actualizationResult.id,
            candidates,
            action: 'DISCARD',
        });
    }

    private selectAll(results: ExtendedActualizationResult[], checked: boolean): void {
        this.setState(prevState => {
            const selected = { ...prevState.selected };
            results.forEach(result => {
                selected[result.id] = checked;
            });
            return { selected };
        });
    }

    private changeState(id: number, checked: boolean): void {
        this.setState(prevState => {
            const selected = {
                ...prevState.selected,
                [id]: checked,
            };
            return { selected };
        });
    }

    private handleMarkAsPrimary(results: ExtendedActualizationResult[]): void {
        const candidates: ActualizationCandidate[] = ActualizationResults0
            .getSelectedCandidates(results);

        this.props.onMarkAsPrimary({
            id: this.props.actualizationResult.id,
            candidates,
            action: 'MAKE_PRIMARY',
        });
    }

    private getInitialSelected(): ComponentState['selected'] {
        const actualization = this.props.actualizationResult;

        if (!actualization) {
            return {};
        }

        return ActualizationResults0.getAllDeselectedState(actualization.results);
    }

    private startComparisonPolling(): void {
        if (this.comparisonPoll) {
            return;
        }

        this.comparisonPoll = window.setInterval(() => {
            this.props.onRefreshActualization();
        }, 5000);
    }

    private stopComparisonPolling(): void {
        if (this.comparisonPoll) {
            window.clearInterval(this.comparisonPoll);
            this.comparisonPoll = null;
        }
    }

    private isComparisonFinished(): boolean {
        return this.props.actualizationResult &&
            this.props.actualizationResult.comparisonFinished;
    }

    private getSuccessResults(): ExtendedActualizationResult[] {
        return this.props.actualizationResult.results
            .filter(result => result.state === 'PENDING' && result.ok)
            .map(result => {
                return {
                    ...result,
                    isSelected: this.state.selected[result.id],
                };
            });
    }

    private getErrorResults(): ExtendedActualizationResult[] {
        return this.props.actualizationResult.results
            .filter(result => result.state === 'PENDING' && !result.ok)
            .map(result => {
                return {
                    ...result,
                    isSelected: this.state.selected[result.id],
                };
            });
    }
}

const styles: StyleRulesCallback = theme => ({
    actionBtn: {
        marginRight: `${theme.spacing.unit}px`,
    },
    resultGridItem: {
        overflowX: 'auto',
        width: '100%',
    }
});
export const ActualizationResults = withStyles(styles)(ActualizationResults0);

interface ComponentState {
    selected: {
        [key: string]: boolean,
    },
}

interface StateProps {
    loading: boolean,
    actualizationResult: Actualization,
    regionCatalogs: RegionCatalog[],
    activityCatalogs: ActivityCatalog[],
}

interface ActionProps {
    onMarkAsPrimary: (payload: ValidationsRequest) => AppThunk,
    onDiscard: (payload: ValidationsRequest) => AppThunk,
    onLoadRegionCatalogs: () => void,
    onLoadActivityCatalogs: () => void,
    onRefreshActualization: () => void,
}

interface ActualizationResultsProps {
    onBack: () => void,
    onClose: () => void,
    onMarkAsPrimary: (payload: ValidationsRequest) => Promise<void>,
    onDiscard: (payload: ValidationsRequest) => Promise<void>,
}

type ComponentProps = ActualizationResultsProps & StyledComponentProps &
    StateProps & Omit<ActionProps, 'onMarkAsPrimary' | 'onDiscard'>;

const mapDispatchToProps: ActionProps = {
    onMarkAsPrimary: putValidationCandidates,
    onDiscard: putValidationCandidates,
    onLoadRegionCatalogs: getActiveRegionCatalogs,
    onLoadActivityCatalogs: getActiveActivityCatalogs,
    onRefreshActualization: refreshActualization,
};

function mapStateToProps(state: State): StateProps {
    const actualizationResultState = state.REFACTORED.actualizationResult;
    const regionCatalogs = state.REFACTORED.regionCatalogs;
    const activityCatalogs = state.REFACTORED.activityCatalogs;
    return {
        loading: actualizationResultState.loading ||
            regionCatalogs.loading || activityCatalogs.loading,
        actualizationResult: actualizationResultState.data,
        activityCatalogs: activityCatalogs.data,
        regionCatalogs: regionCatalogs.data,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ActualizationResults);
