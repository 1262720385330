import { boolean, mixed, number, object, ObjectSchema, string } from '../../utils/validators';
import { Configuration, MyPlantProduct, TermsConditions } from '../../api/model-service/model-service';
import { OtherTermsConditionsFormProps } from './OtherTermsConditionsForm';
import { integerMessage, rangeMessage, requiredMessage } from '../../utils/validationMessages';

export function getTermsAndConditionsSchema(props: OtherTermsConditionsFormProps): ObjectSchema<Partial<Configuration>> {
    const termsConditionsSchema: ObjectSchema<TermsConditions> = object({
        id: number(),
        contractGoverningLaw: string().required(requiredMessage),
        contractLanguage: string().required(requiredMessage),
        secondContractLanguage: string(),
        terminationCategory: string().required(requiredMessage),
        terminationAmount: number()
            .required(requiredMessage)
            .integer(integerMessage)
            .min(0, rangeMessage(0, 99))
            .max(99, rangeMessage(0, 99)),
        terminationWordingStandard: boolean().required(requiredMessage),
        suspensionWordingStandard: boolean().required(requiredMessage),
        embeddedDerivatives: boolean().required(requiredMessage),
        limitationOfLiabilityAmount: number()
            .required(requiredMessage)
            .integer(integerMessage)
            .min(0, rangeMessage(0, 500))
            .max(500, rangeMessage(0, 500)),
        limitationOfLiabilityWordingStandard: boolean().required(requiredMessage),
        productivityImplClause: props.showProductivityImplClause
            ? boolean().required(requiredMessage)
            : null,
        deliveryTerms: string().required(requiredMessage),
        passageOfTitleWordingStandard: boolean().required(requiredMessage),
        siteInventoryIncluded: props.showSiteInventoryIncluded
            ? boolean().required(requiredMessage)
            : null,
        warrantyPeriodParts: number()
            .min(1, rangeMessage(1, 10))
            .max(10, rangeMessage(1, 10)),
        warrantyPeriodService: props.showWarrantyPeriodService
            ? number()
                .min(1, rangeMessage(1, 10))
                .max(10, rangeMessage(1, 10))
            : null,
        warrantyWordingStandard: boolean().required(requiredMessage),
        residualValueOrBuyBackGuaranteeIncluded: boolean().required(requiredMessage),
        assignmentWordingStandard: boolean().required(requiredMessage),
        otherContractWordingStandard: boolean().required(requiredMessage),
        deviationFromDoaApprovalInformation: string()
            .max(2000, 'Field must be between 0 and 2000 characters long')
            .trim(),
        myPlantProduct: mixed<MyPlantProduct>().required(requiredMessage)
            .oneOf([ 'NOT_INCLUDED', 'BASIC', 'CARE', 'PROFESSIONAL', 'CONNECT', 'PROTECT' ]),
        myPlantWording: boolean().required(requiredMessage),
        depositChargeForReupPartsBlocks: props.showDepositChargeForReupPartsBlocks
            ? boolean().required(requiredMessage)
            : null,
        partsListChecked: props.showPartsListChecked
            ? boolean().required(requiredMessage)
            : null,
    });

    return object({
        termsConditions: termsConditionsSchema,
    });
}
