import { ContentTypeError } from '../errors';

export function expectJSON(response: Response): Response {
    const contentType = response.headers.get('Content-Type');
    if (!contentType.toLowerCase().includes('application/json')) {
        throw new ContentTypeError(contentType, 'Expected JSON content');
    }

    return response;
}
