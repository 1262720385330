import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';
import {
    CatalogPageRequest,
    CatalogState,
    CatalogType,
    flipState,
    IBatchCatalogStateUpdate,
    ICatalog,
} from '../model/catalog';
import { Page } from '../model/page';
import { ErrorType } from './error';

const CREATE = 'CREATE';
const READ = 'READ';
const INIT = 'INIT';
const FORMINIT = 'FORMINIT';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const ERROR = 'ERROR';
const CLEARERROR = 'CLEARERROR';
const CATALOGDETAILS = 'CATALOGDETAILS';

export interface ICatalogAction {
    pageData?: Page<ICatalog>;
    requestingPage?: CatalogPageRequest;
    result?: ICatalog;
    error?: Error | string;
    catalog?: ICatalog;
    activityCatalogs?: ICatalog[];
    region?: ICatalog[];
    init?: {
        region: Page<ICatalog>;
        activity: Page<ICatalog>;
        equipment: Page<ICatalog>;
    };
    errorType?: ErrorType;
    type: string;
}

export const CATALOG = {
    CREATE: `${CREATE}_CATALOG`,
    DELETE: `${DELETE}_CATALOG`,
    ERROR: `${ERROR}_CATALOG`,
    INIT: `${INIT}_CATALOG`,
    CATALOGDETAILS: `${CATALOGDETAILS}_CATALOG`,
    FORMINIT: `${FORMINIT}_CATALOG`,
    READ: `${READ}_CATALOG`,
    UPDATE: `${UPDATE}_CATALOG`,
    CLEARERROR: `${CLEARERROR}_CATALOG`,
};

const regionPage = new CatalogPageRequest().setType(CatalogType.region);
regionPage.setLength(100000);

const activityPage = new CatalogPageRequest().setType(CatalogType.activity);
activityPage.setLength(100000);

const equipmentPage = new CatalogPageRequest().setType(CatalogType.equipment);
equipmentPage.setLength(100000);

// Async Redux-Thunk action
export const createCatalog: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, ICatalogAction>
> = (payload: FormData, pageToLoadAfter: CatalogPageRequest) => {
    return async (dispatch: Dispatch<ICatalogAction>): Promise<Action> => {
        try {
            const errorCheck = await api.catalog
                .fetchJSON('', payload, { method: 'POST' })
                .then(
                    json => {
                        return null;
                    },
                    error => {
                        return error;
                    }
                );

            if (errorCheck) {
                return dispatch({
                    error: errorCheck,
                    type: CATALOG[ERROR],
                });
            }

            const data = await api.catalog.fetchJSON(
                '',
                pageToLoadAfter.getQuery(),
                {}
            );
            const regionData = await api.catalog.fetchJSON(
                '',
                regionPage.getQuery(),
                {}
            );
            const activityData = await api.catalog.fetchJSON(
                '',
                activityPage.getQuery(),
                {}
            );
            const equipmentData = await api.catalog.fetchJSON('', equipmentPage.getQuery(), {});
            return dispatch({
                init: {
                    activity: activityData,
                    region: regionData,
                    equipment: equipmentData,
                },
                pageData: data,
                requestingPage: pageToLoadAfter,
                type: CATALOG[CREATE],
            });
        } catch (e) {
            return dispatch({
                error: (e && e.messsage) || e,
                type: CATALOG[ERROR],
            });
        }
    };
};

// Async Redux-Thunk action
export const updateCatalogState: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, ICatalogAction>
> = (
    payload: IBatchCatalogStateUpdate,
    pageToLoadAfter: CatalogPageRequest,
    subject?: ICatalog
) => {
    return async (dispatch: Dispatch<ICatalogAction>): Promise<Action> => {
        try {
            const updateAction: ICatalogAction = {
                requestingPage: pageToLoadAfter,
                type: CATALOG[UPDATE],
                errorType: ErrorType.MESSAGE,
                error: 'Catalog updated!',
            };

            if (payload.defaultId || subject) {
                updateAction.error = `The new default ${subject.type
                    .toLowerCase()
                    .replace('_', '')} catalog is ${subject.name}.`;
            }

            if (payload.changes && payload.changes[0] && subject) {
                updateAction.error = `The ${subject.type.toLowerCase()} catalog ${
                    subject.name
                } is now ${flipState(subject.state).toLowerCase()}.`;
            }

            await api.catalog.fetch('', payload, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const regionData = await api.catalog.fetchJSON(
                '',
                regionPage.getQuery(),
                {}
            );
            const activityData = await api.catalog.fetchJSON(
                '',
                activityPage.getQuery(),
                {}
            );
            const equipmentData = await api.catalog.fetchJSON('', equipmentPage.getQuery(), {});
            updateAction.init = {
                activity: activityData,
                region: regionData,
                equipment: equipmentData,
            };

            const data = await api.catalog.fetchJSON(
                '',
                pageToLoadAfter.getQuery(),
                {}
            );

            updateAction.pageData = data;

            return dispatch(updateAction);
        } catch (e) {
            return dispatch({
                error: e.message,
                type: CATALOG[ERROR],
            });
        }
    };
};

export const getPage: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, ICatalogAction>
> = (page: CatalogPageRequest) => {
    return async (dispatch: Dispatch<ICatalogAction>): Promise<Action> => {
        try {
            const data = await api.catalog.fetchJSON('', page.getQuery(), {});
            return dispatch({
                pageData: data,
                requestingPage: page,
                type: CATALOG[READ],
            });
        } catch (e) {
            return dispatch({
                error: e.message,
                requestingPage: page,
                type: CATALOG[ERROR],
            });
        }
    };
};

export const getCatalogList: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, ICatalogAction>
> = () => {
    return async (dispatch: Dispatch<ICatalogAction>): Promise<Action> => {
        try {
            const regionData = await api.catalog.fetchJSON(
                '',
                regionPage.getQuery(),
                {}
            );
            const activityData = await api.catalog.fetchJSON(
                '',
                activityPage.getQuery(),
                {}
            );
            const equipmentData = await api.catalog.fetchJSON('', equipmentPage.getQuery(), {});
            return dispatch({
                init: {
                    activity: activityData,
                    region: regionData,
                    equipment: equipmentData,
                },
                type: CATALOG[INIT],
            });
        } catch (e) {
            return dispatch({
                error: e.message,
                type: CATALOG[ERROR],
            });
        }
    };
};

export const getFormCatalogs: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, ICatalogAction>
> = (type: CatalogType, activeOnly: boolean) => {
    return async (dispatch: Dispatch<ICatalogAction>): Promise<Action> => {
        try {
            const query = {};
            if (activeOnly) {
                query[`state`] = [CatalogState.DEFAULT, CatalogState.ACTIVE];
            } else {
                query[`state`] = [
                    CatalogState.DEFAULT,
                    CatalogState.ACTIVE,
                    CatalogState.INACTIVE,
                ];
            }

            const catalogs = await api.catalog.fetchJSON(
                `/${type.toLowerCase()}-catalogs/`,
                query,
                {}
            );

            let types;
            switch (type) {
                case CatalogType.activity:
                    types = 'activities';
                    break;
                case CatalogType.region:
                    types = 'regions';
                    break;
                case CatalogType.equipment:
                    types = 'equipments';
                    break;
                default:
                    throw new Error('Unknown catalog type: ' + type);
            }

            return dispatch({
                [types]: catalogs,
                type: CATALOG[FORMINIT],
            });
        } catch (e) {
            return dispatch({
                error: e.message,
                type: CATALOG[ERROR],
            });
        }
    };
};
