import React, { FC } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { RadioGroupField } from '../RadioGroupField/RadioGroupField';
import { booleanOptions } from '../../utils/booleanOptions';
import { FieldProps } from './FieldProps';

export const PartsListChecked: FC<FieldProps> =
    ({ formikProps, disabled }) => {
        const name = 'termsConditions.partsListChecked';
        const errorMessage = inputError(name, formikProps);
        return (
            <RadioGroupField
                name={name}
                label='Parts List Checked'
                options={booleanOptions}
                value={inputValue(name, formikProps)}
                disabled={disabled}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='dense'
                required
            />
        );
    };
