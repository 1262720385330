import React from 'react';
import MaterialCard, { CardProps as MaterialCardProps } from '@material-ui/core/Card';
import { createCardTestProps } from '../Utils/testProps';

interface ICardProps extends MaterialCardProps {
    testName: string,
    testId: string,
}

export const Card: React.FunctionComponent<ICardProps> =
    ({ testName, testId, ...remainingCardProps }) =>
        <MaterialCard
            {...remainingCardProps}
            {...createCardTestProps(testName, testId)}
        />
    ;

export default Card;
