import { mergeWith } from 'lodash';

export function defaultsDeep(target: object, ...sources: object[]): object {
    return mergeWith(target, ...sources, dontMergeArrays);
}

function dontMergeArrays(targetValue: any, sourceValue: any): any {
    if (typeof targetValue === 'object') {
        // continue default merge behavior
        return undefined;
    }

    // Don't override set values
    if (targetValue !== undefined) {
        return targetValue;
    }

    // continue default merge behavior
    return undefined;
}
