import React, { ChangeEvent, ReactElement } from 'react';
import { Formik, FormikConfig, FormikProps } from 'formik';

export function WrappedFormik<V>(props: FormikConfig<V>): ReactElement {
    const { children, ...remainingProps } = props;
    return (
        <Formik {...remainingProps}>
            {(formikProps: FormikProps<V>) => {
                const wrappedFormikProps: FormikProps<V> = withNoNumberValueParsing(formikProps);
                return typeof children === 'function'
                    ? children(wrappedFormikProps)
                    : children;
            }}
        </Formik>
    );
}

function withNoNumberValueParsing<V>(formikProps: FormikProps<V>): FormikProps<V> {
    const handleChange = (evt: ChangeEvent<InputElement>): void => {
        const target = evt.target;
        if (isNumberInput(target)) {
            formikProps.setFieldValue(target.name, target.value);
        } else {
            formikProps.handleChange(evt);
        }
    };

    return { ...formikProps, handleChange };
}

function isNumberInput(target: InputElement): boolean {
    return target.type === 'number' || target.type === 'range';
}

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
