import React, { cloneElement, FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { TextField } from '../TextField/TextField';

export const CommercialOperationDate: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'term.commercialOperationDate';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Commercial Operation Date'
                    type='date'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: '9999-12-31',
                    }}
                />,
        });
    };
