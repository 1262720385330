import React, {ChangeEvent, FC} from 'react';
import {StyledComponentProps, StyleRulesCallback, TableHead, TableRow, withStyles} from '@material-ui/core';
import {Checkbox} from '../Form/Checkbox';
import {ResultsTableHeaderCell} from './ResultsTableHeaderCell';
import {Caret} from './Caret';

const TableHeader0: FC<ComponentProps> =
    props =>
        <TableHead>
            <TableRow>
                <ResultsTableHeaderCell style={{whiteSpace: 'nowrap'}}>
                    <Checkbox
                        classes={{root: props.classes.checkboxClass}}
                        testName='selectAll'
                        checked={props.allSelected}
                        onChange={props.onSelectAll}
                    />
                    <span className={props.classes.spanClass}>Opportunity&nbsp;#</span>
                    <Caret
                        isExpanded={props.allExpanded}
                        onClick={() => props.onExpandAll(!props.allExpanded)}
                    />
                </ResultsTableHeaderCell>
                <ResultsTableHeaderCell>Project name</ResultsTableHeaderCell>
                <ResultsTableHeaderCell>Configuration Name</ResultsTableHeaderCell>
                <ResultsTableHeaderCell>Catalog Reference</ResultsTableHeaderCell>
                <ResultsTableHeaderCell>Service Region</ResultsTableHeaderCell>
                <ResultsTableHeaderCell>Oracle Contract Number</ResultsTableHeaderCell>
            </TableRow>
        </TableHead>;

export interface TableHeaderProps {
    allSelected: boolean,
    onSelectAll: (evt: ChangeEvent<HTMLInputElement>) => void,
    allExpanded: boolean,
    onExpandAll: (expand: boolean) => void,
}

type ComponentProps = TableHeaderProps & StyledComponentProps;
const styles: StyleRulesCallback = theme => ({
    spanClass: {
        verticalAlign: 'middle',
    },
    checkboxClass: {
        verticalAlign: 'middle',
    }
});

export const TableHeader = withStyles(styles)(TableHeader0);
