import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';
import { orderBy } from 'lodash';
import { formOption } from '../../utils/formOption';

export const ServiceRegion: FC<ServiceRegionProps> =
    ({ formikProps, container, regionCatalogs, disabled }) => {
        const name = 'catalogRegionId';
        const errorMessage = inputError(name, formikProps);

        const sortedRegionCatalogs: RegionCatalog[] = orderBy(
            regionCatalogs,
            [ catalogStateSortOrder, 'createdOn' ],
            [ 'asc', 'desc' ],
        );
        const options: IFormOption[] = sortedRegionCatalogs
            .flatMap(catalog => {
                const sortedRegions = orderBy(catalog.regions, [ 'name' ]);
                return sortedRegions.map(region => {
                    return formOption('' + region.id, `${region.name} - ${catalog.name}`);
                });
            });

        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Service Region'
                    placeholder='Select region'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

function catalogStateSortOrder(catalog: RegionCatalog): number {
    return catalog.state === 'DEFAULT'
        ? 0
        : 1;
}

interface ServiceRegionProps extends FieldProps {
    regionCatalogs: RegionCatalog[],
}
