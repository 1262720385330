import { createAction, createReducer } from '@reduxjs/toolkit';
import { ActualizationOverview } from '../../api/model-service/actualization';

interface ActualizationOverviewsState {
    loading: boolean,
    error: string,
    data: ActualizationOverview[],
}

export const startLoading = createAction('actualizationOverviews/startLoading');
export const errorResponse = createAction<Error>('actualizationOverviews/errorResponse');
export const successResponse = createAction<ActualizationOverview[]>('actualizationOverviews/successResponse');
export const clearData = createAction('actualizationOverviews/clearData');

const initialState: ActualizationOverviewsState = {
    loading: false,
    error: null,
    data: [],
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            error: null,
            data: action.payload,
        }))
        .addCase(clearData, () => initialState),
);
export default reducer;
