import * as React from 'react';
import './Dashboard.scss';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '../Buttons/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ViewTitle from '../ViewTitle/ViewTitle';
import LatestModelsTable from '../Models/ModelsTable/LatestModelsTable';
import { connect } from 'react-redux';
import { PageRequest, QueryType } from '../../data/model/pageRequest';
import { clearActiveModel, getModelList } from '../../data/actions/models';
import { IFormModel, ModelPageRequest } from '../../data/model/model';
import { Link } from 'react-router-dom';

import ModelDialog from '../Model/ModelDialog/ModelDialog';
import AddIcon from '@material-ui/icons/Add';
import { IModelState } from '../../data/reducers/model';
import { IUserInfo } from "../../data/model/user";
import { AuthorizedToCreateModel } from '../authorization/AuthorizedToCreateModel';
import {FormControlLabel} from "@material-ui/core";
import Switch from "../Form/Switch";

const styles = (theme: any) => ({
    header: {
        padding: '0px 0px 33px 0',
    },
    listRoot: {
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: '#fbfbfb',
    },
    listStickySection: {
        backgroundColor: '#fff',
    },
    ulList: {
        padding: 0,
    },
    warning: {
        padding: theme.spacing.unit * 2,
        backgroundColor: '#ffe9e9',
        border: '1px solid #c14242',
    },
    extraPad: {
        paddingLeft: '45px !important',
        paddingRight: '45px !important',
    },
    alertIcon: {
        top: 3,
        marginRight: 5,
    },
});

interface IProps {
    classes: {
        header: string;
        listRoot: string;
        listSection: string;
        listStickySection: string;
        ulList: string;
        warning: string;
        extraPad: string;
        alertIcon: string;
    };
    clearCurrentModel: any;
    gotoPage: any;
    lookup: any;
    models: IModelState;
    user: IUserInfo;
}

class Dashboard extends React.Component<IProps, {}> {
    public componentDidMount(): void {
        this.props.models.modelListPage.setLength(25);
        const { user } = this.props;
        const {modelListPage} = this.props.models;

        modelListPage.setUserName(user.user_name);
        modelListPage.setQueryType(QueryType.ByUser);
        modelListPage.setVersion(null);

        this.props.gotoPage(0, modelListPage);

        if (this.props.models) {
            this.props.clearCurrentModel(this.props.models.activeModel);
        }
    }

    public render(): React.ReactElement {
        const { classes } = this.props;
        const { modelList, modelListPage, activeModel } = this.props.models;
        const { gotoPage, clearCurrentModel } = this.props;

        return (
            <React.Fragment>
                <div className="dashboard-header">
                    <h1>Multi-Year Agreement Configurator</h1>
                </div>
                <div className="right-content-wrap">
                    <Grid container spacing={40}>
                        <Grid item xs={5} classes={{ item: classes.extraPad }}>
                            <h2>Welcome!</h2>
                            <Typography variant="subtitle1" gutterBottom>
                                Introducing the new MYA Configurator, a new tool
                                designed to streamline Service Contracts Configuration,
                                Modeling and Handover. For questions please contact{' '}
                                <a href="mailto:myac-support@innio.com">Support</a>
                                {' '}or in case of an issue create a{' '}
                                <a href="https://itsupport.innio.com/support/tickets/new" target="_blank">Ticket</a>
                                .
                            </Typography>
                            <br />
                        </Grid>
                        <Grid item xs={7}>
                            <ViewTitle title="Your Latest Models">
                                <AuthorizedToCreateModel>
                                    <ModelDialog
                                        onClick={() => {
                                            clearCurrentModel(activeModel);
                                        }}
                                        variant="contained"
                                        icon={<AddIcon />}
                                        title="New Model"
                                        page={modelListPage}
                                    />
                                </AuthorizedToCreateModel>
                            </ViewTitle>
                            <FormControlLabel
                                    control={<Switch testName="hideEndedSwtich" checked={this.props.models.modelListPage.getHideEnded()} onChange={(e)=>{
                                        const hideEnded = this.props.models.modelListPage.getHideEnded();

                                        this.setState({hideEnded:!hideEnded});
                                        this.props.models.modelListPage.setHideEnded(!hideEnded);
                                        this.props.gotoPage(0, this.props.models.modelListPage);

                                    }} />}
                                    label="Hide Ended Models"
                                  />

                            <Paper>
                                <LatestModelsTable
                                    modelList={modelList}
                                    goto={(e: any, page: number) =>
                                        gotoPage(page, modelListPage)
                                    }
                                    changePageLength={pageLength => {
                                        modelListPage.setLength(
                                            pageLength.target.value
                                        );
                                        gotoPage(0, modelListPage);
                                    }}
                                />
                            </Paper>
                            <br />
                            <Link to={'/models'}>
                                <Button
                                    testName="viewAllModels"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    View All Models
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Paper />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        models: state.models,
        user: state.user.currentUser
    };
};

let searchTimeout;
const mapDispatchToProps = dispatch => {
    return {
        clearCurrentModel: (activeModel: IFormModel) => {
            dispatch(clearActiveModel(activeModel));
        },
        gotoPage: (page: number, pageRequest: ModelPageRequest) => {
            if (pageRequest) {
                pageRequest.setPage(page);
                dispatch(getModelList(pageRequest));
            }
        },
        lookup: (keyword: string, page: PageRequest) => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
            searchTimeout = setTimeout(() => {
                page.setKeyword(keyword);
                dispatch(getModelList(page));
            }, 300);
        },
    };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Dashboard)
);
