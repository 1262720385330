import { createAction, createReducer } from '@reduxjs/toolkit';
import { RegionCatalog } from '../../api/catalog-service/regionCatalogs';

interface ConfigurationDetailsState {
    loading: boolean,
    error: string,
    data: RegionCatalog[],
}

export const startLoading = createAction('regionCatalogs/startLoading');
export const errorResponse = createAction<Error>('regionCatalogs/errorResponse');
export const successResponse = createAction<RegionCatalog[]>('regionCatalogs/successResponse');

const initialState: ConfigurationDetailsState = {
    loading: false,
    error: null,
    data: [],
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(startLoading, state => ({
            ...state,
            loading: true,
        }))
        .addCase(errorResponse, (state, action) => ({
            ...state,
            loading: false,
            error: action.payload.message,
        }))
        .addCase(successResponse, (state, action) => ({
            loading: false,
            error: null,
            data: action.payload,
        })),
);
export default reducer;
