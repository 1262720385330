import React, { cloneElement, FC } from 'react';
import { FieldProps } from './FieldProps';
import { ErrorFormHelperText } from '../ErrorFormHelperText/ErrorFormHelperText';
import { inputError } from '../../utils/inputError';

export const EscalationErrors: FC<FieldProps> =
    ({ formikProps, container }) => {
        const name = 'payment.escalations';
        const errorMessage = inputError(name, formikProps);
        if (!errorMessage) {
            return null;
        }

        return cloneElement(container, {
            children:
                <ErrorFormHelperText testName={name}>
                    {errorMessage}
                </ErrorFormHelperText>,
        });
    };
