import React from 'react';
import { Configuration, Unit } from '../../api/model-service/model-service';
import { FormikActions, FormikProps, Formik } from '../Formik';
import { AddUnitsFormProps } from './AddUnitInterfaces';
import { UnitConfig } from './UnitConfig';
import { formValuesSchema, getMinOphOrStarts, enableReadCounters } from './schema';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { ObjectSchema } from '../../utils/validators';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { Form } from '../Form/Form';
import { Grid, Typography} from '@material-ui/core';
import Button from '../Buttons/Button';
import AddIcon from '@material-ui/icons/Add';
import { assoc } from 'lodash/fp';
import { isModelAsSold } from '../../utils/isModelAsSold';
import { inputError } from '../../utils/inputError';
import {ErrorFormHelperText} from '../ErrorFormHelperText/ErrorFormHelperText';

function defaultValues(configuration: Configuration): Configuration {
    const result: Configuration = Object.assign({
        units: []
    }, configuration);

    return result;
}

export class AddUnitsForm extends ConfigurationForm<AddUnitsFormProps> {
    public render() {
        const props = this.props;
        const { authorizedToEdit, model, configuration } = props;
        const disabled = !authorizedToEdit || isModelAsSold(model);
        const initialValues = configuration.units ? 
            configuration : defaultValues(configuration);

        const schema = formValuesSchema(model, configuration);
        const minOphOrStarts: number = getMinOphOrStarts(model);
        const showReadCounters = enableReadCounters(model)

        return (
            <Formik 
                initialValues={initialValues}
                validationSchema={schema} 
                onSubmit={(config, actions) => {
                    this.handleSubmit(config, actions, schema)
                }}
                enableReinitialize>
                
                {(formikProps: FormikProps<Configuration>) =>
                    <Form testName="units" noValidate>    
                        <Grid container spacing={24}>
                            <Grid item container xs={12} sm>
                                <Grid item xs={3} sm>
                                    <Typography gutterBottom>
                                        Unit Count: {formikProps.values.units.length}
                                    </Typography>
                                    {this.validateUnitCount(formikProps) &&
                                        <ErrorFormHelperText testName={'noUnitsValidation'}>
                                           Configuration requires at least one unit
                                        </ErrorFormHelperText>
                                    }
                                </Grid>
                            </Grid>
                            {formikProps.values.units.map((unit, index) => {
                                return (
                                    <Grid key={index} item container>
                                        <UnitConfig {...props}
                                            disabled={disabled}
                                            onDelete={(index) => this.deleteUnit(formikProps, index)}
                                            showReadCounters={showReadCounters}
                                            minOphOrStarts={minOphOrStarts}
                                            {...formikProps}
                                            index={index}/>
                                    </Grid>
                                );  
                            })}
                            {!disabled && 
                                <Grid item xs className="addUnit">
                                    <Button
                                        testName="addUnitButton"
                                        id="addUnitButton"
                                        color="primary"
                                        onClick={() => this.addNewUnit(formikProps)}>
                                            Add Unit
                                        <AddIcon/>
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                        <ConfigurationFormButtons
                            loading={formikProps.isSubmitting}
                            onBack={props.onBack}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onNext={() => this.submitForm(formikProps, 'NEXT')}
                            onReadOnlyNext={props.onNext}
                            onSecondaryComplete={this.isConfigurationComplete()
                                ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                : null
                            }
                        />
                    </Form>
                }
            </Formik>);
    }

    private validateUnitCount(formikProps: FormikProps<Configuration>): boolean {
        const error = inputError('units', formikProps);

        return !!error && typeof error === 'string';
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }

    private deleteUnit(props: FormikProps<Configuration>, index: number) {
        const units: Unit[] = [...props.values.units];
        
        if (index > units.length) {
            return;
        }
        
        units.splice(index, 1);
        const updatedConfig = assoc('units', units, props.values);
        props.setValues(updatedConfig);
    }
    
    private addNewUnit(props: FormikProps<Configuration>) {
        let newUnit: Unit = {
            unitStartCounter: 0,
            unitEndCounter: 59999,
        };

        const units: Unit[] = [...props.values.units];

        if (units.length) {
            Object.assign(newUnit, units[units.length - 1]);
            delete newUnit.serialNumber;
            delete newUnit.id;
        }

        units.push(newUnit);
        const updatedConfig = assoc('units', units, props.values);
        props.setValues(updatedConfig);
    }

    private handleSubmit(configuration: Configuration,
        actions: FormikActions<Configuration>,
        schema: ObjectSchema<Partial<Configuration>>): void {

        this.props.onSubmit(schema.cast(configuration) as Configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }
}