import React, { cloneElement, FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { FieldProps } from './FieldProps';
import { BillingInterval as BillingIntervalType } from '../../api/model-service/model-service';

export const BillingInterval: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const name = 'payment.billingInterval';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <SelectField
                    name={name}
                    label='Defined Billing Interval'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    options={options}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                />,
        });
    };

const options: Array<IFormOption<BillingIntervalType>> = [
    formOption('MONTHLY', 'Monthly'),
    formOption('QUARTERLY', 'Quarterly'),
    formOption('CALENDAR_YEAR', 'Calendar Year'),
    formOption('ANNUALLY', 'Annually'),
    formOption('PACKAGES', 'Packages'),
];
