import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';
import { Page } from '../model/page';
import {
    advanceModelStage,
    formModelToServiceModel,
    IFormModel, IModelAmendmentDetails,
    IModelCompetitor,
    IServiceModel,
    ITableModelView,
    modelVersionInputs,
    serviceModelToFormModel,
} from '../model/model';
import { PageRequest } from '../model/pageRequest';
import { ICatalogRegion } from '../model/catalog_region';
import { IConfiguration, IConfigurationView } from '../model/configuration';
import { find, findIndex, indexOf } from 'lodash';
import { ErrorType } from './error';
import { parseErrors } from '../utils/error';
import { IFormOption } from '../utils/form';
import { ILock } from '../model/lockversion';
import {IUserMailData} from "../model/user";
import { isEmpty } from 'lodash';

// CREATE REDUX ACTION TYPES
const CREATE = 'CREATE';
const READ = 'READ';
const INIT = 'INIT';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const ERROR = 'ERROR';
const ALTER = 'ALTER';
const GETONE = 'GETONE';
const HANDOVER = 'HANDOVER';
const TRANSITION = 'TRANSITION';
const TRANSITION_WITH_ERROR = 'TRANSITION_WITH_ERROR';
const AMENDMENT_DETAILS = 'AMENDMENT_DETAILS';

export interface IModelAction {
    type: string;
    error?: Error | string;
    activeModel?: IFormModel;
    errorType?: ErrorType;
    modelList?: Page<ITableModelView>;
    modelListPage?: PageRequest;
    competitors?: IModelCompetitor[];
    regions?: ICatalogRegion[];
    contractManagers?: IUserMailData[];
    key?: string;
    value?: any;
    response?: ITableModelView;
    amendmentDetails?:IModelAmendmentDetails;
}

export const MODEL = {
    CREATE: `${CREATE}_MODEL`,
    DELETE: `${DELETE}_MODEL`,
    ALTER: `${ALTER}_MODEL`,
    ERROR: `${ERROR}_MODEL`,
    INIT: `${INIT}_MODEL`,
    READ: `${READ}_MODEL`,
    UPDATE: `${UPDATE}_MODEL`,
    GETONE: `${GETONE}_MODEL`,
    HANDOVER: `${HANDOVER}_MODEL`,
    AMENDMENT_DETAILS: `${AMENDMENT_DETAILS}_MODEL`,
    TRANSITION: `${TRANSITION}_MODEL`,
    TRANSITION_WITH_ERROR: `${TRANSITION_WITH_ERROR}_MODEL`
};

// END REDUX ACTION TYPE CREATION

export const amendModel: ActionCreator<ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (model: IFormModel, amendmentNumber:string) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        try {

            let amendModel = await api.model.fetchJSON(
                        `/models/${model.id}/amendments?amendmentOpportunityNumber=${amendmentNumber}`,
                        null,
                        {method: 'POST'});

            window.history.pushState('', '', `/models/${amendModel.id}`);

            return dispatch({
                activeModel: serviceModelToFormModel(amendModel),
                type: MODEL[GETONE],
            });
        }catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

// Async Redux-Thunk action
export const submitModel: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (payload: IFormModel, page: PageRequest = null) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        try {
            try {
                if (payload.id) {
                    const lock: ILock = await api.model.fetchJSON(
                        `/models/${payload.id}/lockVersions`
                    );
                    if (lock.lockVersion !== payload.lockVersion) {
                        return dispatch({
                            error: `The model you are updating has been edited by someone else. Please track your changes and reload to get the latest`,
                            errorType: ErrorType.REQUEST,
                            type: MODEL[ERROR],
                        });
                    }
                }

                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                let type = MODEL[CREATE];

                if (payload.id) {
                    options.method = 'PUT';
                    type = MODEL[UPDATE];
                }

                const action: IModelAction = {
                    modelListPage: page,
                    type,
                };

                if (payload.additionalScope) {
                    payload.additionalScope = payload.additionalScope.map(
                        scope => {
                            if (!scope.fixedOccurrence) {
                                scope.fixedOccurrence = false;
                            }
                            return scope;
                        }
                    );
                }

                action.activeModel = serviceModelToFormModel(
                    await api.model.fetchJSON(
                        '/models',
                        formModelToServiceModel(payload),
                        options
                    )
                );

                if (options.method === 'POST') {
                    action.modelList = await api.model.fetchJSON(
                        '/models',
                        page && page.getQuery(),
                        {}
                    );
                }

                return dispatch(action);
            } catch (e) {
                return dispatch({
                    type: MODEL[ERROR],
                    error: parseErrors(e),
                });
            }
        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const attachModelAttachment: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (attachments: File[], model: IServiceModel) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        // Upload a files for a specific model. The files should be in a multipart formdata field "file"

        try {
            let data = null;
            if (attachments && attachments.length > 0) {
                for (const attachment of attachments) {

                    //NMYAC-720 JSON Error on saving model when attachments already attached
                    //if file has ID it has already been upload, we dont need to process it again.
                    if(attachment['id'])
                        continue;

                    const formData: FormData = new FormData();
                    formData.append('file', attachment);

                    data = await api.model.fetchJSON(
                        `/models/${model.id}/attachments`,
                        formData,
                        {method: 'POST'}
                    );
                }
            }

            const remoteModel = await api.model.fetchJSON(`/models/${model.id}`, null, {});

            return dispatch({
                activeModel: serviceModelToFormModel(remoteModel),
                type: MODEL[GETONE],
            });

        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const getModelList: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (page: PageRequest) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        try {
            let params = page.getQuery();

            const data = await api.model.fetchJSON(
                '/models',
                params,
                {}
            );
            return dispatch({
                modelList: data,
                modelListPage: page,
                type: MODEL[READ],
            });
        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const initModelForm: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = () => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        try {
            const regions = await api.catalog.fetchJSON('/regions', null, {});
            const contractManagers = await api.authentication.fetchJSON('/users/roles/contract-managers', null, {});

            return dispatch({
                competitors: [],
                type: MODEL[INIT],
                regions,
                contractManagers
            });
        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const getModelDetails: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (id: number) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {

        try {
            const data = await api.model.fetchJSON(`/models/${id}`, null, {});

            const model = serviceModelToFormModel(data);

            return dispatch({
                activeModel: model,
                type: MODEL[GETONE],
            });
        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const getAmendmentDetails: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (modelID: number) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        const data:IModelAmendmentDetails= await api.model.fetchJSON(`/models/${modelID}/amendment-details`, null, {});
        try {

            return dispatch({
                amendmentDetails: data,
                type: MODEL[AMENDMENT_DETAILS],
            });
        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const clearActiveModel: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (currentModel: IFormModel) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        if (currentModel) {
            const keys = Object.keys(currentModel);
            for (const key of keys) {
                delete currentModel[key];
            }
        }

        try {
            return dispatch({
                activeModel: currentModel,
                type: MODEL[GETONE],
            });
        } catch (e) {
            return dispatch({
                error: e,
                type: MODEL[ERROR],
            });
        }
    };
};

export const canChangePrimaryConfiguration = (
    model: IFormModel,
    configuration: IConfigurationView | IConfiguration
): boolean => {
    if (configuration.state !== 'COMPLETED') {
        return false;
    }

    const catalogReferences = [];
    let primaryConfigurations = model.configurationOverviews.map(config => {
        if (config.primaryConfiguration === true) {
            catalogReferences.push(config.catalogReference);
            return config.id;
        }
    });

    primaryConfigurations = primaryConfigurations.filter(val => !!val);

    if (!configuration.primaryConfiguration) {
        // configuration is not primary currently
        return true;
    } else {
        // configuration is primary already
        return primaryConfigurations.length > 1;
    }
};

function getParentEndDate(model): string {
    return model.amendmentInformation &&
        model.amendmentInformation.parentModel &&
        model.amendmentInformation.parentModel.actualEndDate;
}

export const transitionModelState: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (model: IFormModel) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        try {
            let action: IModelAction = {
                type: MODEL[TRANSITION],
            };

            const options = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            };

            const actualEndDate = model.actualEndDate || getParentEndDate(model) || '';

            action = await api.model
                .fetch(
                    `/models/${model.id}/transitionVersion?state=${advanceModelStage(model.modelVersion)}&actualEndDate=${actualEndDate}`,
                    { counterReadingDeleteApprovals: model.counterReadingDeleteApprovals },
                    options
                )
                .then(
                    json => {
                        action.activeModel = serviceModelToFormModel(json);

                        if (!isEmpty(json.acceptableTransitionErrors)) {
                            action.type = MODEL[TRANSITION_WITH_ERROR];
                            action.errorType = ErrorType.REQUEST;
                            action.error = json.acceptableTransitionErrors;
                        }

                        return action;
                    },
                    err => {
                        action.errorType = ErrorType.REQUEST;
                        action.type = MODEL[ERROR];
                        action.error = err.message;
                        return action;
                    }
                )
                .catch(error => {
                    const display = modelVersionInputs.find(input =>
                        input.value === advanceModelStage(model.modelVersion)
                    );
                    action.error = `Error ${error} happened while attempting to transition model into ${display.label}`;
                    return action;
                });
            return dispatch(action);
        } catch (error) {
            return dispatch({
                error: parseErrors(error),
                type: MODEL[ERROR],
                errorType: ErrorType.REQUEST,
            });
        }
    };
};

export const setPrimaryConfigurations: ActionCreator<
    ThunkAction<Promise<Action>, {}, {}, IModelAction>
> = (model: IFormModel, configuration: IConfigurationView | IConfiguration) => {
    return async (dispatch: Dispatch<IModelAction>): Promise<Action> => {
        try {
            if (configuration.state !== 'COMPLETED') {
                return dispatch({
                    type: MODEL[ERROR],
                    errorType: ErrorType.UI,
                    error:
                        'Configurations must be completed before they are marked as primary.',
                });
            }

            let primaryConfigurations = model.configurationOverviews.map(
                config => {
                    if (config.primaryConfiguration === true) {
                        return config.id;
                    }
                }
            );

            primaryConfigurations = primaryConfigurations.filter(val => !!val);

            const actionPayload: IModelAction = {
                errorType: ErrorType.MESSAGE,
                type: MODEL[UPDATE],
            };

            if (!configuration.primaryConfiguration) {
                // configuration is not primary currently
                actionPayload.error = `Configuration "${
                    configuration.displayName
                }" added as a primary configuration.`;
                primaryConfigurations.push(configuration.id);
            } else {
                // configuration is primary already
                if (primaryConfigurations.length > 1) {
                    const indexToDelete = indexOf(
                        primaryConfigurations,
                        configuration.id
                    );
                    if (indexToDelete > -1) {
                        primaryConfigurations.splice(indexToDelete, 1);
                        actionPayload.error = `Configuration "${
                            configuration.displayName
                        }" removed from primary configurations.`;
                    }
                } else {
                    return dispatch({
                        type: MODEL[ERROR],
                        errorType: ErrorType.UI,
                        error:
                            'You must always have at least one primary configuration.',
                    });
                }
            }

            await api.model.fetch(
                `/models/${model.id}/primaryConfigurations`,
                { primaryConfigurations },
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            const data = await api.model.fetchJSON(
                `/models/${model.id}`,
                null,
                {}
            );

            actionPayload.type = MODEL[UPDATE];
            actionPayload.activeModel = serviceModelToFormModel(data);

            return dispatch(actionPayload);
        } catch (e) {
            return dispatch({
                error: parseErrors(e),
                type: MODEL[ERROR],
                errorType: ErrorType.REQUEST,
            });
        }
    };
};
