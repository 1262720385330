import React, {ReactNode} from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '../Buttons/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import {createErrorTestProps} from '../Utils/testProps';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[900],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const MySnackbarContent = props => {
    const {classes, className, message, onClose, variant, ...other} = props;

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                formatMessage(props)
            }
            action={[
                <IconButton
                    testName="close"
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ]}
            {...other}
        />
    );
};

const formatMessage = (props: any): ReactNode => {
    const {classes, message, variant} = props;
    if (!message) {
        return null;
    }

    const Icon = variantIcon[variant];
    const messages = typeof message === 'string' ? [message] : message;

    return (<>
        {messages.map((item, index) => {
            return (<span {...createErrorTestProps("errorMessage", '' + index)} id={"client-snackbar_" + index}
                          key={index} className={classes.message}>
                    <Icon
                        className={classNames(
                            classes.icon,
                            classes.iconVariant,
                        )}
                    />
                {item}
                </span>)
        })
        }
    </>);
};

export default withStyles(styles1)(MySnackbarContent);
