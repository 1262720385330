import React, { cloneElement } from 'react';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { TextField } from '../TextField/TextField';
import { FieldProps } from './FieldProps';
import { InputAdornment } from '@material-ui/core';
import { IndexType } from './IndexType';
import { withVariablePriceEscalation } from './withEscalationType';

export const IndexRatio = withVariablePriceEscalation<IndexRatioProps>(
    ({ type, title, formikProps, container }) => {
        const name = `indexRatio.${type}`;
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label={title}
                    type='number'
                    value={inputValue(name, formikProps)}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    inputProps={{
                        min: '0',
                        max: '100',
                    }}
                />,
        });
    },
);

export interface IndexRatioProps extends FieldProps {
    type: 'price' | IndexType,
    title: string,
}
