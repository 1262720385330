import React, { cloneElement, FC } from 'react';
import { FieldProps } from './FieldProps';
import { isEmpty } from 'lodash';
import { ErrorFormHelperText } from '../ErrorFormHelperText/ErrorFormHelperText';

export const GlobalErrors: FC<FieldProps> =
    ({ formikProps, container }) => {
        const globalErrors: object = (formikProps.errors as any).global;
        if (isEmpty(globalErrors)) {
            return null;
        }

        return cloneElement(container, {
            children:
                <>
                    {Object.keys(globalErrors).map(key => {
                        const error = globalErrors[key];
                        return (
                            <ErrorFormHelperText key={key} testName={key}>
                                {error}
                            </ErrorFormHelperText>
                        );
                    })}
                </>,
        });
    };
