import React, { FC } from 'react';
import { CoveredUnitsProps } from './CoveredUnitsProps';
import { contractCatagory, contractCatagoryInputs } from '../../data/model/configuration';
import ConfigurationDetailsForm from '../ConfigurationDetailsForm/ConfigurationDetailsForm';

const msaContractCategories = [
    contractCatagory.MSA_PREVENTIVE,
    contractCatagory.MSA_PREVENTIVE_AND_CORRECTIVE,
];
const isMsaCategoryOption = option => msaContractCategories.includes(option.value);
const msaContractCategoryOptions = contractCatagoryInputs.filter(isMsaCategoryOption);

export const MsaCoveredUnits: FC<CoveredUnitsProps> =
    props =>
        <ConfigurationDetailsForm
            {...props}
            savedContractCategoryOptions={msaContractCategoryOptions}
        />;
