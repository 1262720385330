import { IFormOption } from '../../data/utils/form';
import { formOption } from '../../utils/formOption';

export const contractLanguageOptions: IFormOption[] = [
    formOption('Czech', 'Czech'),
    formOption('Danish', 'Danish'),
    formOption('Dutch', 'Dutch'),
    formOption('English', 'English'),
    formOption('German', 'German'),
    formOption('Hungarian', 'Hungarian'),
    formOption('Italian', 'Italian'),
    formOption('Portuguese', 'Portuguese'),
    formOption('Spanish', 'Spanish'),
];
