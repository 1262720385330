import { AppThunk } from '../app-thunk';
import { errorResponse, startLoading, successResponse } from './slice';
import { reportCatalogError } from '../error-actions';
import { getCatalogActivities as getCatalogActivitiesFromApi } from '../../api/catalog-service/catalogActivities';
import { EngineInformation } from '../../api/model-service/model-service';
import {
    getCatalogEngineInformation,
    GetCatalogEngineInformationRequest,
} from '../../api/catalog-service/engineInformation';

export const getCatalogActivities = (equipmentCatalogId: number,
                                     activityCatalogId: number,
                                     engineInformation: EngineInformation): AppThunk =>
    dispatch => {
        const catalogEngineRequest: GetCatalogEngineInformationRequest = {
            equipmentCatalogId,
            activityCatalogId,
        };

        dispatch(startLoading());
        return getCatalogEngineInformation(catalogEngineRequest)
            .then(response => {
                const catalogEngine = response.engineInformation.find(
                    engine =>
                        engine.type === engineInformation.engineType &&
                        engine.version === engineInformation.engineVersion &&
                        engine.maintenanceSection === engineInformation.maintenanceSection,
                );
                if (!catalogEngine) {
                    throw new Error('Could not get catalog activities');
                }

                return getCatalogActivitiesFromApi({ engineId: catalogEngine.id });
            })
            .then(response => {
                dispatch(successResponse(response));
            })
            .catch(err => {
                dispatch(errorResponse(err));
                dispatch(reportCatalogError(err));
                throw err;
            })
    };
