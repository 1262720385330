import { CustomerObligationRadioButtonProps } from "./CustomerObligationsProps";

const sparkPlugService: CustomerObligationRadioButtonProps = {
    label: 'Spark Plug Service',
    name: 'customerObligation.sparkPlugService',
    default: true,
};

const coolingWaterAnalysis: CustomerObligationRadioButtonProps = {
    label: 'Cooling Water Analysis',
    name: 'customerObligation.coolingWaterAnalysis',
    default: true,
};

const fuelGasAnalysis: CustomerObligationRadioButtonProps = {
    label: 'Fuel Gas Analysis',
    name: 'customerObligation.fuelGasAnalysis',
    default: true,
};

const lubeOilSupply: CustomerObligationRadioButtonProps = {
    label: 'Lube Oil Supply',
    name: 'customerObligation.lubeOilSupply',
    default: true,
};

const lubeOilDisposal: CustomerObligationRadioButtonProps = {
    label: 'Lube Oil Disposal',
    name: 'customerObligation.lubeOilDisposal',
    default: true,
};

const lubeOilSampling: CustomerObligationRadioButtonProps = {
    label: 'Lube Oil Sampling',
    name: 'customerObligation.lubeOilSampling',
    default: true,
};

const plannedRoutineMaintenance: CustomerObligationRadioButtonProps = {
    label: 'Planned Routine Maintenance',
    name: 'customerObligation.plannedRoutineMaintenance',
    default: false,
};

const assistanceToExtendedMaintenance: CustomerObligationRadioButtonProps = {
    label: 'Assistance to Extended Maintenance',
    name: 'customerObligation.assistanceToExtendedMaintenance',
    default: false,
};

const machineryBreakdownInsurance: CustomerObligationRadioButtonProps = {
    label: 'Machinery Breakdown Insurance',
    name: 'customerObligation.machineryBreakdownInsurance',
    default: false,
};

export function getMsaCustomerObligations(): CustomerObligationRadioButtonProps[] {
    return [
        sparkPlugService,
        coolingWaterAnalysis,
        fuelGasAnalysis,
        lubeOilSupply,
        lubeOilDisposal,
        lubeOilSampling
    ];
}

export function getDefaultCustomerObligtions() {
    const csaObligations: CustomerObligationRadioButtonProps[] = [
        plannedRoutineMaintenance, 
        assistanceToExtendedMaintenance, 
        machineryBreakdownInsurance
    ]; 

    return getMsaCustomerObligations().concat(csaObligations);
}
