import React, { FC } from 'react';
import MaterialTable from '../../../../Tables/MaterialTable';
import _, { findIndex } from 'lodash';
import { MonetaryInputs } from '../../../../../data/model/configuration_enums';
import { CapsProps } from '../../../../CapsFormSection/CapsProps';

const Caps: FC<CapsBaseProps> = props => {
    const { validate, pricing, mergeConfig } = props;

    const monetaryInputs = MonetaryInputs.slice();

    if (pricing.monetaryCaps) {
        _.forEach(pricing.monetaryCaps, r => {
            _.remove(monetaryInputs, mir => {
                return mir === r.title;
            });
        });
    }

    const monetaryInputsLookup = _.mapKeys(monetaryInputs);

    let addNewMonetaryRowPromise = (newData): Promise<void> =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                if (!newData.title) {
                    reject('Title is Required');
                    return;
                }

                if (!pricing.monetaryCaps) {
                    pricing.monetaryCaps = [];
                }
                pricing.monetaryCaps.push(newData);
                mergeConfig('pricing.monetaryCaps', pricing.monetaryCaps, true);
                validate();
                resolve();
            }, 1000);
        });

    if (monetaryInputs.length === 0) {
        addNewMonetaryRowPromise = null;
    }

    return (
        <React.Fragment>
            {props.showMonetaryCaps
                ? <div className="pt-3 pb-3 material-table">
                    <MaterialTable
                        tableTestName="monetaryCaps"
                        rowTestName="cap"
                        addRowTestName="addCap"
                        editRowTestName="editCap"
                        title="Monetary"
                        options={{
                            paging: false,
                            search: false,
                            showTitle: true,
                            toolbar: true,
                            actionsColumnIndex: -1,
                            toolbarButtonAlignment: 'left',
                        }}
                        columns={[
                            {
                                title: 'Title',
                                field: 'title',
                                lookup: monetaryInputsLookup,
                                render: rowData => rowData.title,
                            },
                            {
                                title: 'Amount € ',
                                field: 'amount',
                                type: 'numeric',
                            },
                        ]}
                        data={pricing.monetaryCaps}
                        editable={{
                            onRowAdd: addNewMonetaryRowPromise,
                            // TODO: can't edit row since the option isn't available....future enhancement
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const index = findIndex(
                                            pricing.monetaryCaps,
                                            oldData
                                        );
                                        pricing.monetaryCaps.splice(index, 1);
                                        mergeConfig(
                                            'pricing.monetaryCaps',
                                            pricing.monetaryCaps,
                                            true
                                        );
                                        validate();
                                        resolve();
                                    }, 1000);
                                }),
                        }}
                    />
                </div>
                : null
            }
            <div className="pt-3 pb-3 material-table">
                <MaterialTable
                    tableTestName="quantityCaps"
                    rowTestName="cap"
                    addRowTestName="addCap"
                    editRowTestName="editCap"
                    title="Quantity"
                    options={{
                        paging: false,
                        search: false,
                        showTitle: true,
                        toolbar: true,
                        actionsColumnIndex: -1,
                        toolbarButtonAlignment: 'left',
                    }}
                    columns={[
                        { title: 'Title', field: 'title' },
                        {
                            title: 'Quantity',
                            field: 'quantity',
                            type: 'numeric',
                        },
                    ]}
                    data={pricing.quantitativeCaps}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (!pricing.quantitativeCaps) {
                                        pricing.quantitativeCaps = [];
                                    }
                                    pricing.quantitativeCaps.push(newData);
                                    mergeConfig(
                                        'pricing.quantitativeCaps',
                                        pricing.quantitativeCaps,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const index = findIndex(
                                        pricing.quantitativeCaps,
                                        oldData
                                    );
                                    pricing.quantitativeCaps[index] = newData;
                                    mergeConfig(
                                        'pricing.quantitativeCaps',
                                        pricing.quantitativeCaps,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const index = findIndex(
                                        pricing.quantitativeCaps,
                                        oldData
                                    );
                                    pricing.quantitativeCaps.splice(index, 1);
                                    mergeConfig(
                                        'pricing.quantitativeCaps',
                                        pricing.quantitativeCaps,
                                        true
                                    );
                                    validate();
                                    resolve();
                                }, 1000);
                            }),
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default Caps;

export interface CapsBaseProps extends CapsProps {
    showMonetaryCaps: boolean,
}
