import { formOption } from '../../utils/formOption';

export const warrantyPeriodOptions = [
    formOption('', 'Not Defined'),
    formOption('1', '1'),
    formOption('2', '2'),
    formOption('3', '3'),
    formOption('4', '4'),
    formOption('5', '5'),
    formOption('6', '6'),
    formOption('7', '7'),
    formOption('8', '8'),
    formOption('9', '9'),
    formOption('10', '10'),
];
