import React, { FunctionComponent, ReactNode } from 'react';
import { CaseAuthorizedToEditForm } from './formAuthorizationContext';

export const AuthorizedToEditForm: FunctionComponent<IAuthorizedToEditForm> =
    props =>
        <CaseAuthorizedToEditForm>
            {
                authorizedToEdit => authorizedToEdit
                    ? props.children
                    : null
            }
        </CaseAuthorizedToEditForm>;

export interface IAuthorizedToEditForm {
    children: ReactNode,
}