import React, { cloneElement, FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { TextField } from '../TextField/TextField';
import { SunsetClauseType } from '../../api/model-service/model-service';
import { InputAdornment } from '@material-ui/core';

export const MaxDuration: FC<FieldProps> =
    ({ formikProps, container, disabled }) => {
        const sunsetClauseType =
            inputValue('term.sunsetClauseType', formikProps) as SunsetClauseType;
        if (sunsetClauseType !== 'MAX_DURATION') {
            return null;
        }

        const name = 'term.maxDuration';
        const errorMessage = inputError(name, formikProps);
        return cloneElement(container, {
            children:
                <TextField
                    name={name}
                    label='Maximal Duration'
                    type='number'
                    placeholder='1-99'
                    value={inputValue(name, formikProps)}
                    disabled={disabled}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    margin='dense'
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Years</InputAdornment>,
                    }}
                    inputProps={{
                        min: '1',
                        max: '99',
                    }}
                />,
        });
    };
