import React, { FC, ChangeEvent } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { inputValue } from '../../utils/inputValue';
import { activityTypeOptions } from '../../utils/activityTypeOptions';
import { FormikProps } from 'formik';
import { Activity, ActivityUnit } from '../../api/model-service/model-service';

function adjustActivityUnit(formikProps: FormikProps<Activity>, evt: ChangeEvent<HTMLSelectElement>) {
    const unit: ActivityUnit = evt.target.value === 'INNIO_LABOR'
        ? 'HOURS'
        : 'EURO';
    formikProps.setFieldValue('unit', unit);
}

export const Type: FC<FieldProps> =
    ({ formikProps }) => {
        const name = 'type';
        const error = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label='Type'
                margin='dense'
                placeholder='Select a type'
                options={activityTypeOptions}
                value={inputValue(name, formikProps)}
                onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                    adjustActivityUnit(formikProps, evt);
                    formikProps.handleChange(evt)
                }}
                onBlur={formikProps.handleBlur}
                error={!!error}
                helperText={error}
                fullWidth
            />
        );
    };

