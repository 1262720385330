import { array, mixed, number, NumberSchema, object, ObjectSchema, Schema, string } from '../../utils/validators';
import {
    BillingBase,
    CalculationMethod,
    Escalation,
    VariablePriceEscalation,
} from '../../api/model-service/model-service';
import { integerMessage, rangeMessage, requiredMessage } from '../../utils/validationMessages';
import { hasValue } from '../../data/utils/form';
import { IndexType } from './IndexType';

export function getEscalationSchema(): ObjectSchema<Escalation> {
    return object({
        id: number(),
        type: mixed()
            .required(requiredMessage)
            .oneOf([ 'fixPrice', 'details' ]),
        billingBases: array<BillingBase>()
            .required(requiredMessage)
            .min(1, requiredMessage),
        initialPriceEscalationDate: string().required(requiredMessage),
        fixedPriceEscalationValue: whenFixedPriceEscalation(number()
            .required(requiredMessage)
            .integer(integerMessage)
            .min(0, rangeMessage(0, 10))
            .max(10, rangeMessage(0, 10))),
        calculationMethod: whenVariablePriceEscalation(mixed<CalculationMethod>()
            .required(requiredMessage)
            .oneOf([ 'MONTHLY_SPECIFIC', 'YEARLY_AVERAGE' ])),
        priceEscalationUpperLimit: whenVariablePriceEscalation(number()
            .min(0, rangeMessage(0, 10))
            .max(10, rangeMessage(0, 10))),
        priceEscalationLowerLimit: whenVariablePriceEscalation(number()
            .min(-10, rangeMessage(-10, 10))
            .when('priceEscalationUpperLimit', (upperLimit: number, schema: NumberSchema) =>
                hasValue(upperLimit) && upperLimit <= 10
                    ? schema.max(upperLimit, rangeMessage(-10, 'the upper limit'))
                    : schema.max(10, rangeMessage(-10, 10)),
            ),
        ),
        indexRatio: allIndexRatiosSchema,
        laborIndex: createIndexSchema('labor'),
        materialIndex: createIndexSchema('material'),
        lubeOilIndex: createIndexSchema('lubeOil'),
        legacyEscalationFormula: whenVariablePriceEscalation(null),
    });
}

const whenEscalationType = (type: Escalation['type']) =>
    <T>(thenSchema: Schema<T>) => mixed().when('type', {
        is: type,
        then: thenSchema,
        otherwise: mixed().strip(true),
    });
const whenVariablePriceEscalation = whenEscalationType('details');
const whenFixedPriceEscalation = whenEscalationType('fixPrice');

const indexRatioSchema = number()
    .required(requiredMessage)
    .integer(integerMessage)
    .min(0, rangeMessage(0, 100))
    .max(100, rangeMessage(0, 100));
const allIndexRatiosSchema = whenVariablePriceEscalation(object<VariablePriceEscalation['indexRatio']>()
    .shape({
        price: indexRatioSchema,
        labor: indexRatioSchema,
        material: indexRatioSchema,
        lubeOil: indexRatioSchema,
    })
    .test('index', null, function (indexRatio: VariablePriceEscalation['indexRatio']) {
        const ratioTypes: Array<keyof typeof indexRatio> = [ 'price', 'labor', 'material', 'lubeOil' ];

        const hasAllValues = ratioTypes.every(type => hasValue(indexRatio[type]));
        if (!hasAllValues) {
            return true;
        }

        const total = ratioTypes.reduce((prevTotal, type) => prevTotal + indexRatio[type], 0);
        if (total === 100) {
            return true;
        }

        return this.createError({
            path: 'global.indexRatio',
            message: 'The sum of all index ratios must be 100%',
        });
    }));

const createIndexSchema = (indexType: IndexType) =>
    // Can't use `whenVariablePriceEscalation()` for the type check because
    // the inner `when()` validation doesn't work.
    mixed().when([ 'type', `indexRatio.${indexType}` ], {
        is: (escalationType, indexRatio) =>
            escalationType === 'details' && hasValue(indexRatio) && indexRatio !== 0,
        then: requiredIndexSchema,
        otherwise: mixed().strip(true),
    });
const requiredIndexSchema = object<VariablePriceEscalation['laborIndex']>()
    .shape({
        name: string().required(requiredMessage),
        date: string().required(requiredMessage),
    });
