import * as yup from 'yup';
import { hasValue } from '../data/utils/form';
import { pastMessage, yearMonthMessage } from './validationMessages';
import moment from 'moment';

export const yearMonth = (): YearMonthSchema => new YearMonthSchema();

export class YearMonthSchema extends yup.mixed<string> {

    private static parse(value: string): moment.Moment {
        return moment(value, 'YYYY-MM', true).date(1);
    }

    constructor() {
        super();

        this.withMutation(() => {
            this.typeError(yearMonthMessage)
                .nullable()
                .transform(emptyAsNull);
        });
    }

    public required(...args): this {
        return super.required(...args) as this;
    }

    public past(message = pastMessage): this {
        return this.test({
            message,
            name: 'past',
            exclusive: true,
            test(value) {
                const valueMoment = YearMonthSchema.parse(value);
                const currentMonth = moment().startOf('month');
                return valueMoment.isBefore(currentMonth);
            },
        })
    }

    // Used internally by yup
    protected _typeCheck(value): boolean {
        if (!hasValue(value)) {
            return true;
        }

        if (typeof value !== 'string') {
            return false;
        }

        return YearMonthSchema.parse(value).isValid()
    }
}

const emptyAsNull = (parsedValue, originalValue) =>
    originalValue === '' || originalValue === undefined ? null : parsedValue;
