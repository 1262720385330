export function formData(body: object): FormData {
    const data = new FormData();

    Object.keys(body).forEach(name => {
        const value = body[name];

        if (!Array.isArray(value)) {
            data.set(name, value);
            return;
        }

        value.forEach(item => {
            data.append(name, item);
        });
    });

    return data;
}
