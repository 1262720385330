import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { createInputTestProps, createErrorTestProps } from '../Utils/testProps';

export default class TextValidator extends ValidatorComponent<TextFieldProps> {

    public render(): React.ReactNode {
        const {
            error,
            FormHelperTextProps,
            helperText,
            inputProps,

            // Extract the props that don't belong in the text field even
            // if they're not used.
            errorMessages,
            validators,
            validatorListener,
            withRequiredValidator,

            ...textFieldProps
        } = this.props;

        const { name } = textFieldProps;
        const { isValid } = this.state;

        const fullInputProps = {
            ...inputProps,
            ...createInputTestProps(name),
        }

        const fullHelperTextProps = isValid
            ? FormHelperTextProps
            : {
                ...FormHelperTextProps,
                ...createErrorTestProps(name),
            };

        const errorOrHint = isValid ? helperText : this.getErrorMessage();

        return (
            <TextField
                {...textFieldProps}
                error={!isValid || error}
                inputProps={fullInputProps}
                FormHelperTextProps={fullHelperTextProps}
                helperText={errorOrHint}
            />
        );
    }
}