import React, { FC, ReactNode } from 'react';
import { DeleteAction, MigrationAction, ReplaceAction } from './migration-actions';
import { Formik, FormikProps } from '../Formik';
import { Button } from '../Buttons/Button';
import { SubmitButton } from '../Form/SubmitButton';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import { SelectField } from '../SelectField/SelectField';
import { hasValue, IFormOption, iv } from '../../data/utils/form';
import { get, isEmpty } from 'lodash';
import * as yup from 'yup';
import Table from '../Tables/Table';
import TableDataRow from '../Tables/TableDataRow';
import TableDataCell from '../Tables/TableDataCell';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';
import { Activity, ActivityType } from '../../api/model-service/model-service';

const MigrateStandardScopeDialogForm0: FC<MigrateStandardScopeDialogContentProps & StyledComponentProps> =
    props => {
        const singleReplacements = props.replaced.filter(hasSingleReplacementOption);
        const multipleReplacements = props.replaced.filter(hasMultipleReplacementOptions);
        return (
            <Formik
                initialValues={getReplacementModel(multipleReplacements)}
                validationSchema={replacementFormModelSchema}
                onSubmit={(values, actions) => {
                    const deleteActions: DeleteAction[] = props.removed.map(oldScope => {
                        return {
                            type: 'DELETE',
                            key: oldScope.key,
                        };
                    });
                    const seamlessReplacementActions: ReplaceAction[] = props.migrated.map(replacement => {
                        return {
                            type: 'REPLACE',
                            key: replacement.oldScope.key,
                            replacement: replacement.replacementOptions[0],
                        };
                    });
                    const singleReplacementActions: ReplaceAction[] = singleReplacements.map(replacement => {
                        return {
                            type: 'REPLACE',
                            key: replacement.oldScope.key,
                            replacement: replacement.replacementOptions[0],
                        };
                    });
                    const multipleReplacementActions: ReplaceAction[] = values.replacements.map((replacement, idx) => {
                        const newActivity = multipleReplacements[idx].replacementOptions.find(activity =>
                            activity.attribute === replacement.attribute);

                        return {
                            type: 'REPLACE',
                            key: replacement.key,
                            replacement: newActivity,
                        };
                    });
                    props.onSubmit([
                        ...deleteActions,
                        ...seamlessReplacementActions,
                        ...singleReplacementActions,
                        ...multipleReplacementActions,
                    ]);

                    actions.setSubmitting(false);
                }}
                enableReinitialize
            >
                {formikProps =>
                    <form onSubmit={formikProps.handleSubmit}>

                        <DialogTitle id='form-dialog-title'>
                            <Grid
                                container
                                direction='row'
                                justify='space-between'
                                alignItems='center'
                            >
                                There were conflicts migrating the activities. Please review them below:
                            </Grid>
                        </DialogTitle>

                        <DialogContent>
                            <Table testName='activityConflicts'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component='th'>Scope</TableCell>
                                        <TableCell component='th'>Service</TableCell>
                                        <TableCell component='th'>Attribute</TableCell>
                                        <TableCell component='th'>Type</TableCell>
                                        <TableCell component='th'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {multipleReplacements.map((replacement, idx) => {
                                        const oldScope = replacement.oldScope;
                                        const newOptions = replacement.replacementOptions;
                                        return (
                                            <TableDataRow
                                                testName='activityConflict'
                                                testId={oldScope.key}
                                                key={oldScope.key}
                                            >
                                                {renderScope(oldScope)}
                                                {renderReplacementInput(props, formikProps, oldScope, newOptions, idx)}
                                            </TableDataRow>
                                        );
                                    })}
                                    {singleReplacements.map((replacement, idx) => {
                                        const oldScope = replacement.oldScope;
                                        const newOptions = replacement.replacementOptions;
                                        return (
                                            <TableDataRow
                                                testName='activityConflict'
                                                testId={oldScope.key}
                                                key={oldScope.key}
                                            >
                                                {renderScope(oldScope)}
                                                {renderSingleReplacement(props, oldScope, newOptions[0], idx)}
                                            </TableDataRow>
                                        );
                                    })}
                                    {props.removed.map(scope =>
                                        <TableDataRow
                                            testName='activityConflict'
                                            testId={scope.key}
                                            key={scope.key}
                                        >
                                            {renderScope(scope)}
                                            <TableDataCell testName='action'>
                                                <Typography color='secondary'>Removed</Typography>
                                            </TableDataCell>
                                        </TableDataRow>,
                                    )}
                                </TableBody>
                            </Table>
                        </DialogContent>

                        <DialogActions>
                            <Button testName='cancel' onClick={props.onCancel}>Cancel</Button>
                            <SubmitButton testName='apply' color='primary'>Proceed</SubmitButton>
                        </DialogActions>
                    </form>
                }
            </Formik>
        );
    }
;

const styles = {
    selectField: {
        minWidth: '150px',
    },
};

export const MigrateStandardScopeDialogForm = withStyles(styles)(MigrateStandardScopeDialogForm0);

export interface MigrateStandardScopeDialogContentProps {
    migrated: ActivityReplacement[],
    removed: Activity[],
    replaced: ActivityReplacement[],
    onCancel: () => void,
    onSubmit: (actions: MigrationAction[]) => void,
}

export interface ActivityReplacement {
    oldScope: Activity,
    replacementOptions: CatalogActivity[],
}

function getReplacementModel(replaced: ActivityReplacement[]): ReplacementFormModel {
    const replacements = replaced.map(replacement => {
        return {
            key: replacement.oldScope.key,
            attribute: null,
        };
    });

    return { replacements };
}

interface ReplacementFormModel {
    replacements: AttributeSelection[];
}

interface AttributeSelection {
    key: string,
    attribute: string,
}

const attributeSelectionSchema = yup.object().shape({
    attribute: yup.string().nullable().trim().required('Please select an attribute'),
});

const replacementFormModelSchema = yup.object().shape({
    replacements: yup.array().of(attributeSelectionSchema),
});

function renderScope(scope: Activity): ReactNode {
    return (
        <>
            <TableDataCell testName='scope'>{scope.scope}</TableDataCell>
            <TableDataCell testName='serviceAndFrequency'>{scope.service} ({scope.frequency})</TableDataCell>
            <TableDataCell testName='additionalAttribute'>{scope.additionalAttribute}</TableDataCell>
            <TableDataCell testName='type'>{getScopeTypeLabel(scope.type)}</TableDataCell>
        </>
    );
}

function hasSingleReplacementOption(replacement: ActivityReplacement): boolean {
    return replacement.replacementOptions.length === 1;
}

function hasMultipleReplacementOptions(replacement: ActivityReplacement): boolean {
    return !hasSingleReplacementOption(replacement);
}

function renderSingleReplacement(props: MigrateStandardScopeDialogContentProps & StyledComponentProps,
                                 scope: Activity,
                                 activity: CatalogActivity,
                                 idx: number): ReactNode {

    const fieldName = `replacements[${idx}].attribute`;
    const singleOption = [
        iv('', 'n/a'),
    ];

    return (
        <TableDataCell testName='action'>
            <SelectField
                className={props.classes.selectField}
                id={fieldName}
                name={fieldName}
                label='New Attribute'
                margin='dense'
                options={singleOption}
                value=''
                fullWidth
            />
        </TableDataCell>
    );
}

function renderReplacementInput(props: MigrateStandardScopeDialogContentProps & StyledComponentProps,
                                formikProps: FormikProps<ReplacementFormModel>,
                                scope: Activity,
                                activities: CatalogActivity[],
                                idx: number): ReactNode {

    const fieldName = `replacements[${idx}].attribute`;
    const { error, helperText } = getErrorTextProps(fieldName, formikProps);

    return (
        <TableDataCell testName='action'>
            <div>
                <SelectField
                    className={props.classes.selectField}
                    id={fieldName}
                    name={fieldName}
                    label='New Attribute'
                    margin='dense'
                    placeholder='Select new attribute'
                    options={getAttributeOptions(activities)}
                    value={getNonNullValue(fieldName, formikProps)}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={error}
                    helperText={helperText}
                    fullWidth
                />
            </div>
        </TableDataCell>
    );
}

function getAttributeOptions(activities: CatalogActivity[]): IFormOption[] {
    return activities.map(activity => iv(activity.attribute, activity.attribute));
}

function renderAttribute(attribute: string): string {
    return isEmpty(attribute)
        ? 'n/a'
        : attribute;
}

function getNonNullValue(name: string, formikProps: FormikProps<ReplacementFormModel>): NonNullable<string> {
    const value = get(formikProps.values, name);
    return hasValue(value)
        ? '' + value
        : '';
}

function getErrorTextProps(name: string, formikProps: FormikProps<ReplacementFormModel>): HelperTextProps {
    const isTouchedInput = get(formikProps.touched, name);
    if (!isTouchedInput) {
        return { error: false, helperText: null };
    }

    const error = getInputError(name, formikProps);
    const hasError = !!error;
    const helperText = hasError ? error : null;
    return { error: hasError, helperText };
}

interface HelperTextProps {
    error: boolean,
    helperText: string,
}

function getInputError(name: string, formikProps: FormikProps<ReplacementFormModel>): string {
    return get(formikProps.errors, name);
}

function getScopeTypeLabel(type: ActivityType): string {
    switch (type) {
        case 'INNIO_PARTS':
            return 'INNIO Parts';
        case 'INNIO_LABOR':
            return 'INNIO Labor';
        case 'OTHER_PROVIDER':
            return 'Other Provider';
        case 'FREIGHT':
            return 'Freight';
        case 'CUSTOMS':
            return 'Customs';
        default:
            assertNever(type);
    }
}

function assertNever(type: never): never {
    throw new Error('Unknown scope type: ' + type);
}
