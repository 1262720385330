import React, { Fragment, FunctionComponent } from 'react';
import './CloneDialog.scss';
import Button from '../../Buttons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '../../Dialogs/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Divider } from '@material-ui/core';

import AsyncSelect from 'react-select/async';
import { withStyles } from '@material-ui/core/styles';
import { modelVersion } from '../../../data/model/model';

const styles = () => ({
    sectionCloneThis: {
        marginBottom: 15,
    },

    sectionCloneSelection: {
        marginTop: 15,
    },
});

const CloneDialog: FunctionComponent<CloneDialogProps> = props => {
    if (!props.open) {
        return null;
    }

    const cloneToCurrentModel = isInProgress(props.modelVersion) || isInOtr(props.modelVersion)
        ? <Fragment>
            <div className={props.classes.sectionCloneThis}>
                <Button
                    testName="cloneToCurrentModel"
                    onClick={props.handleSaveToCurrentModel}
                    color="primary"
                    variant="outlined"
                >
                    Clone to Current Model
                </Button>
            </div>

            <Divider />
        </Fragment>
        : null;

    return (
        <React.Fragment>
            <Dialog
                testName="cloneConfiguration"
                open={true}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}
                classes={{ paper: 'dialog-ov' }}
            >
                <DialogTitle id="form-dialog-title">
                    Clone Configuration
                </DialogTitle>
                <DialogContent classes={{ root: 'dialogContent-ov' }}>
                    {cloneToCurrentModel}

                    <div className={props.classes.sectionCloneSelection}>
                        <AsyncSelect
                            onChange={props.handleChange}
                            noOptionsMessage={() =>
                                'Please type in a search term to find your Model'
                            }
                            placeholder={'Search for a Model...'}
                            cacheOptions
                            loadOptions={props.modelOptionsPromise}
                        />
                    </div>
                    <div style={{ height: 200 }} />
                </DialogContent>

                <DialogActions>
                    <Button testName="cancel" onClick={props.handleClose}>Cancel</Button>
                    <Button testName="clone" disabled={props.disableCloneButton} onClick={props.handleSave} color="primary">
                        Clone
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

interface CloneDialogProps {
    modelVersion: modelVersion,
    open: boolean,
    handleSave: ClickHandler,
    handleSaveToCurrentModel: ClickHandler,
    handleClose: ClickHandler,
    handleChange: Function,
    modelOptionsPromise: LoadModels,
    classes: MaterialClasses,
    disableCloneButton: boolean,
}

type ClickHandler = React.ComponentProps<typeof Button>['onClick'];
type LoadModels = (searchQuery: string) => void;
type MaterialClasses = {
    [key: string]: string,
};

export default withStyles(styles)(CloneDialog);

function isInProgress(version: modelVersion): boolean {
    return version === modelVersion.IN_PROGRESS;
}

function isInOtr(version: modelVersion): boolean {
    return version === modelVersion.OTR;
}
