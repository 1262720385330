import React, { FunctionComponent, ReactNode } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createRowTestProps } from "../Utils/testProps";

const styles = {
    subtotalRow: {
        padding: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#f5f5f5',
        fontWeight: 600,
        marginTop: 20,
    },
    subtotalCell: {
        paddingLeft: '50px',
        paddingRight: '85px',
    },
};

interface ISubtotalRow {
    testName: string,
    children: ReactNode,
    classes: {
        subtotalRow: string,
        subtotalCell: string,
    }
}

const SubtotalRow: FunctionComponent<ISubtotalRow> = props => {
    const testProps = createRowTestProps(props.testName);

    return (
        <div className={props.classes.subtotalRow} {...testProps}>
            <div className={props.classes.subtotalCell}>Subtotal</div>
            {props.children}
        </div>
    );
};
export default withStyles(styles)(SubtotalRow);
