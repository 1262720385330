import React, { Component, ReactNode } from 'react';
import { IconButton } from '../Buttons/IconButton';
import { MoreVert } from '@material-ui/icons';
import { Menu } from '../Popups/Menu';
import { MenuItem } from '../Popups/MenuItem';

export class ActionMenu extends Component<ActionMenuProps, ComponentState> {

    public state = {
        open: false,
    };

    private toggleBtn: HTMLElement;

    public render(): ReactNode {
        return (
            <>
                <IconButton
                    testName='showActions'
                    onClick={e => this.open(e.target as HTMLElement)}
                >
                    <MoreVert/>
                </IconButton>
                <Menu
                    testName={this.props.testName}
                    testId={this.props.testId}
                    anchorEl={this.toggleBtn}
                    open={this.state.open}
                    onClose={() => this.close()}
                >
                    {this.props.actions.map(action =>
                        <MenuItem
                            key={action.label}
                            testName={action.testName}
                            onClick={() => {
                                this.close();
                                action.onClick();
                            }}
                        >
                            {action.label}
                        </MenuItem>,
                    )}
                </Menu>
            </>
        );
    }

    private open(toggleBtn: HTMLElement): void {
        this.setState({
            open: true,
        });

        // This seems to be the only way to get the DOM button in the current
        // material-ui version. Refs didn't work.
        this.toggleBtn = toggleBtn;
    }

    private close(): void {
        this.setState({ open: false });
    }
}

export interface ActionMenuProps {
    testName: string,
    testId: string,
    actions: ActionMenuItem[],
}

export interface ActionMenuItem {
    testName: string,
    label: string,
    onClick: () => void,
}

interface ComponentState {
    open: boolean,
}
