import React, { ReactNode } from 'react';
import { ConfigurationForm } from '../ConfigurationForm/ConfigurationForm';
import { Formik, FormikActions, FormikProps } from '../Formik';
import { Activity, Configuration } from '../../api/model-service/model-service';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { ConfigurationFormButtons } from '../ConfigurationFormButtons/ConfigurationFormButtons';
import { ObjectSchema } from 'yup';
import { CoveredUnitsProps } from '../CoveredUnitsFormSection/CoveredUnitsProps';
import ConfigurationDetailsInputs from './ConfigurationDetailsInputs';
import { hasValue, IFormOption } from '../../data/utils/form';
import { getConfigurationDetailsSchema } from './schema';
import moment from 'moment';
import { castArray, groupBy, isEmpty } from 'lodash';
import { getMigrationRecipe } from '../../state/activityMigration/getMigrationRecipe';
import { MigrationRecipe } from '../../api/catalog-service/activityMigration';
import { activityToCatalogActivity } from '../../utils/activityConversion';
import { CatalogActivity } from '../../api/catalog-service/catalogActivities';
import { AppThunk } from '../../state/app-thunk';
import { connect } from 'react-redux';
import { ActivityReplacement } from '../MigrateStandardScopeDialog/MigrateStandardScopeDialogForm';
import { createConfigurationFormSectionFactory } from '../ConfigurationFormSection/abstract-factory';
import { assertNever } from '../../utils/assertNever';
import { MigrationAction } from '../MigrateStandardScopeDialog/migration-actions';

class ConfigurationDetailsForm0 extends ConfigurationForm<ConfigurationDetailsFormProps & StyledComponentProps, ComponentState> {

    public state = {
        openActivityMigrationDialog: false,
        removed: null,
        migrated: null,
        replaced: null,
    };

    public render(): ReactNode {
        const props = this.props;

        const initialValues = this.getInitialValues();

        const schema = getConfigurationDetailsSchema(props);

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(config, actions) => this.handleSubmit(config, actions, schema)}
                enableReinitialize
            >
                {(formikProps: FormikProps<Configuration>) =>
                    <>
                        <ConfigurationDetailsInputs
                            model={props.model}
                            formikProps={formikProps}
                            savedContractCategoryOptions={this.props.savedContractCategoryOptions}
                            regionCatalogs={this.props.regionCatalogs}
                            activityCatalogs={this.props.activityCatalogs}
                        />
                        {this.renderMigrationDialog(formikProps, schema)}
                        <ConfigurationFormButtons
                            loading={formikProps.isSubmitting}
                            onSave={() => this.submitForm(formikProps, 'SAVE')}
                            onNext={() => this.submitForm(formikProps, 'NEXT')}
                            onReadOnlyNext={props.onNext}
                            onSecondaryComplete={this.isConfigurationComplete()
                                ? () => this.submitForm(formikProps, 'SECONDARY_COMPLETE')
                                : null
                            }
                        />
                    </>
                }
            </Formik>
        )
    }

    private renderMigrationDialog(formikProps: FormikProps<Configuration>,
                                  schema: ObjectSchema<Configuration>): ReactNode {
        if (!this.state.openActivityMigrationDialog) {
            return null;
        }

        const configuration = formikProps.values;
        const sectionFactory = createConfigurationFormSectionFactory(configuration);

        return (
            <sectionFactory.MigrateStandardScopeDialog
                open={this.state.openActivityMigrationDialog}
                migrated={this.state.migrated}
                removed={this.state.removed}
                replaced={this.state.replaced}
                onCancel={() => {
                    formikProps.setSubmitting(false);
                    this.closeActivityMigrationDialog();
                }}
                onSubmit={actions => {
                    const migratedActivities = this.getMigratedActivities(configuration, actions);
                    const updatedConfiguration: Configuration = {
                        ...configuration,
                        standardScope: migratedActivities,
                    };
                    this.doSubmit(schema.cast(updatedConfiguration), formikProps);
                    this.closeActivityMigrationDialog();
                }}
            />
        );
    }

    private getMigratedActivities(configuration: Configuration, actions: MigrationAction[]): Activity[] {
        const activitiesByKey = groupBy(configuration.standardScope, 'key');
        const migratedActivities = actions
            .map(action => {
                switch (action.type) {
                    case 'DELETE':
                        return null;
                    case 'REPLACE': {
                        const oldActivity = activitiesByKey[action.key][0];
                        const replacement = action.replacement;
                        return this.updateActivity(oldActivity, replacement);
                    }
                    default:
                        return assertNever('Unknown migration action', action);
                }
            })
            .filter(hasValue);
        return migratedActivities;
    }

    private updateActivity(oldActivity: Activity, replacementActivity: CatalogActivity): Activity {
        const updatedFrequency = oldActivity.frequencyModified
            ? oldActivity.frequency
            : replacementActivity.frequency;

        return {
            ...oldActivity,
            additionalAttribute: replacementActivity.attribute,
            frequency: updatedFrequency,
            value: replacementActivity.value,
            unit: replacementActivity.unit,
            icValue: replacementActivity.icValue,
        }
    }

    private getInitialValues() {
        const isSavedConfiguration = hasValue(this.props.configuration.id);
        return isSavedConfiguration ? this.props.configuration : this.defaultValues();
    }

    private defaultValues(): Configuration {
        const defaultActivityCatalog = this.props.activityCatalogs
            .find(catalog => catalog.state === 'DEFAULT');

        const now = moment();
        const currentYear = now.year();
        const today = now.format('YYYY-MM-DD');

        return {
            ...this.props.configuration,
            name: null,
            primaryConfiguration: false,
            contractCategory: 'CSA_PREVENTIVE',
            activityCatalogId: defaultActivityCatalog ? defaultActivityCatalog.id : null,
            catalogRegionId: null,
            engineInformation: {
                catalogEngineId: null,
                engineType: null,
                engineVersion: null,
                maintenanceSection: null,
                maintenanceSchedule: null,
                equipmentModel: null,
                electricalPower: null,
                mechanicalPower: null,
                oilVolume: null,
                unitType: null,
                gasType: null,
                productProgram: '' + currentYear,
            },
            term: {
                effectiveContractStartDate: today,
                commercialOperationDate: today,
                sunsetClauseType: 'NONE',
                maxDuration: null,
                expirationDate: null,
            },
        };
    }

    private handleSubmit(rawConfiguration: Configuration,
                         actions: FormikActions<Configuration>,
                         schema: ObjectSchema<Configuration>): void {

        const configuration = schema.cast(rawConfiguration);
        if (this.shouldMigrateActivities(configuration)) {
            return this.migrateActivitiesAndSubmit(configuration, actions);
        }

        this.doSubmit(configuration, actions);
    }

    private doSubmit(configuration: Configuration, actions: FormikActions<Configuration>): void {
        this.props.onSubmit(configuration)
            .then(() => {
                switch (this.submitReason) {
                    case 'NEXT':
                        return this.props.onNext();
                    case 'SECONDARY_COMPLETE':
                        return this.props.onComplete();
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    private shouldMigrateActivities(updatedConfiguration: Configuration): boolean {
        return this.activityCatalogOrEngineChanged(updatedConfiguration) &&
            !isEmpty(updatedConfiguration.standardScope);
    }

    private activityCatalogOrEngineChanged(updatedConfiguration: Configuration): boolean {
        const oldConfiguration = this.getInitialValues();
        const oldEngine = oldConfiguration.engineInformation;
        const updatedEngine = updatedConfiguration.engineInformation;
        return oldConfiguration.activityCatalogId !== updatedConfiguration.activityCatalogId ||
            oldEngine.engineType !== updatedEngine.engineType ||
            oldEngine.engineVersion !== updatedEngine.engineVersion ||
            oldEngine.maintenanceSection !== updatedEngine.maintenanceSection;
    }

    private migrateActivitiesAndSubmit(configuration: Configuration,
                                       actions: FormikActions<Configuration>) {
        const engineId = configuration.engineInformation.catalogEngineId;
        const catalogActivities = configuration.standardScope.map(activityToCatalogActivity);
        this.props.getMigrationRecipe(engineId, catalogActivities)
            .then(recipe => this.openActivityMigrationDialog(configuration, recipe))
            .catch(() => {
                actions.setSubmitting(false);
            });
    }

    private isConfigurationComplete(): boolean {
        return this.props.configuration.state === 'COMPLETED';
    }

    private openActivityMigrationDialog(configuration: Configuration, recipe: MigrationRecipe): void {
        const activities = groupBy(configuration.standardScope, 'key');
        const getActivitiesByKey = key => activities[key][0];

        type ReplacementOptionsType = MigrationRecipe['migratedActivities'] | MigrationRecipe['replacementOptions'];
        const createActivityReplacement =
            (key: string, replacementOptions: ReplacementOptionsType): ActivityReplacement => {
                const oldActivity = getActivitiesByKey(key);
                const migratedActivity = replacementOptions[key];
                return {
                    oldScope: oldActivity,
                    replacementOptions: castArray(migratedActivity),
                };
            };

        this.setState({
            openActivityMigrationDialog: true,
            removed: recipe.removedKeys.map(getActivitiesByKey),
            migrated: Object.keys(recipe.migratedActivities).map(
                key => createActivityReplacement(key, recipe.migratedActivities)),
            replaced: Object.keys(recipe.replacementOptions).map(
                key => createActivityReplacement(key, recipe.replacementOptions)),
        });
    }

    private closeActivityMigrationDialog(): void {
        this.setState({ openActivityMigrationDialog: false });
    }
}

export interface ConfigurationDetailsFormProps extends CoveredUnitsProps {
    savedContractCategoryOptions: IFormOption[],
    getMigrationRecipe: (engineId: number, activities: CatalogActivity[]) => Promise<MigrationRecipe>,
}

interface ComponentState {
    openActivityMigrationDialog: boolean,
    removed: Activity[],
    migrated: ActivityReplacement[],
    replaced: ActivityReplacement[],
}

const styles: StyleRules = {};

export const ConfigurationDetailsForm = withStyles(styles)(ConfigurationDetailsForm0);

interface ActionProps {
    getMigrationRecipe: (engineId: number, activities: CatalogActivity[]) => AppThunk<MigrationRecipe>;
}

const mapDispatchToProps: ActionProps = {
    getMigrationRecipe,
};

export default connect(null, mapDispatchToProps)(ConfigurationDetailsForm);
