import React, { FC } from 'react';
import { FieldProps } from './FieldProps';
import { inputError } from '../../utils/inputError';
import { formOption } from '../../utils/formOption';
import { IFormOption } from '../../data/utils/form';
import { MultiSelectField } from '../MultiSelectField/MultiSelectField';
import { arrayInputValue } from '../../utils/arrayInputValue';
import { UserMail } from '../../api/authenticationService/users';

export const ContractManagers: FC<ContractManagerInputProps> =
    ({ formikProps, contractManagers, disabled }) => {
        const name = 'contractManagers';
        const errorMessage = inputError(name, formikProps);
        const options: IFormOption[] = contractManagers.map(manager => {
            return formOption(manager.username, manager.displayName);
        });
        return (
            <MultiSelectField
                name={name}
                label='Contract Managers'
                placeholder='Select contract managers'
                value={arrayInputValue(name, formikProps)}
                options={options}
                onChange={updatedValue => {
                    formikProps.setFieldValue(name, updatedValue);
                }}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                disabled={disabled}
            />
        );
    };

export interface ContractManagerInputProps extends FieldProps {
    contractManagers: UserMail[],
}
