import React, { FC } from 'react';
import { TableHead, TableRow } from '@material-ui/core';
import {ComparisonTableHeaderCell} from './ComparisonHeaderTableCell';

export const ComparisonTableHeader: FC =
    () =>
        <TableHead>
            <TableRow>
                <ComparisonTableHeaderCell>Source Configuration</ComparisonTableHeaderCell>
                <ComparisonTableHeaderCell>Actualization Period</ComparisonTableHeaderCell>
                <ComparisonTableHeaderCell>Activity Catalog Name</ComparisonTableHeaderCell>
                <ComparisonTableHeaderCell>Region Catalog Name</ComparisonTableHeaderCell>
                <ComparisonTableHeaderCell>Total Expected ophs/a</ComparisonTableHeaderCell>
                <ComparisonTableHeaderCell>Cost</ComparisonTableHeaderCell>
                <ComparisonTableHeaderCell>Billings</ComparisonTableHeaderCell>
            </TableRow>
        </TableHead>;

