import React, { FC } from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { IFormOption } from '../../data/utils/form';
import Radio from '../Form/Radio';
import { CaseAuthorizedToEditForm } from '../Form/formAuthorizationContext';

export const RadioGroupField: FC<RadioGroupFieldProps> =
    props => {
        const {
            placeholder,
            options,
            label,
            helperText,
            name,
            value,
            disabled,
            onChange,
            onBlur,
            ...remainingFormControlProps
        } = props;

        return (
            <CaseAuthorizedToEditForm>
                {authorizedToEdit =>
                    <FormControl
                        disabled={getInputDisabled(authorizedToEdit, disabled)}
                        {...remainingFormControlProps}
                    >
                        <FormLabel>{label}</FormLabel>
                        <RadioGroup
                            name={name}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            row
                        >
                            {options.map(option =>
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Radio testName={name} testId={option.value} color='primary'/>
                                    }
                                    value={option.value}
                                    label={option.label}
                                    labelPlacement='end'
                                />,
                            )}
                        </RadioGroup>
                        {helperText
                            ? <FormHelperText>
                                {helperText}
                            </FormHelperText>
                            : null
                        }
                    </FormControl>
                }
            </CaseAuthorizedToEditForm>
        );
    };

interface RadioGroupFieldProps extends FormControlProps {
    name: string,
    label?: string,
    helperText?: string,
    options: IFormOption[],
    value?: string,
}

function getInputDisabled(authorizedToEdit: boolean, disabled: boolean): boolean {
    return !authorizedToEdit || disabled;
}
