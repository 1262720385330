import React from 'react';
import { StyleRulesCallback, TableCell, withStyles } from '@material-ui/core';

const cellStyles: StyleRulesCallback = theme => ({
    head: {
        backgroundColor: '#f7f7f7',
        color: theme.palette.common.black,
    },
});
export const StyledTableHeaderCell = withStyles(cellStyles)(TableCell);
