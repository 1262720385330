import React, { FC } from 'react';
import { SelectField } from '../SelectField/SelectField';
import { inputValue } from '../../utils/inputValue';
import { inputError } from '../../utils/inputError';
import { IndexType } from './IndexType';
import { getIndexNameOptions } from './indexNameOptions';
import { Escalation } from '../../api/model-service/model-service';
import { FormikProps } from '../Formik';
import { hasIndexRatio } from './hasIndexRatio';

export const IndexName: FC<IndexNameProps> =
    ({ type, title, formikProps }) => {
        if (!hasIndexRatio(type, formikProps)) {
            return null;
        }

        const name = `${type}Index.name`;
        const errorMessage = inputError(name, formikProps);
        return (
            <SelectField
                name={name}
                label={title}
                placeholder={`Select ${title}`}
                value={inputValue(name, formikProps)}
                options={getIndexNameOptions(type)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={!!errorMessage}
                helperText={errorMessage}
                margin='normal'
                fullWidth
                required
            />
        );
    };

export interface IndexNameProps {
    type: IndexType;
    title: string,
    formikProps: FormikProps<Escalation>,
}
