import { FormikProps } from 'formik';
import { path } from 'lodash/fp'

export function inputError(inputPath: string, formikProps: FormikProps<object>): string {
    const touched = path(inputPath, formikProps.touched);
    if (!touched) {
        return null;
    }

    return path(inputPath, formikProps.errors);
}
